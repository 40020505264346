import Axios from "axios";
import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import React, { useEffect } from "react";
import { breakpointsMapping, smallDevicesBreakpoints } from "utils/breakpoints";
import { getBalancePaymentMethods } from "../controller";
import BalanceCharge from "./BalanceCharge";
import SmallDevicesBalanceCharge from "./SmallDevicesBalanceCharge";
import { actionsMapping } from "./store/actions";
import { useBalanceChargeContext } from "./store/Store";

const BalanceChargeBasedOnDevice = () => {
  const { defaultEntityId } = useAuth();
  const { breakpoint } = useAppContext();
  const { dispatch } = useBalanceChargeContext();

  useEffect(() => {
    (async () => {
      const cancelToken = Axios.CancelToken.source();

      const balanceChargeMethods = await getBalancePaymentMethods(
        defaultEntityId,
        {},
        { cancelToken }
      );

      dispatch({
        type: actionsMapping.setBalanceChargeMethods,
        payload: {
          methods: balanceChargeMethods || [],
          hasError: !balanceChargeMethods,
          areLoading: false,
        },
      });

      return cancelToken.cancel();
    })();
  }, []);

  const chooseViewBasedOnDevice = () => {
    if (
      [...smallDevicesBreakpoints, breakpointsMapping.noBreakpoint].includes(breakpoint)
    ) {
      return <SmallDevicesBalanceCharge />;
    }

    return <BalanceCharge />;
  };

  return <>{chooseViewBasedOnDevice()}</>;
};

export default BalanceChargeBasedOnDevice;
