import EmptyList from "domain/private/components/EmptyList";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { routesMapping } from "../../../../utils/routes";

const EmptyActiveParkings = ({ smallVersion }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <EmptyList
      title={
        smallVersion ? (
          <>
            {t("8020") /* Não tens */}
            <br />
            {t("8021") /* estacionamentos ativos! */}
          </>
        ) : (
          t("8018")
        ) /* Não tens estacionamentos ativos! */
      }
      subtitle={t("8019") /* Clica no botão! Atreve-te a estacionar! */}
      button={{
        label: t("4045") /* Novo Estacionamento */,
        action: () => {
          history.push(`${routesMapping.backofficeParking}`);
        },
      }}
    />
  );
};

export default EmptyActiveParkings;

EmptyActiveParkings.propTypes = {
  smallVersion: PropTypes.bool,
};

EmptyActiveParkings.defaultProps = {
  smallVersion: false,
};
