const originalWidth = 34.86;
const originalHeight = 69.414;
const iconAnchorProportion = 0.13;
const aspectRatio = originalHeight / originalWidth;

export const getPin = (google, { color, width = 25 } = {}) => {
  const height = width * aspectRatio;

  return {
    url: `data:image/svg+xml;base64, ${btoa(
      `<svg xmlns="http://www.w3.org/2000/svg" width="${originalWidth}" height="${originalHeight}" viewBox="0 0 34.86 69.414">
        <g transform="translate(-217.14 -247.582)">
          <path
            d="M875.707,484.6h-5.63v-.007H841.036v.007h-.189v43.577h8.71v0h8.721v-8.709h-8.721V493.3H867v17.447h-8.719v8.709h17.429Z"
            transform="translate(-623.707 -237.012)"
            fill="${color}"
          />
          <g transform="translate(222 267.5)">
            <ellipse
              cx="9.524"
              cy="9.524"
              rx="9.524"
              ry="9.524"
              transform="translate(3.799 30.449)" fill="${color}"
            />
          </g>
        </g>
      </svg>`
    )}`,
    scaledSize: new google.maps.Size(width, height),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(width / 2, height - height * iconAnchorProportion),
  };
};
