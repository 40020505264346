import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import { useBackofficeContext } from "domain/private/components/BackOfficeProvider";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { changeAndReturnPermitHolderState } from "requests/permitHolders";
import { permitHolderStates } from "utils/permitHolders";
import { userTypes } from "utils/users";
import { operationsMap } from "../helper";
import { usePermitHoldersDetailsContext } from "../Provider";
import { actionTypes } from "../store/actions";
import useWarnings from "./useWarnings";

const useOperations = () => {
  const { t } = useTranslation();
  const { defaultEntityId: entityId, userId: driverId, driverHash } = useAuth();
  const { setIsLoading } = useAppContext();
  const { displayConfirmation } = useBackofficeContext();
  const { isEditable, dispatch } = usePermitHoldersDetailsContext();
  const {
    id: permitHolderId,
    state,
    reference,
    token: permitHolderToken,
  } = usePermitHoldersDetailsContext();
  const { hasWarnings } = useWarnings();

  const handleChangeState = useCallback(
    async (nextState, actions = []) => {
      setIsLoading(true);

      const newState = await changeAndReturnPermitHolderState(
        entityId,
        driverHash,
        permitHolderId,
        permitHolderToken,
        {
          permitHolderStateId: nextState,
          userId: driverId,
          userTypeId: userTypes.client,
        },
        { autoCloseError: false }
      );

      setIsLoading(false);

      if (newState) {
        dispatch([{ type: actionTypes.SET_STATE, payload: newState }, ...actions]);
      }
    },
    [entityId, driverId, permitHolderId]
  );

  const inactivate = useCallback(() => {
    displayConfirmation({
      title: `${t("1848") /* Dístico */} ${reference}`,
      message: t("10569") /* Tens a certeza que pretendes desativar o dístico? */,
      onConfirm: () => handleChangeState(permitHolderStates.inactivated),
      configs: {
        confirmationButtonColor: "danger",
        confirmationButtonLabel: t("1198") /* Desativar */,
      },
    });
  }, [reference, displayConfirmation, t, handleChangeState]);

  const sendToAnalysis = useCallback(
    () => handleChangeState(permitHolderStates.underAnalysis),
    [handleChangeState]
  );

  const makeEditableFromApprovedStates = useCallback(() => {
    displayConfirmation({
      title: `${t("1848") /* Dístico */} ${reference}`,
      message: t(
        "10570"
      ) /* Se editares o dístico o mesmo fica inválido para fins de estacionamento até ser novamente aprovado. Tens a certeza que pretendes editar este dístico? */,
      onConfirm: () =>
        handleChangeState(permitHolderStates.underElaboration, [
          { type: actionTypes.SET_IS_EDITABLE, payload: true },
        ]),
    });
  }, [reference, displayConfirmation, t, handleChangeState]);

  const makeEditableFromUnderElaborationState = useCallback(
    () => dispatch({ type: actionTypes.SET_IS_EDITABLE, payload: true }),
    []
  );

  const makeEditableFromUnderAnalysisState = useCallback(() => {
    displayConfirmation({
      title: `${t("1848") /* Dístico */} ${reference}`,
      message: t(
        "10571"
      ) /* Este dístico já se encontra em processo de análise. Tens a certeza que pretendes editar o mesmo? */,
      onConfirm: () =>
        handleChangeState(permitHolderStates.underElaboration, [
          { type: actionTypes.SET_IS_EDITABLE, payload: true },
        ]),
    });
  }, []);

  const makeEditable = useCallback(
    () =>
      handleChangeState(permitHolderStates.underElaboration, [
        { type: actionTypes.SET_IS_EDITABLE, payload: true },
      ]),
    [handleChangeState]
  );

  const operations = useMemo(() => {
    const sendToAnalysisOperation = {
      id: operationsMap.sendToAnalysis,
      label: t("10439") /* Enviar para análise */,
      action: sendToAnalysis,
      color: "secondary",
    };

    const inactivateOperation = {
      id: operationsMap.inactivate,
      label: t("1198") /* Desativar */,
      action: inactivate,
      color: "danger",
    };

    const editOperation = {
      id: operationsMap.edit,
      label: t("3375") /* Editar */,
      action: makeEditable,
      color: "secondary",
    };

    const approvedOperations = [
      inactivateOperation,
      {
        id: operationsMap.edit,
        label: t("3375") /* Editar */,
        action: makeEditableFromApprovedStates,
        color: "secondary",
      },
    ];

    return {
      [permitHolderStates.underElaboration]: isEditable
        ? []
        : [
            ...(hasWarnings ? [] : [sendToAnalysisOperation]),
            {
              id: operationsMap.inactivate,
              label: t("1198") /* Desativar */,
              action: inactivate,
              color: "danger",
            },
            {
              id: operationsMap.edit,
              label: t("3375") /* Editar */,
              action: makeEditableFromUnderElaborationState,
              color: "secondary",
            },
          ],
      [permitHolderStates.underAnalysis]: [
        inactivateOperation,
        {
          id: operationsMap.edit,
          label: t("3375") /* Editar */,
          action: makeEditableFromUnderAnalysisState,
          color: "secondary",
        },
      ],
      [permitHolderStates.rejected]: [inactivateOperation, editOperation],
      [permitHolderStates.inactivated]: [
        {
          id: operationsMap.sendToAnalysis,
          label: t("10439") /* Enviar para análise */,
          action: sendToAnalysis,
          color: "secondary",
        },
        editOperation,
      ],
      [permitHolderStates.toApproval]: approvedOperations,
      [permitHolderStates.provisoryApproved]: approvedOperations,
      [permitHolderStates.approvedWithChanges]: approvedOperations,
      [permitHolderStates.approved]: approvedOperations,
    }[state.id];
  }, [
    makeEditableFromApprovedStates,
    makeEditableFromUnderElaborationState,
    inactivate,
    sendToAnalysis,
    makeEditable,
    state,
    isEditable,
    hasWarnings,
  ]);

  return operations;
};

export default useOperations;
