import CancelButton from "components/buttons/CancelButton";
import FlexBox from "components/FlexBox";
import ATMPaymentBox from "domain/private/components/paymentMethods/ATMPaymentBox";
import BalancePaymentBox from "domain/private/components/paymentMethods/BalancePaymentBox";
import MbWayPaymentBox from "domain/private/components/paymentMethods/MbWayPaymentBox";
import useLanguage from "hooks/useLanguage";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { convertFromCents, convertToCents, formatMoney } from "utils/money";
import { paymentMethodsMapping, paymentTypesMapping } from "utils/payment";
import useSubscriptionPaymentMethodsSize, {
  MIN_PIXELS_TO_DISPLAY_PAYMENTS_INLINE,
} from "../../hooks/useSubscriptionPaymentMethodsSize";
import useSubscriptionsOperations from "../../hooks/useSubscriptionsOperations";
import { usePermitHoldersDetailsContext } from "../../Provider";

const PaymentMethods = ({ subscriptionId, amount, closeDetails }) => {
  const {
    subscriptionsTab: {
      paymentMethods,
      list: { update },
    },
  } = usePermitHoldersDetailsContext();
  const [atmPaymentDetails, setAtmPaymentDetails] = useState();
  const language = useLanguage();
  const { t } = useTranslation();
  const { size } = useSubscriptionPaymentMethodsSize();
  const {
    payWithBalance,
    generateAtmPayment,
    generateMbWayPayment,
  } = useSubscriptionsOperations();

  const amountToPay = convertFromCents(amount);

  const generateAtmPaymentHandler = useCallback(async () => {
    setAtmPaymentDetails({
      isLoading: true,
    });

    const details = await generateAtmPayment(subscriptionId, {
      paymentFormId: paymentMethodsMapping.atm.id,
    });

    if (!details) {
      setAtmPaymentDetails({
        isLoading: false,
      });
      return;
    }

    setAtmPaymentDetails({
      amount: convertToCents(details.amount),
      bankEntity: details.bankEntityNumber,
      reference: details.reference,
      isLoading: false,
      deadline: details.deadline,
    });
  }, [subscriptionId]);

  const localOnPayWithBalance = useCallback(async () => {
    const result = await payWithBalance(subscriptionId);

    if (result) {
      closeDetails();
    }
  }, [closeDetails, payWithBalance]);

  const generaMbWayPaymentHandler = useCallback(
    async (params) => {
      const paymentId = await generateMbWayPayment(subscriptionId, {
        ...params,
        paymentFormId: paymentMethodsMapping.mbway.id,
      });

      return paymentId;
    },
    [subscriptionId]
  );

  const onMbWayPaymentSuccess = useCallback(async () => {
    await update();

    closeDetails();
  }, [update, closeDetails]);

  const PaymentMethodsList = useMemo(
    () =>
      paymentMethods.map((paymentMethod) => {
        if (!paymentMethod.isActive) {
          return null;
        }

        if (paymentMethod.id === paymentMethodsMapping.atm.id) {
          return (
            <ATMPaymentBox
              key="atm-payment"
              longMessage={paymentMethod.longMessage}
              onClick={generateAtmPaymentHandler}
              title={paymentMethod.name}
              size={size}
              {...atmPaymentDetails}
            />
          );
        }

        if (paymentMethod.id === paymentMethodsMapping.mbway.id) {
          return (
            <MbWayPaymentBox
              key="mbway-payment"
              title={paymentMethod.name}
              shortMessage={paymentMethod.shortMessage}
              longMessage={paymentMethod.longMessage}
              onGeneratePayment={generaMbWayPaymentHandler}
              onSuccess={onMbWayPaymentSuccess}
              amount={amountToPay}
              itemTypeId={paymentTypesMapping.subscriptionPayment}
              size={size}
            />
          );
        }

        if (paymentMethod.id === paymentMethodsMapping.balance.id) {
          return (
            <BalancePaymentBox
              key="balance-payment"
              handlePay={localOnPayWithBalance}
              amount={amountToPay}
              size={size}
              extraInfo={
                <>
                  <StyleText className="inline" variant="body4">
                    <Trans
                      i18nKey="10522"
                      values={{
                        value: `<b>${formatMoney(amountToPay, {
                          centsFormat: false,
                          country: language,
                        })}</b>`,
                        interpolation: { escapeValue: false },
                      }}
                      components={{ b: <b /> }}
                    />
                    {/* Estas prestes a pagar uma subscrição de {{ value }} com o teu saldo
                  de condutor iParque. */}
                  </StyleText>
                </>
              }
            />
          );
        }

        return null;
      }),
    [paymentMethods, size, amountToPay, localOnPayWithBalance, atmPaymentDetails]
  );

  return (
    <FlexBox className="fill" flexDirection="column">
      <Text className="mt-15 mb-10" variant="body4">
        {t("10586") /* Escolhe o método de pagamento: */}
      </Text>
      <PaymentMethodsContainer>{PaymentMethodsList}</PaymentMethodsContainer>
      <FlexBox justifyContent="flexEnd" className="mt-30">
        <CancelButton onClick={closeDetails}>{t("48") /* Cancelar */}</CancelButton>
      </FlexBox>
    </FlexBox>
  );
};

PaymentMethods.propTypes = {
  closeDetails: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  subscriptionId: PropTypes.number.isRequired,
};

export default PaymentMethods;

const PaymentMethodsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (min-width: ${MIN_PIXELS_TO_DISPLAY_PAYMENTS_INLINE}px) {
    flex-direction: row;
  }

  .payment-method-box {
    flex: 1;
    width: 100%;
    margin-top: 10px;

    @media (min-width: ${MIN_PIXELS_TO_DISPLAY_PAYMENTS_INLINE}px) {
      width: 200px;
      margin-top: 20px;

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
`;

const StyleText = styled(Text)`
  b {
    white-space: nowrap;
  }
`;
