import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/front.svg";
import {
  calculateApproximateHeight,
  calculateVerticalPadding,
  LEVEL_MARGIN,
  LINE_HEIGHT,
  TOGGLE_ANIMATION_SECONDS,
} from "./helper";

const MIN_WIDTH = 300;

const DropdownList = ({ items, level, selectedItem, className }) => {
  const newLevel = level + 1;

  const getIdentifier = ({ label, id }) => id || label;

  const getSelectedItemIdentifier = (checkedItems, selectedItemId) => {
    if (!selectedItem || !checkedItems?.length) {
      return null;
    }

    for (let index = 0; index < checkedItems.length; index += 1) {
      const item = checkedItems[index];

      if (
        getIdentifier(item) === selectedItemId ||
        getSelectedItemIdentifier(item.items, selectedItemId)
      ) {
        return getIdentifier(item);
      }
    }

    return null;
  };

  const [displayedSubItems, setDisplayedSubItems] = useState(
    (() => {
      const selectedItemIdentifier = getSelectedItemIdentifier(items, selectedItem);

      return selectedItem && selectedItemIdentifier
        ? { [selectedItemIdentifier]: true }
        : {};
    })()
  );

  useEffect(() => {
    if (!selectedItem) {
      return;
    }

    const selectedItemIdentifier = getSelectedItemIdentifier(items, selectedItem);

    if (!selectedItemIdentifier) {
      return;
    }

    setDisplayedSubItems({
      [selectedItemIdentifier]: true,
    });
  }, [selectedItem, items]);

  const onItemClick = ({ label, id, onClick, items: itemItems }) => {
    const identifier = getIdentifier({ label, id });

    if (itemItems?.length) {
      setDisplayedSubItems({
        ...displayedSubItems,
        [identifier]: !displayedSubItems[identifier],
      });
    }

    onClick?.(identifier);
  };

  const supportsAnimation = (checkedItems) => {
    if (!checkedItems?.length) {
      return true;
    }

    for (let index = 0; index < checkedItems.length; index += 1) {
      const item = checkedItems[index];

      if (item.items?.length || !supportsAnimation(item.items)) {
        return false;
      }
    }

    return true;
  };

  return (
    <Container level={newLevel} className={className}>
      {items.map((item) => (
        <ItemContainer level={newLevel} key={getIdentifier(item)}>
          <ClickableItemContainer
            clickable={item.items || !!item.onClick}
            onClick={() => onItemClick(item)}
            level={newLevel}
          >
            <StyledText selected={getIdentifier(item) === selectedItem} level={newLevel}>
              {item.label}
            </StyledText>
            {item.items && <Arrow $isOpen={displayedSubItems[getIdentifier(item)]} />}
          </ClickableItemContainer>
          <SubItemContainer
            $isOpen={item.items && displayedSubItems[getIdentifier(item)]}
            supportsAnimation={supportsAnimation(item.items)}
            level={newLevel}
            itemsNumber={item.items?.length || 0}
          >
            <DropdownList
              level={newLevel}
              items={item.items || []}
              selectedItem={selectedItem}
            />
          </SubItemContainer>
        </ItemContainer>
      ))}
    </Container>
  );
};

export default DropdownList;

DropdownList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      onClick: PropTypes.func,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          onClick: PropTypes.func,
          items: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string.isRequired,
              id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
              onClick: PropTypes.func,
            })
          ),
        })
      ),
    })
  ).isRequired,
  level: PropTypes.number,
  className: PropTypes.string,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DropdownList.defaultProps = {
  level: -1,
  className: undefined,
  selectedItem: undefined,
};

const Container = styled.div`
  flex: 1;
  min-width: ${({ level }) => MIN_WIDTH - level * LEVEL_MARGIN}px;
  display: flex;
  flex-direction: column;
  margin-left: ${({ level }) => (level > 0 ? LEVEL_MARGIN : 0)}px;
  padding-bottom: ${({ level }) => calculateVerticalPadding(level)}px;
`;

const ItemContainer = styled.div`
  :not(:last-of-type) {
    border-bottom: ${({ theme, level }) =>
      `${level === 0 ? 2 : 1}px solid  ${theme.dropdownList.border}`};
  }

  :last-of-type {
    border-bottom: ${({ theme, level }) =>
      `${level === 0 ? 2 : 0}px solid  ${theme.dropdownList.border}`};
  }
`;

const ClickableItemContainer = styled.div`
  padding: ${({ level }) => calculateVerticalPadding(level)}px 0;
  display: flex;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "auto")};
  align-items: center;
`;

const Arrow = styled(ArrowIcon)`
  cursor: pointer;
  fill: ${({ theme }) => theme.dropdownList.arrowColor};
  transform: ${({ $isOpen }) => ($isOpen ? `rotate(-90deg)` : `rotate(90deg)`)};
  transition: transform ${TOGGLE_ANIMATION_SECONDS}s ease;
  height: 20px;
  margin-left: auto;
  margin-right: 10px;
`;

const SubItemContainer = styled.div`
  display: flex;
  overflow: hidden;
  ${({ supportsAnimation }) =>
    supportsAnimation
      ? css`
          transition: max-height ${TOGGLE_ANIMATION_SECONDS}s ease-in-out;
          max-height: ${({ $isOpen, level, itemsNumber }) =>
            $isOpen ? `${calculateApproximateHeight(level, itemsNumber)}px` : 0};
        `
      : css`
          display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
        `}
`;

const StyledText = styled(Text).attrs({ variant: "body6" })`
  font-weight: ${({ level, selected, theme }) =>
    level === 0 || selected
      ? theme.typography.weight.bold
      : theme.typography.weight.regular};
  line-height: ${LINE_HEIGHT}px;
`;
