import Button from "components/buttons/Button";
import ContentBorderBox from "domain/private/components/ContentBorderBox";
import ContentHeader from "domain/private/components/ContentHeader";
import { Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import Requirements from "../components/Requirements";
import useApprovedList from "../hooks/useApprovedList";
import useOnClickCreate from "../hooks/useOnClickCreate";
import useRequirementsUtils from "../hooks/useRequirementsUtils";
import useUnapprovedList from "../hooks/useUnapprovedList";
import ApprovedList from "./components/ApprovedList";
import UnapprovedList from "./components/UnapprovedList";

const PermitHoldersListLargeDevicesPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { shouldDisplayRequirements } = useRequirementsUtils();
  const { shouldDisplayApprovedList } = useApprovedList();
  const { isEmptyListDisplayed } = useUnapprovedList();
  const { onClickNewPermitHolder } = useOnClickCreate();

  return (
    <>
      <ContentHeader
        topTitle={t("10416") /* Precisas de estacionar? */}
        bottomTitle={t("10574") /* Não te preocupes! Podes criar aqui o teu dístico */}
      />
      <ContentBorderBox
        cornerColor={theme.permitHolders.unapprovedBoxCorner}
        textColor={theme.permitHolders.unapprovedBoxCorner}
        className="mt-40"
        title={t("10418") /* Processos por aprovar */}
        topRightCornerContent={
          !shouldDisplayRequirements && isEmptyListDisplayed === false ? (
            <Button onClick={onClickNewPermitHolder}>
              {t("10577") /* Novo dístico */}
            </Button>
          ) : undefined
        }
      >
        {shouldDisplayRequirements ? <Requirements /> : <UnapprovedList />}
      </ContentBorderBox>
      {shouldDisplayApprovedList && (
        <>
          <Text variant="body9" className="uppercase mt-30 mb-10">
            {t("10578") /* Dísticos aprovados */}
          </Text>
          <ApprovedList />
        </>
      )}
    </>
  );
};

export default PermitHoldersListLargeDevicesPage;
