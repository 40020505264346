export const TOGGLE_ANIMATION_SECONDS = 0.3;
export const LINE_HEIGHT = 18;
export const LEVEL_MARGIN = 20;

export const calculateVerticalPadding = (level) => {
  if (level === 0) {
    return 20;
  }

  return level === 1 ? 10 : 7;
};

export const calculateApproximateHeight = (level, itemsNumber) => {
  const paddingHeight = calculateVerticalPadding(level) * 2;

  return itemsNumber * (paddingHeight + LINE_HEIGHT);
};
