import moment from "moment";

const MOMENT_SUNDAY = 0;
const IPARQUE_SUNDAY = 7;

export const feeTypesMapping = {
  minutes: 1,
  daily: 2,
  weekly: 3,
  monthly: 4,
};

export const feeCalculationTypes = {
  minute: 1,
  interval: 2,
};

const getDayPeriods = (feeTimeTable, day) => {
  for (let index = 0; index < feeTimeTable.length; index += 1) {
    const daySchedule = feeTimeTable[index];

    if (daySchedule.weekDay.id === day) {
      return daySchedule.periods;
    }
  }

  return [];
};

const convertToIparqueDay = (day) => {
  return day === MOMENT_SUNDAY ? IPARQUE_SUNDAY : day;
};

export const getTodayPeriods = (feeTimeTable) => {
  return getDayPeriods(feeTimeTable, convertToIparqueDay(moment().day()));
};

export const getMergedPeriods = (timeTables) => {
  const periods = timeTables
    .map((timeTable) =>
      timeTable.periods.map((period) => ({ start: period.start, end: period.end }))
    )
    .flat();

  if (periods.length === 1) {
    return periods;
  }

  const mergedPeriods = [];

  for (let index = 0; index < periods.length; index += 1) {
    let currentPeriod = periods[index];
    let nextPeriod = periods[index + 1];

    const { start } = currentPeriod;

    while (
      currentPeriod.end.hours === nextPeriod?.start.hours &&
      currentPeriod.end.minutes === nextPeriod.start.minutes
    ) {
      index += 1;
      currentPeriod = periods[index];
      nextPeriod = periods[index + 1];
    }

    mergedPeriods.push({ start, end: periods[index].end });
  }

  return mergedPeriods;
};

const padTimeUnit = (timeUnit) => `${timeUnit}`.padStart(2, "0");

export const getReadablePeriods = (fees) => {
  const minutesFee = fees?.find((fee) => fee.id === feeTypesMapping.minutes);

  if (!minutesFee) {
    return "";
  }

  const mergedPeriods = getMergedPeriods(minutesFee.timeTables);

  return mergedPeriods
    .map((period) => {
      const startHours = padTimeUnit(period.start.hours);
      const startMinutes = padTimeUnit(period.start.minutes);
      const endHours = padTimeUnit(period.end.hours);
      const endMinutes = padTimeUnit(period.end.minutes);

      return `${startHours}:${startMinutes} - ${endHours}:${endMinutes}`;
    })
    .join(", ");
};
