import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as VerticalDots } from "assets/icons/vertical-dots.svg";
import Button from "components/buttons/Button";
import IconButton from "domain/private/components/IconButton";
import IconButtonPopover from "domain/private/components/IconButton/Popover";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styled from "styled-components";
import { operationsMap } from "../../helper";
import useOperations from "../../hooks/useOperations";

const TabDetailsOperations = ({ className }) => {
  const operations = useOperations();

  const operationsWithoutEdit = useMemo(
    () => operations.filter((operation) => operation.id !== operationsMap.edit),
    [operations]
  );

  const editOperation = useMemo(
    () => operations.find((operation) => operation.id === operationsMap.edit),
    [operations]
  );

  if (!editOperation && operationsWithoutEdit.length === 0) {
    return null;
  }

  return (
    <ButtonsContainer className={className}>
      {editOperation && (
        <IconButton
          className="mr-10"
          color={editOperation.color}
          onClick={editOperation.action}
          Icon={<EditIcon />}
        />
      )}
      {operationsWithoutEdit.length > 0 && (
        <IconButtonPopover
          color="quaternary"
          Icon={<VerticalDots />}
          PopoverComponent={
            <PopoverContentContainer>
              {operationsWithoutEdit.map((operation) => (
                <Button
                  key={operation.id}
                  color={operation.color}
                  onClick={operation.action}
                  className="operation-button"
                  variant="link"
                  size="sm"
                >
                  {operation.label}
                </Button>
              ))}
            </PopoverContentContainer>
          }
        />
      )}
    </ButtonsContainer>
  );
};

export default TabDetailsOperations;

TabDetailsOperations.propTypes = {
  className: PropTypes.string.isRequired,
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PopoverContentContainer = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;

  .operation-button {
    padding-left: 10px;
    justify-content: flex-start;
  }
`;
