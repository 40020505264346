import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as FaxIcon } from "assets/icons/fax.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linkedIn.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/phone.svg";
import { ReactComponent as YoutubeIcon } from "assets/icons/youtube.svg";
import { ReactComponent as IParqueLogo } from "assets/images/iparqueLogo.svg";
import useLocalizedUrls from "hooks/useLocalizedUrls";
import useOnScreen from "hooks/useOnScreen";
import { AcinSolution, Link, SelectField, Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints, breakpointsMapping } from "utils/breakpoints";
import { CERTIFICATES_URL } from "utils/configs";
import ContentContainer from "../components/ContentContainer";
import { useLanguageContext } from "./LanguageProvider";
import { NOTICE_PAYMENT_BOX_HEIGHT } from "./payNotice/PayNotice";

const Wrapper = styled(ContentContainer)`
  background-color: ${(props) => props.theme.background.color.secondary};
  padding-top: 200px;
  opacity: ${({ isOnScreen }) => (isOnScreen ? 1 : 0)};
  transition: opacity 1s 1s;
  padding-bottom: ${NOTICE_PAYMENT_BOX_HEIGHT + 10}px;

  @media (min-width: 1650px) {
    padding-bottom: 0;
  }

  @media (max-width: ${breakpoints.md}) {
    padding-top: 220px;
  }
`;

const Row = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex: 1;
  line-height: 1.8rem;

  @media (max-width: ${breakpoints.md}) {
    margin-top: 50px;
    align-self: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &&.company-address.company-address {
    line-height: 1.5rem;
  }
`;

const ColumnRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const IconLabelContainer = styled.div`
  display: flex;
  align-items: center;

  .footer-icon {
    margin-right: 5px;
    width: 16px;
    fill: ${(props) => props.theme.color.dark};
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 10px;
`;

const StyledSelect = styled(SelectField)`
  width: 80%;
  padding-top: 0;
  font-size: 14px;
  min-width: 200px;

  .custom-select-value {
    font-family: ${(props) => props.theme.typography.text.variant.body6.font};
    font-weight: ${(props) => props.theme.typography.text.variant.body6.weight};
  }

  .custom-select-options {
    font-family: ${(props) => props.theme.typography.text.variant.body6.font};
  }
`;

const StyledLogo = styled(IParqueLogo)`
  fill: ${(props) => props.theme.background.color.primary};
  width: 90px;
  height: 80px;

  .logo-name {
    fill: ${(props) => props.theme.color.darkest};
  }
`;

const SocialIconsWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 60px;

  @media (max-width: ${breakpoints.md}) {
    margin: 30px 0 0;
  }

  > * {
    > svg {
      width: 20px;
      height: 20px;
    }

    :not(:first-child) {
      margin-left: 20px;
    }
  }
`;

const CenterContainer = styled.div`
  margin-top: 60px;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledArrowIcon = styled(ArrowIcon)`
  cursor: pointer;
  margin-top: 20px;
  fill: ${(props) => props.theme.typography.text.color.quaternary};
  transition: transform 0.5s ease;
  transform: ${(props) => (props.$isAddressOpen ? `rotate(180deg)` : ``)};
  ${(props) => props.$isAddressOpen && `margin-bottom: 60px`};
`;

const AddressesRow = styled.div`
  display: ${(props) => (props.isAddressOpen ? "flex" : "none")};
  margin-bottom: 50px;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`;

const PolicyContainer = styled.div`
  margin-bottom: 30px;
`;

const AddressMapLinkWrapper = styled.div`
  margin-top: 20px;
`;

const LargeDevicesAcinSolution = styled(AcinSolution)`
  @media (max-width: calc(${breakpoints.md} - 1px)) {
    display: none;
  }
`;

const SmallDevicesAcinSolution = styled(AcinSolution)`
  margin-bottom: 30px;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const CallCostText = styled(Text)`
  max-width: 250px;
  line-height: 1.5em;
  margin-left: 20px;
  margin-bottom: 5px;

  @media (max-width: ${breakpoints.md}) {
    max-width: 260px;
  }
`;

const ContactsText = styled(Text)`
  max-width: 250px;
  line-height: 1.5em;
  margin-bottom: 20px;
`;

const allowedBreakpointsToDisplayMenu = [
  breakpointsMapping.xl,
  breakpointsMapping.lg,
  breakpointsMapping.noBreakpoint,
];

const intersectionOptions = { unobserveOnIntersection: true };

const Footer = ({ breakpoint }) => {
  const { t } = useTranslation();
  const [isAddressOpen, setIsAddressOpen] = useState(false);
  const { languages, onChangeLanguage, language } = useLanguageContext();
  const {
    privacyPolicyUrl,
    termsAndConditionsUrl,
    securityPolicyUrl,
  } = useLocalizedUrls();
  const containerRef = useRef(null);
  const isOnScreen = useOnScreen(containerRef, intersectionOptions);

  const localOnChangeLanguage = useCallback(
    (event) => onChangeLanguage(event.target.value),
    [onChangeLanguage]
  );

  return (
    <Wrapper isOnScreen={isOnScreen}>
      <Row ref={containerRef}>
        <Column>
          <Link
            underline="always"
            target="_blank"
            href={process.env.REACT_APP_IPARQUE_URL}
            rel="noopener"
          >
            <StyledLogo
              role="img"
              alt={t("10661") /* Logo da solução de gestão de estacionamento */}
              title={t("10661") /* Logo da solução de gestão de estacionamento */}
            />
          </Link>
          <SocialIconsWrapper>
            <Link
              underline="always"
              target="_blank"
              aria-label="LinkedIn"
              href="https://www.linkedin.com/showcase/iparque-smart-parking/"
              rel="noopener"
            >
              <LinkedInIcon
                role="img"
                aria-labelledby={t("10659") /* Linkedin logo */}
                title={t("10659") /* Linkedin logo */}
              />
            </Link>
            <Link
              underline="always"
              target="_blank"
              aria-label="Youtube"
              href="https://www.youtube.com/user/iParqueMS"
              rel="noopener"
            >
              <YoutubeIcon
                role="img"
                aria-labelledby={t("10660") /* Youtube logo */}
                title={t("10660") /* Youtube logo */}
              />
            </Link>
          </SocialIconsWrapper>
          <LargeDevicesAcinSolution translation={t("10551") /* Uma solução */} />
        </Column>
        {allowedBreakpointsToDisplayMenu.includes(breakpoint) && (
          <div>
            <Column>
              <TitleWrapper>
                <Text className="footer-column-title" variant="body6" color="quaternary">
                  {t("7818") /* Menu */}
                </Text>
              </TitleWrapper>
              <Text variant="body7" color="secondary">
                <Link
                  underline="always"
                  target="_blank"
                  href={process.env.REACT_APP_IPARQUE_URL}
                  rel="noopener"
                >
                  {t("5873") /* iParque */}
                </Link>
              </Text>
              <Text variant="body7" color="secondary">
                <Link
                  underline="always"
                  target="_blank"
                  href={CERTIFICATES_URL}
                  rel="noopener"
                >
                  {t("2261") /* Certificações */}
                </Link>
              </Text>
              <Text variant="body7" color="secondary">
                <Link
                  target="_blank"
                  underline="always"
                  href="https://www.acin.pt/"
                  rel="noopener"
                >
                  ACIN Group
                </Link>
              </Text>
              <Text variant="body7" color="secondary">
                <Link
                  underline="always"
                  target="_blank"
                  href="https://www.livroreclamacoes.pt"
                  rel="noopener"
                >
                  {t("7851") /* Elogios, Sugestões e Reclamações */}
                </Link>
              </Text>
            </Column>
          </div>
        )}
        <Column>
          <ColumnRightWrapper>
            <div>
              {allowedBreakpointsToDisplayMenu.includes(breakpoint) && (
                <TitleWrapper>
                  <Text variant="body6" color="quaternary">
                    {t("7850") /* Fala connosco */}
                  </Text>
                </TitleWrapper>
              )}
              <ContactsText variant="body7" color="secondary">
                {
                  t(
                    "11609"
                  ) /* Estamos ao seu dispor, nos dias úteis, das 9h00 às 18h00. */
                }
              </ContactsText>
              <IconLabelContainer>
                <PhoneIcon
                  className="footer-icon"
                  role="img"
                  aria-labelledby={t("10662") /* Icon telefone */}
                  title={t("10662") /* Icon telefone */}
                />
                <Text variant="body7" color="secondary">
                  <Link underline="always" href="tel:707 451 451">
                    707 451 451
                  </Link>
                </Text>
              </IconLabelContainer>
              <CallCostText variant="body9" color="secondary">
                {
                  t(
                    "11495"
                  ) /* Preço máximo a pagar por minuto: 0,09€ (+IVA) para as chamadas originadas nas redes fixas e 0,13€ (+IVA) para as originadas nas redes móveis. */
                }
              </CallCostText>
              <IconLabelContainer>
                <EmailIcon
                  className="footer-icon"
                  role="img"
                  aria-labelledby={t("10663") /* Icon email */}
                  title={t("10663") /* Icon email */}
                />
                <Text variant="caption1" color="secondary">
                  <Link underline="always" href="mailto:apoio@iparque.pt">
                    apoio@iparque.pt
                  </Link>
                </Text>
              </IconLabelContainer>
              <IconLabelContainer>
                <FaxIcon
                  className="footer-icon"
                  role="img"
                  aria-labelledby={t("10664") /* Icon fax */}
                  title={t("10664") /* Icon fax */}
                />
                <Text variant="caption1" color="secondary">
                  +351 291 957 171
                </Text>
              </IconLabelContainer>
              <StyledSelect
                name="language"
                value={language}
                onChange={localOnChangeLanguage}
                options={languages}
                className="mt-20"
              />
            </div>
          </ColumnRightWrapper>
        </Column>
      </Row>
      <Row>
        <CenterContainer>
          <Text className="footer-column-title" variant="body6" color="quaternary">
            {t("7817") /* Onde Estamos */}
          </Text>
          <StyledArrowIcon
            $isAddressOpen={isAddressOpen}
            onClick={() => setIsAddressOpen(!isAddressOpen)}
          />
        </CenterContainer>
      </Row>
      <AddressesRow isAddressOpen={isAddressOpen}>
        <Column className="company-address">
          <TitleWrapper>
            <Text className="footer-column-title" variant="body6" color="secondary">
              {t("3684") /* Porto */}
            </Text>
          </TitleWrapper>
          <Text variant="body7" color="secondary">
            ACIN iCloud Solutions
          </Text>
          <Text variant="body7" color="secondary">
            Tecmaia
          </Text>
          <Text variant="body7" color="secondary">
            Rua Eng. Frederico Ulrich, 2650
          </Text>
          <Text variant="body7" color="secondary">
            4470-605 - Maia
          </Text>
          <AddressMapLinkWrapper>
            <Text variant="body7" color="secondary">
              <Link
                href="https://www.google.pt/maps/place/ACIN+-+iCloud+Solutions+-+Porto/@41.2587977,-8.6435667,17z/data=!3m1!4b1!4m5!3m4!1s0xd2467e8569a452f:0xfb5ca1114f938fda!8m2!3d41.2587937!4d-8.641378"
                underline="always"
                target="_blank"
                rel="noopener"
              >
                {t("7133") /* Ver no mapa */}
              </Link>
            </Text>
          </AddressMapLinkWrapper>
        </Column>
        <Column className="company-address">
          <TitleWrapper>
            <Text className="footer-column-title" variant="body6" color="secondary">
              {t("3488") /* Lisboa */}
            </Text>
          </TitleWrapper>
          <Text variant="body7" color="secondary">
            ACIN iCloud Solutions
          </Text>
          <Text variant="body7" color="secondary">
            Rua Domingos Monteiro
          </Text>
          <Text variant="body7" color="secondary">
            Nº 7 A
          </Text>
          <Text variant="body7" color="secondary">
            1050-074 Lisboa
          </Text>
          <AddressMapLinkWrapper>
            <Text variant="body7" color="secondary">
              <Link
                href="https://www.google.com/maps/place/ACIN+-+iCloud+Solutions+-+Lisboa/@38.7446805,-9.1487847,17z/data=!3m1!4b1!4m5!3m4!1s0xd1931fa7f89f40b:0xde35c286b3855019!8m2!3d38.7446763!4d-9.146596"
                underline="always"
                target="_blank"
                rel="noopener"
              >
                {t("7133") /* Ver no mapa */}
              </Link>
            </Text>
          </AddressMapLinkWrapper>
        </Column>
        <Column className="company-address">
          <TitleWrapper>
            <Text className="footer-column-title" variant="body6" color="secondary">
              {t("6069") /* Madeira */}
            </Text>
          </TitleWrapper>
          <Text variant="body7" color="secondary">
            ACIN iCloud Solutions
          </Text>
          <Text variant="body7" color="secondary">
            Estrada Regional, 104 Nº 42-A
          </Text>
          <Text variant="body7" color="secondary">
            9350-203
          </Text>
          <Text variant="body7" color="secondary">
            Ribeira Brava - Madeira
          </Text>
          <AddressMapLinkWrapper>
            <Text variant="body7" color="secondary">
              <Link
                href="https://www.google.pt/maps/place/ACIN+-+iCloud+Solutions/@32.679014,-17.0621327,17z/data=!3m1!4b1!4m5!3m4!1s0xc6059a3d538596b:0xa393204f0d195615!8m2!3d32.679014!4d-17.059944"
                underline="always"
                target="_blank"
                rel="noopener"
              >
                {t("7133") /* Ver no mapa */}
              </Link>
            </Text>
          </AddressMapLinkWrapper>
        </Column>
        <Column className="company-address">
          <TitleWrapper>
            <Text className="footer-column-title" variant="body6" color="secondary">
              {t("6070") /* Açores */}
            </Text>
          </TitleWrapper>
          <Text variant="body7" color="secondary">
            ACIN iCloud Solutions
          </Text>
          <Text variant="body7" color="secondary">
            Rua da Alegria, nº 101
          </Text>
          <Text variant="body7" color="secondary">
            9500-157
          </Text>
          <Text variant="body7" color="secondary">
            Ponta Delgada - Açores
          </Text>
          <AddressMapLinkWrapper>
            <Text variant="body7" color="secondary">
              <Link
                underline="always"
                href="https://www.google.pt/maps/place/ACIN+-+iCloud+Solutions+-+A%C3%A7ores/@37.736978,-25.6785327,17z/data=!3m1!4b1!4m5!3m4!1s0xb432bb9e38b6363:0x4f9bff9f6e7e0837!8m2!3d37.736978!4d-25.676344"
                target="_blank"
                rel="noopener"
              >
                {t("7133") /* Ver no mapa */}
              </Link>
            </Text>
          </AddressMapLinkWrapper>
        </Column>
      </AddressesRow>
      <Row>
        <CenterContainer>
          <SmallDevicesAcinSolution translation={t("10551") /* Uma solução */} />
          <Text variant="body7" color="secondary">
            {`© ${t("5873") /* iParque */} ${new Date().getFullYear()} - ${
              t("6701") /* Todos os direitos reservados */
            }`}
          </Text>
          <PolicyContainer>
            <Text variant="body7" align="center" color="secondary" className="mb-30">
              <Link
                underline="always"
                target="_blank"
                href={privacyPolicyUrl}
                rel="noopener"
              >
                {t("6005") /* Política de Privacidade */}
              </Link>
              {" | "}
              <Link
                underline="always"
                target="_blank"
                href={termsAndConditionsUrl}
                rel="noopener"
              >
                {t("227") /* Termos e Condições */}
              </Link>
              {" | "}
              <Link
                underline="always"
                target="_blank"
                href={securityPolicyUrl}
                rel="noopener"
              >
                {t("9146") /* Política de Segurança das plataformas ACIN */}
              </Link>
              {" | "}
              <Link
                underline="always"
                target="_blank"
                href="https://www.livroreclamacoes.pt"
                rel="noopener"
              >
                {t("7851") /* Elogios, Sugestões e Reclamações */}
              </Link>
            </Text>
          </PolicyContainer>
        </CenterContainer>
      </Row>
    </Wrapper>
  );
};

Footer.defaultProps = {
  breakpoint: null,
};

Footer.propTypes = {
  breakpoint: PropTypes.string,
};

export default Footer;
