/* eslint-disable import/prefer-default-export */
import i18n from "i18next";
import ReactGA from "react-ga4";
import { convertHoursToMinutes, getCurrentDateInServerFormat } from "utils/dateTime";
import { gaCategory, gaEvents } from "utils/googleAnalytics";
import DriversDataSource from "../../../lib/clients/iParque/dataSources/driversDataSource";
import ParkingsDataSource from "../../../lib/clients/iParque/dataSources/parkingsDataSource";
import StreetsDataSource from "../../../lib/clients/iParque/dataSources/streetsDataSource";
import { colorsMapping } from "../../../utils/colors";
import {
  DRIVER_WITHOUT_BALANCE,
  hasErrorCode,
  LICENSE_PLATE_HAS_NOTICE,
  MAX_PARKING_DURATION_DAY_REACHED,
  MISSING_PARKING_FEES,
} from "../../../utils/error";
import {
  feeCalculationTypes,
  feeTypesMapping,
  getTodayPeriods,
} from "../../../utils/fees";
import { getEndingDate } from "../../../utils/parking";
import { errorMessage, infoMessage, warningMessage } from "../../../utils/userMessages";
import { changeIndexToLicensePlate } from "../../../utils/vehicles";

const driversDataSource = new DriversDataSource();
const parkingsDataSource = new ParkingsDataSource();
const streetsDataSource = new StreetsDataSource();

const processParking = (parking, vehicles) => {
  const vehiclesProcessed = changeIndexToLicensePlate(vehicles);

  let usedBenefit = {
    benefitId: null,
    benefitName: "",
    benefitTypeId: null,
    benefitValue: null,
  };

  if (parking.benefits[0]) {
    usedBenefit = {
      benefitId: parking.benefits[0].parkingBenefit.id,
      benefitName: parking.benefits[0].parkingBenefit.name || null,
      benefitTypeId: parseInt(parking.benefits[0].parkingBenefit.type.id, 10),
      benefitValue: parking.benefits[0].duration,
    };
  }

  return {
    id: parking.id,
    vehicleLicensePlate: parking.licensePlate,
    streetName: parking.street.name,
    streetId: parking.street.id,
    explorationName: parking.street.zone.exploration.name,
    amount: parking.amount,
    parkingTypeId: parking.parkingTypeId,
    parkingPaymentTypeId: parking.parkingPaymentTypeId,
    feeType: parking.feeType,
    startingDate: parking.startingDate,
    endingDate: getEndingDate(
      parking.parkingTypeId,
      parking.stateId,
      parking?.endingDate
    ),
    vehicleBrand:
      vehiclesProcessed[parking.licensePlate]?.model?.brand?.name ||
      i18n.t("6332") /* Desconhecido */,
    vehicleColorId:
      vehiclesProcessed[parking.licensePlate]?.colorId || colorsMapping.gray,
    ...usedBenefit,
    scheduleExtraInfo: parking.scheduleExtraInfo,
  };
};

const getTimeUnits = (time) => {
  const [hours, minutes] = time.split(":").map((unit) => parseInt(unit, 10));

  return { hours, minutes };
};

const compareTimeTables = (firstTimeTable, secondTimeTable) =>
  convertHoursToMinutes(firstTimeTable.periods[0].start.hours) +
  firstTimeTable.periods[0].start.minutes -
  convertHoursToMinutes(secondTimeTable.periods[0].start.hours) +
  secondTimeTable.periods[0].start.minutes;

const sortFees = (fees) => {
  const minutesFeeIndex = fees.findIndex((fee) => fee.id === feeTypesMapping.minutes);

  if (minutesFeeIndex !== -1) {
    // eslint-disable-next-line no-param-reassign
    fees[minutesFeeIndex].timeTables = fees[minutesFeeIndex].timeTables.sort(
      compareTimeTables
    );
  }

  return fees;
};

const processFees = (fees) => {
  const response = [];
  const timeTables = [];

  fees.forEach((fee) => {
    fee.fee.types.forEach((feeType) => {
      if (
        feeType.id === feeTypesMapping.weekly ||
        feeType.id === feeTypesMapping.monthly
      ) {
        response.push(feeType);
        return;
      }

      if (feeType.id === feeTypesMapping.daily) {
        response.push({
          ...feeType,
          beginTime: getTimeUnits(feeType.beginTime),
          endTime: getTimeUnits(feeType.endTime),
        });
        return;
      }

      const processedPeriods = getTodayPeriods(fee.timetable).map((period) => ({
        start: getTimeUnits(period.start),
        end: getTimeUnits(period.end),
      }));

      const firstInterval = feeType.intervals[0];
      const maxDuration = feeType.intervals[feeType.intervals.length - 1].to;

      let minDuration = 1;

      if (firstInterval.feeCalculationTypeId === feeCalculationTypes.interval) {
        minDuration = firstInterval.to;
      }

      if (processedPeriods.length) {
        timeTables.push({
          periods: processedPeriods,
          intervals: feeType.intervals,
        });
      }

      // eslint-disable-next-line no-shadow
      if (!response.find((fee) => fee.id === feeTypesMapping.minutes)) {
        response.push({
          id: feeType.id,
          name: feeType.name,
          description: feeType.description,
          timeTables,
          minDuration,
          maxDuration,
        });
      }
    });
  });

  return sortFees(response);
};

export const getParking = async (
  entityId,
  authHash,
  parkingId,
  { parkingInfoCancelToken, vehiclesCancelToken }
) => {
  try {
    const [vehiclesResponse, parkingResponse] = await Promise.all([
      driversDataSource.getVehicles(
        authHash,
        { noLimit: true, fillCollections: "model" },
        {},
        true,
        vehiclesCancelToken
      ),
      driversDataSource.getParkingById(
        entityId,
        authHash,
        parkingId,
        {
          fillCollections: ["street", "parkingBenefit", "feeType"],
          avoidLicensePlateValidation: true,
        },
        {},
        false,
        parkingInfoCancelToken
      ),
    ]);

    const vehicles = vehiclesResponse.items;
    const parking = parkingResponse;

    return processParking(parking, vehicles);
  } catch (error) {
    errorMessage(
      error,
      i18n.t("8119") /* Ocorreu um erro ao obter os detalhes do estacionamento */
    );
    return null;
  }
};

export const calculateParking = async (entityId, streetId, params) => {
  try {
    const response = await parkingsDataSource.calculate(entityId, streetId, params);

    return {
      amount: response.amount,
      duration: response.duration,
      endingDate: response.endingDate,
    };
  } catch (error) {
    if (hasErrorCode(error, LICENSE_PLATE_HAS_NOTICE)) {
      warningMessage(
        i18n.t(
          "9703"
        ) /* Não é possível iniciar o estacionamento. O teu veículo possui infrações. */
      );

      return null;
    }

    if (hasErrorCode(error, MAX_PARKING_DURATION_DAY_REACHED)) {
      infoMessage(
        i18n.t(
          "8451"
        ) /* Atingiste a duração máxima diária de estacionamento nesta rua. */
      );

      return null;
    }

    if (hasErrorCode(error, DRIVER_WITHOUT_BALANCE)) {
      warningMessage(
        i18n.t("8454") /* O teu saldo é insuficiente para a duração escolhida. */
      );

      return null;
    }

    errorMessage(
      error,
      i18n.t("8169") /* Ocorreu um erro ao calcular o montante do estacionamento */
    );

    return null;
  }
};

export const getFees = async (
  entityId,
  streetId,
  { setIsLoading, date = getCurrentDateInServerFormat() }
) => {
  setIsLoading(true);

  try {
    const response = await streetsDataSource.getFees(
      entityId,
      streetId,
      {
        fillCollections: "all",
      },
      false
    );

    if (!response.items[0].fee.types.length) {
      warningMessage(
        i18n.t("8045") /* Não existem tarifas disponíveis para a rua selecionada */
      );

      return null;
    }

    const dayFees = response.items.filter(
      (fee) =>
        fee.startingDate.split(" ")[0] <= date && fee.endingDate.split(" ")[0] >= date
    );

    if (!dayFees.length) {
      warningMessage(
        i18n.t("8045") /* Não existem tarifas disponíveis para a rua selecionada */
      );

      return null;
    }

    return processFees(dayFees);
  } catch (error) {
    if (hasErrorCode(error, MISSING_PARKING_FEES)) {
      return null;
    }

    errorMessage(
      error,
      i18n.t("8033") /* Ocorreu um erro ao obter os tipo de tarifa da rua */
    );

    return null;
  } finally {
    setIsLoading(false);
  }
};

export const renewParking = async (
  entityId,
  streetId,
  parkingId,
  params,
  { setIsLoading }
) => {
  setIsLoading(true);

  try {
    const response = await parkingsDataSource.renew(
      entityId,
      streetId,
      parkingId,
      params
    );

    ReactGA.event({
      category: gaCategory,
      action: gaEvents.renewParking,
    });

    return {
      id: response.parkingId,
      amount: response.amount,
      startingDate: response.startingDate,
    };
  } catch (error) {
    ReactGA.event({
      category: gaCategory,
      action: gaEvents.renewParkingRequestError,
    });

    errorMessage(error, i18n.t("9687") /* Ocorreu um erro ao renovar o estacionamento */);

    return null;
  } finally {
    setIsLoading(false);
  }
};
