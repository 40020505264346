import { useAuth } from "components/AuthProvider";
import useInfiniteList from "hooks/useInfiniteList";
import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getSubscriptions as getSubscriptionsRequest } from "../controller";
import { usePermitHoldersDetailsContext } from "../Provider";
import { actionTypes } from "../store/actions";

const useSubscriptions = () => {
  const { defaultEntityId: entityId, driverHash } = useAuth();
  const {
    id: permitHolderId,
    dispatch,
    subscriptionsTab: { list: subscriptionsTabList },
  } = usePermitHoldersDetailsContext();
  const { t } = useTranslation();

  const getSubscriptions = useCallback(
    async (params) => {
      const response = await getSubscriptionsRequest(
        entityId,
        driverHash,
        permitHolderId,
        params,
        { t }
      );

      return response;
    },
    [driverHash, entityId, permitHolderId, t]
  );

  const subscriptionsList = useInfiniteList(getSubscriptions);

  useEffect(
    () =>
      dispatch({
        type: actionTypes.SUBSCRIPTIONS_TAB.SET_LIST,
        payload: subscriptionsList,
      }),
    [subscriptionsList.items]
  );

  return subscriptionsTabList;
};

export default useSubscriptions;
