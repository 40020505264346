import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MapController from "../../../lib/map/controller";
import useGoogleMaps from "../../../hooks/useGoogleMaps";
import { useBackofficeThemeContext } from "../components/ThemeProvider";

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  height: 200px;
`;

const maxZoom = 16;

const ParkingMap = ({ id, location }) => {
  const mapContainer = `js-parkings-${id}-container`;
  const { backofficeTheme } = useBackofficeThemeContext();
  const { map, google } = useGoogleMaps(mapContainer, {
    styles: backofficeTheme.map,
    streetViewControl: false,
    gestureHandling: "none",
    maxZoom,
  });

  useEffect(() => {
    if (google && map && location) {
      const mapInstance = new MapController(google, map, null, {
        theme: backofficeTheme,
      });
      mapInstance.renderMarker(location);
      mapInstance.fitCenter();
    }
  }, [location, map, google]);

  return <MapContainer id={mapContainer} />;
};

ParkingMap.defaultProps = {
  location: null,
};

ParkingMap.propTypes = {
  id: PropTypes.number.isRequired,
  location: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
};

export default ParkingMap;
