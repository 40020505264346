import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import {
  canAddVehicles,
  emptyVehicle,
  getNextRegime,
  getPositionOfEditedVehicle,
  getVehiclesToRemove,
  getVehiclesToUpdate,
  getVehiclesWithActiveSubscriptions,
  isEditing,
} from "components/permitHolders/helpers/vehicles";
import { useBackofficeContext } from "domain/private/components/BackOfficeProvider";
import { errorToast } from "iparque-components";
import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  addPermitHolderVehicle,
  removePermitHolderVehicle,
  updatePermitHolderVehicle,
} from "requests/permitHolders";
import { cleanLicencePlate } from "utils/licencePlate";
import { infoMessage } from "utils/userMessages";
import { userTypes } from "utils/users";
import { getVehicles as getPermitHolderVehicles } from "../controller";
import { usePermitHoldersDetailsContext } from "../Provider";
import { actionTypes } from "../store/actions";
import useWarnings from "./useWarnings";

const useVehicleTabOperations = () => {
  const {
    dispatch,
    id: permitHolderId,
    token: permitHolderToken,
    reference,
    vehiclesTab: {
      vehiclesRegimes,
      activeSubscriptions,
      addedVehicles,
      initialAddedVehicles,
    },
  } = usePermitHoldersDetailsContext();
  const { t } = useTranslation();
  const { displayConfirmation } = useBackofficeContext();
  const { defaultEntityId: entityId, userId: driverId, driverHash } = useAuth();
  const { setIsLoading } = useAppContext();
  const { updateWarnings } = useWarnings();

  const displayActiveSubscriptionsWarning = useCallback(
    (vehicles, onConfirm) => {
      displayConfirmation({
        title: `${t("1848") /* Dístico */} ${reference}`,
        message: (
          <Trans
            i18nKey="10585"
            values={{
              vehicles: `<b>${vehicles}</b>`,
              interpolation: { escapeValue: false },
            }}
            components={{ b: <b /> }}
          />
        ),
        /* O(s) veículo(s) {{vehicles}} possuem subscrições ativas. Ao confirmares esta operação, as mesmas serão desativadas. Tens a certeza que pretendes continuar? */
        onConfirm,
      });
    },
    [reference]
  );

  const displayEditingWarning = useCallback(() => {
    infoMessage(
      t(
        "10260"
      ) /* Para poderes adicionar um novo veículo deves finalizar o veículo pendente */
    );

    dispatch({
      type: actionTypes.VEHICLES_TAB.FLAG_ADDED_VEHICLE,
      payload: { position: getPositionOfEditedVehicle(addedVehicles) },
    });
  }, [addedVehicles]);

  const addVehicle = useCallback(() => {
    if (!canAddVehicles(addedVehicles, vehiclesRegimes)) {
      infoMessage(t("10264") /* Não podem ser adicionados mais veículos */);
      return;
    }

    if (isEditing(addedVehicles)) {
      displayEditingWarning();
      return;
    }

    dispatch({
      type: actionTypes.VEHICLES_TAB.ADD_ADDED_VEHICLE,
      payload: {
        ...emptyVehicle,
        permitHolderVehicleId: undefined,
        regime: getNextRegime(addedVehicles, vehiclesRegimes),
      },
    });
  }, [addedVehicles, vehiclesRegimes, t, displayEditingWarning]);

  const editVehicle = useCallback(
    (position, vehicle) =>
      dispatch({
        type: actionTypes.VEHICLES_TAB.EDIT_ADDED_VEHICLE,
        payload: { position, vehicle },
      }),
    []
  );

  const makeEditable = useCallback(
    (position) => {
      if (isEditing(addedVehicles)) {
        displayEditingWarning();
        return;
      }

      dispatch({
        type: actionTypes.VEHICLES_TAB.MAKE_ADDED_VEHICLE_EDITABLE,
        payload: { position },
      });
    },
    [displayEditingWarning, addedVehicles]
  );

  const removeVehicle = useCallback(
    (position) =>
      dispatch({
        type: actionTypes.VEHICLES_TAB.REMOVE_ADDED_VEHICLE,
        payload: { position },
      }),
    []
  );

  const saveVehiclesRequests = useCallback(
    async (
      permitHolderVehiclesToRemove,
      permitHolderVehiclesToSave,
      permitHolderVehiclesToUpdate
    ) => {
      setIsLoading(true);

      const results = await Promise.all([
        ...permitHolderVehiclesToRemove.map((permitHolderVehicleToRemove) =>
          removePermitHolderVehicle(
            entityId,
            driverHash,
            permitHolderToken,
            permitHolderVehicleToRemove.id,
            {
              shouldDeactivateActiveSubscriptions: true,
              userTypeId: userTypes.client,
              userId: driverId,
            },
            { t }
          )
        ),
        ...permitHolderVehiclesToSave.map((vehicle) =>
          addPermitHolderVehicle(
            entityId,
            {
              driverHash,
              permitHolderId: permitHolderToken,
              data: {
                vehicleRegimeId: vehicle.regime.id,
                licensePlate: cleanLicencePlate(vehicle.licencePlate),
                modelId: vehicle.modelId,
                userTypeId: userTypes.client,
                countryId: vehicle.countryId,
                userId: driverId,
              },
            },
            { autoCloseError: false }
          )
        ),
        ...permitHolderVehiclesToUpdate.map((vehicle) =>
          updatePermitHolderVehicle(
            entityId,
            driverId,
            permitHolderToken,
            vehicle.permitHolderVehicleId,
            driverHash,
            {
              shouldDeactivateActiveSubscriptions: true,
              licensePlate: cleanLicencePlate(vehicle.licencePlate),
              modelId: vehicle.modelId,
              vehicleRegimeId: vehicle.regime.id,
              countryId: vehicle.countryId,
            },
            { t }
          )
        ),
      ]);

      const [permitHolderVehicles] = await Promise.all([
        getPermitHolderVehicles(
          entityId,
          driverHash,
          permitHolderId,
          { noLimit: true },
          { t }
        ),
        updateWarnings(),
      ]);

      setIsLoading(false);

      const actions = [];

      if (permitHolderVehicles) {
        actions.push({
          type: actionTypes.VEHICLES_TAB.RESET_CHANGES,
          payload: permitHolderVehicles,
        });
      }

      if (!results.every((result) => result)) {
        errorToast(
          t("3613") /* Erro */,
          t(
            "10528"
          ) /* Foram obtidos alguns erros ao guardar os dados do dístico, por favor contacta o nosso apoio ao cliente */,
          { autoClose: false }
        );

        dispatch(actions);

        return;
      }

      dispatch([...actions, { type: actionTypes.SET_IS_EDITABLE, payload: false }]);
    },
    [entityId, driverId, driverHash, permitHolderToken, updateWarnings, t]
  );

  const saveVehicles = useCallback(async () => {
    if (isEditing(addedVehicles)) {
      displayEditingWarning();

      return;
    }

    const permitHolderVehiclesToRemove = getVehiclesToRemove(
      initialAddedVehicles,
      addedVehicles
    );

    const permitHolderVehiclesToUpdate = getVehiclesToUpdate(
      initialAddedVehicles,
      addedVehicles
    );

    const permitHolderVehiclesToSave = addedVehicles.filter(
      (addedVehicle) => !addedVehicle.permitHolderVehicleId
    );

    if (
      permitHolderVehiclesToRemove.length === 0 &&
      permitHolderVehiclesToSave.length === 0 &&
      permitHolderVehiclesToUpdate.length === 0
    ) {
      dispatch({ type: actionTypes.SET_IS_EDITABLE, payload: false });
      return;
    }

    const vehiclesWithActiveSubscriptions = getVehiclesWithActiveSubscriptions(
      [
        ...permitHolderVehiclesToRemove,
        ...permitHolderVehiclesToUpdate.map((permitHolderVehicleToUpdate) => ({
          licencePlate: permitHolderVehicleToUpdate.initialLicencePlate,
        })),
      ],
      activeSubscriptions
    );

    if (vehiclesWithActiveSubscriptions.length) {
      displayActiveSubscriptionsWarning(
        vehiclesWithActiveSubscriptions
          .map(
            (vehiclesWithActiveSubscription) =>
              vehiclesWithActiveSubscription.licencePlate
          )
          .join(", "),
        () =>
          saveVehiclesRequests(
            permitHolderVehiclesToRemove,
            permitHolderVehiclesToSave,
            permitHolderVehiclesToUpdate
          )
      );
      return;
    }

    saveVehiclesRequests(
      permitHolderVehiclesToRemove,
      permitHolderVehiclesToSave,
      permitHolderVehiclesToUpdate
    );
  }, [
    initialAddedVehicles,
    addedVehicles,
    displayEditingWarning,
    activeSubscriptions,
    saveVehiclesRequests,
  ]);

  return {
    addVehicle,
    editVehicle,
    makeEditable,
    removeVehicle,
    saveVehicles,
  };
};

export default useVehicleTabOperations;
