import { useCallback, useMemo, useState } from "react";
import { useStepContext } from "../../components/Step";
import { usePermitHoldersContext } from "../../Provider";
import { actionTypes } from "../../store/actions";

const useDocumentsStep = () => {
  const { documents, onAdvance } = useStepContext();
  const { documents: submittedDocuments, dispatch } = usePermitHoldersContext();
  const [isStepTouched, setIsStepTouched] = useState(false);

  const isStepValid = useMemo(() => {
    const requiredDocuments = documents.filter((doc) => doc.isRequired);

    const submittedDocumentsIds = Object.keys(
      submittedDocuments
    ).map((submittedDocumentId) => parseInt(submittedDocumentId, 10));

    for (let index = 0; index < requiredDocuments.length; index += 1) {
      const documentId = requiredDocuments[index].id;

      if (!submittedDocumentsIds.includes(documentId)) {
        return false;
      }
    }

    return true;
  }, [submittedDocuments, documents]);

  const isDocumentValid = useCallback(
    (documentId) => {
      const { isRequired } = documents.find((doc) => doc.id === documentId);

      if (!isRequired) {
        return true;
      }

      return Object.keys(submittedDocuments).some(
        (submittedDocumentId) => parseInt(submittedDocumentId, 10) === documentId
      );
    },
    [submittedDocuments, documents]
  );

  const localOnAdvance = useCallback(() => {
    if (!isStepValid) {
      setIsStepTouched(true);
      return;
    }

    setIsStepTouched(false);
    onAdvance();
  }, [isStepValid, onAdvance]);

  const onSubmitDocument = (doc) =>
    dispatch({ type: actionTypes.ADD_DOCUMENT, payload: doc });

  const onRemoveDocument = (id) =>
    dispatch({ type: actionTypes.REMOVE_DOCUMENT, payload: id });

  return {
    documents,
    isStepTouched,
    isDocumentValid,
    onAdvance: localOnAdvance,
    onSubmitDocument,
    onRemoveDocument,
  };
};

export default useDocumentsStep;
