import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../../components/AppProvider";
import { useAuth } from "../../../components/AuthProvider";
import { breakpointsMapping, smallDevicesBreakpoints } from "../../../utils/breakpoints";
import AccountMovements from "./AccountMovements";
import {
  getBalanceHistory as getBalanceHistoryRequest,
  getPaymentsHistory as getPaymentsHistoryRequest,
  getVehicles as getVehiclesRequest,
} from "./controller";
import MediumDevicesAccountMovements from "./mediumDevices/AccountMovements";
import SmallDevicesAccountMovements from "./smallDevices/AccountMovements";

const AccountMovementsPage = () => {
  const { breakpoint } = useAppContext();
  const { updateBalance, defaultEntityId, driverHash } = useAuth();
  const [vehiclesColors, setVehiclesColors] = useState(null);

  useEffect(updateBalance, []);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    const getVehicles = async () => {
      const newVehiclesColors = await getVehiclesRequest(driverHash, cancelToken);

      setVehiclesColors(newVehiclesColors);
    };

    getVehicles();

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const getBalanceHistoryCallback = useCallback(async (params, cancelToken) => {
    const result = await getBalanceHistoryRequest(
      defaultEntityId,
      driverHash,
      params,
      cancelToken
    );

    return result;
  }, []);

  const getPaymentsHistoryCallback = useCallback(async (params, cancelToken) => {
    const result = await getPaymentsHistoryRequest(
      defaultEntityId,
      driverHash,
      params,
      cancelToken
    );

    return result;
  }, []);

  const chooseViewBasedOnDevice = () => {
    const props = {
      getBalanceHistoryCallback,
      getPaymentsHistoryCallback,
      vehiclesColors,
    };

    if (smallDevicesBreakpoints.includes(breakpoint)) {
      return <SmallDevicesAccountMovements {...props} />;
    }

    if (breakpointsMapping.noBreakpoint === breakpoint) {
      return <MediumDevicesAccountMovements {...props} />;
    }

    return <AccountMovements {...props} />;
  };

  return <>{chooseViewBasedOnDevice()}</>;
};

export default AccountMovementsPage;
