import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";
import styled from "styled-components";
import { entityConfigurationsMapping, getConfigValue } from "utils/entities";
import { useAuth } from "../../../components/AuthProvider";
import Link from "../../../components/Link";
import { breakpoints } from "../../../utils/breakpoints";
import { routesMapping } from "../../../utils/routes";

const MenuList = styled.ul`
  list-style-type: none;
  padding-left: 0px;
  flex: 1;
  ${({ spreadItems }) =>
    spreadItems &&
    `
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 50px;
  `};
`;

const MenuItem = styled.li`
  height: 36px;
  padding: 2px 0;
  margin: 3px 0;

  @media (max-width: ${breakpoints.lg}) {
    padding: 0;
    margin: 0;
    height: 30px;
  }
`;

const SelectedMark = styled.div`
  left: ${(props) => {
    return props.displaySelectMarker ? `0` : `-50px`;
  }};
  position: absolute;
  background-color: ${({ theme }) => theme.typography.text.color.secondary};
  margin-left: -25px;
  height: 85%;
  width: 20px;

  @media (max-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const LinkContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  :hover {
    div {
      transition: 0.5s;
      left: 0;
    }
  }

  .link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    :hover {
      opacity: ${({ isSelected }) => (isSelected ? 1 : 0.7)};
    }
  }

  .link-text {
    font-weight: ${(props) => (props.isSelected ? 900 : "inherit")};
  }

  @media (max-width: ${breakpoints.lg}) {
    .link {
      justify-content: center;
    }

    .link-text {
      ${(props) =>
        props.isSelected &&
        `
          color: ${props.theme.background.color.primary};
        `}
    }
  }
`;

function MenuLinks(props) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isGeneralDataProtectionRegulationAccepted, defaultEntity } = useAuth();

  const canCreatePublicPermits = getConfigValue(
    defaultEntity?.configurations || [],
    entityConfigurationsMapping.canCreatePublicPermits
  );

  const { toggleMenu, spreadItems } = props;

  const items = useMemo(() => {
    const settings = {
      componentUrl: routesMapping.backofficeDefinitions,
      label: t("4293") /* Definições */,
    };

    if (!isGeneralDataProtectionRegulationAccepted) {
      return [settings];
    }

    return [
      {
        componentUrl: routesMapping.backofficeParking,
        label: t("7934") /* Estacionar */,
      },
      {
        componentUrl: routesMapping.backofficeParkings,
        label: t("1417") /* Estacionamentos */,
      },
      ...(canCreatePublicPermits
        ? [
            {
              componentUrl: routesMapping.backofficePermitHolders,
              label: t("10573") /* Dísticos */,
            },
          ]
        : []),
      {
        componentUrl: routesMapping.backofficeAccountMovements,
        label: t("4010") /* Movimentos de Conta */,
      },
      {
        componentUrl: routesMapping.backofficeNotices,
        label: t("193") /* Infrações */,
      },
      {
        componentUrl: routesMapping.backofficeVehicles,
        label: t("4424") /* Veículos */,
      },
      {
        componentUrl: routesMapping.backofficeCities,
        label: t("6941") /* Entidades */,
      },
      settings,
    ];
  }, [isGeneralDataProtectionRegulationAccepted, canCreatePublicPermits]);

  return (
    <MenuList spreadItems={spreadItems}>
      {items.map((item) => {
        const isSelected = matchPath(pathname, { path: item.componentUrl });

        return (
          <MenuItem key={item.componentUrl} onClick={toggleMenu}>
            <LinkContainer isSelected={isSelected}>
              <SelectedMark displaySelectMarker={isSelected} />
              <Link className="link" to={item.componentUrl}>
                <Text className="link-text" color="secondary" variant="body8">
                  {item.label}
                </Text>
              </Link>
            </LinkContainer>
          </MenuItem>
        );
      })}
    </MenuList>
  );
}

MenuLinks.defaultProps = {
  toggleMenu: () => {},
  spreadItems: false,
};

MenuLinks.propTypes = {
  toggleMenu: PropTypes.func,
  spreadItems: PropTypes.bool,
};

export default MenuLinks;
