import React from "react";
import { useTranslation } from "react-i18next";
import ContentBorderBox from "../../components/ContentBorderBox";
import ContentHeader from "../../components/ContentHeader";
import useAccountMovements from "../hooks/useAccountMovements";
import PaymentMethods from "./PaymentMethods";

const BalanceCharge = () => {
  const { t } = useTranslation();
  const { backToList } = useAccountMovements();

  return (
    <>
      <ContentHeader
        topTitle={t("7946") /* Queres estacionar? */}
        bottomTitle={t("9350") /* Carrega o teu saldo */}
      />
      <ContentBorderBox
        className="mt-40"
        backCallback={backToList}
        title={t("8173") /* Escolhe o método de pagamento */}
      >
        <PaymentMethods />
      </ContentBorderBox>
    </>
  );
};

export default BalanceCharge;
