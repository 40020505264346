import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ClockOptions from "../ClockOptions";
import ConfigurableClock from "../ConfigurableClock";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  width: 300px;
  height: 350px;
`;

const ClockWrapper = styled.div`
  flex: 2;
`;

const ClockOptionsWrapper = styled.div`
  flex: 1;
`;

const ConfigurableClockWithOptions = ({ duration, onDurationChange, max, min }) => {
  return (
    <Container>
      <ClockWrapper>
        <ConfigurableClock
          max={max}
          min={min}
          duration={duration}
          onDurationChange={onDurationChange}
        />
      </ClockWrapper>
      <ClockOptionsWrapper>
        <ClockOptions max={max} onDurationChange={onDurationChange} />
      </ClockOptionsWrapper>
    </Container>
  );
};

ConfigurableClockWithOptions.propTypes = {
  duration: PropTypes.number,
  onDurationChange: PropTypes.func.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
};

ConfigurableClockWithOptions.defaultProps = {
  max: null,
  min: null,
  duration: null,
};

export default ConfigurableClockWithOptions;
