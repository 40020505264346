import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const sizeMapping = {
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
  xxl: "xxl",
};

const alignMapping = {
  left: "left",
  center: "center",
  right: "right",
};

const sizeProportionMapping = {
  [sizeMapping.xs]: 0.5,
  [sizeMapping.sm]: 1,
  [sizeMapping.md]: 1.5,
  [sizeMapping.lg]: 2,
  [sizeMapping.xl]: 3,
  [sizeMapping.xxl]: 4,
};

const alignFlexMapping = {
  [alignMapping.left]: "flex-start",
  [alignMapping.center]: "center",
  [alignMapping.right]: "flex-end",
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${({ size, flex }) => flex || sizeProportionMapping[size]};
  align-items: ${({ align }) => alignFlexMapping[align]};
`;

const CardCell = ({ children, size, align, flex, className }) => {
  return (
    <Container className={className} size={size} align={align} flex={flex}>
      {children}
    </Container>
  );
};

CardCell.defaultProps = {
  size: sizeMapping.md,
  align: alignMapping.left,
  flex: null,
  children: null,
  className: undefined,
};

CardCell.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(Object.values(sizeMapping)),
  align: PropTypes.oneOf(Object.values(alignMapping)),
  flex: PropTypes.number,
  className: PropTypes.string,
};

export default CardCell;
