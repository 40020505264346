export const LANGUAGE_PLACEHOLDER = "LANGUAGE_PLACEHOLDER";

const GOOGLE_MAPS_API_KEY = "AIzaSyB_21XmB-NxJM82oodxKnkThccPVb9WNGQ";
export const DRIVER_APP_APPLE_URL =
  "https://apps.apple.com/pt/app/iparque-mobile/id526743514";
export const DRIVER_APP_ANDROID_URL =
  "https://play.google.com/store/apps/details?id=com.acin.iparque";
export const GOOGLE_MAPS_URL = `https://maps.googleapis.com/maps/api/js?libraries=visualization&libraries=geometry&key=${GOOGLE_MAPS_API_KEY}`;
export const ACIN_PRIVACY_POLICY_URL = `https://www.acin.pt/${LANGUAGE_PLACEHOLDER}/politica-privacidade/`;
export const ACIN_SECURITY_POLICY_URL = `https://acin.pt/documents/PL03-Information Security Policy.${LANGUAGE_PLACEHOLDER}.pdf`;
export const IPARQUE_GENERAL_CONDITIONS = `https://www.iparque.pt/iparque/doc/${LANGUAGE_PLACEHOLDER}/condicoes_gerais_adesao.pdf`;
export const CERTIFICATES_URL =
  "https://www.iparque.pt/iparque/index.php?op=main&page=certificacao";
export const IPARQUE_SUPPORT_EMAIL = "apoio@iparque.pt";
export const FACEBOOK_SDK_URL = "https://connect.facebook.net/en_US/sdk.js";
export const GOOGLE_RECAPTCHA_URL = "https://www.google.com/recaptcha/api.js";
export const EXPORTATION_FILE_NAME = "personalData";
