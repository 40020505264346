import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { isPublicRoute } from "utils/routes";

const useIsPublicPage = () => {
  const history = useHistory();
  const [isPublicPage, setIsPublicPage] = useState(
    isPublicRoute(history.location.pathname)
  );

  useEffect(() => {
    const unlisten = history.listen((location) =>
      setIsPublicPage(isPublicRoute(location.pathname))
    );

    return unlisten;
  }, [history]);

  return isPublicPage;
};

export default useIsPublicPage;
