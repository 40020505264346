import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import useLocalizedUrls from "hooks/useLocalizedUrls";
import { Button, Link, Popup, Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { acceptContract } from "requests/driver";
import styled from "styled-components";
import { userContractsMapping } from "utils/contracts";
import { allowedBreakpointsToDisplayPopupIcon } from "./helper";

const Container = styled.div`
  margin-top: 10px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  margin: 20px 0 10px;
`;

const ContentContainer = styled.div`
  display: flex;
`;

const StyledInfoIcon = styled(InfoIcon)`
  width: 100px;
  fill: ${({ theme }) => theme.color.dark};
`;

const GeneralDataProtectionPopup = ({ onClose, visible, loginCallback, token }) => {
  const { t } = useTranslation();
  const { setIsLoading, breakpoint } = useAppContext();
  const { handleAcceptGeneralDataProtectionRegulation, driverHash } = useAuth();
  const { privacyPolicyUrl, termsAndConditionsUrl } = useLocalizedUrls();

  const loginWithAcceptance = useCallback(async () => {
    onClose();
    setIsLoading(true);

    const success = await acceptContract(
      driverHash,
      {
        contractId: userContractsMapping.generalDataProtectionRegulation,
      },
      token
    );

    setIsLoading(false);

    loginCallback(() => {
      handleAcceptGeneralDataProtectionRegulation(success);
    });
  }, [driverHash, token]);

  const loginWithoutAcceptance = useCallback(() => {
    onClose();
    loginCallback();
  }, []);

  return (
    <Popup onClose={onClose} visible={visible}>
      <Container>
        <ContentContainer>
          {allowedBreakpointsToDisplayPopupIcon.includes(breakpoint) && (
            <StyledInfoIcon />
          )}
          <div>
            <Text elementType="p" color="secondary" variant="body6">
              {t("6377") /* Regulamento Geral de Proteção de Dados */}
            </Text>
            <Text variant="body7" color="secondary" className="mt-10">
              {t("8671") /* A data limite para a aceitação dos novos */}
              &nbsp;
              <b>
                <Link
                  target="_blank"
                  href={termsAndConditionsUrl}
                  underline="always"
                  rel="noopener"
                >
                  {t("7897") /* Termos de Utilização */}
                </Link>
              </b>
              &nbsp;
              {t("4740") /* e */}
              &nbsp;
              <b>
                <Link
                  target="_blank"
                  href={privacyPolicyUrl}
                  underline="always"
                  rel="noopener"
                >
                  {t("6005") /* Política de Privacidade */}
                </Link>
              </b>
              {t("8672") /* , em conformidade com o RGPD, foi ultrapassada. */}
            </Text>
            <Text variant="body7" color="secondary" className="mt-10">
              {
                t(
                  "9663"
                ) /* Para continuares a utilizar os serviços da plataforma iParque terás de aceitar os nossos termos. */
              }
            </Text>
          </div>
        </ContentContainer>
        <ButtonContainer>
          <Text variant="body10">
            <Button
              size="xs"
              variant="link"
              onClick={loginWithoutAcceptance}
              color="secondary"
            >
              {t("8674") /* Ver depois */}
            </Button>
          </Text>
          <Button size="xs" onClick={loginWithAcceptance} color="secondary">
            {t("6387") /* Aceitar */}
          </Button>
        </ButtonContainer>
      </Container>
    </Popup>
  );
};

GeneralDataProtectionPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  loginCallback: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

export default GeneralDataProtectionPopup;
