import FlexBox from "components/FlexBox";
import CheckboxField from "components/formFields/Checkbox";
import Text from "components/Text";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

const DrivingLicenceCategoriesInput = ({ drivingLicenceCategories, readOnly }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  if (!drivingLicenceCategories) {
    return null;
  }

  const onChange = (event) => {
    if (values.drivingLicenceCategories.includes(event.target.name)) {
      setFieldValue(
        "drivingLicenceCategories",
        values.drivingLicenceCategories.filter((item) => item !== event.target.name)
      );
    } else {
      setFieldValue("drivingLicenceCategories", [
        ...values.drivingLicenceCategories,
        event.target.name,
      ]);
    }
  };

  const isChecked = (category) => {
    return values.drivingLicenceCategories.includes(category);
  };

  let DrivingLicenceCategoriesItems = <></>;

  if (readOnly) {
    DrivingLicenceCategoriesItems = (
      <StyledText color="quintenary" variant="body6">
        <b>
          {values.drivingLicenceCategories.length > 0
            ? drivingLicenceCategories
                .map((category) => (isChecked(category.value) ? category.label : ""))
                .filter((value) => value)
                .join(", ")
            : "-"}
        </b>
      </StyledText>
    );
  } else {
    DrivingLicenceCategoriesItems = (
      <StyledFlexBox>
        {drivingLicenceCategories.map((category) => (
          <CheckboxField
            key={category.value}
            name={category.value}
            onChange={onChange}
            checked={isChecked(category.value)}
            label={
              <StyledText color="quintenary" variant="body6">
                {category.label}
              </StyledText>
            }
          />
        ))}
      </StyledFlexBox>
    );
  }

  return (
    <>
      <Text className="mb-10" color="quintenary" variant="body6">
        {t("5835") /* Categorias */}
      </Text>
      <>{DrivingLicenceCategoriesItems}</>
    </>
  );
};

DrivingLicenceCategoriesInput.propTypes = {
  drivingLicenceCategories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  readOnly: PropTypes.bool,
};

DrivingLicenceCategoriesInput.defaultProps = {
  drivingLicenceCategories: [],
  readOnly: false,
};

export default DrivingLicenceCategoriesInput;

const StyledText = styled(Text)`
  min-width: 40px;
`;

const StyledFlexBox = styled(FlexBox)`
  flex-flow: row wrap;
  margin: 0 auto;
  position: relative;
  gap: 15px;
`;
