import React from "react";
import { ReactComponent as WarningTriangle } from "assets/icons/warning-triangle.svg";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "iparque-components/dist/components";
import { useAppContext } from "components/AppProvider";
import { smallDevicesBreakpoints } from "utils/breakpoints";

const Alert = ({ onClick, onClickTitle, title, subtitle, variant, className }) => {
  const { breakpoint } = useAppContext();

  const isSmallDevice = smallDevicesBreakpoints.includes(breakpoint);
  const localOnClickTitle = onClick ? onClickTitle : "";

  return (
    <Container
      onClick={onClick}
      variant={variant}
      className={className}
      hasSubtitle={!!subtitle}
      title={localOnClickTitle}
    >
      <StyledIcon variant={variant} />
      <TextContainer>
        <StyledText
          title={localOnClickTitle}
          isSmallDevice={isSmallDevice}
          variant="body7"
        >
          {title}
        </StyledText>
        {subtitle && (
          <StyledText
            className="mt-8"
            isSmallDevice={isSmallDevice}
            variant="body8"
            title={localOnClickTitle}
          >
            {subtitle}
          </StyledText>
        )}
      </TextContainer>
    </Container>
  );
};

export default Alert;

Alert.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["warning", "danger"]),
  onClick: PropTypes.func,
  onClickTitle: PropTypes.string,
};

Alert.defaultProps = {
  subtitle: undefined,
  className: undefined,
  variant: "warning",
  onClick: undefined,
  onClickTitle: "",
};

const Container = styled.div`
  display: flex;
  background-color: ${({ variant, theme }) => theme.alerts[variant].background};
  padding: 12px 20px;
  align-items: ${({ hasSubtitle }) => (hasSubtitle ? "flex-start" : "center")};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};
`;

const TextContainer = styled.div`
  margin-left: 20px;
`;

const StyledIcon = styled(WarningTriangle)`
  fill: ${({ variant, theme }) => theme.alerts[variant].icon};
  width: 28px;
  flex-shrink: 0;
`;

const StyledText = styled(Text)`
  font-size: ${({ isSmallDevice }) => (isSmallDevice ? 13 : 17)}px;
`;
