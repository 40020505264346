/* eslint-disable import/prefer-default-export */
import i18n from "i18next";
import { hasErrorCode, PARKING_NO_TIME_AVAILABLE } from "utils/error";
import ParkingsDataSource from "../../../lib/clients/iParque/dataSources/parkingsDataSource";
import { errorMessage, infoMessage } from "../../../utils/userMessages";
import { convertMinutesToSeconds } from "../../../utils/dateTime";

const parkingDataSource = new ParkingsDataSource();

export const stopParking = async (
  entityId,
  streetId,
  parkingId,
  { stopParkingCancelToken }
) => {
  try {
    const response = await parkingDataSource.stop(
      entityId,
      streetId,
      parkingId,
      {},
      {},
      false,
      stopParkingCancelToken
    );

    return {
      endingDate: response.endingDate,
      duration: convertMinutesToSeconds(parseInt(response.parkingDuration, 10)),
    };
  } catch (error) {
    if (hasErrorCode(error, PARKING_NO_TIME_AVAILABLE)) {
      infoMessage(i18n.t("8125") /* Ocorreu um erro ao finalizar o estacionamento */);
      return null;
    }

    errorMessage(
      error,
      i18n.t("8125") /* Ocorreu um erro ao finalizar o estacionamento */
    );
    return null;
  }
};
