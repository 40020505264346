import React from "react";
import { useTranslation } from "react-i18next";
import { usePermitHoldersDetailsContext } from "../../Provider";
import StatusWidget from "../StatusWidget";

const LocationsTabStatus = () => {
  const { t } = useTranslation();
  const {
    locationsTab: { isLoading, hasError, zones },
  } = usePermitHoldersDetailsContext();

  if (hasError) {
    return (
      <StatusWidget variant="error">
        {
          t(
            "10480"
          ) /* Erro! Não é possível obter os detalhes dos locais associados a este dístico. */
        }
      </StatusWidget>
    );
  }

  if (!isLoading && zones.length === 0) {
    return (
      <StatusWidget>
        {t("10563") /* Ainda não foram atribuídos locais ao teu dístico. */}
      </StatusWidget>
    );
  }

  return null;
};

export default LocationsTabStatus;
