import { useAuth } from "components/AuthProvider";
import Loader from "components/loader/Loader";
import useLocation, { locationReadingErrorsMapping } from "hooks/useLocation";
import { errorToast } from "iparque-components";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styledComponents from "styled-components";
import { themesMapping } from "themes/theme";
import { UNABLE_TO_GET_STREETS } from "utils/error";
import { isEmpty } from "utils/general";
import { infoMessage } from "utils/userMessages";
import { useBackofficeThemeContext } from "../components/ThemeProvider";

const AppActionTypes = {
  SET_CONFIGS: "SET_CONFIGS",
  SET_POSITION: "SET_POSITION",
  SET_THEME: "SET_THEME",
  SET_SELECTED_STREET: "SET_SELECTED_STREET",
};

const MapActionsTypes = {
  SET_CLOSEST_STREETS: "SET_CLOSEST_STREETS",
  SET_SELECTED_STREET: "SET_SELECTED_STREET",
  SET_ARE_STREETS_LOADING: "SET_ARE_STREETS_LOADING",
  SET_PIN_POSITION: "SET_PIN_POSITION",
  DISPLAY_ERROR: "DISPLAY_ERROR",
};

const ParkingMap = ({ selectedStreetId, isInteractable, isVisible }) => {
  const { position, locationReadingError } = useLocation(false, {
    enableHighAccuracy: true,
  });
  const mapOrigin = useRef(new URL(process.env.REACT_APP_PARKING_MAP_URL).origin);
  const { defaultEntityId } = useAuth();
  const { backofficeThemeName } = useBackofficeThemeContext();
  const [areStreetsLoading, setAreStreetsLoading] = useState(true);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const mapRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (locationReadingError === locationReadingErrorsMapping.permissionDenied) {
      infoMessage(
        t(
          "9708"
        ) /* Ativa a localização para teres uma melhor experiência de utilização */,
        null,
        {
          autoClose: false,
        }
      );
    }
  }, [locationReadingErrorsMapping]);

  const setClosestStreets = (streets) => {
    document.dispatchEvent(
      new CustomEvent("streetsDistanceChange", {
        detail: {
          streets,
        },
      })
    );
  };

  const setSelectedStreet = (street) => {
    document.dispatchEvent(
      new CustomEvent("streetChange", {
        detail: {
          streetId: street?.id || null,
          streetName: street?.name || "",
        },
      })
    );
  };

  const setPinPosition = (pinPosition) => {
    document.dispatchEvent(
      new CustomEvent("changePinPosition", {
        detail: {
          position: pinPosition,
        },
      })
    );
  };

  const displayError = (code) => {
    if (code === UNABLE_TO_GET_STREETS) {
      errorToast(t("7847") /* Erro! */, t("7534") /* Ocorreu um erro ao obter as ruas */);
    }
  };

  const messageTypeToAction = useMemo(() => {
    return {
      [MapActionsTypes.SET_CLOSEST_STREETS]: setClosestStreets,
      [MapActionsTypes.SET_SELECTED_STREET]: setSelectedStreet,
      [MapActionsTypes.SET_ARE_STREETS_LOADING]: setAreStreetsLoading,
      [MapActionsTypes.SET_PIN_POSITION]: setPinPosition,
      [MapActionsTypes.DISPLAY_ERROR]: displayError,
    };
  }, [
    setClosestStreets,
    setSelectedStreet,
    setAreStreetsLoading,
    setPinPosition,
    displayError,
  ]);

  const messageHandler = (message) => {
    if (message.origin !== mapOrigin.current) {
      return;
    }

    if (Array.isArray(message.data)) {
      message.data.forEach((action) => {
        if (typeof messageTypeToAction[action?.type] !== "function") {
          return;
        }

        messageTypeToAction[action.type](action?.payload);
      });
    } else if (typeof messageTypeToAction[message.data?.type] === "function") {
      messageTypeToAction[message.data.type](message.data?.payload);
    }
  };

  useEffect(() => {
    window.addEventListener("message", messageHandler);

    return () => window.removeEventListener("message", messageHandler);
  }, []);

  const sendMessageToIframe = useCallback((message) => {
    mapRef.current?.contentWindow.postMessage(
      message,
      process.env.REACT_APP_PARKING_MAP_URL
    );
  }, []);

  const onIframeLoad = useCallback(() => {
    sendMessageToIframe({
      type: AppActionTypes.SET_CONFIGS,
      payload: {
        entityId: defaultEntityId,
        baseApiUrl: process.env.REACT_APP_BASE_API_URL,
        loadZonesInfo: true,
        theme:
          backofficeThemeName === themesMapping.normal ? "light" : backofficeThemeName,
      },
    });

    setIsIframeLoaded(true);
  }, []);

  useEffect(() => {
    if (!isEmpty(position) && isIframeLoaded && isInteractable) {
      sendMessageToIframe({
        type: AppActionTypes.SET_POSITION,
        payload: {
          lat: position.latitude,
          lng: position.longitude,
        },
      });
    }
  }, [position, isIframeLoaded]);

  useEffect(() => {
    sendMessageToIframe({
      type: AppActionTypes.SET_THEME,
      payload: backofficeThemeName,
    });
  }, [backofficeThemeName]);

  useEffect(() => {
    if (!selectedStreetId && isIframeLoaded) {
      return;
    }

    sendMessageToIframe({
      type: AppActionTypes.SET_SELECTED_STREET,
      payload: selectedStreetId,
    });
  }, [selectedStreetId]);

  return (
    <MapContainer isInteractable={isInteractable} isVisible={isVisible}>
      <Loader width={80} height={80} isLoading={areStreetsLoading} />
      <iframe
        ref={mapRef}
        width="100%"
        height="100%"
        frameBorder="0"
        src={process.env.REACT_APP_PARKING_MAP_URL}
        title={t("10036") /* Mapa de estacionamento */}
        onLoad={onIframeLoad}
      />
    </MapContainer>
  );
};

export default ParkingMap;

ParkingMap.propTypes = {
  selectedStreetId: PropTypes.number,
  isInteractable: PropTypes.bool,
  isVisible: PropTypes.bool,
};

ParkingMap.defaultProps = {
  selectedStreetId: null,
  isInteractable: true,
  isVisible: true,
};

const MapContainer = styledComponents.div`
  position: relative;
  width: 100%;
  height: 50vh;
  min-height: 300px;
  pointer-events: ${({ isInteractable }) => (isInteractable ? "auto" : "none")};
    display: ${({ isVisible }) => (isVisible ? `block` : `none`)};
`;
