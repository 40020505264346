import { getIn } from "formik";
import PropTypes from "prop-types";
import React, { createContext, useCallback, useContext } from "react";

const Context = createContext({ isFieldRequired: undefined });

export const ValidationSchemaProvider = ({ schema, children }) => {
  const isFieldRequired = useCallback(
    (name) => {
      const fieldValidationSchema = schema ? getIn(schema.describe().fields, name) : null;
      const tests = fieldValidationSchema ? fieldValidationSchema.tests : null;
      const isRequired = tests ? !!tests.find((test) => test.name === "required") : false;

      return isRequired;
    },
    [schema]
  );

  return <Context.Provider value={{ isFieldRequired }}>{children}</Context.Provider>;
};

export const useValidationSchema = () => {
  return useContext(Context);
};

ValidationSchemaProvider.propTypes = {
  children: PropTypes.node.isRequired,
  schema: PropTypes.shape({ describe: PropTypes.func.isRequired }).isRequired,
};
