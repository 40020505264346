class MapGeometry {
  constructor(google) {
    this.google = google;
  }

  getStreetCenteredBounds(street) {
    const bounds = new this.google.maps.LatLngBounds();

    street.polylines.forEach((polyline) => {
      polyline.getPath().forEach((latLng) => {
        bounds.extend(latLng);
      });
    });

    street.polygons.forEach((polygon) => {
      polygon.getPath().forEach((latLng) => {
        bounds.extend(latLng);
      });
    });

    return bounds;
  }
}

export default MapGeometry;
