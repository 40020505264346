import PropTypes from "prop-types";

const userPropTypes = {
  uid: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  vat: PropTypes.string,
  phone: PropTypes.number,
  address: PropTypes.string,
  location: PropTypes.string,
  zipCode: PropTypes.string,
  countryId: PropTypes.number,
};

const userDefaultProps = {
  uid: "",
  email: "",
  vat: "",
  phone: null,
  location: "",
  zipCode: "",
  countryId: null,
};

const countriesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  })
);

const titlePropTypes = PropTypes.shape({
  top: PropTypes.string.isRequired,
  bottom: PropTypes.string.isRequired,
});

export const profilePropTypes = {
  user: PropTypes.shape(userPropTypes),
  countries: countriesPropTypes,
  countryCodesMapping: PropTypes.objectOf(PropTypes.string),
  saveUserCallback: PropTypes.func.isRequired,
  saveUserPasswordCallback: PropTypes.func.isRequired,
  onUserFormEditClickCallback: PropTypes.func.isRequired,
  onPasswordEditClickCallback: PropTypes.func.isRequired,
  editUserForm: PropTypes.bool.isRequired,
  editPasswordForm: PropTypes.bool.isRequired,
  title: titlePropTypes,
  extraRequiredFields: PropTypes.arrayOf(PropTypes.string),
};

export const profileDefaultProps = {
  user: PropTypes.shape(userDefaultProps),
  countries: [],
  extraRequiredFields: [],
};

export const userFormPropTypes = () => {
  const processedUserProps = {
    ...userPropTypes,
  };

  delete processedUserProps.uid;

  return {
    user: processedUserProps,
    countries: countriesPropTypes,
    edit: PropTypes.bool.isRequired,
    saveUserCallback: PropTypes.func.isRequired,
    extraRequiredFields: PropTypes.arrayOf(PropTypes.string),
    onCancel: PropTypes.func.isRequired,
  };
};

export const userFormDefaultProps = () => {
  const processedUserDefaultProps = {
    ...userDefaultProps,
  };

  delete processedUserDefaultProps.uid;

  return { user: processedUserDefaultProps, countries: [], extraRequiredFields: [] };
};

export const passwordFormProps = {
  saveUserPasswordCallback: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export const passwordFormDefaultProps = {
  onCancel: () => {},
};
