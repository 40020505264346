export const streetColorsMapping = {
  red: "#DB2127",
  yellow: "#FBD75B",
  green: "#7BD148",
  blue: "#185C96",
  gray: "#BDC3C7",
  orange: "#FFB878",
};

export const streetHolidaysTypesMapping = {
  streetSection: "streetSection",
};
