import React, { Suspense, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button, Heading, Text, Link, Overlay } from "iparque-components";
import FlexBox from "components/FlexBox";
import {
  appearFromBottom,
  defaultAnimationDelay,
  defaultAnimationDuration,
  getAppearFromLeft,
} from "themes/animations/animations";
import HighlightedPoint from "../../components/HighlightedPoint";
import { ReactComponent as Logo } from "../../../../assets/images/logo.svg";
import { breakpoints } from "../../../../utils/breakpoints";
import { DRIVER_APP_ANDROID_URL, DRIVER_APP_APPLE_URL } from "../../../../utils/configs";
import { languageWithoutRegion } from "../../../../utils/general";
import { videoIds } from "./configs";

const MobileAppGif = React.lazy(() => import("./MobileAppGif"));
const STORE_BADGE_WIDTH = 183;

const AppInfo = () => {
  const { t, i18n } = useTranslation();
  const [GoogleStore, setGoogleStore] = useState(null);
  const [AppleStore, setAppleStore] = useState(null);
  const [videoVisible, setVideoVisible] = useState(false);

  const language = languageWithoutRegion(i18n.language);
  const videoId = videoIds[language];

  useEffect(() => {
    const importGoogleStoreComponent = async () => {
      const googleStoreImport = await import(
        `../../../../components/images/${language}/GoogleStore`
      );

      setGoogleStore(googleStoreImport.default);
    };

    const importAppleStoreComponent = async () => {
      const appleStoreImport = await import(
        `../../../../components/images/${language}/AppleStore`
      );

      setAppleStore(appleStoreImport.default);
    };

    importGoogleStoreComponent();
    importAppleStoreComponent();
  }, [t]);

  const showVideo = () => setVideoVisible(true);

  const closeVideo = () => setVideoVisible(false);

  return (
    <Container>
      <InfoContainer>
        <StyledHeading variant="h1" className="appear-from-left">
          {t("8484") /* O seu estacionamento na app */}
        </StyledHeading>
        <InfoTextWrapper className="appear-from-left">
          <Text variant="body4">
            {
              t(
                "8489"
              ) /* Sempre que te deslocas sonhas com uma solução que te permite pagar o estacionamento com a comodidade que mereces? Conhece a nossa solução e concretiza o teu sonho! O iParque Driver é a app de pagamento de estacionamento à distância que procuras. */
            }
            <br />
            <br />
            <Text elementType="strong" variant="body1">
              {
                t(
                  "8492"
                ) /* Esquece as moedas que não trouxeste e as deslocações ao parquímetro. */
              }
            </Text>
            {
              t(
                "8501"
              ) /* Paga o estacionamento com a app iParque Driver, disponível em */
            }
            &nbsp;
            <Link
              target="_blank"
              href={DRIVER_APP_ANDROID_URL}
              underline="always"
              rel="noopener"
            >
              {t("5228") /* Android */}
            </Link>
            &nbsp;
            {t("4740") /* e */}
            &nbsp;
            <Link
              target="_blank"
              href={DRIVER_APP_APPLE_URL}
              underline="always"
              rel="noopener"
            >
              {t("8493") /* IOS */}
            </Link>
            {t("8494") /* , em poucos segundos e de forma intuitiva! */}
          </Text>
        </InfoTextWrapper>
        <StyledHighlightedPoint>
          {t("10960") /* A app para pagar o teu estacionamento */}
        </StyledHighlightedPoint>
        <StoreIconsWrapper className="appear-from-left">
          <a
            target="blank"
            aria-label="Google Play"
            href={DRIVER_APP_ANDROID_URL}
            rel="noopener"
          >
            {GoogleStore &&
              React.cloneElement(GoogleStore, {
                width: STORE_BADGE_WIDTH,
                "aria-labelledby": "Google Play",
                title: "Google Play",
                role: "img",
              })}
          </a>
          <a
            target="blank"
            aria-label="App Store"
            href={DRIVER_APP_APPLE_URL}
            rel="noopener"
          >
            {AppleStore &&
              React.cloneElement(AppleStore, {
                width: STORE_BADGE_WIDTH,
                "aria-labelledby": "AppStore",
                title: "AppStore",
                role: "img",
              })}
          </a>
        </StoreIconsWrapper>
      </InfoContainer>
      <AppContainer>
        <AppWrapper flexDirection="column">
          <StyledLogo />
          <Suspense fallback={<div />}>
            <MobileAppGif
              alt={
                t("10649") /* Ecrãs da app de pagamento de estacionamento à distância */
              }
              title={
                t("10649") /* Ecrãs da app de pagamento de estacionamento à distância */
              }
            />
          </Suspense>
          <VideoButton
            className="uppercase"
            onClick={showVideo}
            aria-labelledby={
              t("10650") /* A app de pagamento de estacionamento à distância */
            }
            title={t("10650") /* A app de pagamento de estacionamento à distância */}
          >
            {t("10132") /* Ver vídeo */}
          </VideoButton>
        </AppWrapper>
      </AppContainer>
      <Overlay visible={videoVisible} onClose={closeVideo}>
        {videoId && (
          <iframe
            title="iparque-video"
            src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1`}
            width="100%"
            height="100%"
            allow="autoplay;fullscreen"
            frameBorder={0}
          />
        )}
      </Overlay>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 60px;
  display: flex;
  gap: 50px;

  .appear-from-left {
    animation: ${getAppearFromLeft("-20%")} 1s;
    animation-delay: 1s;
    animation-fill-mode: both;
  }

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column-reverse;
    gap: 0;
  }
`;

const StyledHeading = styled(Heading)`
  line-height: 1;
  word-break: break-word;

  @media (min-width: ${breakpoints.lg}) {
    max-width: 620px;
    margin-bottom: 40px;
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 0;
  }
`;

const InfoContainer = styled.div`
  max-width: 620px;
`;

const AppContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${breakpoints.md}) {
    justify-content: center;
  }
`;

const AppWrapper = styled(FlexBox)`
  position: relative;
  align-items: center;
  height: 640px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.lg}) {
    height: 470px;
  }

  @media (max-width: ${breakpoints.md}) {
    height: unset;
  }
`;

const VideoButton = styled(Button)`
  margin-top: 140px;
  align-self: center;
  animation: ${appearFromBottom} ${defaultAnimationDuration}s;
  animation-delay: ${defaultAnimationDelay + 0.7}s;
  animation-fill-mode: both;

  @media (max-width: ${breakpoints.lg}) {
    margin-top: 120px;
  }

  @media (max-width: ${breakpoints.sm}) {
    margin-top: 85px;
  }
`;

const InfoTextWrapper = styled.div`
  padding: 20px 0;
`;

const StyledLogo = styled(Logo)`
  @keyframes scaleUp {
    0% {
      transform: scale(0.1);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  min-width: 330px;
  height: 470px;
  fill: ${({ theme }) => theme.background.color.tertiary};
  animation: scaleUp ${defaultAnimationDuration}s;
  animation-delay: ${defaultAnimationDelay}s;
  animation-fill-mode: both;

  @media (max-width: ${breakpoints.lg}) {
    min-width: 235px;
    height: 290px;
  }

  @media (max-width: ${breakpoints.md}) {
    min-width: 310px;
  }

  @media (max-width: ${breakpoints.sm}) {
    min-width: 170px;
    height: 210px;
  }
`;

const StoreIconsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;

  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: ${breakpoints.sm}) {
    align-items: center;
  }

  a {
    margin-left: 10px;

    @media (max-width: ${breakpoints.lg}) {
      margin-left: 0;
      margin-top: 10px;

      :first-child {
        margin-top: 0;
      }
    }

    :first-child {
      margin-left: 0;
    }
  }
`;

const StyledHighlightedPoint = styled(HighlightedPoint)`
  margin-top: 50px;

  @media (max-width: ${breakpoints.sm}) {
    margin-top: 25px;
  }
`;

export default AppInfo;
