import React from "react";
import { Divider, Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import SimpleDefinition from "../../components/Simple";
import TabContainer from "../../components/TabContainer";
import { settingsMapping } from "../../../../../utils/settings";
import { newslettersDefaultProps, newslettersPropTypes } from "../../propTypes";

const NewslettersTab = ({
  receiveNewslettersNewFeatures,
  receiveNewslettersMarketCampaigns,
  isSmallDevicesVersion,
}) => {
  const { t } = useTranslation();

  return (
    <TabContainer>
      {isSmallDevicesVersion && (
        <Text className="mb-20" variant="body8">
          {t("8526") /* Newsletters */}
        </Text>
      )}
      <SimpleDefinition
        className="mb-10"
        title={t("8592") /* Novas funcionalidades */}
        description={
          t(
            "8593"
          ) /* Enviar-me newsletter com as novas funcionalidades do iParque driver */
        }
        definitionKey={settingsMapping.receiveNewslettersNewFeatures}
        checked={receiveNewslettersNewFeatures}
      />
      <Divider color="secondary" />
      <SimpleDefinition
        className="mb-10 mt-10"
        title={t("8594") /* Campanhas comerciais */}
        description={
          t("8595") /* Enviar-me newsletter com campanhas comerciais do iParque */
        }
        definitionKey={settingsMapping.receiveNewslettersMarketCampaigns}
        checked={receiveNewslettersMarketCampaigns}
      />
    </TabContainer>
  );
};

NewslettersTab.propTypes = newslettersPropTypes;

NewslettersTab.defaultProps = newslettersDefaultProps;

export default NewslettersTab;
