import React, { useMemo, useState } from "react";
import { Search } from "iparque-components";
import { useTranslation } from "react-i18next";
import useDebounce from "hooks/useDebounce";
import PropTypes from "prop-types";
import List from "./List";

const CityList = ({ items, selected, onClick, isLoading, className }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 500);

  const localOnClick = ({ entityId, cityId }) =>
    onClick(items.find((item) => item.id === cityId && item.entity.id === entityId));

  const processCities = (cities) =>
    cities.map((city) => ({
      imageUrl: city.logoAttachment?.url || city.entity.logo,
      id: { entityId: city.entity.id, cityId: city.id },
      cityName: city.name,
      entityName: city.entity.name,
      key: `${city.entity.id}-${city.id}`,
    }));

  const searchedCities = useMemo(() => {
    if (!debounceSearch) {
      return processCities(items);
    }

    const filteredItems = items.filter(
      (city) =>
        city.name.toUpperCase().includes(debounceSearch.toUpperCase()) ||
        city.entity.name.toUpperCase().includes(debounceSearch.toUpperCase())
    );

    return processCities(filteredItems);
  }, [debounceSearch, items]);

  return (
    <div className={className}>
      <Search
        value={search}
        name="search"
        placeholder={t("4435") /* Pesquisa */}
        onChange={(event) => setSearch(event.target.value)}
      />
      <List
        isLoading={isLoading}
        selected={selected}
        items={searchedCities}
        onClick={localOnClick}
      />
    </div>
  );
};

CityList.propTypes = {
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  selected: PropTypes.shape({
    entityId: PropTypes.number,
    cityId: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      logoAttachment: PropTypes.shape({
        url: PropTypes.string,
      }),
      name: PropTypes.string.isRequired,
      entity: PropTypes.shape({
        name: PropTypes.string.isRequired,
        logo: PropTypes.string,
        id: PropTypes.number.isRequired,
      }),
    })
  ),
};

CityList.defaultProps = {
  className: "",
  isLoading: false,
  selected: undefined,
  items: [],
};

export default CityList;
