import React from "react";
import { Button as IParqueButton } from "iparque-components";
import PropTypes from "prop-types";

const Button = React.forwardRef(({ className, ...props }, ref) => {
  return <IParqueButton ref={ref} className={`uppercase ${className}`} {...props} />;
});

Button.propTypes = {
  className: PropTypes.string,
};

Button.defaultProps = {
  className: "",
};

export default Button;
