import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as SupervisorWithNotice } from "../../../../assets/images/supervisorWithNotice.svg";
import { ReactComponent as PaidNotice } from "../../../../assets/images/paid-notice.svg";

const Animation = ({ isPaid }) => {
  const { t } = useTranslation();

  if (isPaid) {
    return (
      <PaidNotice
        role="img"
        alt={t("11823") /* Pagamento de uma infração de estacionamento com sucesso */}
        title={t("11823") /* Pagamento de uma infração de estacionamento com sucesso */}
      />
    );
  }

  return (
    <StyledSupervisorWithNotice
      role="img"
      alt={t("10647") /* Pagamento de uma infração de estacionamento */}
      title={t("10647") /* Pagamento de uma infração de estacionamento */}
    />
  );
};

export default Animation;

const StyledSupervisorWithNotice = styled(SupervisorWithNotice)`
  width: 160px;
  height: 127px;

  #notice-animation,
  #driver-hand-animation {
    transition: transform 0.3s;
  }

  :hover {
    #notice-animation {
      transform: scale(1.8) translate(-13%, -15%);
    }

    #driver-hand-animation {
      transform: skewY(-8deg);
    }
  }
`;

Animation.propTypes = {
  isPaid: PropTypes.bool,
};

Animation.defaultProps = {
  isPaid: false,
};
