import { useAuth } from "components/AuthProvider";
import Loader from "components/loader/Loader";
import { Heading, Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { breakpoints } from "utils/breakpoints";
import { formatMoney } from "utils/money";
import { paymentMethodsMapping } from "utils/payment";
import BoxContainer from "./BoxContainer";
import { paymentBoxSizes } from "./helper";

const titleSizes = {
  [paymentBoxSizes.medium]: "18",
  [paymentBoxSizes.large]: "25",
};

const availableBalanceTextSizes = {
  [paymentBoxSizes.medium]: "14",
  [paymentBoxSizes.large]: "17",
};

const PaymentMethodBox = React.forwardRef(
  (
    {
      paymentMethodId,
      icon,
      title,
      shortMessage,
      amount,
      className,
      extraInfo,
      onClick,
      disabled,
      isLoading,
      displayAmount,
      displayExtraInfo: displayExtraInfoProp,
      setDisplayExtraInfo: setDisplayExtraInfoProp,
      size,
    },
    ref
  ) => {
    const parentControlsExtraInfoState = !!setDisplayExtraInfoProp;

    const { t } = useTranslation();
    const { user } = useAuth();
    const [displayExtraInfo, setDisplayExtraInfo] = useState(
      parentControlsExtraInfoState ? displayExtraInfoProp : false
    );

    const localDisplayExtraInfo = parentControlsExtraInfoState
      ? displayExtraInfoProp
      : displayExtraInfo;

    const localSetDisplayExtraInfo = parentControlsExtraInfoState
      ? setDisplayExtraInfoProp
      : setDisplayExtraInfo;

    const availableBalance = user.driver?.defaultEntity?.balance?.amount;

    useImperativeHandle(ref, () => ({ toggleDetails: localSetDisplayExtraInfo }), [
      localSetDisplayExtraInfo,
    ]);

    const isDisabled = (() => {
      if (disabled) {
        return true;
      }

      if (amount && paymentMethodId === paymentMethodsMapping.balance.id) {
        return availableBalance < amount;
      }

      return false;
    })();

    const localOnClick = async () => {
      if (isDisabled) {
        return;
      }

      const willDisplayExtraInfo = !localDisplayExtraInfo;

      if (willDisplayExtraInfo && onClick) {
        const result = await onClick();

        if (result === false) {
          return;
        }
      }

      localSetDisplayExtraInfo(willDisplayExtraInfo);
    };

    return (
      <BoxContainer
        disabled={isDisabled}
        onClick={localOnClick}
        className={`${className} payment-method-box`}
        size={size}
      >
        <Loader width={80} height={80} isLoading={isLoading} />
        <InnerContainer>
          {icon}
          <div>
            <Title variant="h4" color="tertiary" size={size}>
              {title}
            </Title>
            {shortMessage && (
              <Text className="mt-10 inline-block" variant="body8">
                {shortMessage}
              </Text>
            )}
            {paymentMethodId === paymentMethodsMapping.balance.id && !shortMessage && (
              <AvailableBalanceText
                className="mt-10 inline"
                color={availableBalance > amount ? "primary" : "danger"}
                variant="body8"
                size={size}
              >
                {t("5680") /* Disponível */}
                :&nbsp;
                <b>
                  {formatMoney(availableBalance, {
                    centsFormat: false,
                  })}
                </b>
              </AvailableBalanceText>
            )}
          </div>
          {displayAmount && amount && (
            <Amount variant="h3" color="tertiary">
              {formatMoney(amount, {
                centsFormat: false,
              })}
            </Amount>
          )}
        </InnerContainer>
        {extraInfo && (
          <ExtraInfoContainer displayExtraInfo={localDisplayExtraInfo}>
            {extraInfo}
          </ExtraInfoContainer>
        )}
      </BoxContainer>
    );
  }
);

PaymentMethodBox.propTypes = {
  paymentMethodId: PropTypes.number,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  shortMessage: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  extraInfo: PropTypes.node,
  onClick: PropTypes.func,
  amount: PropTypes.number,
  isLoading: PropTypes.bool,
  displayAmount: PropTypes.bool,
  displayExtraInfo: PropTypes.bool,
  setDisplayExtraInfo: PropTypes.func,
  size: PropTypes.oneOf(Object.values(paymentBoxSizes)),
};

PaymentMethodBox.defaultProps = {
  paymentMethodId: undefined,
  className: "",
  shortMessage: null,
  extraInfo: null,
  onClick: null,
  disabled: false,
  amount: null,
  isLoading: false,
  displayAmount: false,
  displayExtraInfo: false,
  setDisplayExtraInfo: null,
  size: paymentBoxSizes.large,
};

export default PaymentMethodBox;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  gap: 15px;
`;

const ExtraInfoContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  transition: max-height 0.5s, padding 0.5s, margin 0.5s;

  ${({ displayExtraInfo, theme }) =>
    displayExtraInfo
      ? css`
          margin-top: 30px;
          padding-top: 30px;
          border-top: 1px solid ${theme.color.medium};
          max-height: 1000px;
        `
      : css`
          max-height: 0;
        `}
`;

const Title = styled(Heading)`
  font-size: ${({ size }) => `${titleSizes[size]}px`};

  @media (max-width: ${breakpoints.lg}) {
    font-size: ${({ size }) =>
      size === paymentBoxSizes.large ? "20px" : `${titleSizes[size]}px`};
  }
`;

const AvailableBalanceText = styled(Text)`
  font-size: ${({ size }) => `${availableBalanceTextSizes[size]}px`};
`;

const Amount = styled(Heading)`
  margin-left: auto;
  white-space: nowrap;
`;
