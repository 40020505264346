import { useAppContext } from "components/AppProvider";
import Button from "components/buttons/Button";
import Text from "components/Text";
import RecaptchaInfo from "domain/public/components/RecaptchaInfo";
import useGoogleRecaptcha from "hooks/useGoogleRecaptcha";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import { warningMessage } from "utils/userMessages";
import { identificationTypes } from "utils/driversIdentifications";
import { PORTUGAL } from "utils/countries";
import { usePublicEntityContext } from "domain/public/entities/Provider";
import useDriverIdentificationCreation from "../../hooks/useDriverIdentificationCreation";
import { useDriverIdentificationContext } from "../../Provider";
import Documents from "../Documents";
import ReadOnlyFileInput from "../../components/FormFileInput/ReadOnlyFileInput";
import PersonalData from "../PersonalData";

const ConfirmationStep = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const create = useDriverIdentificationCreation();
  const { setIsLoading } = useAppContext();
  const { executeGoogleRecaptcha, isGoogleRecaptchaLoaded } = useGoogleRecaptcha();
  const {
    personalData,
    identificationData,
    documents,
  } = useDriverIdentificationContext();
  const { entityToken, identificationToken } = useParams();
  const { entity } = usePublicEntityContext();

  const showPersonalIdentificationDocuments =
    identificationData?.typeId === identificationTypes.voluntary ||
    (identificationData?.typeId === identificationTypes.rentalVehicleDriver &&
      entity?.countryId === PORTUGAL);

  const submit = async () => {
    setIsLoading(true);

    const token = await executeGoogleRecaptcha();

    if (!token) {
      setIsLoading(false);
      warningMessage(t("7858") /* Por favor, tenta novamente. */);
      return;
    }

    const created = await create(token);

    setIsLoading(false);

    if (created !== null) {
      history.push(
        `/entities/${entityToken}/drivers-identifications/${identificationToken}/confirmation`,
        {
          clientId: created.clientId,
          name: personalData.name,
        }
      );
    }
  };

  return (
    <>
      <>
        <Text color="quintenary" variant="body4" className="mt-10 mb-20">
          <b>{t("10728") /* Dados pessoais */}</b>
        </Text>
        <PersonalData readOnly />
      </>
      <>
        <Text color="quintenary" variant="body4" className="mt-10 mb-10">
          <b>{t("4297") /* Documentos */}</b>
        </Text>
        <Documents readOnly />
        {identificationData?.typeId === identificationTypes.rentalVehicleDriver && (
          <ReadOnlyFileInput
            className={showPersonalIdentificationDocuments ? "mt-30" : ""}
            designation={t("6405") /* Contrato */}
            value={documents.contract}
          />
        )}
      </>
      <RecaptchaInfo className="mt-20 mb-20" />
      <ButtonContainer>
        <Button
          type="submit"
          color="quintenary"
          fullWidth
          onClick={submit}
          disabled={!isGoogleRecaptchaLoaded}
        >
          {t("4046") /* Confirmar */}
        </Button>
      </ButtonContainer>
    </>
  );
};

export default ConfirmationStep;

const ButtonContainer = styled.div`
  display: flex;
  margin: 30px 0 0 auto;
  width: 170px;

  @media (max-width: ${breakpoints.sm}) {
    justify-content: center;
    width: 100%;
  }
`;
