/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link as IparqueLink } from "iparque-components";
import { Link as RouterLink } from "react-router-dom";

// Underline props
const NONE = "none";
const HOVER = "hover";
const AlWAYS = "always";

const underlineStyles = {
  [AlWAYS]: `text-decoration: underline;`,
  [NONE]: `text-decoration: none;`,
  [HOVER]: `:hover {text-decoration: underline;};`,
};

const StyledRouterLink = styled(RouterLink)`
  ${(props) => underlineStyles[props.underline]}
`;

const Link = (props) => {
  const { children, to, ...other } = props;
  return to ? (
    <StyledRouterLink to={to} {...other}>
      {children}
    </StyledRouterLink>
  ) : (
    <IparqueLink {...other}>{children}</IparqueLink>
  );
};

Link.defaultProps = {
  to: null,
  children: undefined,
};

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
};

export default Link;
