import { useAppContext } from "components/AppProvider";
import { errorToast } from "iparque-components";
import EntityVehiclesDataSource from "lib/clients/iParque/dataSources/entityVehiclesDataSource";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { errorMessage } from "utils/userMessages";

const entityVehiclesDataSource = new EntityVehiclesDataSource();

const useEntityVehiclesForm = (entityId) => {
  const { t } = useTranslation();
  const { setIsLoading } = useAppContext();
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);

  const getBrands = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await entityVehiclesDataSource.getBrands(
        entityId,
        { noLimit: true },
        true
      );

      setIsLoading(false);

      setBrands(response.items.map((brand) => ({ value: brand.id, label: brand.name })));
    } catch (error) {
      errorMessage(
        error,
        t("7963") /* Ocorreu um erro ao obter as marcas dos veículos */
      );
    }
  }, [entityId]);

  const onBrandChange = useCallback(
    async (brandId) => {
      try {
        setIsLoading(true);

        const response = await entityVehiclesDataSource.getBrandModels(
          entityId,
          brandId,
          { noLimit: true },
          true
        );

        setIsLoading(false);

        if (response.items.length === 0) {
          errorToast(
            t("3613") /* Erro */,
            t("10258") /* Não existem modelos para a marca selecionada */
          );
        }

        setModels(
          response.items.map((model) => ({ value: model.id, label: model.name }))
        );
      } catch (error) {
        errorMessage(
          error,
          t("7964") /* Ocorreu um erro ao obter os modelos da marca selecionada */
        );

        setModels([]);
      }
    },
    [entityId]
  );

  useEffect(() => {
    getBrands();
  }, []);

  return { brands, models, onBrandChange };
};

export default useEntityVehiclesForm;
