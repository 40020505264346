import { useEffect } from "react";
import { isScrollInBottom } from "utils/dom";

const useOnPageScrollEnd = (callback, args) => {
  useEffect(() => {
    const onScroll = (event) => {
      const { scrollingElement } = event.target;

      if (isScrollInBottom(scrollingElement, args)) {
        callback(event);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [callback]);
};

export default useOnPageScrollEnd;
