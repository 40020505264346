import React, { useRef } from "react";
import PropTypes, { string } from "prop-types";
import styled, { css } from "styled-components";
import { Text } from "iparque-components";
import useOnScreen from "hooks/useOnScreen";

const intersectionOptions = { threshold: 1, unobserveOnIntersection: true };

const HighlightedPoint = ({ className, children }) => {
  const ref = useRef(null);
  const isOnScreen = useOnScreen(ref, intersectionOptions);

  return (
    <Container ref={ref} className={className} isOnScreen={isOnScreen}>
      <SquareBox id="marked-text-square" />
      <div id="marked-text-text">
        <Text elementType="strong" variant="body1">
          {children}
        </Text>
      </div>
    </Container>
  );
};

HighlightedPoint.propTypes = {
  children: PropTypes.string.isRequired,
  className: string,
};

HighlightedPoint.defaultProps = {
  className: "",
};

const SQUARE_SIZE = "2em";

const Container = styled.div`
  width: 100%;
  display: flex;

  #marked-text-square {
    transition: 0.5s 1.5s;

    ${({ isOnScreen }) =>
      isOnScreen
        ? css`
            opacity: 1;
            transform: translate(0, -0.3em);
          `
        : css`
            opacity: 0;
            transform: translate(-100%, -0.3em);
          `}
  }

  #marked-text-text {
    transition: 0.5s 2s;

    ${({ isOnScreen }) =>
      isOnScreen
        ? css`
            opacity: 1;
            transform: translateX(0);
          `
        : css`
            opacity: 0;
            transform: translateX(-20%);
          `}
  }
`;

const SquareBox = styled.div`
  z-index: 1;
  min-width: ${SQUARE_SIZE};
  height: ${SQUARE_SIZE};
  margin-right: 15px;
  background-color: ${(props) => props.theme.background.color.tertiary};
`;

export default HighlightedPoint;
