import { getTokens } from "../../../../utils/auth";
import AbstractDataSource from "./abstractDataSource";

export default class NoticesDataSource extends AbstractDataSource {
  async generatePayment(entityId, { noticeIds, paymentTypeId, phoneNumber }) {
    return this.iParqueClient.POST(
      `v1/entities/${entityId}/notices/payments/generateNoticePayment`,
      { noticeIds, paymentTypeId, phoneNumber },
      { Authorization: getTokens().token }
    );
  }
}
