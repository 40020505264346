import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "iparque-components";
import SmallDevicesContentHeader from "domain/private/components/smallDevices/ContentHeader";
import useAccountMovements from "../hooks/useAccountMovements";
import PaymentMethods from "./PaymentMethods";

const BalanceCharge = () => {
  const { t } = useTranslation();
  const { backToList } = useAccountMovements();

  return (
    <>
      <SmallDevicesContentHeader
        topTitle={t("7946") /* Queres estacionar? */}
        bottomTitle={t("9350") /* Carrega o teu saldo */}
        backCallback={backToList}
      />
      <Text className="uppercase mt-30 mb-20" variant="body7">
        {t("9349") /* Seleciona o método de carregamento */}
      </Text>
      <PaymentMethods />
    </>
  );
};

export default BalanceCharge;
