import Button from "components/buttons/Button";
import { ValidationSchemaProvider } from "components/ValidationSchemaProvider";
import { Form as FormikForm, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import PropTypes from "prop-types";
import DocumentsFormBody from "./Body";
import useForm from "./useForm";

const Documents = ({ readOnly }) => {
  const { t } = useTranslation();
  const { initialData, validationSchema, submit } = useForm();

  return (
    <Container className="mt-20">
      <ValidationSchemaProvider schema={validationSchema}>
        <Formik
          enableReinitialize
          initialValues={initialData}
          validationSchema={validationSchema}
          onSubmit={submit}
        >
          <StyledForm>
            <DocumentsFormBody readOnly={readOnly} />
            {!readOnly && (
              <ButtonContainer>
                <Button type="submit" color="quintenary" fullWidth>
                  {t("10223") /* Avançar */}
                </Button>
              </ButtonContainer>
            )}
          </StyledForm>
        </Formik>
      </ValidationSchemaProvider>
    </Container>
  );
};

Documents.propTypes = {
  readOnly: PropTypes.bool,
};

Documents.defaultProps = {
  readOnly: false,
};

export default Documents;

const Container = styled.div`
  height: 100%;
`;

const StyledForm = styled(FormikForm)`
  display: flex;
  flex-flow: column wrap;
  height: 100%;
  flex: 1;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 30px 0 0 auto;
  width: 170px;

  @media (max-width: ${breakpoints.sm}) {
    justify-content: center;
    width: 100%;
  }
`;
