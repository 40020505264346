import React, { useMemo, useState, useCallback, useContext, useEffect } from "react";
import useLocaleSync from "hooks/useLocaleSync";
import usePageViews from "hooks/usePageViews";
import PropTypes from "prop-types";
import PageLoader from "./loader/PageLoader";
import useWindowSize from "../hooks/useWindowSize";
import { getCurrentBreakpoint } from "../utils/breakpoints";

export const AppContext = React.createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

const setAppHeight = () => {
  const doc = document.documentElement;

  doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};

const AppProvider = (props) => {
  usePageViews();
  useLocaleSync();
  const size = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  const currentBreakpoint = useMemo(() => getCurrentBreakpoint(size.width), [size]);

  const handleSetIsLoading = useCallback((willBeLoading) => {
    setIsLoading(willBeLoading);
  }, []);

  useEffect(() => {
    setAppHeight(size.height);
  }, [size.height]);

  const { children } = props;

  return (
    <AppContext.Provider
      value={{
        breakpoint: currentBreakpoint,
        setIsLoading: handleSetIsLoading,
        isLoading,
      }}
    >
      {children}
      <PageLoader isLoading={isLoading} />
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
