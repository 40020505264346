import styled from "styled-components";
import { Form as FormikForm } from "formik";

const Form = styled(FormikForm)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default Form;
