export const benefitTypesMapping = {
  offerMinutesFirstPaidPeriod: 1,
  offerMinutesAfterFirstPaidPeriod: 2,
  offerMinutesMultipleParkings: 3,
  percentageDiscount: 4,
};

export const offerMinutesBenefitTypes = [
  benefitTypesMapping.offerMinutesFirstPaidPeriod,
  benefitTypesMapping.offerMinutesAfterFirstPaidPeriod,
  benefitTypesMapping.offerMinutesMultipleParkings,
];
