export const envs = {
  development: "development",
  production: "production",
};

const currentEnv = process.env.NODE_ENV;

const select = (options) => options[currentEnv];

const env = { select };

export default env;
