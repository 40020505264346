import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import useChangeDefaultEntity from "hooks/useChangeDefaultEntity";
import useQuery from "hooks/useQuery";
import DriversDataSource from "lib/clients/iParque/dataSources/driversDataSource";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { convertSecondsToMilliseconds } from "utils/dateTime";
import { notificationStates } from "utils/notifications";
import { isNotificationTypeSupported } from "utils/pushNotifications";
import { routesMapping } from "utils/routes";
import { useBackofficeContext } from "../components/BackOfficeProvider";
import ContractsPopup from "../components/ContractsPopUp";
import { saveEntityContractAcceptance } from "../controllers/cities";
import useNotificationRedirect from "./useNotificationRedirect";

const driversDataSource = new DriversDataSource();
const contractDelay = convertSecondsToMilliseconds(0.5);

const NotificationPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { setIsLoading } = useAppContext();
  const {
    notificationId,
    entityId,
    type,
    parkingId,
    infractionId,
    permitHolderId,
  } = useQuery();
  const {
    defaultEntityId,
    driverHash,
    isGeneralDataProtectionRegulationAccepted,
  } = useAuth();
  const { handleRedirect } = useNotificationRedirect();
  const { displayConfirmation } = useBackofficeContext();
  const changeDefaultEntity = useChangeDefaultEntity();
  const [contracts, setContracts] = useState();

  const currentContract = contracts?.[0];

  const goToFallbackPage = useCallback(() => {
    if (window.history.length === 1) {
      history.replace(routesMapping.backofficeParkings);
    }

    history.goBack();
  }, [history]);

  const redirectToPage = useCallback(() => {
    const resourceId = parkingId || infractionId || permitHolderId;

    handleRedirect(notificationId, {
      type,
      stateId: notificationStates.unread,
      resourceId,
    });
  }, [notificationId, type]);

  const changeEntity = useCallback(async () => {
    setIsLoading(true);

    await changeDefaultEntity(entityId, {
      onSuccess: redirectToPage,
      onError: goToFallbackPage,
    });

    setIsLoading(false);
  }, [entityId, redirectToPage, goToFallbackPage]);

  useEffect(() => {
    if (!isNotificationTypeSupported(type) || !notificationId || !entityId) {
      goToFallbackPage();
      return;
    }

    if (!defaultEntityId || !driverHash || !isGeneralDataProtectionRegulationAccepted) {
      return;
    }

    const parsedEntityId = parseInt(entityId, 10);

    if (parsedEntityId === defaultEntityId) {
      redirectToPage();
      return;
    }

    (async () => {
      setIsLoading(true);

      const response = await driversDataSource.getEntity(driverHash, parsedEntityId);

      setIsLoading(false);

      if (!response) {
        goToFallbackPage();
        return;
      }

      displayConfirmation({
        title: t("7857") /* Aviso! */,
        message: (
          <span>
            {t("9732") /* A notificação foi emitida para a entidade */}{" "}
            <b>{response.name}</b>. {t("9735") /* Desejas trocar para esta entidade? */}
          </span>
        ),
        onConfirm: () => {
          if (response.contracts.length === 0) {
            changeEntity();
            return;
          }

          setContracts(response.contracts);
        },
        onCancel: goToFallbackPage,
      });
    })();
  }, [
    goToFallbackPage,
    redirectToPage,
    notificationId,
    entityId,
    type,
    defaultEntityId,
    driverHash,
    isGeneralDataProtectionRegulationAccepted,
  ]);

  const handleAccept = useCallback(
    async (contractId) => {
      setIsLoading(true);

      const result = await saveEntityContractAcceptance(driverHash, entityId, {
        contractId,
      });

      if (!result) {
        setIsLoading(false);
        goToFallbackPage();
        return;
      }

      if (contracts.length === 1) {
        await changeEntity();
        return;
      }

      setTimeout(() => {
        setIsLoading(false);
        setContracts((currentContracts) =>
          currentContracts.filter((contract) => contract.id !== contractId)
        );
      }, contractDelay);
    },
    [driverHash, entityId, goToFallbackPage, changeEntity, contracts]
  );

  return currentContract ? (
    <ContractsPopup
      id={currentContract.id}
      title={currentContract.title}
      description={currentContract.description}
      onClose={goToFallbackPage}
      handleAccept={handleAccept}
      acceptButtonText={t("6387") /* Aceitar */}
      rejectButtonText={t("9") /* Voltar */}
    />
  ) : null;
};

export default NotificationPage;
