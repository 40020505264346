export const actionTypes = {
  SET_IDENTIFICATION_DATA: "SET_IDENTIFICATION_DATA",
  SET_TERMS_AND_CONDITIONS_ACCEPTED: "SET_TERMS_AND_CONDITIONS_ACCEPTED",
  SET_PERSONAL_DATA: "SET_PERSONAL_DATA",
  SET_DOCUMENTS: "SET_DOCUMENTS",
};

const setIdentificationData = (
  state,
  { isIdentificationDataLoading, identificationData }
) => ({
  ...state,
  isIdentificationDataLoading,
  identificationData,
});

const setTermsAndConditionsAccepted = (state, areTheTermsAndConditionsAccepted) => ({
  ...state,
  areTheTermsAndConditionsAccepted,
});

const setPersonalData = (state, personalData) => ({
  ...state,
  personalData: { ...state.personalData, ...personalData },
});

const setDocuments = (state, documents) => ({
  ...state,
  documents,
});

const actions = {
  [actionTypes.SET_IDENTIFICATION_DATA]: setIdentificationData,
  [actionTypes.SET_TERMS_AND_CONDITIONS_ACCEPTED]: setTermsAndConditionsAccepted,
  [actionTypes.SET_PERSONAL_DATA]: setPersonalData,
  [actionTypes.SET_DOCUMENTS]: setDocuments,
};

export default actions;
