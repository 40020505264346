import React from "react";
import PropTypes from "prop-types";

const ConditionalWrapper = ({ condition, wrapper, children }) => {
  return <>{condition ? React.cloneElement(wrapper, { children }) : children}</>;
};

ConditionalWrapper.propTypes = {
  condition: PropTypes.bool.isRequired,
  wrapper: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default ConditionalWrapper;
