import React from "react";
import { Divider, Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import SimpleDefinition from "../../components/Simple";
import { notificationsDefaultProps, notificationsPropTypes } from "../../propTypes";
import NotificationLongParkingDefinition from "./NotificationLongParking";
import { settingsMapping } from "../../../../../utils/settings";
import TabContainer from "../../components/TabContainer";

const NotificationsTab = ({
  notificationLongParkings,
  notificationBalanceAvailable,
  notificationInfractions,
  notificationNoBalance,
  notificationMaxTimeExceeded,
  isSmallDevicesVersion,
}) => {
  const { t } = useTranslation();

  return (
    <TabContainer>
      {isSmallDevicesVersion && (
        <Text className="mb-20" variant="body8">
          {t("260") /* Notificações */}
        </Text>
      )}
      <NotificationLongParkingDefinition
        className="mb-10"
        title={t("8549") /* Estacionamento longo */}
        description={
          t(
            "8550"
          ) /* Notificar-me quando um estacionamento Start&Stop estiver a decorrer há muito tempo */
        }
        definitionKey={settingsMapping.notificationLongParkings}
        value={notificationLongParkings}
      />
      <Divider color="secondary" />
      <SimpleDefinition
        className="mb-10 mt-10"
        title={t("4080") /* Carregamento de Saldo */}
        description={
          t("8540") /* Notificar-me quando um carregamento de saldo estiver disponível */
        }
        definitionKey={settingsMapping.notificationBalanceAvailable}
        checked={notificationBalanceAvailable}
      />
      <Divider color="secondary" />
      <SimpleDefinition
        className="mb-10 mt-10"
        title={t("193") /* Infrações */}
        description={
          t("8541") /* Notificar-me quando um dos veículos receber uma infração */
        }
        definitionKey={settingsMapping.notificationInfractions}
        checked={notificationInfractions}
      />
      <Divider color="secondary" />
      <SimpleDefinition
        className="mb-10 mt-10"
        title={t("8545") /* Sem Saldo */}
        description={
          t(
            "8546"
          ) /* Notificar-me quando um estacionamento for interrompido por falta de saldo */
        }
        definitionKey={settingsMapping.notificationNoBalance}
        checked={notificationNoBalance}
      />
      <Divider color="secondary" />
      <SimpleDefinition
        className="mb-10 mt-10"
        title={t("8547") /* Tempo máximo de estacionamento */}
        description={
          t(
            "8548"
          ) /* Notificar-me quando um estacionamento for interrompido por ter excedido o tempo máximo permitido */
        }
        definitionKey={settingsMapping.notificationMaxTimeExceeded}
        checked={notificationMaxTimeExceeded}
      />
    </TabContainer>
  );
};

NotificationsTab.propTypes = notificationsPropTypes;

NotificationsTab.defaultProps = notificationsDefaultProps;

export default NotificationsTab;
