import React from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";
import { ReactComponent as ParkedCarDarkImage } from "../../../assets/images/parked-car-dark.svg";
import { ReactComponent as ParkedCarLightImage } from "../../../assets/images/parked-car-light.svg";
import { themesMapping } from "../../../themes/theme";
import { useBackofficeThemeContext } from "./ThemeProvider";

export const carAnimation = keyframes`
  0% {
    transform: translate(-50px, 30px);
    opacity: 1;
  }

  100% {
    transform: translate(110px, 150px);
    opacity: 0;
  }
`;

export const checkAnimation = keyframes`
  0% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const PARK_ANIMATION_TIME = 0.3;
const FINISH_PARK_ANIMATION_TIME = 2;
const CHECK_ANIMATION_TIME = 1;

export const parkingStatesMapping = {
  beforeParking: "beforeParking",
  parked: "parked",
  afterParking: "afterParking",
};

const imageStyles = css`
  width: ${({ width }) => width}px;

  .parked-car-check {
    opacity: 0;

    ${({ $state }) =>
      parkingStatesMapping.afterParking === $state &&
      css`
        animation: ${checkAnimation} ${CHECK_ANIMATION_TIME}s ease forwards;
      `};
  }

  .parked-car {
    ${({ $state }) =>
      parkingStatesMapping.parked === $state && `transform: translate(-50px, 30px)`};

    ${({ $state }) =>
      parkingStatesMapping.afterParking === $state &&
      css`
        animation: ${carAnimation} ${FINISH_PARK_ANIMATION_TIME}s ease forwards;
      `};

    transition: transform ${PARK_ANIMATION_TIME}s ease;
  }

  .parked-car-color {
    ${({ $state }) =>
      parkingStatesMapping.parked === $state && `transform: translate(-50px, 30px)`};

    ${({ $state }) =>
      parkingStatesMapping.afterParking === $state &&
      css`
        animation: ${carAnimation} ${FINISH_PARK_ANIMATION_TIME}s ease forwards;
      `};

    transition: transform ${PARK_ANIMATION_TIME}s ease;

    path {
      fill: ${({ color }) => color};
    }
  }

  .parked-car-line {
    visibility: visible;
    opacity: 0;
    opacity: ${({ $state }) => ($state === parkingStatesMapping.parked ? `1` : `0`)};
    transition: visible ${PARK_ANIMATION_TIME}s ease;
  }
`;

const StyledParkedCarDarkImage = styled(ParkedCarDarkImage)`
  ${imageStyles}
`;

const StyledParkedCarLightImage = styled(ParkedCarLightImage)`
  ${imageStyles}
`;

const ParkedCar = ({ color, width, state }) => {
  const { backofficeThemeName } = useBackofficeThemeContext();

  const StyledParkedCarImage =
    backofficeThemeName === themesMapping.dark
      ? StyledParkedCarDarkImage
      : StyledParkedCarLightImage;

  return <StyledParkedCarImage $state={state} width={width} color={color} />;
};

ParkedCar.propTypes = {
  color: PropTypes.string,
  state: PropTypes.oneOf([
    parkingStatesMapping.beforeParking,
    parkingStatesMapping.parked,
    parkingStatesMapping.afterParking,
  ]),
  width: PropTypes.number,
};

ParkedCar.defaultProps = {
  width: 140,
  state: parkingStatesMapping.parked,
  color: null,
};

export default ParkedCar;
