import normal from "./normal";
import dark from "./dark";
import publicTheme from "./public";

const localStorageBackofficeThemeKey = "backoffice-theme";

export const themes = {
  normal,
  dark,
  public: publicTheme,
};

export const themesMapping = {
  dark: "dark",
  normal: "normal",
  public: "public",
  defaultBackoffice: "dark",
};

const backOfficeAvailableThemes = [themesMapping.dark, themesMapping.normal];

const getSystemTheme = () => {
  return window.matchMedia("(prefers-color-scheme: dark)").matches
    ? themesMapping.dark
    : themesMapping.normal;
};

export const setStoredBackofficeTheme = (theme) => {
  return localStorage.setItem(localStorageBackofficeThemeKey, theme);
};

export const getStoredBackofficeTheme = () => {
  return localStorage.getItem(localStorageBackofficeThemeKey);
};

export const hasSystemThemeOnBackoffice = () => {
  const theme = getStoredBackofficeTheme();

  return !backOfficeAvailableThemes.includes(theme);
};

export const getBackofficeTheme = () => {
  const storedBackofficeTheme = getStoredBackofficeTheme();

  return hasSystemThemeOnBackoffice() ? getSystemTheme() : storedBackofficeTheme;
};

export const getBackofficeThemeConfiguration = () => {
  const theme = hasSystemThemeOnBackoffice()
    ? getSystemTheme()
    : getStoredBackofficeTheme();

  return themes[theme];
};
