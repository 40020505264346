import { useAuth } from "components/AuthProvider";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCoverage } from "../controller";
import { usePermitHoldersDetailsContext } from "../Provider";
import { actionTypes } from "../store/actions";

const groupByZone = (streets) => {
  const zones = {};

  streets.forEach((street) => {
    if (zones[street.zone.id]) {
      zones[street.zone.id].items.push({ label: street.name, id: street.id });
    } else {
      zones[street.zone.id] = {
        id: `z-${street.zone.id}`,
        label: street.zone.name,
        items: [{ label: street.name, id: street.id }],
      };
    }
  });

  return Object.values(zones);
};

const useLocations = () => {
  const { defaultEntityId: entityId, driverHash } = useAuth();
  const { id: permitHolderId, dispatch } = usePermitHoldersDetailsContext();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      dispatch({ type: actionTypes.LOCATIONS_TAB.IS_LOADING, payload: true });

      const response = await getCoverage(entityId, driverHash, permitHolderId, { t });

      dispatch([
        { type: actionTypes.LOCATIONS_TAB.IS_LOADING, payload: false },
        { type: actionTypes.LOCATIONS_TAB.HAS_ERROR, payload: response === null },
        { type: actionTypes.LOCATIONS_TAB.LOCATIONS, payload: response || [] },
        {
          type: actionTypes.LOCATIONS_TAB.ZONES,
          payload: response ? groupByZone(response) : [],
        },
      ]);
    })();
  }, []);
};

export default useLocations;
