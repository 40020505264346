import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import SwitchField from "../../components/SwitchField";
import NotificationTimeField from "./notificationTime/Field";
import { useSettingsProvider } from "../../Provider";
import { defaultDuration } from "../../controller";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const NotificationLongParkingDefinition = ({
  title,
  description,
  definitionKey,
  value,
  className,
}) => {
  const [toggleTimeOptions, setToggleTimeOptions] = useState(Boolean(value));
  const { saveSettings } = useSettingsProvider();

  const onSwitchChange = useCallback(
    (event) => {
      const isEnabled = event.target.checked;
      setToggleTimeOptions(isEnabled);

      if (isEnabled) {
        saveSettings(definitionKey, value || defaultDuration);
      } else {
        saveSettings(definitionKey, 0);
      }
    },
    [saveSettings, value]
  );

  const handleSetMinutes = useCallback(
    (minutes) => {
      saveSettings(definitionKey, minutes);
    },
    [saveSettings]
  );

  useEffect(() => {
    setToggleTimeOptions(Boolean(value));
  }, [value]);

  return (
    <Container className={className}>
      <SwitchField
        title={title}
        description={description}
        definitionKey={definitionKey}
        checked={toggleTimeOptions}
        onChange={onSwitchChange}
      />
      {toggleTimeOptions ? (
        <NotificationTimeField
          selectedMinutes={value}
          handleSetMinutes={handleSetMinutes}
        />
      ) : null}
    </Container>
  );
};

NotificationLongParkingDefinition.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  definitionKey: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

NotificationLongParkingDefinition.defaultProps = {
  className: null,
};

export default NotificationLongParkingDefinition;
