import { useState, useEffect, useRef } from "react";
import usePrevious from "../../../hooks/usePrevious";

const useChooseDuration = (
  calculatedParking,
  calculateParkingCallback,
  minDuration = 0
) => {
  const [chosenDuration, setChosenDuration] = useState(null);
  const previousChosenDuration = usePrevious(chosenDuration);
  const hasError = useRef(false);

  useEffect(() => {
    if (!chosenDuration) {
      return;
    }

    if (calculatedParking.error && !hasError.current) {
      // Only execute one time by error
      hasError.current = true;
      setChosenDuration(calculatedParking.duration || minDuration); // Change timer to last parking calculation without errors
      return;
    }

    if (
      chosenDuration !== previousChosenDuration && // Prevent infinite loop due to dependency of chosenDuration
      chosenDuration !== calculatedParking.duration // Prevent repeat the last calculated request
    ) {
      calculateParkingCallback(chosenDuration);
    }
  }, [chosenDuration, calculatedParking]);

  useEffect(() => {
    setChosenDuration(calculatedParking.duration || 0);
  }, [calculatedParking.duration]);

  useEffect(() => {
    if (!calculatedParking.error) {
      hasError.current = false;
    }
  }, [calculatedParking.error]);

  return { chosenDuration, setChosenDuration };
};

export default useChooseDuration;
