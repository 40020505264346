import i18n from "../../../i18n";
import DriversDataSource from "../../../lib/clients/iParque/dataSources/driversDataSource";
import { EXPORTATION_FILE_NAME } from "../../../utils/configs";
import { downloadJson, getObjectKeyByValue } from "../../../utils/general";
import {
  newslettersSettings,
  notificationsSettings,
  serverSettingsMapping,
  settingsMapping,
} from "../../../utils/settings";
import { errorMessage } from "../../../utils/userMessages";

const driversDataSource = new DriversDataSource();

const isDefinitionEnabled = (definition) => {
  return definition === "1";
};

const parseBoolToInt = (bool) => {
  return bool ? "1" : "0";
};

const processSettings = (settings) => {
  const notificationsSettingsProcessed = {};
  const newslettersSettingsProcessed = {};

  settings.forEach((setting) => {
    const settingKey = getObjectKeyByValue(serverSettingsMapping, setting.name);
    const value =
      settingKey === settingsMapping.notificationLongParkings
        ? parseInt(setting.value, 10)
        : isDefinitionEnabled(setting.value);

    if (notificationsSettings.includes(settingKey)) {
      notificationsSettingsProcessed[settingKey] = value;
    }

    if (newslettersSettings.includes(settingKey)) {
      newslettersSettingsProcessed[settingKey] = value;
    }
  });

  return {
    notifications: notificationsSettingsProcessed,
    newsletters: newslettersSettingsProcessed,
  };
};

export const getSettings = async (authHash, cancelToken = null) => {
  try {
    const settings = await driversDataSource.getSettings(
      authHash,
      {},
      false,
      cancelToken
    );

    return processSettings(settings.items);
  } catch (error) {
    errorMessage(error, i18n.t("8536") /* Ocorreu um erro ao obter as definições */);
    return null;
  }
};

export const saveSettings = async (authHash, key, value) => {
  const processedValue = (() => {
    if (key === settingsMapping.language) {
      return value;
    }

    return Number.isInteger(value) ? value : parseBoolToInt(value);
  })();

  try {
    await driversDataSource.saveSettings(authHash, {
      name: serverSettingsMapping[key],
      value: processedValue,
    });

    return true;
  } catch (error) {
    errorMessage(error, i18n.t("8543") /* Ocorreu um erro ao guardar a definição */);

    return false;
  }
};

export const exportData = async (authHash) => {
  try {
    const data = await driversDataSource.getAllData(authHash);

    downloadJson(data, `${EXPORTATION_FILE_NAME}.json`, "text/json");
  } catch (error) {
    errorMessage(error, i18n.t("8599") /* Ocorreu um erro ao exportar os teus dados */);
  }
};

export const defaultDuration = 30;

export const notificationDurationOptions = [30, 60, 90, 120];

export const settingsTabs = {
  notifications: "notifications",
  newsletters: "newsletters",
  account: "account",
  language: "language",
};
