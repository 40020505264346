import React from "react";
import { useTranslation } from "react-i18next";
import StatusWidget from "../StatusWidget";

const ErrorComponent = () => {
  const { t } = useTranslation();

  return (
    <StatusWidget variant="error">
      {
        t(
          "10561"
        ) /* Alerta! Ocorreu um erro ao tentar obter os documentos. Por favor, tenta novamente. */
      }
    </StatusWidget>
  );
};

export default ErrorComponent;
