import styled from "styled-components";
import { breakpoints } from "../../../../utils/breakpoints";

const TabContainer = styled.div`
  width: 650px;
  max-width: 700px;

  @media (min-width: ${breakpoints.xl}) {
    width: 700px;
  }

  @media (max-width: ${breakpoints.lg}) {
    width: 100%;
    max-width: unset;
  }
`;

export default TabContainer;
