import DropdownList from "domain/private/components/DropdownList";
import React from "react";
import LocationsMap from "../../../components/locations/Map";
import LocationsTabStatus from "../../../components/locations/Status";
import TabIdentification from "../../../components/TabIdentification";
import TabTitle from "../../../components/Title";
import { tabsIdsMap } from "../../../helper";
import useLocationsTab from "../../../hooks/useLocationsTab";
import TabDetailsOperations from "../../operations/TabDetails";

const LocationsTab = () => {
  const {
    mapController,
    selectedStreet,
    setSelectedStreet,
    zones,
    isLoading,
  } = useLocationsTab();

  return (
    <>
      <TabDetailsOperations className="mb-10" />
      <TabIdentification tabId={tabsIdsMap.locations} />
      <TabTitle className="mt-20" tabId={tabsIdsMap.locations} />
      <LocationsTabStatus />
      {(zones.length > 0 || isLoading) && (
        <>
          <DropdownList items={zones} selectedItem={selectedStreet} />
          <LocationsMap mapController={mapController} onStreetClick={setSelectedStreet} />
        </>
      )}
    </>
  );
};

export default LocationsTab;
