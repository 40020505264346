import i18next from "i18next";

export const transactionTypes = {
  subscriptions: 4,
};

const generalDbConfig = {
  atm: {
    id: 2,
    code: "m",
  },
  bankTransference: {
    id: 3,
    code: "t",
  },
  creditCard: {
    id: 6,
    code: "vpp",
  },
  mbway: {
    id: 7,
    code: "mbw",
  },
};

export const paymentMethodsMapping = {
  atmTpa: {
    id: 2,
    code: "CD",
  },
  bankTransference: {
    id: 5,
    code: "t",
  },
  atm: {
    id: 10,
    code: "m",
  },
  creditCard: {
    id: 15,
    code: "cc",
  },
  balance: {
    code: "sd",
    id: 16,
  },
  mbway: {
    code: "mbw",
    id: 21,
  },
};

export const paymentPeriodicityTypes = {
  yearly: 1,
  monthly: 2,
  weekly: 3,
  daily: 4,
};

export const getPaymentCodeById = (paymentMethodId) => {
  const paymentMethodValues = Object.values(paymentMethodsMapping);

  for (let index = 0; index < paymentMethodValues.length; index += 1) {
    const paymentMethodValue = paymentMethodValues[index];

    if (paymentMethodValue.id === paymentMethodId) {
      return paymentMethodValue.code;
    }
  }

  return null;
};

export const paymentTypesMapping = {
  parkingNotice: 1,
  balanceCharge: 2,
  subscriptionPayment: 4,
  parkingPayment: 9,
};

export const externalPaymentWebhookTypes = {
  paymentConfirmed: 1,
  paymentExpired: 2,
  paymentCancelled: 3,
  paymentRefunded: 4,
  paymentMethodError: 5,
};

export const paymentConditions = {
  promptPayment: 1,
};

export const externalPaymentStates = {
  confirmed: "confirmed",
  pendingConfirmation: "pending_confirmation",
  cancelled: "cancelled",
  pending: "pending",
};

export const getPaymentMethodIdToServer = (paymentMethodId) => {
  if (paymentMethodId === paymentMethodsMapping.atm.id) {
    return paymentMethodsMapping.atmTpa.id;
  }

  return paymentMethodId;
};

export const getReadablePaymentPeriodicity = (periodicityType, quantity, { t }) => {
  const periodicityTypeLabels = {
    [paymentPeriodicityTypes.yearly]: {
      singular: t("10217") /* ano */,
      plural: t("3415") /* anos */,
    },
    [paymentPeriodicityTypes.monthly]: {
      singular: t("3410") /* mês */,
      plural: t("1479") /* meses */,
    },
    [paymentPeriodicityTypes.weekly]: {
      singular: t("10218") /* semana */,
      plural: t("2463") /* semanas */,
    },
    [paymentPeriodicityTypes.daily]: {
      singular: t("8012") /* dia */,
      plural: t("1297") /* dias */,
    },
  };

  return `${quantity} ${
    periodicityTypeLabels[periodicityType][quantity === 1 ? "singular" : "plural"]
  }`;
};

const convertGeneralDbPaymentMethodToEntityPaymentMethod = (paymentMethod) => {
  const paymentMethodKey = Object.keys(generalDbConfig).find(
    (key) =>
      generalDbConfig[key].code === paymentMethod.code &&
      generalDbConfig[key].id === paymentMethod.id
  );

  return paymentMethodKey ? paymentMethodsMapping[paymentMethodKey] : paymentMethod;
};

export const processPaymentMethods = (paymentMethods, allowedPaymentMethods = []) => {
  const processedPaymentMethods = [];

  paymentMethods.forEach((paymentMethod) => {
    const processedPaymentMethod = convertGeneralDbPaymentMethodToEntityPaymentMethod({
      id: paymentMethod.id,
      code: paymentMethod.code,
    });

    if (
      !processedPaymentMethod ||
      (allowedPaymentMethods.length > 0 &&
        !allowedPaymentMethods.includes(processedPaymentMethod.id))
    ) {
      return;
    }

    processedPaymentMethods.push({
      id: processedPaymentMethod.id,
      code: processedPaymentMethod.code,
      shortMessage: paymentMethod.shortMessage,
      longMessage: paymentMethod.fullMessage,
      isActive: paymentMethod.isActive,
      title: paymentMethod.description,
      name: paymentMethod.description,
      minAmount: paymentMethod.minimumAmount,
    });
  });

  return processedPaymentMethods;
};

export const getBalancePaymentMethod = (t = i18next.t.bind(i18next)) => ({
  code: paymentMethodsMapping.balance.code,
  id: paymentMethodsMapping.balance.id,
  description: t("8171") /* Saldo Condutor */,
  isActive: true,
  minimumAmount: 0,
  isImplemented: true,
  shortMessage: null,
  fullMessage: null,
});
