import AbstractDataSource from "./abstractDataSource";

export default class ClientsDataSource extends AbstractDataSource {
  async getPartyTypes(entityId, params = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/permits/holders/parties/types`,
      params,
      {},
      useCache
    );
  }

  async create(entityId, data) {
    return this.iParqueClient.POST(`v1/entities/${entityId}/clients`, data);
  }

  async update(entityId, clientId, data) {
    return this.iParqueClient.PUT(`v1/entities/${entityId}/clients/${clientId}`, data);
  }

  async getGenders(entityId, params = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/clients/genders`,
      params,
      {},
      useCache
    );
  }

  async createIdentificationDocument(entityId, clientId, data) {
    return this.iParqueClient.POST(
      `v1/entities/${entityId}/clients/${clientId}/identificationDocuments`,
      data
    );
  }
}
