import React from "react";
import { useTranslation } from "react-i18next";
import { usePermitHoldersContext } from "components/permitHolders/Creation/Provider";
import styled from "styled-components";
import { Heading } from "iparque-components/dist/components";

const PermitTypeTitle = () => {
  const { t } = useTranslation();
  const { selectedPermitType } = usePermitHoldersContext();

  return (
    <Title variant="h7" color="quintenary" className="mb-25">
      {`${t("1848") /* Dístico */} - ${selectedPermitType.permitType.designation}`}
    </Title>
  );
};

export default PermitTypeTitle;

const Title = styled(Heading)`
  font-size: 18px;
  font-weight: ${({ theme }) => theme.typography.weight.bold};
`;
