import React from "react";
import styled from "styled-components";
import { Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../components/smallDevices/ContentHeader";
import CurrentBalance from "./CurrentBalance";
import Tabs from "../../../../components/tabs/horizontal/Tabs";
import MovementList from "./List";
import { accountMovementsProps, accountMovementsDefaultProps } from "../propTypes";

const StyledTabs = styled(Tabs)`
  .tab-label {
    width: 100px;
  }
`;

const SmallDevicesAccountMovements = ({
  getBalanceHistoryCallback,
  getPaymentsHistoryCallback,
  vehiclesColors,
}) => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: "balance-history",
      label: t("8409") /* Movimentos de saldo */,
      component: (
        <MovementList
          className="mt-10"
          getDataCallback={getBalanceHistoryCallback}
          vehiclesColors={vehiclesColors}
        />
      ),
    },
    {
      id: "payments-history",
      label: t("8410") /* Outros movimentos */,
      component: (
        <MovementList
          className="mt-10"
          getDataCallback={getPaymentsHistoryCallback}
          vehiclesColors={vehiclesColors}
        />
      ),
    },
  ];

  return (
    <>
      <ContentHeader
        topTitle={t("8393") /* Não sabes a quantas andas? */}
        bottomTitle={t("8394") /* Consulta aqui o teu saldo! */}
      />
      <Text className="uppercase mt-20" color="quaternary" variant="body9">
        {t("8395") /* Saldo atual */}
      </Text>
      <CurrentBalance />
      <StyledTabs tabs={tabs} className="mt-60" />
    </>
  );
};

SmallDevicesAccountMovements.propTypes = accountMovementsProps;

SmallDevicesAccountMovements.defaultProps = accountMovementsDefaultProps;

export default SmallDevicesAccountMovements;
