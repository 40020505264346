/* eslint-disable react/jsx-props-no-spreading */
import { useValidationSchema } from "components/ValidationSchemaProvider";
import { useField } from "formik";
import { DatePickerField as IParqueDatePickerField } from "iparque-components";
import React from "react";
import i18n from "../../i18n";
import { getCountryConfig } from "../../utils/dateTime";

const DatePickerField = ({ ...props }) => {
  const [field, meta] = useField(props);
  const hasError = meta.error && meta.touched;
  const { isFieldRequired } = useValidationSchema();

  return (
    <IParqueDatePickerField
      error={hasError}
      {...field}
      {...props}
      clearLabel={i18n.t("4160") /* Limpar */}
      cancelLabel={i18n.t("48") /* Cancelar */}
      helperText={hasError ? meta.error : ""}
      format={getCountryConfig().date}
      placeholder={getCountryConfig().readableDate}
      mask={getCountryConfig().mask}
      required={isFieldRequired?.(field.name)}
    />
  );
};

export default DatePickerField;
