import PropTypes from "prop-types";

export const formProps = {
  name: PropTypes.string,
  partyTypeId: PropTypes.number,
  countryId: PropTypes.number,
  taxpayerIdentificationNumber: PropTypes.string,
  commercialName: PropTypes.string,
  economicActivityCode: PropTypes.string,
  address: PropTypes.string,
  complementaryAddress: PropTypes.string,
  zipCode: PropTypes.string,
  locality: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  mobilePhone: PropTypes.string,
};
