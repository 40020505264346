import Skeleton from "components/Skeleton";
import React from "react";
import styled from "styled-components";

const IdentificationSkeleton = () => {
  return (
    <>
      <StyledSkeleton height={50} />
      <StyledSkeleton height={50} />
      <Container>
        <Skeleton className="rowItem fill" height={50} />
        <Skeleton className="rowItem fill" height={50} />
      </Container>
      <Container>
        <Skeleton className="rowItem fill" height={50} />
        <Skeleton className="rowItem fill" height={50} />
      </Container>
      <StyledSkeleton height={50} />
      <Container>
        <Skeleton className="rowItem fill" height={50} />
        <Skeleton className="rowItem fill" height={50} />
        <Skeleton className="rowItem fill" height={50} />
      </Container>
      <StyledSkeleton height={70} />
      <ButtonSkeleton width={170} height={40} />
    </>
  );
};

export default IdentificationSkeleton;

const StyledSkeleton = styled(Skeleton)`
  margin: 10px 0;
`;

const ButtonSkeleton = styled(Skeleton)`
  margin: 20px 0 0 auto;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;

  .rowItem {
    margin: 10px 0;
    min-width: 200px;
  }
`;
