import i18n from "../i18n";
import { countriesMapping } from "./countries";
import { languageWithoutRegion } from "./general";

const language = languageWithoutRegion(i18n.language || window.localStorage.i18nextLng);

const moneyDecimalPlaces = 2;

const countriesCurrency = {
  [countriesMapping.pt]: {
    currency: "€",
  },
  [countriesMapping.es]: {
    currency: "€",
  },
  [countriesMapping.en]: {
    currency: "€",
  },
};

export const formatMoney = (
  amount,
  { country = countriesMapping[language], centsFormat = true } = {}
) => {
  if (!amount && amount !== 0) {
    return `- ${countriesCurrency[country].currency}`;
  }

  const processedAmount = centsFormat ? amount / 100 : amount;

  return `${processedAmount.toFixed(moneyDecimalPlaces)} ${
    countriesCurrency[country].currency
  }`;
};

export const convertToCents = (euroAmount) => euroAmount * 100;

export const convertFromCents = (centsAmount) => centsAmount / 100;
