import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Heading, Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "../../../../utils/dateTime";

const StyledTypography = css`
  color: ${({ textColor }) => textColor};
`;

const StyledHeading = styled(Heading)`
  line-height: 20px;
  ${StyledTypography}
`;

const StyledText = styled(Text)`
  ${StyledTypography}
`;

const ParkingDetails = ({
  vehicleColor,
  vehicleBrand,
  vehicleLicensePlate,
  streetName,
  explorationName,
  endingDate,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledHeading textColor={vehicleColor} variant="h4">
        {vehicleLicensePlate}
      </StyledHeading>
      <StyledText textColor={vehicleColor} variant="body8">
        {vehicleBrand}
      </StyledText>
      <Text className="mt-15" variant="body1">
        {streetName}
      </Text>
      <Text variant="body8">{explorationName}</Text>
      {endingDate && (
        <>
          <Text className="mt-15" variant="body10">
            {t("8459") /* Data de fim */}
          </Text>
          <Text variant="body7">{formatDateTime(endingDate)}</Text>
        </>
      )}
    </>
  );
};

ParkingDetails.propTypes = {
  vehicleColor: PropTypes.string,
  vehicleBrand: PropTypes.string,
  vehicleLicensePlate: PropTypes.string,
  streetName: PropTypes.string,
  explorationName: PropTypes.string,
  endingDate: PropTypes.string,
};

ParkingDetails.defaultProps = {
  vehicleColor: null,
  vehicleBrand: "",
  vehicleLicensePlate: "",
  streetName: "",
  explorationName: "",
  endingDate: "",
};

export default ParkingDetails;
