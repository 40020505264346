import Button from "components/buttons/Button";
import { ValidationSchemaProvider } from "components/ValidationSchemaProvider";
import { Form as FormikForm, Formik } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import Body from "./Body";
import useForm from "./useForm";

const PersonalDataForeignForm = ({ readOnly }) => {
  const { t } = useTranslation();
  const { initialData, validationSchema, submit } = useForm();

  return (
    <ValidationSchemaProvider schema={validationSchema}>
      <Formik
        enableReinitialize
        initialValues={initialData}
        validationSchema={readOnly ? undefined : validationSchema}
        onSubmit={submit}
      >
        {({ values }) => (
          <StyledForm>
            <Body values={values} readOnly={readOnly} />
            {!readOnly && (
              <ButtonContainer>
                <Button type="submit" color="quintenary" fullWidth>
                  {t("10223") /* Avançar */}
                </Button>
              </ButtonContainer>
            )}
          </StyledForm>
        )}
      </Formik>
    </ValidationSchemaProvider>
  );
};

PersonalDataForeignForm.propTypes = {
  readOnly: PropTypes.bool,
};

PersonalDataForeignForm.defaultProps = {
  readOnly: false,
};

export default PersonalDataForeignForm;

const StyledForm = styled(FormikForm)`
  display: flex;
  flex-flow: column wrap;
  flex: 1;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 30px 0 0 auto;
  width: 170px;

  @media (max-width: ${breakpoints.sm}) {
    justify-content: center;
    width: 100%;
  }
`;
