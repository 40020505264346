import React, { useCallback, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Text } from "iparque-components";
import { paymentMethodsMapping, paymentTypesMapping } from "utils/payment";
import { useAuth } from "components/AuthProvider";
import { breakpoints } from "utils/breakpoints";
import FlexBox from "components/FlexBox";
import { formatMoney } from "utils/money";
import ATMPaymentBox from "domain/private/components/paymentMethods/ATMPaymentBox";
import BalancePaymentBox from "domain/private/components/paymentMethods/BalancePaymentBox";
import MbWayPaymentBox from "domain/private/components/paymentMethods/MbWayPaymentBox";
import { usePaymentContext } from "./store/Store";
import { generatePayment, payNoticeWithBalance } from "./controller";
import useNotices from "../hooks/useNotices";

const Container = styled(FlexBox)`
  flex-direction: column;

  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;
    align-items: flex-start;

    > div {
      flex: 1;
    }
  }

  .payment-method-box {
    &:not(:first-child) {
      margin-top: 10px;

      @media (min-width: ${breakpoints.xl}) {
        margin-top: 0;
        margin-left: 30px;
      }
    }
  }
`;

const StyleText = styled(Text)`
  /* Prevents the word wrapping in amount */
  b {
    white-space: nowrap;
  }
`;

const PaymentMethods = ({ noticeIds, amountToPay }) => {
  const { paymentMethods } = usePaymentContext();
  const { backToList } = useNotices();
  const { defaultEntityId, authHash, updateBalance } = useAuth();
  const [atmPaymentDetails, setAtmPaymentDetails] = useState();
  const { t } = useTranslation();

  const onAtmBoxClick = useCallback(async () => {
    setAtmPaymentDetails({
      isLoading: true,
    });

    const details = await generatePayment(defaultEntityId, noticeIds, {
      paymentTypeId: paymentMethodsMapping.atm.id,
    });

    if (!details) {
      setAtmPaymentDetails({
        isLoading: false,
      });
      return;
    }

    setAtmPaymentDetails({
      amount: details.amount,
      bankEntity: details.bankEntityNumber,
      reference: details.reference,
      isLoading: false,
    });
  }, [noticeIds]);

  const onSuccess = useCallback(() => {
    updateBalance();
    backToList();
  }, [updateBalance, backToList]);

  const handlePay = useCallback(async () => {
    const result = await payNoticeWithBalance(defaultEntityId, authHash, {
      noticeIds: noticeIds.toString(),
    });

    if (result) {
      onSuccess();
    }
  }, [authHash, noticeIds, onSuccess]);

  const onGenerateMbWayPayment = useCallback(
    async (params) => {
      const paymentDetails = await generatePayment(defaultEntityId, noticeIds, {
        ...params,
        paymentTypeId: paymentMethodsMapping.mbway.id,
      });

      return paymentDetails?.id;
    },
    [defaultEntityId, noticeIds]
  );

  const renderPaymentMethod = useCallback(
    (paymentMethod) => {
      if (!paymentMethod.isActive) {
        return null;
      }

      switch (paymentMethod.id) {
        case paymentMethodsMapping.atm.id:
          return (
            <ATMPaymentBox
              key="atm-payment"
              longMessage={paymentMethod.longMessage}
              onClick={onAtmBoxClick}
              title={paymentMethod.name}
              {...atmPaymentDetails}
            />
          );
        case paymentMethodsMapping.mbway.id:
          return (
            <MbWayPaymentBox
              key="mbway-payment"
              title={paymentMethod.name}
              shortMessage={paymentMethod.shortMessage}
              longMessage={paymentMethod.longMessage}
              onGeneratePayment={onGenerateMbWayPayment}
              onSuccess={onSuccess}
              amount={amountToPay}
              itemTypeId={paymentTypesMapping.parkingNotice}
            />
          );
        case paymentMethodsMapping.balance.id:
          return (
            <BalancePaymentBox
              key="balance-payment"
              handlePay={handlePay}
              amount={amountToPay}
              extraInfo={
                <StyleText variant="body8">
                  {
                    noticeIds.length > 1
                      ? t(
                          "9314"
                        ) /* Estás prestes a pagar várias infrações num valor total de */
                      : t("9308") /* Estás prestes a pagar uma infração de */
                  }
                  &nbsp;
                  <b>{formatMoney(amountToPay, { centsFormat: false })}</b>
                  &nbsp;
                  {t("9309") /* com o teu saldo de condutor iParque. */}
                </StyleText>
              }
            />
          );

        default:
          return null;
      }
    },
    [atmPaymentDetails, noticeIds]
  );

  return (
    <Container>
      {paymentMethods.map((paymentMethod) => renderPaymentMethod(paymentMethod))}
    </Container>
  );
};

PaymentMethods.propTypes = {
  noticeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  amountToPay: PropTypes.number.isRequired,
};

export default PaymentMethods;
