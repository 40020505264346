import DistrictsDataSource from "lib/clients/iParque/dataSources/districtsDataSource";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { errorMessage } from "utils/userMessages";

const districtsDataSource = new DistrictsDataSource();

const useDistricts = (entityId) => {
  const { t } = useTranslation();
  const [districtsOptions, setDistrictsOptions] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await districtsDataSource.getAll(
          entityId,
          { noLimit: true },
          true
        );

        setDistrictsOptions(
          response.items.map((district) => ({ value: district.id, label: district.name }))
        );
      } catch (error) {
        errorMessage(error, t("10415") /* Ocorreu um erro ao obter os distritos */);
      }
    })();
  }, []);

  return { districtsOptions };
};

export default useDistricts;
