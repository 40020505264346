import PropTypes from "prop-types";
import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import {
  getEntityById,
  getEntityThemeByType,
  getPublicEntityConfigs,
} from "requests/entities";
import { entityThemes } from "utils/entities";
import Error400 from "./components/errors/400";
import Error403 from "./components/errors/403";

const tokenRegex = /[a-zA-Z0-9]{32}$/;

const initialState = {
  hasEntityRequestError: false,
  isEntityDataLoading: true,
  entity: undefined,
  entityTheme: undefined,
  entityConfigs: undefined,
};

const PublicEntityContext = createContext(initialState);

const PublicEntityProvider = ({ children }) => {
  const { entityToken } = useParams();
  const [state, setState] = useState(initialState);

  const isTokenValid = tokenRegex.test(entityToken);

  const getEntityConfig = useCallback((config) => state.entityConfigs[config], [
    state.entityConfigs,
  ]);

  useEffect(() => {
    if (!isTokenValid) {
      return;
    }

    (async () => {
      const entityData = await getEntityById(entityToken);

      if (!entityData) {
        setState((currentState) => ({
          ...currentState,
          isEntityDataLoading: false,
          hasEntityRequestError: true,
        }));
        return;
      }

      const [entityTheme, entityConfigs] = await Promise.all([
        getEntityThemeByType(entityData.id, entityThemes.light),
        getPublicEntityConfigs(entityData.id),
      ]);

      if (!entityTheme || !entityConfigs) {
        setState((currentState) => ({
          ...currentState,
          isEntityDataLoading: false,
          hasEntityRequestError: true,
        }));
        return;
      }

      const configsMap = {};

      entityConfigs.forEach((config) => {
        configsMap[config.key] = config.value;
      });

      setState({
        isEntityDataLoading: false,
        hasEntityRequestError: false,
        entity: entityData,
        entityTheme,
        entityConfigs: configsMap,
      });
    })();
  }, []);

  if (!isTokenValid) {
    return <Error403 />;
  }

  if (state.hasEntityRequestError) {
    return <Error400 />;
  }

  return (
    <PublicEntityContext.Provider value={{ ...state, getEntityConfig }}>
      {children}
    </PublicEntityContext.Provider>
  );
};

export default PublicEntityProvider;

PublicEntityProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const usePublicEntityContext = () => {
  return useContext(PublicEntityContext);
};
