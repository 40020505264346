import i18n from "i18next";
import DriversDataSource from "lib/clients/iParque/dataSources/driversDataSource";
import NoticesDataSource from "lib/clients/iParque/dataSources/noticesDataSource";
import PaymentsDataSource from "lib/clients/iParque/dataSources/paymentsDataSource";
import {
  getPaymentMethodIdToServer,
  paymentMethodsMapping,
  processPaymentMethods,
} from "utils/payment";
import { errorMessage, successMessage } from "utils/userMessages";

const paymentsDataSource = new PaymentsDataSource();
const noticesDataSource = new NoticesDataSource();
const driversDataSource = new DriversDataSource();

const allowedPaymentMethods = [
  paymentMethodsMapping.atm.id,
  paymentMethodsMapping.mbway.id,
];

export const getPaymentMethods = async (entityId, params, { cancelToken }) => {
  try {
    const response = await paymentsDataSource.getNoticePaymentMethods(
      entityId,
      {
        ...params,
        noLimit: true,
      },
      false,
      cancelToken
    );

    return processPaymentMethods(response.items, allowedPaymentMethods);
  } catch (error) {
    errorMessage(
      error,
      i18n.t("9306") /* Ocorreu um erro ao obter os métodos de pagamento das infrações */
    );

    return null;
  }
};

export const generatePayment = async (entityId, noticeIds, params) => {
  try {
    const response = await noticesDataSource.generatePayment(entityId, {
      ...params,
      noticeIds: noticeIds.toString(),
      paymentTypeId: getPaymentMethodIdToServer(params.paymentTypeId),
    });

    return response;
  } catch (error) {
    errorMessage(
      error,
      i18n.t("9307") /* Ocorreu um erro ao gerar os dados de pagamento do aviso */
    );

    return null;
  }
};

export const payNoticeWithBalance = async (entityId, authHash, params) => {
  try {
    await driversDataSource.payNoticeWithBalance(entityId, authHash, params);

    if (params.noticeIds.length === 1) {
      successMessage(i18n.t("9311") /* Infração paga com sucesso */);
    } else {
      successMessage(i18n.t("9324") /* Infrações pagas com sucesso  */);
    }
    return true;
  } catch (error) {
    errorMessage(error, i18n.t("9312") /* Ocorreu um erro ao pagar a infração */);

    return false;
  }
};

export const getNoticesResume = (notices) =>
  notices.reduce(
    (resume, notice) => {
      const newAmount = resume.amount + notice.amount;
      return {
        ids: [...resume.ids, notice.id],
        amount: newAmount,
      };
    },
    { ids: [], amount: 0 }
  );
