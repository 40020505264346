import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import EmptyList from "domain/private/components/EmptyList";
import useOnClickCreate from "../hooks/useOnClickCreate";

const EmptyUnapprovedList = ({ justify }) => {
  const { t } = useTranslation();
  const { onClickNewPermitHolder } = useOnClickCreate();

  return (
    <EmptyList
      justify={justify}
      title={t("10423") /* Não tens processos por aprovar */}
      subtitle={t("10424") /* Clica no botão para adicionar. */}
      button={{ label: t("3387") /* Adicionar */, action: onClickNewPermitHolder }}
    />
  );
};

export default EmptyUnapprovedList;

EmptyUnapprovedList.propTypes = {
  justify: PropTypes.oneOf(["space-between", "center"]),
};

EmptyUnapprovedList.defaultProps = {
  justify: undefined,
};
