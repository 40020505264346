import React from "react";
import { useTranslation } from "react-i18next";
import { tabsIdsMap } from "../../helper";
import { usePermitHoldersDetailsContext } from "../../Provider";
import Alert from "./Alert";

const MissingVehicles = () => {
  const { t } = useTranslation();
  const { tabsRef } = usePermitHoldersDetailsContext();

  const displayVehiclesTab = () => tabsRef.current?.changeTab(tabsIdsMap.vehicles);

  return (
    <Alert
      variant="warning"
      onClick={displayVehiclesTab}
      onClickTitle={t("10638") /* Ir para tab veículos */}
      title={t("10566") /* São necessários veículos para a aprovação do dístico. */}
    />
  );
};

export default MissingVehicles;
