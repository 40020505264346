import PropTypes from "prop-types";
import { settingsMapping } from "../../../utils/settings";

export const notificationsPropTypes = {
  [settingsMapping.notificationLongParkings]: PropTypes.number,
  [settingsMapping.notificationBalanceAvailable]: PropTypes.bool,
  [settingsMapping.notificationInfractions]: PropTypes.bool,
  [settingsMapping.notificationNoBalance]: PropTypes.bool,
  [settingsMapping.notificationMaxTimeExceeded]: PropTypes.bool,
  isSmallDevicesVersion: PropTypes.bool,
};

export const notificationsDefaultProps = {
  [settingsMapping.notificationLongParkings]: 0,
  [settingsMapping.notificationBalanceAvailable]: false,
  [settingsMapping.notificationInfractions]: false,
  [settingsMapping.notificationNoBalance]: false,
  [settingsMapping.notificationMaxTimeExceeded]: false,
  isSmallDevicesVersion: false,
};

export const newslettersPropTypes = {
  [settingsMapping.receiveNewslettersNewFeatures]: PropTypes.bool,
  [settingsMapping.receiveNewslettersMarketCampaigns]: PropTypes.bool,
  isSmallDevicesVersion: PropTypes.bool,
};

export const newslettersDefaultProps = {
  [settingsMapping.receiveNewslettersNewFeatures]: false,
  [settingsMapping.receiveNewslettersMarketCampaigns]: false,
  isSmallDevicesVersion: false,
};

export const accountPropTypes = {
  isSmallDevicesVersion: PropTypes.bool,
};

export const accountDefaultProps = {
  isSmallDevicesVersion: false,
};

export const settingsPropTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
      icon: PropTypes.node,
    })
  ).isRequired,
};
