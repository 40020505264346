import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import { Text, Button } from "iparque-components";
import Popup from "./Popup";
import FlexBox from "../../../components/FlexBox";
import CheckboxField from "../../../components/formFields/Checkbox";
import { breakpoints } from "../../../utils/breakpoints";

const StyledText = styled(Text)`
  a {
    text-decoration: underline;
    outline: none;
  }
`;

const ButtonsWrapper = styled(FlexBox)`
  margin-left: auto;

  #contract-acceptance-button {
    margin: 0 0 0 10px;
  }

  @media (max-width: ${breakpoints.md}) {
    margin-left: unset;
    flex-direction: column-reverse;
    align-items: center;

    #contract-acceptance-button {
      margin: 0 0 10px;
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    #contract-acceptance-button {
      width: 100%;
    }
  }
`;

const ContractsPopup = ({
  id,
  title,
  description,
  onClose,
  handleAccept,
  acceptButtonText,
  rejectButtonText,
}) => {
  const { t } = useTranslation();

  return (
    <Popup onClose={onClose} title={title} width={800}>
      <Formik
        enableReinitialize
        validateOnBlur={false}
        initialValues={{
          contractAcceptance: false,
        }}
        validationSchema={Yup.object({
          contractAcceptance: Yup.bool().oneOf(
            [true],
            t("8910") /* Os termos apresentados devem ser aceites */
          ),
        })}
        onSubmit={() => handleAccept(id)}
      >
        <Form>
          <FlexBox flexDirection="column">
            <StyledText variant="body8" className="mb-10 inline">
              {ReactHtmlParser(description)}
            </StyledText>
            <CheckboxField
              id="entity-contract-acceptance"
              name="contractAcceptance"
              label={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Text variant="body7">
                  {t("8909") /* Li e concordo com os termos apresentados */}
                </Text>
              }
            />
            <ButtonsWrapper className="mt-40">
              <Button variant="link" color="secondary" onClick={onClose}>
                {rejectButtonText}
              </Button>
              <Button
                size="sm"
                color="secondary"
                id="contract-acceptance-button"
                type="submit"
              >
                {acceptButtonText}
              </Button>
            </ButtonsWrapper>
          </FlexBox>
        </Form>
      </Formik>
    </Popup>
  );
};

ContractsPopup.propTypes = {
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  handleAccept: PropTypes.func.isRequired,
  acceptButtonText: PropTypes.string.isRequired,
  rejectButtonText: PropTypes.string.isRequired,
};

export default ContractsPopup;
