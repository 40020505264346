import React from "react";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { Link, Text } from "iparque-components";
import PropTypes from "prop-types";
import styled from "styled-components";

const ReadOnlyFileInput = ({ designation, value, className }) => {
  return (
    <Container className={className}>
      <FilledInputContainer>
        <div>
          <Text variant="body1" color="quintenary">
            {designation}
          </Text>
          <Text variant="body6" color="quintenary">
            {value ? (
              <Link underline="always" target="_blank" href={URL.createObjectURL(value)}>
                {value.name}
              </Link>
            ) : (
              "-"
            )}
          </Text>
        </div>
        {value && (
          <StyledLink target="_blank" href={URL.createObjectURL(value)}>
            <SearchIcon width={22} cursor="pointer" />
          </StyledLink>
        )}
      </FilledInputContainer>
    </Container>
  );
};

ReadOnlyFileInput.propTypes = {
  designation: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.shape({ name: PropTypes.string }),
};

ReadOnlyFileInput.defaultProps = {
  className: "",
  value: null,
};

export default ReadOnlyFileInput;

const Container = styled.div`
  border: 0px solid ${({ theme }) => theme.card.outlined.primary.borderColor};
  padding: 20px;
  background-color: ${({ theme }) => theme.card.dense.primary.backgroundColor};
`;

const FilledInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
