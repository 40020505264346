import Button from "components/buttons/Button";
import { Heading } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const EmptyList = ({ title, subtitle, button, justify, className }) => {
  return (
    <Container className={className} justify={justify}>
      <div />
      <div>
        <StyledTitle align="center" color="tertiary" variant="h3">
          {title}
        </StyledTitle>
        <StyledText align="center" color="tertiary" variant="h7" className="mt-10">
          {subtitle}
        </StyledText>
      </div>
      <Button size="md" onClick={button.action} className="mt-40">
        {button.label}
      </Button>
    </Container>
  );
};

export default EmptyList;

EmptyList.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.string.isRequired,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }).isRequired,
  className: PropTypes.string,
  justify: PropTypes.oneOf(["space-between", "center"]),
};

EmptyList.defaultProps = {
  className: undefined,
  justify: "space-between",
};

const Container = styled.div`
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: ${({ justify }) => justify};
`;

const StyledTitle = styled(Heading)`
  line-height: 30px;
`;

const StyledText = styled(Heading)`
  font-weight: ${({ theme }) => theme.typography.weight.regular};
`;
