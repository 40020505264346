import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import Button from "components/buttons/Button";
import CancelButton from "components/buttons/CancelButton";
import DraggableFileFormField from "components/formFields/DraggableFile";
import SelectField from "components/formFields/Select";
import FormGridContainer, { FieldContainer } from "components/FormGrid";
import { allowedFileExtensions } from "components/permitHolders/helpers/documents";
import { ValidationSchemaProvider } from "components/ValidationSchemaProvider";
import GenericPopup from "domain/private/components/Popup";
import ButtonsContainer from "domain/private/components/PopupButtonsContainer";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { saveDocument } from "requests/permitHolders";
import { userTypes } from "utils/users";
import Yup from "yup.js";
import useWarnings from "../../hooks/useWarnings";
import { usePermitHoldersDetailsContext } from "../../Provider";
import { actionTypes } from "../../store/actions";

const Popup = ({ onClose, documentTypes }) => {
  const { t } = useTranslation();
  const { defaultEntityId: entityId, userId: driverId } = useAuth();
  const { setIsLoading } = useAppContext();
  const { dispatch, token: permitHolderToken } = usePermitHoldersDetailsContext();
  const { updateWarnings } = useWarnings();
  const {
    documentsTab: {
      list: { update },
    },
  } = usePermitHoldersDetailsContext();

  const onDocumentSubmit = async ({ documentTypeId, file }) => {
    setIsLoading(true);

    const result = await saveDocument(entityId, permitHolderToken, {
      file,
      typeId: documentTypeId,
      userId: driverId,
      userTypeId: userTypes.client,
    });

    if (!result) {
      setIsLoading(false);
      return;
    }

    onClose();

    await Promise.all([update(), updateWarnings()]);
    setIsLoading(false);

    dispatch({ type: actionTypes.SET_IS_EDITABLE, payload: false });
  };

  const validationSchema = useMemo(
    () =>
      Yup.object({
        documentTypeId: Yup.number().required(t("10271") /* O campo é obrigatório */),
        file: Yup.mixed()
          .required(t("10271") /* O campo é obrigatório */)
          .fileExtension(t, allowedFileExtensions),
      }),
    [t]
  );

  return (
    <GenericPopup onClose={onClose} title={t("10589") /* Adicionar documento */}>
      <ValidationSchemaProvider schema={validationSchema}>
        <Formik
          initialValues={{ documentTypeId: undefined, file: undefined }}
          onSubmit={onDocumentSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <FormGridContainer>
              <FieldContainer fullWidthOnSmallDevices>
                <SelectField
                  name="documentTypeId"
                  options={documentTypes}
                  label={t("3659") /* Tipo */}
                />
              </FieldContainer>
              <FieldContainer fullWidth>
                <DraggableFileFormField
                  name="file"
                  label={t("4918") /* Anexo */}
                  allowedExtensions={allowedFileExtensions}
                />
              </FieldContainer>
            </FormGridContainer>
            <ButtonsContainer>
              <CancelButton size="sm" onClick={onClose}>
                {t("48") /* Cancelar */}
              </CancelButton>
              <Button type="submit" size="sm">
                {t("88") /* Guardar */}
              </Button>
            </ButtonsContainer>
          </Form>
        </Formik>
      </ValidationSchemaProvider>
    </GenericPopup>
  );
};

Popup.propTypes = {
  onClose: PropTypes.func.isRequired,
  documentTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired
  ),
};

Popup.defaultProps = {
  documentTypes: [],
};

export default Popup;
