import AnimationPlaceholder from "domain/errors/components/AnimationPlaceholder";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Error from "../GenericError";

const Animation = React.lazy(() => import("./Animation"));

const Error403 = ({ goBack }) => {
  const { t } = useTranslation();

  return (
    <Error
      goBack={goBack}
      title={t("10208") /* Acesso negado. */}
      subtitle={t("10209") /* Lamentamos, mas tem de fazer inversão de marcha. */}
      Animation={
        <React.Suspense fallback={<AnimationPlaceholder />}>
          <Animation />
        </React.Suspense>
      }
    />
  );
};

Error403.propTypes = {
  goBack: PropTypes.func,
};

Error403.defaultProps = {
  goBack: undefined,
};

export default Error403;
