import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import PropTypes from "prop-types";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { breakpointsMapping } from "utils/breakpoints";
import { useAppContext } from "./AppProvider";
import Button from "./buttons/Button";
import FlexBox from "./FlexBox";
import Link from "./Link";
import Text from "./Text";

const DraggableFileInput = React.forwardRef(
  ({ id, name, className, onChange, error, allowedExtensions, ...other }, ref) => {
    const [file, setFile] = useState();
    const theme = useTheme();
    const inputRef = useRef(null);
    const { t } = useTranslation();
    const { breakpoint } = useAppContext();

    const mobileVersion = [
      breakpointsMapping.xs,
      breakpointsMapping.sm,
      breakpointsMapping.md,
    ].includes(breakpoint);

    useImperativeHandle(ref, () => ({ file }), [file]);

    const onButtonClick = () => {
      inputRef.current.click();
    };

    const defaultEventHandler = (event) => {
      event.preventDefault();
    };

    const handleClick = () => {
      inputRef.current.click();
      inputRef.current.value = "";
    };

    const handleFileDrop = (event) => {
      event.preventDefault();

      setFile(event.dataTransfer.files?.[0]);
    };

    const handleOnChange = (event) => {
      const { files } = event.target;

      setFile(files[0]);
    };

    const onRemoveFile = () => setFile("");

    useEffect(() => {
      onChange(file);
    }, [file]);

    const accept = allowedExtensions.map((extension) => `.${extension}`).toString();

    return (
      <div className={className}>
        {file ? (
          <FlexBox>
            <Text numberOfLines={1} className="fill" variant="body6" color="quintenary">
              <Link underline="hover" target="_blank" href={URL.createObjectURL(file)}>
                {file.name}
              </Link>
            </Text>
            <CloseIcon
              width={18}
              cursor="pointer"
              fill={theme.color.danger}
              onClick={onRemoveFile}
              className="mr-10"
            />
          </FlexBox>
        ) : (
          <>
            {mobileVersion ? (
              <Button
                color="primary"
                onClick={onButtonClick}
                className={className}
                type="button"
                size="sm"
              >
                {t("5748") /* Carregar */}
              </Button>
            ) : (
              <DropContainer
                onDrop={handleFileDrop}
                onClick={handleClick}
                onDragOver={defaultEventHandler}
                onDragEnter={defaultEventHandler}
                onDragLeave={defaultEventHandler}
                error={error}
              >
                <Text variant="body4">
                  {t("10240") /* Arraste ou selecione o ficheiro */}
                </Text>
                <StyledButton color="quintenary" variant="link" type="button">
                  {t("5687") /* Selecionar */}
                </StyledButton>
              </DropContainer>
            )}
            <Input
              name={name}
              ref={inputRef}
              type="file"
              multiple={false}
              onChange={handleOnChange}
              accept={accept}
              id={id}
              {...other}
            />
          </>
        )}
      </div>
    );
  }
);

DraggableFileInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  error: PropTypes.bool,
  allowedExtensions: PropTypes.arrayOf(PropTypes.string),
};

DraggableFileInput.defaultProps = {
  id: undefined,
  className: undefined,
  onChange: () => {},
  error: false,
  allowedExtensions: [],
};

export default DraggableFileInput;

const DropContainer = styled.div`
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 15px;
  border: 2px dashed
    ${({ theme, error }) =>
      error ? theme.color.danger : theme.draggableFile.primary.borderColor};
`;

const Input = styled.input`
  display: none;
`;

const StyledButton = styled(Button)`
  text-decoration: underline;
  padding: 0;
  color: ${({ theme }) => theme.typography.text.color.primary};
`;
