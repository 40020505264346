import { matchPath } from "react-router-dom";

export const routesMapping = {
  landing: "/",
  publicEntities: "/entities/:entityToken",
  login: "/login",
  registry: "/registry",
  passwordRecovery: "/password-recovery",
  registryConclusion: "/registry-conclusion",
  registryActivation: "/registry-activation",
  passwordDefinition: "/password-definition",
  backofficeCities: "/backoffice/cities",
  backofficeParking: "/backoffice/parking",
  backofficePermitHolders: "/backoffice/permit-holders",
  backofficePermitHoldersCreation: "/backoffice/permit-holders/creation",
  backofficeParkings: "/backoffice/parkings",
  backofficeAccountMovements: "/backoffice/account-movements",
  backofficeNotices: "/backoffice/infractions",
  backofficeVehicles: "/backoffice/vehicles",
  backofficeDefinitions: "/backoffice/definitions",
  backofficeProfile: "/backoffice/profile",
  backofficeBalanceCharge: "/backoffice/account-movements/charge",
  backofficeNotification: process.env.REACT_APP_NOTIFICATION_ROUTE,
  driversIdentifications: "/drivers-identifications",
};

const publicRoutes = [
  routesMapping.landing,
  routesMapping.login,
  routesMapping.registry,
  routesMapping.passwordRecovery,
  routesMapping.registryConclusion,
  routesMapping.registryActivation,
  routesMapping.passwordDefinition,
  routesMapping.publicEntities,
  `${routesMapping.publicEntities}/*`,
  routesMapping.driversIdentifications,
];

export const isPublicRoute = (targetRoute) => {
  return publicRoutes.some((route) =>
    matchPath(targetRoute, { path: route, exact: true })
  );
};

export const getQueryParam = (name) => {
  return new URLSearchParams(window.location.search).get(name);
};

export const removeQueryParam = (param) => {
  const url = new URL(window.location.href);

  url.searchParams.delete(param);

  window.history.pushState({}, "", url.toString());
};

export const queryKeys = {
  noticeCreditCardPaymentCode: "noticeCreditCardPaymentCode",
  noticePaymentSuccess: "noticePaymentSuccess",
  token: "token",
};
