import { onMessage } from "firebase/messaging";
import { hasNotificationPermission } from "utils/pushNotifications";
import { getMessaging, getMessagingToken } from "./firebaseHelper";

const notify = (message, observers) => {
  observers.forEach((observer) => observer(message));
};

class FirebaseMessageObservable {
  constructor() {
    this.observers = [];
  }

  async updateObservers() {
    if (!hasNotificationPermission()) {
      return false;
    }

    const token = await getMessagingToken();

    if (!token) {
      return false;
    }

    const messaging = await getMessaging();

    if (!messaging) {
      return false;
    }

    onMessage(messaging, (message) => {
      notify(message, this.observers);
    });

    return true;
  }

  async subscribeOnMessage(observer) {
    if (this.observers.includes(observer)) {
      return false;
    }

    this.observers.push(observer);

    return this.updateObservers();
  }

  unsubscribeOnMessage(observer) {
    const observerIndex = this.observers.indexOf(observer);

    if (observerIndex !== -1) {
      this.observers.splice(observerIndex, 1);
    }

    return this.updateObservers();
  }

  clearSubscriptions() {
    this.observers = [];

    this.updateObservers();
  }
}

const firebaseMessageObservable = new FirebaseMessageObservable();

export default firebaseMessageObservable;
