import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { routesMapping } from "../../../../utils/routes";

const useAccountMovements = () => {
  const history = useHistory();

  const charge = useCallback(() => {
    history.push(routesMapping.backofficeBalanceCharge);
  }, []);

  const backToList = useCallback(() => {
    history.push(routesMapping.backofficeAccountMovements);
  }, []);

  return { charge, backToList };
};

export default useAccountMovements;
