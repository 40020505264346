export const actionTypes = {
  SET_SELECTED_CITY: "SET_SELECTED_CITY",
  SET_SELECTED_PERMIT_TYPE: "SET_SELECTED_PERMIT_TYPE",
  SET_CURRENT_STEP: "SET_CURRENT_STEP",
  SET_TERMS_AND_CONDITIONS_ACCEPTED: "SET_TERMS_AND_CONDITIONS_ACCEPTED",
  SET_PERSONAL_DATA: "SET_PERSONAL_DATA",
  ADD_DOCUMENT: "ADD_DOCUMENT",
  REMOVE_DOCUMENT: "REMOVE_DOCUMENT",
  REMOVE_DOCUMENTS: "REMOVE_DOCUMENTS",
  ADD_VEHICLE: "ADD_VEHICLE",
  EDIT_VEHICLE: "EDIT_VEHICLE",
  MAKE_VEHICLE_EDITABLE: "MAKE_VEHICLE_EDITABLE",
  REMOVE_VEHICLE: "REMOVE_VEHICLE",
  FLAG_VEHICLE: "FLAG_VEHICLE",
};

const setSelectedCities = (state, selectedCity) => ({ ...state, selectedCity });

const setSelectedPermitType = (state, selectedPermitType) => ({
  ...state,
  selectedPermitType,
});

const setCurrentStep = (state, currentStep) => ({ ...state, currentStep });

const setTermsAndConditionsAccepted = (state, termsAndConditionAcceptance) => ({
  ...state,
  termsAndConditionAcceptance,
});

const setPersonalData = (state, personalData) => ({
  ...state,
  personalData,
});

const addDocument = (state, { id, designation, file }) => ({
  ...state,
  documents: { ...state.documents, [id]: { id, designation, file } },
});

const removeDocument = (state, id) => {
  const documentsCopy = { ...state.documents };

  delete documentsCopy[id];

  return { ...state, documents: documentsCopy };
};

const removeDocuments = (state) => ({
  ...state,
  documents: {},
});

const addVehicle = (state, vehicle) => ({
  ...state,
  vehicles: [...state.vehicles, vehicle],
});

const editVehicle = (state, { position, vehicle }) => {
  const vehicles = [...state.vehicles];

  vehicles[position] = {
    ...vehicle,
    regime: vehicles[position].regime,
    editable: false,
    flagged: false,
  };

  return {
    ...state,
    vehicles,
  };
};

const makeVehicleEditable = (state, { position }) => {
  const vehicles = [...state.vehicles];

  vehicles[position].editable = true;

  return {
    ...state,
    vehicles,
  };
};

const removeVehicle = (state, { position }) => {
  const vehicles = [...state.vehicles];

  vehicles.splice(position, 1);

  return {
    ...state,
    vehicles,
  };
};

const flagVehicle = (state, { position }) => {
  const vehicles = [...state.vehicles];

  vehicles[position].flagged = true;

  return {
    ...state,
    vehicles,
  };
};

const actions = {
  [actionTypes.SET_SELECTED_CITY]: setSelectedCities,
  [actionTypes.SET_SELECTED_PERMIT_TYPE]: setSelectedPermitType,
  [actionTypes.SET_CURRENT_STEP]: setCurrentStep,
  [actionTypes.SET_TERMS_AND_CONDITIONS_ACCEPTED]: setTermsAndConditionsAccepted,
  [actionTypes.SET_PERSONAL_DATA]: setPersonalData,
  [actionTypes.ADD_DOCUMENT]: addDocument,
  [actionTypes.REMOVE_DOCUMENT]: removeDocument,
  [actionTypes.REMOVE_DOCUMENTS]: removeDocuments,
  [actionTypes.ADD_VEHICLE]: addVehicle,
  [actionTypes.EDIT_VEHICLE]: editVehicle,
  [actionTypes.MAKE_VEHICLE_EDITABLE]: makeVehicleEditable,
  [actionTypes.FLAG_VEHICLE]: flagVehicle,
  [actionTypes.REMOVE_VEHICLE]: removeVehicle,
};

export default actions;
