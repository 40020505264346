import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Heading } from "iparque-components";
import { useBackofficeThemeContext } from "../ThemeProvider";
import { ReactComponent as Back } from "../../../../assets/icons/backLarge.svg";

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;

  #small-devices-page-title-container {
    margin-left: ${({ hasBackCallback }) => (hasBackCallback ? "15px" : "-25px")};
    transition: margin 0.3s ease;
  }

  .city-heading-title {
    line-height: 25px;
  }
`;

const BackWrapper = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};
  display: flex;
  align-items: center;

  ${({ isVisible }) => (isVisible ? "transition: opacity 1s ease;" : "")}
`;

const SmallDevicesContentHeader = ({
  topTitle,
  bottomTitle,
  backCallback,
  className,
  backArrowColor,
}) => {
  const { backofficeTheme } = useBackofficeThemeContext();
  const hasBackCallback = typeof backCallback === "function";

  return (
    <HeaderContainer hasBackCallback={hasBackCallback} className={className}>
      <BackWrapper isVisible={hasBackCallback}>
        <Back
          height="45"
          width="25"
          cursor={hasBackCallback ? "pointer" : "auto"}
          fill={backArrowColor || backofficeTheme.background.color.primary}
          onClick={backCallback}
        />
      </BackWrapper>
      <div id="small-devices-page-title-container">
        <Heading className="city-heading-title" color="secondary" variant="h5">
          {topTitle}
        </Heading>
        <Heading className="city-heading-title" color="primary" variant="h5">
          {bottomTitle}
        </Heading>
      </div>
    </HeaderContainer>
  );
};

SmallDevicesContentHeader.defaultProps = {
  backCallback: null,
  backArrowColor: null,
  className: "",
};

SmallDevicesContentHeader.propTypes = {
  topTitle: PropTypes.string.isRequired,
  bottomTitle: PropTypes.string.isRequired,
  backCallback: PropTypes.func,
  className: PropTypes.string,
  backArrowColor: PropTypes.string,
};

export default SmallDevicesContentHeader;
