import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwitchField from "../../components/SwitchField";
import { useSettingsProvider } from "../../Provider";
import { useAuth } from "../../../../../components/AuthProvider";
import { acceptContract, declineContract } from "../../../../../requests/driver";
import { userContractsMapping } from "../../../../../utils/contracts";

const GeneralDataProtectionRegulationField = ({ title, description, className }) => {
  const { saveSettings } = useSettingsProvider();
  const {
    driverHash,
    isGeneralDataProtectionRegulationAccepted,
    handleAcceptGeneralDataProtectionRegulation,
  } = useAuth();
  const [isRegulationAccepted, setIsRegulationAccepted] = useState(
    isGeneralDataProtectionRegulationAccepted
  );

  const onSwitchChange = useCallback(
    async (event) => {
      const isAccepted = event.target.checked;
      let result;

      setIsRegulationAccepted(isAccepted); // Avoid waiting for request to change input

      if (isAccepted) {
        result = await acceptContract(driverHash, {
          contractId: userContractsMapping.generalDataProtectionRegulation,
        });
      } else {
        result = await declineContract(driverHash, {
          contractId: userContractsMapping.generalDataProtectionRegulation,
        });
      }

      if (!result) {
        setIsRegulationAccepted(isGeneralDataProtectionRegulationAccepted); // Revert to the last saved value
        return;
      }

      handleAcceptGeneralDataProtectionRegulation(isAccepted); // Update app
    },
    [saveSettings, isGeneralDataProtectionRegulationAccepted]
  );

  useEffect(() => {
    setIsRegulationAccepted(isGeneralDataProtectionRegulationAccepted);
  }, [isGeneralDataProtectionRegulationAccepted]);

  return (
    <SwitchField
      title={title}
      description={description}
      definitionKey="generalDataProtectionRegulation"
      checked={isRegulationAccepted}
      onChange={onSwitchChange}
      className={className}
      avoidLoading
    />
  );
};

GeneralDataProtectionRegulationField.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  className: PropTypes.string,
};

GeneralDataProtectionRegulationField.defaultProps = {
  className: null,
};

export default GeneralDataProtectionRegulationField;
