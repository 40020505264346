import axios from "axios";
import { onNetworkError } from "../../providers/NetInfo/helper";

/**
 * Abstract Client
 *
 * @export
 * @class Abstract Client
 */
export default class AbstractClient {
  constructor(baseUrl = process.env.REACT_APP_BASE_API_URL) {
    this.baseUrl = baseUrl;
  }

  request({
    path,
    method = "GET",
    params = {},
    data = {},
    headers = {},
    cancelToken = null,
  }) {
    const url = this.baseUrl + path;

    return axios({
      url,
      baseURL: this.baseUrl,
      method,
      params,
      data,
      headers,
      cancelToken,
    })
      .then((response) => response)
      .catch((error) => {
        if (!error.response) {
          onNetworkError();
        }

        return error;
      });
  }

  async GET(path, params = {}, headers = {}, cancelToken = null) {
    return this.request({
      path,
      params,
      headers,
      method: "GET",
      cancelToken: cancelToken?.token || null,
    });
  }

  async POST(path, body = {}, headers = {}) {
    return this.request({
      path,
      headers,
      data: body,
      method: "POST",
    });
  }

  async PUT(path, body = {}, headers = {}) {
    return this.request({
      path,
      headers,
      data: body,
      method: "PUT",
    });
  }

  async DELETE(path, params = {}, headers = {}) {
    return this.request({
      path,
      params,
      headers,
      method: "DELETE",
    });
  }
}
