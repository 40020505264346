import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { breakpoints } from "utils/breakpoints";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fill-available;
  flex: 1;

  @media (max-width: ${breakpoints.lg}) {
    max-width: unset;
  }
`;

const LeftSideContainer = (props) => {
  const { children, className } = props;

  return <ContentContainer className={className}>{children}</ContentContainer>;
};

LeftSideContainer.defaultProps = {
  className: null,
};

LeftSideContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default LeftSideContainer;
