import { permitHoldersOccupationType } from "utils/permitHolders";
import { usePermitHoldersDetailsContext } from "../Provider";

const useOccupationType = () => {
  const {
    permit: { occupationTypeId },
  } = usePermitHoldersDetailsContext();

  return {
    isVehicleOccupation: occupationTypeId === permitHoldersOccupationType.vehicle,
  };
};

export default useOccupationType;
