import { useMemo } from "react";
import { useAppContext } from "../../../components/AppProvider";
import { smallDevicesBreakpoints } from "../../../utils/breakpoints";

const useAnimationSize = () => {
  const { breakpoint } = useAppContext();

  return useMemo(() => {
    const isSmallDevices = smallDevicesBreakpoints.includes(breakpoint);
    let size;

    if (isSmallDevices) {
      size = 250;
    }

    size = 350;

    return { height: size, width: size };
  }, [breakpoint]);
};

export default useAnimationSize;
