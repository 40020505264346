import Link from "components/Link";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import { IPARQUE_SUPPORT_EMAIL_HREF } from "../controller";

const ValidateVehiclesLink = ({ unvalidatedLicencePlates, hasUnpaidNotices }) => {
  const { t } = useTranslation();

  if (unvalidatedLicencePlates.length === 0 || !hasUnpaidNotices) {
    return null;
  }

  return (
    <StyledText variant="body3" color="danger" className="uppercase">
      <Link href={IPARQUE_SUPPORT_EMAIL_HREF} underline="none">
        {unvalidatedLicencePlates.length}&nbsp;
        {
          unvalidatedLicencePlates.length === 1
            ? t("10642") /* veículo por validar */
            : t("10643") /* veículos por validar */
        }
      </Link>
    </StyledText>
  );
};

export default ValidateVehiclesLink;

ValidateVehiclesLink.propTypes = {
  unvalidatedLicencePlates: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasUnpaidNotices: PropTypes.bool.isRequired,
};

const StyledText = styled(Text)`
  text-decoration: underline;
  line-height: 1;
  align-self: flex-end;

  @media (max-width: ${breakpoints.lg}) {
    font-size: 13px;
  }
`;
