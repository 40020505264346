import Button from "components/buttons/Button";
import { Heading } from "iparque-components";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Skeleton from "../../../components/Skeleton";
import { formatMoney } from "../../../utils/money";
import { parkingTypesMapping } from "../../../utils/parking";
import AmountDetails from "../components/parkings/AmountDetails";
import ParkingEndDate from "../components/parkings/EndDate";
import FeeNameBox from "../components/parkings/FeeNameBox";
import ParkingDetails from "../components/parkings/ParkingDetails";
import SelectedBenefit from "../components/parkings/SelectedBenefit";
import { longFeeDurationDefaultProps, longFeeDurationPropTypes } from "./propTypes";

const ParkingInfoContainer = styled.div`
  display: flex;
  flex: 3;
`;

const ParkingDetailsWrapper = styled.div`
  flex: 3;
`;

const SelectedBenefitWrapper = styled.div`
  flex: 2;
  margin: 0 20px;
`;

const ParkingRightInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.color.secondary};
`;

const LongFeeDuration = ({
  parking,
  calculateParkingCallback,
  onConfirmRenewalCallback,
  calculatedParking,
  fee,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (parking?.id) {
      calculateParkingCallback();
    }
  }, [parking.id]);

  return (
    <>
      <ParkingInfoContainer>
        <ParkingDetailsWrapper>
          <ParkingDetails
            vehicleColor={parking.vehicleColor}
            vehicleBrand={parking.vehicleBrand}
            vehicleLicensePlate={parking.vehicleLicensePlate}
            streetName={parking.streetName}
            explorationName={parking.explorationName}
          />
          <ParkingEndDate
            className="mt-20"
            isLoading={calculatedParking?.isLoading}
            parkingTypeId={parkingTypesMapping.duration}
            endingDate={calculatedParking.endingDate}
          />
        </ParkingDetailsWrapper>
        {parking.benefitName && (
          <SelectedBenefitWrapper>
            <SelectedBenefit
              description={parking.benefitName}
              color={parking.benefitColor}
            />
          </SelectedBenefitWrapper>
        )}
        <ParkingRightInfo>
          <div>
            <AmountDetails amount={parking.currentAmount || 0} />
            {calculatedParking.isLoading ? (
              <Skeleton height={25} width={124} />
            ) : (
              <StyledHeading variant="h2">
                {`+${formatMoney(calculatedParking?.amount, { centsFormat: false })}`}
              </StyledHeading>
            )}
          </div>
          {fee.name && <FeeNameBox className="mt-15" name={fee.name} />}
        </ParkingRightInfo>
      </ParkingInfoContainer>
      <ButtonContainer>
        <Button
          disabled={calculatedParking.duration === null}
          size="md"
          color="primary"
          onClick={onConfirmRenewalCallback}
        >
          {t("35") /* Confirmar */}
        </Button>
      </ButtonContainer>
    </>
  );
};

LongFeeDuration.propTypes = longFeeDurationPropTypes;

LongFeeDuration.defaultProps = longFeeDurationDefaultProps;

export default LongFeeDuration;
