import Button from "components/buttons/Button";
import CancelButton from "components/buttons/CancelButton";
import { Form as FormikForm, Formik } from "formik";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as Yup from "yup";
import TextField from "../../../components/formFields/Text";
import PasswordRequirements from "../../../components/PasswordRequirements";
import { breakpoints } from "../../../utils/breakpoints";
import { passwordFormDefaultProps, passwordFormProps } from "./propTypes";
import { FORM_BUTTON_WIDTH } from "./utils";

const Container = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  text-align: right;
  margin-top: 20px;

  > button {
    width: ${FORM_BUTTON_WIDTH};
  }

  @media (max-width: ${breakpoints.lg}) {
    text-align: center;
    margin-top: 40px;
  }
`;

const StyledCancelButton = styled(CancelButton)`
  @media (max-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const GridContainer = styled.div`
  width: 70%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 20px;

  @media (max-width: ${breakpoints.lg}) {
    width: 100%;
  }
`;

const FullWidthContainer = styled.div`
  width: fill-available;
  grid-column-end: span 12;
`;

const HalfWidthContainer = styled.div`
  width: fill-available;
  grid-column-end: span 6;
`;

const StyledPasswordRequirements = styled(PasswordRequirements)`
  .password-requirements-text {
    font-weight: ${({ theme }) => theme.typography.weight.light};
  }
`;

const PasswordForm = ({ saveUserPasswordCallback, onCancel }) => {
  const { t } = useTranslation();
  const passwordRef = useRef();

  return (
    <>
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          passwordConfirmation: "",
        }}
        validationSchema={Yup.object({
          oldPassword: Yup.string().required(t("10271") /* O campo é obrigatório */),
          newPassword: Yup.string()
            .required(t("10271") /* O campo é obrigatório */)
            .password(
              t("8154") /* O campo deverá preencher os requisitos apresentados abaixo */,
              passwordRef
            ),
          passwordConfirmation: Yup.string()
            .required(t("10271") /* O campo é obrigatório */)
            .oneOf(
              [Yup.ref("newPassword"), null],
              t(
                "7883"
              ) /* A confirmação da senha não corresponde com a senha introduzida */
            ),
        })}
        onSubmit={saveUserPasswordCallback}
      >
        {({ handleChange, isValid, dirty, values }) => (
          <FormikForm className="mt-20">
            <Container>
              <GridContainer>
                <HalfWidthContainer>
                  <TextField
                    type="password"
                    id="old-password-field"
                    name="oldPassword"
                    label={t("8193") /* Antiga Senha */}
                  />
                </HalfWidthContainer>
                <HalfWidthContainer />
                <HalfWidthContainer>
                  <TextField
                    type="password"
                    id="password-field"
                    name="newPassword"
                    label={t("7909") /* Nova Senha */}
                    onChange={handleChange}
                  />
                </HalfWidthContainer>
                <HalfWidthContainer>
                  <TextField
                    type="password"
                    id="password-confirmation-field"
                    name="passwordConfirmation"
                    label={t("7884") /* Confirmar Senha */}
                  />
                </HalfWidthContainer>
                <FullWidthContainer className="mt-20">
                  <StyledPasswordRequirements
                    ref={passwordRef}
                    password={values.newPassword}
                    textColor="primary"
                    textVariant="body10"
                  />
                </FullWidthContainer>
              </GridContainer>
            </Container>
            <ButtonContainer>
              <StyledCancelButton
                onClick={onCancel}
                size="sm"
                type="button"
                className="mr-10"
              >
                {t("48") /* Cancelar */}
              </StyledCancelButton>
              <Button disabled={!(isValid && dirty)} type="submit" size="sm">
                {t("88") /* Guardar */}
              </Button>
            </ButtonContainer>
          </FormikForm>
        )}
      </Formik>
    </>
  );
};

PasswordForm.propTypes = passwordFormProps;

PasswordForm.defaultProps = passwordFormDefaultProps;

export default PasswordForm;
