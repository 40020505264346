import {
  GlobalStyles as IparqueGlobalStyles,
  MaterialProvider,
} from "iparque-components";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SkeletonTheme } from "react-loading-skeleton";
import { ThemeProvider } from "styled-components";
import "../fonts.css";
import GlobalStyles from "../globalStyles";
import { themes, themesMapping } from "../themes/theme";
import { languageWithoutRegion } from "../utils/general";
import { languagesMapping } from "../utils/languages";

const theme = themes[themesMapping.public];

const AppThemeProvider = (props) => {
  const { children } = props;
  const [locale, setLocale] = useState("");
  const { i18n } = useTranslation();

  useEffect(() => {
    const language = languageWithoutRegion(i18n.language);
    const importMomentLocation = async () => {
      if (language !== languagesMapping.en) {
        // English is always loaded
        await import(`moment/locale/${language}`);
      }

      setLocale(language);
      moment.locale(language);
    };

    importMomentLocation();
  }, [i18n.language]);

  return (
    <ThemeProvider theme={theme}>
      <MaterialProvider locale={locale}>
        <IparqueGlobalStyles />
        <GlobalStyles />
        <SkeletonTheme
          color={theme.loadingSkeleton.color}
          highlightColor={theme.loadingSkeleton.highlightColor}
        >
          {children}
        </SkeletonTheme>
      </MaterialProvider>
    </ThemeProvider>
  );
};

AppThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppThemeProvider;
