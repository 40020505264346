import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import { convertDateTimeToReadableDate } from "../../../../utils/dateTime";
import { parkingTimerStates } from "./Timer";

const TimerAdditionalInfo = ({ state, nextStart, variant, className }) => {
  const { t } = useTranslation();

  const render = useMemo(() => {
    if (!state || !nextStart) {
      return null;
    }

    if (state === parkingTimerStates.scheduled) {
      return (
        <Text variant={variant} className={className}>
          {`${t("8381") /* Agendado para */} ${convertDateTimeToReadableDate(nextStart)}`}
        </Text>
      );
    }

    if (state === parkingTimerStates.paused) {
      return (
        <Text variant={variant} className={className}>
          {`${t("8382") /* A contagem recomeça */} ${convertDateTimeToReadableDate(
            nextStart
          )}`}
        </Text>
      );
    }

    return null;
  }, [state, nextStart]);

  return render;
};

TimerAdditionalInfo.propTypes = {
  state: PropTypes.oneOf(Object.values(parkingTimerStates)),
  nextStart: PropTypes.string,
  variant: PropTypes.oneOf(["body4", "body10", "body11"]),
  className: PropTypes.string,
};

TimerAdditionalInfo.defaultProps = {
  state: null,
  nextStart: null,
  variant: "body4",
  className: null,
};

export default TimerAdditionalInfo;
