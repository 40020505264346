import { Form as FormikForm, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import Yup from "yup.js";
import styled from "styled-components";
import { Button } from "iparque-components";
import TextField from "components/formFields/Text";
import { breakpoints } from "utils/breakpoints";
import { useHistory } from "react-router-dom";
import { getIdentificationToken } from "requests/driversIdentifications";
import { customerSupportMessage } from "utils/userMessages";
import { ValidationSchemaProvider } from "components/ValidationSchemaProvider";
import useCities from "hooks/useCities";
import { ENTITY_TOKEN_DOES_NOT_EXIST } from "utils/error";
import { useAppContext } from "components/AppProvider";
import CityPicker from "../../../../../components/formFields/Cities";

const NoticeData = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { cities, isLoading } = useCities();
  const { setIsLoading } = useAppContext();

  async function fetchIdentificationTokenData(entityId, noticeId) {
    const identificationTokenData = await getIdentificationToken(
      entityId,
      {
        noticeId,
      },
      {
        autoCloseError: true,
        t,
      }
    );

    return identificationTokenData?.token;
  }

  const onAdvance = async (values) => {
    if (values) {
      const entityToken = values.city.entity.token;

      if (entityToken === null) {
        customerSupportMessage(ENTITY_TOKEN_DOES_NOT_EXIST);
        return;
      }

      setIsLoading(true);

      const identificationToken = await fetchIdentificationTokenData(
        values.city.entity.id,
        values.noticeId
      );

      if (identificationToken) {
        history.push(
          `/entities/${entityToken}/drivers-identifications/${identificationToken}`,
          {
            areTheTermsAndConditionsAccepted: true,
          }
        );
      }

      setIsLoading(false);
    }
  };

  const formValidationSchema = Yup.object({
    noticeId: Yup.string().required(t("10271") /* O campo é obrigatório */),
    city: Yup.string().required(t("10271") /* O campo é obrigatório */),
  });

  return (
    <ValidationSchemaProvider schema={formValidationSchema}>
      <Formik
        initialValues={{
          noticeId: undefined,
          city: undefined,
        }}
        validationSchema={formValidationSchema}
        onSubmit={onAdvance}
      >
        <StyledForm className="mt-20">
          <div>
            <TextField
              className="form-field"
              name="noticeId"
              label={t("7380") /* Número do aviso */}
              readOnly={false}
            />
            <CityPicker
              className="mt-30"
              label={t("10645") /* Seleciona a cidade */}
              name="city"
              items={cities}
              isLoading={isLoading}
            />
          </div>
          <ButtonContainer>
            <Button type="submit" color="quintenary" className="uppercase" fullWidth>
              {t("10223") /* Avançar */}
            </Button>
          </ButtonContainer>
        </StyledForm>
      </Formik>
    </ValidationSchemaProvider>
  );
};

export default NoticeData;

const StyledForm = styled(FormikForm)`
  display: flex;
  flex-flow: column wrap;
  flex: 1;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 2em 0 0 auto;
  width: 150px;

  @media (max-width: ${breakpoints.sm}) {
    justify-content: center;
    width: 100%;
  }
`;
