import React from "react";
import styled from "styled-components";
import { ReactComponent as NotificationsBellIcon } from "assets/icons/notifications-bell.svg";
import { breakpoints } from "utils/breakpoints";
import { useNotificationsContext } from "../Provider";

const ICON_SIZE = 28;

const NotificationsBell = () => {
  const { unreadNumber, toggleOpen } = useNotificationsContext();

  return (
    <Container onClick={toggleOpen}>
      <Bell width={ICON_SIZE} height={ICON_SIZE} />
      {unreadNumber > 0 && <Badge>{unreadNumber > 99 ? "99+" : unreadNumber}</Badge>}
    </Container>
  );
};

export default NotificationsBell;

NotificationsBell.defaultProps = {
  className: undefined,
};

const Container = styled.div`
  position: relative;
  height: ${ICON_SIZE}px;
`;

const Bell = styled(NotificationsBellIcon)`
  fill: ${({ theme }) => theme.typography.text.color.primary};
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }

  @media (max-width: ${breakpoints.lg}) {
    fill: ${({ theme }) => theme.typography.text.color.secondary};
  }
`;

const Badge = styled.div`
  position: absolute;
  top: -5px;
  left: 15px;
  min-width: 1em;
  background-color: ${({ theme }) => theme.color.danger};
  border-radius: 20px;
  padding: 2.5px;
  font-size: 9px;
  pointer-events: none;
  text-align: center;
  color: ${({ theme }) => theme.color.lightest};
  line-height: 1;
`;
