/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
import { useEffect } from "react";

window.piAId = "970993";
window.piCId = "1690";
window.piHostname = "pi.pardot.com";

const usePardot = () => {
  useEffect(() => {
    (() => {
      const asyncLoad = () => {
        const s = document.createElement("script");

        s.type = "text/javascript";
        s.src = `${
          document.location.protocol == "https:" ? "https://pi" : "http://cdn"
        }.pardot.com/pd.js`;

        const c = document.getElementsByTagName("script")[0];
        c.parentNode.insertBefore(s, c);
      };

      if (window.attachEvent) {
        window.attachEvent("onload", asyncLoad);
      } else {
        window.addEventListener("load", asyncLoad, false);
      }
    })();
  }, []);
};

export default usePardot;
