import React from "react";
import SubscriptionsList from "../../../components/subscriptions/smallDevices/List";
import TabTitle from "../../../components/Title";
import { tabsIdsMap } from "../../../helper";

const SubscriptionTab = () => {
  return (
    <>
      <TabTitle tabId={tabsIdsMap.subscriptions} />
      <SubscriptionsList />
    </>
  );
};

export default SubscriptionTab;
