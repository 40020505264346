import useLanguages from "hooks/useLanguages";
import PropTypes from "prop-types";
import React, { createContext, useContext, useMemo } from "react";

const LanguageContext = createContext({
  languages: [],
  onChangeLanguage: () => {},
  language: "",
});

export const useLanguageContext = () => {
  return useContext(LanguageContext);
};

const LanguageProvider = ({ children }) => {
  const { languages, onChangeLanguage, language } = useLanguages({ useLoading: false });

  const values = useMemo(
    () => ({
      languages,
      onChangeLanguage,
      language,
    }),
    [languages, onChangeLanguage, language]
  );

  return <LanguageContext.Provider value={values}>{children}</LanguageContext.Provider>;
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguageProvider;
