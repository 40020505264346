import { useCallback, useState } from "react";
import { useAuth } from "components/AuthProvider";
import { isScrollInBottom } from "utils/dom";
import { getAll } from "../controller";
import { useNotificationsContext } from "../Provider";
import { actionTypes } from "../store/actions";
import { NOTIFICATIONS_REQUEST_LIMIT } from "../configs";

const useInfiniteList = () => {
  const { defaultEntityId, driverHash } = useAuth();
  const { notifications, totalNotifications, dispatch } = useNotificationsContext();
  const [isLoading, setIsLoading] = useState(false);

  const getNotifications = useCallback(async () => {
    setIsLoading(true);

    const response = await getAll(defaultEntityId, driverHash, {
      skip: notifications.length,
      limit: NOTIFICATIONS_REQUEST_LIMIT,
    });

    setIsLoading(false);

    if (response.hasError) {
      return;
    }

    dispatch({
      type: actionTypes.SET_NOTIFICATIONS,
      payload: {
        notifications: [...notifications, ...response.items],
        totalNotifications: response.totalItems || 0,
      },
    });
  }, [defaultEntityId, driverHash, notifications]);

  const onScroll = useCallback(
    (event) => {
      if (
        isScrollInBottom(event.target) &&
        !isLoading &&
        notifications?.length < totalNotifications
      ) {
        getNotifications();
      }
    },
    [isLoading, notifications?.length, totalNotifications, getNotifications]
  );

  return { onScroll };
};

export default useInfiniteList;
