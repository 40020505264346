import Text from "components/Text";
import { Search } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAuth } from "../../../components/AuthProvider";
import { checkDefaultEntity, getDefaultEntity } from "../../../utils/auth";
import Logout from "../components/Logout";
import ContentHeader from "../components/smallDevices/ContentHeader";
import CityItem from "./Item";
import CitiesList from "./List";
import { citiesPropTypes } from "./propTypes";

const Container = styled.div`
  ${({ hasDefaultEntityDefined, theme }) =>
    !hasDefaultEntityDefined &&
    ` background-color: ${theme.background.color.background};
      width: fill-available;
      padding: 15px;
    `}
`;

const LogoutWrapper = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: inherit;
  height: 50px;
  justify-content: center;
  align-items: center;
`;

const SmallDevicesCities = ({ cities, search, setSearch }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const hasDefaultEntityDefined = checkDefaultEntity(user);
  const defaultEntity = getDefaultEntity(user);

  return (
    <Container hasDefaultEntityDefined={hasDefaultEntityDefined}>
      <ContentHeader
        topTitle={
          hasDefaultEntityDefined
            ? t("7948") /* Estás noutra cidade? */
            : t("7946") /* Queres estacionar? */
        }
        bottomTitle={
          hasDefaultEntityDefined
            ? t("7949") /* Escolhe onde queres estacionar! */
            : t("7947") /* Seleciona a concessionária */
        }
      />
      {defaultEntity && (
        <>
          <Text className="mt-30 mb-10" variant="body4">
            {`${t("10899") /* Entidade selecionada */}:`}
          </Text>
          <CityItem
            city={{
              entityId: defaultEntity.id,
              entityName: defaultEntity.name,
              entityLogo: defaultEntity.logo,
              balance: defaultEntity.balance.amount,
            }}
            selected
          />
        </>
      )}
      <div className="mt-30">
        {defaultEntity && (
          <Text className=" mb-10" variant="body4">
            {`${t("10901") /* Cidades mais próximas */}:`}
          </Text>
        )}
        <Search
          value={search}
          name="search-city"
          placeholder={t("10842") /* Procura onde queres estacionar */}
          onChange={(event) => setSearch(event.target.value)}
        />
        <CitiesList className="mt-20 mb-20" cities={cities} />
      </div>
      {!hasDefaultEntityDefined && (
        <LogoutWrapper>
          <Logout lightBackground />
        </LogoutWrapper>
      )}
    </Container>
  );
};

SmallDevicesCities.propTypes = citiesPropTypes;

export default SmallDevicesCities;
