import PropTypes from "prop-types";

export const itemProps = {
  id: PropTypes.shape({
    entityId: PropTypes.number,
    cityId: PropTypes.string,
  }),
  imageUrl: PropTypes.string,
  cityName: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  selected: PropTypes.shape({
    entityId: PropTypes.number,
    cityId: PropTypes.string,
  }),
};
