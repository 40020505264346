import React from "react";
import { PORTUGAL } from "utils/countries";
import PropTypes from "prop-types";
import { identificationTypes } from "utils/driversIdentifications";
import { usePublicEntityContext } from "../../../Provider";
import IdentificationSkeleton from "./Skeleton";
import PersonalDataDefaultForm from "./components/DefaultForm";
import PersonalDataForeignForm from "./components/ForeignForm";
import { useDriverIdentificationContext } from "../../Provider";

const PersonalData = ({ readOnly }) => {
  const { isEntityDataLoading, entity } = usePublicEntityContext();
  const { identificationData } = useDriverIdentificationContext();

  if (isEntityDataLoading) {
    return <IdentificationSkeleton />;
  }

  if (
    entity.countryId !== PORTUGAL &&
    identificationData.typeId === identificationTypes.rentalVehicleDriver
  ) {
    return <PersonalDataForeignForm readOnly={readOnly} />;
  }

  return <PersonalDataDefaultForm readOnly={readOnly} />;
};

PersonalData.propTypes = {
  readOnly: PropTypes.bool,
};

PersonalData.defaultProps = {
  readOnly: false,
};

export default PersonalData;
