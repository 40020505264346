import { ReactComponent as GeneralTabIcon } from "assets/icons/outlined-person.svg";
import { ReactComponent as DocumentsTabIcon } from "assets/icons/document.svg";
import { ReactComponent as VehiclesTabIcon } from "assets/icons/outlined-vehicle.svg";
import { ReactComponent as LocationsTabIcon } from "assets/icons/map-pin.svg";
import { ReactComponent as SubscriptionsTabIcon } from "assets/icons/outlined-euro.svg";
import { ReactComponent as HistoryTabIcon } from "assets/icons/folder.svg";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { tabsIdsMap } from "../helper";

const useTabsUtils = () => {
  const { t } = useTranslation();

  const getTabIcon = useCallback(
    (tabId) =>
      ({
        [tabsIdsMap.general]: <GeneralTabIcon />,
        [tabsIdsMap.documents]: <DocumentsTabIcon />,
        [tabsIdsMap.vehicles]: <VehiclesTabIcon />,
        [tabsIdsMap.locations]: <LocationsTabIcon />,
        [tabsIdsMap.subscriptions]: <SubscriptionsTabIcon />,
        [tabsIdsMap.history]: <HistoryTabIcon />,
      }[tabId]),
    []
  );

  const getTabLabel = useCallback(
    (tabId) =>
      ({
        [tabsIdsMap.general]: t("53") /* Geral */,
        [tabsIdsMap.documents]: t("4297") /* Documentos */,
        [tabsIdsMap.vehicles]: t("4424") /* Veículos */,
        [tabsIdsMap.locations]: t("10460") /* Locais */,
        [tabsIdsMap.subscriptions]: t("261") /* Subscrições */,
        [tabsIdsMap.history]: t("2789") /* Histórico */,
      }[tabId]),
    [t]
  );

  return { getTabIcon, getTabLabel };
};

export default useTabsUtils;
