import PropTypes from "prop-types";

const benefitProps = {
  name: PropTypes.string,
  typeId: PropTypes.number,
};

const locationProps = {
  lat: PropTypes.number,
  lng: PropTypes.number,
};

export const activeParkingProps = {
  id: PropTypes.number.isRequired,
  licensePlate: PropTypes.string.isRequired,
  vehicleColorId: PropTypes.number.isRequired,
  stateId: PropTypes.number.isRequired,
  streetName: PropTypes.string.isRequired,
  streetId: PropTypes.number.isRequired,
  explorationName: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  paymentMethodId: PropTypes.number.isRequired,
  parkingTypeName: PropTypes.string.isRequired,
  parkingTypeId: PropTypes.number.isRequired,
  startingDate: PropTypes.string.isRequired,
  endingDate: PropTypes.string,
  feeTypeId: PropTypes.number.isRequired,
  scheduleExtraInfo: PropTypes.shape({
    isRunning: PropTypes.bool,
    remainingDuration: PropTypes.number,
    currentDuration: PropTypes.number,
    nextPause: PropTypes.string,
    nextStart: PropTypes.string,
  }),
  benefit: PropTypes.shape(benefitProps),
  location: PropTypes.shape(locationProps).isRequired,
};

export const activeParkingDefaultProps = {
  scheduleExtraInfo: {},
  endingDate: null,
  benefit: {},
};

export const finishedParkingProps = {
  licensePlate: PropTypes.string.isRequired,
  vehicleColorId: PropTypes.number.isRequired,
  streetName: PropTypes.string.isRequired,
  explorationName: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  endingDate: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  stateId: PropTypes.number.isRequired,
  stateName: PropTypes.string.isRequired,
  benefit: PropTypes.shape(benefitProps),
  location: PropTypes.shape(locationProps).isRequired,
};

export const finishedParkingDefaultProps = {
  benefit: {},
};

const parkingsProps = {
  activesParkings: PropTypes.arrayOf(
    PropTypes.shape({ ...activeParkingProps, id: PropTypes.number.isRequired })
  ).isRequired,
  finishedParkings: PropTypes.arrayOf(
    PropTypes.shape({
      ...finishedParkingProps,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  updateCallback: PropTypes.func.isRequired,
};

export default parkingsProps;
