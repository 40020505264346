import ScrollBarStyles from "components/ScrollBarStyles";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { ThemeProvider, useTheme } from "styled-components";
import { entityThemeFields } from "utils/entities";
import { usePublicEntityContext } from "./Provider";

const PublicEntityThemeProvider = ({ children }) => {
  const theme = useTheme();
  const { entityTheme } = usePublicEntityContext();

  const processedTheme = useMemo(() => {
    if (!entityTheme) {
      return undefined;
    }

    theme.color.tertiary = entityTheme[entityThemeFields.primaryColor];
    theme.scroll.thumbColor = theme.color.darkest;
    theme.card.outlined.primary.hoverBorderColor =
      entityTheme[entityThemeFields.primaryColor];
    theme.card.outlined.primary.selectedBorderColor =
      entityTheme[entityThemeFields.primaryColor];

    theme.formField.color.primary.label = theme.color.darkest;
    theme.formField.color.primary.input = theme.color.darkest;
    theme.formField.color.primary.focus = theme.color.darkest;
    theme.formField.color.primary.border = theme.color.darkest;

    return theme;
  }, [theme, entityTheme]);

  return (
    <ThemeProvider theme={processedTheme || theme}>
      {processedTheme && <ScrollBarStyles />}
      {children}
    </ThemeProvider>
  );
};

export default PublicEntityThemeProvider;

PublicEntityThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
