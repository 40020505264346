import { Heading, Button } from "iparque-components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { routesMapping } from "../../../../utils/routes";
import { useBackofficeThemeContext } from "../../components/ThemeProvider";
import { requirementsDefaultProps, requirementsPropsTypes } from "./propTypes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

const ParkingRequirements = ({ requirementsMet, className }) => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();
  const history = useHistory();

  const getAnimation = ({ hideVehicle = false, hideBalance = false } = {}) => {
    const animation = backofficeTheme.animation.stopParking;

    if (hideVehicle) {
      animation.layers[1] = {};
    }

    if (hideBalance) {
      animation.layers[0] = {};
    }

    return animation;
  };

  const dynamicInfo = useMemo(() => {
    let message = null;
    let buttons = [];
    let animationOptions = {};

    const chargeBalance = () =>
      history.push(`${routesMapping.backofficeAccountMovements}/charge`);

    if (!requirementsMet.balance) {
      message = (
        <Heading align="center" color="tertiary" variant="h7">
          {t("8212") /* Antes de avançares carrega o saldo da tua conta. */}
        </Heading>
      );

      buttons = [
        <Button
          key="charge-balance"
          size="xs"
          className="uppercase"
          onClick={chargeBalance}
        >
          {t("3991") /* Carregar Saldo */}
        </Button>,
      ];

      animationOptions = { hideVehicle: true, hideBalance: false };
    }

    return { message, buttons, animation: getAnimation(animationOptions) };
  }, [requirementsMet]);

  return (
    <Container className={className}>
      <div>
        <Lottie
          options={{ animationData: dynamicInfo.animation }}
          height={250}
          width={250}
        />
      </div>
      <div className="mt-30">
        {dynamicInfo.message}
        <Heading align="center" color="tertiary" variant="h3" className="mt-20">
          {t("10845") /* Estaciona em segurança! */}
        </Heading>
      </div>
      <div className="mt-40">{dynamicInfo.buttons}</div>
    </Container>
  );
};

ParkingRequirements.propTypes = requirementsPropsTypes;

ParkingRequirements.defaultProps = requirementsDefaultProps;

export default ParkingRequirements;
