import React, { useCallback } from "react";
import { Heading, Text } from "iparque-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { paymentMethodsMapping } from "utils/payment";
import { formatMoney } from "utils/money";
import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";
import { themesMapping } from "themes/theme";
import { useAuth } from "components/AuthProvider";
import { useBackofficeThemeContext } from "../../components/ThemeProvider";

const ICON_SIZE = 50;

export const paymentBoxGeneralStyle = css`
  min-width: 290px;
  width: 290px;
  min-height: 165px;
  height: fit-content;
  padding: 15px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 15px;
    margin-right: 0;
  }

  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
`;

const Container = styled.div`
  ${paymentBoxGeneralStyle}

  display: flex;
  border: 2px solid ${({ theme }) => theme.parkingPaymentBox.borderColor};
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;

  :hover {
    background: ${({ theme }) => theme.parkingPaymentBox.borderColor};

    .payment-box-icon {
      fill: ${({ theme }) => theme.box.color};
    }

    .payment-box-text {
      color: ${({ theme }) => theme.box.color};
    }
  }
`;

const InnerContainer = styled.div`
  display: flex;
  height: ${ICON_SIZE}px;
`;

const StyledHeading = styled(Heading)`
  line-height: 25px;
  width: 1px; /* Workaround to break line with only one word */
`;

const PaymentMethodBox = ({ code, description, className, onClick, disabled }) => {
  const { backofficeTheme, backofficeThemeName } = useBackofficeThemeContext();
  const { user } = useAuth();
  const { t } = useTranslation();

  const userAmount = user.driver?.defaultEntity?.balance?.amount;
  const isNormalTheme = backofficeThemeName === themesMapping.normal;
  const isBalance = code === paymentMethodsMapping.balance.code;

  const getIconByCode = useCallback(() => {
    if (isBalance) {
      return (
        <WalletIcon
          height={ICON_SIZE}
          width={ICON_SIZE}
          className="payment-box-icon"
          fill={backofficeTheme.parkingPaymentBox.iconColor}
        />
      );
    }

    return null;
  }, [backofficeTheme]);

  return (
    <Container
      onClick={onClick}
      $isNormalTheme={isNormalTheme}
      className={className}
      disabled={disabled}
    >
      <InnerContainer>
        {getIconByCode()}
        <div className="ml-15">
          <StyledHeading
            $isNormalTheme={isNormalTheme}
            variant="h4"
            className="payment-box-text"
            color="secondary"
          >
            {description}
          </StyledHeading>
          {isBalance && (
            <Text className="mt-10 payment-box-text" variant="body11">
              {`${t("5680") /* Disponível */}: ${formatMoney(userAmount, {
                centsFormat: false,
              })}`}
            </Text>
          )}
        </div>
      </InnerContainer>
    </Container>
  );
};

PaymentMethodBox.propTypes = {
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

PaymentMethodBox.defaultProps = {
  className: "",
};

export default PaymentMethodBox;
