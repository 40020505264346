import React, { useState, useRef, useCallback, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useDebounce from "../../../../hooks/useDebounce";
import {
  convertMinutesToSeconds,
  convertSecondsToMinutes,
  convertSecondsToReadableTime,
  convertTimeToSeconds,
  minuteInSeconds,
} from "../../../../utils/dateTime";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow.svg";
import useBrowser, { browserMapping } from "../../../../hooks/useBrowser";
import { infoMessage } from "../../../../utils/userMessages";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ClockContainer = styled.div`
  border: ${({ theme, isFocused }) =>
    `${
      isFocused
        ? `4px solid ${theme.input.focusedBorderColor}`
        : `1px solid ${theme.input.borderColor}`
    }`}; /* Same of clock heading */
  border-radius: 15px;
  box-sizing: border-box;
`;

const StyledInput = styled.input`
  font-size: 80px;
  font-family: ${({ theme }) => theme.typography.font.tertiary};
  font-weight: ${({ theme }) => theme.typography.weight.light};
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;
  width: fit-content;
  color: ${({ theme }) => theme.input.textColor};
  clip-path: inset(0);
  padding: ${({ browser }) =>
    browser === browserMapping.firefox ? "5px 16px 5px 30px" : "5px 30px"};

  ::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }

  ::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  ::-webkit-datetime-edit-hour-field:focus {
    background-color: transparent;
    color: ${({ theme }) => theme.input.textColor}88;
  }

  ::-webkit-datetime-edit:focus {
    background-color: transparent;
  }

  ::-webkit-datetime-edit-minute-field:focus {
    background-color: transparent;
    color: ${({ theme }) => theme.input.textColor}88;
  }
`;

const StyledArrow = styled(Arrow)`
  cursor: pointer;
  height: 40px;
  width: 46px;
  fill: ${({ theme }) => theme.color.primary};
  ${({ $top }) =>
    $top ? "transform: rotate(180deg); margin-bottom: 10px;" : "margin-top: 10px;"}
`;

const DURATION_ARROWS_PRESS_INTERVAL = 200;
const DURATION_CHANGE_DEBOUNCE_INTERVAL = 700;

const ConfigurableClock = ({ duration, onDurationChange, max, min }) => {
  const [localDuration, setLocalDuration] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const clickInterval = useRef(null);
  const shouldDisplayMaxDurationInfo = useRef(true);
  const shouldDisplayMinDurationInfo = useRef(true);

  const maxInSeconds = convertMinutesToSeconds(max);
  const minInSeconds = convertMinutesToSeconds(min);

  const debounceDuration = useDebounce(localDuration, DURATION_CHANGE_DEBOUNCE_INTERVAL);

  const { t } = useTranslation();

  const displayMaxDurationInfo = useCallback(() => {
    if (!shouldDisplayMaxDurationInfo.current) {
      return;
    }

    shouldDisplayMaxDurationInfo.current = false;
    infoMessage(
      t("8268", {
        max: convertSecondsToReadableTime(maxInSeconds, {
          avoidSeconds: true,
        }),
      }) /* A duração máxima do estacionamento é de {{max}} minutos. */
    );
  }, [maxInSeconds, shouldDisplayMaxDurationInfo.current]);

  const displayMinDurationInfo = useCallback(() => {
    if (!shouldDisplayMinDurationInfo.current) {
      return;
    }

    shouldDisplayMinDurationInfo.current = false;
    infoMessage(
      t("8267", {
        min: convertSecondsToReadableTime(minInSeconds, {
          avoidSeconds: true,
        }),
      }) /* A duração mínima do estacionamento é de {{min}} minutos. */
    );
  }, [minInSeconds, shouldDisplayMinDurationInfo.current]);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    if (debounceDuration === null || debounceDuration === undefined) {
      return;
    }

    const isDurationHigherThanMax = maxInSeconds < debounceDuration;
    const isDurationLessThanMin = minInSeconds > debounceDuration;

    if (isDurationHigherThanMax) {
      displayMaxDurationInfo();
      setLocalDuration(maxInSeconds);
      return;
    }

    if (isDurationLessThanMin) {
      displayMinDurationInfo();
      setLocalDuration(minInSeconds);
      return;
    }

    onDurationChange(convertSecondsToMinutes(debounceDuration)); // The component receives and gives the duration in minutes
  }, [debounceDuration, minInSeconds, maxInSeconds]);

  // Used when user write the duration with keyboard
  const onLocalDurationChange = useCallback(
    (event) => {
      const durationString = event.target.value;

      // Avoid invalid intervals in clock
      if (!durationString) {
        if (minInSeconds) {
          setLocalDuration(minInSeconds);
        } else {
          setLocalDuration(minuteInSeconds);
        }
        return;
      }

      const hours = event.target.value.slice(0, 2);
      const minutes = event.target.value.slice(-2);
      const secondsDuration = convertTimeToSeconds({ hours, minutes });

      // Avoid invalid intervals in clock
      if (!secondsDuration) {
        if (minInSeconds) {
          setLocalDuration(minInSeconds);
        } else {
          setLocalDuration(minuteInSeconds);
        }
        return;
      }

      setLocalDuration(secondsDuration);
    },
    [minInSeconds]
  );

  const onIncreasing = useCallback(() => {
    setLocalDuration((prevDuration) => {
      return prevDuration + minuteInSeconds;
    });
  }, []);

  const onDecreasing = useCallback(() => {
    setLocalDuration((prevDuration) => {
      const newValue = prevDuration - minuteInSeconds;

      // Avoid invalid intervals in clock
      if (!newValue) {
        if (minInSeconds) {
          return minInSeconds;
        }

        return minuteInSeconds;
      }

      return newValue;
    });
  }, [minInSeconds]);

  const onIncreaseMouseDown = useCallback(() => {
    clickInterval.current = setInterval(() => {
      if (!isFocused) {
        setIsFocused(true);
      }

      onIncreasing();
    }, DURATION_ARROWS_PRESS_INTERVAL);
  }, []);

  useEffect(() => {
    setLocalDuration(convertMinutesToSeconds(duration)); // All internal time calculations are made in seconds
  }, [duration]);

  useEffect(() => {
    if (localDuration < minInSeconds) {
      setLocalDuration(minInSeconds);
    }
  }, [minInSeconds]);

  const onDecreaseMouseDown = useCallback(() => {
    clickInterval.current = setInterval(() => {
      if (!isFocused) {
        setIsFocused(true);
      }

      onDecreasing();
    }, DURATION_ARROWS_PRESS_INTERVAL);
  }, []);

  const onMouseUp = useCallback(() => {
    setIsFocused(false);
    clearInterval(clickInterval.current);
  }, []);

  const browser = useBrowser();

  return (
    <Container>
      <StyledArrow
        onMouseDown={onIncreaseMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseUp}
        onTouchStart={onIncreaseMouseDown}
        onTouchEnd={onMouseUp}
        onClick={onIncreasing}
        $top
      />
      <ClockContainer isFocused={isFocused}>
        <StyledInput
          min="00:01"
          onFocus={onFocus}
          onBlur={onBlur}
          browser={browser}
          readOnly={browser === browserMapping.safari}
          type="time"
          maxlength="5"
          required="required"
          value={convertSecondsToReadableTime(localDuration, {
            avoidSeconds: true,
          })}
          onChange={onLocalDurationChange}
        />
      </ClockContainer>
      <StyledArrow
        onClick={onDecreasing}
        onMouseDown={onDecreaseMouseDown}
        onMouseMove={onMouseUp}
        onMouseUp={onMouseUp}
        onTouchStart={onDecreaseMouseDown}
        onTouchEnd={onMouseUp}
      />
    </Container>
  );
};

ConfigurableClock.propTypes = {
  duration: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  onDurationChange: PropTypes.func.isRequired,
};

ConfigurableClock.defaultProps = {
  duration: null,
  max: null,
  min: null,
};

export default ConfigurableClock;
