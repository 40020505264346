import ConditionalWrapper from "components/ConditionalWrapper";
import FlexBox from "components/FlexBox";
import useMediaQuery from "hooks/useMediaQuery";
import { Heading, Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import Lottie from "react-lottie";
import styled, { css, keyframes } from "styled-components";
import { breakpoints } from "utils/breakpoints";

const lottieStyle = {
  maxWidth: 200,
  minWidth: 180,
  height: "unset",
  marginLeft: "unset",
};

const FeatureItem = ({
  animationData,
  title,
  highlightedTitle,
  text,
  index,
  ariaLabel,
  isSectionOnScreen,
}) => {
  const containerRef = useRef(null);
  const shouldRenderFlexWrapper = useMediaQuery({ maxWidth: breakpoints.md });

  return (
    <ConditionalWrapper condition={shouldRenderFlexWrapper} wrapper={<FlexWrapper />}>
      <LottieContainer index={index} isSectionOnScreen={isSectionOnScreen}>
        <Lottie
          innerRef={containerRef}
          options={{
            loop: false,
            animationData,
          }}
          style={lottieStyle}
          role="img"
          aria-labelledby={ariaLabel}
          title={ariaLabel}
        />
      </LottieContainer>
      <ConditionalWrapper condition={shouldRenderFlexWrapper} wrapper={<TexContainer />}>
        <Title
          index={index}
          isSectionOnScreen={isSectionOnScreen}
          variant="h9"
          className="uppercase"
        >
          {title}{" "}
          <Heading color="quaternary" variant="h9" className="uppercase inline">
            {highlightedTitle}
          </Heading>
        </Title>
        <Body index={index} isSectionOnScreen={isSectionOnScreen} variant="body5">
          {text}
        </Body>
      </ConditionalWrapper>
    </ConditionalWrapper>
  );
};

FeatureItem.propTypes = {
  animationData: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  highlightedTitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isSectionOnScreen: PropTypes.bool.isRequired,
};

const getAppearFromBottomKeyframes = (translateFactor) => keyframes`
  0% {
    transform: translateY(${translateFactor * 100}px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const getAnimationConfig = (translateFactor) => css`
  animation: ${getAppearFromBottomKeyframes(translateFactor)} 1.5s;
  animation-fill-mode: both;
  animation-delay: 0.5s;
`;

const appearFromBottom = css`
  opacity: 1;

  @media (min-width: ${breakpoints.md}) {
    opacity: 0;

    ${({ isSectionOnScreen, index }) =>
      isSectionOnScreen ? getAnimationConfig((index % 2) + 1) : ""};
  }

  @media (min-width: ${breakpoints.xl}) {
    ${({ isSectionOnScreen, index }) =>
      isSectionOnScreen ? getAnimationConfig((index % 4) + 1) : ""};
  }
`;

const FlexWrapper = styled(FlexBox)`
  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }
`;

const LottieContainer = styled.div`
  ${appearFromBottom};

  @media (max-width: ${breakpoints.sm}) {
    svg {
      max-width: 180px;
    }
  }
`;

const TexContainer = styled.div`
  word-break: break-word;

  span {
    margin-top: 20px;
  }

  @media (max-width: ${breakpoints.xl}) {
    margin-top: 0;
    max-width: 320px;
  }

  @media (max-width: ${breakpoints.md}) {
    margin-left: 30px;
  }

  @media (max-width: ${breakpoints.sm}) {
    margin-left: 0;
    max-width: unset;
  }
`;

const Title = styled(Heading)`
  ${appearFromBottom};
  margin-top: 30px;
  grid-row-start: 2;
  display: block;

  @media (max-width: ${breakpoints.xl}) {
    margin-top: 0;
    max-width: 320px;
  }

  @media (max-width: ${breakpoints.md}) {
    margin-top: 0;
  }

  @media (max-width: ${breakpoints.sm}) {
    max-width: unset;
  }
`;

const Body = styled(Text)`
  ${appearFromBottom};
  margin-top: 20px;
  grid-row-start: 3;

  @media (max-width: ${breakpoints.xl}) {
    max-width: 320px;
  }

  @media (max-width: ${breakpoints.sm}) {
    max-width: unset;
  }
`;

export default FeatureItem;
