import React from "react";
import { Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import BoxContainer from "./BoxContainer";

const ErrorPaymentBox = () => {
  const { t } = useTranslation();

  return (
    <BoxContainer disabled size="large">
      <Text variant="body11">{t("3402") /* Ocorreu um erro */}</Text>
    </BoxContainer>
  );
};

export default ErrorPaymentBox;
