import PropTypes from "prop-types";
import React, { useImperativeHandle } from "react";
import styled from "styled-components";
import Tab from "./components/Tab";
import TabPanel from "./components/TabPanel";
import useTabs from "./hooks/useTabs";
import { tabsDefaultProps, tabsPropTypes } from "./propTypes";

const VerticalTabs = React.forwardRef(
  ({ tabs, tabWidth, className, onTabChange }, ref) => {
    const { onChange, activeTabId } = useTabs({ tabs, onTabChange });

    useImperativeHandle(ref, () => ({ changeTab: onChange }), [onChange]);

    return (
      <Container ref={ref} className={className}>
        <TabsList width={tabWidth}>
          {tabs.map((tab) => (
            <Tab
              isActive={tab.id === activeTabId}
              key={tab.id}
              onClick={() => onChange(tab.id)}
              label={tab.label}
              icon={tab.icon}
            />
          ))}
        </TabsList>
        <TabPanel className="ml-50 tab-panel" tabs={tabs} activeTabId={activeTabId} />
      </Container>
    );
  }
);

VerticalTabs.propTypes = {
  ...tabsPropTypes,
  tabWidth: PropTypes.string,
  onTabChange: PropTypes.func,
};

VerticalTabs.defaultProps = {
  ...tabsDefaultProps,
  tabWidth: "330px",
  onTabChange: undefined,
};

export default VerticalTabs;

const Container = styled.div`
  display: flex;
`;

const TabsList = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  flex-shrink: 0;
`;
