import Button from "components/buttons/Button";
import CancelButton from "components/buttons/CancelButton";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Popup from "./Popup";
import PopupButtonsContainer from "./PopupButtonsContainer";

const defaultConfigs = {
  confirmationButtonColor: "primary",
};

const ConfirmationPopUp = ({ title, message, onCancel, onConfirm, configs }) => {
  const { t } = useTranslation();
  const { confirmationButtonColor, confirmationButtonLabel, cancelButtonLabel } = {
    ...defaultConfigs,
    confirmationButtonLabel: t("35") /* Confirmar */,
    cancelButtonLabel: t("48") /* Cancelar */,
    ...configs,
  };
  const confirmationButtonRef = useRef(null);

  useEffect(() => {
    confirmationButtonRef.current?.focus();
  }, []);

  return (
    <Popup title={title} onClose={onCancel}>
      <Text variant="body8" className="inline">
        {message}
      </Text>
      <PopupButtonsContainer>
        <CancelButton onClick={onCancel}>{cancelButtonLabel}</CancelButton>
        <Button
          ref={confirmationButtonRef}
          color={confirmationButtonColor}
          onClick={onConfirm}
        >
          {confirmationButtonLabel}
        </Button>
      </PopupButtonsContainer>
    </Popup>
  );
};

ConfirmationPopUp.defaultProps = {
  configs: defaultConfigs,
};

ConfirmationPopUp.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  configs: PropTypes.shape({
    confirmationButtonColor: PropTypes.string,
    confirmationButtonLabel: PropTypes.string,
    cancelButtonLabel: PropTypes.string,
  }),
};

export default ConfirmationPopUp;
