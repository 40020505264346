import React from "react";
import { useTranslation } from "react-i18next";
import StatusWidget from "../StatusWidget";

const EmptyListInfo = () => {
  const { t } = useTranslation();

  return (
    <StatusWidget variant="info">
      {t("10556") /* Ainda não foram realizadas quaisquer operações. */}
    </StatusWidget>
  );
};

export default EmptyListInfo;
