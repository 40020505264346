import styled from "styled-components";

const MaxWidthContainer = styled.div`
  position: relative;
  max-width: 1920px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export default MaxWidthContainer;
