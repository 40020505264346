import AbstractDataSource from "./abstractDataSource";

export default class DriversIdentificationsDataSource extends AbstractDataSource {
  async getIdentificationData(token, params = {}, headers = {}, useCache = false) {
    const response = await this.iParqueClient.GET(
      `v1/drivers/identifications/${token}`,
      params,
      headers,
      useCache
    );

    return response;
  }

  async getIdentificationEvidence(token, params = {}, headers = {}, useCache = false) {
    const response = await this.iParqueClient.GET(
      `v1/drivers/identifications/${token}/evidence`,
      params,
      headers,
      useCache
    );

    return response;
  }

  async createIdentification(token, data) {
    return this.iParqueClient.POST(`v1/drivers/identifications/${token}`, data);
  }

  async getIdentificationToken(entityId, params = {}, headers = {}, useCache = false) {
    const response = await this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/identifications/token`,
      params,
      headers,
      useCache
    );

    return response;
  }
}
