import useToggleVerticalTabs from "hooks/useToggleVerticalTabs";
import { useCallback, useState } from "react";
import { settingsTabs } from "../controller";

const useVerticalTabs = () => {
  const [displayLanguageComponent, setDisplayLanguageComponent] = useState(false);

  const {
    displayVerticalTabs,
    handleBackToTabs,
    handleDisplayTabDetails,
  } = useToggleVerticalTabs();

  const handleTabClick = useCallback(
    (tabId) => {
      if (tabId === settingsTabs.language) {
        setDisplayLanguageComponent(true);
        return;
      }

      setDisplayLanguageComponent(false);
      handleDisplayTabDetails();
    },
    [handleDisplayTabDetails, setDisplayLanguageComponent]
  );

  return {
    displayLanguageComponent,
    displayVerticalTabs,
    handleTabClick,
    handleBackToTabs,
  };
};

export default useVerticalTabs;
