import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { routesMapping } from "utils/routes";

const useBack = () => {
  const history = useHistory();

  const onBack = useCallback(() => history.push(routesMapping.backofficePermitHolders), [
    history,
  ]);

  return onBack;
};

export default useBack;
