import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Heading } from "iparque-components";
import { secondsToTime } from "../../../utils/dateTime";

export const timerSizes = {
  lg: "lg",
  md: "md",
};

const sizeToHeadingVariantMapping = {
  [timerSizes.md]: "h9",
  [timerSizes.lg]: "h8",
};

const Timer = ({ seconds, countDown, paused, size }) => {
  const [currentSeconds, setCurrentSeconds] = useState(seconds);

  useEffect(() => {
    let timer = null;
    let internalSeconds = seconds;

    if (paused) {
      return undefined;
    }

    // eslint-disable-next-line consistent-return
    timer = setInterval(() => {
      if (countDown) {
        internalSeconds -= 1;

        if (internalSeconds <= 0) {
          return () => clearInterval(timer);
        }
      } else {
        internalSeconds += 1;
      }

      setCurrentSeconds(internalSeconds);
    }, 1000);

    return () => (timer ? clearInterval(timer) : null);
  }, [seconds, paused]);

  const time = secondsToTime(currentSeconds);

  return (
    <Container>
      {time.days > 0 && <Heading variant="h10">{`${time.days}d`}</Heading>}
      <Heading variant={sizeToHeadingVariantMapping[size]}>{`${time.hours}:`}</Heading>
      <Heading variant={sizeToHeadingVariantMapping[size]}>{`${time.minutes}`}</Heading>
      <Heading variant="h10">{`${time.seconds}`}</Heading>
    </Container>
  );
};

export default Timer;

Timer.defaultProps = {
  countDown: false,
  paused: false,
  size: timerSizes.medium,
};

Timer.propTypes = {
  paused: PropTypes.bool,
  seconds: PropTypes.number.isRequired,
  size: PropTypes.oneOf(Object.values(timerSizes)),
  countDown: PropTypes.bool,
};

const Container = styled.div`
  display: flex;
  margin-top: 10px;
  line-height: 0.8;

  * {
    color: ${({ theme }) => theme.parkings.timer.value};
  }
`;
