import { useAuth } from "components/AuthProvider";
import Button from "components/buttons/Button";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPermitTypeDocuments } from "requests/permitHolders";
import { usePermitHoldersDetailsContext } from "../../Provider";
import Popup from "./Popup";

const AddButton = (props) => {
  const { t } = useTranslation();
  const [displayPopup, setDisplayPopup] = useState(false);
  const [permitDocuments, setPermitDocuments] = useState([]);
  const { defaultEntityId: entityId } = useAuth();
  const {
    permit: { id: permitId },
  } = usePermitHoldersDetailsContext();

  const handlePopupClose = () => setDisplayPopup(false);
  const handlePopupOpen = () => setDisplayPopup(true);

  useEffect(() => {
    (async () => {
      const response = await getPermitTypeDocuments(
        entityId,
        permitId,
        {
          noLimit: true,
        },
        { t }
      );

      if (response.hasError) {
        return;
      }

      setPermitDocuments(
        response.items.map((doc) => ({ value: doc.id, label: doc.designation }))
      );
    })();
  }, []);

  return (
    <>
      {displayPopup && (
        <Popup documentTypes={permitDocuments} onClose={handlePopupClose} />
      )}
      <Button onClick={handlePopupOpen} {...props}>
        {t("3387") /* Adicionar */}
      </Button>
    </>
  );
};

export default AddButton;
