import { usePublicEntityContext } from "domain/public/entities/Provider";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getGenders } from "requests/clients";
import useCountries from "hooks/useCountries";
import Yup from "yup.js";
import { actionTypes } from "domain/public/entities/driversIdentifications/store/actions";
import { useDriverIdentificationContext } from "domain/public/entities/driversIdentifications/Provider";
import { useStepsContext } from "domain/public/components/StepsProvider";
import { partyTypes } from "utils/clients";
import { STEPS } from "../../../helper";

const useForm = () => {
  const { t } = useTranslation();
  const { entity } = usePublicEntityContext();
  const { dispatch, personalData } = useDriverIdentificationContext();
  const { goToStep } = useStepsContext();
  const { countryOptions, countryCodesMapping } = useCountries({ entityId: entity?.id });
  const [genderOptions, setGenderOptions] = useState([]);

  const initialData = {
    ...personalData,
    companyTaxpayerIdentificationNumber:
      personalData.partyTypeId === partyTypes.company
        ? personalData.taxpayerIdentificationNumber
        : "",
    taxpayerIdentificationNumber:
      personalData.partyTypeId === partyTypes.person
        ? personalData.taxpayerIdentificationNumber
        : "",
  };

  useEffect(() => {
    (async () => {
      if (entity) {
        const clientGendersResponse = await getGenders(
          entity.id,
          { noLimit: true },
          true
        );

        setGenderOptions(
          clientGendersResponse.items.map((gender) => ({
            label: gender.name,
            value: gender.id,
          }))
        );
      }
    })();
  }, [entity]);

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string().trim().required(t("10271") /* O campo é obrigatório */),
      genderId: Yup.number().required(t("10271") /* O campo é obrigatório */),
      taxpayerIdentificationNumber: Yup.string()
        .trim()
        .when("companyTaxpayerIdentificationNumber", {
          is: undefined,
          then: Yup.string()
            .required(t("10271") /* O campo é obrigatório */)
            .vat(
              t("9977") /* O campo é inválido para o país selecionado */,
              countryCodesMapping
            ),
        }),
      companyTaxpayerIdentificationNumber: Yup.string()
        .trim()
        .when("taxpayerIdentificationNumber", {
          is: undefined,
          then: Yup.string().required(t("10271") /* O campo é obrigatório */),
        }),
      countryId: Yup.number().required(t("10271") /* O campo é obrigatório */),
      address: Yup.string().trim().required(t("10271") /* O campo é obrigatório */),
      zipCode: Yup.string()
        .trim()
        .required(t("10271") /* O campo é obrigatório */)
        .zipCode(
          t("9977") /* O campo é inválido para o país selecionado */,
          countryCodesMapping
        ),
      locality: Yup.string().trim().required(t("10271") /* O campo é obrigatório */),
      complementaryAddress: Yup.string()
        .trim()
        .required(t("10271") /* O campo é obrigatório */),
    },
    [["taxpayerIdentificationNumber", "companyTaxpayerIdentificationNumber"]]
  );

  const submit = (values) => {
    const formValues = { ...values };
    formValues.partyTypeId = partyTypes.person;

    if (formValues.companyTaxpayerIdentificationNumber) {
      formValues.taxpayerIdentificationNumber =
        formValues.companyTaxpayerIdentificationNumber;

      formValues.partyTypeId = partyTypes.company;
    }

    delete formValues.companyTaxpayerIdentificationNumber;

    dispatch({
      type: actionTypes.SET_PERSONAL_DATA,
      payload: formValues,
    });

    goToStep(STEPS.documents);
  };

  return {
    initialData,
    validationSchema,
    submit,
    countries: countryOptions,
    genders: genderOptions,
  };
};

export default useForm;
