export const colorsMapping = {
  black: 1,
  red: 2,
  yellow: 3,
  green: 4,
  white: 5,
  blue: 6,
  gray: 7,
  orange: 8,
};

export const getColorsArray = (themeColors) => {
  return Object.values(themeColors);
};
