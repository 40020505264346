import PendingOperationsProvider from "components/pendingOperations/Provider";
import PublicPagePopups from "domain/public/components/PagePopups";
import "lib/firebase/firebaseHelper";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import AppProvider from "./components/AppProvider";
import AppThemeProvider from "./components/AppThemeProvider";
import AuthProvider from "./components/AuthProvider";
import PrivatePage from "./domain/private/Page";
import PublicPage from "./domain/public/Page";
import NetInfoProvider from "./providers/NetInfo";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_4_TRACKING_ID);

const setMetaDescription = (description) => {
  document.querySelector("meta[name='description']").setAttribute("content", description);
};

function App() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("10675") /* iParque Driver –  App de estacionamentos */;

    setMetaDescription(
      t(
        "10684"
      ) /* A app que dá liberdade total ao condutor no pagamento do estacionamento. Deixe-se de trocos! */
    );
  }, [t]);

  return (
    <AppThemeProvider>
      <Router basename={process.env.REACT_APP_BASE_RELATIVE_ROUTES}>
        <NetInfoProvider>
          <AuthProvider>
            <AppProvider>
              <PendingOperationsProvider>
                <PublicPagePopups>
                  <PublicPage />
                </PublicPagePopups>
                <PrivatePage />
              </PendingOperationsProvider>
            </AppProvider>
          </AuthProvider>
        </NetInfoProvider>
      </Router>
    </AppThemeProvider>
  );
}

export default App;
