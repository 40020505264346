/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import useOnPageScrollEnd from "hooks/useOnPageScrollEnd";
import { useAuth } from "../../../components/AuthProvider";
import { useNetInfoContext } from "../../../providers/NetInfo";
import DriversDataSource from "../../../lib/clients/iParque/dataSources/driversDataSource";
import { errorMessage } from "../../../utils/userMessages";
import Parkings from "./Parkings";
import { convertMinutesToSeconds } from "../../../utils/dateTime";
import { colorsMapping } from "../../../utils/colors";
import { lazyLoadPaginationLimit } from "../../../utils/pagination";
import { AppContext } from "../../../components/AppProvider";
import { smallDevicesBreakpoints } from "../../../utils/breakpoints";
import ParkingsSmallDevices from "./ParkingsSmallDevices";
import { formatMoney } from "../../../utils/money";
import { getEndingDate } from "../../../utils/parking";
import { sortOrder } from "../../../utils/server";
import useParkingTypeNames from "../hooks/useParkingTypeNames";

const driversDataSource = new DriversDataSource();

const attachVehicleInformation = (parkings, vehicles, { t }) => {
  const vehiclesProcessed = {};

  vehicles.forEach((vehicle) => {
    vehiclesProcessed[vehicle.licensePlate] = vehicle;
  });

  return parkings.map((parking) => {
    return {
      ...parking,
      vehicleBrand:
        vehiclesProcessed[parking.licensePlate]?.model?.brand?.name ||
        t("6332") /* Desconhecido */,
      vehicleColorId:
        vehiclesProcessed[parking.licensePlate]?.colorId || colorsMapping.gray,
    };
  });
};

let totalParkingsPages = 1;
const START_AND_STOP_UPDATE_INTERVAL = 60000;

const ParkingsPage = () => {
  const { defaultEntityId, updateBalance, driverHash } = useAuth();
  const { t } = useTranslation();
  const { hasInternetAccess } = useNetInfoContext();
  const [fetchingParkings, setFetchingParkings] = useState(false);
  const [parkings, setParkings] = useState({ actives: [], finished: [] });
  const parkingsPage = useRef(0);
  const { setIsLoading, isLoading, breakpoint } = useContext(AppContext);
  const vehicles = useRef();
  const parkingTypeNames = useParkingTypeNames();

  const processFinishedParkings = useCallback(
    (parkingsResponse) => {
      return parkingsResponse.map((parking) => {
        const benefit = {};

        if (parking?.benefits.length && parking?.benefits[0]?.parkingBenefit) {
          benefit.name = parking?.benefits[0].parkingBenefit.name || "";
          benefit.typeId = parseInt(parking?.benefits[0].parkingBenefit.type.id, 10);
        }

        return {
          id: parking.id,
          licensePlate: parking.licensePlate,
          vehicleColorId: parking.vehicleColorId,
          streetName: parking.street?.name || "-",
          explorationName: parking.street?.zone?.exploration?.name || "-",
          amount: formatMoney(parking.amount),
          endingDate: parking.endingDate,
          startingDate: parking.startingDate,
          duration: convertMinutesToSeconds(parking.duration),
          parkingTypeId: parking.parkingType.id,
          parkingTypeName: parkingTypeNames[parking.parkingType.id],
          stateId: parking.state.id,
          stateName: parking.state.name,
          location: {
            lat: parking?.vehicleLocation?.latitude || null,
            lng: parking?.vehicleLocation?.longitude || null,
          },
          benefit,
        };
      });
    },
    [parkingTypeNames]
  );

  const processActivesParkings = useCallback(
    (parkingsResponse) => {
      return parkingsResponse.map((parking) => {
        const benefit = {};

        if (parking?.benefits.length) {
          benefit.name = parking?.benefits[0].parkingBenefit.name || "";
          benefit.typeId = parseInt(parking?.benefits[0].parkingBenefit.type.id, 10);
        }

        return {
          id: parking.id,
          licensePlate: parking.licensePlate,
          vehicleColorId: parking.vehicleColorId,
          streetName: parking.street.name,
          streetId: parking.street.id,
          explorationName: parking.street.zone.exploration.name,
          amount: formatMoney(parking.amount),
          paymentMethodId: parking.parkingPaymentType.id,
          endingDate: getEndingDate(
            parking.parkingType.id,
            parking.state.id,
            parking?.endingDate
          ),
          startingDate: parking.startingDate,
          parkingTypeId: parking.parkingType.id,
          parkingTypeName: parkingTypeNames[parking.parkingType.id],
          vehicleBrand: parking.vehicleBrand,
          stateId: parking.state.id,
          stateName: parking.state.name,
          feeTypeId: parking.feeType.id,
          scheduleExtraInfo: parking.scheduleExtraInfo,
          location: {
            lat: parking?.vehicleLocation?.latitude,
            lng: parking?.vehicleLocation?.longitude,
          },
          benefit,
        };
      });
    },
    [parkingTypeNames]
  );

  const onPageScrollEnd = useCallback(async () => {
    if (fetchingParkings) {
      return;
    }

    setFetchingParkings(true);

    const newPage = parkingsPage.current + 1;

    if (newPage >= totalParkingsPages) {
      return;
    }

    try {
      const finishedParkings = await driversDataSource.getParkings(
        defaultEntityId,
        driverHash,
        {
          limit: lazyLoadPaginationLimit,
          skip: newPage * lazyLoadPaginationLimit,
          fillCollections: "all",
          active: false,
          sortBy: "id",
          sortOrder: sortOrder.desc,
          avoidLicensePlateValidation: true,
        }
      );

      totalParkingsPages = finishedParkings.totalPages;
      parkingsPage.current = newPage;

      const finishedParkingsWithVehicleInformation = attachVehicleInformation(
        finishedParkings.items,
        vehicles.current,
        { t }
      );

      setParkings((currentParkings) => ({
        ...currentParkings,
        finished: [
          ...currentParkings.finished,
          ...processFinishedParkings(finishedParkingsWithVehicleInformation),
        ],
      }));
    } catch (error) {
      errorMessage(
        error,
        t("10037") /* Ocorreu um erro ao obter os estacionamentos finalizados */
      );
    } finally {
      setFetchingParkings(false);
    }
  }, [fetchingParkings]);

  useOnPageScrollEnd(onPageScrollEnd, { offset: 70 });

  const getData = useCallback(
    (useLoader = true) => {
      if (useLoader) {
        setIsLoading(true);
      }

      Promise.all([
        driversDataSource.getVehicles(
          driverHash,
          { noLimit: true, fillCollections: "model" },
          {},
          true
        ),
        driversDataSource.getParkings(defaultEntityId, driverHash, {
          noLimit: true,
          fillCollections: "all",
          active: true,
          sortBy: "id",
          sortOrder: sortOrder.desc,
          avoidLicensePlateValidation: true,
        }),
        driversDataSource.getParkings(defaultEntityId, driverHash, {
          limit: lazyLoadPaginationLimit,
          fillCollections: "all",
          active: false,
          sortBy: "id",
          sortOrder: sortOrder.desc,
          avoidLicensePlateValidation: true,
        }),
      ])
        .then((response) => {
          const [
            vehiclesResponse,
            activeParkingsResponse,
            finishedParkingsResponse,
          ] = response;

          vehicles.current = vehiclesResponse.items;
          const activeParkingsWithoutVehicleInfo = activeParkingsResponse.items;
          const finishedParkingsWithoutVehicleInfo = finishedParkingsResponse.items;

          const activeParkingsWithVehicleInformation = attachVehicleInformation(
            activeParkingsWithoutVehicleInfo,
            vehicles.current,
            { t }
          );

          const finishedParkingsWithVehicleInformation = attachVehicleInformation(
            finishedParkingsWithoutVehicleInfo,
            vehicles.current,
            { t }
          );

          totalParkingsPages = finishedParkingsResponse.totalPages;

          setParkings({
            actives: processActivesParkings(activeParkingsWithVehicleInformation),
            finished: [
              ...parkings.finished,
              ...processFinishedParkings(finishedParkingsWithVehicleInformation),
            ],
          });

          updateBalance();
        })
        .catch((error) => {
          errorMessage(
            error,
            t("7996") /* Ocorreu um erro ao obter os estacionamentos */
          );
        })
        .finally(() => {
          if (useLoader) {
            setIsLoading(false);
          }
        });
    },
    [parkingsPage]
  );

  useEffect(() => {
    if (!hasInternetAccess) {
      return undefined;
    }

    const updateInterval = setInterval(() => {
      getData(false);
    }, START_AND_STOP_UPDATE_INTERVAL);

    return () => {
      clearInterval(updateInterval);
      setIsLoading(false);
    };
  }, [hasInternetAccess]);

  useEffect(getData, []);

  const chooseParkingsViewBasedOnDevice = () => {
    const props = {
      activesParkings: parkings.actives,
      finishedParkings: parkings.finished,
      updateCallback: getData,
    };

    if (smallDevicesBreakpoints.includes(breakpoint)) {
      return <ParkingsSmallDevices {...props} />;
    }

    return <Parkings {...props} />;
  };

  if (isLoading) {
    return null;
  }

  return <>{chooseParkingsViewBasedOnDevice()}</>;
};

export default ParkingsPage;
