import React from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import CheckboxField from "components/formFields/Checkbox";
import { Form as FormikForm, Formik } from "formik";
import Yup from "yup.js";
import { Button, Text } from "iparque-components";

const DriversIdentificationsInformations = ({
  onAdvance,
  areTheTermsAndConditionsAccepted,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <StyledText color="quintenary" variant="body3">
          <Trans i18nKey="10525" components={{ b: <b /> }} />
          {/* Para efetuar o pagamento da infração, deverá, antes de mais, proceder à identificação voluntária, necessária ao levantamento do auto de contraordenação que, se aplicável, conduzirá à aplicação da respetiva coima pela ANSR ou pela concessionária consoante se trate de contraordenação grave ou leve respetivamente. */}
        </StyledText>
        <StyledText className="mt-20" color="quintenary" variant="body3">
          <Trans i18nKey="10526" components={{ b: <b /> }} />
          {/* Caso proceda à <b>identificação voluntária através do preenchimento do formulário</b> será enviada, por carta registada, a notificação do auto de contraordenação donde constará a referência multibanco, que poderá ser paga no prazo de 15 dias úteis (a contar da data da notificação) nas caixas MB. */}
        </StyledText>
      </div>
      <Formik
        initialValues={{ termsAndConditions: areTheTermsAndConditionsAccepted }}
        validationSchema={Yup.object({
          termsAndConditions: Yup.bool().oneOf(
            [true],
            t("10224") /* Deves aceitar as condições apresentadas */
          ),
        })}
        onSubmit={onAdvance}
      >
        <StyledForm className="mt-20">
          <CheckboxField
            name="termsAndConditions"
            label={
              <Text color="quintenary" variant="body6">
                {t("10220") /* Confirmo e aceito as condições apresentadas */}
              </Text>
            }
          />
          <ButtonContainer>
            <Button type="submit" color="quintenary" className="uppercase" fullWidth>
              {t("10223") /* Avançar */}
            </Button>
          </ButtonContainer>
        </StyledForm>
      </Formik>
    </>
  );
};

DriversIdentificationsInformations.propTypes = {
  onAdvance: PropTypes.func.isRequired,
  areTheTermsAndConditionsAccepted: PropTypes.bool,
};

DriversIdentificationsInformations.defaultProps = {
  areTheTermsAndConditionsAccepted: false,
};

export default DriversIdentificationsInformations;

const StyledForm = styled(FormikForm)`
  display: flex;
  flex-flow: column wrap;
  flex: 1;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 2em 0 0 auto;
  width: 150px;

  @media (max-width: ${breakpoints.sm}) {
    justify-content: center;
    width: 100%;
  }
`;

const StyledText = styled(Text)`
  line-height: 1.5em;
`;
