/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../components/AppProvider";
import { useAuth } from "../../../components/AuthProvider";
import { breakpointsMapping, smallDevicesBreakpoints } from "../../../utils/breakpoints";
import Profile from "./Profile";
import SmallDevicesProfile from "./smallDevices/Profile";
import MediumDevicesProfile from "./mediumDevices/Profile";
import {
  getById as getUserByIdRequest,
  update as updateUserRequest,
  changePassword as changeUserPasswordRequest,
} from "../../../requests/driver";
import useChangeDefaultEntity from "../../../hooks/useChangeDefaultEntity";
import useCountries from "../../../hooks/useCountries";
import { fieldsMapping } from "./utils";
import { removeCache } from "../../../utils/cache";

const ProfilePage = () => {
  const { breakpoint, setIsLoading } = useContext(AppContext);
  const { updateUser, driverHash } = useAuth();
  const { countryOptions: countries, countryCodesMapping } = useCountries();
  const [editUserForm, setUserFormEditing] = useState(false);
  const [editPasswordForm, setPasswordFormEditing] = useState(false);
  const [extraRequiredFields, setExtraRequiredFields] = useState([]);
  const chosenEntity = useRef(null);
  const [user, setUser] = useState({
    uid: "",
    name: "",
    email: "",
    vat: "",
    phone: null,
    countryId: null,
    id: null,
  });

  const { t } = useTranslation();
  const changeDefaultEntity = useChangeDefaultEntity();

  const [title, setTitle] = useState({
    top: t("8185") /* Beep Beep! */,
    bottom: t("8186") /* Estacionaste no teu perfil! */,
  });
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.missingFields.length) {
      setTitle({
        top: t("8203") /* Para escolheres esta entidade */,
        bottom: t("8204") /* tens de preencher os seguintes campos */,
      });
      setUserFormEditing(true);
      setExtraRequiredFields(location.state.missingFields);

      chosenEntity.current = location.state.entityId;
    }
  }, [location]);

  useEffect(() => {
    const getInitialData = async () => {
      removeCache(`/drivers/${driverHash}`); // Clear existing user cache

      setIsLoading(true);

      const userInfo = await getUserByIdRequest(driverHash);

      if (userInfo) {
        setUser(userInfo);
      }

      setIsLoading(false);
    };

    getInitialData();
  }, []);

  const enableUserFormEditing = setUserFormEditing;

  const enablePasswordFormEditing = setPasswordFormEditing;

  const saveUserCallback = useCallback(
    (userInfo) => {
      const saveUser = async () => {
        setIsLoading(true);

        const response = await updateUserRequest(driverHash, userInfo);

        if (response === true) {
          updateUser(userInfo);
          enableUserFormEditing(false);
          setUser({ ...user, ...userInfo, phone: userInfo[fieldsMapping.phone] });

          if (extraRequiredFields.length && chosenEntity.current) {
            await changeDefaultEntity(chosenEntity.current);
          }
        } else if (Array.isArray(response)) {
          setExtraRequiredFields(response);
        }

        setIsLoading(false);
      };

      saveUser();
    },
    [extraRequiredFields, user]
  );

  const saveUserPasswordCallback = useCallback((passwordData) => {
    const changeUserPassword = async () => {
      setIsLoading(true);

      const saveUserPasswordRequest = await changeUserPasswordRequest(
        driverHash,
        passwordData
      );

      if (saveUserPasswordRequest) {
        enablePasswordFormEditing(false);
      }

      setIsLoading(false);
    };

    changeUserPassword();
  }, []);

  const chooseViewBasedOnDevice = () => {
    const props = {
      user,
      countries,
      countryCodesMapping,
      saveUserCallback,
      saveUserPasswordCallback,
      onUserFormEditClickCallback: enableUserFormEditing,
      onPasswordEditClickCallback: enablePasswordFormEditing,
      editUserForm,
      editPasswordForm,
      title,
      extraRequiredFields,
    };

    if (breakpoint === breakpointsMapping.lg) {
      return <MediumDevicesProfile {...props} />;
    }

    if (smallDevicesBreakpoints.includes(breakpoint)) {
      return <SmallDevicesProfile {...props} />;
    }

    return <Profile {...props} />;
  };

  return <>{chooseViewBasedOnDevice()}</>;
};

export default ProfilePage;
