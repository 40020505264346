import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import FlexBox from "components/FlexBox";
import Text from "components/Text";
import { useBackofficeContext } from "domain/private/components/BackOfficeProvider";
import { LABEL_VALUE_CARD_VALUE_VARIANT } from "domain/private/components/cardList/LabelValueCell";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { deletePermitHolderDocument } from "requests/permitHolders";
import styled, { useTheme } from "styled-components";
import { userTypes } from "utils/users";
import useWarnings from "../../hooks/useWarnings";
import { usePermitHoldersDetailsContext } from "../../Provider";
import { actionTypes } from "../../store/actions";
import ListCard from "../ListCard";

const ICON_SIZE = 25;
const SEARCH_ICON_SIZE = ICON_SIZE + 2;

const Card = ({ id, typeName, url, ...other }) => {
  const {
    isEditable,
    token: permitHolderToken,
    documentsTab: {
      list: { update },
    },
    dispatch,
  } = usePermitHoldersDetailsContext();
  const { displayConfirmation } = useBackofficeContext();
  const { setIsLoading } = useAppContext();
  const { defaultEntityId: entityId, userId: driverId, driverHash } = useAuth();
  const { updateWarnings } = useWarnings();
  const theme = useTheme();
  const { t } = useTranslation();

  const onDetails = () => window.location.assign(url);

  const onRemove = () => {
    displayConfirmation({
      title: t("4134") /* Eliminar documento */,
      message: t("10597") /* Tens a certeza que pretendes eliminar o documento? */,
      onConfirm: async () => {
        setIsLoading(true);

        const result = await deletePermitHolderDocument(
          entityId,
          driverHash,
          permitHolderToken,
          id,
          {
            userId: driverId,
            userTypeId: userTypes.client,
          },
          { t }
        );

        if (!result) {
          setIsLoading(false);
          return;
        }

        await Promise.all([update(), updateWarnings()]);
        setIsLoading(false);

        dispatch({ type: actionTypes.SET_IS_EDITABLE, payload: false });
      },
    });
  };

  return (
    <ListCard {...other}>
      <FlexBox className="fill">
        <Text numberOfLines={1} variant={LABEL_VALUE_CARD_VALUE_VARIANT}>
          {typeName}
        </Text>
      </FlexBox>
      <IconsContainer>
        {url && (
          <SearchIcon
            fill={theme.icon.color}
            width={SEARCH_ICON_SIZE}
            height={SEARCH_ICON_SIZE}
            onClick={onDetails}
            cursor="pointer"
            title={t("1401") /* Download */}
            className="document-icon"
          />
        )}
        {isEditable && (
          <TrashIcon
            fill={theme.color.danger}
            width={ICON_SIZE}
            height={ICON_SIZE}
            onClick={onRemove}
            cursor="pointer"
            title={t("717") /* Eliminar */}
            className="document-icon"
          />
        )}
      </IconsContainer>
    </ListCard>
  );
};

Card.propTypes = {
  id: PropTypes.number.isRequired,
  typeName: PropTypes.string.isRequired,
  url: PropTypes.string,
};

Card.defaultProps = {
  url: undefined,
};

export default React.memo(Card);

const IconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60px;
  align-items: center;

  .document-icon {
    :only-child {
      margin-left: auto;
    }
  }
`;
