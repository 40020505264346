import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { Heading, Text } from "iparque-components";
import { ReactComponent as CarIcon } from "assets/icons/car.svg";
import { breakpoints } from "utils/breakpoints";

const highlightedStyle = css`
  ${({ theme, color }) => css`
    background-color: ${color};

    .vehicle-box-licence-plate,
    .vehicle-box-brand,
    .vehicle-box-warning {
      color: ${theme.background.color.background};
    }

    .vehicle-box-icon {
      fill: ${theme.background.color.background};
    }
  `}
`;

const Container = styled.div`
  border: 2px solid ${({ color }) => color};
  display: flex;
  align-items: center;
  padding: 15px 20px;
  flex-basis: calc(50% - 10px);
  box-sizing: border-box;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
  cursor: pointer;

  :hover {
    ${highlightedStyle}
    opacity: ${({ selected }) => (selected ? 0.7 : 1)};
  }

  ${({ selected }) => selected && highlightedStyle}

  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
    justify-content: space-around;
    padding: 20px 0;
  }
`;

const StyledCarIcon = styled(CarIcon)`
  fill: ${({ color }) => color};
  flex-shrink: 0;

  @media (max-width: ${breakpoints.lg}) {
    width: 60px;
    height: 60px;
  }
`;

const StyledHeading = styled(Heading)`
  color: ${({ textColor }) => textColor};
`;

const StyledText = styled(Text)`
  color: ${({ textColor }) => textColor};

  @media (max-width: ${breakpoints.lg}) {
    text-align: center;
  }
`;

const WarningText = styled(Text)`
  color: ${({ theme }) => theme.color.danger};

  @media (max-width: ${breakpoints.lg}) {
    margin-top: 10px;
  }
`;

const CarInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  @media (max-width: ${breakpoints.lg}) {
    margin: 10px 10px 0;
    align-items: center;

    .vehicle-box-licence-plate {
      line-height: 15px;
    }
  }
`;

const VehicleBox = ({
  brand,
  model,
  licensePlate,
  color,
  id,
  onClick,
  disabled,
  warningText,
  selected,
  className,
}) => {
  return (
    <Container
      onClick={() => onClick(id)}
      color={color}
      disabled={disabled}
      className={className}
      selected={selected}
    >
      <StyledCarIcon className="vehicle-box-icon" color={color} />
      <CarInfoContainer>
        <StyledHeading
          className="vehicle-box-licence-plate"
          textColor={color}
          variant="h5"
        >
          {licensePlate}
        </StyledHeading>
        <StyledText className="vehicle-box-brand" textColor={color} variant="body6">
          {brand} {model}
        </StyledText>
        {warningText && (
          <WarningText className="vehicle-box-warning" variant="body10">
            &bull; {warningText}
          </WarningText>
        )}
      </CarInfoContainer>
    </Container>
  );
};

VehicleBox.propTypes = {
  color: PropTypes.string.isRequired,
  brand: PropTypes.string,
  model: PropTypes.string,
  licensePlate: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  warningText: PropTypes.string,
  className: PropTypes.string,
};

VehicleBox.defaultProps = {
  brand: undefined,
  model: undefined,
  disabled: false,
  warningText: undefined,
  className: "",
  selected: false,
};

export default VehicleBox;
