import useQuery from "hooks/useQuery";
import { useCallback, useState } from "react";

const useTabs = ({ tabs, onTabChange }) => {
  const { activeTabId: initialActiveTab } = useQuery();
  const [activeTabId, setActiveTabId] = useState(initialActiveTab || tabs[0].id);

  const onChange = useCallback(
    (newActiveTab) => {
      setActiveTabId(newActiveTab);

      const url = new URL(window.location);
      url.searchParams.set("activeTabId", newActiveTab);
      window.history.pushState(null, "", url.toString());

      onTabChange?.(newActiveTab);
    },
    [onTabChange]
  );

  return { initialActiveTab, onChange, activeTabId };
};

export default useTabs;
