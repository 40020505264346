// eslint-disable-next-line import/prefer-default-export
export const removeCache = (cacheKey) => {
  Object.keys(sessionStorage).forEach((sessionStorageKey) => {
    if (
      sessionStorageKey
        .toLocaleLowerCase()
        .includes(cacheKey.toLocaleLowerCase())
    ) {
      sessionStorage.removeItem(sessionStorageKey);
    }
  });
};
