/* eslint-disable react/jsx-props-no-spreading */
import Axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../components/AppProvider";
import ParkingFinished from "./Finished";
import ParkingFinishedSmallDevices from "./FinishedSmallDevices";
import { useAuth } from "../../../components/AuthProvider";
import { stopParking as stopParkingRequest } from "./controller";
import { getParking as getParkingRequest } from "../requests/parkings";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import { parkingStatesMapping as animationStates } from "../components/ParkedCar";
import { smallDevicesBreakpoints } from "../../../utils/breakpoints";
import useQuery from "../../../hooks/useQuery";

const ParkingFinishPage = () => {
  const { parkingId } = useParams();
  const { defaultEntityId, driverHash } = useAuth();
  const { breakpoint, setIsLoading: setPageLoading } = useContext(AppContext);
  const { backofficeTheme } = useBackofficeThemeContext();

  const {
    amount,
    benefitColor,
    benefitName,
    duration,
    explorationName,
    streetName,
    streetId,
    vehicleBrand,
    vehicleColor,
    vehicleLicensePlate,
  } = useQuery();

  const [parking, setParking] = useState({
    amount: parseFloat(amount) || 0,
    duration: parseInt(duration, 10) || 0,
    benefitColor: benefitColor || "",
    benefitName: benefitName || "",
    explorationName: explorationName || "",
    streetName: streetName || "",
    vehicleBrand: vehicleBrand || "",
    vehicleColor: vehicleColor || "",
    vehicleLicensePlate: vehicleLicensePlate || "",
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (smallDevicesBreakpoints.includes(breakpoint)) {
      setPageLoading(isLoading); // Block all screen in small devices
    }
  }, [breakpoint, isLoading]);

  useEffect(() => {
    const parkingInfoCancelToken = Axios.CancelToken.source();
    const vehiclesCancelToken = Axios.CancelToken.source();
    const stopParkingCancelToken = Axios.CancelToken.source();

    const getParking = async () => {
      setIsLoading(true);

      const stopParking = await stopParkingRequest(defaultEntityId, streetId, parkingId, {
        stopParkingCancelToken,
      });

      if (stopParking === null) {
        setIsLoading(false);
        return;
      }

      const parkingInfo = await getParkingRequest(
        defaultEntityId,
        driverHash,
        parkingId,
        {
          parkingInfoCancelToken,
          vehiclesCancelToken,
        }
      );

      setIsLoading(false);

      if (parkingInfo === null) {
        return;
      }

      setParking({
        ...parkingInfo,
        ...stopParking,
        benefitName: parkingInfo.benefitName,
        vehicleColor: backofficeTheme.carColor[parkingInfo.vehicleColorId],
        benefitColor: parkingInfo.benefitTypeId
          ? backofficeTheme.benefitColor[parkingInfo.benefitTypeId]
          : "",
        animationState: animationStates.afterParking,
      });
    };

    getParking();

    return () => {
      parkingInfoCancelToken.cancel();
      vehiclesCancelToken.cancel();
      stopParkingCancelToken.cancel();
    };
  }, []);

  const chooseViewBasedOnDevice = () => {
    if (smallDevicesBreakpoints.includes(breakpoint)) {
      return <ParkingFinishedSmallDevices isLoading={isLoading} {...parking} />;
    }

    return <ParkingFinished isLoading={isLoading} {...parking} />;
  };

  return <>{chooseViewBasedOnDevice()}</>;
};

export default ParkingFinishPage;
