/* eslint-disable no-undef */
import { useAppContext } from "components/AppProvider";
import { useEffect, useState } from "react";
import { breakpointsMapping } from "utils/breakpoints";
import { GOOGLE_MAPS_URL } from "../utils/configs";

const useGoogleMaps = (containerId, mapOptions) => {
  const [map, setMap] = useState(null);
  const [googleRef, setGoogleRef] = useState(null);
  const { breakpoint } = useAppContext();

  const handleMapCreation = () => {
    const mapConfigs = {
      zoom: 2,
      gestureHandling: [
        breakpointsMapping.xs,
        breakpointsMapping.sm,
        breakpointsMapping.md,
      ].includes(breakpoint)
        ? "greedy"
        : "cooperative",
      keyboardShortcuts: false,
      disableDefaultUI: true,
      center: {
        lat: 0,
        lng: 0,
      },
      ...mapOptions,
    };

    setGoogleRef(google);

    const googleMap = new google.maps.Map(
      document.getElementById(containerId),
      mapConfigs
    );

    google.maps.event.addListenerOnce(googleMap, "tilesloaded", () => {
      const checkExist = setInterval(() => {
        const images = document.querySelectorAll(
          `#${containerId} .gm-bundled-control img`
        );

        if (images.length) {
          for (let index = 0; index < images.length; index += 1) {
            const image = images[index];

            image.alt = "";
          }

          clearInterval(checkExist);
        }
      }, 500);
    });

    setMap(googleMap);
  };

  useEffect(() => {
    if (typeof google === "object" && typeof google.maps === "object") {
      handleMapCreation();
    } else {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = GOOGLE_MAPS_URL;
      googleMapScript.defer = true;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener("load", () => handleMapCreation());
    }
  }, []);

  return { map, google: googleRef };
};

export default useGoogleMaps;
