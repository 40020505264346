/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { paymentMethodsMapping, paymentTypesMapping } from "utils/payment";
import { parkingRenewDefaultPropTypes, parkingRenewPropTypes } from "./propTypes";
import ContentBorderBox from "../components/ContentBorderBox";
import ContentHeader from "../components/ContentHeader";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import ParkedCarBox from "../components/parkings/ParkedCarBox";
import ParkingChooseDuration from "./ChooseDuration";
import ParkingDetails from "./Details";
import Loader from "../../../components/loader/Loader";
import { feeTypesMapping } from "../../../utils/fees";
import LongFeeDuration from "./LongFeeDuration";
import { generateTitle } from "./helper";
import ParkingContainer from "../components/ParkingContainer";
import { parkingRenewalSteps } from "./controller";
import MbWayPaymentBox from "../parking/paymentMethods/MbWayPaymentBox";

const PageContainer = styled.div`
  max-width: 68vw;
  display: flex;
  flex-direction: column;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const ParkingRenew = ({
  chooseDurationProps,
  detailsProps,
  vehicleColor,
  animationState,
  isRenewed,
  isLoading,
  step,
  fee,
  paymentMethod,
  onRenewParkingWithSpecialPaymentMethod,
  onBack,
  allowBack,
  setAllowBack,
}) => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();
  const { parking, checkParkingCallback, calculatedParking } = chooseDurationProps;

  const isMinutesFee = fee.id === feeTypesMapping.minutes;

  const renderParkingDetails = () => {
    if (isRenewed) {
      return <ParkingDetails {...detailsProps} fee={fee} />;
    }

    if (step === parkingRenewalSteps.specialPaymentMethod) {
      if (parking.parkingPaymentTypeId === paymentMethodsMapping.mbway.id) {
        return (
          <MbWayPaymentBox
            defaultIsOpen
            {...paymentMethod}
            amount={calculatedParking.amount}
            onConfirmPayment={onRenewParkingWithSpecialPaymentMethod}
            onSuccess={checkParkingCallback}
            onCancel={onBack}
            setAllowBackStepCallback={setAllowBack}
            disabled={false}
            itemTypeId={paymentTypesMapping.parkingPayment}
          />
        );
      }

      return null;
    }

    if (isMinutesFee) {
      return <ParkingChooseDuration {...chooseDurationProps} fee={fee} />;
    }

    return <LongFeeDuration {...chooseDurationProps} fee={fee} />;
  };

  return (
    <PageContainer>
      <ContentHeader
        topTitle={t("7830") /* Vamos estacionar? */}
        bottomTitle={t("7831") /* É fácil! */}
      />
      <ContentBorderBox
        title={generateTitle({ isRenewed, isMinutesFee })}
        cornerColor={backofficeTheme.color.primary}
        className="mt-40"
        backCallback={allowBack ? onBack : null}
      >
        <ParkedCarBox state={animationState} vehicleColor={vehicleColor}>
          <ParkingContainer smallVersion={!isMinutesFee && !isRenewed}>
            {isLoading && (
              <LoaderWrapper>
                <Loader width={80} height={80} isLoading={isLoading} />
              </LoaderWrapper>
            )}
            <>{renderParkingDetails()}</>
          </ParkingContainer>
        </ParkedCarBox>
      </ContentBorderBox>
    </PageContainer>
  );
};

ParkingRenew.propTypes = parkingRenewPropTypes;

ParkingRenew.defaultProps = parkingRenewDefaultPropTypes;

export default ParkingRenew;
