import { useAppContext } from "components/AppProvider";
import React from "react";
import { breakpointsMapping, smallDevicesBreakpoints } from "utils/breakpoints";
import PermitHolderDetailsLargePage from "./large/Page";
import PermitHoldersDetailsMediumDevicesPage from "./medium/Page";
import PermitHolderDetailsSmallDevicesPage from "./small/Page";

const PermitHoldersPage = () => {
  const { breakpoint } = useAppContext();

  if (smallDevicesBreakpoints.includes(breakpoint)) {
    return <PermitHolderDetailsSmallDevicesPage />;
  }

  if (breakpointsMapping.noBreakpoint === breakpoint) {
    return <PermitHoldersDetailsMediumDevicesPage />;
  }

  return <PermitHolderDetailsLargePage />;
};

export default PermitHoldersPage;
