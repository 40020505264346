const earthRadius = 6371000; // in meters
const radian = Math.PI / 180;

/**
 * https://en.wikipedia.org/wiki/Haversine_formula
 */
export const straightLineDistance = (firstPosition, secondPosition) => {
  const φ1 = firstPosition.latitude * radian;
  const φ2 = secondPosition.latitude * radian;
  const Δφ = (secondPosition.latitude - firstPosition.latitude) * radian;
  const Δλ = (secondPosition.longitude - firstPosition.longitude) * radian;
  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earthRadius * c;
};

export function isPointInArea({ latitude, longitude }, area) {
  let inside = false;

  // eslint-disable-next-line no-plusplus
  for (let i = 0, j = area.length - 1; i < area.length; j = i++) {
    const xi = area[i].latitude;
    const yi = area[i].longitude;
    const xj = area[j].latitude;
    const yj = area[j].longitude;

    const intersect =
      // eslint-disable-next-line eqeqeq
      yi > longitude != yj > longitude &&
      latitude < ((xj - xi) * (longitude - yi)) / (yj - yi) + xi;

    if (intersect) inside = !inside;
  }

  return inside;
}
