/* eslint-disable react/jsx-props-no-spreading */
import SmallDevicesContentHeader from "domain/private/components/smallDevices/ContentHeader";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { paymentMethodsMapping, paymentTypesMapping } from "utils/payment";
import { feeTypesMapping } from "../../../../utils/fees";
import GeneralInfo from "../../components/parkings/smallDevices/ParkingDetails";
import MbWayPaymentBox from "../../parking/paymentMethods/MbWayPaymentBox";
import { parkingRenewalSteps } from "../controller";
import { generateTitle } from "../helper";
import { parkingRenewDefaultPropTypes, parkingRenewPropTypes } from "../propTypes";
import ParkingChooseDuration from "./ChooseDuration";
import ParkingDetails from "./Details";
import LongFeeDuration from "./LongFeeDuration";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ParkingRenew = ({
  vehicleLicensePlate,
  vehicleColor,
  vehicleBrand,
  animationState,
  streetName,
  explorationName,
  isRenewed,
  chooseDurationProps,
  detailsProps,
  fee,
  step,
  paymentMethod,
  onRenewParkingWithSpecialPaymentMethod,
  onBack,
  allowBack,
  setAllowBack,
}) => {
  const { t } = useTranslation();
  const { parking, checkParkingCallback, calculatedParking } = chooseDurationProps;

  const isMinutesFee = fee.id === feeTypesMapping.minutes;

  const renderParkingDetails = () => {
    if (isRenewed) {
      return <ParkingDetails {...detailsProps} fee={fee} />;
    }

    if (step === parkingRenewalSteps.specialPaymentMethod) {
      if (parking.parkingPaymentTypeId === paymentMethodsMapping.mbway.id) {
        return (
          <MbWayPaymentBox
            defaultIsOpen
            {...paymentMethod}
            amount={calculatedParking.amount}
            onConfirmPayment={onRenewParkingWithSpecialPaymentMethod}
            onSuccess={checkParkingCallback}
            onCancel={onBack}
            setAllowBackStepCallback={setAllowBack}
            itemTypeId={paymentTypesMapping.parkingPayment}
            disabled={false}
            centered
          />
        );
      }

      return null;
    }

    if (isMinutesFee) {
      return <ParkingChooseDuration {...chooseDurationProps} fee={fee} />;
    }

    return <LongFeeDuration {...chooseDurationProps} fee={fee} />;
  };

  return (
    <>
      <SmallDevicesContentHeader
        topTitle={t("7830") /* Vamos estacionar? */}
        bottomTitle={t("7831") /* É fácil! */}
        backCallback={allowBack ? onBack : null}
      />
      <Text className="uppercase mt-20" color="tertiary" variant="body10">
        {generateTitle({ isRenewed, isMinutesFee })}
      </Text>
      <Container>
        <GeneralInfo
          vehicleColor={vehicleColor}
          vehicleBrand={vehicleBrand}
          vehicleLicensePlate={vehicleLicensePlate}
          streetName={streetName}
          explorationName={explorationName}
          animationState={animationState}
          displayImage
        />
        {renderParkingDetails()}
      </Container>
    </>
  );
};

ParkingRenew.propTypes = {
  ...parkingRenewPropTypes,
  vehicleBrand: PropTypes.string,
  vehicleLicensePlate: PropTypes.string,
  explorationName: PropTypes.string,
  streetName: PropTypes.string,
};

ParkingRenew.defaultProps = {
  ...parkingRenewDefaultPropTypes,
  vehicleBrand: "",
  vehicleLicensePlate: "",
  explorationName: "",
  streetName: "",
};

export default ParkingRenew;
