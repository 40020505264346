import RequirementCard from "domain/private/components/requirements/Card";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { openEmailForVehicleValidation } from "../controller";

const VehicleValidationRequirement = ({ number }) => {
  const { t } = useTranslation();

  return (
    <RequirementCard
      index={number}
      label={
        t(
          "10639"
        ) /* Contacta os nossos serviços de apoio de forma a validarmos a(s) tuas(s) matrícula(s). */
      }
      button={{
        label: t("2783") /* Validar */,
        action: openEmailForVehicleValidation,
      }}
    />
  );
};

export default VehicleValidationRequirement;

VehicleValidationRequirement.propTypes = {
  number: PropTypes.number,
};

VehicleValidationRequirement.defaultProps = {
  number: 1,
};
