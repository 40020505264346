import { IPARQUE_SUPPORT_EMAIL } from "utils/configs";
import i18n from "../../../i18n";
import DriversDataSource from "../../../lib/clients/iParque/dataSources/driversDataSource";
import { colorsMapping } from "../../../utils/colors";
import {
  DRIVER_VEHICLES_NOT_VALIDATED,
  hasCanceledError,
  hasErrorCode,
  INVALID_DRIVER_VAT_NUMBER,
  REQUIRED_DRIVER_VAT_NUMBER,
  REQUIRED_DRIVER_VEHICLES,
} from "../../../utils/error";
import { errorMessage } from "../../../utils/userMessages";
import { changeIndexToLicensePlate } from "../../../utils/vehicles";

const driversDataSource = new DriversDataSource();

export const IPARQUE_SUPPORT_EMAIL_HREF = `mailto:${IPARQUE_SUPPORT_EMAIL}`;
export const NOTICE_PLACEHOLDER_HEIGHT = 65;
export const ALL_NOTICES = "all";

export const openEmailForVehicleValidation = () => {
  window.location = IPARQUE_SUPPORT_EMAIL_HREF;
};

export const processNotices = (notices, vehicles) => {
  const processedVehicles = changeIndexToLicensePlate(vehicles);

  return notices.map((notice) => {
    return {
      id: notice.id,
      number: notice.number,
      licensePlate: notice.licensePlate,
      vehicleColorId:
        processedVehicles[notice.licensePlate]?.colorId || colorsMapping.gray,
      dateTime: notice.date,
      paymentDateTime: notice.payableUntil,
      amount: notice.amount,
      stateName: notice.state,
      stateId: notice.stateId,
      zoneName: notice.zone,
      streetName: notice.street,
      situation: notice.infraction,
      infraction: notice.infringedStandards,
      payable: notice.payable,
    };
  });
};

export const getNotices = async (entityId, authHash, params, cancelToken = null) => {
  try {
    const response = await driversDataSource.getNotices(
      entityId,
      authHash,
      params,
      false,
      cancelToken
    );

    return {
      items: response.items,
      totalPages: response.totalPages,
    };
  } catch (error) {
    if (hasCanceledError(error)) {
      return null;
    }

    const errors = [];

    if (hasErrorCode(error, INVALID_DRIVER_VAT_NUMBER)) {
      errors.push(INVALID_DRIVER_VAT_NUMBER);
    }

    if (hasErrorCode(error, REQUIRED_DRIVER_VAT_NUMBER)) {
      errors.push(REQUIRED_DRIVER_VAT_NUMBER);
    }

    if (hasErrorCode(error, DRIVER_VEHICLES_NOT_VALIDATED)) {
      errors.push(DRIVER_VEHICLES_NOT_VALIDATED);
    }

    if (hasErrorCode(error, REQUIRED_DRIVER_VEHICLES)) {
      errors.push(REQUIRED_DRIVER_VEHICLES);
    }

    if (errors.length) {
      return {
        hasError: true,
        errors,
      };
    }

    errorMessage(error, i18n.t("8418") /* Ocorreu um erro ao obter as infrações */);

    return { hasError: true };
  }
};

export const getVehicles = async (authHash, cancelToken) => {
  try {
    const response = await driversDataSource.getVehicles(
      authHash,
      { noLimit: true },
      {},
      false,
      cancelToken
    );

    return {
      items: response.items,
      totalPages: response.totalPages,
    };
  } catch (error) {
    return {
      items: [],
    };
  }
};
