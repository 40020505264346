import PermitHolderCreationFlow from "components/permitHolders/Creation";
import { toast } from "iparque-components";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getPermitHolderEvidence } from "requests/permitHolders";
import { entityConfigurationsMapping } from "utils/entities";
import Error400 from "../../components/errors/400";
import Error403 from "../../components/errors/403";
import { usePublicEntityContext } from "../../Provider";
import { publicEntitiesRoutes } from "../../routes";
import Template from "./components/Template";

const PermitHoldersCreation = () => {
  const history = useHistory();
  const {
    entityConfigs,
    getEntityConfig,
    entity,
    isEntityDataLoading,
  } = usePublicEntityContext();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const onBeforeUnload = (event) => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => window.removeEventListener("beforeunload", onBeforeUnload);
  }, []);

  const onSubmissionSuccess = useCallback(
    async ({ permitHolderId, allowRegistry, ...personalData }) => {
      const evidenceResponse = await getPermitHolderEvidence(entity.id, permitHolderId);

      toast.dismiss();
      history.push(publicEntitiesRoutes.permitHoldersCreationConfirmation, {
        evidenceUrl: evidenceResponse?.url,
        allowRegistry,
        ...personalData,
      });
    },
    [entity, history]
  );

  const onError = useCallback(() => setHasError(true), []);

  if (
    entityConfigs &&
    !getEntityConfig(entityConfigurationsMapping.canCreatePublicPermits)
  ) {
    return <Error403 />;
  }

  if (hasError) {
    return <Error400 />;
  }

  return (
    <PermitHolderCreationFlow
      entity={entity}
      isEntityDataLoading={isEntityDataLoading}
      onSubmissionSuccess={onSubmissionSuccess}
      onInitialRequestError={onError}
      TemplateComponent={Template}
    />
  );
};

export default PermitHoldersCreation;
