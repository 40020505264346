import FlexBox from "components/FlexBox";
import { Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { usePermitHoldersDetailsContext } from "../../Provider";

const Title = () => {
  const { t } = useTranslation();
  const { reference, state } = usePermitHoldersDetailsContext();

  return (
    <FlexBox alignItems="center">
      <Text variant="body7">{`${t("1848") /* Dístico */} ${reference}`}</Text>
      &nbsp;&nbsp;&nbsp;
      <Text variant="body6">-</Text>
      &nbsp;&nbsp;&nbsp;
      <StateLabel stateId={state.id}>{state.designation}</StateLabel>
    </FlexBox>
  );
};

export default Title;

const StateLabel = styled(Text).attrs({ variant: "body2" })`
  color: ${({ theme, stateId }) => theme.permitHolders.states[stateId]};
  text-transform: none;
`;
