import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Yup from "yup.js";
import useCountries from "hooks/useCountries";
import useEntityVehiclesForm from "hooks/useEntityVehiclesForm";
import { usePermitHoldersContext } from "../../Provider";

const useForm = () => {
  const { t } = useTranslation();
  const { entity, vehicles } = usePermitHoldersContext();
  const { countryOptions: countries, countryCodesMapping } = useCountries({
    entityId: entity.id,
  });
  const { brands, models, onBrandChange } = useEntityVehiclesForm(entity.id);

  const getLicencePlates = useCallback(
    (position) => {
      const vehiclesCopy = [...vehicles];

      vehiclesCopy.splice(position, 1); // Avoid place in the list the licence plate of vehicle in validation

      return vehiclesCopy.map((vehicle) => vehicle.licencePlate);
    },
    [vehicles]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object({
        countryId: Yup.number().required(t("10271") /* O campo é obrigatório */),
        licencePlate: Yup.string()
          .required(t("10271") /* O campo é obrigatório */)
          .licencePlate(
            t("9977") /* O campo é inválido para o país selecionado */,
            countryCodesMapping
          )
          .test(
            "notInList",
            t("10263") /* A matrícula já se encontra atribuída */,
            // eslint-disable-next-line func-names
            function (value) {
              return !getLicencePlates(this.parent.position).includes(value);
            }
          ),
        brandId: Yup.string().required(t("10271") /* O campo é obrigatório */),
        modelId: Yup.string().required(t("10271") /* O campo é obrigatório */),
      }),
    [getLicencePlates]
  );

  return { validationSchema, countries, brands, models, onBrandChange };
};

export default useForm;
