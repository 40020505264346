import driverLogo from "assets/images/driverLogo.svg";
import Skeleton from "components/Skeleton";
import { usePublicEntityContext } from "domain/public/entities/Provider";
import { Heading } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const LOGO_HEIGHT = 80;

const TemplateHeader = ({ isLoading, title, smallVersion, className }) => {
  const { t } = useTranslation();
  const { entity } = usePublicEntityContext();

  return (
    <div className={className}>
      {isLoading ? (
        <Skeleton height={LOGO_HEIGHT} width={LOGO_HEIGHT} circle />
      ) : (
        <Image src={entity ? entity.logo : driverLogo} alt={t("10155") /* Logo */} />
      )}
      <Title className="mt-10" color="secondary" variant="h1" smallVersion={smallVersion}>
        {title}
      </Title>
    </div>
  );
};

export default TemplateHeader;

TemplateHeader.propTypes = {
  isLoading: PropTypes.bool,
  smallVersion: PropTypes.bool,
  title: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TemplateHeader.defaultProps = {
  isLoading: false,
  smallVersion: false,
  className: undefined,
};

const Image = styled.img`
  height: ${LOGO_HEIGHT}px;
  max-width: 250px;
  object-fit: contain;
`;

const Title = styled(Heading)`
  font-size: ${({ smallVersion }) => (smallVersion ? "20px" : "30px")};
`;
