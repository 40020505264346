import React from "react";
import PropTypes from "prop-types";
import { Text } from "iparque-components";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  background: ${({ theme }) => theme.box.color};
  height: 80px;
  padding-left: 10px;
  display: flex;
  align-items: center;
`;

const ErrorCard = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <Text variant="body11">{t("3402") /* Ocorreu um erro */}</Text>
    </Container>
  );
};

export default ErrorCard;

ErrorCard.propTypes = {
  className: PropTypes.string,
};

ErrorCard.defaultProps = {
  className: undefined,
};
