import { ReactComponent as Back } from "assets/icons/backLarge.svg";
import { Heading } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import TemplateHeader from "./Header";

const SmallDevicesTemplate = ({ children, title, subTitle, onBack, isLoading }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const canGoBack = !!onBack;

  return (
    <>
      <BackWrapper $canGoBack={canGoBack}>
        <Back
          height={35}
          cursor="pointer"
          fill={theme.color.tertiary}
          onClick={onBack}
          title={t("9") /* Voltar */}
        />
      </BackWrapper>
      <TemplateHeader
        title={title}
        isLoading={isLoading}
        className="mt-30 mb-40"
        smallVersion
      />
      {subTitle && (
        <Title $canGoBack={canGoBack} color="quintenary" variant="h5">
          {subTitle}
        </Title>
      )}
      {children}
    </>
  );
};

export default SmallDevicesTemplate;

SmallDevicesTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
  isLoading: PropTypes.bool,
  onBack: PropTypes.func,
};

SmallDevicesTemplate.defaultProps = {
  isLoading: false,
  subTitle: undefined,
  onBack: undefined,
};

const BackWrapper = styled.div`
  opacity: ${({ $canGoBack }) => ($canGoBack ? 1 : 0)};
  transition: opacity 0.1s ease;
  display: flex;
  align-items: center;
`;

const Title = styled(Heading)`
  font-size: 14px;
`;
