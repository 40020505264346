import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

const EntitiesContext = createContext();

export const useCitiesProvider = () => {
  return useContext(EntitiesContext);
};

const CitiesProvider = ({ setDefaultEntity, children }) => {
  return (
    <EntitiesContext.Provider
      value={{
        setDefaultEntity,
      }}
    >
      {children}
    </EntitiesContext.Provider>
  );
};

CitiesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  setDefaultEntity: PropTypes.func.isRequired,
};

export default CitiesProvider;
