/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { formatDateTime } from "utils/dateTime";
import { Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import { getPendingBalanceChargeInfo } from "./controller";
import { useAuth } from "../../../components/AuthProvider";
import { paymentMethodsMapping } from "../../../utils/payment";
import InlineDetailsItem, { Line } from "./components/inlineDetails/Item";
import InlineDetailsListSkeleton from "./components/inlineDetails/ListSkeleton";
import { formatAtm, formatIban } from "../../../utils/formatters";
import ClipboardIcon from "../../../components/ClipboardIcon";
import { formatMoney } from "../../../utils/money";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
`;

const StyledClipboardIcon = styled(ClipboardIcon)`
  min-width: ${({ small }) => (small ? "15px" : "18px")};
  height: ${({ small }) => (small ? "15px" : "18px")};
  margin-left: 5px;
`;

const ClipboardContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BalanceChargeDetails = ({ id, className, small }) => {
  const { defaultEntityId, driverHash } = useAuth();
  const { t } = useTranslation();
  const [balanceChargeInfo, setBalanceChargeInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getBalanceChargeInfo = async () => {
      const newBalanceChargeInfo = await getPendingBalanceChargeInfo(
        defaultEntityId,
        driverHash,
        id
      );

      setIsLoading(false);
      setBalanceChargeInfo(newBalanceChargeInfo);
    };

    if (id) {
      getBalanceChargeInfo();
    }
  }, [id]);

  const paymentDetails = useMemo(() => {
    if (isLoading) {
      return <InlineDetailsListSkeleton />;
    }

    if (!balanceChargeInfo) {
      return null;
    }

    const textVariant = small ? "body10" : "body3";
    const amount = formatMoney(balanceChargeInfo.amount, { centsFormat: false });
    const deadline = balanceChargeInfo.deadline
      ? formatDateTime(balanceChargeInfo.deadline)
      : undefined;

    if (balanceChargeInfo.paymentMethodId === paymentMethodsMapping.atm.id) {
      return (
        <>
          <InlineDetailsItem
            small={small}
            label={`${t("4021") /* Entidade */}:`}
            value={
              <ClipboardContainer>
                <Text variant={textVariant}>
                  {formatAtm(balanceChargeInfo.atm?.entity)}
                </Text>
                {balanceChargeInfo.atm?.entity && (
                  <StyledClipboardIcon
                    text={balanceChargeInfo.atm?.entity}
                    small={small}
                  />
                )}
              </ClipboardContainer>
            }
          />
          <InlineDetailsItem
            small={small}
            label={`${t("2021") /* Referência */}:`}
            value={
              <ClipboardContainer>
                <Text variant={textVariant}>
                  {formatAtm(balanceChargeInfo.atm?.reference)}
                </Text>
                {balanceChargeInfo.atm?.reference && (
                  <StyledClipboardIcon
                    text={balanceChargeInfo.atm?.reference}
                    small={small}
                  />
                )}
              </ClipboardContainer>
            }
          />
          <InlineDetailsItem
            small={small}
            label={`${t("4912") /* Montante */}:`}
            value={
              <ClipboardContainer>
                <Text variant={textVariant}>{amount}</Text>
                <StyledClipboardIcon text={amount} small={small} />
              </ClipboardContainer>
            }
          />
          {deadline && (
            <InlineDetailsItem
              className="mt-10"
              small={small}
              label={`${t("9677") /* Data limite */}:`}
              value={deadline}
            />
          )}
        </>
      );
    }

    if (balanceChargeInfo.paymentMethodId === paymentMethodsMapping.bankTransference.id) {
      return (
        <>
          {small ? (
            <>
              <Line>
                <Text variant="body12">{`${t("2338") /* IBAN */}:`}</Text>
              </Line>
              <Line className="mb-10">
                <Text variant="body11">
                  {formatIban(balanceChargeInfo.bankTransference.iban)}
                </Text>
                <StyledClipboardIcon
                  text={balanceChargeInfo.bankTransference.iban}
                  small={small}
                />
              </Line>
              <Line>
                <Text variant="body12">{`${t("4912") /* Montante */}:`}</Text>
              </Line>
              <Line className="mb-10">
                <Text variant="body11">{amount}</Text>
                <StyledClipboardIcon text={amount} small={small} />
              </Line>
              {deadline && (
                <>
                  <Line>
                    <Text variant="body12">{`${t("9677") /* Data limite */}:`}</Text>
                  </Line>
                  <Line className="mb-10">
                    <Text variant="body11">{deadline}</Text>
                  </Line>
                </>
              )}
            </>
          ) : (
            <>
              <InlineDetailsItem
                label={`${t("2338") /* IBAN */}:`}
                value={
                  <ClipboardContainer>
                    <Text variant="body3">
                      {formatIban(balanceChargeInfo.bankTransference.iban)}
                    </Text>
                    <StyledClipboardIcon
                      text={balanceChargeInfo.bankTransference.iban}
                      small={small}
                    />
                  </ClipboardContainer>
                }
              />
              <InlineDetailsItem
                small={small}
                label={`${t("4912") /* Montante */}:`}
                value={
                  <ClipboardContainer>
                    <Text variant="body3">{amount}</Text>
                    <StyledClipboardIcon text={amount} small={small} />
                  </ClipboardContainer>
                }
              />
              {deadline && (
                <InlineDetailsItem
                  className="mt-10"
                  small={small}
                  label={`${t("9677") /* Data limite */}:`}
                  value={deadline}
                />
              )}
            </>
          )}
        </>
      );
    }

    return null;
  }, [balanceChargeInfo, isLoading, small]);

  return (
    <Container className={className}>
      {paymentDetails}
      {balanceChargeInfo?.paymentMethod?.longMessage && (
        <div>
          <Text className="mt-10" variant="body5" color="danger">
            {t("553") /* Atenção */}
          </Text>
          <Text variant="body11">
            {ReactHtmlParser(balanceChargeInfo.paymentMethod.longMessage)}
          </Text>
        </div>
      )}
    </Container>
  );
};

BalanceChargeDetails.propTypes = {
  id: PropTypes.number.isRequired,
  className: PropTypes.string,
  small: PropTypes.bool,
};

BalanceChargeDetails.defaultProps = {
  className: null,
  small: false,
};

export default BalanceChargeDetails;
