import { usePublicEntityContext } from "domain/public/entities/Provider";
import { useTranslation } from "react-i18next";
import useCountries from "hooks/useCountries";
import Yup from "yup.js";
import { useStepsContext } from "domain/public/components/StepsProvider";
import { useCallback, useEffect, useState } from "react";
import { identificationTypes } from "utils/driversIdentifications";
import { PORTUGAL } from "utils/countries";
import { formatDate } from "utils/dateTime";
import {
  getAllDrivingLicenceCategories,
  getAllPrefixes,
} from "requests/identificationDocuments";
import { identificationDocumentType } from "utils/identificationDocuments";
import { allowedFileFormats } from "./configs";
import { useDriverIdentificationContext } from "../../Provider";
import { actionTypes } from "../../store/actions";
import { STEPS } from "../helper";

const useForm = () => {
  const { t } = useTranslation();
  const { entity } = usePublicEntityContext();
  const { countryOptions } = useCountries({ entityId: entity?.id });
  const { identificationData, documents, dispatch } = useDriverIdentificationContext();
  const { goToStep } = useStepsContext();
  const [drivingLicenceCategories, setDrivingLicenceCategories] = useState([]);
  const [drivingLicencePrefixes, setDrivingLicencePrefixes] = useState([]);

  const initialData = {
    citizenCardCountryId: documents.citizenCard.countryId || entity?.countryId,
    citizenCardNumber: documents.citizenCard.number,
    citizenCardIssuingEntity: documents.citizenCard.issuingEntity,
    citizenCardIssuingDate: documents.citizenCard.issuingDate,
    drivingLicenceCountryId: documents.drivingLicence.countryId || entity?.countryId,
    drivingLicencePrefix: documents.drivingLicence.prefix,
    drivingLicenceNumber: documents.drivingLicence.number,
    drivingLicenceIssuingEntity: documents.drivingLicence.issuingEntity,
    drivingLicenceIssuingDate: documents.drivingLicence.issuingDate,
    drivingLicenceCategories: documents.drivingLicence.categories,
    drivingLicenceRestrictions: documents.drivingLicence.restrictions,
    contract: documents.contract,
  };

  useEffect(() => {
    const fetchAllDrivingLicenceCategories = async () => {
      const drivingLicenceCategoriesResponse = await getAllDrivingLicenceCategories(
        entity.id,
        { noLimit: true },
        true
      );

      setDrivingLicenceCategories(
        drivingLicenceCategoriesResponse.map((drivingLicenceCategory) => ({
          label: drivingLicenceCategory.designation,
          value: `${drivingLicenceCategory.id}`,
        }))
      );
    };

    const fetchAllDrivingLicencePrefixes = async () => {
      const drivingLicencePrefixesResponse = await getAllPrefixes(
        entity.id,
        identificationDocumentType.drivingLicence,
        { noLimit: true },
        true
      );

      setDrivingLicencePrefixes(
        drivingLicencePrefixesResponse.map((drivingLicencePrefix) => ({
          label: drivingLicencePrefix.code,
          value: drivingLicencePrefix.id,
        }))
      );
    };

    if (entity) {
      fetchAllDrivingLicenceCategories();
      fetchAllDrivingLicencePrefixes();
    }
  }, [entity]);

  const requiredPersonalDocumentsData = useCallback(
    (value) => {
      if (
        identificationData?.typeId === identificationTypes.voluntary ||
        (identificationData?.typeId === identificationTypes.rentalVehicleDriver &&
          entity?.countryId === PORTUGAL)
      ) {
        return !(value === undefined || value === "");
      }

      return true;
    },
    [entity, identificationData]
  );

  const requiredIfRentalVehicleDriverIdentification = useCallback(
    (value) => {
      if (identificationData.typeId === identificationTypes.rentalVehicleDriver) {
        return !(value === undefined || value === null);
      }

      return true;
    },
    [identificationData]
  );

  const validateFileFormat = useCallback(
    (value) => {
      if (identificationData.typeId === identificationTypes.rentalVehicleDriver) {
        return allowedFileFormats.includes(value?.type.split("/")[1]);
      }

      return true;
    },
    [identificationData]
  );

  const validationSchema = Yup.object().shape({
    citizenCardNumber: Yup.string()
      .trim()
      .test(
        "required",
        t("10271") /* O campo é obrigatório */,
        requiredPersonalDocumentsData
      ),
    citizenCardCountryId: Yup.number().test(
      "required",
      t("10271") /* O campo é obrigatório */,
      requiredPersonalDocumentsData
    ),
    citizenCardIssuingDate: Yup.string()
      .validDateFormat(
        `${t("9710") /* O campo tem de estar no formato */} ${formatDate(new Date())}`
      )
      .maxDate(
        t("9711") /* Deverá ser inferior ou igual à data atual */,
        formatDate(new Date())
      ),
    drivingLicencePrefix: Yup.string().test(
      "required",
      t("10271") /* O campo é obrigatório */,
      requiredPersonalDocumentsData
    ),
    drivingLicenceNumber: Yup.string()
      .trim()
      .test(
        "required",
        t("10271") /* O campo é obrigatório */,
        requiredPersonalDocumentsData
      ),
    drivingLicenceIssuingDate: Yup.string()
      .validDateFormat(
        `${t("9710") /* O campo tem de estar no formato */} ${formatDate(new Date())}`
      )
      .maxDate(
        t("9711") /* Deverá ser inferior ou igual à data atual */,
        formatDate(new Date())
      ),
    contract: Yup.mixed()
      .test(
        "required",
        t("10271") /* O campo é obrigatório */,
        requiredIfRentalVehicleDriverIdentification
      )
      .test(
        "fileType",
        t("10724") /* Formato de ficheiro não suportado */,
        validateFileFormat
      ),
  });

  const submit = (values) => {
    const formValues = {
      citizenCard: {
        countryId: values?.citizenCardCountryId,
        number: values?.citizenCardNumber,
        issuingEntity: values?.citizenCardIssuingEntity,
        issuingDate: values?.citizenCardIssuingDate,
      },
      drivingLicence: {
        countryId: values?.drivingLicenceCountryId,
        prefix: values?.drivingLicencePrefix,
        number: values?.drivingLicenceNumber,
        issuingEntity: values?.drivingLicenceIssuingEntity,
        issuingDate: values?.drivingLicenceIssuingDate,
        restrictions: values?.drivingLicenceRestrictions,
        categories: values?.drivingLicenceCategories,
      },
      contract: values?.contract,
    };

    dispatch({
      type: actionTypes.SET_DOCUMENTS,
      payload: formValues,
    });

    goToStep(STEPS.confirmation);
  };

  return {
    initialData,
    validationSchema,
    submit,
    countries: countryOptions,
    drivingLicencePrefixes,
    drivingLicenceCategories,
  };
};

export default useForm;
