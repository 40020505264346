import FlexBox from "components/FlexBox";
import React from "react";
import TabIdentification from "../../../components/TabIdentification";
import TabTitle from "../../../components/Title";
import SaveButton from "../../../components/vehicles/SaveButton";
import StatusComponent from "../../../components/vehicles/StatusComponent";
import VehiclesPicker from "../../../components/vehicles/VehiclesPicker";
import { tabsIdsMap } from "../../../helper";
import useVehiclesTab from "../../../hooks/useVehiclesTab";
import { usePermitHoldersDetailsContext } from "../../../Provider";
import TabDetailsOperations from "../../operations/TabDetails";

const VehiclesTab = () => {
  useVehiclesTab();
  const {
    vehiclesTab: { addedVehicles, isLoading },
  } = usePermitHoldersDetailsContext();

  return (
    <>
      <TabDetailsOperations className="mb-10" />
      <TabIdentification tabId={tabsIdsMap.vehicles} />
      <TabTitle className="mt-20" tabId={tabsIdsMap.vehicles} />
      <StatusComponent />
      {(addedVehicles.length > 0 || isLoading) && (
        <FlexBox flexDirection="column" className="fill" justifyContent="spaceBetween">
          <VehiclesPicker />
          <SaveButton justifyContent="center" />
        </FlexBox>
      )}
    </>
  );
};

export default VehiclesTab;
