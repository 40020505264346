import styled, { css } from "styled-components";
import { breakpoints } from "utils/breakpoints";

const FormGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 20px;
`;

export default FormGridContainer;

export const FieldContainer = styled.div`
  grid-column-end: span ${({ fullWidth }) => (fullWidth ? 12 : 6)};

  ${({ fullWidthOnMediumDevices }) =>
    fullWidthOnMediumDevices &&
    css`
      @media (max-width: ${breakpoints.md}) {
        grid-column-end: span 12;
      }
    `}

  ${({ fullWidthOnSmallDevices }) =>
    fullWidthOnSmallDevices &&
    css`
      @media (max-width: ${breakpoints.sm}) {
        grid-column-end: span 12;
      }
    `}

  @media (max-width: 370px) {
    grid-column-end: span 12;
  }
`;

export const TemplateFieldContainer = styled.div`
  grid-column-end: span 12;

  @media (min-width: ${breakpoints.xl}), (max-width: ${breakpoints.lg}) {
    grid-column-end: span 6;
  }

  @media (max-width: ${breakpoints.md}) {
    grid-column-end: span 12;
  }
`;
