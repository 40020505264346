import Button from "components/buttons/Button";
import CardCell from "domain/private/components/cardList/Cell";
import { Heading, Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { paymentMethodsMapping } from "utils/payment";
import { breakpoints } from "../../../../utils/breakpoints";
import { convertDateTimeToReadableDate } from "../../../../utils/dateTime";
import { feeTypesMapping } from "../../../../utils/fees";
import { buildQueryString } from "../../../../utils/general";
import { parkingTypesMapping } from "../../../../utils/parking";
import { routesMapping } from "../../../../utils/routes";
import ListCard from "../../components/cardList/Card";
import ParkedCar from "../../components/ParkedCar";
import ParkingEndDate from "../../components/parkings/EndDate";
import ParkingTimer from "../../components/parkings/Timer";
import TimerAdditionalInfo from "../../components/parkings/TimerAdditionalInfo";
import { useBackofficeThemeContext } from "../../components/ThemeProvider";
import useTimerInfo from "../../hooks/useTimerInfo";
import { activeParkingProps } from "../propTypes";
import CommonExtraInformation from "../components/CommonExtraInfo";
import TimerLabelContainer from "../components/TimerLabelContainer";

const ActiveParkingBox = ({
  id,
  licensePlate,
  vehicleColorId,
  paymentMethodId,
  parkingTypeId,
  parkingTypeName,
  streetName,
  streetId,
  explorationName,
  amount,
  vehicleBrand,
  startingDate,
  endingDate,
  scheduleExtraInfo,
  feeTypeId,
  updateCallback,
  benefit,
  location,
}) => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();
  const history = useHistory();
  const timerInfo = useTimerInfo(id, { endCallback: () => updateCallback(false) });

  const vehicleColor = backofficeTheme.carColor[vehicleColorId];
  const isMinutesFee = feeTypeId === feeTypesMapping.minutes;
  const paramsQuery = buildQueryString({
    amount,
    vehicleColor,
    streetName,
    streetId,
    paymentMethodId,
    explorationName,
    vehicleLicensePlate: licensePlate,
    vehicleBrand,
    feeTypeId,
  });

  const isMBWayPayment = paymentMethodId === paymentMethodsMapping.mbway.id;

  const renderExtraInfoCallback = ({ isOpen }) => (
    <DetailsContainer>
      <div className="mt-40">
        <Text variant="body4" color="primary">
          {t("615") /* Montante a pagar */}
        </Text>
        <Heading color="tertiary" variant="h2">
          {amount}
        </Heading>
      </div>
      <ParkingEndDate
        parkingTypeId={parkingTypeId}
        className="mt-20"
        endingDate={endingDate}
      />
      <CommonExtraInformation
        id={id}
        benefit={benefit}
        location={location}
        isOpen={isOpen}
      />
      <Action>
        {parkingTypeId !== parkingTypesMapping.startAndStop && (
          <Button
            size="xs"
            className="parking-action-buttons"
            color="info"
            onClick={() => {
              history.push(
                `${routesMapping.backofficeParking}/${id}/renew?${paramsQuery}`
              );
            }}
          >
            {t("46") /* Renovar */}
          </Button>
        )}
        {isMinutesFee && !isMBWayPayment && (
          <Button
            size="xs"
            className="parking-action-buttons mt-10"
            color="danger"
            onClick={() => {
              history.push(
                `${routesMapping.backofficeParking}/${id}/stop?${paramsQuery}`
              );
            }}
          >
            {t("3216") /* Parar */}
          </Button>
        )}
      </Action>
    </DetailsContainer>
  );

  return (
    <StyledListCard renderExtraInfoCallback={renderExtraInfoCallback}>
      <Icon>
        <ParkedCar color={vehicleColor} />
      </Icon>
      <CardCell size="md" className="mr-10">
        <div className="flex-baseline-container">
          <StyledHeading colorId={vehicleColorId} variant="h4">
            {licensePlate}
          </StyledHeading>
          <Text variant="body9" className="ml-15">
            {convertDateTimeToReadableDate(startingDate)}
          </Text>
        </div>
        <Text variant="body1" color="primary">
          {streetName}
        </Text>
        <Text variant="body4" color="primary">
          {explorationName}
        </Text>
      </CardCell>
      <CardCell size="md" align="center">
        <TimerLabelContainer>
          <Text color="primary" className="inline" variant="body10">
            {t("54") /* Estacionamento */}
          </Text>
          &nbsp;
          <Text color="primary" className="inline" variant="body9">
            {parkingTypeName}
          </Text>
          <TimerAdditionalInfo
            state={timerInfo?.state}
            nextStart={timerInfo?.nextStart}
            variant="body10"
          />
        </TimerLabelContainer>
        <ParkingTimer
          parkingId={id}
          parkingTypeId={parkingTypeId}
          startingDate={startingDate}
          endingDate={endingDate}
          scheduleExtraInfo={scheduleExtraInfo}
          size="md"
        />
      </CardCell>
    </StyledListCard>
  );
};

export default React.memo(ActiveParkingBox);

ActiveParkingBox.propTypes = activeParkingProps;

const StyledListCard = styled(ListCard)`
  margin-left: 40px;

  :first-child {
    margin-top: 25px;
  }
`;

const Icon = styled.div`
  margin-top: -35px;
  margin-left: -55px;
  margin-right: 30px;

  @media (max-width: ${breakpoints.md}) {
    margin-top: -40px;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Action = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .parking-action-buttons {
    width: 300px;
  }
`;

const StyledHeading = styled(Heading)`
  color: ${({ colorId, theme }) => theme.carColor[colorId]};
`;
