import AbstractDataSource from "./abstractDataSource";
import { getTokens } from "../../../../utils/auth";

export default class VehiclesDataSource extends AbstractDataSource {
  async getBrands(params = {}) {
    return this.iParqueClient.GET(`v1/vehicles/brands`, params, {
      Authorization: getTokens().token,
    });
  }

  async getBrandModels(brandId, params = {}) {
    return this.iParqueClient.GET(`v1/vehicles/brands/${brandId}/models`, params, {
      Authorization: getTokens().token,
    });
  }

  async getBenefits(entityId, licensePlate, params) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/vehicles/${licensePlate}/benefits`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }

  async getAll(entityId, authHash, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/${authHash}/vehicles`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }
}
