import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styled from "styled-components";
import ErrorCard from "../../domain/private/components/ErrorCard";
import ListSkeleton from "../../domain/private/components/ListSkeleton";

const CARD_HEIGHT = 60;

const InfiniteList = ({
  isLoading,
  renderItem,
  hasError,
  loadingItemsNumber,
  items,
  cardHeight,
  EmptyListComponent,
  ErrorComponent,
  onFinishedScroll,
  maxHeight,
}) => {
  const Items = useMemo(
    () =>
      items.map((item, index) =>
        React.cloneElement(renderItem(item), {
          key: item.key || item.id,
          addBottomMargin: index < items.length - 1,
        })
      ),
    [renderItem, items]
  );

  if (isLoading && loadingItemsNumber) {
    return <ListSkeleton height={cardHeight} itemsNumber={loadingItemsNumber} />;
  }

  if (hasError) {
    return ErrorComponent;
  }

  if (items.length === 0 && EmptyListComponent) {
    return EmptyListComponent;
  }

  return (
    <ListContainer maxHeight={maxHeight} onScroll={onFinishedScroll}>
      {Items}
    </ListContainer>
  );
};

export default InfiniteList;

InfiniteList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool,
  loadingItemsNumber: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape()),
  renderItem: PropTypes.func.isRequired,
  onFinishedScroll: PropTypes.func,
  cardHeight: PropTypes.number,
  EmptyListComponent: PropTypes.node,
  ErrorComponent: PropTypes.node,
  maxHeight: PropTypes.string,
};

InfiniteList.defaultProps = {
  items: [],
  cardHeight: CARD_HEIGHT,
  EmptyListComponent: undefined,
  loadingItemsNumber: undefined,
  hasError: undefined,
  onFinishedScroll: undefined,
  maxHeight: "100%",
  ErrorComponent: <ErrorCard />,
};

const ListContainer = styled.div`
  max-height: ${({ maxHeight }) => maxHeight};
  overflow-y: auto;
`;
