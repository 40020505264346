export const browserMapping = {
  firefox: "firefox",
  safari: "safari",
};

const useBrowser = () => {
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    ((p) => {
      return p.toString() === "[object SafariRemoteNotification]";
      // eslint-disable-next-line no-undef
    })(!window.safari || (typeof safari !== "undefined" && safari.pushNotification));

  if (isSafari) {
    return browserMapping.safari;
  }

  if (typeof InstallTrigger !== "undefined") {
    return browserMapping.firefox;
  }

  return null;
};

export default useBrowser;
