import { useAuth } from "components/AuthProvider";
import Button from "components/buttons/Button";
import CancelButton from "components/buttons/CancelButton";
import useExternalPaymentStatus from "hooks/useExternalPaymentStatus";
import { errorToast, Input, Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import { paymentTypesMapping } from "utils/payment";
import { warningMessage } from "utils/userMessages";
import { isPhoneValid } from "utils/validators";

const Container = styled.div`
  width: 100%;
  max-height: ${({ isOpen }) => (isOpen ? "500px" : 0)};
  transition: max-height ${({ animationTime }) => animationTime}s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const PhoneContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;

  .mb-way-button {
    width: 150px;

    &:first-child {
      z-index: ${({ isPaymentInProgress }) => (isPaymentInProgress ? 1000 : "initial")};
    }

    :not(:first-child) {
      margin-left: 15px;
    }
  }

  @media (max-width: ${breakpoints.sm}) {
    .mb-way-button {
      flex: 1;
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (max-width: 360px) {
    flex-direction: column-reverse;
    align-items: center;

    .mb-way-button {
      width: 100%;

      :not(:first-child) {
        margin-left: 0px;
        margin-bottom: 10px;
      }
    }
  }
`;

const StyledInput = styled(Input)`
  ${({ hasPhoneError, theme }) => hasPhoneError && `border-color: ${theme.color.danger};`}
  border-radius: 10px;
  width: 140px;
  font-size: ${({ theme }) => theme.typography.heading.variant.h4.size.lg};
  font-family: ${({ theme }) => theme.typography.heading.variant.h4.font};
  font-weight: ${({ theme }) => theme.typography.heading.variant.h4.weight};

  ::-webkit-inner-spin-button {
    appearance: none;
  }

  appearance: textfield;
`;

const MbWayPaymentDetails = ({
  id,
  amount,
  isOpen,
  longMessage,
  onConfirmPayment,
  onSuccess,
  onPaymentStarted,
  onPaymentEnded,
  onCloseDetails,
  slideAnimationTime,
  isPaymentInProgress,
  itemTypeId,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const {
    paymentStatus,
    watchPaymentStatus,
    stopWatchingPaymentStatus,
  } = useExternalPaymentStatus();
  const [phone, setPhone] = useState(user.driver.phone);
  const [transactionId, setTransactionId] = useState();
  const [inputTouched, setInputTouched] = useState(false);

  const isInvalidPhone = useMemo(() => inputTouched && !isPhoneValid(phone), [
    inputTouched,
    phone,
  ]);

  useEffect(() => {
    if (!transactionId || !paymentStatus.isConcluded) {
      return;
    }

    onPaymentEnded();
    stopWatchingPaymentStatus();

    if (paymentStatus.error) {
      errorToast(t("3613") /* Erro */, paymentStatus.error);
      return;
    }

    onSuccess(transactionId);
  }, [
    transactionId,
    paymentStatus,
    onPaymentEnded,
    stopWatchingPaymentStatus,
    onSuccess,
  ]);

  const stopPayment = useCallback(() => {
    stopWatchingPaymentStatus();
    onPaymentEnded();
  }, [stopWatchingPaymentStatus, onPaymentEnded]);

  const startPayment = useCallback(
    async (event) => {
      event.stopPropagation();

      if (amount === null) {
        warningMessage(t("9704") /* Ocorreu um problema! Tenta novamente */);
        return;
      }

      if (isInvalidPhone) {
        warningMessage(
          t("9706") /* O número de telemóvel deverá ser composto por 9 números */
        );
        return;
      }

      onPaymentStarted();

      const createdTransactionId = await onConfirmPayment(
        id,
        { phoneNumber: phone },
        { disableLoading: true }
      );

      if (!createdTransactionId) {
        stopPayment();
        return;
      }

      if (amount === 0) {
        onPaymentEnded();
        onSuccess(createdTransactionId);
        return;
      }

      setTransactionId(createdTransactionId);
      watchPaymentStatus({
        paymentId: createdTransactionId,
        itemTypeId,
      });
    },
    [
      amount,
      isInvalidPhone,
      onPaymentStarted,
      onConfirmPayment,
      id,
      phone,
      stopPayment,
      onPaymentEnded,
      onSuccess,
      watchPaymentStatus,
      itemTypeId,
    ]
  );

  const onCancel = useCallback(
    (event) => {
      event.stopPropagation();

      if (isPaymentInProgress) {
        stopPayment();
        return;
      }

      onCloseDetails();
    },
    [isPaymentInProgress, stopPayment, onCloseDetails]
  );

  const onClickPhoneInput = (event) => event.stopPropagation();

  const onBlurPhoneInput = () => setInputTouched(true);

  return (
    <Container isOpen={isOpen} animationTime={slideAnimationTime}>
      <Text variant="body10">
        {t("8269") /* Seleciona o número associado à conta MB WAY */}
      </Text>
      <PhoneContainer onClick={onClickPhoneInput}>
        <StyledInput
          className="mt-30"
          type="number"
          name="phoneNumber"
          hasPhoneError={isInvalidPhone}
          max={999999999}
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          onBlur={onBlurPhoneInput}
          displayNativeControls={false}
        />
      </PhoneContainer>
      <div>
        <Text className="mt-30" variant="body11">
          {ReactHtmlParser(longMessage)}
        </Text>
      </div>
      <ButtonContainer isPaymentInProgress={isPaymentInProgress}>
        <CancelButton className="mb-way-button" size="xs" onClick={onCancel}>
          {t("48") /* Cancelar */}
        </CancelButton>
        {!isPaymentInProgress && (
          <Button
            className="mb-way-button mb-way-button-cancel"
            size="xs"
            onClick={startPayment}
          >
            {t("4537") /* Pagar */}
          </Button>
        )}
      </ButtonContainer>
    </Container>
  );
};

MbWayPaymentDetails.propTypes = {
  id: PropTypes.number.isRequired,
  amount: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  isPaymentInProgress: PropTypes.bool.isRequired,
  onConfirmPayment: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onPaymentStarted: PropTypes.func.isRequired,
  onPaymentEnded: PropTypes.func.isRequired,
  onCloseDetails: PropTypes.func.isRequired,
  slideAnimationTime: PropTypes.number,
  longMessage: PropTypes.string,
  itemTypeId: PropTypes.oneOf(Object.values(paymentTypesMapping)).isRequired,
};

MbWayPaymentDetails.defaultProps = {
  amount: null,
  longMessage: "",
  slideAnimationTime: 0,
};

export default MbWayPaymentDetails;
