import AbstractDataSource from "./abstractDataSource";

export default class EntityVehiclesDataSource extends AbstractDataSource {
  async getBrands(entityId, params = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/vehicles/brands`,
      params,
      {},
      useCache
    );
  }

  async getBrandModels(entityId, brandId, params = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/vehicles/brands/${brandId}/models`,
      params,
      {},
      useCache
    );
  }
}
