import Button from "components/buttons/Button";
import CancelButton from "components/buttons/CancelButton";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import Popup from "./Popup";

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;

  .action-popup-button {
    width: 130px;

    @media (max-width: ${breakpoints.sm}) {
      flex: 1;
    }
  }
`;

const ActionPopup = ({ title, children, width, onClose, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Popup width={width} title={title} onClose={onClose}>
      {children}
      <ButtonsContainer>
        <CancelButton className="mr-10 action-popup-button" onClick={onClose}>
          {t("48") /* Cancelar */}
        </CancelButton>
        <Button className="action-popup-button" onClick={onConfirm}>
          {t("35") /* Confirmar */}
        </Button>
      </ButtonsContainer>
    </Popup>
  );
};

ActionPopup.defaultProps = {
  width: undefined,
};

ActionPopup.propTypes = {
  width: PropTypes.number,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ActionPopup;
