import React from "react";
import DriversIdentificationsInformations from "domain/public/components/DriversIdentificationsInformations";
import { useStepsContext } from "domain/public/components/StepsProvider";
import { STEPS } from "../helper";

const Informations = () => {
  const { goToStep } = useStepsContext();

  const onAdvance = () => {
    goToStep(STEPS.noticeData);
  };

  return <DriversIdentificationsInformations onAdvance={onAdvance} />;
};

export default Informations;
