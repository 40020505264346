import FlexBox from "components/FlexBox";
import Skeleton from "components/Skeleton";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TITLE_FONT_SIZE } from "./index";

const TitleSkeleton = ({ className }) => {
  const { t } = useTranslation();

  return (
    <FlexBox alignItems="center" className={className}>
      <StyledText variant="body9">{t("1848") /* Dístico */} &nbsp;</StyledText>
      <Skeleton width={55} height={12} />
      <StyledText variant="body9">&nbsp;&nbsp;-&nbsp;&nbsp;</StyledText>
      <Skeleton width={85} height={12} />
    </FlexBox>
  );
};

export default TitleSkeleton;

TitleSkeleton.propTypes = {
  className: PropTypes.string.isRequired,
};

const StyledText = styled(Text)`
  font-size: ${TITLE_FONT_SIZE}px;
  line-height: ${TITLE_FONT_SIZE}px;
`;
