import Axios from "axios";
import useNTPDateTime from "hooks/useNtpDateTime";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { gaCategory, gaEvents } from "utils/googleAnalytics";
import { AppContext } from "../../../components/AppProvider";
import { useAuth } from "../../../components/AuthProvider";
import { useNetInfoContext } from "../../../providers/NetInfo";
import { breakpointsMapping, smallDevicesBreakpoints } from "../../../utils/breakpoints";
import { colorsMapping } from "../../../utils/colors";
import {
  minuteInMilliseconds,
  serverDateFormat,
  serverDateTimeFormat,
} from "../../../utils/dateTime";
import { entityConfigurationsMapping, getConfigValue } from "../../../utils/entities";
import { feeTypesMapping, getReadablePeriods } from "../../../utils/fees";
import { meetsRequirements } from "../../../utils/general";
import { parkingMethodsMapping, parkingTypesMapping } from "../../../utils/parking";
import { paymentMethodsMapping } from "../../../utils/payment";
import { getAvailableVehicles } from "../../../utils/vehicles";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import useClosestEntitySuggestion from "../hooks/useClosestEntitySuggestion";
import {
  calculateParking as calculateParkingRequest,
  getFees as getFeesRequest,
} from "../requests/parkings";
import Parking from "./Parking";
import ParkingMediumDevices from "./ParkingMediumDevices";
import ParkingSmallDevices from "./ParkingSmallDevices";
import SuggestedEntityPopup from "./SuggestedEntityPopup";
import {
  benefitToArray,
  createParking as createParkingRequest,
  getBenefits as getBenefitsRequest,
  getParking as getParkingRequest,
  getStreetHolidays as getStreetHolidaysRequest,
  getVehicles as getVehiclesRequest,
  parkingSteps,
  selectedBenefitInitialState,
  selectedFeeInitialState,
  selectedParkingTypeInitialState,
  startParking as startParkingRequest,
} from "./controller";
import { getFilteredPopupFees } from "./feesPopup/helper";
import ParkingRequirements from "./requirements/Page";
import ParkingRequirementsSmallDevices from "./requirements/SmallDevicesPage";

const ParkingPage = () => {
  const {
    userId,
    defaultEntityId,
    defaultEntity,
    updateBalance,
    user,
    driverHash,
  } = useAuth();
  const { setIsLoading, breakpoint } = useContext(AppContext);
  const { hasInternetAccess } = useNetInfoContext();
  const [currentStep, setCurrentStep] = useState(parkingSteps.streets);
  const { backofficeTheme } = useBackofficeThemeContext();
  const [allowBackStep, setAllowBackStep] = useState(false);
  const [popupFees, setPopupFees] = useState([]);
  const lastCalculatedDuration = useRef();
  const { selectedEntity, suggestedEntity } = useClosestEntitySuggestion();
  const [showSuggestedEntity, setShowSuggestedEntity] = useState([]);
  const { isCorrectDateTimeChecked } = useNTPDateTime();

  const [requirementsMet, setRequirementsMet] = useState({
    balance: true,
    vehicles: true,
    availableVehicles: true,
    allChecked: false,
  });

  const [candidateStreet, setCandidateStreet] = useState({
    id: null,
    name: "",
    hasFees: true,
    feePeriods: "",
    hasStreetHoliday: false,
    isLoadingInfo: false,
  });

  const [selectedStreet, setSelectedStreet] = useState({
    id: null,
    name: "",
    explorationName: "",
    updatedDateTime: null,
  });

  const [selectedFee, setSelectedFee] = useState(selectedFeeInitialState);

  const [selectedVehicle, setSelectedVehicle] = useState({
    id: null,
    licensePlate: "",
    brand: "",
    updatedDateTime: null,
    color: "",
  });

  const [selectedParkingType, setSelectedParkingType] = useState(
    selectedParkingTypeInitialState
  );

  const [selectedBenefit, setSelectedBenefit] = useState(selectedBenefitInitialState);

  const [activeParking, setActiveParking] = useState({
    id: null,
    amount: 0,
    startingDate: "",
    endingDate: null,
    scheduleExtraInfo: null,
    parkingPaymentTypeId: null,
  });

  const [calculatedParking, setCalculatedParking] = useState({
    isLoading: false,
    endingDate: null,
    amount: null,
    duration: null,
    error: false,
  });

  const [streets, setStreets] = useState([]);
  const [fees, setFees] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [benefits, setBenefits] = useState([]);

  const stepsHistoryStack = useRef([parkingSteps.streets]);
  const position = useRef({ latitude: null, longitude: null });

  const onlyParkWithActiveGeographicLocationActive = getConfigValue(
    defaultEntity?.configurations || [],
    entityConfigurationsMapping.onlyParkWithActiveGeographicLocationActive
  );

  const canParkWithoutBalance = getConfigValue(
    user?.driver?.defaultEntity?.configurations || [],
    entityConfigurationsMapping.canParkWithoutBalance
  );

  useEffect(() => {
    const getVehicles = async () => {
      const newVehicles = await getVehiclesRequest(defaultEntityId, driverHash);

      setVehicles(getAvailableVehicles(newVehicles));
    };

    getVehicles();
  }, []);

  useEffect(() => {
    if (user?.driver?.defaultEntity?.balance?.amount === 0 && !canParkWithoutBalance) {
      setRequirementsMet((prevRequirements) => {
        return { ...prevRequirements, balance: false, allChecked: true };
      });

      return;
    }

    setRequirementsMet((prevRequirements) => {
      return { ...prevRequirements, balance: true, allChecked: true };
    });
  }, [defaultEntityId]);

  const getParking = useCallback(
    async (id, { parkingInfoCancelToken = null } = {}) => {
      const parkingInfo = await getParkingRequest(defaultEntityId, driverHash, id, {
        parkingInfoCancelToken,
      });

      if (parkingInfo) {
        updateBalance();

        setActiveParking({
          id,
          amount: parkingInfo.amount,
          startingDate: parkingInfo.startingDate,
          endingDate: parkingInfo.endingDate,
          scheduleExtraInfo: parkingInfo.scheduleExtraInfo,
          parkingPaymentTypeId: parkingInfo.parkingPaymentTypeId,
        });
      }
    },
    [defaultEntityId, driverHash, updateBalance]
  );

  useEffect(() => {
    if (!hasInternetAccess) {
      return undefined;
    }

    const parkingInfoCancelToken = Axios.CancelToken.source();
    let updateParkingInterval = null;

    if (activeParking.id) {
      updateParkingInterval = setInterval(
        () => getParking(activeParking.id, { parkingInfoCancelToken }),
        minuteInMilliseconds
      );
    }

    return () => {
      parkingInfoCancelToken.cancel();

      if (updateParkingInterval) {
        clearInterval(updateParkingInterval);
      }
    };
  }, [hasInternetAccess, activeParking.id]);

  const handleStreetsDistanceChange = useCallback((event) => {
    setStreets(event.detail.streets);
  }, []);

  const handlePinChange = useCallback((event) => {
    position.current = event.detail.position;
  }, []);

  const handleSetCandidateStreet = useCallback(
    (newCandidateStreetId, newCandidateStreetName) => {
      const getStreetInfo = async (streetId) => {
        const streetHolidays = await getStreetHolidaysRequest(defaultEntityId, streetId);

        const currentDate = moment();
        const feesDate = moment();

        for (let index = 0; index < (streetHolidays?.length || 0); index += 1) {
          const streetHoliday = streetHolidays[index];

          if (
            streetHoliday.day !== currentDate.date() ||
            streetHoliday.month !== currentDate.month() + 1
          ) {
            break;
          }

          feesDate.add(1, "days");
          setCandidateStreet((currentCandidateStreet) => ({
            ...currentCandidateStreet,
            hasStreetHoliday: true,
          }));
        }

        const streetFees = await getFeesRequest(defaultEntityId, streetId, {
          setIsLoading: () => {}, // avoid loader for this operation
          date: feesDate.format(serverDateFormat),
        });

        if (streetHolidays === null || streetFees === null) {
          setCandidateStreet((currentCandidateStreet) => ({
            ...currentCandidateStreet,
            hasFees: false,
            isLoadingInfo: false,
          }));

          return;
        }

        setCandidateStreet((currentCandidateStreet) => ({
          ...currentCandidateStreet,
          id: streetId,
          name: newCandidateStreetName,
          hasFees: !!streetFees.length,
          feePeriods: getReadablePeriods(streetFees),
          isLoadingInfo: false,
        }));
        setFees(streetFees || []);
        setPopupFees(getFilteredPopupFees(streetFees || []));
      };

      setCandidateStreet({
        hasFees: true,
        id: newCandidateStreetId || null,
        name: "",
        hasStreetHoliday: false,
        isLoadingInfo: true,
        feePeriods: "",
      });
      setPopupFees([]);
      getStreetInfo(newCandidateStreetId);
    },
    [defaultEntity]
  );

  const setCandidateStreetByMap = useCallback(
    (event) => {
      const newCandidateStreet = event.detail;

      if (newCandidateStreet.streetId) {
        handleSetCandidateStreet(
          newCandidateStreet.streetId,
          newCandidateStreet.streetName
        );
      } else {
        setCandidateStreet({
          hasFees: false,
          id: null,
          name: "",
          hasStreetHoliday: false,
          isLoadingInfo: false,
          feePeriods: "",
        });
      }
    },
    [handleSetCandidateStreet]
  );

  const changeStep = useCallback((newStep) => {
    // Enable back only after choose first step
    if (stepsHistoryStack.current.length === 1) {
      setAllowBackStep(true);
    }

    stepsHistoryStack.current.push(newStep);
    setCurrentStep(newStep);
  }, []);

  const onBack = useCallback(() => {
    stepsHistoryStack.current.pop();
    setCurrentStep(stepsHistoryStack.current[stepsHistoryStack.current.length - 1]);

    // Prevent going back further than city choose step
    if (stepsHistoryStack.current.length === 1) {
      setAllowBackStep(false);
    }
  }, []);

  const setAllowBackStepCallback = useCallback((allowBack) => {
    setAllowBackStep(allowBack);
  }, []);

  const setCandidateStreetCallback = useCallback(({ id, name }) => {
    if (!id) {
      return;
    }

    setCandidateStreet({
      hasFees: true,
      id,
      name,
      hasStreetHoliday: false,
      isLoadingInfo: true,
      feePeriods: "",
    });
  }, []);

  const resetCalculatedParking = () => {
    setCalculatedParking({
      isLoading: false,
      amount: null,
      duration: null,
      error: false,
    }); // Reset existing parking calculations
  };

  const resetParking = useCallback(() => {
    resetCalculatedParking();
    setSelectedBenefit(selectedBenefitInitialState); // Reset current selected benefit
    setSelectedParkingType(selectedParkingTypeInitialState); // Reset selected parking type (daily/monthly/weekly does not support start & stop)
  }, []);

  const setSelectedStreetCallBack = useCallback(({ id, name, explorationName }) => {
    ReactGA.event({
      category: gaCategory,
      action: gaEvents.startParkingIntention,
    });

    setSelectedStreet({
      id,
      name,
      explorationName,
      updatedDateTime: moment().format(serverDateTimeFormat),
    });
  }, []);

  const setSelectedFeeCallBack = useCallback(({ id, name, maxDuration, minDuration }) => {
    setSelectedFee({
      id,
      name,
      updatedDateTime: moment().format(serverDateTimeFormat),
      maxDuration,
      minDuration,
    });
  }, []);

  const setSelectedVehicleCallback = useCallback(
    ({ id, licensePlate, brand, colorId }) => {
      setSelectedVehicle({
        id,
        licensePlate,
        brand,
        updatedDateTime: moment().format(serverDateTimeFormat),
        color: backofficeTheme.carColor[colorId || colorsMapping.gray],
      });
    },
    []
  );

  const setParkingTypeCallback = useCallback((parkingTypeId) => {
    setSelectedParkingType({
      id: parkingTypeId,
      updatedDateTime: moment().format(serverDateTimeFormat),
    });
  }, []);

  const setBenefitCallback = useCallback(({ id, description, type, value }) => {
    setSelectedBenefit({
      id,
      description,
      type,
      value,
      updatedDateTime: moment().format(serverDateTimeFormat),
      color: backofficeTheme.benefitColor[type] || "",
    });
  });

  const onChooseDurationCallback = useCallback(() => {
    changeStep(parkingSteps.durationChoosePaymentMethod);
  }, []);

  const startParkingCallback = useCallback(() => {
    const startParking = async () => {
      const parkingInfo = await startParkingRequest(
        defaultEntityId,
        selectedStreet.id,
        {
          driverId: userId,
          licensePlate: selectedVehicle.licensePlate,
          parkingMethodId: parkingMethodsMapping.driverWeb,
          startingDate: moment().format(serverDateTimeFormat),
          lat: position.current.latitude,
          lng: position.current.longitude,
          feeTypeId: selectedFee.id,
          benefits: benefitToArray(selectedBenefit.id, selectedBenefit.value),
        },
        { setIsLoading }
      );

      if (parkingInfo) {
        updateBalance();

        setAllowBackStep(false);

        setActiveParking({
          id: parkingInfo.id,
          amount: parkingInfo.amount,
          startingDate: parkingInfo.startingDate,
          scheduleExtraInfo: parkingInfo.scheduleExtraInfo,
          endingDate: parkingInfo.endingDate,
          parkingPaymentTypeId: parkingInfo.parkingPaymentTypeId,
        });

        changeStep(parkingSteps.startAndStopDetails);
      }
    };

    startParking();
  }, [
    selectedStreet.id,
    selectedVehicle.licensePlate,
    selectedBenefit.id,
    selectedFee.id,
    position.current,
  ]);

  const checkParking = useCallback(
    async (parkingId) => {
      updateBalance();
      setAllowBackStep(false);

      await getParking(parkingId);

      changeStep(parkingSteps.durationDetails);
    },
    [updateBalance, getParking, changeStep]
  );

  const createParkingCallback = useCallback(
    async (paymentTypeId, otherParams = {}, args = { disableLoading: false }) => {
      const parkingInfo = await createParkingRequest(
        defaultEntityId,
        selectedStreet.id,
        {
          driverId: userId,
          licensePlate: selectedVehicle.licensePlate,
          parkingMethodId: parkingMethodsMapping.driverWeb,
          startingDate: moment().format(serverDateTimeFormat),
          lat: position.current.latitude,
          lng: position.current.longitude,
          feeTypeId: selectedFee.id,
          benefits: benefitToArray(selectedBenefit.id, selectedBenefit.value),
          paymentTypeId,
          duration: lastCalculatedDuration.current,
          ...otherParams,
        },
        { setIsLoading: args.disableLoading ? () => {} : setIsLoading }
      );

      if (!parkingInfo) {
        return null;
      }

      return parkingInfo.id;
    },
    [
      selectedStreet.id,
      selectedVehicle.licensePlate,
      selectedBenefit.id,
      selectedFee.id,
      position.current,
    ]
  );

  const calculateParkingCallback = useCallback(
    (duration = 1) => {
      // Duration is always mandatory and greater than 0
      const calculate = async () => {
        const calculatedParkingInfo = await calculateParkingRequest(
          defaultEntityId,
          selectedStreet.id,
          {
            driverId: userId,
            feeTypeId: selectedFee.id,
            licensePlate: selectedVehicle.licensePlate,
            startingDate: moment().format(serverDateTimeFormat),
            benefits: benefitToArray(selectedBenefit.id, selectedBenefit.value),
            parkingMethodId: parkingMethodsMapping.driverWeb,
            paymentTypeId: canParkWithoutBalance
              ? paymentMethodsMapping.mbway.id
              : paymentMethodsMapping.balance.id,
            duration,
          }
        );

        if (!calculatedParkingInfo) {
          setCalculatedParking({
            ...calculatedParking,
            error: true,
            isLoading: false,
          });
          return;
        }

        lastCalculatedDuration.current = duration;

        setCalculatedParking({
          ...calculatedParkingInfo,
          error: false,
          isLoading: false,
        });
      };

      if (selectedParkingType.id !== parkingTypesMapping.duration) {
        return;
      }

      setCalculatedParking({
        ...calculatedParking,
        error: false,
        isLoading: true,
      });

      calculate();
    },
    [
      selectedParkingType.id,
      selectedStreet.id,
      selectedFee.id,
      selectedVehicle.licensePlate,
      selectedBenefit.id,
      calculatedParking,
    ]
  );

  useEffect(() => {
    document.addEventListener("streetsDistanceChange", handleStreetsDistanceChange);
    document.addEventListener("streetChange", setCandidateStreetByMap);
    document.addEventListener("changePinPosition", handlePinChange);

    return () => {
      document.removeEventListener("streetsDistanceChange", handleStreetsDistanceChange);
      document.removeEventListener("streetChange", setCandidateStreetByMap);
      document.removeEventListener("changePinPosition", handlePinChange);
    };
  }, [handleStreetsDistanceChange, setCandidateStreetByMap, handlePinChange]);

  useEffect(() => {
    if (!selectedStreet.id) {
      return;
    }

    if (fees.length === 1) {
      setSelectedFee({
        id: fees[0].id,
        name: fees[0].name,
        maxDuration: fees[0].maxDuration,
        minDuration: fees[0].minDuration,
        updatedDateTime: moment().format(serverDateTimeFormat),
      }); // Set operation timestamp forces effect event with the same fee

      return;
    }

    changeStep(parkingSteps.fees);
  }, [selectedStreet.id, selectedStreet.updatedDateTime]);

  useEffect(() => {
    if ([parkingSteps.fees, parkingSteps.streets].includes(currentStep)) {
      resetParking(); // Reset parking calculations when fee or street changes
    }
  }, [currentStep]);

  useEffect(() => {
    if (selectedFee.id) {
      changeStep(parkingSteps.vehicles);
    }
  }, [selectedFee.id, selectedFee.updatedDateTime, vehicles]);

  useEffect(() => {
    if (!selectedVehicle.licensePlate) {
      return;
    }

    if (
      selectedFee.id !== feeTypesMapping.minutes ||
      user?.driver?.defaultEntity?.balance?.amount === 0 // Only duration parkings are available without balance
    ) {
      setSelectedParkingType({
        id: parkingTypesMapping.duration,
        updatedDateTime: moment().format(serverDateTimeFormat),
      });
      return;
    }

    setSelectedParkingType(selectedParkingTypeInitialState);

    changeStep(parkingSteps.parkingTypes);
  }, [selectedVehicle.licensePlate, selectedVehicle.updatedDateTime]);

  useEffect(() => {
    const getBenefits = async () => {
      const benefitsResponse = await getBenefitsRequest(
        defaultEntityId,
        selectedVehicle.licensePlate,
        selectedStreet.id,
        {
          setIsLoading,
          feeTypeId: selectedFee.id,
          parkingTypeId: selectedParkingType.id,
        }
      );

      if (benefitsResponse === null || benefitsResponse.length === 0) {
        setSelectedBenefit({
          id: null,
          description: "",
          type: null,
          value: 0,
          updatedDateTime: moment().format(serverDateTimeFormat),
          color: "",
        });
        return;
      }

      if (benefitsResponse.length === 1) {
        setSelectedBenefit({
          id: benefitsResponse[0].id,
          description: benefitsResponse[0].description,
          type: benefitsResponse[0].type,
          value: benefitsResponse[0].value,
          updatedDateTime: moment().format(serverDateTimeFormat),
          color: backofficeTheme.benefitColor[benefitsResponse[0].type] || "",
        });

        return;
      }

      setBenefits(benefitsResponse);
      changeStep(parkingSteps.benefits);
    };

    if (selectedParkingType.id) {
      getBenefits();
    }
  }, [selectedParkingType.id, selectedParkingType.updatedDateTime, selectedFee.id]);

  useEffect(() => {
    if (selectedBenefit.updatedDateTime === null) {
      // Avoid the change step when the benefit is reset
      return;
    }

    resetCalculatedParking();

    if (selectedParkingType.id === parkingTypesMapping.startAndStop) {
      changeStep(parkingSteps.startStartAndStop);
    } else if (selectedParkingType.id === parkingTypesMapping.duration) {
      if (selectedFee.id === feeTypesMapping.minutes) {
        changeStep(parkingSteps.durationChooseDuration);
        return;
      }

      changeStep(parkingSteps.durationLongPeriodFeeStart);
    }
  }, [selectedBenefit.id, selectedBenefit.updatedDateTime]);

  useEffect(() => {
    setShowSuggestedEntity(!!suggestedEntity && !!selectedEntity);
  }, [suggestedEntity]);

  const chooseParkingViewBasedOnDevice = () => {
    const properties = {
      onlyParkWithActiveGeographicLocationActive,
      onBack: allowBackStep ? onBack : null,
      step: currentStep,
      streets,
      candidateStreet,
      selectedVehicle,
      selectedBenefit,
      stepsProps: {
        isMapInteractable:
          currentStep === parkingSteps.streets && !candidateStreet.isLoadingInfo,
        selectedStreet,
        checkParking,
        startParkingCallback,
        createParkingCallback,
        calculateParkingCallback,
        activeParking,
        calculatedParking,
        onChooseDurationCallback,
        setAllowBackStepCallback,
        selectedFee,
        candidateStreetId: candidateStreet.id,
      },
      bottomStepsProps: {
        selectedFeeId: selectedFee.id,
        selectedParkingTypeId: selectedParkingType.id,
        setSelectedStreetCallBack,
        setCandidateStreetCallback,
        setSelectedFeeCallBack,
        setSelectedVehicleCallback,
        setParkingTypeCallback,
        setBenefitCallback,
        onlyParkWithActiveGeographicLocationActive,
        fees,
        popupFees,
        vehicles,
        benefits,
      },
    };

    if (!isCorrectDateTimeChecked || !requirementsMet.allChecked) {
      return null;
    }

    if (showSuggestedEntity) {
      return (
        <SuggestedEntityPopup
          onClose={() => setShowSuggestedEntity(false)}
          selectedEntity={selectedEntity}
          suggestedEntity={suggestedEntity}
        />
      );
    }

    if (!meetsRequirements(requirementsMet)) {
      if (smallDevicesBreakpoints.includes(breakpoint)) {
        return <ParkingRequirementsSmallDevices requirementsMet={requirementsMet} />;
      }

      return <ParkingRequirements requirementsMet={requirementsMet} />;
    }

    if (smallDevicesBreakpoints.includes(breakpoint)) {
      return <ParkingSmallDevices {...properties} />;
    }

    if (breakpointsMapping.noBreakpoint === breakpoint) {
      return <ParkingMediumDevices {...properties} />;
    }

    return <Parking {...properties} />;
  };

  return <>{chooseParkingViewBasedOnDevice()}</>;
};

export default ParkingPage;
