import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Text } from "iparque-components";
import { useAuth } from "../../../components/AuthProvider";
import { ReactComponent as ProfileIcon } from "../../../assets/icons/profile.svg";
import { breakpoints } from "../../../utils/breakpoints";
import { formatMoney } from "../../../utils/money";
import { routesMapping } from "../../../utils/routes";

const ProfileCard = styled.div`
  margin-top: 10px;
  display: flex;
  cursor: pointer;

  @media (max-width: ${breakpoints.lg}) {
    justify-content: center;
    align-items: center;
  }
`;

const ProfileIconContainer = styled.div`
  display: flex;
  padding-right: 10px;
`;

const StyledProfileIcon = styled(ProfileIcon)`
  fill: ${({ theme }) => theme.typography.text.color.secondary};
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
`;

const Profile = ({ toggleMenu }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const userAmount = user.driver?.defaultEntity?.balance?.amount;
  const history = useHistory();

  const linkToProfile = () => {
    history.push(routesMapping.backofficeProfile);

    if (toggleMenu) {
      toggleMenu();
    }
  };

  return (
    <ProfileCard onClick={linkToProfile}>
      <ProfileIconContainer>
        <StyledProfileIcon />
      </ProfileIconContainer>
      <ProfileInfo>
        <Text color="secondary" variant="body9">
          {user.driver.name}
        </Text>
        <Text color="secondary" variant="body11">
          {user.driver?.defaultEntity?.name || `${t("7935") /* Carregando */}...`}
        </Text>
        <Text color="tertiary" variant="body7">
          {userAmount !== undefined
            ? formatMoney(userAmount, { centsFormat: false })
            : `${t("7935") /* Carregando */}...`}
        </Text>
      </ProfileInfo>
    </ProfileCard>
  );
};

Profile.propTypes = {
  toggleMenu: PropTypes.func,
};

Profile.defaultProps = {
  toggleMenu: undefined,
};

export default Profile;
