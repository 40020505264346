import Button from "components/buttons/Button";
import LabelValueCardCell from "domain/private/components/cardList/LabelValueCell";
import useLanguage from "hooks/useLanguage";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { formatDate } from "utils/dateTime";
import { formatMoney } from "utils/money";
import { permitHoldersSubscriptionsStates } from "utils/permitHolders";
import ListCard from "../../../components/ListCard";
import PaymentMethods from "../../../components/subscriptions/PaymentMethods";
import SubscriptionStateText from "../../../components/subscriptions/StateText";
import useOccupationType from "../../../hooks/useOccupationType";
import useSubscriptionPaymentMethodsSize from "../../../hooks/useSubscriptionPaymentMethodsSize";
import useSubscriptionsCard from "../../../hooks/useSubscriptionsCard";

const Card = ({
  id,
  cardNumber,
  licencePlates,
  startingDate,
  endDate,
  amount,
  state,
  ...other
}) => {
  const { t } = useTranslation();
  const language = useLanguage();
  const {
    cardRef,
    onPay,
    closeDetails,
    isLoading,
    shouldDisplayPayButton,
  } = useSubscriptionsCard();
  const { approximateHeight } = useSubscriptionPaymentMethodsSize();
  const { isVehicleOccupation } = useOccupationType();

  return (
    <ListCard
      isLoading={isLoading}
      {...other}
      extraInfo={
        <PaymentMethods subscriptionId={id} closeDetails={closeDetails} amount={amount} />
      }
      ref={cardRef}
      displayArrow={false}
      extraInfoApproximateHeight={approximateHeight}
    >
      <LabelValueCardCell label={t("4713") /* Número */} value={cardNumber} size="sm" />
      {isVehicleOccupation && (
        <LabelValueCardCell
          label={t("1499") /* Matrícula(s) */}
          value={licencePlates}
          size="sm"
        />
      )}
      <LabelValueCardCell
        label={t("10212") /* Data de início */}
        value={formatDate(startingDate, language)}
        size="sm"
      />
      <LabelValueCardCell
        label={t("8459") /* Data de fim */}
        value={formatDate(endDate, language)}
        size="sm"
      />
      <LabelValueCardCell
        label={t("4912") /* Montante */}
        value={formatMoney(amount, { country: language })}
        size="sm"
        valueAlign="right"
      />
      <LabelValueCardCell
        value={<SubscriptionStateText {...state} right />}
        size="xs"
        align="right"
      />
      <LabelValueCardCell
        value={
          <ButtonContainer
            className="ml-20"
            shouldDisplay={
              state.id === permitHoldersSubscriptionsStates.waitingForPayment &&
              shouldDisplayPayButton
            }
          >
            <Button className="uppercase" size="xs" onClick={onPay}>
              {t("4537") /* Pagar */}
            </Button>
          </ButtonContainer>
        }
        size="xs"
        align="right"
      />
    </ListCard>
  );
};

export default Card;

Card.propTypes = {
  id: PropTypes.number.isRequired,
  cardNumber: PropTypes.string.isRequired,
  licencePlates: PropTypes.string.isRequired,
  startingDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  state: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

const ButtonContainer = styled.div`
  visibility: ${({ shouldDisplay }) => (shouldDisplay ? "visible" : "hidden")};
`;
