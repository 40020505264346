import PropTypes from "prop-types";

const requirementsProps = {
  balance: PropTypes.bool.isRequired,
  vehicles: PropTypes.bool.isRequired,
  availableVehicles: PropTypes.bool.isRequired,
};

export const requirementsPagePropsTypes = {
  requirementsMet: PropTypes.shape(requirementsProps).isRequired,
};

export const requirementsPropsTypes = {
  requirementsMet: PropTypes.shape(requirementsProps).isRequired,
  className: PropTypes.string,
};

export const requirementsDefaultProps = {
  className: "",
};
