import AbstractClient from "./abstractClient";
import { buildQueryString } from "../../utils/general";

/**
 * Cached client
 *
 * @class CachedClient
 * @extends {AbstractClient}
 */
class CachedClient extends AbstractClient {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  async GET(path, params = {}, headers = {}, useCache = false, cancelToken = null) {
    const cacheKey = `${path}?${buildQueryString(params)}`;

    let requestedData = null;

    if (useCache && process.env.NODE_ENV === "production") {
      requestedData = JSON.parse(sessionStorage.getItem(cacheKey));
    }

    if (!requestedData || useCache === false) {
      requestedData = await super.GET(path, params, headers, cancelToken);
    }

    if (useCache) {
      sessionStorage.setItem(cacheKey, JSON.stringify(requestedData));
    }

    return requestedData;
  }
}

export default CachedClient;
