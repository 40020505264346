export const actionTypes = {
  SET_CURRENT_STEP: "SET_CURRENT_STEP",
};

const setCurrentStep = (state, currentStep) => ({ ...state, currentStep });

const actions = {
  [actionTypes.SET_CURRENT_STEP]: setCurrentStep,
};

export default actions;
