import React from "react";
import PersonalInfoForm from "../../../components/general/PersonalInfoForm";
import TabIdentification from "../../../components/TabIdentification";
import { tabsIdsMap } from "../../../helper";
import TabDetailsOperations from "../../operations/TabDetails";

const GeneralTab = () => {
  return (
    <>
      <TabDetailsOperations className="mb-10" />
      <TabIdentification tabId={tabsIdsMap.general} />
      <PersonalInfoForm className="mt-30" />
    </>
  );
};

export default GeneralTab;
