/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useInfiniteList from "../../../hooks/useInfiniteList";
import ContentBorderBox, {
  CONTENT_CONTAINER_HORIZONTAL_SPACING,
} from "../components/ContentBorderBox";
import ContentHeader from "../components/ContentHeader";
import ListSkeleton from "../components/ListSkeleton";
import ErrorCard from "../components/ErrorCard";
import AllNotices from "./AllNotices";
import NoticeCard from "./Card";
import EmptyUnpaidNotices from "./EmptyUnpaidNotices";
import Requirements from "./components/Requirements";
import { noticesPropTypes } from "./propTypes";
import { NOTICE_PLACEHOLDER_HEIGHT } from "./controller";
import PayAllButton from "./components/PayAllButton";
import ValidateVehiclesLink from "./components/ValidateVehiclesLink";
import useCornerColor from "./hooks/useCornerColor";

const UnpaidNoticesContainer = styled.div`
  max-height: 500px;
  overflow: auto;
`;

const ButtonWrapper = styled.div`
  margin: 10px ${CONTENT_CONTAINER_HORIZONTAL_SPACING}px 0;
`;

const Notices = ({
  getNoticesCallback,
  validatedLicencePlates,
  unvalidatedLicencePlates,
}) => {
  const { t } = useTranslation();
  const {
    items,
    onFinishedScroll,
    isRequested,
    isLoading,
    hasError,
    errors,
  } = useInfiniteList(getNoticesCallback, {
    unpaid: true,
  });
  const cornerColor = useCornerColor(items);

  const hasMultiplePayable = items.map((item) => item.payable).length > 1;

  const borderBoxContent = (() => {
    if (isLoading) {
      return <ListSkeleton itemsNumber={1} height={NOTICE_PLACEHOLDER_HEIGHT} />;
    }

    if (hasError && !errors.length) {
      return <ErrorCard />;
    }

    if (errors.length) {
      return <Requirements errors={errors} />;
    }

    if (items.length) {
      return (
        <UnpaidNoticesContainer onScroll={onFinishedScroll}>
          {items.map((item) => (
            <NoticeCard key={`${item.number}-unpaid`} {...item} />
          ))}
        </UnpaidNoticesContainer>
      );
    }

    if (isRequested && items.length === 0) {
      return (
        <EmptyUnpaidNotices
          validatedLicencePlates={validatedLicencePlates}
          unvalidatedLicencePlates={unvalidatedLicencePlates}
        />
      );
    }

    return null;
  })();

  return (
    <>
      <ContentHeader
        topTitle={t("8415") /* Ups! Tens infrações? */}
        bottomTitle={t("8416") /* Não te preocupes. Paga aqui! */}
      />
      <ContentBorderBox
        className="mt-40"
        cornerColor={cornerColor}
        title={t("8417") /* Infrações por pagar */}
        topRightCornerContent={
          <ValidateVehiclesLink
            unvalidatedLicencePlates={unvalidatedLicencePlates}
            hasUnpaidNotices={items.length > 0}
          />
        }
      >
        {borderBoxContent}
      </ContentBorderBox>
      {isRequested && hasMultiplePayable ? (
        <ButtonWrapper>
          <PayAllButton notices={items} size="sm" />
        </ButtonWrapper>
      ) : null}
      <AllNotices getNoticesCallback={getNoticesCallback} />
    </>
  );
};

Notices.propTypes = noticesPropTypes;

export default Notices;
