import React, { useCallback } from "react";
import PropTypes from "prop-types";
import SwitchField from "./SwitchField";
import { useSettingsProvider } from "../Provider";

const SimpleDefinition = ({ title, description, definitionKey, checked, className }) => {
  const { saveSettings } = useSettingsProvider();

  const onSwitchChange = useCallback(
    (event) => {
      saveSettings(definitionKey, event.target.checked);
    },
    [saveSettings]
  );

  return (
    <SwitchField
      title={title}
      description={description}
      definitionKey={definitionKey}
      checked={checked}
      onChange={onSwitchChange}
      className={className}
    />
  );
};

SimpleDefinition.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  definitionKey: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

SimpleDefinition.defaultProps = {
  className: null,
};

export default SimpleDefinition;
