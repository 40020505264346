import i18n from "i18next";
import DriversDataSource from "../../../lib/clients/iParque/dataSources/driversDataSource";
import PaymentsDataSource from "../../../lib/clients/iParque/dataSources/paymentsDataSource";
import { getEntityConfiguration } from "../../../utils/auth";
import { entityConfigurationsMapping } from "../../../utils/entities";
import { hasCanceledError } from "../../../utils/error";
import {
  getPaymentCodeById,
  paymentMethodsMapping,
  processPaymentMethods,
} from "../../../utils/payment";
import { errorMessage } from "../../../utils/userMessages";
import { getVehiclesColorsMap } from "../../../utils/vehicles";

const driversDataSource = new DriversDataSource();
const paymentsDataSource = new PaymentsDataSource();

const processBalanceHistory = (items) => {
  return items.map((item) => {
    return {
      id: item.id,
      key: `${item.date}-${item.id}`,
      description: item?.transactionType?.description,
      dateTime: item.date,
      amount: item.amount,
      completed: item?.balanceCharge?.completed ?? true,
      balanceChargeId: item?.balanceCharge?.id,
      paymentMethodId:
        item?.balanceCharge?.paymentType?.id || paymentMethodsMapping.balance.id,
      licensePlate: item.licencePlate,
      invoice: {
        url: item?.balanceCharge?.payment?.invoiceUrl,
        number: item?.balanceCharge?.payment?.invoiceNumber,
      },
    };
  });
};

const processPaymentsHistory = (items) => {
  return items.map((item) => {
    return {
      id: item.id,
      key: `${item.date}-${item.id}`,
      description: item.description,
      dateTime: item.date,
      amount: item.amount,
      completed: !item?.isPending ?? true,
      balanceChargeId: null,
      paymentMethodId: item?.paymentType?.id,
      licensePlate: item.licencePlate,
      invoice: {
        url: item?.payment?.invoiceUrl,
        number: item?.payment?.invoiceNumber,
      },
    };
  });
};

const getPaymentType = (paymentType) => {
  if (paymentType === 2) {
    // Multibanco TPA
    return paymentMethodsMapping.atm.id;
  }

  return paymentType;
};

const processBalancePaymentsDetails = (response) => {
  return {
    paymentMethodId: getPaymentType(response.paymentType.id),
    amount: response.amount,
    bankTransference: {
      iban: getEntityConfiguration(entityConfigurationsMapping.iban),
    },
    atm: {
      entity: response.bankEntityNumber,
      reference: response.reference,
    },
    deadline: response.deadline,
  };
};

export const getAmountColor = (completed, amount) => {
  if (!completed) {
    return "warning";
  }

  return amount > 0 ? "success" : "danger";
};

export const getBalanceHistory = async (
  entityId,
  authHash,
  params,
  cancelToken = null
) => {
  try {
    const response = await driversDataSource.getBalanceHistory(
      entityId,
      authHash,
      {
        ...params,
        fillCollections: "all",
      },
      false,
      cancelToken
    );

    return {
      items: processBalanceHistory(response.items),
      totalPages: response.totalPages,
    };
  } catch (error) {
    if (hasCanceledError(error)) {
      return null;
    }

    errorMessage(
      error,
      i18n.t("8398") /* Ocorreu um erro ao obter os movimentos de saldo */
    );

    return {
      items: [],
      totalPages: 0,
    };
  }
};

export const getPaymentsHistory = async (
  entityId,
  authHash,
  params,
  cancelToken = null
) => {
  try {
    const response = await driversDataSource.gePaymentsHistory(
      entityId,
      authHash,
      {
        ...params,
        fillCollections: "all",
      },
      false,
      cancelToken
    );

    return {
      items: processPaymentsHistory(response.items),
      totalPages: response.totalPages,
    };
  } catch (error) {
    if (hasCanceledError(error)) {
      return null;
    }

    errorMessage(
      error,
      i18n.t("8401") /* Ocorreu um erro ao obter os outros pagamentos */
    );

    return {
      items: [],
      totalPages: 0,
    };
  }
};

const getBalancePaymentDetails = async (entityId, authHash, balancePaymentId, params) => {
  try {
    const response = await driversDataSource.getBalancePaymentDetails(
      entityId,
      authHash,
      balancePaymentId,
      {
        ...params,
        fillCollections: "all",
      }
    );

    return processBalancePaymentsDetails(response);
  } catch (error) {
    errorMessage(
      error,
      i18n.t("8403") /* Ocorreu um erro ao obter os detalhes do movimento */
    );

    return null;
  }
};

export const getBalancePaymentMethods = async (
  entityId,
  params,
  { useCache = false } = {}
) => {
  try {
    const response = await paymentsDataSource.getBalancePaymentMethods(
      entityId,
      {
        ...params,
        noLimit: true,
      },
      useCache
    );

    return processPaymentMethods(response.items);
  } catch (error) {
    errorMessage(
      error,
      i18n.t("8408") /* Ocorreu um erro ao obter os métodos de carregamento de saldo */
    );

    return null;
  }
};

export const getPendingBalanceChargeInfo = async (
  entityId,
  driverId,
  balancePaymentId
) => {
  const [balancePaymentDetails, balancePaymentMethods] = await Promise.all([
    getBalancePaymentDetails(entityId, driverId, balancePaymentId),
    getBalancePaymentMethods(entityId, {}, { useCache: true }),
  ]);

  if (!balancePaymentDetails || !balancePaymentMethods) {
    return null;
  }

  const balancePaymentDetailsCode = getPaymentCodeById(
    balancePaymentDetails.paymentMethodId
  );

  for (let index = 0; index < balancePaymentMethods.length; index += 1) {
    const balancePaymentMethod = balancePaymentMethods[index];

    if (balancePaymentMethod.code === balancePaymentDetailsCode) {
      return { ...balancePaymentDetails, paymentMethod: balancePaymentMethod };
    }
  }

  return balancePaymentDetails;
};

export const getVehicles = async (authHash, cancelToken) => {
  try {
    const vehicles = await driversDataSource.getVehicles(
      authHash,
      { noLimit: true },
      {},
      true,
      cancelToken
    );

    return getVehiclesColorsMap(vehicles.items);
  } catch {
    return null;
  }
};
