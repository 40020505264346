import ConditionalWrapper from "components/ConditionalWrapper";
import FlexBox from "components/FlexBox";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import CardCell from "./Cell";

const valueAligns = {
  right: "right",
};

const LABEL_VALUE_CARD_LABEL_VARIANT = "body4";
export const LABEL_VALUE_CARD_VALUE_VARIANT = "body3";

const LabelValueCardCell = ({ label, value, valueAlign, ...other }) => {
  return (
    <CardCell {...other}>
      <ConditionalWrapper
        condition={valueAlign === valueAligns.right}
        wrapper={<FlexBox flexDirection="column" />}
      >
        {label && <Text variant={LABEL_VALUE_CARD_LABEL_VARIANT}>{label}</Text>}
        <ValueText align={valueAlign} variant={LABEL_VALUE_CARD_VALUE_VARIANT}>
          {value}
        </ValueText>
      </ConditionalWrapper>
    </CardCell>
  );
};

export default LabelValueCardCell;

LabelValueCardCell.defaultProps = {
  valueAlign: undefined,
  label: undefined,
};

LabelValueCardCell.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node.isRequired,
  valueAlign: PropTypes.oneOf(Object.values(valueAligns)),
};

const ValueText = styled(Text)`
  ${({ align }) =>
    align === valueAligns.right
      ? css`
          display: flex;
          margin-left: auto;
        `
      : ""}
`;
