import i18n from "i18n";
import AttachmentsDataSource from "lib/clients/iParque/dataSources/attachments";
import { errorMessage } from "utils/userMessages";

const attachmentsDataSource = new AttachmentsDataSource();

export const createAttachment = async (
  entityId,
  file,
  data,
  { autoCloseError = true } = {}
) => {
  try {
    const response = await attachmentsDataSource.create(entityId, file, data);

    return { attachmentId: response.id };
  } catch (error) {
    errorMessage(error, i18n.t("8309") /* Ocorreu um erro ao guardar o anexo */, null, {
      autoClose: autoCloseError,
    });
    return null;
  }
};
