// eslint-disable-next-line import/prefer-default-export
export const zeroPad = (number, length) => {
  let numberStr = `${number}`;

  while (numberStr.length < length) {
    numberStr = `0${numberStr}`;
  }

  return numberStr;
};
