import { useState, useCallback } from "react";
import useDebounce from "./useDebounce";

const useSearchField = () => {
  const [search, setSearch] = useState();
  const debounceSearch = useDebounce(search, 500);

  const onSearchChange = useCallback((event) => setSearch(event.target.value), []);

  return { search, debounceSearch, onSearchChange };
};

export default useSearchField;
