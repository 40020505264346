import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Bar = styled.span`
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background-color: ${({ theme }) => theme.background.color.primary};
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
`;

const MenuContainer = styled.div`
  width: 30px;
  height: 29px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  #menu-first-bar {
    top: 0px;
    transform-origin: left center;
  }

  #menu-second-bar {
    top: 12px;
    transform-origin: left center;
  }

  #menu-third-bar {
    top: 24px;
    transform-origin: left center;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
      #menu-first-bar {
        transform: rotate(45deg);
        top: 2px;
        left: 4px;
      }

      #menu-second-bar {
        width: 0;
        opacity: 0;
      }

      #menu-third-bar {
        transform: rotate(-45deg);
        top: 23px;
        left: 4px;
      }
  `}
`;

const Menu = (props) => {
  const { setIsOpenCallback, isOpen } = props;

  return (
    <MenuContainer isOpen={isOpen} onClick={setIsOpenCallback} id="nav-icon4">
      <Bar id="menu-first-bar" />
      <Bar id="menu-second-bar" />
      <Bar id="menu-third-bar" />
    </MenuContainer>
  );
};

Menu.propTypes = {
  setIsOpenCallback: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default Menu;
