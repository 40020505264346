import Alert from "components/Alert";
import React from "react";
import { useTranslation } from "react-i18next";

const SendToAnalysisWarning = () => {
  const { t } = useTranslation();

  return (
    <Alert
      variant="warning"
      title={
        t(
          "10568"
        ) /* Para dar seguimento ao processo deverás enviar o dístico para análise. */
      }
    />
  );
};

export default SendToAnalysisWarning;
