import PropTypes from "prop-types";

export const paymentBoxPropTypes = {
  paymentMethod: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    longMessage: PropTypes.string.isRequired,
    shortMessage: PropTypes.string,
    minAmount: PropTypes.number.isRequired,
  }).isRequired,
  initialFlowCallback: PropTypes.func.isRequired,
};
