import React from "react";
import { useTranslation } from "react-i18next";
import AnimationPlaceholder from "../components/AnimationPlaceholder";
import PageError from "../components/PageError";

const Animation = React.lazy(() => import("./Animation"));

const Page403 = () => {
  const { t } = useTranslation();

  return (
    <PageError
      animation={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <React.Suspense fallback={<AnimationPlaceholder />}>
          <Animation />
        </React.Suspense>
      }
      title={t("7844") /* Ups! */}
      subtitle={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <>
          {t("8901") /* Passagem interdita! */}
          <br />
          {t("8903") /* Sem autorização prévia. */}
        </>
      }
      text={
        t("9676") /* Desculpa, não tens autorização para aceder ao lugar solicitado. */
      }
    />
  );
};

export default Page403;
