import FlexBox from "components/FlexBox";
import Text from "components/Text";
import useClickOutside from "hooks/useClickOutside";
import { Button, Heading, Input } from "iparque-components";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  defaultAnimationDelay,
  defaultAnimationDuration,
  getAppearFromLeft,
} from "themes/animations/animations";
import Animation from "./Animation";
import Arrow from "./Arrow";
import StartPaymentButton from "./StartPaymentButton";
import useNoticePayment from "./hooks/useNoticePayment";

export const NOTICE_PAYMENT_BOX_HEIGHT = 320;

const ANIMATION_TIME = "0.3s";
const PADDING = "20px";

const PayNotice = () => {
  const { t } = useTranslation();
  const {
    displayPaymentBox,
    setDisplayPaymentBox,
    noticeCode,
    setNoticeCode,
    isPaid,
    resetState,
    creditCardCharge,
    isMinimized,
    toggleMinimize,
  } = useNoticePayment();
  const boxRef = useRef(null);

  useClickOutside(
    boxRef,
    useCallback(() => setDisplayPaymentBox(false), [])
  );

  const displayPaymentForm = () => {
    if (displayPaymentBox || isPaid) {
      return;
    }

    setDisplayPaymentBox(true);
  };

  return (
    <Box ref={boxRef} isMinimized={isMinimized} isOpen={displayPaymentBox}>
      <FixedContentContainer>
        <FlexBox justifyContent="spaceBetween" alignItems="center">
          <Heading elementType="h2" variant="h5" color="secondary">
            {(() => {
              if (displayPaymentBox) {
                return t(
                  "11812"
                ); /* Beep Beep! Queres pagar de forma rápida a tua infração? */
              }

              return isPaid
                ? t("11821") /* Pagamento concluído! */
                : t("7843"); /* Pagar infração! */
            })()}
          </Heading>
          {!displayPaymentBox && (
            <Arrow
              onClick={toggleMinimize}
              animationTime={ANIMATION_TIME}
              isMinimized={isMinimized}
            />
          )}
        </FlexBox>
        <StyledHeading variant="h6" color="secondary" className="mt-20">
          {
            isPaid
              ? t("11822") /* A tua infração foi paga. */
              : t("11813") /* É tão fácil que nem precisas de criar conta! */
          }
        </StyledHeading>
      </FixedContentContainer>
      <BodyContainer onClick={displayPaymentForm}>
        <Animation isPaid={isPaid} />
        <FlexBox
          className={displayPaymentBox ? "not-displayed" : ""}
          alignItems="flexEnd"
          justifyContent="spaceBetween"
        >
          <StartPaymentButton
            isPaid={isPaid}
            resetState={resetState}
            displayPaymentForm={displayPaymentForm}
          />
        </FlexBox>
        <FormContainer className={!displayPaymentBox ? "not-displayed" : ""}>
          <FlexBox>
            <Input
              value={noticeCode}
              onChange={(event) => setNoticeCode(event.target.value)}
              type="number"
              name="noticeCode"
              placeholder={t("209") /* Código da Infração */}
              className="mr-15 fill"
              displayNativeControls={false}
            />
            <Button
              onClick={() => {
                creditCardCharge(noticeCode);
              }}
              color="secondary"
              className="mr-10"
            >
              {t("4537") /* Pagar */}
            </Button>
          </FlexBox>
          <Text variant="body5" color="secondary">
            {
              t(
                "11820"
              ) /* Para pagar serás redirecionado para a PayPay, um serviço de pagamentos online. */
            }
          </Text>
        </FormContainer>
      </BodyContainer>
    </Box>
  );
};

export default PayNotice;

const Box = styled.div`
  max-height: ${({ isMinimized }) => (isMinimized ? 40 : NOTICE_PAYMENT_BOX_HEIGHT)}px;
  z-index: 2;
  position: fixed;
  overflow: hidden;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.background.color.highlightBox};
  width: ${({ isOpen }) => (isOpen ? "600px" : "350px")};
  transition: width, max-height ${ANIMATION_TIME} ease-in-out;
  animation: ${getAppearFromLeft()} ${defaultAnimationDuration}s;
  animation-delay: ${defaultAnimationDelay}s;
  animation-fill-mode: both;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  padding: ${PADDING};
`;

const FixedContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 160px;
`;

const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  svg {
    margin-top: 20px;
    max-height: 120px;
    flex-shrink: 0;
  }
`;

const FormContainer = styled.div`
  margin-left: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-sizing: border-box;
`;

const StyledHeading = styled(Heading)`
  line-height: 1.2em;
`;
