import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "iparque-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  padding: 5px 10px;
  border: 1px solid ${({ theme }) => theme.border.lightBackground.color};
  width: fit-content;
  border-radius: 20px;
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.border.lightBackground.color};
`;

const FeeNameBox = ({ name, className }) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <StyledText variant="body3">{`${t("6660") /* Tarifa */} ${name}`}</StyledText>
    </Container>
  );
};

FeeNameBox.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

FeeNameBox.defaultProps = {
  className: "",
};

export default FeeNameBox;
