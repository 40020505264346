import CancelButton from "components/buttons/CancelButton";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Popup from "./Popup";
import PopupButtonsContainer from "./PopupButtonsContainer";

const AlertPopUp = ({ title, message, onClose }) => {
  const { t } = useTranslation();

  return (
    <Popup title={title} onClose={onClose}>
      <Text variant="body8" className="inline">
        {message}
      </Text>
      <PopupButtonsContainer>
        <CancelButton onClick={onClose}>{t("3442") /* Fechar */}</CancelButton>
      </PopupButtonsContainer>
    </Popup>
  );
};

AlertPopUp.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AlertPopUp;
