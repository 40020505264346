import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Divider, Text } from "iparque-components";
import { ReactComponent as Logo } from "../../../assets/images/driverLogo.svg";
import Profile from "./Profile";
import MenuLinks from "./Links";
import Logout from "../components/Logout";
import MenuIcon from "../../../components/icons/Menu";
import NotificationsArea from "../notifications/NotificationsArea";
import { useNotificationsContext } from "../notifications/Provider";

const Container = styled.div`
  background-color: ${({ theme }) => theme.background.color.smallDevicesMenu};
  position: fixed;
  width: 100%;

  ${(props) =>
    props.isOpen &&
    `
      height: fill-available;
      overflow: auto;
    `};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const MenuWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const StyledLogo = styled(Logo)`
  height: 40px;
  width: 40px;
  fill: ${({ theme }) => theme.background.color.primary};
`;

const Wrapper = styled.div`
  padding: 15px 15px;
  position: relative;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const Menu = styled.div`
  display: ${(props) => (props.isOpen ? `flex` : `none`)};
  flex-direction: column;
  flex: 1;

  .spread-items {
    justify-content: space-between;
  }
`;

const SmallDevicesMenu = () => {
  const { t } = useTranslation();
  const {
    isOpen: isNotificationsAreaOpen,
    close: closeNotificationsArea,
  } = useNotificationsContext();
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    if (isNotificationsAreaOpen) {
      setIsOpen(false);
    }
  }, [isNotificationsAreaOpen]);

  const toggleOpen = () => {
    const newState = !isOpen;

    setIsOpen(newState);

    if (!isOpen) {
      closeNotificationsArea();
    }
  };

  return (
    <Container ref={containerRef} isOpen={isOpen}>
      <Wrapper>
        <Header>
          <StyledLogo />
          <MenuWrapper>
            <NotificationsArea className="mr-20" />
            <MenuIcon isOpen={isOpen} setIsOpenCallback={toggleOpen} />
          </MenuWrapper>
        </Header>
        <Menu isOpen={isOpen}>
          <Divider className="mt-40" />
          <Text color="secondary" variant="body5">
            {t("4849") /* Perfil */}
          </Text>
          <Profile toggleMenu={toggleOpen} />
          <Divider />
          <Text color="secondary" variant="body5">
            {t("7936") /* Gestão */}
          </Text>
          <MenuLinks spreadItems toggleMenu={toggleOpen} />
        </Menu>
        {isOpen && <Logout />}
      </Wrapper>
    </Container>
  );
};

export default SmallDevicesMenu;
