import FlexBox from "components/FlexBox";
import Skeleton from "components/Skeleton";
import VerticalTabs from "components/tabs/verticals/Tabs";
import ContentBorderBox from "domain/private/components/ContentBorderBox";
import ContentHeader from "domain/private/components/ContentHeader";
import ErrorCard from "domain/private/components/ErrorCard";
import { Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Operations from "../components/Operations";
import OperationsSkeleton from "../components/Operations/Skeleton";
import Warnings from "../components/Warnings";
import useBack from "../hooks/useBack";
import Title from "../large/Title";
import TitleSkeleton from "../large/Title/Skeleton";
import { usePermitHoldersDetailsContext } from "../Provider";
import useTabs from "./hooks/useTabs";

const PermitHolderDetailsMediumPage = () => {
  const { permit, initialRequestStatus, tabsRef } = usePermitHoldersDetailsContext();
  const { t } = useTranslation();
  const onBack = useBack();
  const { tabs, onTabChange } = useTabs();

  return (
    <>
      <ContentHeader
        topTitle={t("10572") /* Consulta aqui os detalhes do teu dístico */}
        bottomTitle={t("7998") /* Não deixes que nada te escape! */}
      />
      {initialRequestStatus.hasError ? (
        <ErrorCard className="mt-40" />
      ) : (
        <ContentBorderBox
          backCallback={onBack}
          className="mt-40"
          title={initialRequestStatus.isLoading ? <TitleSkeleton /> : <Title />}
        >
          <FlexBox className="mt-20" justifyContent="flexEnd">
            {initialRequestStatus.isLoading ? <OperationsSkeleton /> : <Operations />}
          </FlexBox>
          <Warnings className="mt-20" />
          {initialRequestStatus.isLoading ? (
            <Skeleton height={15} width={80} className="mt-30" />
          ) : (
            <Text variant="body7" className="mt-30">
              {permit.type.designation}
            </Text>
          )}
          <StyledVerticalTab
            ref={tabsRef}
            tabs={tabs}
            className="mt-10"
            tabWidth="280px"
            onTabChange={onTabChange}
          />
        </ContentBorderBox>
      )}
    </>
  );
};

export default PermitHolderDetailsMediumPage;

const StyledVerticalTab = styled(VerticalTabs)`
  .tab-panel {
    margin-left: 30px;
  }
`;
