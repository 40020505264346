import { usePermitHoldersListContext } from "../Provider";

const useRequirementsUtils = () => {
  const {
    requirements,
    unapprovedList: {
      items: unapprovedListItems,
      isRequested: unapprovedListIsRequested,
    },
    approvedList: { items: approvedListItems, isRequested: approvedListIsRequested },
  } = usePermitHoldersListContext();

  const meetsRequirements = !Object.values(requirements).every(
    (value) => value === false
  );

  const shouldDisplayRequirements =
    !meetsRequirements &&
    unapprovedListIsRequested &&
    unapprovedListItems.length === 0 &&
    approvedListIsRequested &&
    approvedListItems.length === 0;

  return { shouldDisplayRequirements };
};

export default useRequirementsUtils;
