import React, { useContext } from "react";
import VehiclesSmallDevices from "./smallDevices/VehiclesPage";
import VehiclesPage from "./VehiclesPage";
import { AppContext } from "../../../components/AppProvider";
import { smallDevicesBreakpoints } from "../../../utils/breakpoints";

const Page = () => {
  const { breakpoint } = useContext(AppContext);

  const VehiclesBasedOnDeviceSize = (() => {
    if (smallDevicesBreakpoints.includes(breakpoint)) {
      return <VehiclesSmallDevices />;
    }

    return <VehiclesPage />;
  })();

  return VehiclesBasedOnDeviceSize;
};

export default Page;
