/* eslint-disable react/jsx-props-no-spreading */
import Button from "components/buttons/Button";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5vh;
`;

const BottomButton = ({ children, ...other }) => {
  return (
    <>
      <Container>
        <Button {...other}>{children}</Button>
      </Container>
    </>
  );
};

BottomButton.propTypes = {
  children: PropTypes.string.isRequired,
};

export default BottomButton;
