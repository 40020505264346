export const actionTypes = {
  SET_REQUIREMENTS: "SET_REQUIREMENTS",
  SET_APPROVED_LIST: "SET_APPROVED_LIST",
  SET_UNAPPROVED_LIST: "SET_UNAPPROVED_LIST",
};

const setRequirements = (state, requirements) => ({ ...state, requirements });

const setApprovedList = (state, approvedList) => ({ ...state, approvedList });

const setUnApprovedList = (state, unapprovedList) => ({ ...state, unapprovedList });

const actions = {
  [actionTypes.SET_REQUIREMENTS]: setRequirements,
  [actionTypes.SET_APPROVED_LIST]: setApprovedList,
  [actionTypes.SET_UNAPPROVED_LIST]: setUnApprovedList,
};

export default actions;
