import { useAppContext } from "components/AppProvider";
import { useBackofficeContext } from "domain/private/components/BackOfficeProvider";
import NTPProvider from "lib/NTPProvider";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getMinutesBetweenTwoDates } from "utils/dateTime";
import { Platform, platforms } from "utils/platform";

const MINUTES_TOLERANCE = 1;

const useNTPDateTime = () => {
  const { setIsLoading } = useAppContext();
  const { displayConfirmation, displayAlert } = useBackofficeContext();
  const { t } = useTranslation();
  const [isResolved, setIsResolved] = useState(false);
  const ntpProvider = useMemo(() => new NTPProvider(), []);

  const onSuccessServerDateTime = useCallback(
    (date) => {
      const resolve = () => setIsResolved(true);

      if (Math.abs(getMinutesBetweenTwoDates(moment.utc(), date)) <= MINUTES_TOLERANCE) {
        setIsResolved(true);
        return;
      }

      const openDateTimeSettings = Platform.select({
        [platforms.windows]: () => {
          window.location.href = "ms-settings:";
        },
        [platforms.default]: undefined,
      });

      const popupConfig = {
        title: t("11080") /* Sincronizar relógio */,
        message: t(
          "10984"
        ) /* A hora do teu dispositivo não está de acordo com a hora automática, o que poderá levar a erros de cálculo no tempo de estacionamento. Para prosseguir, por favor, verifica a situação nas definições do teu dispositivo. */,
      };

      if (openDateTimeSettings) {
        const onConfirm = () => {
          openDateTimeSettings();
          resolve();
        };

        displayConfirmation({
          ...popupConfig,
          onConfirm,
          onCancel: resolve,
        });
      } else {
        displayAlert({
          ...popupConfig,
          onClose: resolve,
        });
      }
    },
    [displayConfirmation, t]
  );

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const date = await ntpProvider.getDateTime();

        setIsLoading(false);

        if (!date) {
          return;
        }

        onSuccessServerDateTime(date);
        // eslint-disable-next-line no-empty
      } catch {}
    })();
  }, [onSuccessServerDateTime, ntpProvider]);

  return { isCorrectDateTimeChecked: isResolved };
};

export default useNTPDateTime;
