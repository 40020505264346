import React, { Suspense, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { Heading, Text } from "iparque-components";
import HighlightedPoint from "../../components/HighlightedPoint";
import { breakpoints, breakpointsMapping } from "../../../../utils/breakpoints";
import useOnScreen from "../../../../hooks/useOnScreen";

const DriverStreet = React.lazy(() => import("./DriverStreet"));

const intersectionOptions = { unobserveOnIntersection: true };
const allowedBreakpointsToDisplayMainStreet = [breakpointsMapping.xl, null];
const allowedBreakpointsToDisplayParkingInfo = [
  breakpointsMapping.xl,
  breakpointsMapping.lg,
  breakpointsMapping.md,
  breakpointsMapping.noBreakpoint,
];

const ParkingInfo = ({ breakpoint }) => {
  const { t } = useTranslation();
  const containerRef = useRef();
  const isOnScreen = useOnScreen(containerRef, intersectionOptions);

  if (!allowedBreakpointsToDisplayParkingInfo.includes(breakpoint)) {
    return null;
  }

  return (
    <Container isOnScreen={isOnScreen} ref={containerRef}>
      <Box>
        <ParkingInfoContainer>
          <HeadingWrapper className="appear-from-left">
            <Heading elementType="h2" variant="h1">
              {t("8514") /* Paga o estacionamento à distância! */}
            </Heading>
          </HeadingWrapper>
          <ParkingTextContainer className="appear-from-left">
            <InlineText variant="body4">
              {
                t(
                  "8507"
                ) /* Gostamos de simplificar o dia a dia dos nossos condutores.
                    E é por isso que com a nossa aplicação fica mais fácil e conveniente pagar
                    o estacionamento. Sem trocos ou deslocações ao parquímetro. */
              }
              <br />
              <br />
              {
                t(
                  "8508"
                ) /* Ainda assim, com o iParque Driver podes ir mais longe. Esta é
                    a solução sustentável e */
              }
              &nbsp;
              <Text variant="body4" italic>
                {t("8509") /* eco-friendly */}
              </Text>
              &nbsp;
              {
                t(
                  "8510"
                ) /* que precisas para fazer toda a gestão dos teus estacionamentos.
                    Paga infrações, encontra o teu lugar, consulta o teu histórico, gere a informação
                    do teu veículo. */
              }
            </InlineText>
            <br />
            <br />
            <Text elementType="strong" variant="body1">
              {
                t(
                  "7837"
                ) /* Vais descobrir tudo isto e muito mais ao embarcar nesta viagem connosco. */
              }
            </Text>
          </ParkingTextContainer>
          <HighlightedPoint className="mt-40">
            {t("7838") /* Deixa-te de trocos! */}
          </HighlightedPoint>
        </ParkingInfoContainer>
      </Box>
      {allowedBreakpointsToDisplayMainStreet.includes(breakpoint) && (
        <ImageContainer className="appear-from-right">
          <Suspense fallback={<div />}>
            <DriverStreet
              isAnimationContainerOnScreen={isOnScreen}
              role="img"
              alt={
                t(
                  "10656"
                ) /* Estacionamento pago através da app, sem moedas ou deslocação ao parquímetro */
              }
              title={
                t(
                  "10656"
                ) /* Estacionamento pago através da app, sem moedas ou deslocação ao parquímetro */
              }
            />
          </Suspense>
        </ImageContainer>
      )}
    </Container>
  );
};

ParkingInfo.defaultProps = {
  breakpoint: null,
};

ParkingInfo.propTypes = {
  breakpoint: PropTypes.string,
};

const Container = styled.div`
  display: inline-flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 200px;
  background-color: ${({ theme }) => theme.background.color.primary};

  .appear-from-left {
    transition: opacity 1s 1s, transform 1s 1s;

    ${({ isOnScreen }) =>
      isOnScreen
        ? css`
            opacity: 1;
            transform: translateX(0);
          `
        : css`
            opacity: 0;
            transform: translateX(-20%);
          `}
  }

  .appear-from-right {
    transition: opacity 1s 1s, transform 1s 1s;

    ${({ isOnScreen }) =>
      isOnScreen
        ? css`
            opacity: 1;
            transform: translateX(0);
          `
        : css`
            opacity: 0;
            transform: translateX(50%);
          `}
  }

  @media (max-width: ${breakpoints.lg}) {
    padding-bottom: 0;
  }

  @media (max-width: ${breakpoints.md}) {
    padding-bottom: 300px;
  }
`;

const Box = styled.div`
  width: 60vw;
  height: 60vw;
  min-height: 800px;
  padding: 75px;
  background-color: ${({ theme }) => theme.background.color.tertiary};

  @media (min-width: ${breakpoints.xl}) {
    width: 55vw;
    height: 55vw;
  }

  @media (max-width: ${breakpoints.lg}) {
    width: auto;
    height: fit-content;
    padding: 60px;
  }

  @media (max-width: ${breakpoints.md}) {
    width: 84%;
    height: fit-content;
    padding: 8%;
  }
`;

const ParkingInfoContainer = styled.div`
  height: 100%;
  width: 70%;
  padding: 0 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.background.color.primary};

  @media (max-width: ${breakpoints.lg}) {
    padding: 150px 10%;
    width: 80%;
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 80px 10%;
    width: 80%;
  }
`;

const HeadingWrapper = styled.div`
  line-height: 65px;
  margin-bottom: 20px;
`;

const ParkingTextContainer = styled.div`
  display: inline;
`;

const ImageContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 300px;
`;

const InlineText = styled(Text)`
  display: inline;
`;

export default ParkingInfo;
