import { Text as GeneralText } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

const Text = ({ numberOfLines, ...other }) => {
  return <StyledText numberOfLines={numberOfLines} {...other} />;
};

Text.propTypes = {
  numberOfLines: PropTypes.number,
};

Text.defaultProps = {
  numberOfLines: undefined,
};

export default Text;

const StyledText = styled(GeneralText)`
  ${({ numberOfLines }) =>
    numberOfLines
      ? css`
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @supports (-webkit-line-clamp: ${numberOfLines}) {
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: ${numberOfLines};
            -webkit-box-orient: vertical;
          }
        `
      : ""}
`;
