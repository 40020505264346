import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import InputFileButton from "components/InputFileButton";
import { Link, Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import styled, { useTheme } from "styled-components";
import { breakpoints } from "utils/breakpoints";
import { usePermitHoldersContext } from "../../Provider";
import { allowedFileExtensions } from "../../../helpers/documents";

const Card = ({
  designation,
  id,
  isRequired,
  isValid,
  isStepTouched,
  onSubmitDocument,
  onRemoveDocument,
}) => {
  const theme = useTheme();
  const { documents } = usePermitHoldersContext();
  const doc = documents[id];

  return (
    <Container hasError={isStepTouched && !isValid}>
      {doc ? (
        <FilledInputContainer>
          <div>
            <Text variant="body1" color="quintenary">
              {designation}
              {isRequired ? " *" : ""}
            </Text>
            <Text variant="body6" color="quintenary">
              <Link
                underline="always"
                target="_blank"
                href={URL.createObjectURL(doc.file)}
              >
                {doc.file.name}
              </Link>
            </Text>
          </div>
          <CloseIcon
            width={18}
            className="clickable-icon"
            fill={theme.color.danger}
            onClick={() => onRemoveDocument(id)}
          />
        </FilledInputContainer>
      ) : (
        <EmptyInputContainer>
          <Text variant="body1" color="quintenary">
            {designation}
            {isRequired ? " *" : ""}
          </Text>
          <InputFileButton
            className="input-file-button"
            allowedFormats={allowedFileExtensions}
            name="file"
            onChange={(file) => onSubmitDocument({ id, designation, file })}
          />
        </EmptyInputContainer>
      )}
    </Container>
  );
};

Card.propTypes = {
  designation: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isRequired: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  onSubmitDocument: PropTypes.func.isRequired,
  onRemoveDocument: PropTypes.func.isRequired,
  isStepTouched: PropTypes.bool.isRequired,
};

export default Card;

const Container = styled.div`
  border: 2px solid
    ${({ theme, hasError }) =>
      hasError ? theme.color.danger : theme.card.outlined.primary.borderColor};
  padding: 15px;

  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;

const FilledInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmptyInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    align-items: flex-start;

    .input-file-button {
      margin-top: 20px;
      margin-left: auto;
    }
  }
`;
