import { useStepsContext } from "domain/public/components/StepsProvider";
import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { getIdentificationData } from "requests/driversIdentifications";
import { partyTypes } from "utils/clients";
import { identificationTypes } from "utils/driversIdentifications";
import { STEPS } from "./Steps/helper";
import { actionTypes } from "./store/actions";
import reducer from "./store/reducer";

const initialState = {
  isIdentificationDataLoading: true,
  areTheTermsAndConditionsAccepted: false,
  identificationData: {
    entity: "",
    notice: "",
    typeId: "",
  },
  personalData: {
    partyTypeId: undefined,
    name: "",
    countryId: undefined,
    genderId: undefined,
    taxpayerIdentificationNumber: "",
    commercialName: "",
    economicActivityCode: undefined,
    address: "",
    complementaryAddress: "",
    zipCode: "",
    locality: "",
    email: "",
    phone: undefined,
    mobilePhone: undefined,
  },
  documents: {
    citizenCard: {
      countryId: undefined,
      number: "",
      issuingEntity: "",
      issuingDate: undefined,
    },
    drivingLicence: {
      countryId: undefined,
      prefix: undefined,
      number: "",
      issuingEntity: "",
      issuingDate: undefined,
      categories: [],
      restrictions: "",
    },
    contract: undefined,
  },
};

const DriverIdentificationContext = createContext(initialState);

const DriverIdentificationProvider = ({ children }) => {
  const { t } = useTranslation();
  const { identificationToken } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  const { goToStep } = useStepsContext();

  useEffect(() => {
    const onBeforeUnload = (event) => {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => window.removeEventListener("beforeunload", onBeforeUnload);
  }, []);

  useEffect(() => {
    async function fetchRentalIdentificationData() {
      const identificationData = await getIdentificationData(
        identificationToken,
        {
          fillCollections: "notice,entity",
        },
        { t }
      );

      dispatch({
        type: actionTypes.SET_IDENTIFICATION_DATA,
        payload: {
          isIdentificationDataLoading: false,
          identificationData,
        },
      });

      dispatch({
        type: actionTypes.SET_PERSONAL_DATA,
        payload: {
          partyTypeId: partyTypes.person,
          countryId: identificationData.entity?.countryId,
        },
      });

      if (location?.state?.areTheTermsAndConditionsAccepted) {
        dispatch({
          type: actionTypes.SET_TERMS_AND_CONDITIONS_ACCEPTED,
          payload: location.state.areTheTermsAndConditionsAccepted,
        });
      }

      if (
        identificationData?.typeId === identificationTypes.rentalVehicleDriver ||
        location?.state?.areTheTermsAndConditionsAccepted
      ) {
        goToStep(STEPS.personalData);
      } else if (identificationData?.typeId === identificationTypes.voluntary) {
        goToStep(STEPS.informations);
      }
    }

    if (identificationToken) {
      fetchRentalIdentificationData();
    }
  }, []);

  return (
    <DriverIdentificationContext.Provider value={{ dispatch, ...state }}>
      {children}
    </DriverIdentificationContext.Provider>
  );
};

export default DriverIdentificationProvider;

DriverIdentificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useDriverIdentificationContext = () => {
  return useContext(DriverIdentificationContext);
};
