export const actionsMapping = {
  setBalanceChargeMethods: "SET_BALANCE_CHARGE_METHODS",
};

const actions = {
  [actionsMapping.setBalanceChargeMethods]: (state, balanceChargeMethods) => {
    return {
      ...state,
      balanceChargeMethods,
    };
  },
};

export default actions;
