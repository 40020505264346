import React from "react";
import { Trans } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";
import { formatDate, getTimeFromDateTime } from "utils/dateTime";
import { Text } from "iparque-components";

const Header = ({ licencePlate, datetime }) => {
  if (!licencePlate || !datetime) {
    return null;
  }

  return (
    <StyledText color="quintenary" variant="body5">
      <Trans
        i18nKey="10334"
        values={{
          licencePlate: `<b>${licencePlate}</b>`,
          date: `<b>${formatDate(datetime)}</b>`,
          hour: `<b>${getTimeFromDateTime(datetime)}</b>`,
          interpolation: { escapeValue: false },
        }}
        components={{ b: <b /> }}
      />
      {/* Foi registado um aviso para o veículo {{licencePlate}} no dia {{day}} pelas {{hour}}. */}
    </StyledText>
  );
};

Header.propTypes = {
  licencePlate: PropTypes.string,
  datetime: PropTypes.string,
};

Header.defaultProps = {
  licencePlate: "",
  datetime: "",
};

export default Header;

const StyledText = styled(Text)`
  display: block;
  font-family: ${({ theme }) => theme.typography.font.primary};
`;
