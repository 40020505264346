import Button from "components/buttons/Button";
import Text from "components/Text";
import { Heading } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAuth } from "../../../../components/AuthProvider";
import { formatMoney } from "../../../../utils/money";
import useAccountMovements from "../hooks/useAccountMovements";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CurrentBalance = () => {
  const { user } = useAuth();
  const { charge } = useAccountMovements();
  const { t } = useTranslation();
  const userAmount = user.driver?.defaultEntity?.balance?.amount;
  const hasBalance = userAmount > 0;

  return (
    <Container>
      {!hasBalance && (
        <>
          <Heading className="mt-20" color="tertiary" variant="h5" align="center">
            {t("9650") /* Estás com a luz da reserva acesa! */}
          </Heading>
          <Text color="primary" variant="body6" className="mt-10" align="center">
            {t("10844") /* Vamos abastecer o saldo? */}
          </Text>
        </>
      )}
      <Heading className="mt-20" color={hasBalance ? "tertiary" : "danger"}>
        {formatMoney(userAmount, { centsFormat: false })}
      </Heading>
      <Button onClick={charge} className="mt-10">
        {t("8457") /* Carregar */}
      </Button>
    </Container>
  );
};

export default CurrentBalance;
