import { useCallback, useEffect, useRef } from "react";

const useIntersectionObserver = (element, callback, options = {}) => {
  const intersectionObserver = useRef();

  const unobserve = useCallback(() => intersectionObserver.current?.disconnect(), []);

  const intersectionObserverCallback = useCallback(
    (entries) => {
      const { isIntersecting } = entries[0];

      callback(isIntersecting);

      if (isIntersecting && options.unobserveOnIntersection) {
        unobserve();
      }
    },
    [callback, options.unobserveOnIntersection, unobserve]
  );

  useEffect(() => {
    const divElement = element.current;

    if (!divElement) {
      return undefined;
    }

    intersectionObserver.current = new IntersectionObserver(
      intersectionObserverCallback,
      options
    );

    intersectionObserver.current.observe(divElement);

    return unobserve;
  }, [element, intersectionObserverCallback, options, unobserve]);
};

export default useIntersectionObserver;
