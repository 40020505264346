import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { breakpoints } from "utils/breakpoints";

const ContentContainer = ({ className = "", children }) => {
  return <Container className={className}>{children}</Container>;
};

ContentContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

ContentContainer.defaultProps = {
  className: "",
  children: null,
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  max-width: 1344px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${breakpoints.lg}) {
    width: 80%;
  }

  @media (max-width: ${breakpoints.sm}) {
    width: 90%;
  }
`;

export default ContentContainer;
