import { ReactComponent as CarIcon } from "assets/icons/car.svg";
import Button from "components/buttons/Button";
import { Heading, Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { paymentMethodsMapping } from "utils/payment";
import { convertDateTimeToReadableDate } from "../../../../utils/dateTime";
import { feeTypesMapping } from "../../../../utils/fees";
import { buildQueryString } from "../../../../utils/general";
import { parkingTypesMapping } from "../../../../utils/parking";
import { routesMapping } from "../../../../utils/routes";
import ListCard from "../../components/cardList/Card";
import ParkingEndDate from "../../components/parkings/EndDate";
import ParkingTimer from "../../components/parkings/Timer";
import TimerAdditionalInfo from "../../components/parkings/TimerAdditionalInfo";
import { useBackofficeThemeContext } from "../../components/ThemeProvider";
import useTimerInfo from "../../hooks/useTimerInfo";
import CommonExtraInformation from "../components/CommonExtraInfo";
import { activeParkingProps } from "../propTypes";

const ActiveParkingBox = ({
  id,
  licensePlate,
  vehicleColorId,
  paymentMethodId,
  parkingTypeId,
  streetName,
  streetId,
  explorationName,
  amount,
  vehicleBrand,
  startingDate,
  endingDate,
  scheduleExtraInfo,
  feeTypeId,
  updateCallback,
  benefit,
  location,
}) => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();
  const history = useHistory();
  const timerInfo = useTimerInfo(id, { endCallback: () => updateCallback(false) });

  const vehicleColor = backofficeTheme.carColor[vehicleColorId];
  const isMinutesFee = feeTypeId === feeTypesMapping.minutes;
  const paramsQuery = buildQueryString({
    amount,
    vehicleColor,
    streetName,
    streetId,
    paymentMethodId,
    explorationName,
    vehicleLicensePlate: licensePlate,
    vehicleBrand,
    feeTypeId,
  });

  const isMBWayPayment = paymentMethodId === paymentMethodsMapping.mbway.id;

  const renderExtraInfoCallback = ({ isOpen }) => (
    <DetailsContainer>
      <div className="mt-40">
        <Text variant="body1" color="primary">
          {streetName}
        </Text>
        <Text variant="body4" color="primary">
          {explorationName}
        </Text>
      </div>
      <div className="mt-20">
        <Text variant="body4" color="primary">
          {t("615") /* Montante a pagar */}
        </Text>
        <Heading color="tertiary" variant="h2">
          {amount}
        </Heading>
      </div>
      <ParkingEndDate
        parkingTypeId={parkingTypeId}
        className="mt-20"
        endingDate={endingDate}
      />
      <CommonExtraInformation
        id={id}
        benefit={benefit}
        location={location}
        isOpen={isOpen}
      />
      <Action>
        {parkingTypeId !== parkingTypesMapping.startAndStop && (
          <Button
            size="xs"
            className="parking-action-buttons"
            color="info"
            onClick={() => {
              history.push(
                `${routesMapping.backofficeParking}/${id}/renew?${paramsQuery}`
              );
            }}
          >
            {t("46") /* Renovar */}
          </Button>
        )}
        {isMinutesFee && !isMBWayPayment && (
          <Button
            size="xs"
            className="parking-action-buttons mt-10"
            color="danger"
            onClick={() => {
              history.push(
                `${routesMapping.backofficeParking}/${id}/stop?${paramsQuery}`
              );
            }}
          >
            {t("3216") /* Parar */}
          </Button>
        )}
      </Action>
    </DetailsContainer>
  );

  return (
    <ListCard renderExtraInfoCallback={renderExtraInfoCallback}>
      <CarIcon width="60px" height="60px" fill={vehicleColor} />
      <ParkingInfo>
        <StyledHeading colorId={vehicleColorId} variant="h4">
          {licensePlate}
        </StyledHeading>
        <Text variant="body10" className="mb-10">
          {convertDateTimeToReadableDate(startingDate)}
        </Text>
        <ParkingTimer
          parkingId={id}
          parkingTypeId={parkingTypeId}
          startingDate={startingDate}
          endingDate={endingDate}
          scheduleExtraInfo={scheduleExtraInfo}
          size="md"
        />
        <TimerAdditionalInfo
          state={timerInfo?.state}
          nextStart={timerInfo?.nextStart}
          variant="body11"
        />
      </ParkingInfo>
    </ListCard>
  );
};

export default React.memo(ActiveParkingBox);

ActiveParkingBox.propTypes = activeParkingProps;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ParkingInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 20px;
  line-height: 1;
`;

const Action = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .parking-action-buttons {
    width: 300px;
  }
`;

const StyledHeading = styled(Heading)`
  color: ${({ colorId, theme }) => theme.carColor[colorId]};
`;
