import React from "react";
import styled from "styled-components";
import { tabsIdsMap } from "../../../helper";
import { usePermitHoldersDetailsContext } from "../../../Provider";
import AddButton from "../../documents/AddButton";
import DocumentsList from "../../documents/List";
import TabTitle from "../../Title";

const DocumentsTab = () => {
  const { isEditable } = usePermitHoldersDetailsContext();

  return (
    <>
      <HeaderContainer>
        <TabTitle className="fill" tabId={tabsIdsMap.documents} />
        {isEditable && (
          <div>
            <AddButton size="xs" />
          </div>
        )}
      </HeaderContainer>
      <DocumentsList />
    </>
  );
};

export default DocumentsTab;

const HeaderContainer = styled.div`
  display: flex;
  height: 50px;
`;
