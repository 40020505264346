import { Link, Text } from "iparque-components";
import Template from "domain/public/entities/components/Template";
import { usePublicEntityContext } from "domain/public/entities/Provider";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import Error403 from "domain/public/entities/components/errors/403";
import { getIdentificationEvidence } from "requests/driversIdentifications";

const DriversIdentificationsEvidence = () => {
  const { isEntityDataLoading } = usePublicEntityContext();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { identificationToken } = useParams();
  const [evidenceUrl, setEvidenceUrl] = useState("");

  useEffect(() => {
    const getEvidenceUrl = async () => {
      const response = await getIdentificationEvidence(identificationToken, null, { t });

      if (response?.url) {
        setEvidenceUrl(response.url);
      }
    };

    if (state.name) {
      getEvidenceUrl();
    }
  }, [state]);

  if (!state.name) {
    return <Error403 />;
  }

  return (
    <Template
      isLoading={isEntityDataLoading}
      title={t("10239") /* Identificar o condutor do veículo */}
    >
      <div className="mt-30">
        <Body color="quintenary" variant="body5">
          {
            t("10739", {
              name: state.name,
            }) /* {{name}}, o teu dístico foi criado com sucesso. */
          }
        </Body>
        {evidenceUrl && (
          <Body color="quintenary" variant="body5">
            <span>
              {t("10303") /* Podes descarregar o comprovativo */}{" "}
              <Link underline="always" target="_blank" href={evidenceUrl}>
                {t("6515") /* aqui */}
              </Link>
              .
            </span>
          </Body>
        )}
      </div>
    </Template>
  );
};

export default DriversIdentificationsEvidence;

const Body = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.weight.bold};
`;
