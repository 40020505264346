import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { formatMoney } from "utils/money";

const PriceCard = ({ designation, price, className }) => {
  return (
    <Container className={className}>
      <Label color="quintenary" variant="body5">
        {designation}
      </Label>
      <Value color="quintenary" variant="body5">
        {formatMoney(price, { centsFormat: false })}
      </Value>
    </Container>
  );
};

export default PriceCard;

PriceCard.propTypes = {
  designation: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  className: PropTypes.string,
};

PriceCard.defaultProps = {
  className: "",
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.card.dense.primary.backgroundColor};
`;

const Label = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.weight.regular};
`;

const Value = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.weight.bold};
`;
