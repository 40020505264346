import React from "react";
import Skeleton from "../../../../../components/Skeleton";
import { Line, Cell } from "./Item";

const InlineDetailsListSkeleton = () => {
  return (
    <>
      <Line>
        <Cell>
          <Skeleton width={80} height={15} />
        </Cell>
        <Cell right>
          <Skeleton width={100} height={15} />
        </Cell>
      </Line>
      <Line>
        <Cell>
          <Skeleton width={60} height={15} />
        </Cell>
        <Cell right>
          <Skeleton width={80} height={15} />
        </Cell>
      </Line>
      <Line>
        <Cell>
          <Skeleton width={120} height={15} />
        </Cell>
        <Cell right>
          <Skeleton width={40} height={15} />
        </Cell>
      </Line>
    </>
  );
};

export default InlineDetailsListSkeleton;
