import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  compareServerDateTimes,
  getCurrentDateTimeInServerFormat,
  secondInMilliseconds,
} from "../../../../utils/dateTime";
import { customEvents } from "../../../../utils/events";
import { isEnded, isScheduled, parkingTypesMapping } from "../../../../utils/parking";
import Timer, { timerSizes } from "../Timer";

export const parkingTimerStates = {
  running: "running",
  paused: "paused",
  scheduled: "scheduled",
  ended: "ended",
};

const checkStateMilliseconds = secondInMilliseconds;

const calculateParkingState = (scheduleExtraInfo, startingDate, endingDate) => {
  if (!scheduleExtraInfo) {
    return null;
  }

  if (isScheduled(startingDate)) {
    return parkingTimerStates.scheduled;
  }

  if (isEnded(endingDate)) {
    return parkingTimerStates.ended;
  }

  const nextPause = scheduleExtraInfo?.nextPause || endingDate || null;
  const nextStart = scheduleExtraInfo?.nextStart || startingDate || null;

  if (!scheduleExtraInfo.isRunning && nextStart) {
    if (compareServerDateTimes(getCurrentDateTimeInServerFormat(), nextStart) > 0) {
      return parkingTimerStates.paused;
    }

    return parkingTimerStates.running;
  }

  if (scheduleExtraInfo.isRunning && nextPause) {
    if (compareServerDateTimes(getCurrentDateTimeInServerFormat(), nextPause) > 0) {
      return parkingTimerStates.running;
    }

    return parkingTimerStates.paused;
  }

  return scheduleExtraInfo.isRunning
    ? parkingTimerStates.running
    : parkingTimerStates.paused;
};

const ParkingTimer = ({
  parkingId,
  startingDate,
  endingDate,
  parkingTypeId,
  scheduleExtraInfo,
  onCountDownComplete,
  size,
}) => {
  const [state, setState] = useState(null);

  const calculateSeconds = () => {
    if (!scheduleExtraInfo) {
      return 0;
    }

    if (parkingTypeId === parkingTypesMapping.duration) {
      return scheduleExtraInfo.remainingDuration;
    }

    return scheduleExtraInfo.currentDuration;
  };

  useEffect(() => {
    const newState = calculateParkingState(scheduleExtraInfo, startingDate, endingDate);

    setState(newState);

    if (newState === parkingTimerStates.endingDate) {
      return null;
    }

    const updateInterval = setInterval(() => {
      const intervalNewState = calculateParkingState(
        scheduleExtraInfo,
        startingDate,
        endingDate
      );

      setState(intervalNewState);

      if (intervalNewState === parkingTimerStates.endingDate) {
        onCountDownComplete?.();
        clearInterval(updateInterval);
      }
    }, checkStateMilliseconds);

    return () => clearInterval(updateInterval);
  }, [scheduleExtraInfo, startingDate, endingDate]);

  useEffect(() => {
    if (state === null) {
      // not calculated yet
      return;
    }

    document.dispatchEvent(
      new CustomEvent(`${customEvents.timerStateChange}${parkingId}`, {
        detail: {
          state,
          endingDate,
          nextStart: scheduleExtraInfo.nextStart || startingDate,
        },
      })
    );
  }, [state]);

  const isTimerPaused = () => {
    return [
      parkingTimerStates.scheduled,
      parkingTimerStates.paused,
      parkingTimerStates.ended,
    ].includes(state);
  };

  return (
    <>
      <Timer
        size={size}
        paused={isTimerPaused()}
        seconds={calculateSeconds()}
        countDown={parkingTypeId && parkingTypeId === parkingTypesMapping.duration}
      />
    </>
  );
};

ParkingTimer.propTypes = {
  startingDate: PropTypes.string,
  parkingId: PropTypes.number.isRequired,
  endingDate: PropTypes.string,
  parkingTypeId: PropTypes.number,
  onCountDownComplete: PropTypes.func,
  size: PropTypes.oneOf(Object.values(timerSizes)),
  scheduleExtraInfo: PropTypes.shape({
    isRunning: PropTypes.bool,
    remainingDuration: PropTypes.number,
    currentDuration: PropTypes.number,
    nextPause: PropTypes.string,
    nextStart: PropTypes.string,
  }),
};

ParkingTimer.defaultProps = {
  parkingTypeId: null,
  startingDate: null,
  endingDate: null,
  scheduleExtraInfo: null,
  onCountDownComplete: undefined,
  size: undefined,
};

export default ParkingTimer;
