/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useTranslation } from "react-i18next";
import ContentHeader from "../components/ContentHeader";
import ContentBorderBox from "../components/ContentBorderBox";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import { parkingDefaultProps, parkingPropTypes } from "./propTypes";
import { getStepDescription } from "./controller";
import ParkingBottomSteps from "./ParkingBottomSteps";
import ParkingSteps from "./ParkingSteps";

const Parking = ({
  streets,
  candidateStreet,
  candidateStreetId,
  step,
  onBack,
  onlyParkWithActiveGeographicLocationActive,
  bottomStepsProps,
  stepsProps,
  selectedVehicle,
  selectedBenefit,
}) => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();
  const basePath = ".";

  return (
    <div>
      <ContentHeader
        topTitle={t("7830") /* Vamos estacionar? */}
        bottomTitle={t("7831") /* É fácil! */}
      />
      <ContentBorderBox
        backCallback={onBack}
        title={getStepDescription(step, {
          onlyParkWithActiveGeographicLocationActive,
        })}
        className="mt-40"
        cornerColor={backofficeTheme.color.primary}
      >
        <ParkingSteps
          basePath={basePath}
          step={step}
          candidateStreetId={candidateStreetId}
          selectedVehicle={selectedVehicle}
          selectedBenefit={selectedBenefit}
          {...stepsProps}
        />
      </ContentBorderBox>
      <ParkingBottomSteps
        basePath={basePath}
        step={step}
        candidateStreet={candidateStreet}
        streets={streets}
        selectedVehicle={selectedVehicle}
        selectedBenefitId={selectedBenefit.id}
        {...bottomStepsProps}
      />
    </div>
  );
};

Parking.defaultProps = parkingDefaultProps;

Parking.propTypes = parkingPropTypes;

export default React.memo(Parking);
