import React from "react";
import styled from "styled-components";
import { Heading, Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ReactComponent as CarIcon } from "../../../assets/icons/car.svg";
import { languageWithoutRegion } from "../../../utils/general";
import { formatDate } from "../../../utils/dateTime";
import { breakpoints } from "../../../utils/breakpoints";

const Container = styled.div`
  background: ${({ theme }) => theme.box.color};
  padding: 15px;
  display: flex;
  margin: 5px 0;
  cursor: pointer;
`;

const CarDataContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    align-items: initial;
  }
`;

const StyledHeading = styled(Heading)`
  color: ${({ textColor }) => textColor};
`;

const StyledText = styled(Text)`
  color: ${({ textColor }) => textColor};
`;

const CarInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const VehicleDatesContainer = styled.div`
  margin-left: auto;
  display: flex;

  .purchase-date-container {
    margin-right: 20px;
  }

  @media (max-width: ${breakpoints.md}) {
    margin-top: 5px;
    margin-left: 20px;
  }
`;

const SoldVehiclesBox = ({
  brand,
  licensePlate,
  color,
  purchaseDate,
  saleDate,
  onClick,
}) => {
  const { t, i18n } = useTranslation();
  const language = languageWithoutRegion(i18n.language);

  return (
    <Container onClick={onClick}>
      <CarIcon height="60px" width="60px" fill={color} />
      <CarDataContainer>
        <CarInfoContainer>
          <StyledHeading textColor={color} variant="h5">
            {licensePlate}
          </StyledHeading>
          <StyledText textColor={color} variant="body6">
            {brand}
          </StyledText>
        </CarInfoContainer>
        <VehicleDatesContainer>
          <div className="purchase-date-container">
            <Text variant="body9">{t("1863") /* Data de Compra */}</Text>
            <Text className="mt-5" variant="body10">
              {purchaseDate ? formatDate(purchaseDate, language) : "-"}
            </Text>
          </div>
          <div>
            <Text variant="body9">{t("6004") /* Data de Venda */}</Text>
            <Text className="mt-5" variant="body10">
              {formatDate(saleDate, language)}
            </Text>
          </div>
        </VehicleDatesContainer>
      </CarDataContainer>
    </Container>
  );
};

SoldVehiclesBox.defaultProps = {
  purchaseDate: null,
  onClick: () => {},
};

SoldVehiclesBox.propTypes = {
  color: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  licensePlate: PropTypes.string.isRequired,
  purchaseDate: PropTypes.string,
  saleDate: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default SoldVehiclesBox;
