import PropTypes from "prop-types";
import { parkingStatesMapping as animationStates } from "../components/ParkedCar";
import { parkingRenewalSteps } from "./controller";

const parkingPropTypes = {
  id: PropTypes.number,
  vehicleBrand: PropTypes.string,
  vehicleLicensePlate: PropTypes.string,
  explorationName: PropTypes.string,
  streetName: PropTypes.string,
  benefitName: PropTypes.string,
  benefitColor: PropTypes.string,
  currentAmount: PropTypes.number,
  startingDate: PropTypes.string,
  endingDate: PropTypes.string,
  queryString: PropTypes.string,
  parkingTypeId: PropTypes.number,
  scheduleExtraInfo: PropTypes.shape({
    isRunning: PropTypes.bool,
    remainingDuration: PropTypes.number,
    currentDuration: PropTypes.number,
    nextPause: PropTypes.string,
    nextStart: PropTypes.string,
  }),
};

const parkingDefaultProps = {
  id: null,
  vehicleBrand: "",
  vehicleLicensePlate: "",
  explorationName: "",
  streetName: "",
  benefitName: "",
  benefitColor: null,
  currentAmount: 0,
  startingDate: "",
  endingDate: "",
  queryString: "",
  parkingTypeId: null,
  scheduleExtraInfo: null,
};

const feePropTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  maxDuration: PropTypes.number,
};

const feeDefaultProps = { maxDuration: null, id: null, name: "" };

const calculatedParkingPropTypes = {
  amount: PropTypes.number,
  duration: PropTypes.number,
  isLoading: PropTypes.bool,
  endingDate: PropTypes.string,
};

const calculatedParkingDefaultProps = {
  amount: 0,
  duration: null,
  isLoading: false,
  endingDate: null,
};

export const chooseDurationPropTypes = {
  parking: PropTypes.shape(parkingPropTypes),
  calculateParkingCallback: PropTypes.func.isRequired,
  onConfirmRenewalCallback: PropTypes.func.isRequired,
  renewParkingCallback: PropTypes.func.isRequired,
  checkParkingCallback: PropTypes.func.isRequired,
  calculatedParking: PropTypes.shape(calculatedParkingPropTypes),
  fee: PropTypes.shape(feePropTypes),
};

export const chooseDurationDefaultProps = {
  parking: parkingDefaultProps,
  calculatedParking: calculatedParkingDefaultProps,
  fee: feeDefaultProps,
  onBack: null,
};

export const longFeeDurationPropTypes = {
  parking: PropTypes.shape(parkingPropTypes),
  calculateParkingCallback: PropTypes.func.isRequired,
  renewParkingCallback: PropTypes.func.isRequired,
  calculatedParking: PropTypes.shape(calculatedParkingPropTypes),
  fee: PropTypes.shape(feePropTypes),
};

export const longFeeDurationDefaultProps = {
  parking: parkingDefaultProps,
  calculatedParking: calculatedParkingDefaultProps,
  fee: feeDefaultProps,
};

export const detailsPropTypes = {
  ...parkingPropTypes,
  onRenewCallback: PropTypes.func.isRequired,
  fee: PropTypes.shape(feePropTypes),
};

export const detailsDefaultProps = {
  ...parkingDefaultProps,
  fee: feeDefaultProps,
};

export const parkingRenewPropTypes = {
  id: PropTypes.number,
  vehicleColor: PropTypes.string,
  animationState: PropTypes.string,
  isRenewed: PropTypes.bool.isRequired,
  step: PropTypes.oneOf(Object.values(parkingRenewalSteps)),
  allowBack: PropTypes.bool.isRequired,
  setAllowBack: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onRenewParkingWithSpecialPaymentMethod: PropTypes.func.isRequired,
  chooseDurationProps: PropTypes.shape(chooseDurationPropTypes),
  detailsProps: PropTypes.shape(detailsPropTypes),
  fee: PropTypes.shape(feePropTypes),
  isLoading: PropTypes.bool,
};

export const parkingRenewDefaultPropTypes = {
  id: null,
  vehicleColor: "",
  animationState: animationStates.parked,
  chooseDurationProps: chooseDurationDefaultProps,
  detailsProps: detailsDefaultProps,
  fee: feeDefaultProps,
  isLoading: false,
  onBack: null,
};
