import React from "react";
import { SelectField as IParqueSelectField } from "iparque-components";
import { useField } from "formik";
import { useValidationSchema } from "components/ValidationSchemaProvider";

const SelectField = ({ ...props }) => {
  const [field, meta] = useField(props);
  const hasError = meta.error && meta.touched && !meta.value;
  const { isFieldRequired } = useValidationSchema();

  return (
    <>
      <IParqueSelectField
        error={!!hasError}
        {...field}
        {...props}
        required={isFieldRequired?.(field.name)}
        helperText={hasError ? meta.error : ""}
      />
    </>
  );
};

export default SelectField;
