export const gaEvents = {
  loginSuccessfully: "login_successfully",
  startParkingIntention: "start_parking_intention",
  startAndStopParkingBegin: "start_and_stop_parking_begin",
  durationParkingBegin: "duration_parking_begin",
  parkingRequestError: "parking_request_error",
  renewParking: "renew_parking",
  renewParkingRequestError: "renew_parking_request_error",
};

export const gaCategory = "Parkings";
