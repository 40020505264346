import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const PopoverContainer = ({ children, className }) => {
  return (
    <>
      <Overlay />
      <Container className={className}>{children}</Container>
    </>
  );
};

export default PopoverContainer;

PopoverContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

PopoverContainer.defaultProps = {
  className: undefined,
};

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.popup.overlayColor};
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.popup.backgroundColor};
  border-radius: 14px;
  padding: 10px;
  z-index: 101;
`;
