import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Text, Radio } from "iparque-components";
import PropTypes from "prop-types";
import styled from "styled-components";
import darkImage from "../../../assets/images/dark-theme.jpg";
import lightImage from "../../../assets/images/light-theme.jpg";
import autoImage from "../../../assets/images/auto-theme.jpg";
import smallDevicesDarkImage from "../../../assets/images/mobile-dark-theme.png";
import smallDevicesLightImage from "../../../assets/images/mobile-light-theme.png";
import smallDevicesAutoImage from "../../../assets/images/mobile-auto-theme.png";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import { hasSystemThemeOnBackoffice, themesMapping } from "../../../themes/theme";
import { breakpoints, smallDevicesBreakpoints } from "../../../utils/breakpoints";
import { useAppContext } from "../../../components/AppProvider";

const Theme = ({ className }) => {
  const { t } = useTranslation();
  const { settingsBackofficeThemeName, setBackofficeTheme } = useBackofficeThemeContext();
  const { breakpoint } = useAppContext();

  const onChangeThemePreference = useCallback((event) => {
    setBackofficeTheme(event.target.value);
  }, []);

  const isSmallDevices = smallDevicesBreakpoints.includes(breakpoint);

  return (
    <Container className={className}>
      <ThemeImage src={isSmallDevices ? smallDevicesLightImage : lightImage} />
      <ThemeImage src={isSmallDevices ? smallDevicesDarkImage : darkImage} />
      <ThemeImage src={isSmallDevices ? smallDevicesAutoImage : autoImage} />
      <Text variant="body8" align="center" className="mt-10">
        {t("8518") /* Tons claros */}
      </Text>
      <Text variant="body8" align="center" className="mt-10">
        {t("8519") /* Tons escuros */}
      </Text>
      <Text variant="body8" align="center" className="mt-10">
        {t("6056") /* Automático */}
      </Text>
      <Radio
        className="mt-5"
        name="theme"
        value={themesMapping.normal}
        checked={settingsBackofficeThemeName === themesMapping.normal}
        onChange={onChangeThemePreference}
      />
      <Radio
        className="mt-5"
        name="theme"
        value={themesMapping.dark}
        checked={settingsBackofficeThemeName === themesMapping.dark}
        onChange={onChangeThemePreference}
      />
      <Radio
        className="mt-5"
        name="theme"
        value="system"
        checked={hasSystemThemeOnBackoffice()}
        onChange={onChangeThemePreference}
      />
    </Container>
  );
};

export default Theme;

Theme.propTypes = {
  className: PropTypes.string,
};

Theme.defaultProps = {
  className: null,
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  justify-items: center;
`;

const ThemeImage = styled.img`
  border: 1px solid ${({ theme }) => theme.color.medium};
  width: 180px;

  @media (max-width: ${breakpoints.lg}) {
    width: 100px;
  }

  @media (max-width: ${breakpoints.md}) {
    width: 70px;
  }
`;
