import { useAuth } from "components/AuthProvider";
import { getNextRegime } from "components/permitHolders/helpers/vehicles";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getPermitTypeVehicleRegimes } from "requests/permitHolders";
import { permitHoldersSubscriptionsStates } from "utils/permitHolders";
import {
  getSubscriptions,
  getVehicles as getPermitHolderVehicles,
  getVehiclesOptions as getPermitHolderVehiclesOptions,
} from "../controller";
import { usePermitHoldersDetailsContext } from "../Provider";
import { actionTypes } from "../store/actions";

const useVehiclesTab = () => {
  const { defaultEntityId: entityId, driverHash } = useAuth();
  const { dispatch, permit, id: permitHolderId } = usePermitHoldersDetailsContext();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      dispatch([
        { type: actionTypes.VEHICLES_TAB.SET_IS_LOADING, payload: true },
        { type: actionTypes.VEHICLES_TAB.SET_HAS_ERROR, payload: false },
      ]);

      const [
        vehiclesOptions,
        permitHolderVehicles,
        regimes,
        activeSubscriptions,
      ] = await Promise.all([
        getPermitHolderVehiclesOptions(
          entityId,
          driverHash,
          permitHolderId,
          { noLimit: true },
          { t }
        ),
        getPermitHolderVehicles(
          entityId,
          driverHash,
          permitHolderId,
          { noLimit: true },
          { t }
        ),
        getPermitTypeVehicleRegimes(entityId, permit.id, {
          noLimit: true,
        }),
        getSubscriptions(
          entityId,
          driverHash,
          permitHolderId,
          {
            noLimit: true,
            stateId: permitHoldersSubscriptionsStates.active,
          },
          { t }
        ),
      ]);

      const actions = [{ type: actionTypes.VEHICLES_TAB.SET_IS_LOADING, payload: false }];

      if (
        !vehiclesOptions ||
        !permitHolderVehicles ||
        regimes.hasError ||
        activeSubscriptions.hasError
      ) {
        dispatch([
          ...actions,
          { type: actionTypes.VEHICLES_TAB.SET_HAS_ERROR, payload: true },
        ]);
        return;
      }

      const addedVehicles = [];

      permitHolderVehicles.forEach((permitHolderVehicle) => {
        const addedVehicle = vehiclesOptions.find(
          (vehicleOption) =>
            vehicleOption.licencePlate === permitHolderVehicle.licencePlate
        );

        addedVehicles.push({
          permitHolderVehicleId: permitHolderVehicle.id,
          countryId: addedVehicle.countryId,
          licencePlate: addedVehicle.licencePlate,
          brandId: addedVehicle.brandId,
          modelId: addedVehicle.modelId,
          editable: false,
          flagged: false,
          touched: false,
          regime: getNextRegime(addedVehicles, regimes.items),
        });
      });

      dispatch([
        ...actions,
        {
          type: actionTypes.VEHICLES_TAB.SET_ADDED_VEHICLES,
          payload: addedVehicles,
        },
        {
          type: actionTypes.VEHICLES_TAB.SET_INITIAL_ADDED_VEHICLES,
          payload: permitHolderVehicles.map((permitHolderVehicle) => ({
            id: permitHolderVehicle.id,
            licencePlate: permitHolderVehicle.licencePlate,
          })),
        },
        { type: actionTypes.VEHICLES_TAB.SET_VEHICLES_OPTIONS, payload: vehiclesOptions },
        { type: actionTypes.VEHICLES_TAB.SET_VEHICLE_REGIMES, payload: regimes.items },
        {
          type: actionTypes.VEHICLES_TAB.SET_ACTIVE_SUBSCRIPTIONS,
          payload: activeSubscriptions.items,
        },
        { type: actionTypes.VEHICLES_TAB.SET_HAS_ERROR, payload: false },
      ]);
    })();
  }, []);
};

export default useVehiclesTab;
