import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const SubscriptionStateText = ({ id, name, right }) => {
  return (
    <StateText stateId={id} variant="body3" align={right ? "right" : undefined}>
      {name}
    </StateText>
  );
};

export default SubscriptionStateText;

SubscriptionStateText.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  right: PropTypes.bool,
};

SubscriptionStateText.defaultProps = {
  right: false,
};

const StateText = styled(Text)`
  color: ${({ theme, stateId }) => theme.permitHolders.subscriptionStates[stateId]};
`;
