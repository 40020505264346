import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Heading, Text } from "iparque-components/dist/components";
import styled, { css } from "styled-components";
import ParkedCar from "../../ParkedCar";
import { breakpoints, breakpointsMapping } from "../../../../../utils/breakpoints";
import { AppContext } from "../../../../../components/AppProvider";

const CarContainer = styled.div`
  margin-top: 5vh;
  display: flex;
  align-items: center;
  align-self: center;

  .start-and-stop-car-info-container {
    margin-left: 25px;
  }

  @media (max-width: ${breakpoints.sm}) {
    margin-top: 30px;
    align-self: auto;
    width: 180px;

    .start-and-stop-car-info-container {
      width: 150px;
      margin-left: 15px;
    }
  }
`;

const StyledTypography = css`
  color: ${({ textColor }) => textColor};
`;

const StyledHeading = styled(Heading)`
  line-height: 20px;
  ${StyledTypography}
`;

const StyledText = styled(Text)`
  ${StyledTypography}
`;

const ParkingDetails = ({
  streetName,
  explorationName,
  animationState,
  vehicleLicensePlate,
  vehicleBrand,
  vehicleColor,
  displayImage,
}) => {
  const { breakpoint } = useContext(AppContext);
  const isMediumDevice = [breakpointsMapping.md, breakpointsMapping.lg].includes(
    breakpoint
  );

  return (
    <>
      <div className={isMediumDevice ? "mt-50" : "mt-30"}>
        <Text variant="body1" align={isMediumDevice ? "center" : "left"}>
          {streetName}
        </Text>
        <Text variant="body6" align={isMediumDevice ? "center" : "left"}>
          {explorationName}
        </Text>
      </div>
      <CarContainer>
        <div>
          {displayImage && <ParkedCar color={vehicleColor} state={animationState} />}
        </div>
        <div className="start-and-stop-car-info-container">
          <StyledHeading textColor={vehicleColor} variant="h4">
            {vehicleLicensePlate}
          </StyledHeading>
          <StyledText textColor={vehicleColor} variant="body6">
            {vehicleBrand}
          </StyledText>
        </div>
      </CarContainer>
    </>
  );
};

ParkingDetails.propTypes = {
  streetName: PropTypes.string,
  explorationName: PropTypes.string,
  animationState: PropTypes.string,
  vehicleLicensePlate: PropTypes.string,
  vehicleBrand: PropTypes.string,
  vehicleColor: PropTypes.string,
  displayImage: PropTypes.bool,
};

ParkingDetails.defaultProps = {
  streetName: "",
  explorationName: "",
  animationState: "",
  vehicleLicensePlate: "",
  vehicleBrand: "",
  vehicleColor: "",
  displayImage: false,
};

export default React.memo(ParkingDetails);
