import { createGlobalStyle, css } from "styled-components";
import { getBodyScrollStyle, getScrollStyle } from "utils/scroll";

const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    body {
      ${getBodyScrollStyle(theme)}
    }

    ${getScrollStyle(theme)}
  `}

  :root {
    --app-height: 100%;
  }

  a:hover{
    opacity: 0.7
  }

  .uppercase {
    text-transform: uppercase;
  }

  .lowercase {
    text-transform: lowercase;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-8 {
    margin-top: 8px;
  }

  .mt-10 {
    margin-top: 10px !important;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .mt-60 {
    margin-top: 60px;
  }

  .mb-4 {
    margin-bottom: 4px;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-25 {
    margin-bottom: 25px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  .ml-5 {
    margin-left: 5px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .ml-15 {
    margin-left: 15px;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .ml-30 {
    margin-left: 30px;
  }

  .ml-50 {
    margin-left: 50px;
  }

  .ml-70 {
    margin-left: 70px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mr-5 {
    margin-right: 5px;
  }

  .mr-15 {
    margin-right: 15px;
  }

  .mr-20 {
    margin-right: 20px;
  }

  .mr-40 {
    margin-right: 40px;
  }

  .available-width {
    width: fill-available;
  }

  .inline {
    display: inline;
  }

  .inline-block {
    display: inline-block;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .right {
    float: right;
  }

  .visible {
    visibility: visible;
  }

  .hidden {
    visibility: hidden;
  }

  .flex-baseline-container {
    display: flex;
    align-items: baseline;
  }

  .flex-center-container {
    display: flex;
    align-items: center;
  }

  .relative-container {
    position: relative;
  }

  .fill {
    flex: 1;
  }

  .clickable-icon {
    cursor: pointer;
    flex-shrink: 0;

    :hover {
      opacity: 0.8;
    }
  }

  .fit-text-vertically {
    line-height: 1;
  }

  .not-displayed {
    display: none !important;
  }
`;

export default GlobalStyles;
