export const UNSELECTED_POLYGON_ALPHA = "0.25";
export const UNSELECTED_POLYLINE_ALPHA = "0.35";
export const SELECTED_POLYGON_ALPHA = "0.65";
export const SELECTED_POLYLINE_ALPHA = "0.75";

export const polylineConfig = {
  strokeColor: "#185c96",
  strokeOpacity: UNSELECTED_POLYLINE_ALPHA,
  strokeWeight: 5,
  clickable: true,
};

export const polygonConfig = {
  strokeColor: "#185c96",
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: "#185c96",
  fillOpacity: UNSELECTED_POLYGON_ALPHA,
  clickable: true,
};
