import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "iparque-components";
import NotificationTimeOptions from "./Options";
import { notificationDurationOptions } from "../../../controller";
import NotificationTimeInput from "./Input";
import { breakpoints } from "../../../../../../utils/breakpoints";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
  }
`;

const StyledText = styled(Text)`
  cursor: pointer;
  color: ${({ theme }) => theme.color.secondary};
`;

const CustomOptionsContainer = styled.div`
  margin-left: 40px;

  @media (max-width: ${breakpoints.lg}) {
    margin-left: 0;
    margin-top: 30px;
  }
`;

const NotificationTimeField = ({ handleSetMinutes, selectedMinutes }) => {
  const { t } = useTranslation();
  const [displayInput, setDisplayInput] = useState(
    !notificationDurationOptions.includes(selectedMinutes)
  );

  useEffect(() => {
    setDisplayInput(!notificationDurationOptions.includes(selectedMinutes));
  }, [selectedMinutes]);

  const onInputChange = useCallback(
    (minutes) => {
      if (selectedMinutes !== minutes) {
        handleSetMinutes(minutes);
      }
    },
    [selectedMinutes, handleSetMinutes]
  );

  const onOptionClick = useCallback(
    (minutes) => {
      handleSetMinutes(minutes);
      setDisplayInput(false);
    },
    [handleSetMinutes]
  );

  const handleDisplayInput = useCallback(() => {
    setDisplayInput(true);
  }, []);

  return (
    <Container>
      <NotificationTimeOptions
        selectedMinutes={!displayInput ? selectedMinutes : null}
        onClick={onOptionClick}
      />
      <CustomOptionsContainer>
        {displayInput ? (
          <NotificationTimeInput onChange={onInputChange} value={selectedMinutes} />
        ) : (
          <div
            onClick={handleDisplayInput}
            onKeyPress={handleDisplayInput}
            role="button"
            tabIndex="0"
          >
            <StyledText variant="body10">{t("8556") /* Definir tempo */}</StyledText>
          </div>
        )}
      </CustomOptionsContainer>
    </Container>
  );
};

NotificationTimeField.propTypes = {
  handleSetMinutes: PropTypes.func.isRequired,
  selectedMinutes: PropTypes.number.isRequired,
};

export default NotificationTimeField;
