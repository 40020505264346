import { errorToast, successToast, warningToast, infoToast } from "iparque-components";
import i18n from "i18next";
import { hasCanceledError, hasUnauthorizedError } from "./error";

export const errorMessage = (
  error,
  message,
  title = i18n.t("3613" /* Erro */),
  options = {}
) => {
  if (error && hasUnauthorizedError(error)) {
    return;
  }

  if (error && hasCanceledError(error)) {
    return;
  }

  errorToast(title, message, options);
};

export const successMessage = (
  message,
  title = i18n.t("586" /* Sucesso */),
  options = {}
) => {
  successToast(title, message, options);
};

export const warningMessage = (
  message,
  title = i18n.t("7857") /* Aviso! */,
  options = {}
) => {
  warningToast(title, message, options);
};

export const infoMessage = (
  message,
  title = i18n.t("7885") /* Informação! */,
  options = {}
) => {
  infoToast(title, message, options);
};

export const customerSupportMessage = (errorCode) =>
  errorToast(
    i18n.t("3613") /* Erro */,
    i18n.t("10266", {
      error: errorCode,
    }) /* Foi retornado o erro {{error}}. Por favor contacte o nosso apoio ao cliente. */,
    { autoClose: false }
  );
