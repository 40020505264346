/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../components/AppProvider";
import Settings from "./Settings";
import SmallDevicesSettings from "./smallDevices/Settings";
import MediumDevicesSettings from "./mediumDevices/Settings";
import { breakpointsMapping, smallDevicesBreakpoints } from "../../../utils/breakpoints";
import {
  getSettings as getSettingsRequest,
  saveSettings as saveSettingsRequest,
  settingsTabs,
} from "./controller";
import { useAuth } from "../../../components/AuthProvider";
import SettingsProvider from "./Provider";
import NotificationsTab from "./tabs/notifications/Tab";
import NewslettersTab from "./tabs/newsletters/Tab";
import AccountTab from "./tabs/account/Tab";
import LanguageTab from "./tabs/language/Tab";
import { ReactComponent as NotificationIcon } from "../../../assets/icons/notification.svg";
import { ReactComponent as NewsletterIcon } from "../../../assets/icons/newsletter.svg";
import { ReactComponent as AccountIcon } from "../../../assets/icons/account.svg";
import { ReactComponent as LanguageIcon } from "../../../assets/icons/language.svg";

const SettingsPage = () => {
  const { breakpoint } = useAppContext();
  const { authHash } = useAuth();
  const [settings, setSettings] = useState(null);
  const [isSettingsLoading, setIsSettingsLoading] = useState(true);
  const { t } = useTranslation();

  const getSettings = useCallback(
    async (cancelToken) => {
      const newSettings = await getSettingsRequest(authHash, cancelToken);
      setSettings(newSettings);
      setIsSettingsLoading(false);
    },
    [authHash]
  );

  const saveSettings = useCallback(
    async (settingKey, value) => {
      if (settings.notifications?.[settingKey] !== undefined) {
        setSettings({
          ...settings,
          notifications: { ...settings.notifications, [settingKey]: value },
        });
      }

      if (settings.newsletters?.[settingKey] !== undefined) {
        setSettings({
          ...settings,
          newsletters: { ...settings.newsletters, [settingKey]: value },
        });
      }

      const result = await saveSettingsRequest(authHash, settingKey, value);

      if (!result) {
        getSettings(null);
      }
    },
    [authHash, settings]
  );

  useEffect(() => {
    const cancelToken = Axios.CancelToken.source();

    getSettings(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const tabs = useMemo(
    () => [
      {
        id: settingsTabs.notifications,
        label: t("260") /* Notificações */,
        component: <NotificationsTab {...settings?.notifications} />,
        icon: <NotificationIcon />,
      },
      {
        id: settingsTabs.newsletters,
        label: t("8526") /* Newsletters */,
        component: <NewslettersTab {...settings?.newsletters} />,
        icon: <NewsletterIcon />,
      },
      {
        id: settingsTabs.account,
        label: t("8527") /* Conta */,
        component: <AccountTab />,
        icon: <AccountIcon />,
      },
      {
        id: settingsTabs.language,
        label: t("79") /* Idioma */,
        component: <LanguageTab />,
        icon: <LanguageIcon />,
      },
    ],
    [settings]
  );

  const chooseViewBasedOnDevice = () => {
    const props = { tabs };

    if (smallDevicesBreakpoints.includes(breakpoint)) {
      return <SmallDevicesSettings {...props} />;
    }

    if (breakpointsMapping.noBreakpoint === breakpoint) {
      return <MediumDevicesSettings {...props} />;
    }

    return <Settings {...props} />;
  };

  return (
    <SettingsProvider saveSettings={saveSettings} isSettingsLoading={isSettingsLoading}>
      {chooseViewBasedOnDevice()}
    </SettingsProvider>
  );
};

export default SettingsPage;
