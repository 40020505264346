import { Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ContentBorderBox, {
  CONTENT_CONTAINER_HORIZONTAL_SPACING,
} from "../components/ContentBorderBox";
import ContentHeader from "../components/ContentHeader";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import CurrentBalance from "./CurrentBalance";
import MovementList from "./List";
import { accountMovementsProps, accountMovementsDefaultProps } from "./propTypes";

const MovementsContainer = styled.div`
  padding: 0 ${CONTENT_CONTAINER_HORIZONTAL_SPACING}px;
  margin-top: 30px;
  display: flex;
`;

const HistoryContainer = styled.div`
  flex: 1;
`;

const AccountMovements = ({
  getBalanceHistoryCallback,
  getPaymentsHistoryCallback,
  vehiclesColors,
}) => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();

  return (
    <>
      <ContentHeader
        topTitle={t("8393") /* Não sabes a quantas andas? */}
        bottomTitle={t("8394") /* Consulta aqui o teu saldo! */}
      />
      <ContentBorderBox
        className="mt-40"
        cornerColor={backofficeTheme.color.primary}
        title={t("8395") /* Saldo atual */}
      >
        <CurrentBalance />
      </ContentBorderBox>
      <MovementsContainer>
        <HistoryContainer>
          <Text variant="body9" className="uppercase">
            {t("8409") /* Movimentos de saldo */}
          </Text>
          <MovementList
            vehiclesColors={vehiclesColors}
            className="mt-10"
            getDataCallback={getBalanceHistoryCallback}
          />
        </HistoryContainer>
        <HistoryContainer className="ml-30">
          <Text variant="body9" className="uppercase">
            {t("8410") /* Outros movimentos */}
          </Text>
          <MovementList
            vehiclesColors={vehiclesColors}
            className="mt-10"
            getDataCallback={getPaymentsHistoryCallback}
          />
        </HistoryContainer>
      </MovementsContainer>
    </>
  );
};

AccountMovements.propTypes = accountMovementsProps;

AccountMovements.defaultProps = accountMovementsDefaultProps;

export default AccountMovements;
