import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Text, Link } from "iparque-components";
import styled from "styled-components";
import { formatMoney } from "../../../utils/money";
import PaymentMethodIcon from "../components/paymentMethods/PaymentIcon";
import { getAmountColor } from "./controller";
import BalanceChargeDetails from "./BalanceChargeDetails";
import { movementCardDefaultProps, movementCardPropTypes } from "./propTypes";
import { Cell } from "./components/inlineDetails/Item";
import { convertDateTimeToReadableDate } from "../../../utils/dateTime";
import ListCard from "../components/cardList/Card";
import { useBackofficeThemeContext } from "../components/ThemeProvider";

const AmountContainer = styled.div`
  min-width: 100px;
`;

const EmptyContainer = styled.div`
  min-width: 100px;
`;

const StyledListCard = styled(ListCard)`
  .list-card-extra-info-container {
    margin-top: 15px;
  }
`;

const IconContainer = styled.div`
  flex: 1;
  min-width: 70px;

  svg {
    vertical-align: middle;
  }
`;

const DescriptionContainer = styled.div`
  flex: 3;
  line-height: 1.2;
`;

const LeftInfoContainer = styled.div`
  min-width: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 10px;
`;

const PaymentInfoContainer = styled.div`
  flex: 1;
`;

const InvoiceContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const StyledText = styled(Text)`
  color: ${({ textColor }) => textColor};
`;

const MovementCard = ({
  description,
  amount,
  dateTime,
  completed,
  balanceChargeId,
  paymentMethodId,
  licensePlate,
  invoice,
  vehiclesColors,
}) => {
  const { t } = useTranslation();
  const hasInvoice = invoice.number && invoice.url;
  const hasBoxExtraInfo = hasInvoice || !completed;
  const { backofficeTheme } = useBackofficeThemeContext();

  const renderExtraInfoCallback = useCallback(
    ({ isOpen }) => (
      <>
        <EmptyContainer />
        <PaymentInfoContainer>
          {balanceChargeId && !completed && isOpen ? (
            <BalanceChargeDetails id={balanceChargeId} />
          ) : null}
          {hasInvoice && (
            <InvoiceContainer>
              <div>
                <Cell>
                  <Text variant="body4">{t("8402") /* Nº da fatura */}</Text>
                </Cell>
                <Cell right>
                  <Text variant="body3">{invoice.number}</Text>
                </Cell>
              </div>
              <Cell right>
                <Text variant="body3">
                  <Link
                    underline="always"
                    target="_blank"
                    href={invoice.url}
                    rel="noopener"
                  >
                    {t("7388") /* Ver factura  */}
                  </Link>
                </Text>
              </Cell>
            </InvoiceContainer>
          )}
        </PaymentInfoContainer>
      </>
    ),
    [(balanceChargeId, completed, hasInvoice)]
  );

  return (
    <StyledListCard
      renderExtraInfoCallback={hasBoxExtraInfo ? renderExtraInfoCallback : null}
      preserveArrowWidth
    >
      <AmountContainer>
        <Text variant="body1" color={getAmountColor(completed, amount)}>
          {amount > 0 ? `+${formatMoney(amount)}` : formatMoney(amount)}
        </Text>
      </AmountContainer>
      <IconContainer>
        <PaymentMethodIcon paymentMethodId={paymentMethodId} />
      </IconContainer>
      <DescriptionContainer>
        <Text variant="body3">{description}</Text>
      </DescriptionContainer>
      <LeftInfoContainer>
        {licensePlate && (
          <StyledText
            textColor={backofficeTheme.carColor[vehiclesColors?.[licensePlate]]}
            variant="body3"
          >
            {licensePlate}
          </StyledText>
        )}
        <Text variant="body9">{convertDateTimeToReadableDate(dateTime)}</Text>
      </LeftInfoContainer>
    </StyledListCard>
  );
};

MovementCard.propTypes = movementCardPropTypes;

MovementCard.defaultProps = movementCardDefaultProps;

export default MovementCard;
