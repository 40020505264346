import usePardot from "hooks/usePardot";
import React, { useContext } from "react";
import { AppContext } from "../../../components/AppProvider";
import { breakpointsMapping } from "../../../utils/breakpoints";
import Cities from "./Cities";
import Features from "./Features";
import Footer from "./Footer";
import Head from "./Head";
import LanguageProvider from "./LanguageProvider";
import ParkingInfo from "./parkingInfo/ParkingInfo";
import PayNotice from "./payNotice/PayNotice";
import PayNoticeSmallScreen from "./payNotice/PayNoticeSmallScreen";
import Subscription from "./subscription/Subscription";

const largeDevices = [
  breakpointsMapping.lg,
  breakpointsMapping.noBreakpoint,
  breakpointsMapping.xl,
];

const LandingPage = () => {
  const { breakpoint } = useContext(AppContext);

  usePardot();

  return (
    <LanguageProvider>
      <Head />
      <Features />
      <Cities breakpoint={breakpoint} />
      <ParkingInfo breakpoint={breakpoint} />
      <Subscription />
      <Footer breakpoint={breakpoint} />
      {largeDevices.includes(breakpoint) ? <PayNotice /> : <PayNoticeSmallScreen />}
    </LanguageProvider>
  );
};

export default LandingPage;
