import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "iparque-components";
import { ReactComponent as ExportIcon } from "../../../../../assets/icons/export.svg";
import { exportData } from "../../controller";
import { useAuth } from "../../../../../components/AuthProvider";
import { useAppContext } from "../../../../../components/AppProvider";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledExportIcon = styled(ExportIcon)`
  margin-left: auto;
  fill: ${({ theme }) => theme.definitions.exportIconColor};
  cursor: pointer;
`;

const ExportUserData = ({ title, description, className }) => {
  const { driverHash } = useAuth();
  const { setIsLoading } = useAppContext();

  const handleIconClick = useCallback(async () => {
    setIsLoading(true);
    await exportData(driverHash);
    setIsLoading(false);
  }, []);

  return (
    <Container className={className}>
      <div className="mr-10">
        <Text variant="body9">{title}</Text>
        <Text className="mt-5" variant="body10">
          {description}
        </Text>
      </div>
      <StyledExportIcon onClick={handleIconClick} />
    </Container>
  );
};

ExportUserData.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

ExportUserData.defaultProps = {
  className: null,
};

export default ExportUserData;
