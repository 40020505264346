import React from "react";
import PropTypes from "prop-types";
import ReactLoadingSkeleton from "react-loading-skeleton";
import styled from "styled-components";

const Skeleton = ({ height, width, maxWidth, circle, className }) => {
  return (
    <Container maxWidth={maxWidth} className={className}>
      <StyledSkeleton height={height} width={width} circle={circle} />
    </Container>
  );
};

Skeleton.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  maxWidth: PropTypes.number,
  className: PropTypes.string,
  circle: PropTypes.bool,
};

Skeleton.defaultProps = {
  height: undefined,
  width: undefined,
  maxWidth: undefined,
  className: null,
  circle: false,
};

export default Skeleton;

const Container = styled.div`
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : ``)}
`;

const StyledSkeleton = styled(ReactLoadingSkeleton)`
  display: flex;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;
