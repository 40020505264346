import React from "react";
import { Heading, Text } from "iparque-components";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReactComponent as CarIcon } from "../../../assets/icons/car.svg";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import {
  convertSecondsToTimeFormatted,
  formatDateTime,
  convertDateTimeToReadableDate,
} from "../../../utils/dateTime";
import { finishedParkingProps, finishedParkingDefaultProps } from "./propTypes";
import { isEmpty } from "../../../utils/general";
import ParkingMap from "./Map";
import SelectedBenefit from "../components/parkings/SelectedBenefit";
import CardCell from "../components/cardList/Cell";
import ListCard from "../components/cardList/Card";
import { getTextColorByState } from "../../../utils/parking";

const CAR_ICON_SIZE = "60px";

const FinishedParkingBox = ({
  id,
  licensePlate,
  vehicleColorId,
  streetName,
  explorationName,
  startingDate,
  endingDate,
  duration,
  amount,
  stateId,
  stateName,
  benefit,
  location,
}) => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();
  const hasBenefit = !isEmpty(benefit);
  const hasLocation = location.lat !== null && location.lng !== null;
  const hasExtraInfo = hasBenefit || hasLocation;

  const renderExtraInfoCallback = ({ isOpen }) => (
    <ExtraInfoContainer>
      <IconContainer />
      <CardCell size="sm" />
      <CardCell size="lg">
        {hasBenefit && (
          <>
            <Text variant="body4" color="primary">
              {t("7076") /* Benefício */}
            </Text>
            <SelectedBenefit
              className="mr-10"
              description={benefit.name}
              typeId={benefit.typeId}
            />
          </>
        )}
      </CardCell>
      <CardCell flex={5}>
        {hasLocation && isOpen && (
          <>
            <Text variant="body4" color="primary">
              {t("2539") /* Localização */}
            </Text>
            <ParkingMap id={id} location={location} />
          </>
        )}
      </CardCell>
    </ExtraInfoContainer>
  );

  return (
    <StyledListCard
      renderExtraInfoCallback={hasExtraInfo ? renderExtraInfoCallback : null}
      preserveArrowWidth
    >
      <IconContainer>
        <CarIcon
          width={CAR_ICON_SIZE}
          height={CAR_ICON_SIZE}
          className="vehicle-box-icon"
          fill={backofficeTheme.carColor[vehicleColorId]}
        />
      </IconContainer>
      <CardCell size="sm">
        <StyledHeading colorId={vehicleColorId} variant="h4">
          {licensePlate}
        </StyledHeading>
        <Text variant="body3" color="primary">
          {convertDateTimeToReadableDate(startingDate)}
        </Text>
      </CardCell>
      <CardCell size="lg">
        <Text variant="body3" color="primary">
          {streetName}
        </Text>
        <Text variant="body4" color="primary">
          {explorationName}
        </Text>
      </CardCell>
      <CardCell size="lg">
        <Text variant="body4" color="primary">
          {t("8005") /* Duração estacionamento */}
        </Text>
        <Text variant="body3" color="primary">
          {convertSecondsToTimeFormatted(duration)}
        </Text>
      </CardCell>
      <CardCell size="sm">
        <Text variant="body4" color="primary">
          {t("8459") /* Data de fim */}
        </Text>
        <Text variant="body3" color="primary">
          {formatDateTime(endingDate)}
        </Text>
      </CardCell>
      <CardCell size="sm">
        <Text variant="body4" color="primary">
          {t("8000") /* Montante pago */}
        </Text>
        <Text variant="body3" color="primary">
          {amount}
        </Text>
      </CardCell>
      <CardCell size="sm" align="right">
        <Text variant="body3" color={getTextColorByState(stateId)}>
          {stateName}
        </Text>
      </CardCell>
    </StyledListCard>
  );
};

export default React.memo(FinishedParkingBox);

FinishedParkingBox.propTypes = finishedParkingProps;

FinishedParkingBox.defaultProps = finishedParkingDefaultProps;

const StyledListCard = styled(ListCard)`
  .list-card-extra-info-container {
    margin-top: 40px;
  }
`;

const IconContainer = styled.div`
  min-width: 70px;
  height: ${CAR_ICON_SIZE};
`;

const StyledHeading = styled(Heading)`
  color: ${({ colorId, theme }) => theme.carColor[colorId]};
`;

const ExtraInfoContainer = styled.div`
  height: 270px; /* This height is mandatory to the animation */
  display: flex;
  width: 100%;
`;
