import PropTypes from "prop-types";
import React, { useEffect, useImperativeHandle } from "react";
import styled from "styled-components";
import Tab from "../components/Tab";
import TabPanel from "../components/TabPanel";
import useTabs from "../hooks/useTabs";
import { tabsDefaultProps, tabsPropTypes } from "../propTypes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const TabsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const VerticalTabs = React.forwardRef(
  ({ tabs, displayTabs, onTabClick, className }, ref) => {
    const { onChange, activeTabId, initialActiveTab } = useTabs({
      tabs,
      onTabChange: onTabClick,
    });

    useImperativeHandle(ref, () => ({ changeTab: onChange }), [onChange]);

    useEffect(() => {
      if (initialActiveTab) {
        onTabClick(initialActiveTab);
      }
    }, [initialActiveTab]);

    return (
      <Container ref={ref} className={className}>
        {displayTabs ? (
          <TabsList>
            {tabs.map((tab) => (
              <Tab
                key={tab.id}
                onClick={() => onChange(tab.id)}
                label={tab.label}
                icon={tab.icon}
              />
            ))}
          </TabsList>
        ) : (
          <StyledTabPanel tabs={tabs} activeTabId={activeTabId} isSmallDevicesVersion />
        )}
      </Container>
    );
  }
);

VerticalTabs.propTypes = { ...tabsPropTypes, onTabClick: PropTypes.func.isRequired };

VerticalTabs.defaultProps = tabsDefaultProps;

export default VerticalTabs;
