import CityList from "components/CityList";
import { useValidationSchema } from "components/ValidationSchemaProvider";
import { useField, useFormikContext } from "formik";
import {
  FormFieldContainer,
  FormFieldLabel,
  FormFieldTextHelper,
} from "iparque-components";
import PropTypes from "prop-types";
import React from "react";

const CitiesField = ({ helperText, id, name, label, className, ...other }) => {
  const processedId = id || name;
  const [field, meta] = useField(name);
  const formik = useFormikContext();
  const hasError = meta.error && meta.touched;
  const { isFieldRequired } = useValidationSchema();

  const onChange = (city) => formik.setFieldValue(name, city);

  const selected = field.value
    ? { entityId: field.value.entity.id, cityId: field.value.id }
    : undefined;

  return (
    <FormFieldContainer className={className}>
      <FormFieldLabel
        color="primary"
        hasAnimation={false}
        htmlFor={processedId}
        error={hasError}
        required={isFieldRequired?.(field.name)}
      >
        {label}
      </FormFieldLabel>
      <CityList className="mt-20" onClick={onChange} selected={selected} {...other} />
      <FormFieldTextHelper error={hasError}>
        {hasError ? meta.error : helperText}
      </FormFieldTextHelper>
    </FormFieldContainer>
  );
};

CitiesField.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CitiesField.defaultProps = {
  id: undefined,
  helperText: "",
  className: "",
};

export default CitiesField;
