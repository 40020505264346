/* eslint-disable import/prefer-default-export */
import i18n from "../../../i18n";
import DriversDataSource from "../../../lib/clients/iParque/dataSources/driversDataSource";
import { errorMessage } from "../../../utils/userMessages";

const driversDataSource = new DriversDataSource();

export const saveEntityContractAcceptance = async (authHash, entityId, params) => {
  try {
    await driversDataSource.saveEntityContractAcceptance(authHash, entityId, params);

    return true;
  } catch (error) {
    errorMessage(
      error,
      i18n.t("8912") /* Ocorreu um erro ao guardar a aceitação do contrato */
    );

    return false;
  }
};
