import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { STEPS_BEFORE_PERMIT_TYPE_CHOOSE } from "components/permitHolders/Creation/helper";
import { usePermitHoldersContext } from "components/permitHolders/Creation/Provider";
import useTitle from "components/permitHolders/Creation/hooks/useTitle";
import GenericTemplate from "../../../../components/Template";
import PermitTypeTitle from "./PermitTypeTitle";

const Template = ({ children, isLoading }) => {
  const {
    canGoBack,
    goBack,
    selectedPermitType,
    currentStep,
  } = usePermitHoldersContext();
  const { t } = useTranslation();
  const title = useTitle();

  return (
    <GenericTemplate
      onBack={canGoBack ? goBack : undefined}
      isLoading={isLoading}
      title={t("10527") /* Criação de dístico */}
      subTitle={title}
    >
      <LeftContentContainer>
        {selectedPermitType &&
          !STEPS_BEFORE_PERMIT_TYPE_CHOOSE.includes(currentStep?.index) && (
            <PermitTypeTitle />
          )}
        {children}
      </LeftContentContainer>
    </GenericTemplate>
  );
};

export default Template;

Template.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
};

Template.defaultProps = {
  isLoading: false,
};

const LeftContentContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;
