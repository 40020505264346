import FlexBox from "components/FlexBox";
import Skeleton from "components/Skeleton";
import { Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";

const TitleSkeleton = () => {
  const { t } = useTranslation();

  return (
    <FlexBox alignItems="center">
      <Text variant="body7">{t("1848") /* Dístico */}</Text>
      &nbsp;
      <Skeleton width={80} height={15} />
      &nbsp;&nbsp;&nbsp;
      <Text variant="body6">-</Text>
      &nbsp;&nbsp;&nbsp;
      <Skeleton width={120} height={15} />
    </FlexBox>
  );
};

export default TitleSkeleton;
