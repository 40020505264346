const notAllowedExtensions = ["exe", "sh", "js", "php", "java", "c", "py", "bat"];

const isAllowedExtension = (extension, { allowedFormats = [] } = {}) => {
  if (allowedFormats.length) {
    return allowedFormats.includes(extension);
  }

  return !notAllowedExtensions.includes(extension);
};

export const isFileExtensionAllowed = (fileName, { allowedFormats = [] } = {}) => {
  const extension = fileName.split(".")[1];

  return isAllowedExtension(extension, { allowedFormats });
};
