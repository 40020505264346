export const emptyVehicle = {
  countryId: undefined,
  licencePlate: "",
  brandId: undefined,
  modelId: undefined,
  editable: true,
  flagged: false,
};

export const isEditing = (addedVehicles) =>
  addedVehicles.length > 0 && addedVehicles.find((addedVehicle) => addedVehicle.editable);

export const getPositionOfEditedVehicle = (addedVehicles) =>
  addedVehicles.findIndex((vehicle) => vehicle.editable);

export const canAddVehicles = (addedVehicles, availableRegimes) => {
  const maxVehicles = availableRegimes.reduce(
    (maxVehiclesNumber, vehicleRegime) =>
      maxVehiclesNumber + vehicleRegime.maxVehiclesNumber,
    0
  );

  return addedVehicles.length < maxVehicles;
};

export const getNextRegime = (addedVehicles, availableRegimes) => {
  const numberOffAddedVehicles = addedVehicles.length;
  let regimeVehiclesCount = 0;

  for (let regimeIndex = 0; regimeIndex < availableRegimes.length; regimeIndex += 1) {
    const regime = availableRegimes[regimeIndex];

    regimeVehiclesCount += regime.maxVehiclesNumber;

    if (numberOffAddedVehicles < regimeVehiclesCount) {
      return regime;
    }
  }

  return null;
};

export const getAvailableVehicleOptions = (selectedVehicles, vehiclesOptions) => {
  const selectedLicencePlates = selectedVehicles.map((vehicle) => vehicle.licencePlate);

  return vehiclesOptions.filter(
    (vehicleOption) => !selectedLicencePlates.includes(vehicleOption.licencePlate)
  );
};

export const getVehiclesWithActiveSubscriptions = (vehicles, activeSubscriptions) => {
  const vehiclesWithActiveSubscriptions = vehicles.filter((vehicle) =>
    activeSubscriptions.some((activeSubscription) =>
      activeSubscription.licencePlates.includes(vehicle.licencePlate)
    )
  );

  return vehiclesWithActiveSubscriptions;
};

export const getVehiclesToRemove = (initialVehicles, addedVehicles) => {
  const permitHolderVehiclesToRemove = [];

  initialVehicles.forEach((initialAddedVehicle) => {
    const permitHolderVehicle = addedVehicles.find(
      (addedVehicle) => addedVehicle.permitHolderVehicleId === initialAddedVehicle.id
    );

    if (!permitHolderVehicle) {
      permitHolderVehiclesToRemove.push(initialAddedVehicle);
    }
  });

  return permitHolderVehiclesToRemove;
};

export const getVehiclesToUpdate = (initialVehicles, addedVehicles) => {
  const permitHolderVehiclesToUpdate = [];

  initialVehicles.forEach((initialAddedVehicle) => {
    const permitHolderVehicle = addedVehicles.find(
      (addedVehicle) => addedVehicle.permitHolderVehicleId === initialAddedVehicle.id
    );

    if (permitHolderVehicle && permitHolderVehicle.touched) {
      permitHolderVehiclesToUpdate.push({
        ...permitHolderVehicle,
        initialLicencePlate: initialAddedVehicle.licencePlate,
      });
    }
  });

  return permitHolderVehiclesToUpdate;
};
