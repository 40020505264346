/* eslint-disable react/no-array-index-key */
import { useAuth } from "components/AuthProvider";
import AddVehicleLink from "components/permitHolders/components/AddVehicleLink";
import AdvanceButton from "components/permitHolders/Creation/components/AdvanceButton";
import useVehiclesStep from "components/permitHolders/Creation/defaultSteps/Vehicles/useVehiclesStep";
import { getAvailableVehicleOptions } from "components/permitHolders/helpers/vehicles";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import VehiclePicker from "../../../../../../components/permitHolders/components/VehiclePicker";
import SmallDevicesFillSpace from "../../components/SmallDevicesFillSpace";
import { getVehicles } from "./controller";

const VehiclesStep = () => {
  const { t } = useTranslation();
  const { driverHash } = useAuth();
  const {
    vehicles,
    onAdvance,
    addVehicle,
    editVehicle,
    makeEditable,
    removeVehicle,
    isEditing,
  } = useVehiclesStep();
  const [vehicleOptions, setVehicleOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const vehiclesResponse = await getVehicles(driverHash, { t });

      if (!vehicles) {
        return;
      }

      setVehicleOptions(vehiclesResponse);
    })();
  }, []);

  const availableVehicleOptions = useMemo(
    () => getAvailableVehicleOptions(vehicles, vehicleOptions),
    [vehicles, vehicleOptions]
  );

  return (
    <>
      {vehicles.map((vehicle, index) => (
        <VehiclePicker
          key={index}
          position={index}
          editVehicle={editVehicle}
          makeEditable={makeEditable}
          removeVehicle={removeVehicle}
          canRemove={index > 0 && index === vehicles.length - 1}
          vehicle={vehicle}
          allVehicleOptions={vehicleOptions}
          availableVehicleOptions={availableVehicleOptions}
        />
      ))}
      {!isEditing && (
        <AddVehicleLink onClick={addVehicle} className="mt-30">
          {t("10479") /* Adicionar outro veículo */}
        </AddVehicleLink>
      )}
      <SmallDevicesFillSpace />
      <AdvanceButton type="button" onClick={onAdvance} />
    </>
  );
};

export default VehiclesStep;
