import { keyframes } from "styled-components";

export const defaultAnimationDuration = 0.8;
export const defaultAnimationDelay = 0.8;

export const appearFromBottom = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const getAppearFromLeft = (translateX = "-100%") => keyframes`
  0% {
    transform: translateX(${translateX});
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const getAppearFromRight = (translateX = "100%") => keyframes`
  0% {
    transform:  translateX(${translateX});
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;
