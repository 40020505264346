import { useAuth } from "components/AuthProvider";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getPermitHolderWarnings } from "requests/permitHolders";
import { permitHolderStates } from "utils/permitHolders";
import { usePermitHoldersDetailsContext } from "../Provider";
import { actionTypes } from "../store/actions";

const useWarnings = () => {
  const { warnings, state } = usePermitHoldersDetailsContext();
  const { defaultEntityId: entityId, driverHash } = useAuth();
  const { id: permitHolderId, dispatch } = usePermitHoldersDetailsContext();
  const { t } = useTranslation();

  const hasWarnings = useMemo(
    () => Object.values(warnings).length > 0 && state.id !== permitHolderStates.approved,
    [warnings, state.id]
  );

  const updateWarnings = useCallback(async () => {
    const newWarnings = await getPermitHolderWarnings(
      entityId,
      driverHash,
      permitHolderId,
      {},
      { t }
    );

    if (!newWarnings) {
      return;
    }

    dispatch({
      type: actionTypes.SET_WARNINGS,
      payload: newWarnings,
    });
  }, [entityId, driverHash, permitHolderId, t]);

  return { hasWarnings, updateWarnings };
};

export default useWarnings;
