import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import lightTheme from "themes/normal";
import { itemProps } from "./propTypes";

const Item = ({ imageUrl, id, cityName, entityName, onClick, selected }) => {
  const localOnClick = () => onClick(id);

  const isSelected = id.entityId === selected?.entityId && id.cityId === selected?.cityId;

  return (
    <Container onClick={localOnClick} isSelected={isSelected}>
      <Image src={imageUrl} alt={cityName} className="mr-10" />
      <div>
        <Text variant="body7" color="quintenary">
          {cityName}
        </Text>
        <Text variant="body11" color="quintenary" className="mt-5">
          {entityName}
        </Text>
      </div>
    </Container>
  );
};

Item.propTypes = { ...itemProps, onClick: PropTypes.func.isRequired };

Item.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  imageUrl: "",
};

export default Item;

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  height: 50px;
  border: 1px solid
    ${({ theme, isSelected }) =>
      theme.citiesCard[isSelected ? "selectedBorderColor" : "borderColor"]};

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  :hover {
    border-color: ${({ theme }) => theme.citiesCard.hoverBorderColor};
  }
`;

const Image = styled.img`
  max-width: 90px;
  max-height: 50px;
  object-fit: contain;
  background-color: ${lightTheme.box.entitiesBackgroundColor};
  padding: 5px;
  box-sizing: border-box;
`;
