import { ReactComponent as Back } from "assets/icons/backLarge.svg";
import Skeleton from "components/Skeleton";
import { Heading } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import TemplateHeader from "./Header";

const ARROW_WIDTH = 20;
const ARROW_MARGIN_WIDTH = 10;

const LargeDevicesTemplate = ({ children, title, subTitle, onBack, isLoading }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const canGoBack = !!onBack;

  return (
    <>
      <TemplateHeader title={title} isLoading={isLoading} className="mb-30" />
      {(subTitle !== undefined || canGoBack) && (
        <>
          {isLoading ? (
            <Skeleton maxWidth={350} height={30} />
          ) : (
            <SubtitleContainer alignItems="center" $canGoBack={canGoBack}>
              <BackWrapper $canGoBack={canGoBack}>
                <StyledBack
                  $canGoBack={canGoBack}
                  width={ARROW_WIDTH}
                  cursor={canGoBack ? "pointer" : "auto"}
                  fill={theme.color.tertiary}
                  onClick={onBack}
                  title={t("9") /* Voltar */}
                />
              </BackWrapper>
              {subTitle && (
                <Title $canGoBack={canGoBack} color="quintenary" variant="h5">
                  {subTitle}
                </Title>
              )}
            </SubtitleContainer>
          )}
        </>
      )}
      {children}
    </>
  );
};

export default LargeDevicesTemplate;

LargeDevicesTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
  isLoading: PropTypes.bool,
  onBack: PropTypes.func,
};

LargeDevicesTemplate.defaultProps = {
  isLoading: false,
  subTitle: undefined,
  onBack: undefined,
};

const SubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -${({ $canGoBack }) => ($canGoBack ? ARROW_WIDTH + ARROW_MARGIN_WIDTH : ARROW_WIDTH)}px;
`;

const StyledBack = styled(Back)`
  margin-right: ${({ $canGoBack }) => ($canGoBack ? `${ARROW_MARGIN_WIDTH}px` : "0")};
  transition: margin 0.5s ease;
`;

const BackWrapper = styled.div`
  opacity: ${({ $canGoBack }) => ($canGoBack ? 1 : 0)};
  transition: opacity 0.5s ease;
  display: flex;
  align-items: center;
`;

const Title = styled(Heading)`
  font-size: 20px;
`;
