import { useEffect } from "react";
import { ESC, TAB } from "../utils/keys";

const getFocusableElements = (popupRef) => {
  return popupRef.current.querySelectorAll("a[href], button, textarea, input, select");
};

const usePopupAccessibility = ({ popupRef, onClose }) => {
  const handleTabKey = (e) => {
    const focusableElements = getFocusableElements(popupRef);
    const firstFocusable = focusableElements[0];
    const lastFocusable = focusableElements[focusableElements.length - 1];

    if (e.shiftKey && e.target === firstFocusable) {
      e.preventDefault();
      lastFocusable.focus();
      return;
    }

    if (e.shiftKey) {
      return;
    }

    if (e.target === lastFocusable) {
      e.preventDefault();
      firstFocusable.focus();
    }
  };

  const keyListenersMap = [
    {
      keyCode: ESC,
      operation: onClose,
    },
    {
      keyCode: TAB,
      operation: handleTabKey,
    },
  ];

  const keyListener = (e) => {
    const chooseListener = keyListenersMap.filter(
      (listener) => listener.keyCode === e.keyCode
    )[0];

    return chooseListener && chooseListener.operation(e);
  };

  useEffect(() => {
    document.addEventListener("keydown", keyListener);

    const focusableElements = getFocusableElements(popupRef);

    if (focusableElements.length > 0) {
      focusableElements[0].focus();
    }

    return () => {
      document.removeEventListener("keydown", keyListener);
    };
  }, []);

  return null;
};

export default usePopupAccessibility;
