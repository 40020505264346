import { Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as CarIcon } from "../../../../assets/icons/car.svg";
import {
  convertDateTimeToReadableDate,
  convertSecondsToTimeFormatted,
  formatDateTime,
} from "../../../../utils/dateTime";
import { getTextColorByState } from "../../../../utils/parking";
import ListCard from "../../components/cardList/Card";
import CardCell from "../../components/cardList/Cell";
import { useBackofficeThemeContext } from "../../components/ThemeProvider";
import CommonExtraInformation from "../components/CommonExtraInfo";
import { finishedParkingProps } from "../propTypes";

const FinishedParkingBox = ({
  id,
  licensePlate,
  vehicleColorId,
  startingDate,
  endingDate,
  stateName,
  stateId,
  streetName,
  explorationName,
  duration,
  amount,
  benefit,
  location,
}) => {
  const { backofficeTheme } = useBackofficeThemeContext();
  const { t } = useTranslation();

  const renderExtraInfoCallback = ({ isOpen }) => (
    <DetailsContainer>
      <div className="mt-40">
        <Text variant="body3" color="primary">
          {streetName}
        </Text>
        <Text variant="body4" color="primary">
          {explorationName}
        </Text>
      </div>
      <div className="mt-20">
        <Text variant="body4" color="primary">
          {t("8005") /* Duração estacionamento */}
        </Text>
        <Text variant="body3" color="primary">
          {convertSecondsToTimeFormatted(duration)}
        </Text>
      </div>
      <div className="mt-20 flex-center-container">
        <CardCell size="sm">
          <Text variant="body4" color="primary">
            {t("8459") /* Data de fim */}
          </Text>
          <Text variant="body3" color="primary">
            {formatDateTime(endingDate)}
          </Text>
        </CardCell>
        <CardCell size="sm">
          <Text variant="body4" color="primary">
            {t("8000") /* Montante pago */}
          </Text>
          <Text variant="body3" color="primary">
            {amount}
          </Text>
        </CardCell>
      </div>
      <CommonExtraInformation
        id={id}
        benefit={benefit}
        location={location}
        isOpen={isOpen}
      />
    </DetailsContainer>
  );

  return (
    <ListCard renderExtraInfoCallback={renderExtraInfoCallback}>
      <Icon>
        <CarIcon
          width="60px"
          height="60px"
          className="vehicle-box-icon"
          fill={backofficeTheme.carColor[vehicleColorId]}
        />
      </Icon>
      <ParkingInfo>
        <StyledText colorId={vehicleColorId} variant="body3">
          {licensePlate}
        </StyledText>
        <Text variant="body10" color="primary">
          {convertDateTimeToReadableDate(startingDate)}
        </Text>
      </ParkingInfo>
      <ParkingState>
        <Text variant="body4" color={getTextColorByState(stateId)}>
          {stateName}
        </Text>
      </ParkingState>
    </ListCard>
  );
};

export default React.memo(FinishedParkingBox);

FinishedParkingBox.propTypes = finishedParkingProps;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Icon = styled.div`
  display: flex;
`;

const ParkingInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.5;
  margin: 0 10px;
`;

const ParkingState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  margin-left: 10px;
`;

const StyledText = styled(Text)`
  color: ${({ colorId, theme }) => theme.carColor[colorId]};
`;
