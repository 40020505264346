import { usePermitHoldersContext } from "components/permitHolders/Creation/Provider";
import { Heading } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import SmallDevicesFillSpace from "../../components/SmallDevicesFillSpace";

const PermitDetailsWrapper = ({ children }) => {
  const { t } = useTranslation();
  const { selectedPermitType } = usePermitHoldersContext();

  return (
    <>
      <Wrapper>
        <Heading variant="h4" color="quintenary" className="mb-40">
          {`${t("1848") /* Dístico */} ${selectedPermitType.permitType.designation}`}
        </Heading>
        {children}
      </Wrapper>
      <SmallDevicesFillSpace />
    </>
  );
};

export default PermitDetailsWrapper;

PermitDetailsWrapper.propTypes = {
  children: PropTypes.node,
};

PermitDetailsWrapper.defaultProps = {
  children: undefined,
};

const Wrapper = styled.div`
  max-width: 700px;
  padding: 50px 40px;
  border: 2px solid ${({ theme }) => theme.color.light};

  @media (max-width: ${breakpoints.lg}) {
    max-width: 100%;
  }

  @media (max-width: ${breakpoints.sm}) {
    padding: 20px;
  }
`;
