import MapGeometry from "lib/map/geometry";
import {
  polygonConfig,
  polylineConfig,
  SELECTED_POLYGON_ALPHA,
  SELECTED_POLYLINE_ALPHA,
  UNSELECTED_POLYGON_ALPHA,
  UNSELECTED_POLYLINE_ALPHA,
} from "utils/map";
import { streetColorsMapping } from "utils/streets";

const streetLocations = {
  polyline: 1,
  polygon: 2,
};

const getStreetColor = (initialStreetColor, { theme }) => {
  if (theme.streetColor[initialStreetColor]) {
    return theme.streetColor[initialStreetColor];
  }

  return initialStreetColor || theme.streetColor[streetColorsMapping.gray];
};

class MapController {
  constructor(google, map, { theme, onStreetClick }) {
    this.google = google;
    this.map = map;
    this.theme = theme;
    this.onStreetClick = onStreetClick;
    this.bounds = new this.google.maps.LatLngBounds();
    this.streets = [];
    this.mapGeometry = new MapGeometry(google);
    this.selectStreet = this.selectStreet.bind(this);
    this.selectedStreet = undefined;
  }

  setStreets(locations) {
    this.processStreets(locations);

    this.map.fitBounds(this.bounds);
  }

  setTheme(theme) {
    this.theme = theme;
    this.updateStreetsColors();
  }

  updateStreetsColors() {
    this.streets.forEach((street) => {
      const streetColor = getStreetColor(street.color, {
        theme: this.theme,
      });

      street.polylines.forEach((polyline) => {
        polyline.setOptions({
          strokeColor: streetColor,
        });
      });

      street.polygons.forEach((polygon) => {
        polygon.setOptions({
          strokeColor: streetColor,
          fillColor: streetColor,
        });
      });
    });
  }

  getCoordinates(points) {
    const coordinates = [];

    points.forEach((point) => {
      const latLng = new this.google.maps.LatLng({
        lat: parseFloat(point.latitude),
        lng: parseFloat(point.longitude),
      });

      this.bounds.extend(latLng);

      coordinates.push(latLng);
    });

    return coordinates;
  }

  getPolyline(points) {
    return new this.google.maps.Polyline({
      ...polylineConfig,
      path: this.getCoordinates(points),
    });
  }

  getPolygon(points) {
    return new this.google.maps.Polygon({
      ...polygonConfig,
      path: this.getCoordinates(points),
    });
  }

  unselectSelectedStreet() {
    if (!this.selectedStreet) {
      return;
    }

    this.selectedStreet.polylines.forEach((polyline) => {
      polyline.setOptions({
        strokeOpacity: UNSELECTED_POLYLINE_ALPHA,
      });
    });

    this.selectedStreet.polygons.forEach((polygon) => {
      polygon.setOptions({
        fillOpacity: UNSELECTED_POLYGON_ALPHA,
      });
    });

    this.selectedStreet = null;
  }

  selectStreet(streetId) {
    this.unselectSelectedStreet();

    const street = this.streets.find((str) => str.id === streetId);

    if (!street) {
      return;
    }

    street.polylines.forEach((polyline) => {
      polyline.setOptions({
        strokeOpacity: SELECTED_POLYLINE_ALPHA,
      });
    });

    street.polygons.forEach((polygon) => {
      polygon.setOptions({
        fillOpacity: SELECTED_POLYGON_ALPHA,
      });
    });

    this.map.fitBounds(this.mapGeometry.getStreetCenteredBounds(street));

    this.selectedStreet = street;
  }

  localOnStreetClick(streetId) {
    this.selectStreet(streetId);
    this.onStreetClick(streetId);
  }

  processStreets(streets) {
    streets.forEach((street) => {
      if (street.locations.length === 0) {
        return;
      }

      const streetColor = getStreetColor(street.color, {
        theme: this.theme,
      });

      const processedStreet = {
        id: street.id,
        name: street.name,
        color: streetColor,
        polylines: [],
        polygons: [],
      };

      street.locations.forEach((location) => {
        if (location.type.id === streetLocations.polygon) {
          const polygon = this.getPolygon(location.points);

          polygon.setOptions({
            strokeColor: streetColor,
            fillColor: streetColor,
          });

          this.google.maps.event.addListener(polygon, "click", () =>
            this.localOnStreetClick(street.id)
          );

          polygon.setMap(this.map);
          processedStreet.polygons.push(polygon);
        } else if (location.type.id === streetLocations.polyline) {
          const polyline = this.getPolyline(location.points);

          polyline.setOptions({
            strokeColor: streetColor,
          });

          this.google.maps.event.addListener(polyline, "click", () =>
            this.localOnStreetClick(street.id)
          );

          polyline.setMap(this.map);
          processedStreet.polylines.push(polyline);
        }
      });

      this.streets.push(processedStreet);
    });
  }
}

export default MapController;
