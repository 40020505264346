import AbstractDataSource from "./abstractDataSource";

export default class EntityCountriesDataSource extends AbstractDataSource {
  async getAll(entityId, params = {}, headers = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/countries`,
      params,
      headers,
      useCache
    );
  }
}
