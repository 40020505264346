import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Text } from "iparque-components";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import { useAuth } from "components/AuthProvider";
import ClickableDiv from "components/ClickableDiv";
import FlexBox from "components/FlexBox";
import { breakpoints } from "utils/breakpoints";
import { useNotificationsContext } from "../Provider";
import Notification from "./Notification";
import { getAll } from "../controller";
import { actionTypes } from "../store/actions";
import { NOTIFICATIONS_REQUEST_LIMIT } from "../configs";
import useInfiniteList from "./useInfiniteList";

const NotificationsList = () => {
  const { t } = useTranslation();
  const { defaultEntityId, driverHash } = useAuth();
  const { onScroll } = useInfiniteList();
  const {
    isOpen,
    notifications,
    unreadNumber,
    markAsRead,
    close,
    dispatch,
  } = useNotificationsContext();
  const [isLoading, setIsLoading] = useState(true);
  const listRef = useRef(null);

  const hasError = notifications === undefined;
  const hasNotifications = !hasError && notifications.length > 0;
  const canMarkAllAsRead = unreadNumber > 0;
  const emptyListText = (() => {
    if (isLoading) {
      return t("10469") /* A obter as notificações... */;
    }

    return hasError
      ? t("7492") /* Ocorreu um erro ao obter as notificações */
      : t("10468"); /* Não tens notificações */
  })();

  const getInitialNotifications = useCallback(async () => {
    setIsLoading(true);

    const response = await getAll(defaultEntityId, driverHash, {
      limit: NOTIFICATIONS_REQUEST_LIMIT,
    });

    setIsLoading(false);
    dispatch({
      type: actionTypes.SET_NOTIFICATIONS,
      payload: {
        notifications: response.items,
        totalNotifications: response.totalItems || 0,
      },
    });
  }, [t, defaultEntityId, driverHash]);

  useEffect(() => {
    if (isOpen) {
      getInitialNotifications();
    }
  }, [isOpen]);

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_NOTIFICATIONS,
      payload: { notifications: [], totalNotifications: 0 },
    });
  }, [defaultEntityId]);

  useLayoutEffect(() => {
    if (isOpen && listRef.current) {
      listRef.current.scrollTop = 0;
    }
  }, [isOpen]);

  const markAllAsRead = () => {
    if (canMarkAllAsRead) {
      markAsRead(undefined, { markAllAsRead: true });
    }
  };

  return (
    <Container isOpen={isOpen} hasNotifications={false}>
      <FlexBox alignItems="center">
        <ArrowContainer onClick={close}>
          <BackArrow />
        </ArrowContainer>
        <HeaderText variant="body4" color="quintenary">
          {t("260") /* Notificações */}
        </HeaderText>
      </FlexBox>
      {hasNotifications ? (
        <>
          <ScrollableContainer id="scrollable" ref={listRef} onScroll={onScroll}>
            {notifications.map((notification) => (
              <Notification key={notification.id} {...notification} />
            ))}
          </ScrollableContainer>
          <Footer>
            <ClickableDiv onClick={markAllAsRead}>
              <MarkAsReadText>
                {canMarkAllAsRead && t("10466") /* Marcar como lidas */}
              </MarkAsReadText>
            </ClickableDiv>
          </Footer>
        </>
      ) : (
        <FlexBox alignItems="center" justifyContent="center" className="fill">
          <EmptyListText variant="body6" align="center">
            {emptyListText}
          </EmptyListText>
        </FlexBox>
      )}
    </Container>
  );
};

export default NotificationsList;

const Container = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50px;
  right: 0;
  width: 270px;
  max-height: min(calc(100vh - 100px), 800px);
  min-height: ${({ hasNotifications }) => (hasNotifications ? 300 : 100)}px;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  background-color: ${({ theme }) => theme.notifications.backgroundColor};
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);

  @media (max-width: ${breakpoints.lg}) {
    background-color: ${({ theme }) => theme.notifications.mediumDevicesBackgroundColor};
    position: fixed;
    bottom: 0;
    top: 70px;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: unset;
    max-height: unset;
    box-shadow: unset;
    -webkit-box-shadow: unset;
  }
`;

const ScrollableContainer = styled.div`
  overflow: auto;

  ::-webkit-scrollbar {
    width: 6px;

    @media (max-width: ${breakpoints.md}) {
      width: 3px;
    }
  }

  ::-webkit-scrollbar-track {
    border-left: 0;
  }

  ::-webkit-scrollbar-thumb {
    border-left: 0;
  }

  ::-webkit-scrollbar-thumb:hover {
    border-left: 0;
  }

  @media (max-width: ${breakpoints.lg}) {
    flex: 1;
  }
`;

const ArrowContainer = styled(ClickableDiv)`
  margin-left: 15px;

  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const BackArrow = styled(ArrowIcon)`
  transform-origin: top left;
  transform: rotate(90deg) translate(-35%, -100%);
  fill: ${({ theme }) => theme.notifications.headerTextColor};
`;

const HeaderText = styled(Text)`
  font-size: 10px;
  margin: 15px 30px 5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.notifications.headerTextColor};

  @media (max-width: ${breakpoints.lg}) {
    font-size: 13px;
    margin: 15px 5px;
  }
`;

const EmptyListText = styled(Text)`
  font-size: 12px;
  color: ${({ theme }) => theme.notifications.noNotificationsTextColor};
`;

const Footer = styled.div`
  align-self: flex-start;
  margin: 10px 15px 11px;

  @media (max-width: ${breakpoints.lg}) {
    margin: 15px 15px 20px;
  }
`;

const FooterText = styled(Text).attrs({
  variant: "body4",
})`
  font-size: 12px;

  :hover {
    opacity: 0.7;
  }
`;

const MarkAsReadText = styled(FooterText)`
  text-decoration: underline;
  color: ${({ theme }) => theme.notifications.headerTextColor};
`;
