import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { formatMoney } from "utils/money";
import { Text } from "iparque-components/dist/components";

const List = styled.div`
  overflow-y: auto;
  max-height: 250px;
`;

const Resume = styled.div`
  padding: 10px 15px;
  background: ${({ theme }) => theme.box.color};
  display: flex;
  width: fit-content;
  margin-left: auto;
`;

const NoticesContainer = ({ children, amount }) => {
  const { t } = useTranslation();

  return (
    <div>
      <List>{children}</List>
      {children.length > 1 && (
        <Resume className="mt-20">
          <Text>
            {`${t("9313") /* Total a pagar */}: ${formatMoney(amount, {
              centsFormat: false,
            })}`}
          </Text>
        </Resume>
      )}
    </div>
  );
};

NoticesContainer.propTypes = {
  children: PropTypes.node.isRequired,
  amount: PropTypes.number.isRequired,
};

export default NoticesContainer;
