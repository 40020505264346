import React from "react";
import StepsProvider from "domain/public/components/StepsProvider";
import DriverIdentificationProvider from "./Provider";
import DriverIdentificationSteps from "./Steps";

const DriversIdentifications = () => {
  return (
    <StepsProvider>
      <DriverIdentificationProvider>
        <DriverIdentificationSteps />
      </DriverIdentificationProvider>
    </StepsProvider>
  );
};

export default DriversIdentifications;
