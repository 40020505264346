import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "iparque-components";

export const Line = styled.div`
  display: flex;
  margin-right: 30px;
  margin: 2px 0;
`;

export const Cell = styled.div`
  flex: 2;
  white-space: nowrap;
  display: flex;
  justify-content: ${({ right }) => (right ? `flex-end` : `flex-start`)};
`;

const InlineDetailsItem = ({ label, value, small, className }) => {
  return (
    <Line className={className}>
      <Cell>
        <Text variant={small ? "body10" : "body4"}>{label}</Text>
      </Cell>
      <Cell right>
        {React.isValidElement(value) ? (
          value
        ) : (
          <Text variant={small ? "body11" : "body3"}>{value}</Text>
        )}
      </Cell>
    </Line>
  );
};

InlineDetailsItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
    .isRequired,
  small: PropTypes.bool,
  className: PropTypes.string,
};

InlineDetailsItem.defaultProps = {
  small: false,
  className: undefined,
};

export default InlineDetailsItem;
