import React from "react";
import { Heading, Text, Button } from "iparque-components";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { routesMapping } from "../../../../utils/routes";
import { emptyUnpaidNoticesProps } from "../propTypes";
import useEmptyUnpaidNotices from "../hooks/useEmptyUnpaidNotices";
import VehicleValidationRequirement from "../components/VehicleValidationRequirement";

const Container = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledHeading = styled(Heading)`
  line-height: 25px;
`;

const EmptyUnpaidNotices = ({ validatedLicencePlates, unvalidatedLicencePlates }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { title, primarySubtitle, secondarySubtitle } = useEmptyUnpaidNotices(
    validatedLicencePlates,
    unvalidatedLicencePlates
  );

  const startParking = () => {
    history.push(`${routesMapping.backofficeParking}`);
  };

  return (
    <Container>
      <StyledHeading align="center" color="tertiary" variant="h5">
        {title}
      </StyledHeading>
      <Text color="primary" className="mt-15" variant="body7">
        {primarySubtitle}
      </Text>
      {secondarySubtitle && (
        <Text color="primary" className="mt-15" variant="body7">
          {secondarySubtitle}
        </Text>
      )}
      {unvalidatedLicencePlates.length > 0 ? (
        <div className="mt-30">
          <VehicleValidationRequirement />
        </div>
      ) : (
        <Button size="sm" onClick={startParking} className="uppercase mt-30">
          {t("4045") /* Novo Estacionamento */}
        </Button>
      )}
    </Container>
  );
};

export default EmptyUnpaidNotices;

EmptyUnpaidNotices.propTypes = emptyUnpaidNoticesProps;
