import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Text, Button } from "iparque-components";
import { ReactComponent as WarningIcon } from "assets/icons/warning.svg";
import { routesMapping } from "utils/routes";
import Link from "components/Link";
import PasswordRequirementsPopup from "./PasswordRequirementsPopup";

const StyledWarningIcon = styled(WarningIcon)`
  width: 100px;
  fill: ${({ theme }) => theme.color.dark};
`;

const PasswordRequirementsErrorPopup = ({ onClose, visible }) => {
  const { t } = useTranslation();

  return (
    <PasswordRequirementsPopup
      button={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Button onClick={onClose} size="xs" color="secondary">
          {t("3442") /* Fechar */}
        </Button>
      }
      icon={<StyledWarningIcon className="mr-15" />}
      onClose={onClose}
      visible={visible}
    >
      <div>
        <Text elementType="p" color="secondary" variant="body6">
          {t("9667") /* Caro(a) condutor(a) */}
        </Text>
        <Text className="mt-10" elementType="p" color="secondary" variant="body6">
          {
            t(
              "9664"
            ) /* Para continuares a aceder à plataforma, deves proceder à recuperação da tua senha e definir uma nova que cumpra com os requisitos. */
          }
        </Text>
        <Text elementType="p" color="secondary" variant="body6">
          <b>
            <Link underline="always" to={routesMapping.passwordRecovery}>
              {t("9665") /* Clica aqui para alterares a tua senha agora */}
            </Link>
          </b>
        </Text>
      </div>
    </PasswordRequirementsPopup>
  );
};

PasswordRequirementsErrorPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default PasswordRequirementsErrorPopup;
