import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import { getParkingTypes, parkingSteps } from "./controller";
import {
  streetPropTypes,
  bottomStepsPropTypes,
  bottomStepsDefaultProps,
  selectedVehiclePropTypes,
  candidateStreetsDefaultProps,
  candidateStreetPropTypes,
  selectedVehicleDefaultProps,
} from "./propTypes";
import StepWrapper from "../components/StepWrapper";

const ParkingBottomSteps = ({
  className,
  basePath,
  onlyParkWithActiveGeographicLocationActive,
  candidateStreet,
  selectedFeeId,
  selectedVehicle,
  selectedParkingTypeId,
  selectedBenefitId,
  setSelectedStreetCallBack,
  setCandidateStreetCallback,
  setSelectedFeeCallBack,
  setSelectedVehicleCallback,
  setParkingTypeCallback,
  setBenefitCallback,
  step,
  streets,
  fees,
  popupFees,
  vehicles,
  benefits,
}) => {
  const [StreetsList, setStreetsList] = useState(null);
  const [FeesList, setFeesList] = useState(null);
  const [VehiclesList, setVehiclesList] = useState(null);
  const [ParkingTypesList, setParkingTypesList] = useState(null);
  const [BenefitsList, setBenefitsList] = useState(null);

  const { backofficeTheme } = useBackofficeThemeContext();

  useEffect(() => {
    const importStreetList = () => {
      const StreetsListComponent = React.lazy(() =>
        import(`${basePath}/StreetsList.jsx`)
      );

      setStreetsList(StreetsListComponent);
    };

    const importFeesList = async () => {
      const FeesListImport = React.lazy(() => import(`${basePath}/FeesList`));

      setFeesList(FeesListImport);
    };

    const importVehiclesList = async () => {
      const VehiclesListImport = React.lazy(() => import(`${basePath}/VehiclesList`));

      setVehiclesList(VehiclesListImport);
    };

    const importParkingTypesList = async () => {
      const ParkinTypesListImport = React.lazy(() =>
        import(`${basePath}/ParkingTypesList`)
      );

      setParkingTypesList(ParkinTypesListImport);
    };

    const importBenefitsList = async () => {
      const BenefitsListImport = React.lazy(() => import(`${basePath}/BenefitsList`));

      setBenefitsList(BenefitsListImport);
    };

    importStreetList();
    importFeesList();
    importVehiclesList();
    importParkingTypesList();
    importBenefitsList();
  }, []);

  useEffect(() => {
    const hasSelectedFee = fees.filter((fee) => fee.id === selectedFeeId).length > 0;

    if (!hasSelectedFee) {
      setSelectedFeeCallBack({ id: null, maxDuration: null });
    }
  }, [fees, selectedFeeId]);

  const renderSteps = () => {
    return (
      <div className={className}>
        {StreetsList && (
          <StepWrapper isVisible={step === parkingSteps.streets}>
            <StreetsList
              streets={streets}
              popupFees={popupFees}
              visible={step === parkingSteps.streets}
              selectedStreet={candidateStreet}
              setSelectedStreetCallBack={setSelectedStreetCallBack}
              setCandidateStreetCallback={setCandidateStreetCallback}
              onlyParkWithActiveGeographicLocationActive={
                onlyParkWithActiveGeographicLocationActive
              }
            />
          </StepWrapper>
        )}
        {FeesList && (
          <StepWrapper isVisible={step === parkingSteps.fees}>
            <FeesList
              fees={fees}
              setSelectedFeeCallBack={setSelectedFeeCallBack}
              selectedFeeId={selectedFeeId}
            />
          </StepWrapper>
        )}
        {VehiclesList && (
          <StepWrapper isVisible={step === parkingSteps.vehicles}>
            <VehiclesList
              vehicles={vehicles}
              setSelectedVehicleCallback={setSelectedVehicleCallback}
              selectedVehicle={selectedVehicle}
            />
          </StepWrapper>
        )}
        {ParkingTypesList && (
          <StepWrapper isVisible={step === parkingSteps.parkingTypes}>
            <ParkingTypesList
              setParkingTypeCallback={setParkingTypeCallback}
              selectedParkingTypeId={selectedParkingTypeId}
              parkingTypes={getParkingTypes({ theme: backofficeTheme })}
            />
          </StepWrapper>
        )}
        {BenefitsList && (
          <StepWrapper isVisible={step === parkingSteps.benefits}>
            <BenefitsList
              benefits={benefits}
              selectedBenefitId={selectedBenefitId}
              setBenefitCallback={setBenefitCallback}
            />
          </StepWrapper>
        )}
      </div>
    );
  };

  return <>{renderSteps()}</>;
};

ParkingBottomSteps.propTypes = {
  ...bottomStepsPropTypes,
  className: PropTypes.string,
  streets: streetPropTypes,
  step: PropTypes.string.isRequired,
  candidateStreet: PropTypes.shape(candidateStreetPropTypes),
  selectedVehicle: PropTypes.shape(selectedVehiclePropTypes),
};

ParkingBottomSteps.defaultProps = {
  ...bottomStepsDefaultProps,
  className: null,
  streets: [],
  candidateStreet: candidateStreetsDefaultProps,
  selectedVehicle: selectedVehicleDefaultProps,
};

export default React.memo(ParkingBottomSteps);
