import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import VerticalTabs from "../../../../components/tabs/verticals/smallDevices/Tabs";
import ContentHeader from "../../components/smallDevices/ContentHeader";
import Theme from "../Theme";
import { settingsPropTypes } from "../propTypes";
import { useAppContext } from "../../../../components/AppProvider";
import { breakpointsMapping } from "../../../../utils/breakpoints";
import LanguageTab from "../tabs/language/Tab";
import useVerticalTabs from "../hooks/useVerticalTabs";

const Settings = ({ tabs }) => {
  const { breakpoint } = useAppContext();
  const { t } = useTranslation();
  const {
    displayVerticalTabs,
    displayLanguageComponent,
    handleBackToTabs,
    handleTabClick,
  } = useVerticalTabs();

  return (
    <>
      <ContentHeader
        topTitle={t("8516") /* Este lugar é teu! */}
        bottomTitle={t("8517") /* Configura-o à tua maneira. */}
        backCallback={!displayVerticalTabs ? handleBackToTabs : null}
      />
      {displayVerticalTabs && <Theme className="mt-40" />}
      <VerticalTabs
        className={
          [breakpointsMapping.sm, breakpointsMapping.xs].includes(breakpoint)
            ? "mt-30"
            : "mt-60"
        }
        tabs={tabs}
        displayTabs={displayVerticalTabs}
        onTabClick={handleTabClick}
      />
      {displayLanguageComponent && <StyledLanguageTab />}
    </>
  );
};

export default Settings;

Settings.propTypes = settingsPropTypes;

const StyledLanguageTab = styled(LanguageTab)`
  margin: 30px 20px 0 20px;
  width: calc(100% - 40px);
  height: 180px;
`;
