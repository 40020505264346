import Text from "components/Text";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { usePermitHoldersDetailsContext } from "../Provider";

const TabComponent = ({ Component }) => {
  const { initialRequestStatus } = usePermitHoldersDetailsContext();
  const { t } = useTranslation();

  if (initialRequestStatus.isLoading) {
    return <Text variant="body6">{t("7527") /* Carregando... */}</Text>;
  }

  return <Component />;
};

export default TabComponent;

TabComponent.propTypes = {
  Component: PropTypes.func.isRequired,
};
