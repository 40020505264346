import FlexBox from "components/FlexBox";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { usePermitHoldersDetailsContext } from "../../Provider";

export const TITLE_FONT_SIZE = 13;

const Title = ({ className }) => {
  const { t } = useTranslation();
  const { reference, state } = usePermitHoldersDetailsContext();

  return (
    <FlexBox alignItems="center" className={className}>
      <StyledTitle>{`${t("1848") /* Dístico */} ${reference}`}</StyledTitle>
      <StyledTitle>&nbsp; - &nbsp;</StyledTitle>
      <StateLabel stateId={state.id}>{state.designation}</StateLabel>
    </FlexBox>
  );
};

export default Title;

Title.propTypes = {
  className: PropTypes.string.isRequired,
};

const StyledTitle = styled(Text).attrs({
  variant: "body9",
})`
  font-size: ${TITLE_FONT_SIZE}px;
  text-transform: uppercase;
`;

const StateLabel = styled(StyledTitle)`
  color: ${({ theme, stateId }) => theme.permitHolders.states[stateId]};
  text-transform: none;
`;
