import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  display: ${({ isVisible }) => (isVisible ? `block` : `none`)};
`;

const StepWrapper = ({ children, isVisible }) => {
  return <Container isVisible={isVisible}>{children}</Container>;
};

StepWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default StepWrapper;
