import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Popup } from "iparque-components";
import { breakpoints } from "utils/breakpoints";
import { AppContext } from "components/AppProvider";
import { allowedBreakpointsToDisplayPopupIcon } from "./helper";

const Container = styled.div`
  margin-top: 10px;
  max-width: 800px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.md}) {
    margin-top: 0;
  }
`;

const ButtonWrapper = styled.div`
  align-self: flex-end;
  margin: 20px 0 10px;
`;

const ContentContainer = styled.div`
  display: flex;
`;

const PasswordPopup = ({ children, icon, button, onClose, visible }) => {
  const { breakpoint } = useContext(AppContext);

  return (
    <Popup onClose={onClose} visible={visible}>
      <Container>
        <ContentContainer>
          {allowedBreakpointsToDisplayPopupIcon.includes(breakpoint) && icon}
          {children}
        </ContentContainer>
        <ButtonWrapper>{button}</ButtonWrapper>
      </Container>
    </Popup>
  );
};

PasswordPopup.propTypes = {
  children: PropTypes.node.isRequired,
  button: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default PasswordPopup;
