import Button from "components/buttons/Button";
import { Heading, Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { paymentMethodsMapping } from "utils/payment";
import { convertDateTimeToReadableDate } from "../../../utils/dateTime";
import { feeTypesMapping } from "../../../utils/fees";
import { buildQueryString, isEmpty } from "../../../utils/general";
import { parkingTypesMapping } from "../../../utils/parking";
import { routesMapping } from "../../../utils/routes";
import ListCard from "../components/cardList/Card";
import CardCell from "../components/cardList/Cell";
import ParkedCar from "../components/ParkedCar";
import ParkingEndDate from "../components/parkings/EndDate";
import SelectedBenefit from "../components/parkings/SelectedBenefit";
import ParkingTimer from "../components/parkings/Timer";
import TimerAdditionalInfo from "../components/parkings/TimerAdditionalInfo";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import useTimerInfo from "../hooks/useTimerInfo";
import TimerLabelContainer from "./components/TimerLabelContainer";
import ParkingsMap from "./Map";
import { activeParkingDefaultProps, activeParkingProps } from "./propTypes";

const ActiveParkingBox = ({
  id,
  licensePlate,
  vehicleColorId,
  streetName,
  streetId,
  explorationName,
  amount,
  paymentMethodId,
  parkingTypeName,
  parkingTypeId,
  updateCallback,
  startingDate,
  feeTypeId,
  scheduleExtraInfo,
  endingDate,
  benefit,
  location,
}) => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();
  const history = useHistory();
  const vehicleColor = backofficeTheme.carColor[vehicleColorId];
  const isMinutesFee = feeTypeId === feeTypesMapping.minutes;
  const timerInfo = useTimerInfo(id, { endCallback: () => updateCallback(false) });
  const hasBenefit = !isEmpty(benefit);
  const hasLocation = location.lat !== null && location.lng !== null;
  const hasExtraInfo = hasBenefit || hasLocation;
  const paramsQuery = buildQueryString({
    amount,
    vehicleColor,
    streetName,
    streetId,
    explorationName,
    vehicleLicensePlate: licensePlate,
    feeTypeId,
    paymentMethodId,
  });

  const isMBWayPayment = paymentMethodId === paymentMethodsMapping.mbway.id;

  const renderExtraInfoCallback = ({ isOpen }) => (
    <ExtraInfoContainer>
      <IconContainer />
      <CardCell size="md" />
      <CardCell size="md">
        {hasBenefit && (
          <>
            <Text variant="body4" color="primary">
              {t("7076") /* Benefício */}
            </Text>
            <SelectedBenefit description={benefit.name} typeId={benefit.typeId} />
          </>
        )}
      </CardCell>
      <CardCell flex={2.5}>
        {hasLocation && isOpen && (
          <>
            <Text variant="body4" color="primary">
              {t("2539") /* Localização */}
            </Text>
            <ParkingsMap id={id} location={location} />
          </>
        )}
      </CardCell>
    </ExtraInfoContainer>
  );

  return (
    <StyledListCard
      renderExtraInfoCallback={hasExtraInfo ? renderExtraInfoCallback : null}
      preserveArrowWidth
    >
      <IconContainer>
        <ParkedCar color={vehicleColor} />
      </IconContainer>
      <CardCell size="md">
        <div className="flex-baseline-container">
          <StyledHeading colorId={vehicleColorId} variant="h4">
            {licensePlate}
          </StyledHeading>
          <Text variant="body9" className="ml-15">
            {convertDateTimeToReadableDate(startingDate)}
          </Text>
        </div>
        <Text variant="body1" color="primary">
          {streetName}
        </Text>
        <Text variant="body4" color="primary">
          {explorationName}
        </Text>
      </CardCell>
      <CardCell size="md">
        <TimerLabelContainer>
          <Text className="inline" variant="body10">
            {t("54") /* Estacionamento */}
          </Text>
          &nbsp;
          <Text className="inline" variant="body9">
            {parkingTypeName}
          </Text>
          <TimerAdditionalInfo
            state={timerInfo?.state}
            nextStart={timerInfo?.nextStart}
            variant="body10"
          />
        </TimerLabelContainer>
        <ParkingTimer
          parkingId={id}
          parkingTypeId={parkingTypeId}
          startingDate={startingDate}
          endingDate={endingDate}
          scheduleExtraInfo={scheduleExtraInfo}
          size="md"
        />
      </CardCell>
      <CardCell size="md">
        <Text variant="body4" color="primary">
          {t("615") /* Montante a pagar */}
        </Text>
        <Heading color="tertiary" variant="h2">
          {amount}
        </Heading>
        <ParkingEndDate parkingTypeId={parkingTypeId} endingDate={endingDate} />
      </CardCell>
      <CardCell size="sm" align="right">
        <CardButtonsContainer>
          {parkingTypeId !== parkingTypesMapping.startAndStop && (
            <Button
              size="xs"
              fullWidth
              color="info"
              onClick={() => {
                history.push(
                  `${routesMapping.backofficeParking}/${id}/renew?${paramsQuery}`
                );
              }}
            >
              {t("46") /* Renovar */}
            </Button>
          )}
          {isMinutesFee && !isMBWayPayment && (
            <Button
              size="xs"
              fullWidth
              color="danger"
              onClick={() => {
                history.push(
                  `${routesMapping.backofficeParking}/${id}/stop?${paramsQuery}`
                );
              }}
            >
              {t("3216") /* Parar */}
            </Button>
          )}
        </CardButtonsContainer>
      </CardCell>
    </StyledListCard>
  );
};

export default React.memo(ActiveParkingBox);

ActiveParkingBox.defaultProps = activeParkingDefaultProps;

ActiveParkingBox.propTypes = activeParkingProps;

const StyledListCard = styled(ListCard)`
  margin-left: 40px;

  :first-child {
    margin-top: 25px;
  }

  .list-card-extra-info-container {
    margin-top: 40px;
  }
`;

const ExtraInfoContainer = styled.div`
  height: 270px; /* This height is mandatory to the animation */
  display: flex;
  width: 100%;
`;

const IconContainer = styled.div`
  margin-top: -30px;
  margin-left: -50px;
  min-width: 185px;
`;

const StyledHeading = styled(Heading)`
  color: ${({ colorId, theme }) => theme.carColor[colorId]};
`;

const CardButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 140px;
`;
