import { getTokens } from "../../../../utils/auth";
import AbstractDataSource from "./abstractDataSource";

export default class ParkingsDataSource extends AbstractDataSource {
  async start(entityId, streetId, params = {}) {
    return this.iParqueClient.POST(
      `v1/entities/${entityId}/streets/${streetId}/parkings/start`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }

  async stop(
    entityId,
    streetId,
    parkingId,
    params = {},
    headers = {},
    useCache = false,
    cancelToken = null
  ) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/streets/${streetId}/parkings/${parkingId}/stop`,
      params,
      {
        ...headers,
        Authorization: getTokens().token,
      },
      useCache,
      cancelToken
    );
  }

  async calculate(entityId, streetId, params) {
    return this.iParqueClient.POST(
      `v1/entities/${entityId}/streets/${streetId}/parkings/calculate`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }

  async create(entityId, streetId, params = {}) {
    return this.iParqueClient.POST(
      `v1/entities/${entityId}/streets/${streetId}/parkings`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }

  async renew(entityId, streetId, parkingId, params = {}) {
    return this.iParqueClient.POST(
      `v1/entities/${entityId}/streets/${streetId}/parkings/${parkingId}`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }
}
