import React from "react";
import PropTypes from "prop-types";
import { CREATION_CONTEXT } from "./helper";
import PermitHoldersProvider, { entityPropTypes } from "./Provider";
import PermitHoldersSteps, { permitHolderStepsPropTypes } from "./Steps";

const PermitHolderCreationFlow = ({
  entity,
  isEntityDataLoading,
  creationContext,
  ...stepsProps
}) => {
  return (
    <PermitHoldersProvider
      entity={entity}
      creationContext={creationContext}
      isEntityDataLoading={isEntityDataLoading}
    >
      <PermitHoldersSteps {...stepsProps} />
    </PermitHoldersProvider>
  );
};

export default PermitHolderCreationFlow;

PermitHolderCreationFlow.propTypes = {
  ...permitHolderStepsPropTypes,
  ...entityPropTypes,
  creationContext: PropTypes.oneOf(Object.values(CREATION_CONTEXT)),
};

PermitHolderCreationFlow.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  entity: undefined,
  creationContext: CREATION_CONTEXT.public,
};
