import React from "react";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "iparque-components";
import { Route } from "react-router-dom";
import useIsPublicPage from "hooks/useIsPublicPage";
import publicTheme from "../../themes/public";
import LandingPage from "./landing/Page";
import Login from "./login/Page";
import Registry from "./Registry";
import RegistryConclusion from "./RegistryConclusion";
import RegistryActivation from "./RegistryActivation";
import PasswordRecovery from "./PasswordRecovery";
import PasswordDefinition from "./PasswordDefinition";
import { routesMapping } from "../../utils/routes";
import PublicEntities from "./entities";
import DriversIdentifications from "./driversIdentifications";

const PublicPage = () => {
  const isPublicPage = useIsPublicPage();

  return (
    <ThemeProvider theme={publicTheme}>
      <Route exact path={routesMapping.landing}>
        <LandingPage />
      </Route>
      <Route path={routesMapping.login}>
        <Login />
      </Route>
      <Route path={routesMapping.registry}>
        <Registry />
      </Route>
      <Route path={routesMapping.registryConclusion}>
        <RegistryConclusion />
      </Route>
      <Route path={routesMapping.registryActivation}>
        <RegistryActivation />
      </Route>
      <Route path={routesMapping.passwordRecovery}>
        <PasswordRecovery />
      </Route>
      <Route path={routesMapping.passwordDefinition}>
        <PasswordDefinition />
      </Route>
      <Route path={routesMapping.publicEntities}>
        <PublicEntities />
      </Route>
      <Route path={routesMapping.driversIdentifications}>
        <DriversIdentifications />
      </Route>
      {isPublicPage && <ToastContainer />}
    </ThemeProvider>
  );
};

export default PublicPage;
