import PropTypes from "prop-types";

export const accountMovementsProps = {
  getBalanceHistoryCallback: PropTypes.func.isRequired,
  getPaymentsHistoryCallback: PropTypes.func.isRequired,
  vehiclesColors: PropTypes.objectOf(PropTypes.number),
};

export const accountMovementsDefaultProps = {
  vehiclesColors: null,
};

export const movementsListProps = {
  getDataCallback: PropTypes.func.isRequired,
  vehiclesColors: PropTypes.objectOf(PropTypes.number),
  className: PropTypes.string,
};

export const movementsListDefaultProps = {
  vehiclesColors: null,
  className: "",
};

const invoicePropTypes = {
  number: PropTypes.string,
  url: PropTypes.string,
};

export const movementCardPropTypes = {
  description: PropTypes.string.isRequired,
  paymentMethodId: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  dateTime: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  balanceChargeId: PropTypes.number,
  licensePlate: PropTypes.string,
  invoice: PropTypes.shape(invoicePropTypes),
  vehiclesColors: PropTypes.objectOf(PropTypes.number),
};

export const movementCardDefaultProps = {
  balanceChargeId: null,
  licensePlate: null,
  vehiclesColors: null,
};
