import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import useLanguage from "hooks/useLanguage";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getPermitHolderDetails } from "requests/permitHolders";
import { routesMapping } from "utils/routes";

const useDetails = () => {
  const language = useLanguage();
  const { t } = useTranslation();
  const { driverHash, defaultEntityId: entityId } = useAuth();
  const { setIsLoading } = useAppContext();
  const history = useHistory();

  const display = useCallback(
    async (permitHolderId) => {
      setIsLoading(true);

      const details = await getPermitHolderDetails(
        entityId,
        driverHash,
        permitHolderId,
        {},
        {
          language,
          t,
        }
      );

      setIsLoading(false);

      if (!details) {
        return;
      }

      history.push(`${routesMapping.backofficePermitHolders}/${permitHolderId}`, details);
    },
    [history, entityId, driverHash, language, t]
  );

  return { displayDetails: display };
};

export default useDetails;
