import React from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import { formatIban } from "utils/formatters";
import { paymentMethodsMapping } from "utils/payment";
import { Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import FlexBox from "components/FlexBox";
import ClipboardIcon from "components/ClipboardIcon";
import { useAppContext } from "components/AppProvider";
import { breakpointsMapping } from "utils/breakpoints";
import { formatDateTime } from "utils/dateTime";
import { formatMoney } from "utils/money";
import InlineDetail from "./InlineDetail";
import PaymentMethodBox from "./PaymentBox";
import PaymentMethodIcon from "./PaymentIcon";

const BankTransferencePaymentBox = ({
  shortMessage,
  longMessage,
  title,
  onClick,
  isLoading,
  amount,
  deadline,
  iban,
}) => {
  const { t } = useTranslation();
  const { breakpoint } = useAppContext();

  const smallVariant = [
    breakpointsMapping.sm,
    breakpointsMapping.xs,
    breakpointsMapping.md,
  ].includes(breakpoint);

  const amountFormatted = formatMoney(amount, { centsFormat: false });

  const extraInfo = (
    <FlexBox fullWidth flexDirection="column">
      <InlineDetail
        smallValueSize={smallVariant}
        isLoading={isLoading}
        title={t("2338") /* IBAN */}
        value={
          iban && (
            <>
              {formatIban(iban)}
              &nbsp;
              <ClipboardIcon width="16px" height="18px" text={iban} />
            </>
          )
        }
      />
      <InlineDetail
        onClick={(event) => event.stopPropagation()}
        isLoading={isLoading}
        title={t("4912") /* Montante */}
        value={
          <>
            {amountFormatted}
            &nbsp;
            <ClipboardIcon width="16px" height="18px" text={amountFormatted} />
          </>
        }
      />
      <InlineDetail
        isLoading={isLoading}
        className="mt-10"
        title={t("9677") /* Data limite */}
        value={deadline ? formatDateTime(deadline) : "N/A"}
      />
      {longMessage && (
        <div>
          <Text className="mt-10" variant="body5" color="danger">
            {t("553") /* Atenção */}
          </Text>
          <Text variant="body11">{ReactHtmlParser(longMessage)}</Text>
        </div>
      )}
    </FlexBox>
  );

  return (
    <PaymentMethodBox
      icon={
        <PaymentMethodIcon
          size="large"
          paymentMethodId={paymentMethodsMapping.bankTransference.id}
        />
      }
      shortMessage={shortMessage}
      title={title}
      extraInfo={extraInfo}
      onClick={onClick}
    />
  );
};

BankTransferencePaymentBox.defaultProps = {
  shortMessage: undefined,
  longMessage: undefined,
  amount: undefined,
  deadline: undefined,
  iban: undefined,
  isLoading: false,
};

BankTransferencePaymentBox.propTypes = {
  title: PropTypes.string.isRequired,
  shortMessage: PropTypes.string,
  longMessage: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  amount: PropTypes.number,
  deadline: PropTypes.string,
  iban: PropTypes.string,
};

export default BankTransferencePaymentBox;
