import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { FALLBACK_LANGUAGE } from "./utils/languages";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      backend: {
        loadPath: process.env.REACT_APP_TRANSLATION_PATH,
      },
      load: "languageOnly",
      fallbackLng: FALLBACK_LANGUAGE,
      debug: false,
      react: {
        wait: true,
      },
    },
    (err) => {
      if (err) {
        throw err;
      }

      return null;
    }
  );

export default i18n;
