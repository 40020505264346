import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tabsIdsMap } from "../helper";

const TabTitle = ({ tabId, className }) => {
  const { t } = useTranslation();

  const title = useMemo(
    () =>
      ({
        [tabsIdsMap.general]: "",
        [tabsIdsMap.vehicles]: t("10497") /* Veículos associados */,
        [tabsIdsMap.documents]: t("4195") /* Documentos Associados */,
        [tabsIdsMap.locations]: t("10461") /* Locais onde é permitido estacionar */,
        [tabsIdsMap.subscriptions]: t("10481") /* Consultar subscrições */,
        [tabsIdsMap.history]: t("10553") /* Histórico de operações */,
      }[tabId]),
    [tabId]
  );

  return (
    <Text className={`mb-20 ${className}`} variant="body7">
      {title}:
    </Text>
  );
};

export default TabTitle;

TabTitle.propTypes = {
  className: PropTypes.string,
  tabId: PropTypes.oneOf(Object.values(tabsIdsMap)).isRequired,
};

TabTitle.defaultProps = {
  className: "",
};
