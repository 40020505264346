import PermitHoldersDataSource from "lib/clients/iParque/dataSources/permitHolders";
import {
  hasErrorCode,
  MISSING_PERMIT_HOLDERS_SUBSCRIPTIONS,
  MISSING_PERMIT_HOLDER_HISTORY,
  MISSING_PERMIT_HOLDER_VEHICLES,
} from "utils/error";
import { errorMessage } from "utils/userMessages";
import { processVehicles as processVehiclesOptions } from "../controllers/vehicles";

const permitHoldersDataSource = new PermitHoldersDataSource();

const processSubscriptions = (subscriptions) =>
  subscriptions.map((subscription) => ({
    id: subscription.id,
    cardNumber: subscription.number,
    licencePlates:
      subscription.licensePlates?.length > 0
        ? subscription.licensePlates.join("; ")
        : "-",
    startingDate: subscription.startDate,
    endDate: subscription.endDate,
    amount: subscription.amount,
    state: subscription.state,
  }));

const processVehicles = (vehicles) =>
  vehicles.map((vehicle) => ({
    id: vehicle.id,
    licencePlate: vehicle.licensePlate,
  }));

const processHistory = (history) =>
  history.map((item) => ({
    id: item.id,
    dateTime: item.date,
    operation: item.operation,
    userName: item.user?.name,
  }));

const processDocuments = (documents) =>
  documents.map((item) => ({
    id: item.id,
    typeName: item.type.description,
    url: item.attachmentUrl,
  }));

export const getCoverage = async (entityId, driverHash, permitHolderId, { t }) => {
  try {
    const response = await permitHoldersDataSource.getCoverage(
      entityId,
      driverHash,
      permitHolderId,
      { noLimit: true, fillCollections: "zone" }
    );

    return response.items;
  } catch (error) {
    errorMessage(error, t("10462") /* Ocorreu um erro ao obter os locais autorizados */);

    return null;
  }
};

export const getSubscriptions = async (
  entityId,
  driverHash,
  permitHolderId,
  params = {},
  { t }
) => {
  try {
    const response = await permitHoldersDataSource.getSubscriptions(
      entityId,
      driverHash,
      permitHolderId,
      { ...params, fillCollections: "state" }
    );

    return {
      items: processSubscriptions(response.items),
      totalPages: response.totalPages,
    };
  } catch (error) {
    if (hasErrorCode(error, MISSING_PERMIT_HOLDERS_SUBSCRIPTIONS)) {
      return {
        items: [],
        totalPages: 0,
      };
    }

    errorMessage(
      error,
      t("10482") /* Ocorreu um erro ao obter as subscrições associadas ao dístico */
    );

    return { hasError: true };
  }
};

export const getDocuments = async (
  entityId,
  driverHash,
  permitHolderId,
  params = {},
  { t }
) => {
  try {
    const response = await permitHoldersDataSource.getDocuments(
      entityId,
      driverHash,
      permitHolderId,
      { ...params, fillCollections: "type" }
    );

    return {
      items: processDocuments(response.items),
      totalPages: response.totalPages,
    };
  } catch (error) {
    errorMessage(
      error,
      t("10559") /* Ocorreu um erro ao obter os documentos associados ao dístico */
    );

    return { hasError: true };
  }
};

export const getHistory = async (
  entityId,
  driverHash,
  permitHolderId,
  params = {},
  { t }
) => {
  try {
    const response = await permitHoldersDataSource.getHistory(
      entityId,
      driverHash,
      permitHolderId,
      { ...params, fillCollections: "user" }
    );

    return {
      items: processHistory(response.items),
      totalPages: response.totalPages,
    };
  } catch (error) {
    if (hasErrorCode(error, MISSING_PERMIT_HOLDER_HISTORY)) {
      return {
        items: [],
        totalPages: 0,
      };
    }

    errorMessage(
      error,
      t("10554") /* Ocorreu um erro ao obter o histórico de alterações do dístico */
    );

    return { hasError: true };
  }
};

export const getVehiclesOptions = async (
  entityId,
  driverHash,
  permitHolderId,
  params = {},
  { t }
) => {
  try {
    const response = await permitHoldersDataSource.getVehiclesOptions(
      entityId,
      driverHash,
      permitHolderId,
      params
    );

    return processVehiclesOptions(response.items);
  } catch (error) {
    errorMessage(
      error,
      t("10502") /* Ocorreu um erro ao obter os veículos disponíveis ao dístico */
    );

    return null;
  }
};

export const getVehicles = async (
  entityId,
  driverHash,
  permitHolderId,
  params = {},
  { t }
) => {
  try {
    const response = await permitHoldersDataSource.getVehicles(
      entityId,
      driverHash,
      permitHolderId,
      params
    );

    return processVehicles(response.items);
  } catch (error) {
    if (hasErrorCode(error, MISSING_PERMIT_HOLDER_VEHICLES)) {
      return [];
    }

    errorMessage(
      error,
      t("10499") /* Ocorreu um erro ao obter os veículos associados ao dístico */
    );

    return null;
  }
};
