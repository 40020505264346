import { useTranslation } from "react-i18next";

const useEmptyUnpaidNotices = (validatedLicencePlates, unvalidatedLicencePlates) => {
  const { t } = useTranslation();

  const hasUnvalidatedVehicles = unvalidatedLicencePlates.length > 0;

  const title = hasUnvalidatedVehicles
    ? t("10096") /* Parabéns! */
    : t("8424"); /* Continua a ser um condutor exemplar! */

  const primarySubtitle = (() => {
    if (!hasUnvalidatedVehicles) {
      return t("7830"); /* Vamos estacionar? */
    }

    if (validatedLicencePlates.length === 1) {
      return t("10087", {
        licencePlate: validatedLicencePlates[0],
      }); /* O teu veículo {{licencePlate}} está validado e sem infrações. */
    }

    return t("10088", {
      licencePlates: validatedLicencePlates.join(", "),
    }); /* Os teus veículos {{licencePlates}} estão validados e sem infrações. */
  })();

  const secondarySubtitle = (() => {
    if (!hasUnvalidatedVehicles) {
      return null;
    }

    if (unvalidatedLicencePlates.length === 1) {
      return t("10089", {
        licencePlate: unvalidatedLicencePlates[0],
      }); /* Valida o veículo {{licencePlate}} para verificares se tens infrações. */
    }

    return t("10090", {
      licencePlates: unvalidatedLicencePlates.join(", "),
    }); /* Valida os veículos {{licencePlates}} para verificares se tens infrações. */
  })();

  return { title, primarySubtitle, secondarySubtitle };
};

export default useEmptyUnpaidNotices;
