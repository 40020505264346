import Button from "components/buttons/Button";
import { Heading, Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";

const RequirementCard = ({ index, label, button }) => {
  return (
    <Container>
      <TextContainer alignItems="center">
        <Heading color="secondary" variant="h6">
          {index}.
        </Heading>
        &nbsp;&nbsp;
        <Label variant="body3" color="quintenary">
          {label}
        </Label>
      </TextContainer>
      {button && (
        <ButtonContainer className="button-container">
          <Button size="sm" className="ml-10" onClick={button.action}>
            {button.label}
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default RequirementCard;

RequirementCard.propTypes = {
  index: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }),
};

RequirementCard.defaultProps = {
  button: undefined,
};

const Container = styled.div`
  border: 2px solid ${({ theme }) => theme.requirements.border};
  background-color: ${({ theme }) => theme.requirements.background};
  padding: 15px;
  width: 620px;
  min-height: 100px;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    flex-direction: column;

    .button-container {
      margin-top: 15px;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.md}) {
    align-self: start;
  }
`;

const Label = styled(Text)`
  color: ${({ theme }) => theme.requirements.label};
`;

const ButtonContainer = styled.div`
  margin-left: auto;

  > button {
    min-width: 195px;
  }
`;
