import React from "react";
import PropTypes from "prop-types";
import GeneralQRCode from "../../../components/QRCode";
import CornerBorderBox from "./CornerBorderBox";
import { useBackofficeThemeContext } from "./ThemeProvider";

const QRCode = ({ width, height, value, className }) => {
  const { backofficeTheme } = useBackofficeThemeContext();

  return (
    <CornerBorderBox className={className} cornerColor={backofficeTheme.color.secondary}>
      <GeneralQRCode
        width={width}
        height={height}
        value={value}
        backgroundColor={backofficeTheme.qrCode.backgroundColor}
        figureColor={backofficeTheme.qrCode.figureColor}
      />
    </CornerBorderBox>
  );
};

QRCode.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

QRCode.defaultProps = {
  width: null,
  height: null,
  className: "",
};

export default QRCode;
