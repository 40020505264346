import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { permitHolderStates } from "utils/permitHolders";
import { warningsMap } from "../../helper";
import { usePermitHoldersDetailsContext } from "../../Provider";
import MissingDocumentsWarning from "./MissingDocuments";
import MissingVehicles from "./MissingVehicles";
import RejectionWarning from "./Rejection";
import SendToAnalysisWarning from "./SendToAnalysis";
import ExpiredDocumentsWarning from "./ExpiredDocuments";

const Warnings = ({ className }) => {
  const { warnings, state } = usePermitHoldersDetailsContext();

  const WarningsItems = useMemo(() => {
    const items = [];

    if (state.id === permitHolderStates.approved) {
      return items;
    }

    const getComponent = (warningKey) => {
      if (warningKey === warningsMap.rejection) {
        return <RejectionWarning {...warnings[warningsMap.rejection]} />;
      }

      if (
        warningKey === warningsMap.missingDocuments &&
        warnings[warningsMap.missingDocuments].length
      ) {
        return (
          <MissingDocumentsWarning documents={warnings[warningsMap.missingDocuments]} />
        );
      }

      if (
        warningKey === warningsMap.expiredDocuments &&
        warnings[warningsMap.expiredDocuments].length
      ) {
        return (
          <ExpiredDocumentsWarning documents={warnings[warningsMap.expiredDocuments]} />
        );
      }

      if (warningKey === warningsMap.missingVehicles) {
        return <MissingVehicles />;
      }

      return null;
    };

    for (let index = 0; index < Object.keys(warnings).length; index += 1) {
      const warningKey = Object.keys(warnings)[index];
      const component = getComponent(warningKey);

      if (component) {
        items.push(React.cloneElement(getComponent(warningKey), { key: warningKey }));
      }
    }

    if (items.length === 0 && state.id === permitHolderStates.underElaboration) {
      items.push(<SendToAnalysisWarning key={warningsMap.analysisAlert} />);
    }

    return items;
  }, [state, warnings]);

  if (WarningsItems.length === 0) {
    return null;
  }

  return <AlertsContainer className={className}>{WarningsItems}</AlertsContainer>;
};

export default Warnings;

Warnings.propTypes = {
  className: PropTypes.string,
};

Warnings.defaultProps = {
  className: "",
};

const AlertsContainer = styled.div`
  .warning-alert {
    :not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`;
