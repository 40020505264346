import moment from "moment";
import i18n from "i18next";
import { countriesMapping } from "./countries";
import { languageWithoutRegion } from "./general";
import { zeroPad } from "./number";

const language = languageWithoutRegion(i18n.language || window.localStorage.i18nextLng);
const minutesInHours = 60;

export const serverDateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const serverDateFormat = "YYYY-MM-DD";
export const minuteInSeconds = 60;
export const minuteInMilliseconds = 1000 * 60;
export const secondInMilliseconds = 1000;
const NUMBER_OF_CHARS_OF_SERVER_DATE_TIME_STRING = 19;

const countriesFormats = {
  [countriesMapping.pt]: {
    date: "DD/MM/YYYY",
    readableDate: "dd/mm/yyyy",
    mask: "__/__/____",
    dateTime: "DD/MM/YYYY HH:mm",
    time: "HH:mm",
  },
  [countriesMapping.es]: {
    date: "DD/MM/YYYY",
    readableDate: "dd/mm/yyyy",
    mask: "__/__/____",
    dateTime: "DD/MM/YYYY HH:mm",
    time: "HH:mm",
  },
  [countriesMapping.en]: {
    date: "DD/MM/YY",
    readableDate: "dd/mm/yy",
    mask: "__/__/__",
    dateTime: "DD/MM/YY HH:mm",
    time: "HH:mm",
  },
};

export const formatDate = (date, country = [countriesMapping[language]]) => {
  if (!date) {
    return "-";
  }

  return moment(date).format(countriesFormats[country].date);
};

const formatTime = (date, country = [countriesMapping[language]]) => {
  if (!date) {
    return "-";
  }

  return moment(date).format(countriesFormats[country].time);
};

export const formatDateTime = (date, country = [countriesMapping[language]]) => {
  return moment(date, serverDateTimeFormat).format(countriesFormats[country].dateTime);
};

export const formatToServerDateTime = (date, country = [countriesMapping[language]]) => {
  return moment(date, countriesFormats[country].date).format(serverDateTimeFormat);
};

export const formatToServerDate = (date, country = [countriesMapping[language]]) => {
  return moment(date, countriesFormats[country].date).format(serverDateFormat);
};

export const getCountryConfig = (country = [countriesMapping[language]]) => {
  return countriesFormats[country];
};

export const isValidDateFormat = (date, country = [countriesMapping[language]]) => {
  if (!date) {
    return true;
  }

  return moment(date, countriesFormats[country].date, true).isValid();
};

const compareDates = (startDate, endDate, country = [countriesMapping[language]]) => {
  const startDateObject = moment(startDate, countriesFormats[country].date);
  const endDateObject = moment(endDate, countriesFormats[country].date);

  if (startDateObject < endDateObject) {
    return 1;
  }

  if (startDateObject > endDateObject) {
    return -1;
  }

  return 0;
};

export const maxDateValidation = (
  targetDate,
  maxDate,
  country = [countriesMapping[language]]
) => {
  if (!targetDate || !maxDate) {
    return true;
  }

  return compareDates(targetDate, maxDate, country) !== -1;
};

export const minDateValidation = (
  targetDate,
  minDate,
  country = [countriesMapping[language]]
) => {
  if (!targetDate || !minDate) {
    return true;
  }

  return compareDates(targetDate, minDate, country) === -1;
};

export const getSecondsBetweenTwoDates = (
  startDate,
  endDate,
  { format = serverDateTimeFormat } = {}
) => {
  return Math.round(
    moment.duration(moment(endDate, format).diff(moment(startDate, format))).asSeconds()
  );
};

export const getMinutesBetweenTwoDates = (
  startDate,
  endDate,
  { format = serverDateTimeFormat } = {}
) => {
  return Math.round(
    moment.duration(moment(endDate, format).diff(moment(startDate, format))).asMinutes()
  );
};

export const secondsToTime = (seconds, { padNumbers = true } = {}) => {
  const units = {
    days: Math.floor(seconds / (minuteInSeconds * minuteInSeconds * 24)),
    hours: Math.floor((seconds / (minuteInSeconds * minuteInSeconds)) % 24),
    minutes: Math.floor((seconds / minuteInSeconds) % minuteInSeconds),
    seconds: Math.floor(seconds % minuteInSeconds),
  };

  return padNumbers
    ? {
        days: units.days,
        hours: zeroPad(units.hours, 2),
        minutes: zeroPad(units.minutes, 2),
        seconds: zeroPad(units.seconds, 2),
      }
    : units;
};

export const convertTimeToSeconds = ({ minutes, hours }) => {
  return (
    (parseInt(hours, 10) * minuteInSeconds + parseInt(minutes, 10)) * minuteInSeconds
  );
};

export const convertHoursToMinutes = (hours) => {
  return hours * minutesInHours;
};

export const convertMinutesToSeconds = (minutes) => {
  return minutes * minuteInSeconds;
};

export const convertSecondsToMinutes = (seconds) => {
  return Math.floor(seconds / minuteInSeconds);
};

export const convertSecondsToReadableTime = (seconds, { avoidSeconds = false } = {}) => {
  const time = secondsToTime(seconds);

  return `${time.hours}:${time.minutes}${!avoidSeconds ? `:${time.seconds}` : ``}`;
};

export const convertSecondsToTimeFormatted = (seconds) => {
  const time = secondsToTime(seconds);
  let dayInfo = "";

  if (time.days === 1) {
    dayInfo = `1 ${i18n.t("8012") /* dia */} `;
  } else if (time.days > 1) {
    dayInfo = `${time.days} ${i18n.t("1297") /* dias */} `;
  }

  return `${dayInfo}${time.hours}:${time.minutes} ${i18n.t("1553") /* min */}`;
};

export const convertSecondsToMilliseconds = (seconds) => {
  if (!seconds) {
    return 0;
  }

  return seconds * secondInMilliseconds;
};

export const getCurrentDateInServerFormat = () => {
  return moment().format(serverDateFormat);
};

export const getCurrentDateTimeInServerFormat = () => {
  return moment().format(serverDateTimeFormat);
};

export const compareServerDateTimes = (startDate, endDate) => {
  const startDateObject = moment(startDate, serverDateTimeFormat);
  const endDateObject = moment(endDate, serverDateTimeFormat);

  if (startDateObject < endDateObject) {
    return 1;
  }

  if (startDateObject > endDateObject) {
    return -1;
  }

  return 0;
};

export const convertDateTimeToReadableDate = (
  dateTime,
  format = serverDateTimeFormat,
  country = [countriesMapping[language]]
) => {
  if (!dateTime) {
    return "-";
  }

  return moment(dateTime, format)
    .calendar({
      lastWeek(now) {
        const days = now.diff(this, "days");

        return `[${
          i18n.t("9500", {
            days,
            hours: formatTime(dateTime, country),
          }) /* há {{days}} dias às {{hours}} */
        }]`;
      },
      nextWeek: `[${i18n.t("5670") /* às */}] HH:mm, ${countriesFormats[country].date}`,
      sameElse(now) {
        if (this.isBefore(now)) {
          return countriesFormats[country].dateTime;
        }

        return `[${i18n.t("5670") /* às */}] HH:mm, ${countriesFormats[country].date}`;
      },
    })
    .toLowerCase();
};

export const convertMinutesToReadableTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (remainingMinutes === 0) {
    return `${hours}h`;
  }

  if (hours < 1) {
    return `${remainingMinutes} ${i18n.t("1553") /* min */}`;
  }

  return `${hours}h ${remainingMinutes} ${i18n.t("1553") /* min */}`;
};

export const getMonthLabel = (monthNumber) =>
  moment()
    .month(monthNumber - 1)
    .format("MMMM");

export const getTimeFromDateTime = (date) => {
  const d = new Date(date);

  return d.toLocaleTimeString();
};

export const removeMilliseconds = (dateTime) =>
  dateTime.slice(0, NUMBER_OF_CHARS_OF_SERVER_DATE_TIME_STRING);
