import { css } from "styled-components";
import { breakpoints } from "./breakpoints";

export const getBodyScrollStyle = (theme) => css`
  ::-webkit-scrollbar {
    width: 10px;
    background: transparent;

    @media (max-width: ${breakpoints.md}) {
      width: 3px;
    }
  }

  ::-webkit-scrollbar-track {
    background: ${theme.scroll.trackColor};
  }

  ::-webkit-scrollbar-thumb {
    background: ${`${theme.scroll.thumbColor}EE`};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${`${theme.scroll.thumbColor}AA`};
  }
`;

export const getScrollStyle = (theme) => css`
  ::-webkit-scrollbar {
    width: 16px;
    background: transparent;

    @media (max-width: ${breakpoints.md}) {
      width: 6px;
    }
  }

  ::-webkit-scrollbar-track {
    background: ${theme.scroll.trackColor};
    background-clip: padding-box;
    border-left: 6px solid transparent;

    @media (max-width: ${breakpoints.md}) {
      border-left: 3px solid transparent;
    }
  }

  ::-webkit-scrollbar-thumb {
    background: ${`${theme.scroll.thumbColor}EE`};
    background-clip: padding-box;
    border-left: 6px solid rgba(0, 0, 0, 0);

    @media (max-width: ${breakpoints.md}) {
      border-left: 3px solid transparent;
    }
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${`${theme.scroll.thumbColor}AA`};
    background-clip: padding-box;
    border-left: 6px solid rgba(0, 0, 0, 0);

    @media (max-width: ${breakpoints.md}) {
      border-left: 3px solid transparent;
    }
  }
`;
