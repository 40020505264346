import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useState } from "react";
import { setNetworkConnectionCallbacks } from "./helper";
import Overlay from "./Overlay";

const NetInfoContext = createContext({
  hasInternetAccess: true,
});

const NetInfoProvider = ({ children }) => {
  const [hasInternetAccess, setHasInternetAccess] = useState(true);

  useEffect(() => {
    setNetworkConnectionCallbacks(
      () => window.location.reload(),
      () => setHasInternetAccess(false)
    );
  }, []);

  return (
    <NetInfoContext.Provider value={{ hasInternetAccess }}>
      {children}
      <Overlay visible={!hasInternetAccess} />
    </NetInfoContext.Provider>
  );
};

NetInfoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NetInfoProvider;
export const useNetInfoContext = () => useContext(NetInfoContext);
