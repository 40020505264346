import { driverNormal, driverNormalColors as colors } from "iparque-components";
import { parkingTypesMapping } from "utils/parking";
import {
  permitHoldersSubscriptionsStates,
  permitHolderStates,
} from "utils/permitHolders";
import { benefitTypesMapping } from "../utils/benefits";
import { colorsMapping } from "../utils/colors";
import { streetColorsMapping } from "../utils/streets";
import parkingRequirementsAnimation from "./animations/parkingRequirements/light";
import mapTheme from "./maps/normal";

const backgroundColor = colors.lightest;

const normal = {
  ...driverNormal,
  color: colors,
  map: mapTheme,
  background: {
    color: {
      menu: colors.secondary,
      smallDevicesMenu: colors.secondary,
      background: backgroundColor,
      primary: colors.primary,
      secondary: colors.lightest,
    },
  },
  scroll: {
    trackColor: colors.light,
    thumbColor: colors.primary,
  },
  box: {
    color: colors.light,
    highlightedColor: colors.light,

    // Exceptions
    entitiesBackgroundColor: colors.lightest,
    entitiesBorderColor: colors.medium,
    selectedEntityBoderderColor: colors.dark,
  },
  streetBox: {
    highlightColor: colors.secondary,
    backgroundColor: "#F0F5FD",
    buttonColor: {
      selected: "primary",
      unselected: "secondary",
    },
  },
  icon: {
    color: colors.darkest,
    selectedColor: colors.secondary,
  },
  parking: {
    horizontalList: {
      arrow: {
        enabled: colors.secondary,
        disabled: colors.medium,
      },
    },
    licencePlateBox: {
      text: "#9F9F9F",
      border: "#9F9F9F",
    },
    parkingTypeBox: {
      [parkingTypesMapping.startAndStop]: {
        color: colors.primary,
        contentColor: colors.secondary,
      },
      [parkingTypesMapping.duration]: {
        color: colors.secondary,
        contentColor: colors.lightest,
      },
    },
    benefitBox: {
      contentColor: "#1D1E1F",
    },
  },
  carColor: {
    [colorsMapping.black]: "#000000",
    [colorsMapping.red]: "#F44738",
    [colorsMapping.yellow]: "#EDC53E",
    [colorsMapping.green]: "#31D56B",
    [colorsMapping.white]: "#D3D3D3",
    [colorsMapping.blue]: "#0651F1",
    [colorsMapping.gray]: "#A5A5A5",
    [colorsMapping.orange]: "#F17B06",
  },
  streetColor: {
    [streetColorsMapping.red]: "#DB2127",
    [streetColorsMapping.yellow]: "#FBD75B",
    [streetColorsMapping.green]: "#7BD148",
    [streetColorsMapping.blue]: colors.secondary,
    [streetColorsMapping.gray]: "#BDC3C7",
    [streetColorsMapping.orange]: "#FFB878",
  },
  benefitColor: {
    [benefitTypesMapping.offerMinutesFirstPaidPeriod]: "#BD8CED",
    [benefitTypesMapping.offerMinutesAfterFirstPaidPeriod]: "#2CD0DE",
    [benefitTypesMapping.offerMinutesMultipleParkings]: "#FFEEB4",
    [benefitTypesMapping.percentageDiscount]: "#E0ABDC",
  },
  colorPicker: {
    contrastColor: {
      [colorsMapping.white]: {
        border: "#EAEAEA",
        background: colors.lightest,
      },
    },
  },
  input: {
    borderColor: colors.secondary,
    textColor: colors.secondary,
    focusedBorderColor: colors.primary,
  },
  loadingSkeleton: {
    color: colors.secondary,
    highlightColor: "#e6edfd",
    listColor: colors.light,
    listHighlightColor: colors.lightest,
    smoothColor: colors.light,
    smoothHighlightColor: colors.lightest,
  },
  paymentBox: {
    borderColor: {
      normal: colors.medium,
      hover: colors.darkest,
    },
  },
  parkingPaymentBox: {
    borderColor: colors.secondary,
    iconColor: colors.secondary,
  },
  popup: {
    overlayColor: "#00000077",
    backgroundColor: colors.lightest,
    titleColor: colors.secondary,
  },
  qrCode: {
    backgroundColor,
    figureColor: colors.darkest,
  },
  border: {
    lightBackground: {
      color: colors.secondary,
    },
  },
  animation: {
    stopParking: parkingRequirementsAnimation,
  },
  paymentIcon: {
    accountMovements: {
      iconColor: colors.darkest,
    },
    paymentMethod: {
      iconColor: colors.secondary,
    },
  },
  tab: {
    vertical: {
      selectedColor: "#F0F5FD",
      hoverColor: colors.light,
      arrowColor: colors.darkest,
    },
  },
  dropdownList: {
    border: colors.light,
    arrowColor: colors.darkest,
  },
  boxOptionsField: {
    backgroundColor: "#EBEBEB",
    selectedBackgroundColor: "#9F9F9F",
  },
  definitions: {
    exportIconColor: colors.darkest,
  },
  balanceCharge: {
    input: colors.secondary,
    options: colors.secondary,
  },
  draggableFile: {
    primary: {
      borderColor: colors.darkest,
    },
  },
  feeInfo: {
    typePicker: {
      background: {
        selected: colors.secondary,
        unselected: colors.lightest,
      },
      border: {
        selected: colors.secondary,
        unselected: "#9F9F9F",
      },
      text: {
        selected: colors.lightest,
        unselected: colors.darkest,
      },
    },
    priceTable: {
      background: `${colors.secondary}21`,
      text: colors.darkest,
    },
  },
  requirements: {
    border: colors.secondary,
    background: colors.lightest,
    label: colors.secondary,
  },
  card: {
    outlined: {
      primary: {
        borderColor: colors.light,
        hoverBorderColor: colors.darkest,
        selectedBorderColor: colors.darkest,
      },
    },
    dense: {
      primary: {
        backgroundColor: "#EFEFEF",
        iconColor: colors.darkest,
        positiveIconColor: "#198D6E",
      },
    },
  },
  permitHolders: {
    unapprovedBoxCorner: colors.secondary,
    approvedCardBackground: "#E6FAED",
    states: {
      [permitHolderStates.underElaboration]: "#F17B06",
      [permitHolderStates.toApproval]: colors.darkest,
      [permitHolderStates.approved]: colors.success,
      [permitHolderStates.rejected]: colors.danger,
      [permitHolderStates.provisoryApproved]: colors.success,
      [permitHolderStates.approvedWithChanges]: colors.success,
      [permitHolderStates.underAnalysis]: colors.secondary,
      [permitHolderStates.inactivated]: colors.mediumDark,
    },
    subscriptionStates: {
      [permitHoldersSubscriptionsStates.active]: colors.success,
      [permitHoldersSubscriptionsStates.inactive]: colors.danger,
      [permitHoldersSubscriptionsStates.waitingForPayment]: "#EDC53E",
      [permitHoldersSubscriptionsStates.waitingForBeginning]: "#0651F1",
      [permitHoldersSubscriptionsStates.waitingForRenewal]: "#DA93FF",
      [permitHoldersSubscriptionsStates.expired]: "#000000",
      [permitHoldersSubscriptionsStates.notPaid]: "#707070",
    },
  },
  alerts: {
    danger: { icon: colors.danger, background: `${colors.danger}20` },
    warning: { icon: "#F17B06", background: "#F17B0610" },
  },
  notifications: {
    backgroundColor: colors.lightest,
    mediumDevicesBackgroundColor: colors.lightest,
    noNotificationsTextColor: "#CBCBCB",
    headerTextColor: "#808080",
    showAllTextColor: colors.secondary,
    card: {
      dividerColor: "#E5E5E5",
      hoverColor: "#EDF3FE",
      dateColor: "#808080",
      hoverDateColor: "#808080",
      read: { dot: "#E2E2E2", text: colors.dark },
      unread: { dot: colors.secondary, text: colors.darkest },
    },
  },
  parkings: {
    timer: {
      label: colors.secondary,
      value: colors.secondary,
    },
  },
  citiesCard: {
    borderColor: colors.light,
    hoverBorderColor: colors.medium,
    selectedBorderColor: colors.medium,
  },
  entitySuggestion: {
    card: {
      backgroundColor: colors.light,
      selectedBackgroundColor: colors.lightest,
      selectedBorderColor: colors.light,
      disabledFontColor: colors.dark,
    },
  },
};

export default normal;
