import Button from "components/buttons/Button";
import VehicleBox from "domain/private/components/VehicleBox";
import useInfiniteList from "hooks/useInfiniteList";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { routesMapping } from "utils/routes";
import ContentBorderBox from "../components/ContentBorderBox";
import ErrorCard from "../components/ErrorCard";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import EmptyVehicles from "./components/EmptyVehicles";
import useVehicles from "./hooks/useVehicles";
import LoadingSkeleton from "./LoadingSkeleton";

const Vehicles = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { backofficeTheme } = useBackofficeThemeContext();
  const { addVehicle, getVehicles } = useVehicles();
  const { items, onFinishedScroll, isLoading, hasError } = useInfiniteList(getVehicles);
  const [hasActiveVehicles, setHasActiveVehicles] = useState(false);

  const onClickVehicle = useCallback(
    (id) => history.push(`${routesMapping.backofficeVehicles}/${id}`),
    [history]
  );

  useEffect(() => {
    if (items.length > 0) {
      setHasActiveVehicles(true);
    }
  }, [items]);

  const Content = useMemo(() => {
    if (isLoading) {
      return <LoadingSkeleton />;
    }

    if (hasError) {
      return <ErrorCard />;
    }

    if (items.length === 0) {
      return <EmptyVehicles />;
    }

    return (
      <VehiclesContainer onScroll={onFinishedScroll}>
        {items.map((vehicle) => (
          <VehicleBox
            key={vehicle.id}
            id={vehicle.id}
            color={vehicle.color}
            brand={vehicle.brand}
            licensePlate={vehicle.licensePlate}
            onClick={onClickVehicle}
          />
        ))}
      </VehiclesContainer>
    );
  }, [items, isLoading, hasError, onFinishedScroll]);

  return (
    <ContentBorderBox
      className="mt-20"
      cornerColor={backofficeTheme.color.primary}
      title={t("7961") /* Os meus veículos */}
      topRightCornerContent={
        hasActiveVehicles && (
          <Button size="sm" onClick={addVehicle}>
            {t("1874") /* Novo Veículo */}
          </Button>
        )
      }
    >
      {Content}
    </ContentBorderBox>
  );
};

export default Vehicles;

const VehiclesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  max-height: 45vh;
  overflow: auto;
`;
