import React from "react";
import HistoryList from "../../../components/history/List";
import HistoryCard from "../../../components/history/smallDevices/Card";
import TabTitle from "../../../components/Title";
import { tabsIdsMap } from "../../../helper";

const HistoryTab = () => {
  return (
    <>
      <TabTitle tabId={tabsIdsMap.history} />
      <HistoryList Card={HistoryCard} />
    </>
  );
};

export default HistoryTab;
