import Button from "components/buttons/Button";
import { Form as FormikForm, Formik } from "formik";
import { errorToast, Heading, infoToast } from "iparque-components";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as Yup from "yup";
import { AppContext } from "../../components/AppProvider";
import TextField from "../../components/formFields/Text";
import useGoogleRecaptcha from "../../hooks/useGoogleRecaptcha";
import DriversDataSource from "../../lib/clients/iParque/dataSources/driversDataSource";
import { getButtonSize } from "../../utils/general";
import { routesMapping } from "../../utils/routes";
import { warningMessage } from "../../utils/userMessages";
import LeftSideContainer from "./components/PageSplittedTemplate/LeftSideContainer";
import PageSplittedTemplate from "./components/PageSplittedTemplate/Template";
import RecaptchaInfo from "./components/RecaptchaInfo";
import { pageContexts } from "./configs";

const driversDataSource = new DriversDataSource();

const onSubmitWithReCAPTCHA = async (values, args) => {
  const { setIsLoading, t, executeGoogleRecaptcha } = args;
  const token = await executeGoogleRecaptcha();

  if (!token) {
    warningMessage(t("7858") /* Por favor, tenta novamente. */);
    return;
  }

  driversDataSource
    .passwordRecovery({ recaptchaToken: token, ...values })
    .then(() =>
      infoToast(
        t("7885") /* Informação! */,
        t("9702") /* Verifica o teu email para criares a nova senha. */,
        {
          autoClose: false,
        }
      )
    )
    .catch(() => errorToast(t("7847") /* Erro! */, t("3402") /* Ocorreu um erro */))
    .finally(() => setIsLoading(false));
};

const PasswordRecovery = () => {
  const { breakpoint, setIsLoading } = useContext(AppContext);
  const { t } = useTranslation();
  const { executeGoogleRecaptcha, isGoogleRecaptchaLoaded } = useGoogleRecaptcha();

  return (
    <PageSplittedTemplate
      pageContext={pageContexts.passwordRecovery}
      title={t("7879") /* Recuperar Senha */}
      backUrl={routesMapping.login}
    >
      <LeftSideContainer className="mt-20">
        <Container>
          <Heading color="secondary" variant="h7">
            {t("7888") /* Ups, não te lembras da senha? */}
          </Heading>
          <Heading color="secondary" variant="h8">
            {t("10519") /* Por favor, indica o teu endereço de correio eletrónico. */}
          </Heading>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email(t("7894") /* O campo deverá ser válido */)
                .required(t("10271") /* O campo é obrigatório */),
            })}
            onSubmit={(values) => {
              setIsLoading(true);
              onSubmitWithReCAPTCHA(values, { setIsLoading, t, executeGoogleRecaptcha });
            }}
          >
            <Form className="mt-20">
              <TextField
                type="email"
                id="email-field"
                name="email"
                label={t("178") /* Email */}
              />
              <Button
                type="submit"
                className="mt-30"
                disabled={!isGoogleRecaptchaLoaded}
                size={getButtonSize(breakpoint)}
                fullWidth
              >
                {t("3762") /* Recuperar */}
              </Button>
              <RecaptchaInfo />
            </Form>
          </Formik>
        </Container>
      </LeftSideContainer>
    </PageSplittedTemplate>
  );
};

export default PasswordRecovery;

const Container = styled.div`
  max-width: 420px;
`;

const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
