import FlexBox from "components/FlexBox";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const TabIdentification = ({ Icon, label }) => {
  return (
    <FlexBox alignItems="center">
      {Icon && (
        <IconContainer>
          {React.cloneElement(Icon, {
            className: "vertical-tab-icon",
          })}
        </IconContainer>
      )}
      <Text className="tab-label" align="center" variant="body6">
        {label}
      </Text>
    </FlexBox>
  );
};

export default TabIdentification;

TabIdentification.propTypes = {
  Icon: PropTypes.node,
  label: PropTypes.string.isRequired,
};

TabIdentification.defaultProps = {
  Icon: undefined,
};

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 25px;
  height: 25px;
  width: 25px;

  .vertical-tab-icon {
    fill: ${({ theme }) => theme.color.secondary};
    height: inherit;
  }
`;
