import DocumentsTab from "../../components/largeScreensTabs/Documents";
import GeneralTab from "../../components/largeScreensTabs/General";
import useLargeScreenTabs from "../../hooks/useLargeScreenTabs";
import useGenericTabs from "../../hooks/useTabs";
import HistoryTab from "../tabs/History";
import LocationsTab from "../tabs/Locations";
import SubscriptionsTab from "../tabs/Subscriptions";
import VehiclesTab from "../tabs/Vehicles";

const useTabs = () => {
  const { onTabChange } = useLargeScreenTabs();
  const tabs = useGenericTabs({
    GeneralTab,
    DocumentsTab,
    VehiclesTab,
    LocationsTab,
    SubscriptionsTab,
    HistoryTab,
  });

  return { tabs, onTabChange };
};

export default useTabs;
