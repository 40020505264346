import FlexBox from "components/FlexBox";
import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import styled from "styled-components";
import Skeleton from "../../../components/Skeleton";
import { useBackofficeThemeContext } from "../components/ThemeProvider";

const LoadingSkeleton = () => {
  const { backofficeTheme } = useBackofficeThemeContext();

  return (
    <SkeletonTheme
      color={backofficeTheme.loadingSkeleton.listColor}
      highlightColor={backofficeTheme.loadingSkeleton.listHighlightColor}
    >
      <FlexBox>
        <StyledSkeleton height={100} className="mr-5" />
        <StyledSkeleton height={100} className="ml-5" />
      </FlexBox>
    </SkeletonTheme>
  );
};

const StyledSkeleton = styled(Skeleton)`
  flex: 1;
`;

export default LoadingSkeleton;
