import React from "react";
import { useTranslation } from "react-i18next";
import ContentHeader from "../components/ContentHeader";
import SoldVehicles from "./SoldVehicles";
import Vehicles from "./Vehicles";

const VehiclesPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContentHeader
        topTitle={t("7946") /* Queres estacionar? */}
        bottomTitle={t("7957") /* Regista o teu veículo! */}
      />
      <div className="mt-30">
        <Vehicles />
        <SoldVehicles />
      </div>
    </>
  );
};

export default VehiclesPage;
