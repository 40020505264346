const setList = (state, payload) => ({
  ...state,
  subscriptionsTab: { ...state.subscriptionsTab, list: payload },
});

const setPaymentMethods = (state, payload) => ({
  ...state,
  subscriptionsTab: { ...state.subscriptionsTab, paymentMethods: payload },
});

export default {
  setList,
  setPaymentMethods,
};
