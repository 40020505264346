import { useState, useCallback } from "react";

const useToggleVerticalTabs = () => {
  const [displayVerticalTabs, setDisplayVerticalTabs] = useState(true);

  const handleBackToTabs = useCallback(() => {
    setDisplayVerticalTabs(true);
  }, []);

  const handleDisplayTabDetails = useCallback(() => {
    setDisplayVerticalTabs(false);
  }, []);

  return { displayVerticalTabs, handleBackToTabs, handleDisplayTabDetails };
};

export default useToggleVerticalTabs;
