import React from "react";
import NoticesPaymentBasedOnDevice from "./PaymentBasedOnDevice";
import Store from "./store/Store";

const NoticesPaymentPage = () => {
  return (
    <Store>
      <NoticesPaymentBasedOnDevice />
    </Store>
  );
};

export default NoticesPaymentPage;
