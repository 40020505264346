import Button from "components/buttons/Button";
import VehicleBox from "domain/private/components/VehicleBox";
import useInfiniteList from "hooks/useInfiniteList";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { routesMapping } from "utils/routes";
import ErrorCard from "../../components/ErrorCard";
import EmptyVehicles from "../components/EmptyVehicles";
import useVehicles from "../hooks/useVehicles";
import LoadingSkeleton from "../LoadingSkeleton";

const Vehicles = () => {
  const history = useHistory();
  const { getVehicles, addVehicle } = useVehicles();
  const { t } = useTranslation();
  const { items, onFinishedScroll, isLoading, hasError } = useInfiniteList(getVehicles);

  const onClickVehicle = useCallback(
    (id) => history.push(`${routesMapping.backofficeVehicles}/${id}`),
    [history]
  );

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (hasError) {
    return <ErrorCard />;
  }

  if (items.length === 0) {
    return <EmptyVehicles />;
  }

  return (
    <>
      <ButtonWrapper>
        <Button size="xs" onClick={addVehicle}>
          {t("1874") /* Novo Veículo */}
        </Button>
      </ButtonWrapper>
      <VehiclesContainer onScroll={onFinishedScroll}>
        {items.map((vehicle) => (
          <VehicleBox
            key={vehicle.id}
            id={vehicle.id}
            color={vehicle.color}
            brand={vehicle.brand}
            licensePlate={vehicle.licensePlate}
            onClick={onClickVehicle}
          />
        ))}
      </VehiclesContainer>
    </>
  );
};

export default Vehicles;

const VehiclesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  max-height: 60vh;
  overflow: auto;
`;

const ButtonWrapper = styled.div`
  margin: 30px 0;
  text-align: center;
`;
