import FlexBox from "components/FlexBox";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styled from "styled-components";

const PaymentMethodsList = ({ paymentMethods }) => {
  const Components = useMemo(() => {
    const indexToSplit = Math.ceil(paymentMethods.length / 2);

    return {
      leftColumn: paymentMethods.slice(0, indexToSplit),
      rightColumn: paymentMethods.slice(indexToSplit, paymentMethods.length),
    };
  }, [paymentMethods]);

  if (!paymentMethods.length) {
    return null;
  }

  return (
    <FlexBox>
      <StyledFlexBox flexDirection="column">{Components.leftColumn}</StyledFlexBox>
      {Components.rightColumn.length > 0 ? (
        <StyledFlexBox className="ml-30" flexDirection="column">
          {Components.rightColumn}
        </StyledFlexBox>
      ) : null}
    </FlexBox>
  );
};

export default PaymentMethodsList;

PaymentMethodsList.propTypes = {
  paymentMethods: PropTypes.arrayOf(PropTypes.node).isRequired,
};

const StyledFlexBox = styled(FlexBox)`
  flex: 1;

  .payment-method-box {
    width: 100%;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
`;
