import { initializeApp } from "firebase/app";
import {
  getMessaging as firebaseGetMessaging,
  getToken,
  isSupported,
} from "firebase/messaging";
import firebaseConfig from "./firebaseConfig.json";

const firebaseApp = initializeApp(firebaseConfig);

const getMessaging = async () => {
  const isFirebaseSupported = await isSupported();

  return isFirebaseSupported ? firebaseGetMessaging(firebaseApp) : null;
};

const getMessagingToken = async () => {
  const messaging = await getMessaging();

  if (!messaging) {
    return undefined;
  }

  try {
    return await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY,
    });
  } catch {
    return undefined;
  }
};

export { getMessaging, getMessagingToken };
