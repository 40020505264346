import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import { paymentBoxSizes } from "./helper";

const paddingSizes = {
  [paymentBoxSizes.medium]: { vertical: "20px", horizontal: "20px" },
  [paymentBoxSizes.large]: { vertical: "30px", horizontal: "30px" },
};

const BoxContainer = styled.div`
  position: relative;
  padding: ${({ size }) =>
    `${paddingSizes[size].vertical} ${paddingSizes[size].horizontal}`};
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.paymentBox.borderColor.normal};

  ${({ disabled }) =>
    disabled
      ? `
        cursor: not-allowed;
        pointer-events: none;
      `
      : ""};

  :hover {
    border: 1px solid ${({ theme }) => theme.paymentBox.borderColor.hover};
  }

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

export default BoxContainer;
