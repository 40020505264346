import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ContentBorderBox from "../../components/ContentBorderBox";
import ContentHeader from "../../components/ContentHeader";
import { useBackofficeThemeContext } from "../../components/ThemeProvider";
import { requirementsPagePropsTypes } from "./propTypes";
import ParkingRequirements from "./Requirements";

const Container = styled.div`
  max-width: 68vw;
`;

const ParkingRequirementsPage = ({ requirementsMet }) => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();

  return (
    <Container>
      <ContentHeader
        topTitle={t("7830") /* Vamos estacionar? */}
        bottomTitle={t("7831") /* É fácil! */}
      />
      <ContentBorderBox
        title=""
        className="mt-40"
        cornerColor={backofficeTheme.color.primary}
      >
        <ParkingRequirements requirementsMet={requirementsMet} />
      </ContentBorderBox>
    </Container>
  );
};

ParkingRequirementsPage.propTypes = requirementsPagePropsTypes;

export default ParkingRequirementsPage;
