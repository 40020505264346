import FlexBox from "components/FlexBox";
import Text from "components/Text";
import { Button, Heading, Input, usePublicPagePopupsContext } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import {
  defaultAnimationDelay,
  defaultAnimationDuration,
  getAppearFromLeft,
} from "themes/animations/animations";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import { ReactComponent as DriverLogo } from "../../../../assets/images/driverLogo.svg";
import { breakpoints } from "../../../../utils/breakpoints";
import Animation from "./Animation";
import Arrow from "./Arrow";
import StartPaymentButton from "./StartPaymentButton";
import useNoticePayment from "./hooks/useNoticePayment";

const ANIMATION_TIME = "0.3s";

const PayNoticeSmallScreen = () => {
  const {
    displayPaymentBox,
    setDisplayPaymentBox,
    noticeCode,
    setNoticeCode,
    resetState,
    isPaid,
    creditCardCharge,
    isMinimized,
    toggleMinimize,
  } = useNoticePayment();
  const { t } = useTranslation();
  const {
    isSmallVersion,
    popupsHeight,
    animationDuration,
  } = usePublicPagePopupsContext();

  const close = () => {
    document.body.style.overflow = "auto";
    setDisplayPaymentBox(false);
  };

  const open = () => {
    if (displayPaymentBox || isPaid) {
      return;
    }

    document.body.style.overflow = "hidden";
    setDisplayPaymentBox(true);
  };

  return (
    <Box
      displayPaymentBox={displayPaymentBox}
      popupsHeight={isSmallVersion ? popupsHeight : 0}
      popupsAnimationDuration={animationDuration}
    >
      {displayPaymentBox && (
        <>
          <Header>
            <CloseIcon onClick={close} />
          </Header>
          <LogoContainer>
            <DriverLogo />
          </LogoContainer>
        </>
      )}
      <Container displayPaymentBox={displayPaymentBox} isMinimized={isMinimized}>
        {!displayPaymentBox && (
          <FixedContentContainer displayPaymentBox={displayPaymentBox}>
            <FlexBox justifyContent="spaceBetween" alignItems="center" className="mb-15">
              <Title>
                {
                  isPaid
                    ? t("11821") /* Pagamento concluído! */
                    : t("7843") /* Pagar infração! */
                }
              </Title>
              <Arrow
                isMinimized={isMinimized}
                onClick={toggleMinimize}
                animationTime={ANIMATION_TIME}
              />
            </FlexBox>
            <Body>
              {
                isPaid
                  ? t("11824") /* A tua infração\nfoi paga. */
                  : t("11813") /* É tão fácil que nem precisas de criar conta! */
              }
            </Body>
          </FixedContentContainer>
        )}
        <AnimationContainer displayPaymentBox={displayPaymentBox} onClick={open}>
          <Animation isPaid={isPaid} />
        </AnimationContainer>
        <BodyContainer onClick={open}>
          {!displayPaymentBox ? (
            <StartPaymentButton
              isPaid={isPaid}
              resetState={resetState}
              displayPaymentForm={open}
            />
          ) : (
            <>
              <Title>{t("8185") /* Beep Beep! */}</Title>
              <Title>
                {t("11819") /* Queres pagar de forma rápida a tua infração? */}
              </Title>
              <Body className="mt-20">
                {t("11813") /* É tão fácil que nem precisas de criar conta! */}
              </Body>
              <InlineContainer>
                <StyledInput
                  value={noticeCode}
                  onChange={(event) => setNoticeCode(event.target.value)}
                  type="text"
                  name="noticeCode"
                  placeholder={t("209") /* Código da Infração */}
                />
                <Button
                  onClick={() => {
                    creditCardCharge(noticeCode);
                  }}
                  color="secondary"
                  size="sm"
                >
                  {t("4537") /* Pagar */}
                </Button>
              </InlineContainer>
              <Text variant="body4" color="secondary" className="mt-5">
                {
                  t(
                    "11820"
                  ) /* Para pagar serás redirecionado para a PayPay, um serviço de pagamentos online. */
                }
              </Text>
            </>
          )}
        </BodyContainer>
      </Container>
    </Box>
  );
};

const Box = styled.div`
  overflow: hidden;
  background-color: ${(props) => props.theme.background.color.highlightBox};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  margin-bottom: ${({ popupsHeight, displayPaymentBox }) =>
    displayPaymentBox ? 0 : `${popupsHeight}px`};
  z-index: 1006;
  transition: margin ${({ popupsAnimationDuration }) => popupsAnimationDuration}s,
    top ${({ popupsAnimationDuration }) => popupsAnimationDuration}s;
  animation: ${getAppearFromLeft()} ${defaultAnimationDuration}s;
  animation-delay: ${defaultAnimationDelay}s;
  animation-fill-mode: both;
`;

const Title = styled(Heading).attrs({ variant: "h7", color: "secondary" })`
  font-size: 20px;
`;

const Body = styled(Heading).attrs({
  variant: "h8",
  color: "secondary",
})`
  font-size: 20px;
  line-height: 1.2;
  white-space: break-spaces;
`;

const Container = styled.div`
  display: flex;
  padding: 20px 20px;
  justify-content: space-between;
  height: ${({ isMinimized }) => (isMinimized ? "65px" : "340px")};
  width: 240px;
  overflow: hidden;
  flex-direction: column;
  transition: all ${ANIMATION_TIME};
  align-items: center;
  box-sizing: border-box;

  ${({ displayPaymentBox }) =>
    displayPaymentBox &&
    css`
      justify-content: center;
      padding: 0;
      height: var(--app-height);
      width: 100vw;
    `}
`;

const FixedContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AnimationContainer = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-top: ${(props) => (props.displayPaymentBox ? "20%" : "15px")};

  svg {
    max-height: 80px;
    width: inherit;

    ${(props) =>
      props.displayPaymentBox &&
      css`
        max-height: 150px;
        margin-bottom: 10%;
      `}
  }
`;

const BodyContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0 10%;
`;

const InlineContainer = styled.div`
  display: flex;
  margin-top: 40px;

  button {
    margin-left: 15px;
  }

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    width: 100%;

    button {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`;

const Header = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;

  svg {
    cursor: pointer;
    height: 20px;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 10%;
`;

const StyledInput = styled(Input)`
  box-sizing: border-box;
`;

export default PayNoticeSmallScreen;
