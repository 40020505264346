import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ReactComponent as FrontIcon } from "assets/icons/front.svg";

const Arrow = ({ onClick, isMinimized, animationTime }) => {
  return (
    <ArrowContainer onClick={onClick}>
      <ArrowIcon isMinimized={isMinimized} animationTime={animationTime} />
    </ArrowContainer>
  );
};

export default Arrow;

Arrow.propTypes = {
  onClick: PropTypes.func.isRequired,
  isMinimized: PropTypes.func.isRequired,
  animationTime: PropTypes.string.isRequired,
};

const ArrowContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  width: 40px;
  height: 30px;
`;

const ArrowIcon = styled(FrontIcon)`
  fill: ${({ theme }) => theme.icon.color};
  transform: ${({ isMinimized }) => (isMinimized ? `rotate(-90deg)` : `rotate(90deg)`)};
  transition: transform ${({ animationTime }) => animationTime} ease;
  height: 20px;
`;
