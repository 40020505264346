import { useAuth } from "components/AuthProvider";
import DriversDataSource from "lib/clients/iParque/dataSources/driversDataSource";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { colorsMapping } from "utils/colors";
import { routesMapping } from "utils/routes";
import { errorMessage } from "utils/userMessages";

const driversDataSource = new DriversDataSource();

const processVehicles = (vehicles, soldScenario, { theme, t }) => {
  return vehicles.map((vehicle) => ({
    color: theme.carColor[vehicle?.color?.id || colorsMapping.gray],
    brand: vehicle?.model?.brand?.name || t("6332") /* Desconhecido */,
    licensePlate: vehicle.licensePlate,
    id: parseInt(vehicle.id, 10),
    ...(soldScenario
      ? { purchaseDate: vehicle.purchasedDate, saleDate: vehicle.sellingDate }
      : {}),
  }));
};

const useVehicles = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { driverHash } = useAuth();
  const theme = useTheme();

  const addVehicle = useCallback(() => {
    history.push(`${routesMapping.backofficeVehicles}/add`);
  }, [history]);

  const getVehicles = useCallback(
    async ({ sold = false, ...other } = {}) => {
      try {
        const vehiclesResponse = await driversDataSource.getVehicles(driverHash, {
          fillCollections: "all",
          sold,
          ...other,
        });

        return {
          items: processVehicles(vehiclesResponse.items, sold, { t, theme }),
          totalPages: vehiclesResponse.totalPages,
        };
      } catch (error) {
        errorMessage(error, t("7956") /* Ocorreu um erro ao obter a lista de veículos */);

        return { hasError: true };
      }
    },
    [t, theme, driverHash]
  );

  const getSoldVehicles = useCallback((params) => getVehicles({ sold: true, ...params }));

  return { getVehicles, getSoldVehicles, addVehicle };
};

export default useVehicles;
