import i18n from "i18next";
import moment from "moment";
import ReactGA from "react-ga4";
import { gaCategory, gaEvents } from "utils/googleAnalytics";
import DriversDataSource from "../../../lib/clients/iParque/dataSources/driversDataSource";
import ParkingsDataSource from "../../../lib/clients/iParque/dataSources/parkingsDataSource";
import PaymentsDataSource from "../../../lib/clients/iParque/dataSources/paymentsDataSource";
import StreetsDataSource from "../../../lib/clients/iParque/dataSources/streetsDataSource";
import VehiclesDataSource from "../../../lib/clients/iParque/dataSources/vehiclesDataSource";
import { benefitTypesMapping, offerMinutesBenefitTypes } from "../../../utils/benefits";
import { colorsMapping } from "../../../utils/colors";
import { serverDateFormat } from "../../../utils/dateTime";
import {
  hasErrorCode,
  LICENSE_PLATE_HAS_NOTICE,
  MISSING_STREET_HOLIDAYS,
  ZONE_PARKING_STARTED_ALREADY,
  DRIVER_WITHOUT_BALANCE,
  TIMEZONE_DIFFERS_FROM_LOCAL_TIME,
} from "../../../utils/error";
import { feeTypesMapping } from "../../../utils/fees";
import {
  getEndingDate,
  isRunning,
  parkingMethodsMapping,
  parkingTypesMapping,
} from "../../../utils/parking";
import { paymentMethodsMapping } from "../../../utils/payment";
import { streetHolidaysTypesMapping } from "../../../utils/streets";
import { errorMessage, warningMessage } from "../../../utils/userMessages";
import { getVehicleState } from "../../../utils/vehicles";
import { parkingStatesMapping as animationStates } from "../components/ParkedCar";

const streetsDataSource = new StreetsDataSource();
const driversDataSource = new DriversDataSource();
const vehiclesDataSource = new VehiclesDataSource();
const parkingsDataSource = new ParkingsDataSource();
const paymentDataSource = new PaymentsDataSource();

export const durationDefaultOptions = [15, 30, 45, 60];

export const parkingSteps = {
  streets: "streets",
  fees: "fees",
  vehicles: "vehicles",
  parkingTypes: "parkingTypes",
  benefits: "benefits",
  startStartAndStop: "startStartAndStop",
  startAndStopDetails: "startAndStopDetails",
  durationChooseDuration: "durationChooseDuration",
  durationChoosePaymentMethod: "durationChoosePaymentMethod",
  durationDetails: "durationDetails",
  durationLongPeriodFeeStart: "durationLongPeriodFeeStart",
};

export const parkingBottomSteps = [
  parkingSteps.streets,
  parkingSteps.fees,
  parkingSteps.vehicles,
  parkingSteps.parkingTypes,
  parkingSteps.benefits,
];

export const startAndStopSteps = [
  parkingSteps.startStartAndStop,
  parkingSteps.startAndStopDetails,
];

export const durationSteps = [
  parkingSteps.durationChooseDuration,
  parkingSteps.durationChoosePaymentMethod,
  parkingSteps.durationDetails,
  parkingSteps.durationLongPeriodFeeStart,
];

export const selectedParkingTypeInitialState = {
  id: null,
  updatedDateTime: null,
};

export const selectedFeeInitialState = {
  id: null,
  name: "",
  maxDuration: null,
  minDuration: null,
  updatedDateTime: null,
};

export const selectedBenefitInitialState = {
  id: null,
  description: "",
  type: null,
  value: 0,
  updatedDateTime: null,
  color: "",
};

const processVehicles = (vehicles) => {
  return vehicles.map((vehicle) => {
    return {
      id: vehicle.id,
      licensePlate: vehicle.licensePlate,
      colorId: vehicle?.color?.id || colorsMapping.gray,
      brand: vehicle?.model?.brand?.name || i18n.t("6332") /* Desconhecido */,
      state: getVehicleState(vehicle?.parkings || []),
    };
  });
};

const processBenefits = (benefits, { feeTypeId, parkingTypeId }) => {
  let validBenefits = [];

  validBenefits = benefits.map((benefit) => {
    return {
      id: benefit.parkingBenefit.id,
      value: benefit.duration,
      description: benefit.parkingBenefit.name,
      type: parseInt(benefit.parkingBenefit.type.id, 10),
    };
  });

  validBenefits = validBenefits.filter((benefit) => benefit.value > 0) || [];

  if (feeTypeId !== feeTypesMapping.minutes) {
    validBenefits =
      validBenefits.filter(
        (benefit) => !offerMinutesBenefitTypes.includes(benefit.type)
      ) || [];
  }

  if (parkingTypeId === parkingTypesMapping.startAndStop) {
    validBenefits =
      validBenefits.filter(
        (benefit) => benefit.type !== benefitTypesMapping.offerMinutesAfterFirstPaidPeriod
      ) || [];
  }

  return validBenefits;
};

const processParkingPaymentMethods = (parkingPaymentMethods) => {
  const processedParkingPaymentMethods = parkingPaymentMethods.filter(
    (parkingPaymentMethod) =>
      parkingPaymentMethod.isActive && parkingPaymentMethod.isImplemented
  );

  return processedParkingPaymentMethods.map((parkingPaymentMethod) => {
    const parkingMethod = {
      description: parkingPaymentMethod.description,
      code: parkingPaymentMethod.code,
    };

    if (parkingPaymentMethod.code === paymentMethodsMapping.mbway.code) {
      return {
        ...parkingMethod,
        id: paymentMethodsMapping.mbway.id,
        shortMessage: parkingPaymentMethod.shortMessage,
        longMessage: parkingPaymentMethod.fullMessage,
      };
    }

    return parkingMethod;
  });
};

export const getStreetHolidays = async (entityId, streetId) => {
  try {
    const response = await streetsDataSource.getHolidays(
      entityId,
      streetId,
      {
        type: streetHolidaysTypesMapping.streetSection,
        startingDate: moment().format(serverDateFormat),
        endingDate: moment().add(10, "days").format(serverDateFormat), // look 10 days ahead
        noLimit: true,
      },
      true
    );

    return response.items;
  } catch (error) {
    if (hasErrorCode(error, MISSING_STREET_HOLIDAYS)) {
      return [];
    }

    errorMessage(error, i18n.t("8165") /* Ocorreu um erro ao obter os feriados da rua */);
    return null;
  }
};

export const getVehicles = async (entityId, authHash) => {
  try {
    const response = await vehiclesDataSource.getAll(
      entityId,
      authHash,
      {
        fillCollections: "all",
        noLimit: true,
        sold: false,
        listActive: true,
      },
      {},
      false
    );

    return processVehicles(response.items);
  } catch (error) {
    errorMessage(
      error,
      i18n.t("7956") /* Ocorreu um erro ao obter a lista de veículos */
    );

    return null;
  }
};

export const getParkingTypes = () => {
  return [
    {
      id: parkingTypesMapping.startAndStop,
      name: i18n.t("8053") /* Start & Stop */,
      formattedName: i18n.t("10677") /* Start\n& Stop */,
      description: i18n.t("8055") /* Termina o teu estacionamento quando quiseres */,
    },
    {
      id: parkingTypesMapping.duration,
      name: i18n.t("8054") /* Definir duração */,
      formattedName: i18n.t("10678") /* Definir\nduração */,
      description: i18n.t(
        "8056"
      ) /* O estacionamento termina no fim do período definido */,
    },
  ];
};

export const getParkingPaymentMethods = async (entityId) => {
  const parkingPaymentMethodBalance = {
    code: paymentMethodsMapping.balance.code,
    id: paymentMethodsMapping.balance.id,
    description: i18n.t("8171") /* Saldo Condutor */,
    isActive: 1,
    isImplemented: 1,
  };

  try {
    const response = await paymentDataSource.getParkingPaymentMethods(entityId, {
      noLimit: true,
    });

    return [
      parkingPaymentMethodBalance,
      ...processParkingPaymentMethods(response?.items || []),
    ];
  } catch {
    return [parkingPaymentMethodBalance];
  }
};

export const getBenefits = async (
  entityId,
  licensePlate,
  streetId,
  { setIsLoading, feeTypeId, parkingTypeId }
) => {
  setIsLoading(true);

  try {
    const response = await vehiclesDataSource.getBenefits(
      entityId,
      licensePlate,
      {
        parkingMethodId: parkingMethodsMapping.driverWeb,
        streetId,
        fillCollections: "parkingBenefit",
      },
      {},
      true
    );

    return processBenefits(response.items, { feeTypeId, parkingTypeId });
  } catch (error) {
    errorMessage(
      error,
      i18n.t("8058") /* Ocorreu um erro ao obter os benefícios associados ao veículo */
    );

    return null;
  } finally {
    setIsLoading(false);
  }
};

export const startParking = async (entityId, streetId, params, { setIsLoading }) => {
  setIsLoading(true);

  try {
    const response = await parkingsDataSource.start(entityId, streetId, params);

    ReactGA.event({
      category: gaCategory,
      action: gaEvents.startAndStopParkingBegin,
    });

    return {
      id: response.parkingId,
      amount: response.amount,
      startingDate: response.startingDate,
      endingDate: null,
      scheduleExtraInfo: {
        currentDuration: 0,
        remainingDuration: null,
        isRunning: isRunning(response.startingDate),
        nextStart: null,
        nextPause: null,
      },
    };
  } catch (error) {
    ReactGA.event({
      category: gaCategory,
      action: gaEvents.parkingRequestError,
    });

    if (hasErrorCode(error, ZONE_PARKING_STARTED_ALREADY)) {
      warningMessage(
        i18n.t("8456") /* Já tens um estacionamento ativo para essa matrícula */
      );

      return null;
    }

    if (hasErrorCode(error, LICENSE_PLATE_HAS_NOTICE)) {
      warningMessage(
        i18n.t(
          "9703"
        ) /* Não é possível iniciar o estacionamento. O teu veículo possui infrações. */
      );

      return null;
    }

    if (hasErrorCode(error, TIMEZONE_DIFFERS_FROM_LOCAL_TIME)) {
      warningMessage(
        i18n.t(
          "12035"
        ) /* O fuso horário do teu dispositivo difere do fuso horário da entidade */
      );

      return null;
    }

    errorMessage(
      error,
      i18n.t("8120") /* Ocorreu um erro ao criar o estacionamento Start & Stop */
    );

    return null;
  } finally {
    setIsLoading(false);
  }
};

export const createParking = async (entityId, streetId, params, { setIsLoading }) => {
  setIsLoading(true);

  try {
    const response = await parkingsDataSource.create(entityId, streetId, params);

    ReactGA.event({
      category: gaCategory,
      action: gaEvents.durationParkingBegin,
    });

    return {
      id: response.parkingId,
    };
  } catch (error) {
    ReactGA.event({
      category: gaCategory,
      action: gaEvents.parkingRequestError,
    });

    if (hasErrorCode(error, DRIVER_WITHOUT_BALANCE)) {
      warningMessage(
        i18n.t("8454") /* O teu saldo é insuficiente para a duração escolhida. */
      );

      return null;
    }

    if (hasErrorCode(error, TIMEZONE_DIFFERS_FROM_LOCAL_TIME)) {
      warningMessage(
        i18n.t(
          "12035"
        ) /* O fuso horário do teu dispositivo difere do fuso horário da entidade */
      );

      return null;
    }

    errorMessage(error, i18n.t("8172") /* Ocorreu um erro ao iniciar o estacionamento */);

    return null;
  } finally {
    setIsLoading(false);
  }
};

export const getParking = async (
  entityId,
  authHash,
  parkingId,
  { parkingInfoCancelToken }
) => {
  try {
    const parking = await driversDataSource.getParkingById(
      entityId,
      authHash,
      parkingId,
      { avoidLicensePlateValidation: true },
      {},
      false,
      parkingInfoCancelToken
    );

    return {
      amount: parking.amount,
      endingDate: getEndingDate(
        parking.parkingTypeId,
        parking.stateId,
        parking?.endingDate
      ),
      startingDate: parking.startingDate,
      scheduleExtraInfo: parking.scheduleExtraInfo,
      parkingPaymentTypeId: parking.parkingPaymentTypeId,
    };
  } catch (error) {
    errorMessage(
      error,
      i18n.t("8119") /* Ocorreu um erro ao obter os detalhes do estacionamento */
    );
    return null;
  }
};

export const getStepDescription = (
  state,
  { onlyParkWithActiveGeographicLocationActive }
) => {
  if (state === parkingSteps.streets) {
    return onlyParkWithActiveGeographicLocationActive
      ? i18n.t("8029") /* Seleciona a rua no mapa */
      : i18n.t("8028"); /* Seleciona a rua */
  }

  if (state === parkingSteps.fees) {
    return i18n.t("8038"); /* Seleciona a tarifa */
  }

  if (state === parkingSteps.vehicles) {
    return i18n.t("8039"); /* Seleciona o veículo que pretendes estacionar */
  }

  if (state === parkingSteps.parkingTypes) {
    return i18n.t("8065"); /* Seleciona como pretendes estacionar */
  }

  if (state === parkingSteps.benefits) {
    return i18n.t("8066"); /* Seleciona o benefício */
  }

  if (state === parkingSteps.startStartAndStop) {
    return i18n.t("8116"); /* Inicia o estacionamento start & stop */
  }

  if (state === parkingSteps.startAndStopDetails) {
    return i18n.t("8133"); /* Estacionamento a decorrer */
  }

  if (state === parkingSteps.durationChooseDuration) {
    return i18n.t("8167"); /* Escolhe por quanto tempo desejas estacionar */
  }

  if (state === parkingSteps.durationChoosePaymentMethod) {
    return i18n.t("8173"); /* Escolhe o método de pagamento */
  }

  if (state === parkingSteps.durationDetails) {
    return i18n.t("8133"); /* Estacionamento a decorrer */
  }

  if (state === parkingSteps.durationLongPeriodFeeStart) {
    return i18n.t("8233"); /* Inicia o estacionamento */
  }

  return "";
};

export const streetsListMemo = (props, nextProps) => {
  return (
    props.streets === nextProps.streets &&
    props.selectedStreet === nextProps.selectedStreet &&
    props.visible === nextProps.visible &&
    props.onlyParkWithActiveGeographicLocationActive ===
      nextProps.onlyParkWithActiveGeographicLocationActive &&
    props.popupFees === nextProps.popupFees
  );
};

export const getParkingAnimationState = (step) => {
  let state = animationStates.parked;

  const stepsForBeforeParkingState = [
    parkingSteps.startStartAndStop,
    parkingSteps.durationChooseDuration,
    parkingSteps.durationChoosePaymentMethod,
    parkingSteps.durationLongPeriodFeeStart,
  ];

  const stepsForParkedState = [
    parkingSteps.startAndStopDetails,
    parkingSteps.durationDetails,
  ];

  if (stepsForBeforeParkingState.includes(step)) {
    state = animationStates.beforeParking;
  }

  if (stepsForParkedState.includes(step)) {
    state = animationStates.parked;
  }

  return state;
};

export const benefitToArray = (id, value) => {
  return id ? [{ id, duration: value }] : [];
};
