import AbstractDataSource from "./abstractDataSource";

export default class DistrictsDataSource extends AbstractDataSource {
  async getAll(entityId, params = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/districts`,
      params,
      {},
      useCache
    );
  }
}
