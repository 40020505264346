import { useState, useCallback, useEffect } from "react";
import { customEvents } from "../../../utils/events";
import { parkingTimerStates } from "../components/parkings/Timer";

const useTimerInfo = (parkingId, { endCallback = null } = {}) => {
  const [timerInfo, setTimerInfo] = useState(null);

  const handleTimerStateChange = useCallback((event) => {
    const newTimerInfo = event.detail;

    setTimerInfo(newTimerInfo);

    if (newTimerInfo.state === parkingTimerStates.ended && endCallback) {
      endCallback();

      document.removeEventListener(
        `${customEvents.timerStateChange}${parkingId}`,
        handleTimerStateChange
      );
    }
  }, []);

  useEffect(() => {
    document.addEventListener(
      `${customEvents.timerStateChange}${parkingId}`,
      handleTimerStateChange
    );

    return () => {
      document.removeEventListener(
        `${customEvents.timerStateChange}${parkingId}`,
        handleTimerStateChange
      );
    };
  }, []);

  return timerInfo;
};

export default useTimerInfo;
