import Text from "components/Text";
import { Heading, Search } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import styled, { css } from "styled-components";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { useAuth } from "../../../components/AuthProvider";
import { checkDefaultEntity, getDefaultEntity } from "../../../utils/auth";
import ContentHeader from "../components/ContentHeader";
import Logout from "../components/Logout";
import animation from "./animation.json";
import CityItem from "./Item";
import CitiesList from "./List";
import { citiesPropTypes } from "./propTypes";

const defaultOptions = {
  animationData: animation,
  loop: false,
};

const lottieStyle = {
  width: "80%",
  maxWidth: "500px",
};

const Container = styled.div`
  display: flex;

  ${({ hasDefaultEntityDefined, theme }) =>
    !hasDefaultEntityDefined &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      background-color: ${theme.background.color.background};
      align-items: center;
    `};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
  position: relative;

  .city-header-message {
    position: absolute;
    bottom: 0;
    left: 74px;
    line-height: 20px;
  }
`;

const CitiesContainer = styled.div`
  height: 50vh;
  min-width: 550px;
  overflow: auto;
`;

const LogoutWrapper = styled.div`
  position: absolute;
  left: 5vw;
  bottom: 30px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 5vw;
  max-width: 650px;
`;

const RightContainer = styled.div`
  flex: 1;
`;

const StyledLogo = styled(Logo)`
  height: 150px;
  width: 130px;
  fill: ${({ theme }) => theme.background.color.primary};
`;

const Cities = ({ cities, search, setSearch }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const hasDefaultEntityDefined = checkDefaultEntity(user);
  const defaultEntity = getDefaultEntity(user);

  return (
    <div>
      <Container hasDefaultEntityDefined={hasDefaultEntityDefined}>
        <LeftContainer>
          <HeaderContainer>
            {hasDefaultEntityDefined ? (
              <ContentHeader
                topTitle={t("7948") /* Estás noutra cidade? */}
                bottomTitle={t("7949") /* Escolhe onde queres estacionar! */}
              />
            ) : (
              <>
                <StyledLogo preserveAspectRatio="xMinYMax meet" />
                <Heading className="city-header-message" color="tertiary" variant="h5">
                  {t("7945") /* Escolhe a cidade em que queres estacionar */}
                </Heading>
              </>
            )}
          </HeaderContainer>
          {defaultEntity && (
            <>
              <Text className="mt-30 mb-10" variant="body4">
                {`${t("10899") /* Entidade selecionada */}:`}
              </Text>
              <CityItem
                city={{
                  entityId: defaultEntity.id,
                  entityName: defaultEntity.name,
                  entityLogo: defaultEntity.logo,
                  balance: defaultEntity.balance.amount,
                }}
                selected
              />
            </>
          )}
          <div className="mt-30">
            {defaultEntity && (
              <Text className="mb-10" variant="body4">
                {`${t("10901") /* Cidades mais próximas */}:`}
              </Text>
            )}
            <Search
              value={search}
              name="search-city"
              placeholder={t("10842") /* Procura onde queres estacionar */}
              onChange={(event) => setSearch(event.target.value)}
            />
            <CitiesContainer className="mt-20">
              <CitiesList
                hasDefaultEntityDefined={hasDefaultEntityDefined}
                cities={cities}
              />
            </CitiesContainer>
          </div>
        </LeftContainer>
        <RightContainer>
          {!hasDefaultEntityDefined && (
            <Lottie options={defaultOptions} style={lottieStyle} />
          )}
        </RightContainer>
      </Container>
      {!hasDefaultEntityDefined && (
        <LogoutWrapper>
          <Logout lightBackground />
        </LogoutWrapper>
      )}
    </div>
  );
};

Cities.propTypes = citiesPropTypes;

export default Cities;
