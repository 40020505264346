import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { tabsIdsMap } from "../../helper";
import { usePermitHoldersDetailsContext } from "../../Provider";
import Alert from "./Alert";
import DocumentsList from "./components/DocumentsList";

const ExpiredDocumentsWarning = ({ documents }) => {
  const { t } = useTranslation();
  const { tabsRef } = usePermitHoldersDetailsContext();

  const displayDocumentsTab = () => tabsRef.current?.changeTab(tabsIdsMap.documents);

  return (
    <Alert
      variant="warning"
      onClick={displayDocumentsTab}
      onClickTitle={t("10637") /* Ir para tab documentos */}
      title={
        t(
          "10564"
        ) /* Para dares seguimento ao dístico terás de renovar os seguintes documentos que se encontram expirados: */
      }
      subtitle={<DocumentsList documents={documents} />}
    />
  );
};

export default ExpiredDocumentsWarning;

ExpiredDocumentsWarning.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({ designation: PropTypes.string.isRequired })
  ).isRequired,
};
