import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import NotificationTimeOption from "./Option";
import { notificationDurationOptions } from "../../../controller";

const NotificationTimeOptions = ({ onClick, selectedMinutes }) => {
  return (
    <Container>
      {notificationDurationOptions.map((durationOption) => (
        <NotificationTimeOption
          key={durationOption}
          minutes={durationOption}
          onClick={onClick}
          isSelected={durationOption === selectedMinutes}
        />
      ))}
    </Container>
  );
};

export default NotificationTimeOptions;

NotificationTimeOptions.propTypes = {
  onClick: PropTypes.func.isRequired,
  selectedMinutes: PropTypes.number,
};

NotificationTimeOptions.defaultProps = {
  selectedMinutes: null,
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
`;
