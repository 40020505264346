import PropTypes from "prop-types";

const tabItemPropTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  component: PropTypes.node.isRequired,
  icon: PropTypes.node,
};

export const tabPanelPropTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape(tabItemPropTypes)).isRequired,
  activeTabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
};

export const tabPanelDefaultProps = {
  className: "",
};

export const tabPropTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.node,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export const tabDefaultProps = {
  icon: null,
  isActive: false,
};

export const tabsPropTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.shape(tabItemPropTypes)).isRequired,
  isSmallDevicesVersion: PropTypes.bool,
};

export const tabsDefaultProps = {
  className: null,
  isSmallDevicesVersion: false,
};
