import Button from "components/buttons/Button";
import FlexBox from "components/FlexBox";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { paymentMethodsMapping } from "utils/payment";
import { feeTypesMapping } from "../../../utils/fees";
import { routesMapping } from "../../../utils/routes";
import AmountDetails from "../components/parkings/AmountDetails";
import ParkingEndDate from "../components/parkings/EndDate";
import FeeNameBox from "../components/parkings/FeeNameBox";
import GeneralInfo from "../components/parkings/ParkingDetails";
import SelectedBenefit from "../components/parkings/SelectedBenefit";
import ParkingTimer from "../components/parkings/Timer";
import TimerAdditionalInfo from "../components/parkings/TimerAdditionalInfo";
import useTimerInfo from "../hooks/useTimerInfo";
import { detailsDefaultProps, detailsPropTypes } from "./propTypes";

const ParkingDetailsWrapper = styled.div`
  flex: 3;
`;

const ParkingRightInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const SelectedBenefitWrapper = styled.div`
  flex: 2;
  margin: 0 20px;
`;

const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ParkingDetails = ({
  id,
  vehicleBrand,
  vehicleLicensePlate,
  explorationName,
  streetName,
  benefitName,
  benefitColor,
  currentAmount,
  vehicleColor,
  onRenewCallback,
  startingDate,
  endingDate,
  queryString,
  parkingTypeId,
  fee,
  scheduleExtraInfo,
  parkingPaymentTypeId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMinutesFee = fee.id === feeTypesMapping.minutes;

  const stopParking = () => {
    history.push(`${routesMapping.backofficeParking}/${id}/stop?${queryString}`);
  };

  const timerInfo = useTimerInfo(id, { endCallback: stopParking });

  const isMBWayPayment = parkingPaymentTypeId === paymentMethodsMapping.mbway.id;

  return (
    <>
      <FlexBox>
        <ParkingDetailsWrapper>
          <GeneralInfo
            vehicleColor={vehicleColor}
            vehicleBrand={vehicleBrand}
            vehicleLicensePlate={vehicleLicensePlate}
            streetName={streetName}
            explorationName={explorationName}
          />
        </ParkingDetailsWrapper>
        {benefitName && (
          <SelectedBenefitWrapper>
            <SelectedBenefit description={benefitName} color={benefitColor} />
          </SelectedBenefitWrapper>
        )}
        <ParkingRightInfo>
          <AmountDetails amount={currentAmount} />
          {!isMinutesFee && fee.name && <FeeNameBox className="mt-15" name={fee.name} />}
        </ParkingRightInfo>
      </FlexBox>
      <TimerContainer>
        <ParkingTimer
          parkingId={id}
          parkingTypeId={parkingTypeId}
          startingDate={startingDate}
          endingDate={endingDate}
          scheduleExtraInfo={scheduleExtraInfo}
          size="lg"
        />
        <ParkingEndDate
          className="mt-15"
          parkingTypeId={parkingTypeId}
          endingDate={endingDate}
        />
        <TimerAdditionalInfo state={timerInfo?.state} nextStart={timerInfo?.nextStart} />
      </TimerContainer>
      <ButtonsContainer>
        <Button size="md" onClick={onRenewCallback} className="mb-10" color="info">
          {t("46") /* Renovar */}
        </Button>
        {isMinutesFee && !isMBWayPayment && (
          <Button color="danger" size="md" onClick={stopParking}>
            {t("3216") /* Parar */}
          </Button>
        )}
      </ButtonsContainer>
    </>
  );
};

ParkingDetails.propTypes = detailsPropTypes;

ParkingDetails.defaultProps = detailsDefaultProps;

export default ParkingDetails;
