import { Text, Link, PublicPagePopupsProvider } from "iparque-components";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useLocalizedUrls from "hooks/useLocalizedUrls";
import useWarnings from "hooks/useWarnings";
import useCookies from "hooks/useCookies";
import useIsPublicPage from "hooks/useIsPublicPage";

const POPUPS_DELAY = 1.5;

const PublicPagePopups = ({ children }) => {
  const { t } = useTranslation();
  const { privacyPolicyUrl } = useLocalizedUrls();
  const warnings = useWarnings();
  const { isCookiesAccepted, handleAcceptCookies } = useCookies();
  const isPublicPage = useIsPublicPage();

  if (!isPublicPage) {
    return children;
  }

  return (
    <PublicPagePopupsProvider
      popupsDelay={POPUPS_DELAY}
      warnings={warnings}
      cookies={
        isCookiesAccepted
          ? undefined
          : {
              body: (
                <>
                  <Text variant="body7" color="secondary">
                    {
                      t(
                        "7875"
                      ) /* O iParque utiliza cookies para melhorar a tua experiência neste website. */
                    }
                  </Text>
                  <Text className="inline" variant="body7" color="secondary">
                    {`${t("7953") /* Ao navegar, estás a consentir a utilização. */}
                ${t("7954") /* Vê mais sobre a */}`}
                    &nbsp;
                    <b>
                      <Link
                        rel="noopener"
                        underline="always"
                        target="_blank"
                        href={privacyPolicyUrl}
                      >
                        {t("7955") /* política de cookies acin */}
                      </Link>
                    </b>
                    .
                  </Text>
                </>
              ),
              button: {
                label: t("6517") /* Aceito */,
                onClick: handleAcceptCookies,
              },
            }
      }
    >
      {children}
    </PublicPagePopupsProvider>
  );
};

PublicPagePopups.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicPagePopups;
