import { hasObjectProperty } from "./general";

export const REQUEST_CANCELED = "fe000";
export const DEFAULT_API_ERROR_CODE = "e999";
export const DRIVER_EMAIL_PASSWORD_INVALID = "e347";
export const EMAIL_ALREADY_EXISTS = "e398";
export const DRIVER_ALREADY_ACTIVATED = "e406";
export const UNAUTHORIZED = "e000";
export const PASSWORD_REQUIREMENTS_NOT_MET = "e1939";
export const MISSING_PARKING_FEES = "e519";
export const MISSING_STREET_HOLIDAYS = "e661";
export const OLD_PASSWORD_NOT_MATCH = "e410";
export const REQUIRED_PHONE = "e113";
export const INVALID_TAX_PAYER_IDENTIFICATION_NUMBER = "e1538";
export const DRIVER_WITHOUT_BALANCE = "e326";
export const PASSWORD_ARE_EQUAL = "e409";
export const INVALID_DRIVER_VAT_NUMBER = "e218";
export const DRIVER_VEHICLES_NOT_VALIDATED = "e232";
export const REQUIRED_DRIVER_VAT_NUMBER = "e219";
export const REQUIRED_DRIVER_VEHICLES = "e220";
export const MAX_PARKING_DURATION_DAY_REACHED = "e324";
export const LICENSE_PLATE_HAS_NOTICE = "e318";
export const ZONE_PARKING_STARTED_ALREADY = "e376";
export const VISA_REFERENCE_WAS_PREVIOUSLY_USED = "e1631";
export const PARKING_NO_TIME_AVAILABLE = "e341";
export const VEHICLE_ALREADY_EXISTS = "e235";
export const UNABLE_TO_GET_STREETS = "e2804";
export const UNABLE_TO_LOGIN_DRIVER = "e356";
export const REQUIRE_THE_ORDER_OF_VEHICLE_REGIMES = "e2999";
export const REQUIRE_PERMIT_TYPE_DOCUMENTS = "e3001";
export const REQUIRE_PERMITS = "e3002";
export const REQUIRE_PERMIT_TYPE_VEHICLE_REGIMES = "e3003";
export const MAXIMUM_NUMBER_PERMIT_HOLDER_PROCESSES_ALLOWED_SAME_TAX_IDENTIFICATION_NUMBER_HAS_BEEN_REACHED =
  "e437";
export const MAXIMUM_NUMBER_PERMIT_HOLDER_PROCESSES_ALLOWED_SAME_ADDRESS_HAS_BEEN_REACHED =
  "e438";
export const DRIVER_ALREADY_IDENTIFIED = "e2948";
export const EXCEEDED_LIMIT_DATE_FOR_DRIVER_IDENTIFICATION = "e2947";
export const MISSING_PERMIT_HOLDERS_SUBSCRIPTIONS = "e1522";
export const MISSING_PERMIT_HOLDER_VEHICLES = "e1606";
export const NOTICE_NOT_FOUND = "e222";
export const TOKEN_NOT_FOUND = "e3057";
export const MISSING_PERMIT_HOLDER_HISTORY = "e888";
export const PERMIT_HOLDER_ALREADY_ASSOCIATED = "e3075";
export const INVALID_TOKEN = "e021";
export const ENTITY_TOKEN_DOES_NOT_EXIST = "e3077";
export const TOO_MANY_UNSUCCESSFUL_ATTEMPTS = "e1601";
export const DRIVER_NOT_ACTIVATED = "e400";
export const EMAIL_DISPATCHER_NOT_READY = "e411";
export const TIMEZONE_DIFFERS_FROM_LOCAL_TIME = "e3766";

export const hasErrorCode = (responseError, errorCode) => {
  if (!hasObjectProperty(responseError, "code")) {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.error(responseError);
    }

    return false;
  }

  if (responseError.code === errorCode) {
    return true;
  }

  if (responseError?.errors && responseError.errors === null) {
    return false;
  }

  const { errors } = responseError;

  for (let index = 0; index < errors.length; index += 1) {
    const error = errors[index];

    if (error.code === errorCode) {
      return true;
    }
  }

  return false;
};

export const hasUnauthorizedError = (error) => {
  return hasErrorCode(error, UNAUTHORIZED);
};

export const hasCanceledError = (error) => {
  return hasErrorCode(error, REQUEST_CANCELED);
};
