import { useAuth } from "components/AuthProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getParkingPaymentMethods } from "../parking/controller";
import { parkingRenewalSteps } from "./controller";

const useParkingRenewal = (parking) => {
  const { defaultEntityId } = useAuth();
  const [allowBack, setAllowBack] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [steps, setSteps] = useState([parkingRenewalSteps.chooseDuration]);

  useEffect(() => {
    (async () => {
      const responsePaymentMethods = await getParkingPaymentMethods(defaultEntityId);

      setPaymentMethod(
        responsePaymentMethods.find(
          (responsePaymentMethod) =>
            responsePaymentMethod.id === parking.parkingPaymentTypeId
        )
      );
    })();
  }, [parking, defaultEntityId]);

  const step = useMemo(() => steps[0], [steps]);

  const onBack = useCallback(() => {
    setSteps((currentSteps) => {
      if (currentSteps.length === 1) {
        return currentSteps;
      }

      const currentStepsCopy = [...currentSteps];

      currentStepsCopy.shift();

      return currentStepsCopy;
    });
  }, []);

  const changeStep = useCallback((newStep) => {
    setSteps((currentSteps) =>
      currentSteps[0] === newStep ? currentSteps : [newStep, ...currentSteps]
    );
  }, []);

  return { step, steps, onBack, changeStep, allowBack, setAllowBack, paymentMethod };
};

export default useParkingRenewal;
