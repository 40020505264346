import { colorsMapping } from "utils/colors";

export const processVehicles = (vehicles) =>
  vehicles.map((vehicle) => ({
    id: vehicle.id,
    licencePlate: vehicle.licensePlate,
    countryId: vehicle.countryId,
    colorId: vehicle.colorId || colorsMapping.gray,
    brandId: vehicle.model?.brand?.id,
    brandName: vehicle.model?.brand?.name,
    modelId: vehicle.model?.id,
    modelName: vehicle.model?.name,
    enabled: !!(vehicle.model?.id && vehicle.model?.brand?.id),
  }));
