/* eslint-disable react/jsx-props-no-spreading */
import Axios from "axios";
import moment from "moment";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { paymentMethodsMapping } from "utils/payment";
import ParkingRenew from "./Renew";
import SmallDevicesParkingRenew from "./smallDevices/Renew";
import { useAuth } from "../../../components/AuthProvider";
import {
  getParking as getParkingRequest,
  calculateParking as calculateParkingRequest,
  getFees as getFeesRequest,
  renewParking as renewParkingRequest,
} from "../requests/parkings";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import useQuery from "../../../hooks/useQuery";
import {
  minuteInMilliseconds,
  serverDateTimeFormat,
  secondInMilliseconds,
} from "../../../utils/dateTime";
import { benefitToArray } from "../parking/controller";
import { buildParkingQueryString, parkingMethodsMapping } from "../../../utils/parking";
import { feeTypesMapping } from "../../../utils/fees";
import { AppContext } from "../../../components/AppProvider";
import { smallDevicesBreakpoints } from "../../../utils/breakpoints";
import { routesMapping } from "../../../utils/routes";
import { calculatedParkingInitialState, parkingRenewalSteps } from "./controller";
import { useNetInfoContext } from "../../../providers/NetInfo";
import useParkingRenewal from "./useParkingRenewal";

const ParkingRenewPage = () => {
  const { parkingId } = useParams();
  const { defaultEntityId, userId, updateBalance, driverHash } = useAuth();
  const { backofficeTheme } = useBackofficeThemeContext();
  const { breakpoint, setIsLoading: setPageLoading } = useContext(AppContext);
  const { hasInternetAccess } = useNetInfoContext();
  const history = useHistory();

  const {
    amount,
    benefitColor,
    benefitName,
    duration,
    paymentMethodId,
    explorationName,
    streetName,
    streetId,
    vehicleBrand,
    vehicleColor,
    vehicleLicensePlate,
    feeTypeId, // Passing this property avoid waiting for the parking request to set the box height
  } = useQuery();

  const [parking, setParking] = useState({
    currentAmount: parseFloat(amount) || 0,
    duration: parseInt(duration, 10) || 0,
    explorationName: explorationName || "",
    streetName: streetName || "",
    streetId: streetId || "",
    vehicleBrand: vehicleBrand || "",
    vehicleColor: vehicleColor || "",
    vehicleLicensePlate: vehicleLicensePlate || "",
    benefitId: null,
    benefitName: benefitName || "",
    benefitValue: null,
    benefitColor: benefitColor || "",
    parkingPaymentTypeId: parseInt(paymentMethodId, 10),
  });

  const [calculatedParking, setCalculatedParking] = useState(
    calculatedParkingInitialState
  );

  const [fee, setFee] = useState({
    maxDuration: null,
    id: parseInt(feeTypeId, 10),
    name: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isRenewed, setIsRenewed] = useState(false);

  const {
    step,
    steps,
    allowBack,
    paymentMethod,
    onBack,
    changeStep,
    setAllowBack,
  } = useParkingRenewal(parking);

  useEffect(() => {
    if (smallDevicesBreakpoints.includes(breakpoint)) {
      setPageLoading(isLoading); // Block all screen in small devices
    }
  }, [breakpoint, isLoading]);

  useEffect(() => {
    let finishParkingInterval = null;

    if (parking.endingDate) {
      finishParkingInterval = setInterval(() => {
        if (
          moment().format(serverDateTimeFormat) <=
          moment(parking.endingDate).format(serverDateTimeFormat)
        ) {
          return;
        }

        history.push(
          `${routesMapping.backofficeParking}/${parking.id}/stop?${parking.queryString}`
        );
      }, secondInMilliseconds);
    } else if (finishParkingInterval) {
      clearInterval(finishParkingInterval);
    }

    return () => {
      clearInterval(finishParkingInterval);
    };
  }, [parking]);

  const getParking = useCallback(
    async ({ parkingInfoCancelToken, vehiclesCancelToken }) => {
      const parkingInfo = await getParkingRequest(
        defaultEntityId,
        driverHash,
        parkingId,
        {
          parkingInfoCancelToken,
          vehiclesCancelToken,
        }
      );

      if (!parkingInfo) {
        return;
      }

      const fees = await getFeesRequest(defaultEntityId, parkingInfo.streetId, {
        setIsLoading: () => {},
      });

      if (!fees) {
        return;
      }

      const minutesFee = fees.filter(
        (feeType) => feeType.id === feeTypesMapping.minutes
      )[0];

      setFee({ maxDuration: minutesFee.maxDuration, ...parkingInfo.feeType });

      delete parkingInfo.feeType;

      setParking({
        ...parkingInfo,
        queryString: buildParkingQueryString({
          ...parkingInfo,
          vehicleColor: backofficeTheme.carColor[parkingInfo.vehicleColorId],
        }),
        currentAmount: parkingInfo.amount,
        vehicleColor: backofficeTheme.carColor[parkingInfo.vehicleColorId],
        benefitColor: backofficeTheme.benefitColor[parkingInfo.benefitTypeId] || "",
      });
    },
    [defaultEntityId, userId, parkingId, backofficeTheme]
  );

  useEffect(() => {
    const parkingInfoCancelToken = Axios.CancelToken.source();
    const vehiclesCancelToken = Axios.CancelToken.source();

    const getParkingWithLoading = async () => {
      setIsLoading(true);
      await getParking({ parkingInfoCancelToken, vehiclesCancelToken });
      setIsLoading(false);
    };

    getParkingWithLoading();

    return () => {
      parkingInfoCancelToken.cancel();
      vehiclesCancelToken.cancel();
    };
  }, []);

  const calculateParkingCallback = useCallback(
    // Duration is always mandatory and greater than 0
    (newDuration = 1) => {
      const calculate = async () => {
        const calculatedParkingInfo = await calculateParkingRequest(
          defaultEntityId,
          parking.streetId,
          {
            driverId: userId,
            feeTypeId: fee.id,
            licensePlate: parking.vehicleLicensePlate,
            startingDate: moment().format(serverDateTimeFormat),
            benefits: benefitToArray(parking.benefitId, parking.benefitValue),
            parkingMethodId: parkingMethodsMapping.driverWeb,
            duration: newDuration,
          }
        );

        if (!calculatedParkingInfo) {
          setCalculatedParking({ ...calculatedParking, isLoading: false, error: true });
          return;
        }

        setCalculatedParking({
          ...calculatedParkingInfo,
          isLoading: false,
          error: false,
        });
      };

      setCalculatedParking({ ...calculatedParking, isLoading: true, error: false });
      calculate();
    },
    [parking, calculatedParking]
  );

  const renewParkingCallback = useCallback(
    // Duration is always mandatory and greater than 0
    async (newDuration = 1, params = {}, args = { disableLoading: false }) => {
      if (!args.disableLoading) {
        setIsLoading(true);
      }

      const parkingInfo = await renewParkingRequest(
        defaultEntityId,
        parking.streetId,
        parkingId,
        {
          driverId: userId,
          paymentTypeId: parking.parkingPaymentTypeId,
          benefits: benefitToArray(parking.benefitId, parking.benefitValue),
          parkingMethodId: parkingMethodsMapping.driverWeb,
          duration: newDuration,
          ...params,
        },
        { setIsLoading: () => {} }
      );

      if (!parkingInfo) {
        setIsLoading(false);
        return null;
      }

      return parkingInfo.id;
    },
    [defaultEntityId, parkingId, userId, parking]
  );

  const onRenewParkingWithSpecialPaymentMethod = (_, params = {}, args = {}) => {
    return renewParkingCallback(calculatedParking.duration, params, args);
  };

  const checkParkingCallback = useCallback(async () => {
    updateBalance();

    const parkingInfoCancelToken = Axios.CancelToken.source();
    const vehiclesCancelToken = Axios.CancelToken.source();

    await getParking({ parkingInfoCancelToken, vehiclesCancelToken });

    onBack();
    setIsRenewed(true);
    setIsLoading(false);
  }, [updateBalance, getParking, onBack]);

  const onRenewCallback = useCallback(() => {
    setIsRenewed(false);
    setCalculatedParking(calculatedParkingInitialState);
  }, []);

  useEffect(() => {
    if (!hasInternetAccess) {
      return undefined;
    }

    const parkingInfoCancelToken = Axios.CancelToken.source();
    const vehiclesCancelToken = Axios.CancelToken.source();

    let updateParkingInterval = null;

    if (isRenewed) {
      updateParkingInterval = setInterval(
        () => getParking({ parkingInfoCancelToken, vehiclesCancelToken }),
        minuteInMilliseconds
      );
    } else if (updateParkingInterval) {
      clearInterval(updateParkingInterval);
    }

    return () => {
      if (updateParkingInterval) {
        clearInterval(updateParkingInterval);
      }

      parkingInfoCancelToken.cancel();
      vehiclesCancelToken.cancel();
    };
  }, [hasInternetAccess, isRenewed]);

  const onConfirmRenewalCallback = useCallback(
    async (newDuration = 1, params = {}) => {
      if (parking.parkingPaymentTypeId === paymentMethodsMapping.balance.id) {
        await renewParkingCallback(newDuration, params);

        checkParkingCallback();
        return;
      }

      changeStep(parkingRenewalSteps.specialPaymentMethod);
    },
    [parking, renewParkingCallback, checkParkingCallback, changeStep]
  );

  const chooseViewBasedOnDevice = () => {
    const props = {
      vehicleColor: parking.vehicleColor,
      isRenewed,
      fee,
      step,
      allowBack,
      paymentMethod,
      setAllowBack,
      onBack: steps.length > 1 ? onBack : null,
      onRenewParkingWithSpecialPaymentMethod,
      chooseDurationProps: {
        parking,
        calculateParkingCallback,
        onConfirmRenewalCallback,
        renewParkingCallback,
        checkParkingCallback,
        calculatedParking,
      },
      detailsProps: { ...parking, onRenewCallback },
      isLoading,
    };

    if (smallDevicesBreakpoints.includes(breakpoint)) {
      return (
        <SmallDevicesParkingRenew
          vehicleLicensePlate={parking.vehicleLicensePlate}
          vehicleBrand={parking.vehicleBrand}
          streetName={parking.streetName}
          explorationName={parking.explorationName}
          {...props}
        />
      );
    }

    return <ParkingRenew {...props} />;
  };

  return <>{chooseViewBasedOnDevice()}</>;
};

export default ParkingRenewPage;
