import Button from "components/buttons/Button";
import { errorToast } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook.svg";
import useFacebook from "../../../hooks/useFacebook";
import { socialNetworksMapping } from "../../../utils/general";

const FacebookLoginButton = ({ size, className, loginCallback }) => {
  const { t } = useTranslation();
  const { facebookInstance } = useFacebook();

  const loginWithFacebook = () => {
    facebookInstance.login(
      (response) => {
        if (!response?.authResponse) {
          errorToast(
            t("3613") /* Erro */,
            t("8183") /* Não foi possível estabelecer a ligação com o Facebook */
          );
          return;
        }

        facebookInstance.api("/me?fields=email,first_name,last_name", (userInfo) => {
          loginCallback(socialNetworksMapping.facebook, {
            email: userInfo.email,
            socialId: userInfo.id,
            name: `${userInfo.first_name || ""} ${userInfo.last_name || ""}`.trim(),
          });
        });
      },
      { scope: "email" }
    );
  };

  return (
    <>
      {facebookInstance && (
        <StyledButton
          size={size}
          type="button"
          className={className}
          color="tertiary"
          startIcon={
            <FacebookIcon aria-labelledby="Facebook logo" title="Facebook logo" />
          }
          onClick={loginWithFacebook}
        >
          {t("2571") /* Entrar com o facebook */}
        </StyledButton>
      )}
    </>
  );
};

export default FacebookLoginButton;

FacebookLoginButton.propTypes = {
  size: PropTypes.string.isRequired,
  loginCallback: PropTypes.func.isRequired,
  className: PropTypes.string,
};

FacebookLoginButton.defaultProps = {
  className: "",
};

const StyledButton = styled(Button)`
  padding-left: 1rem;
  padding-right: 1rem;
`;
