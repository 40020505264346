import useMediaQuery from "hooks/useMediaQuery";
import { usePublicPagePopupsContext } from "iparque-components";
import PropTypes from "prop-types";
import React, { Suspense } from "react";
import styled from "styled-components";
import { breakpoints } from "../../../../utils/breakpoints";
import { routesMapping } from "../../../../utils/routes";
import { pageContexts } from "../../configs";
import Back from "./Back";
import LeftSideHeader from "./LeftSideHeader";

const DriverStreet = React.lazy(() => import("./DriverStreet"));

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: ${({ popupsHeight }) => popupsHeight ?? 0}px;
  transition: padding ${({ popupsAnimationDuration }) => popupsAnimationDuration}s;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.color.lightest} 50%,
    ${({ theme }) => theme.background.color.primary} 50%
  );

  @media (max-width: ${breakpoints.lg}) {
    background: unset;
    background-color: ${({ theme }) => theme.color.lightest};
  }
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-bottom: 80px;
  width: 50%;
  align-items: center;
  z-index: 1;
  flex: 1;

  @media (max-width: ${breakpoints.lg}) {
    width: 100%;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 350px;
  max-width: 450px;
  flex: 1;

  @media (max-width: ${breakpoints.lg}) {
    width: 80%;
    min-width: 300px;
  }
`;

const Template = ({ children, pageContext, title, onBackClick, backUrl }) => {
  const { paddingTop, animationDuration } = usePublicPagePopupsContext();
  const shouldRenderAnimation = useMediaQuery({ minWidth: breakpoints.lg });

  return (
    <Container popupsHeight={paddingTop} popupsAnimationDuration={animationDuration}>
      <Back to={backUrl || routesMapping.login} onClick={onBackClick} />
      <StyledContentContainer>
        <InnerContainer>
          <LeftSideHeader title={title} />
          {children}
        </InnerContainer>
      </StyledContentContainer>
      {shouldRenderAnimation && (
        <Suspense fallback={<div />}>
          <DriverStreet pageContext={pageContext} />
        </Suspense>
      )}
    </Container>
  );
};

Template.defaultProps = {
  pageContext: pageContexts.login,
  backUrl: routesMapping.landing,
  onBackClick: undefined,
};

Template.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.oneOf(Object.values(pageContexts)),
  backUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
  onBackClick: PropTypes.func,
};

export default Template;
