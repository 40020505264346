import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { themesMapping } from "themes/theme";
import { useTranslation } from "react-i18next";
import { ReactComponent as MbwayIcon } from "assets/icons/mbway.svg";
import { ReactComponent as AtmIcon } from "assets/icons/atm.svg";
import { ReactComponent as CreditCardIcon } from "assets/icons/credit-card.svg";
import { ReactComponent as BankTransferenceIcon } from "assets/icons/bank-transference.svg";
import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";
import { paymentMethodsMapping } from "utils/payment";
import { useBackofficeThemeContext } from "../ThemeProvider";
import { getIconSize, scenarios, sizes } from "./helper";

const Container = styled.div`
  .payment-type-icon {
    fill: ${({ theme, scenario }) => theme.paymentIcon[scenario].iconColor};
    flex-shrink: 0;

    ${({ $isNormalTheme }) =>
      $isNormalTheme &&
      `
        .mb-icon-box {
          fill: #005aa8;
        }

        .mbway-icon-box {
          fill: #d60510;
        }

        .bank-transference-icon {
          fill: #31d56b;
        }

        .credit-card-icon {
          fill: #0651f1;
        }
    `}
  }
`;

const PaymentMethodIcon = ({ paymentMethodId, small, size, scenario }) => {
  const { backofficeThemeName } = useBackofficeThemeContext();
  const isNormalTheme = backofficeThemeName === themesMapping.normal;
  const { t } = useTranslation();

  const processedSize = (() => {
    if (small) {
      return sizes.small;
    }

    return size;
  })();

  const icon = useMemo(() => {
    if (paymentMethodId === paymentMethodsMapping.atm.id) {
      return (
        <AtmIcon
          title={t("4000") /* Multibanco */}
          height={getIconSize({
            iconType: "atm",
            size: processedSize,
            sizeType: "height",
          })}
          width={getIconSize({ iconType: "atm", size: processedSize, sizeType: "width" })}
          className="payment-type-icon"
        />
      );
    }

    if (paymentMethodId === paymentMethodsMapping.mbway.id) {
      return (
        <MbwayIcon
          title={t("6916") /* MB WAY */}
          height={getIconSize({
            iconType: "mbway",
            size: processedSize,
            sizeType: "height",
          })}
          width={getIconSize({
            iconType: "mbway",
            size: processedSize,
            sizeType: "width",
          })}
          className="payment-type-icon"
        />
      );
    }

    if (paymentMethodId === paymentMethodsMapping.creditCard.id) {
      return (
        <CreditCardIcon
          title={t("8411") /* Cartão de Crédito */}
          height={getIconSize({
            iconType: "creditCard",
            size: processedSize,
            sizeType: "height",
          })}
          width={getIconSize({
            iconType: "creditCard",
            size: processedSize,
            sizeType: "width",
          })}
          className="payment-type-icon"
        />
      );
    }

    if (paymentMethodId === paymentMethodsMapping.balance.id) {
      return (
        <WalletIcon
          title={t("3680") /* Saldo */}
          height={getIconSize({
            iconType: "balance",
            size: processedSize,
            sizeType: "height",
          })}
          width={getIconSize({
            iconType: "balance",
            size: processedSize,
            sizeType: "width",
          })}
          className="payment-type-icon"
        />
      );
    }

    if (paymentMethodId === paymentMethodsMapping.bankTransference.id) {
      return (
        <BankTransferenceIcon
          title={t("1212") /* Transferência Bancária */}
          height={getIconSize({
            iconType: "bankTransference",
            size: processedSize,
            sizeType: "height",
          })}
          width={getIconSize({
            iconType: "bankTransference",
            size: processedSize,
            sizeType: "width",
          })}
          className="payment-type-icon"
        />
      );
    }

    return null;
  }, [paymentMethodId, scenario, size]);

  return (
    <Container scenario={scenario} $isNormalTheme={isNormalTheme}>
      {icon}
    </Container>
  );
};

PaymentMethodIcon.propTypes = {
  paymentMethodId: PropTypes.number.isRequired,
  size: PropTypes.oneOf(Object.values(sizes)),
  scenario: PropTypes.oneOf(Object.values(scenarios)),
  small: PropTypes.bool,
};

PaymentMethodIcon.defaultProps = {
  small: false,
  size: sizes.medium,
  scenario: scenarios.accountMovements,
};

export default PaymentMethodIcon;
