import React, { useEffect, useState, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import VehicleDetails from "./Details";
import VehicleDetailsSmallDevices from "./DetailsSmallDevices";
import DriversDataSource from "../../../../lib/clients/iParque/dataSources/driversDataSource";
import { useAuth } from "../../../../components/AuthProvider";
import { AppContext } from "../../../../components/AppProvider";
import { smallDevicesBreakpoints } from "../../../../utils/breakpoints";
import { colorsMapping } from "../../../../utils/colors";
import { errorMessage } from "../../../../utils/userMessages";

const driversDataSource = new DriversDataSource();

const VehicleDetailsPage = () => {
  const { vehicleId } = useParams();
  const { setIsLoading, breakpoint } = useContext(AppContext);
  const { driverHash } = useAuth();
  const { t } = useTranslation();
  const [vehicle, setVehicle] = useState(() => {
    const parsedId = parseInt(vehicleId, 10);

    return Number.isInteger(parsedId) ? { id: parsedId } : undefined;
  });

  const getVehicleDetails = useCallback(() => {
    setIsLoading(true);

    driversDataSource
      .getVehicleById(driverHash, vehicleId, { fillCollections: "model" })
      .then((response) => {
        setVehicle({
          ...response,
          brandId: response?.model?.brand?.id
            ? parseInt(response.model.brand.id, 10)
            : undefined,
          modelId: response?.model?.id ? parseInt(response.model.id, 10) : undefined,
          colorId: response?.colorId || colorsMapping.gray,
        });
      })
      .catch((error) => {
        errorMessage(error, t("7962") /* Ocorreu um erro ao obter os dados do veículo */);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (vehicleId !== "add") {
      getVehicleDetails();
    }
  }, []);

  return (
    <>
      {smallDevicesBreakpoints.includes(breakpoint) ? (
        <VehicleDetailsSmallDevices vehicle={vehicle} />
      ) : (
        <VehicleDetails vehicle={vehicle} />
      )}
    </>
  );
};

export default VehicleDetailsPage;
