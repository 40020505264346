import DropdownList from "domain/private/components/DropdownList";
import React from "react";
import LocationsMap from "../../../components/locations/Map";
import LocationsTabStatus from "../../../components/locations/Status";
import TabTitle from "../../../components/Title";
import { tabsIdsMap } from "../../../helper";
import useLocationsTab from "../../../hooks/useLocationsTab";

const LocationsTab = () => {
  const {
    mapController,
    selectedStreet,
    setSelectedStreet,
    zones,
    isLoading,
  } = useLocationsTab();

  return (
    <>
      <TabTitle tabId={tabsIdsMap.locations} />
      <LocationsTabStatus />
      {(zones.length > 0 || isLoading) && (
        <>
          <DropdownList items={zones} selectedItem={selectedStreet} />
          <LocationsMap mapController={mapController} onStreetClick={setSelectedStreet} />
        </>
      )}
    </>
  );
};

export default LocationsTab;
