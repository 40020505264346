import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Text } from "iparque-components";
import FlexBox from "components/FlexBox";
import Skeleton from "../../../../components/Skeleton";

const InlineDetail = ({ isLoading, title, value, smallValueSize, className }) => {
  return (
    <Container className={className}>
      <FlexBox size="sm">
        <Text variant="body4">{title}</Text>
      </FlexBox>
      <FlexBox size="md" justifyContent="flexEnd">
        {isLoading ? (
          <Skeleton height={20} width={70} />
        ) : (
          <Text variant={smallValueSize ? "body11" : "body3"}>{value || "-"}</Text>
        )}
      </FlexBox>
    </Container>
  );
};

InlineDetail.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.node,
  isLoading: PropTypes.bool,
  smallValueSize: PropTypes.bool,
};

InlineDetail.defaultProps = {
  isLoading: false,
  value: "",
  smallValueSize: false,
  className: undefined,
};

export default InlineDetail;

const Container = styled(FlexBox)`
  margin: 1px 0;
`;
