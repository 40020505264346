import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useStepsContext } from "domain/public/components/StepsProvider";
import { useDriverIdentificationContext } from "../Provider";
import PersonalData from "./PersonalData";
import Informations from "./Informations";
import { STEPS } from "./helper";
import Template from "../../components/Template";
import { usePublicEntityContext } from "../../Provider";
import Header from "../components/Header";
import Message from "../components/Message";
import Documents from "./Documents";
import ConfirmationStep from "./Confirmation";

const DriverIdentificationSteps = () => {
  const { t } = useTranslation();
  const { isEntityDataLoading } = usePublicEntityContext();
  const { identificationData } = useDriverIdentificationContext();
  const { currentStep, canGoBack, goBack } = useStepsContext();

  const stepsTitles = {
    [STEPS.informations]: t("6037") /* Informações */,
    [STEPS.personalData]: t("3673") /* Dados Pessoais */,
    [STEPS.documents]: t("4297") /* Documentos */,
    [STEPS.confirmation]: t("10253") /* Verifica os dados */,
  };

  const TitleComponent = (
    <p>
      {t("10239") /* Identificar o condutor do veículo */}
      <Header
        licencePlate={identificationData?.notice?.licensePlate}
        datetime={identificationData?.notice?.date}
      />
    </p>
  );

  if (identificationData?.error) {
    return (
      <Template
        isLoading={!identificationData || isEntityDataLoading}
        title={TitleComponent}
      >
        {identificationData?.error && (
          <Message type="error" text={identificationData.error} />
        )}
      </Template>
    );
  }

  return (
    <Template
      isLoading={isEntityDataLoading}
      title={TitleComponent}
      subTitle={stepsTitles[currentStep?.index]}
      onBack={canGoBack ? goBack : undefined}
    >
      <Step isVisible={currentStep?.index === STEPS.informations}>
        <Informations />
      </Step>
      <Step isVisible={currentStep?.index === STEPS.personalData}>
        <PersonalData />
      </Step>
      <Step isVisible={currentStep?.index === STEPS.documents}>
        <Documents />
      </Step>
      <Step isVisible={currentStep?.index === STEPS.confirmation}>
        <ConfirmationStep />
      </Step>
    </Template>
  );
};

export default DriverIdentificationSteps;

const Step = styled.div`
  display: ${({ isVisible }) => (isVisible ? `flex` : `none`)};
  flex-direction: column;
  flex: 1;
  margin-top: 10px;
`;
