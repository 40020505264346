import { useAppContext } from "components/AppProvider";
import Button from "components/buttons/Button";
import FlexBox from "components/FlexBox";
import { Text } from "iparque-components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { breakpoints, breakpointsMapping } from "utils/breakpoints";
import { routesMapping } from "../../../utils/routes";
import SmallDevicesContentHeader from "../components/smallDevices/ContentHeader";
import EmptyActiveParkings from "./components/EmptyActiveParkings";
import ActiveParkingBoxMediumDevices from "./mediumDevices/ActiveParkingBox";
import FinishedParkingBoxMediumDevices from "./mediumDevices/FinishedParkingBox";
import parkingsProps from "./propTypes";
import ActiveParkingBoxSmallDevices from "./smallDevices/ActiveParkingBox";
import FinishedParkingBoxSmallDevices from "./smallDevices/FinishedParkingBox";

const smallDevicesBreakpoints = [
  breakpointsMapping.xs,
  breakpointsMapping.sm,
  breakpointsMapping.md,
];

const ParkingsSmallDevices = ({ activesParkings, finishedParkings, updateCallback }) => {
  const { t } = useTranslation();
  const { breakpoint } = useAppContext();
  const history = useHistory();

  const { ActiveParkingBoxComponent, FinishedParkingBoxComponent } = useMemo(() => {
    const isSmallDevice = smallDevicesBreakpoints.includes(breakpoint);

    return {
      ActiveParkingBoxComponent: isSmallDevice
        ? ActiveParkingBoxSmallDevices
        : ActiveParkingBoxMediumDevices,
      FinishedParkingBoxComponent: isSmallDevice
        ? FinishedParkingBoxSmallDevices
        : FinishedParkingBoxMediumDevices,
    };
  }, [breakpoint]);

  return (
    <>
      <SmallDevicesContentHeader
        topTitle={t("7997") /* Consulta os teus estacionamentos */}
        bottomTitle={t("7998") /* Não deixes que nada te escape! */}
      />
      {activesParkings.length > 0 && (
        <HeaderContainer justifyContent="spaceBetween" className="mt-30">
          <Text className="uppercase mb-10" color="quaternary" variant="body9">
            {t("2") /* Estacionamentos ativos */}
          </Text>
          <Button
            size="xs"
            id="add-button"
            onClick={() => {
              history.push(`${routesMapping.backofficeParking}`);
            }}
          >
            {t("4045") /* Novo Estacionamento */}
          </Button>
        </HeaderContainer>
      )}
      <ActiveParkingsContainer>
        {activesParkings.length > 0 ? (
          activesParkings.map((parking) => (
            <ActiveParkingBoxComponent
              key={parking.id}
              updateCallback={updateCallback}
              {...parking}
            />
          ))
        ) : (
          <EmptyActiveParkings
            smallVersion={breakpoint !== breakpointsMapping.noBreakpoint}
          />
        )}
      </ActiveParkingsContainer>
      {finishedParkings.length > 0 && (
        <>
          <Text className="uppercase mt-30" variant="body9">
            {t("8001") /* Histórico de estacionamentos */}
          </Text>
          <div className="mt-10">
            {finishedParkings.map((parking) => (
              <FinishedParkingBoxComponent key={parking.id} {...parking} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

ParkingsSmallDevices.propTypes = parkingsProps;

export default ParkingsSmallDevices;

const HeaderContainer = styled(FlexBox)`
  @media (max-width: 600px) {
    flex-direction: column-reverse;

    button {
      align-self: center;
      margin-bottom: 30px;
    }
  }
`;

const ActiveParkingsContainer = styled.div`
  margin-top: 30px;

  @media (max-width: ${breakpoints.md}) {
    margin-top: 10px;
  }

  @media (max-width: 600px) {
    margin-top: 0;
  }
`;
