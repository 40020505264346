import PaymentsDataSource from "lib/clients/iParque/dataSources/paymentsDataSource";

const paymentDataSource = new PaymentsDataSource();

export const getExternalPaymentStatus = async (entityId, paymentId, params) => {
  try {
    const response = await paymentDataSource.getExternalPaymentStatus(
      entityId,
      paymentId,
      params
    );

    return response.state;
  } catch {
    return null;
  }
};
