export const partyTypes = {
  person: 1,
  company: 2,
};

export const partyTypesWithCompanyFields = [partyTypes.company];

export const partyCreationTypes = {
  owners: 1,
  permitHolders: 2,
};

export const clientValidationScenarios = {
  offenceDefendant: "offence_defendant",
  permitHolders: "permit_holders",
  driversIdentifications: "driver_identification",
};
