import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getObjectKeyByValue } from "../utils/general";

const sizeProportionMapping = {
  xs: 0.5,
  sm: 1,
  md: 1.5,
  lg: 2,
  xl: 3,
};

const flexDirectionPropertyMapping = {
  column: "column",
  row: "row",
};

const alignItemsPropertyMapping = {
  flexStart: "flex-start",
  flexEnd: "flex-end",
  center: "center",
  baseline: "baseline",
  stretch: "stretch ",
};

const justifyContentPropertyMapping = {
  flexStart: "flex-start",
  flexEnd: "flex-end",
  center: "center",
  spaceBetween: "space-between",
  spaceEvenly: "space-evenly",
};

const Container = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItemsPropertyMapping[alignItems]};
  justify-content: ${({ justifyContent }) =>
    justifyContentPropertyMapping[justifyContent]};
  flex-direction: ${({ flexDirection }) => flexDirectionPropertyMapping[flexDirection]};
  flex: ${({ size }) => sizeProportionMapping[size]};
  gap: ${({ gap }) => gap}px;
  ${({ right }) => (right ? "margin-left: auto;" : "")}
  ${({ fullWidth }) => (fullWidth ? "width: 100%; " : "")}
`;

const FlexBox = ({
  children,
  alignItems,
  justifyContent,
  size,
  className,
  flexDirection,
  right,
  fullWidth,
  gap,
}) => {
  return (
    <Container
      alignItems={alignItems}
      justifyContent={justifyContent}
      size={size}
      flexDirection={flexDirection}
      className={className}
      right={right}
      fullWidth={fullWidth}
      gap={gap}
    >
      {children}
    </Container>
  );
};

FlexBox.defaultProps = {
  alignItems: getObjectKeyByValue(
    alignItemsPropertyMapping,
    alignItemsPropertyMapping.stretch
  ),
  justifyContent: getObjectKeyByValue(
    justifyContentPropertyMapping,
    justifyContentPropertyMapping.flexStart
  ),
  size: getObjectKeyByValue(sizeProportionMapping, sizeProportionMapping.small),
  flexDirection: getObjectKeyByValue(
    flexDirectionPropertyMapping,
    flexDirectionPropertyMapping.row
  ),
  className: "",
  right: false,
  fullWidth: false,
  gap: 0,
  children: undefined,
};

FlexBox.propTypes = {
  children: PropTypes.node,
  justifyContent: PropTypes.oneOf(Object.keys(justifyContentPropertyMapping)),
  alignItems: PropTypes.oneOf(Object.keys(alignItemsPropertyMapping)),
  size: PropTypes.oneOf(Object.keys(sizeProportionMapping)),
  flexDirection: PropTypes.oneOf(Object.keys(flexDirectionPropertyMapping)),
  className: PropTypes.string,
  right: PropTypes.bool,
  fullWidth: PropTypes.bool,
  gap: PropTypes.number,
};

export default FlexBox;
