import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Heading } from "iparque-components";
import { ReactComponent as Back } from "../../../assets/icons/backLarge.svg";
import { useBackofficeThemeContext } from "./ThemeProvider";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  #page-title-container {
    margin-left: ${({ hasBackCallback }) => (hasBackCallback ? "10px" : "-35px")};
    transition: margin 0.3s ease;
    line-height: 1.2;
  }
`;

const BackWrapper = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};

  ${({ isVisible }) => isVisible && "transition: opacity 1s ease;"}
`;

const ContentHeader = ({ topTitle, bottomTitle, backCallback }) => {
  const { backofficeTheme } = useBackofficeThemeContext();
  const hasBackCallback = typeof backCallback === "function";

  return (
    <HeaderContainer hasBackCallback={hasBackCallback}>
      <BackWrapper isVisible={hasBackCallback}>
        <Back
          height="65"
          width="35"
          cursor={hasBackCallback ? "pointer" : "auto"}
          fill={backofficeTheme.background.color.primary}
          onClick={backCallback}
        />
      </BackWrapper>
      <div id="page-title-container">
        <Heading color="secondary" variant="h3">
          {topTitle}
        </Heading>
        <Heading color="primary" variant="h3">
          {bottomTitle}
        </Heading>
      </div>
    </HeaderContainer>
  );
};

ContentHeader.propTypes = {
  topTitle: PropTypes.string.isRequired,
  bottomTitle: PropTypes.string.isRequired,
  backCallback: PropTypes.func,
};

ContentHeader.defaultProps = {
  backCallback: null,
};

export default ContentHeader;
