import React, { useMemo } from "react";
import styled from "styled-components";
import { tabPanelDefaultProps, tabPanelPropTypes } from "../propTypes";

const Container = styled.div`
  flex: 1;
`;

const TabPanel = ({ tabs, activeTabId, className, isSmallDevicesVersion }) => {
  const activeTabPanel = useMemo(() => {
    for (let index = 0; index < tabs.length; index += 1) {
      const tab = tabs[index];

      if (tab.id === activeTabId) {
        return (
          <Container className={className} key={tab.id}>
            {React.cloneElement(tab.component, {
              isSmallDevicesVersion,
            })}
          </Container>
        );
      }
    }

    return null;
  }, [tabs, activeTabId]);

  return <>{activeTabPanel}</>;
};

TabPanel.propTypes = tabPanelPropTypes;

TabPanel.defaultProps = tabPanelDefaultProps;

export default TabPanel;
