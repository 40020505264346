import CityList from "components/CityList";
import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import { getCurrentDateTimeInServerFormat } from "utils/dateTime";
import { useStepContext } from "../../components/Step";
import { usePermitHoldersContext } from "../../Provider";
import { actionTypes } from "../../store/actions";

const CityStep = () => {
  const { dispatch, isPrivateContext, selectedCity, entity } = usePermitHoldersContext();
  const { cities } = useStepContext();

  const onChooseCity = useCallback(({ id, districtId, name }) => {
    dispatch({
      type: actionTypes.SET_SELECTED_CITY,
      payload: {
        id,
        name,
        districtId,
        updatedDateTime: getCurrentDateTimeInServerFormat(),
      },
    });
  }, []);

  const processedCities = cities.map((city) => ({ ...city, entity }));

  return (
    <Container isPrivateContext={isPrivateContext}>
      <CityList
        items={processedCities}
        selected={{ entityId: entity.id, cityId: selectedCity?.id }}
        onClick={onChooseCity}
      />
    </Container>
  );
};

export default CityStep;

const Container = styled.div`
  ${({ isPrivateContext }) =>
    isPrivateContext
      ? css`
          max-width: 700px;
        `
      : ""}
`;
