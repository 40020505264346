import useLocation from "hooks/useLocation";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { formatCitiesWithDistances } from "utils/cities";
import CityItem from "./Item";

const List = ({ cities, className }) => {
  const [processedCities, setProcessedCities] = useState([]);
  const { position } = useLocation(false, {
    enableHighAccuracy: true,
  });

  useEffect(() => {
    if (!cities) {
      return;
    }

    setProcessedCities(formatCitiesWithDistances(cities, position));
  }, [cities]);

  return (
    <div className={className}>
      {processedCities.map((city, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <CityItem key={`${city.name}-${index}`} className="mb-5" city={city} />
      ))}
    </div>
  );
};

List.defaultProps = {
  className: null,
};

List.propTypes = {
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      entityId: PropTypes.number.isRequired,
      entityLogo: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
};

export default List;
