import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { routesMapping } from "utils/routes";

const useOnClickCreate = () => {
  const history = useHistory();

  const onClickNewPermitHolder = useCallback(() => {
    history.push(routesMapping.backofficePermitHoldersCreation);
  }, [history]);

  return { onClickNewPermitHolder };
};

export default useOnClickCreate;
