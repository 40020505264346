import { useAppContext } from "components/AppProvider";
import LanguagesDataSource from "lib/clients/iParque/dataSources/languagesDataSource";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { languageWithoutRegion } from "utils/general";
import { errorMessage } from "utils/userMessages";

const languagesDataSource = new LanguagesDataSource();

const getLanguages = async (params, t) => {
  try {
    const response = await languagesDataSource.getAll(params, true);

    return response.items.map((language) => ({
      value: language.code,
      label: language.language,
    }));
  } catch (error) {
    errorMessage(error, t("7931") /* Ocorreu um erro ao obter os idiomas */);

    return null;
  }
};

const useLanguages = ({ useLoading = false, onChangeCallback = undefined }) => {
  const [languages, setLanguages] = useState([]);
  const { setIsLoading } = useAppContext();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    (async () => {
      setIsLoading(useLoading && true);

      const newLanguages = await getLanguages({}, t);

      setIsLoading(false);

      if (newLanguages) {
        setLanguages(newLanguages);
      }
    })();
  }, [t]);

  const onChange = useCallback(
    (newLanguage) => {
      i18n.changeLanguage(newLanguage);

      if (onChangeCallback) {
        onChangeCallback();
      } else {
        window.location.reload();
      }
    },
    [i18n, onChangeCallback]
  );

  return {
    languages,
    onChangeLanguage: onChange,
    language: languageWithoutRegion(i18n.language),
  };
};

export default useLanguages;
