/* eslint-disable react/jsx-props-no-spreading */
import { Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import ContentHeader from "../components/smallDevices/ContentHeader";
import { parkingDefaultProps, parkingPropTypes } from "./propTypes";
import { getStepDescription } from "./controller";
import ParkingBottomSteps from "./ParkingBottomSteps";
import ParkingSteps from "./ParkingSteps";

const ParkingSmallDevices = ({
  streets,
  candidateStreet,
  candidateStreetId,
  step,
  onBack,
  onlyParkWithActiveGeographicLocationActive,
  bottomStepsProps,
  stepsProps,
  selectedVehicle,
  selectedBenefit,
}) => {
  const { t } = useTranslation();
  const basePath = "./smallDevices";

  return (
    <>
      <ContentHeader
        topTitle={t("7830") /* Vamos estacionar? */}
        bottomTitle={t("7831") /* É fácil! */}
        backCallback={onBack}
      />
      <Text className="uppercase mt-20 mb-4" color="quaternary" variant="body9">
        {getStepDescription(step, {
          onlyParkWithActiveGeographicLocationActive,
        })}
      </Text>
      <ParkingSteps
        basePath={basePath}
        streets={streets}
        step={step}
        candidateStreetId={candidateStreetId}
        selectedVehicle={selectedVehicle}
        selectedBenefit={selectedBenefit}
        {...stepsProps}
      />
      <ParkingBottomSteps
        className="mt-20"
        basePath={basePath}
        step={step}
        candidateStreet={candidateStreet}
        streets={streets}
        selectedVehicle={selectedVehicle}
        selectedBenefitId={selectedBenefit.id}
        {...bottomStepsProps}
      />
    </>
  );
};

ParkingSmallDevices.defaultProps = parkingDefaultProps;

ParkingSmallDevices.propTypes = parkingPropTypes;

export default React.memo(ParkingSmallDevices);
