import useInfiniteList from "hooks/useInfiniteList";
import { Text } from "iparque-components";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import { routesMapping } from "utils/routes";
import { CONTENT_CONTAINER_HORIZONTAL_SPACING } from "../components/ContentBorderBox";
import ErrorCard from "../components/ErrorCard";
import useVehicles from "./hooks/useVehicles";
import SoldVehiclesBox from "./SoldVehicleBox";

const SoldVehicles = () => {
  const { t } = useTranslation();
  const { getSoldVehicles } = useVehicles();
  const history = useHistory();

  const { items, onFinishedScroll, isLoading, hasError } = useInfiniteList(
    getSoldVehicles
  );

  const onClickVehicle = useCallback(
    (id) => history.push(`${routesMapping.backofficeVehicles}/${id}`),
    [history]
  );

  if (isLoading || items.length === 0) {
    return null;
  }

  if (hasError) {
    return <ErrorCard />;
  }

  return (
    <Container>
      <Text className="uppercase mt-30" variant="body9">
        {t("9670") /* Meus veículos que já foram vendidos */}
      </Text>
      <SoldVehiclesContainer onScroll={onFinishedScroll}>
        {items.map((vehicle) => {
          return (
            <SoldVehiclesBox
              key={vehicle.id}
              color={vehicle.color}
              brand={vehicle.brand}
              licensePlate={vehicle.licensePlate}
              purchaseDate={vehicle.purchaseDate}
              saleDate={vehicle.saleDate}
              onClick={() => onClickVehicle(vehicle.id)}
            />
          );
        })}
      </SoldVehiclesContainer>
    </Container>
  );
};

export default SoldVehicles;

const Container = styled.div`
  margin-left: ${CONTENT_CONTAINER_HORIZONTAL_SPACING}px;

  @media (max-width: ${breakpoints.lg}) {
    margin-left: unset;
  }
`;

const SoldVehiclesContainer = styled.div`
  margin-top: 10px;
  max-width: 600px;
  max-height: 600px;
  overflow: auto;
`;
