import InfiniteList from "components/InfiniteList";
import React from "react";
import EmptyListInfo from "../../../components/subscriptions/EmptyListInfo";
import ErrorComponent from "../../../components/subscriptions/ErrorComponent";
import TabTitle from "../../../components/Title";
import { LIST_MAX_HEIGHT } from "../../../configs";
import { tabsIdsMap } from "../../../helper";
import useSubscriptions from "../../../hooks/useSubscriptions";
import Card from "./Card";

const NUMBER_OF_LOADING_CARDS = 2;

const SubscriptionsTab = () => {
  const { items, isLoading, onFinishedScroll, hasError } = useSubscriptions();

  return (
    <>
      <TabTitle tabId={tabsIdsMap.subscriptions} />
      <InfiniteList
        hasError={hasError}
        onFinishedScroll={onFinishedScroll}
        isLoading={isLoading}
        renderItem={(item) => <Card {...item} />}
        items={items}
        loadingItemsNumber={NUMBER_OF_LOADING_CARDS}
        EmptyListComponent={<EmptyListInfo />}
        ErrorComponent={<ErrorComponent />}
        maxHeight={LIST_MAX_HEIGHT}
      />
    </>
  );
};

export default SubscriptionsTab;
