import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import VerticalTabs from "../../../components/tabs/verticals/Tabs";
import ContentHeader from "../components/ContentHeader";
import Theme from "./Theme";
import { settingsPropTypes } from "./propTypes";

const ThemeContainer = styled.div`
  width: 625px;
`;

const Settings = ({ tabs }) => {
  const { t } = useTranslation();

  return (
    <>
      <ContentHeader
        topTitle={t("8516") /* Este lugar é teu! */}
        bottomTitle={t("8517") /* Configura-o à tua maneira. */}
      />
      <ThemeContainer>
        <Theme className="mt-50" />
        <VerticalTabs tabs={tabs} className="mt-50" />
      </ThemeContainer>
    </>
  );
};

Settings.propTypes = settingsPropTypes;

export default Settings;
