import { useState, useEffect, useRef } from "react";
import { getAll as getAllCountries } from "requests/countries";

const useCountries = ({ entityId } = {}) => {
  const [countryOptions, setCountryOptions] = useState([]);
  const countryCodesMapping = useRef({});

  useEffect(() => {
    (async () => {
      const newCountries = await getAllCountries(entityId, { noLimit: true });

      if (!newCountries) {
        return;
      }

      newCountries.forEach((country) => {
        countryCodesMapping.current[country.id] = country.isoCode;
      });

      setCountryOptions(
        newCountries.map((newCountry) => ({
          label: newCountry.name,
          value: newCountry.id,
        }))
      );
    })();
  }, []);

  return { countryOptions, countryCodesMapping: countryCodesMapping.current };
};

export default useCountries;
