import React, { useState } from "react";
import GeneralATMPaymentBox from "domain/private/components/paymentMethods/ATMPaymentBox";
import { useAuth } from "components/AuthProvider";
import { generateBalancePayment } from "../controller";
import { paymentBoxPropTypes } from "./propTypes";

const ATMPaymentBox = ({ initialFlowCallback, paymentMethod }) => {
  const { defaultEntityId, authHash } = useAuth();
  const [atmPaymentDetails, setAtmPaymentDetails] = useState();

  const onClick = () => {
    const promise = new Promise((resolve) => {
      (async () => {
        const amount = await initialFlowCallback(paymentMethod);

        if (!amount) {
          resolve(false);
          return;
        }

        resolve(true);

        setAtmPaymentDetails({
          isLoading: true,
        });

        const details = await generateBalancePayment(defaultEntityId, authHash, {
          amount,
          paymentMethodId: paymentMethod.id,
        });

        if (!details) {
          setAtmPaymentDetails({
            isLoading: false,
          });
          return;
        }

        setAtmPaymentDetails({
          amount: details.amount,
          bankEntity: details.bankEntityNumber,
          reference: details.reference,
          deadline: details.deadline,
          isLoading: false,
        });
      })();
    });

    return promise;
  };

  return (
    <GeneralATMPaymentBox
      longMessage={paymentMethod.longMessage}
      shortMessage={paymentMethod.shortMessage}
      {...atmPaymentDetails}
      onClick={onClick}
      title={paymentMethod.title}
    />
  );
};

ATMPaymentBox.propTypes = paymentBoxPropTypes;

export default ATMPaymentBox;
