import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Loader from "./Loader";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
`;

const PageLoader = (props) => {
  const { isLoading } = props;

  return (
    <>
      {isLoading && (
        <Container>
          <Loader isLoading={isLoading} />
        </Container>
      )}
    </>
  );
};

PageLoader.defaultProps = {
  isLoading: false,
};

PageLoader.propTypes = {
  isLoading: PropTypes.bool,
};

export default PageLoader;
