import Skeleton from "components/Skeleton";
import React from "react";
import styled from "styled-components";
import { BUTTON_MIN_WIDTH, SPACE_BETWEEN_BUTTONS } from "./Button";

const OperationsSkeleton = () => {
  return (
    <Container>
      <Skeleton className="skeleton-buttons" width={BUTTON_MIN_WIDTH} height={30} />
      <Skeleton className="skeleton-buttons" width={BUTTON_MIN_WIDTH} height={30} />
    </Container>
  );
};

export default OperationsSkeleton;

const Container = styled.div`
  display: flex;

  .skeleton-buttons {
    :not(:last-of-type) {
      margin-right: ${SPACE_BETWEEN_BUTTONS}px;
    }
  }
`;
