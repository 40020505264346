import Button from "components/buttons/Button";
import CancelButton from "components/buttons/CancelButton";
import { ValidationSchemaProvider } from "components/ValidationSchemaProvider";
import { CONTENT_CONTAINER_HORIZONTAL_SPACING } from "domain/private/components/ContentBorderBox";
import { Form, Formik } from "formik";
import useCountries from "hooks/useCountries";
import { errorToast, Text } from "iparque-components";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as CarIcon } from "../../../../assets/icons/car.svg";
import { AppContext } from "../../../../components/AppProvider";
import { useAuth } from "../../../../components/AuthProvider";
import DatePickerField from "../../../../components/formFields/DatePicker";
import SelectField from "../../../../components/formFields/Select";
import TextField from "../../../../components/formFields/Text";
import DriversDataSource from "../../../../lib/clients/iParque/dataSources/driversDataSource";
import VehiclesDataSource from "../../../../lib/clients/iParque/dataSources/vehiclesDataSource";
import { breakpoints } from "../../../../utils/breakpoints";
import { removeCache } from "../../../../utils/cache";
import { colorsMapping, getColorsArray } from "../../../../utils/colors";
import { formatDate, formatToServerDateTime } from "../../../../utils/dateTime";
import {
  hasErrorCode,
  hasUnauthorizedError,
  VEHICLE_ALREADY_EXISTS,
} from "../../../../utils/error";
import { routesMapping } from "../../../../utils/routes";
import {
  errorMessage,
  successMessage,
  warningMessage,
} from "../../../../utils/userMessages";
import Yup from "../../../../yup";
import { useBackofficeContext } from "../../components/BackOfficeProvider";
import ColorPicker from "../../components/SimpleColorPicker";
import { useBackofficeThemeContext } from "../../components/ThemeProvider";
import { vehicleDefaultProps, vehicleProps } from "./propTypes";

const Container = styled.div`
  display: flex;
  width: fill-available;

  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;

    #vehicle-form-icon {
      justify-content: center;
      align-items: center;
    }
  }
`;

const CarIconWrapper = styled.div`
  @media (max-width: ${breakpoints.lg}) {
    margin-top: 40px;
    text-align: center;
  }
`;

const VehicleDataContainer = styled.div`
  width: fill-available;
  margin-top: 20px;
  margin-left: 40px;

  @media (max-width: ${breakpoints.lg}) {
    margin-left: 0;
  }
`;

const GridContainer = styled.div`
  display: grid;
  max-width: 900px;
  width: auto;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 20px;
`;

const Line = styled.div`
  width: fill-available;
  margin: 4px 0;
  grid-column-end: span 6;
`;

const ColorPickerContainer = styled.div`
  @media (max-width: ${breakpoints.lg}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
  max-width: 100%;

  button {
    width: 150px;
  }

  @media (max-width: ${breakpoints.lg}) {
    button {
      width: 250px;
    }
  }
`;

const HeaderButtonsContainer = styled(ButtonsContainer)`
  position: absolute;
  top: 10px;
  right: ${CONTENT_CONTAINER_HORIZONTAL_SPACING}px;

  @media (max-width: ${breakpoints.lg}) {
    margin-top: 80px;
    position: initial;
    align-self: center;
  }
`;

const BottomContainer = styled(ButtonsContainer)`
  margin-top: 80px;
  justify-content: flex-end;

  @media (max-width: ${breakpoints.lg}) {
    position: initial;
    justify-content: center;
  }
`;

const vehiclesDataSource = new VehiclesDataSource();
const driverDataSource = new DriversDataSource();

const VehicleForm = ({
  vehicle: {
    modelId,
    countryId,
    brandId,
    licensePlate,
    purchasedDate,
    sellingDate,
    id: vehicleId,
    colorId,
  },
}) => {
  const { t } = useTranslation();
  const { user, driverHash } = useAuth();
  const history = useHistory();
  const { backofficeTheme } = useBackofficeThemeContext();
  const { setIsLoading } = useContext(AppContext);
  const { displayConfirmation } = useBackofficeContext();
  const { countryOptions, countryCodesMapping } = useCountries();
  const formRef = useRef();
  const [editing, setEditing] = useState(!vehicleId);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedColor, setSelectedColor] = useState({
    id: colorId,
    color: backofficeTheme.carColor[colorId],
  });

  const onColorChange = useCallback((selectedColorId) => {
    if (!selectedColorId) {
      return;
    }

    setSelectedColor({
      id: selectedColorId,
      color: backofficeTheme.carColor[selectedColorId],
    });
  }, []);

  const getBrands = useCallback(() => {
    vehiclesDataSource
      .getBrands({ noLimit: true })
      .then((response) =>
        setBrands(
          response.items.map((brand) => {
            return { value: brand.id, label: brand.name };
          })
        )
      )
      .catch((error) => {
        if (hasUnauthorizedError(error)) {
          return;
        }

        errorToast(
          t("3613") /* Erro */,
          t("7963") /* Ocorreu um erro ao obter as marcas dos veículos */
        );
      });
  }, []);

  const getModels = useCallback((selectedBrandId) => {
    vehiclesDataSource
      .getBrandModels(selectedBrandId, { noLimit: true })
      .then((response) =>
        setModels(
          response.items.map((model) => {
            return { value: model.id, label: model.name };
          })
        )
      )
      .catch((error) => {
        if (hasUnauthorizedError(error)) {
          return;
        }

        errorToast(
          t("3613") /* Erro */,
          t("7964") /* Ocorreu um erro ao obter os modelos da marca selecionada */
        );
      });
  }, []);

  const saveVehicle = useCallback(
    async (vehicleToEditId, vehicleValues) => {
      setIsLoading(true);

      const purchasedDateFormatted =
        vehicleValues.purchasedDate &&
        formatToServerDateTime(vehicleValues.purchasedDate);

      const sellingDateFormatted =
        vehicleValues.sellingDate && formatToServerDateTime(vehicleValues.sellingDate);

      const vehicleData = {
        ...vehicleValues,
        sellingDate: sellingDateFormatted,
        purchasedDate: purchasedDateFormatted,
      };

      try {
        // eslint-disable-next-line no-unused-expressions
        vehicleToEditId
          ? await driverDataSource.editVehicle(driverHash, vehicleToEditId, vehicleData)
          : await driverDataSource.createVehicle(driverHash, vehicleData);

        successMessage(
          vehicleToEditId
            ? t("5402") /* Veículo editado com sucesso */
            : t("7972") /* Veículo guardado com sucesso */
        );

        removeCache("/vehicles");
        history.push(routesMapping.backofficeVehicles);
      } catch (error) {
        if (hasErrorCode(error, VEHICLE_ALREADY_EXISTS)) {
          warningMessage(t("9705") /* Já tens um veículo com esta matrícula */);
          return;
        }

        errorMessage(
          error,
          vehicleToEditId
            ? t("7974") /* Ocorreu um erro ao editar o veículo */
            : t("7973") /* Ocorreu um erro ao guardar o veículo */
        );
      } finally {
        setIsLoading(false);
      }
    },
    [t, history]
  );

  const deleteVehicleRequest = useCallback((vehicleToDeleteId) => {
    setIsLoading(true);

    driverDataSource
      .deleteVehicle(driverHash, vehicleToDeleteId)
      .then(() => {
        successMessage(t("7975") /* Veículo eliminado com sucesso */);

        removeCache("/vehicles");
        history.push(routesMapping.backofficeVehicles);
      })
      .catch((error) => {
        errorMessage(error, t("7976") /* Ocorreu um erro ao eliminar o veículo */);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const deleteVehicle = (vehicleToDeleteId) => {
    displayConfirmation({
      title: t("920") /* Eliminar Veículo */,
      message: t("8184") /* Deseja eliminar este veículo? */,
      onConfirm: () => deleteVehicleRequest(vehicleToDeleteId),
    });
  };

  useEffect(() => {
    getBrands();
  }, []);

  useEffect(() => {
    setSelectedColor({
      id: colorId,
      color: backofficeTheme.carColor[colorId],
    });
  }, [colorId]);

  useEffect(() => {
    setModels([]);

    if (selectedBrand) {
      getModels(selectedBrand);
      return;
    }

    if (brandId) {
      getModels(brandId);
    }
  }, [selectedBrand, brandId]);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        countryId: Yup.number().required(t("10271") /* O campo é obrigatório */),
        licensePlate: Yup.string()
          .required(t("10271") /* O campo é obrigatório */)
          .licencePlate(
            t("9977") /* O campo é inválido para o país selecionado */,
            countryCodesMapping
          ),
        brandId: Yup.number().nullable(),
        modelId: Yup.number().when("brandId", {
          is: (brandIdValue) => brandIdValue,
          then: Yup.number().nullable().required(t("10271") /* O campo é obrigatório */),
          otherwise: Yup.number().nullable(),
        }),
        purchasedDate: Yup.string()
          .validDateFormat(
            `${t("9710") /* O campo tem de estar no formato */} ${formatDate(new Date())}`
          )
          .maxDate(
            t("9711") /* Deverá ser inferior ou igual à data atual */,
            formatDate(new Date())
          ),
        sellingDate: Yup.string()
          .validDateFormat(
            `${t("9710") /* O campo tem de estar no formato */} ${formatDate(new Date())}`
          )
          .maxDate(
            t("9711") /* Deverá ser inferior ou igual à data atual */,
            formatDate(new Date())
          )
          .minDate(
            t("7979") /* Deverá ser superior à data de compra */,
            Yup.ref("purchasedDate")
          ),
      }),
    []
  );

  const initialValues = {
    countryId: countryId || user.driver.countryId || null,
    licensePlate,
    brandId,
    modelId,
    purchasedDate: purchasedDate ? formatDate(purchasedDate) : "",
    sellingDate: sellingDate ? formatDate(sellingDate) : "",
  };

  const onCancelEditing = () => {
    if (!vehicleId) {
      history.goBack();
      return;
    }

    formRef.current?.setValues(initialValues);
    setEditing(false);
  };

  return (
    <Container>
      <CarIconWrapper>
        <CarIcon fill={selectedColor.color} />
      </CarIconWrapper>
      <VehicleDataContainer>
        <ColorPickerContainer>
          <Text variant="body7">{t("7967") /* Qual a cor do teu veículo? */}</Text>
          <ColorPicker
            className="mt-10"
            selectedColorId={selectedColor.id}
            colorIds={getColorsArray(colorsMapping)}
            onChange={!editing ? () => {} : onColorChange}
          />
        </ColorPickerContainer>
        <div className="mt-60">
          <ValidationSchemaProvider schema={validationSchema}>
            <Formik
              innerRef={formRef}
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                saveVehicle(vehicleId, { ...values, colorId: selectedColor.id });
              }}
            >
              {({ handleChange, handleSubmit, setFieldValue }) => (
                <>
                  <Form>
                    <GridContainer>
                      <Line>
                        <SelectField
                          id="country-field"
                          options={countryOptions}
                          name="countryId"
                          label={t("2721") /* País */}
                          disabled={!!vehicleId}
                          readOnly={!editing}
                        />
                      </Line>
                      <Line>
                        <TextField
                          id="name-field"
                          name="licensePlate"
                          label={t("7254") /* Matrícula */}
                          disabled={!!vehicleId}
                          normalize={(value) => value?.toUpperCase()}
                          readOnly={!editing}
                        />
                      </Line>
                      <Line>
                        <SelectField
                          id="brand-field"
                          options={brands}
                          name="brandId"
                          label={t("8306") /* Marca */}
                          onChange={(event) => {
                            handleChange(event);
                            setSelectedBrand(event.target.value);
                            setFieldValue("modelId", undefined);
                          }}
                          readOnly={!editing}
                        />
                      </Line>
                      <Line>
                        <SelectField
                          id="model-field"
                          options={models}
                          name="modelId"
                          label={t("4548") /* Modelo */}
                          readOnly={!editing}
                        />
                      </Line>
                      <Line>
                        <DatePickerField
                          id="purchase-date-field"
                          name="purchasedDate"
                          label={t("1863") /* Data de Compra */}
                          readOnly={!editing}
                        />
                      </Line>
                      <Line>
                        <DatePickerField
                          id="selling-date-field"
                          name="sellingDate"
                          label={t("6004") /* Data de Venda */}
                          readOnly={!editing}
                        />
                      </Line>
                    </GridContainer>
                    {editing && (
                      <BottomContainer>
                        <CancelButton onClick={onCancelEditing}>
                          {t("48") /* Cancelar */}
                        </CancelButton>
                        <Button type="submit" onClick={handleSubmit}>
                          {t("88") /* Guardar */}
                        </Button>
                      </BottomContainer>
                    )}
                  </Form>
                </>
              )}
            </Formik>
          </ValidationSchemaProvider>
        </div>
      </VehicleDataContainer>
      {vehicleId && !editing && (
        <HeaderButtonsContainer>
          <Button type="button" color="danger" onClick={() => deleteVehicle(vehicleId)}>
            {t("717") /* Eliminar */}
          </Button>
          <Button type="button" color="secondary" onClick={() => setEditing(true)}>
            {t("3375") /* Editar */}
          </Button>
        </HeaderButtonsContainer>
      )}
    </Container>
  );
};

VehicleForm.defaultProps = {
  vehicle: vehicleDefaultProps,
};

VehicleForm.propTypes = {
  vehicle: PropTypes.shape(vehicleProps),
};

export default VehicleForm;
