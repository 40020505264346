import { Input } from "iparque-components";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import useClickOutside from "../../../hooks/useClickOutside";

const PRIMARY = "primary";
const SECONDARY = "secondary";

const InputBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: ${(props) => (props.isOpen ? `100%` : `fit-content`)};
  cursor: ${(props) => (props.isOpen ? `initial` : `pointer`)};

  svg {
    position: absolute;
    margin-left: 12px;
    width: 20px;
    height: 20px;
    transition: transform 1s forwards;
    fill: ${(props) => props.theme.color.darker};

    ${(props) => props.isOpen && `display: none`}
  }

  :hover {
    svg {
      transform: rotate(95deg);
    }
  }

  input {
    cursor: ${(props) => (props.isOpen ? `initial` : `pointer`)};
    transition: all 0.3s ease;

    ::placeholder {
      ${(props) => !props.isOpen && `color: transparent;`}
    }

    ${(props) =>
      props.isOpen
        ? `
        width: 100%;`
        : `
        min-width: 0;
        width: 10px;
    `}
  }
`;

const SearchInput = ({ id, name, value, placeholder, onChange, color, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  const { t } = useTranslation();

  useClickOutside(inputRef, () => setIsOpen(false));

  return (
    <InputBox
      className={className}
      ref={inputRef}
      isOpen={isOpen}
      onClick={() => setIsOpen(true)}
    >
      <Search
        role="img"
        title={t("10655") /* Icon pesquisa */}
        aria-labelledby={t("10655") /* Icon pesquisa */}
      />
      <Input
        id={id}
        value={isOpen ? value : ""}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        color={color}
        type="text"
      />
    </InputBox>
  );
};

SearchInput.defaultProps = {
  id: "",
  value: "",
  placeholder: "",
  color: PRIMARY,
  className: null,
};

SearchInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  value: PropTypes.node,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  color: PropTypes.oneOf([PRIMARY, SECONDARY]),
  className: PropTypes.string,
};

export default SearchInput;
