import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const IconButton = React.forwardRef(({ Icon, onClick, className, color }, ref) => {
  return (
    <Container ref={ref} className={className} color={color} onClick={onClick}>
      {React.cloneElement(Icon, { className: "icon-button-icon", width: 25 })}
    </Container>
  );
});

export default IconButton;

IconButton.propTypes = {
  Icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "quaternary",
    "quintenary",
    "disabled",
    "danger",
    "warning",
    "info",
  ]),
};

IconButton.defaultProps = {
  className: "",
  color: "primary",
};

const Container = styled.div`
  background-color: ${({ theme, color }) => theme.button.background.color[color]};
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon-button-icon {
    fill: ${({ theme, color }) => theme.button.text.color[color]};
  }
`;
