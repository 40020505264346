import Button from "components/buttons/Button";
import CancelButton from "components/buttons/CancelButton";
import { ValidationSchemaProvider } from "components/ValidationSchemaProvider";
import { Form as FormikForm, Formik } from "formik";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import * as Yup from "yup";
import SelectField from "../../../components/formFields/Select";
import TextField from "../../../components/formFields/Text";
import { breakpoints } from "../../../utils/breakpoints";
import { userFormDefaultProps, userFormPropTypes } from "./propTypes";
import { fieldsMapping, FORM_BUTTON_WIDTH } from "./utils";

const commonStyle = css`
  margin-top: ${({ edit }) => (edit ? "25px" : "5px")};
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 20px;

  @media (max-width: ${breakpoints.md}) {
    margin-top: 40px;
  }
`;

const FullWidthContainer = styled.div`
  grid-column-end: span 12;
  ${commonStyle}
`;

const HalfWidthContainer = styled.div`
  grid-column-end: span 6;
  ${commonStyle}

  ${({ $wrap }) =>
    $wrap
      ? css`
          @media (max-width: 1200px) and (min-width: ${breakpoints.md}),
            (max-width: 650px) {
            grid-column-end: span 12;
          }
        `
      : ""}
`;

const ButtonContainer = styled.div`
  margin-top: 60px;
  grid-column-end: span 12;
  text-align: end;

  > button {
    width: ${FORM_BUTTON_WIDTH};
  }

  @media (max-width: ${breakpoints.md}) {
    text-align: center;
  }
`;

const UserForm = ({
  user,
  countries,
  countryCodesMapping,
  edit,
  saveUserCallback,
  extraRequiredFields,
  onCancel,
}) => {
  const { t } = useTranslation();
  const formRef = useRef();

  useEffect(() => {
    if (extraRequiredFields.length && formRef.current) {
      formRef.current?.validateForm(); // Force form validation
    }
  }, [extraRequiredFields]);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required(t("10271") /* O campo é obrigatório */),
        [fieldsMapping.vat]: Yup.string()
          .extraRequired(t("10271") /* O campo é obrigatório */, extraRequiredFields)
          .vat(
            t("9977") /* O campo é inválido para o país selecionado */,
            countryCodesMapping
          ),
        email: Yup.string()
          .required(t("10271") /* O campo é obrigatório */)
          .email(t("7894") /* O campo deverá ser válido */),
        countryId: Yup.string().required(t("10271") /* O campo é obrigatório */),
        [fieldsMapping.phone]: Yup.number()
          .extraRequired(t("10271") /* O campo é obrigatório */, extraRequiredFields)
          .phone(t("7894") /* O campo deverá ser válido */, Yup.ref("countryId")),
      }),
    []
  );

  const initialValues = useMemo(
    () => ({
      name: user.name,
      email: user.email,
      [fieldsMapping.vat]: user.vat || "",
      [fieldsMapping.phone]: user.phone || "",
      address: user.address || "",
      location: user.location || "",
      zipCode: user.zipCode || "",
      countryId: user.countryId || "",
    }),
    [user]
  );

  const onCancelHandler = () => {
    formRef.current?.setValues(initialValues);
    onCancel();
  };

  return (
    <ValidationSchemaProvider schema={validationSchema}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveUserCallback(values);
        }}
      >
        {({ values }) => {
          return (
            <FormikForm>
              <GridContainer>
                <FullWidthContainer>
                  <TextField
                    id="name-field"
                    name="name"
                    label={t("4518") /* Nome */}
                    readOnly={!edit}
                  />
                </FullWidthContainer>
                <HalfWidthContainer $wrap>
                  <TextField
                    type="email"
                    id="email-field"
                    name="email"
                    label={t("178") /* Email */}
                    readOnly={!edit}
                  />
                </HalfWidthContainer>
                <HalfWidthContainer $wrap>
                  <TextField
                    type="number"
                    id="phone-field"
                    name={fieldsMapping.phone}
                    label={t("398") /* Telemóvel */}
                    touched
                    readOnly={!edit}
                    displayNativeControls={false}
                  />
                </HalfWidthContainer>
                <HalfWidthContainer>
                  <SelectField
                    id="country-field"
                    name="countryId"
                    options={countries}
                    label={t("2721") /* País */}
                    readOnly={!edit}
                  />
                </HalfWidthContainer>
                <HalfWidthContainer>
                  <TextField
                    type="text"
                    id="vat-field"
                    name={fieldsMapping.vat}
                    label={t("4198") /* NIF */}
                    helperText={
                      !values.vat
                        ? t("9988") /* O NIF é necessário para a emissão de faturas */
                        : ""
                    }
                    readOnly={!edit}
                  />
                </HalfWidthContainer>
                <FullWidthContainer>
                  <TextField
                    type="text"
                    id="address-field"
                    name="address"
                    label={t("2022") /* Morada */}
                    readOnly={!edit}
                  />
                </FullWidthContainer>
                <HalfWidthContainer>
                  <TextField
                    type="text"
                    id="location-field"
                    name="location"
                    label={t("2539") /* Localização */}
                    readOnly={!edit}
                  />
                </HalfWidthContainer>
                <HalfWidthContainer>
                  <TextField
                    type="text"
                    id="zipCode-field"
                    name="zipCode"
                    label={t("2031") /* Código Postal */}
                    readOnly={!edit}
                  />
                </HalfWidthContainer>
                {edit && (
                  <ButtonContainer>
                    <CancelButton
                      onClick={onCancelHandler}
                      size="sm"
                      type="button"
                      className="mr-10"
                    >
                      {t("48") /* Cancelar */}
                    </CancelButton>
                    <Button size="sm" type="submit">
                      {t("88") /* Guardar */}
                    </Button>
                  </ButtonContainer>
                )}
              </GridContainer>
            </FormikForm>
          );
        }}
      </Formik>
    </ValidationSchemaProvider>
  );
};

UserForm.propTypes = userFormPropTypes;

UserForm.defaultProps = userFormDefaultProps;

export default UserForm;
