import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Text } from "iparque-components";
import Link from "../../../components/Link";

const RecaptchaInfo = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Text className="recaptcha-info" color="tertiary" variant="body8">
        {t("7872") /* Este site é protegido por reCAPTCHA e aplicam-se a */}
        &nbsp;
        <Link underline="always" href="https://policies.google.com/privacy">
          {t("6005") /* Política de Privacidade */}
        </Link>
        &nbsp;
        {t("10517") /* e os */}
        &nbsp;
        <Link underline="always" href="https://policies.google.com/terms">
          {t("7873") /* Termos de Serviço */}
        </Link>
        &nbsp;
        {t("10518") /* da Google. */}
      </Text>
    </div>
  );
};

export default RecaptchaInfo;

RecaptchaInfo.propTypes = {
  className: PropTypes.string,
};

RecaptchaInfo.defaultProps = {
  className: "mt-10",
};
