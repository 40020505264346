import React from "react";
import SubscriptionsList from "../../../components/subscriptions/smallDevices/List";
import TabIdentification from "../../../components/TabIdentification";
import TabTitle from "../../../components/Title";
import { tabsIdsMap } from "../../../helper";
import TabDetailsOperations from "../../operations/TabDetails";

const SubscriptionsTab = () => {
  return (
    <>
      <TabDetailsOperations className="mb-10" />
      <TabIdentification tabId={tabsIdsMap.subscriptions} />
      <TabTitle className="mt-20" tabId={tabsIdsMap.subscriptions} />
      <SubscriptionsList />
    </>
  );
};

export default SubscriptionsTab;
