import {
  breakpointsInPixels,
  breakpointsMapping as iParqueBreakpointsMapping,
  breakpoints as iParqueBreakpoints,
} from "iparque-components";

export const breakpoints = iParqueBreakpoints;

export const breakpointsMapping = iParqueBreakpointsMapping;

export const smallDevicesBreakpoints = [
  breakpointsMapping.xs,
  breakpointsMapping.sm,
  breakpointsMapping.md,
  breakpointsMapping.lg,
];

export const getCurrentBreakpoint = (widthSize) => {
  if (widthSize > breakpointsInPixels.xl) {
    return breakpointsMapping.xl;
  }

  if (widthSize > breakpointsInPixels.md && widthSize <= breakpointsInPixels.lg) {
    return breakpointsMapping.lg;
  }

  if (widthSize > breakpointsInPixels.sm && widthSize <= breakpointsInPixels.md) {
    return breakpointsMapping.md;
  }

  if (widthSize > breakpointsInPixels.xs && widthSize <= breakpointsInPixels.sm) {
    return breakpointsMapping.sm;
  }

  if (widthSize <= breakpointsInPixels.xs) {
    return breakpointsMapping.xs;
  }

  return breakpointsMapping.noBreakpoint; // other sizes have no breakpoint
};
