import PropTypes from "prop-types";
import React, { createContext, useContext } from "react";
import styled from "styled-components";
import { usePermitHoldersContext } from "../Provider";

const initialState = {};

const StepContext = createContext(initialState);

const Step = ({ children, step }) => {
  const { currentStep, getStepState, isInStack } = usePermitHoldersContext();

  if (!isInStack(step)) {
    return null;
  }

  return (
    <Container isVisible={step === currentStep?.index}>
      <StepContext.Provider value={getStepState(step) || {}}>
        {children}
      </StepContext.Provider>
    </Container>
  );
};

Step.propTypes = {
  children: PropTypes.node.isRequired,
  step: PropTypes.string.isRequired,
};

export const useStepContext = () => {
  return useContext(StepContext);
};

export default Step;

const Container = styled.div`
  display: ${({ isVisible }) => (isVisible ? `flex` : `none`)};
  flex: 1;
  flex-direction: column;
`;
