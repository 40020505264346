import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import Button from "components/buttons/Button";
import FlexBox from "components/FlexBox";
import SelectField from "components/formFields/Select";
import TextField from "components/formFields/Text";
import { ValidationSchemaProvider } from "components/ValidationSchemaProvider";
import { Form as FormikForm, Formik } from "formik";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { breakpoints } from "utils/breakpoints";
import FormGridContainer from "../FormGridContainer";
import useForm from "./useForm";

const VehicleForm = ({
  vehicle,
  position,
  editVehicle,
  makeEditable,
  removeVehicle,
  canRemove,
  className,
  readOnly,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { countries, brands, models, onBrandChange, validationSchema } = useForm();
  const isSaved = !!vehicle.licencePlate;

  useEffect(() => {
    if (vehicle.brandId) {
      onBrandChange(vehicle.brandId);
    }
  }, [vehicle.brandId]);

  return (
    <Container className={className} flagged={vehicle.flagged}>
      <ValidationSchemaProvider schema={validationSchema}>
        <Formik
          initialValues={{ ...vehicle, position }}
          onSubmit={(values) => editVehicle(position, values)}
          validationSchema={validationSchema}
        >
          {({ handleChange, setFieldValue, handleSubmit }) => (
            <FormikForm>
              <FlexBox className="fill">
                <FlexBox flexDirection="column" className="fill">
                  <Text variant="body2" color="quintenary">
                    {vehicle.regime.designation}
                  </Text>
                  <FormGridContainer className="mt-10">
                    <FieldContainer>
                      <SelectField
                        className="form-field"
                        name="countryId"
                        options={countries}
                        label={t("2721") /* País */}
                        readOnly={!vehicle.editable}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <TextField
                        className="form-field"
                        name="licencePlate"
                        label={t("7254") /* Matrícula */}
                        readOnly={!vehicle.editable}
                        normalize={(value) => value?.toUpperCase()}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <SelectField
                        className="form-field"
                        options={brands}
                        name="brandId"
                        label={t("8306") /* Marca */}
                        onChange={(event) => {
                          handleChange(event);
                          onBrandChange(event.target.value);
                          setFieldValue("modelId", undefined);
                        }}
                        readOnly={!vehicle.editable}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <SelectField
                        className="form-field"
                        options={models}
                        name="modelId"
                        label={t("4548") /* Modelo */}
                        disabled={models.length === 0}
                        readOnly={!vehicle.editable}
                      />
                    </FieldContainer>
                    <input type="hidden" name="position" />
                  </FormGridContainer>
                  {!readOnly && vehicle.editable && (
                    <ButtonsContainer className="mt-20">
                      {canRemove && (
                        <Button
                          onClick={() => removeVehicle(position)}
                          color="quintenary"
                          variant="outlined"
                          className="form-button"
                        >
                          {isSaved ? t("717") /* Eliminar */ : t("48") /* Cancelar */}
                        </Button>
                      )}
                      <Button
                        onClick={handleSubmit}
                        color="quintenary"
                        className=" form-button"
                      >
                        {t("88") /* Guardar */}
                      </Button>
                    </ButtonsContainer>
                  )}
                </FlexBox>
                {!readOnly && !vehicle.editable && (
                  <IconsContainer>
                    <IconsWrapper>
                      {canRemove && (
                        <TrashIcon
                          onClick={() => removeVehicle(position)}
                          fill={theme.card.dense.primary.iconColor}
                          className="form-icon"
                          title={t("717") /* Eliminar */}
                        />
                      )}
                      <EditIcon
                        fill={theme.card.dense.primary.iconColor}
                        onClick={() => makeEditable(position)}
                        className="form-icon"
                        title={t("3375") /* Editar */}
                      />
                    </IconsWrapper>
                  </IconsContainer>
                )}
              </FlexBox>
            </FormikForm>
          )}
        </Formik>
      </ValidationSchemaProvider>
    </Container>
  );
};

export default VehicleForm;

VehicleForm.propTypes = {
  vehicle: PropTypes.shape({
    countryId: PropTypes.number.isRequired,
    licencePlate: PropTypes.string,
    brandId: PropTypes.number,
    modelId: PropTypes.number,
    editable: PropTypes.bool.isRequired,
    flagged: PropTypes.bool.isRequired,
    regime: PropTypes.shape({
      designation: PropTypes.string.isRequired,
    }),
  }).isRequired,
  editVehicle: PropTypes.func,
  makeEditable: PropTypes.func,
  removeVehicle: PropTypes.func,
  canRemove: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
};

VehicleForm.defaultProps = {
  className: "",
  editVehicle: undefined,
  makeEditable: undefined,
  removeVehicle: undefined,
  readOnly: false,
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.card.dense.primary.backgroundColor};
  padding: 15px;
  border: 1px solid
    ${({ flagged, theme }) =>
      flagged ? theme.color.danger : theme.card.dense.primary.backgroundColor};

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  .form-field {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 30px ${({ theme }) => theme.card.dense.primary.backgroundColor}
        inset;
    }
  }
`;

const FieldContainer = styled.div`
  width: fill-available;
  grid-column-end: span 6;

  @media (min-width: ${breakpoints.xl}), (max-width: ${breakpoints.lg}) {
    grid-column-end: span 3;
  }

  @media (max-width: ${breakpoints.md}) {
    grid-column-end: span 6;
  }

  @media (max-width: 370px) {
    grid-column-end: span 12;
  }
`;

const IconsContainer = styled.div`
  margin-left: 15px;
  min-width: 70px;
  justify-content: space-between;

  @media (min-width: ${breakpoints.xl}), (max-width: ${breakpoints.lg}) {
    display: flex;
    align-items: center;
  }

  @media (max-width: ${breakpoints.md}) {
    align-items: flex-start;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  .form-icon {
    margin-left: auto;
    width: 25px;
    cursor: pointer;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }

  .form-button {
    &:not(:first-of-type) {
      margin-left: 30px;
      margin-top: 0;

      @media (max-width: ${breakpoints.sm}) {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }
`;
