import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import {
  pendingOperationKeys,
  usePendingOperationsContext,
} from "components/pendingOperations/Provider";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { associatePermitHolderDriver } from "requests/permitHolders";
import { breakpointsMapping, smallDevicesBreakpoints } from "utils/breakpoints";
import { successMessage } from "utils/userMessages";
import useRequirements from "./hooks/useRequirements";
import PermitHoldersListLargeDevicesPage from "./large/Page";
import ListHookWrapper from "./ListHookWrapper";
import PermitHoldersListMediumDevicesPage from "./medium/Page";
import PermitHoldersListSmallDevicesPage from "./small/Page";

const PermitHoldersListPage = () => {
  const { breakpoint } = useAppContext();
  const { state } = useLocation();
  const {
    ongoingPendingOperation,
    completePendingOperation,
  } = usePendingOperationsContext();
  const { defaultEntityId: entityId, driverHash } = useAuth();
  const { t } = useTranslation();
  useRequirements();

  useEffect(() => {
    if (
      !state?.token ||
      ongoingPendingOperation !== pendingOperationKeys.associatePermitHolder
    ) {
      return;
    }

    (async () => {
      const result = await associatePermitHolderDriver(
        entityId,
        driverHash,
        state.token,
        {
          errorMessage: false,
          autoCloseError: false,
          t,
        }
      );

      completePendingOperation();

      if (result) {
        successMessage(
          t("10550") /* O dístico foi associado à tua conta com sucesso */,
          null,
          { autoClose: false }
        );
      }
    })();
  }, []);

  const ComponentBasedOnDevice = (() => {
    if (smallDevicesBreakpoints.includes(breakpoint)) {
      return <PermitHoldersListSmallDevicesPage />;
    }

    if (breakpointsMapping.noBreakpoint === breakpoint) {
      return <PermitHoldersListMediumDevicesPage />;
    }

    return <PermitHoldersListLargeDevicesPage />;
  })();

  return (
    <>
      {!ongoingPendingOperation && <ListHookWrapper />}
      {ComponentBasedOnDevice}
    </>
  );
};

export default PermitHoldersListPage;
