import { errorToast } from "iparque-components";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { isFileExtensionAllowed } from "utils/file";
import Button from "./buttons/Button";

const InputFileButton = React.forwardRef(
  (
    { name, id, onChange, allowedFormats, buttonColor, buttonVariant, className },
    ref
  ) => {
    const { t } = useTranslation();
    const inputRef = useRef();

    const onButtonClick = (event) => {
      event.stopPropagation();
      inputRef.current.click();
    };

    const onLocalChange = (event) => {
      const file = event.target.files[0];

      if (
        !isFileExtensionAllowed(file.name, {
          allowedFormats,
        })
      ) {
        const formats = [...allowedFormats];
        const lastFormat = formats.pop();

        errorToast(
          t("3613") /* Erro */,
          t("10251", {
            extensions: formats.join(" "),
            extension: lastFormat,
          }) /* Apenas são permitidos ficheiros {{extensions}} e {{extension}} */
        );
        inputRef.current.value = "";
        return;
      }

      onChange(file);
    };

    return (
      <>
        <LoadButton
          ref={ref}
          color={buttonColor}
          onClick={onButtonClick}
          variant={buttonVariant}
          className={className}
          type="button"
        >
          {t("5748") /* Carregar */}
        </LoadButton>
        <HiddenFileInput
          ref={inputRef}
          name={name}
          accept={allowedFormats.map((extension) => `.${extension}`).toString()}
          type="file"
          onChange={onLocalChange}
          id={id || name}
        />
      </>
    );
  }
);

InputFileButton.propTypes = {
  allowedFormats: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonVariant: PropTypes.string,
  className: PropTypes.string,
};

InputFileButton.defaultProps = {
  allowedFormats: [],
  id: undefined,
  buttonColor: "quintenary",
  buttonVariant: "outlined",
  className: undefined,
};

export default InputFileButton;

const LoadButton = styled(Button)`
  border-width: 2px;
`;

const HiddenFileInput = styled.input`
  display: none;
`;
