import styled from "styled-components";
import React from "react";
import FlexBox from "components/FlexBox";
import PropTypes from "prop-types";

const FieldWrapper = ({ children }) => {
  return (
    <FlexBox size="sm">
      <FieldContainer>{children}</FieldContainer>
    </FlexBox>
  );
};

FieldWrapper.propTypes = {
  children: PropTypes.node,
};

FieldWrapper.defaultProps = {
  children: undefined,
};

export default FieldWrapper;

const FieldContainer = styled.div`
  width: 100%;
  min-width: 200px;
`;
