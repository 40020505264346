import FlexBox from "components/FlexBox";
import CardCell from "domain/private/components/cardList/Cell";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as FrontIcon } from "../../../../../../assets/icons/front.svg";
import ListCard from "../Card";

const Card = ({
  id,
  reference,
  typeName,
  city,
  stateName,
  stateId,
  approved,
  ...other
}) => {
  const { t } = useTranslation();

  return (
    <ListCard id={id} approved={approved} {...other}>
      <FlexBox flexDirection="column" className="fill">
        <FlexBox>
          <FlexBox>
            <Text variant="body2">{reference}</Text>
          </FlexBox>
          {!approved && (
            <StateText stateId={stateId} variant="body2">
              {stateName}
            </StateText>
          )}
        </FlexBox>
        <FlexBox className="mt-20">
          <CardCell size="sm">
            <Text variant="body4">{t("3659") /* Tipo */}</Text>
            <Text variant="body3">{typeName}</Text>
          </CardCell>
          <CardCell size="sm">
            <Text variant="body4">{t("2053") /* Cidade */}</Text>
            <Text variant="body3">{city}</Text>
          </CardCell>
        </FlexBox>
      </FlexBox>
      <Arrow />
    </ListCard>
  );
};

export default Card;

Card.propTypes = {
  id: PropTypes.number.isRequired,
  reference: PropTypes.string.isRequired,
  typeName: PropTypes.string,
  city: PropTypes.string.isRequired,
  stateName: PropTypes.string,
  stateId: PropTypes.number,
  approved: PropTypes.bool,
};

Card.defaultProps = {
  typeName: "-",
  approved: false,
  stateName: undefined,
  stateId: undefined,
};

const Arrow = styled(FrontIcon)`
  margin-left: auto;
  height: 25px;
  fill: ${({ theme }) => theme.icon.color};
`;

const StateText = styled(Text)`
  color: ${({ theme, stateId }) => theme.permitHolders.states[stateId]};
  margin-left: auto;
  margin-right: 20px;
`;
