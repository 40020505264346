import React, { useState, useEffect, useCallback, useRef } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Heading, Text, errorToast } from "iparque-components";
import SearchInput from "../components/SearchInput";
import HiddenLabel from "../../../components/HiddenLabel";
import Map from "./map/Map";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { breakpoints, breakpointsMapping } from "../../../utils/breakpoints";
import useDebounce from "../../../hooks/useDebounce";
import useOnScreen from "../../../hooks/useOnScreen";
import EntitiesDataSource from "../../../lib/clients/iParque/dataSources/entitiesDataSource";
import FullPageContainer from "../components/FullPageContainer";
import ContentContainer from "../components/ContentContainer";

const allowedBreakpointsToDisplayMap = [
  breakpointsMapping.xl,
  breakpointsMapping.lg,
  breakpointsMapping.md,
  breakpointsMapping.noBreakpoint,
];

const getCitiesFromEntities = (entities) => {
  return (
    Array.prototype.concat.apply(
      [],
      entities.map((entity) => entity.cities)
    ) || []
  );
};

const entitiesDataSource = new EntitiesDataSource();
const mapIntersectionOptions = { rootMargin: "400px" };
const animationIntersectionOptions = { threshold: 0.2, unobserveOnIntersection: true };

let cities = [];

const Cities = ({ breakpoint }) => {
  const [search, setSearch] = useState("");
  const [processedCities, setProcessedCities] = useState([]);
  const [hasResults, setHasResults] = useState(null);

  const { t } = useTranslation();

  const containerRef = useRef(null);
  const debounceSearch = useDebounce(search, 500);
  const isMapContainerOnScreen = useOnScreen(containerRef, mapIntersectionOptions);
  const isAnimationContainerOnScreen = useOnScreen(
    containerRef,
    animationIntersectionOptions
  );

  const renderMap = allowedBreakpointsToDisplayMap.includes(breakpoint);

  const processCities = useCallback(() => {
    const newCities = [];
    let cityExists = false;

    cities.forEach((city) => {
      if (
        city.name.toUpperCase().includes(debounceSearch?.toUpperCase()) ||
        debounceSearch === ""
      ) {
        newCities.push(city);

        if (city.name === debounceSearch) {
          cityExists = true;
        }
      }
    });

    if (search) {
      setHasResults((renderMap && newCities.length) || cityExists);
    } else {
      setHasResults(null);
    }

    setProcessedCities(newCities);
  }, [debounceSearch]);

  useEffect(() => {
    processCities();
  }, [debounceSearch]);

  useEffect(() => {
    entitiesDataSource
      .getAll({ cities: true, mobile: true })
      .then((response) => {
        cities = getCitiesFromEntities(response.items);
        setProcessedCities(cities);
      })
      .catch(() => {
        errorToast(
          t("3613") /* Erro */,
          t("7829") /* Ocorreu um erro ao obter as cidades. */
        );
      });
  }, []);

  return (
    <Container ref={containerRef}>
      {renderMap && isMapContainerOnScreen && <Map cities={processedCities} />}
      <InnerContainer shouldAnimate={isAnimationContainerOnScreen}>
        <BackgroundRectangle id="background-rectangle" />
        <ContentContainer>
          <InnerContentContainer>
            <div>
              <LeftSquare id="left-square" />
              <TextContainer id="top-text">
                <StyledHeading elementType="h2" variant="h1">
                  {t("7830") /* Vamos estacionar? */}
                </StyledHeading>
                <Heading elementType="h3" variant="h1" color="quaternary">
                  {t("7831") /* É fácil! */}
                </Heading>
                <Text variant="body3">{t("7832") /* Nós temos um lugar para ti. */}</Text>
                <InputContainer>
                  <HiddenLabel htmlFor="city-search-id">
                    {t("2053") /* Cidade */}
                  </HiddenLabel>
                  <StyledSearchInput
                    id="city-search-id"
                    name="Search"
                    placeholder={t("2053") /* Cidade */}
                    onChange={(event) => {
                      setSearch(event.target.value);
                    }}
                    value={search}
                  />
                  {hasResults !== null &&
                    (hasResults ? (
                      <ResultsContainer>
                        <StyledCheckIcon />
                        <Text variant="body7">
                          {t("7833") /* Já podes estacionar, estamos na tua cidade */}
                        </Text>
                      </ResultsContainer>
                    ) : (
                      <ResultsContainer>
                        <StyledCloseIcon />
                        <Text variant="body7">
                          {t("7834") /* Ups! Ainda não estamos na tua cidade */}
                        </Text>
                      </ResultsContainer>
                    ))}
                </InputContainer>
              </TextContainer>
            </div>
          </InnerContentContainer>
        </ContentContainer>
      </InnerContainer>
    </Container>
  );
};

Cities.defaultProps = {
  breakpoint: null,
};

Cities.propTypes = {
  breakpoint: PropTypes.string,
};

const TOP_CONTENT_MIN_HEIGHT = "430px";

const getBackgroundRectangleAnimation = (targetWidth, delay) => css`
  @keyframes shrink {
    0% {
      width: 100%;
      height: 100vh;
    }

    100% {
      width: ${targetWidth};
      height: 45vh;
    }
  }

  animation: shrink 1s;
  animation-fill-mode: both;
  animation-delay: ${delay};
`;

const getAppearFromLeftAnimation = (delay) => css`
  @keyframes appearFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-50%);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  animation: appearFromLeft 1s;
  animation-fill-mode: both;
  animation-delay: ${delay};
`;

const Container = styled(FullPageContainer)`
  padding-top: 100px;

  @media (max-width: ${breakpoints.sm}) {
    background-color: ${(props) => props.theme.background.color.quaternary};
    min-height: 500px;
    padding-top: 40px;
  }
`;

const InnerContainer = styled.div`
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;

  @keyframes leftSquare {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
      max-height: 0;
      width: 0;
    }

    100% {
      opacity: 1;
      max-height: 280px;
      width: 90px;
    }
  }

  ${({ shouldAnimate }) =>
    shouldAnimate
      ? css`
          #background-rectangle {
            @media (min-width: ${breakpoints.sm}) {
              ${getBackgroundRectangleAnimation("60%", "0.5s")};
            }

            @media (min-width: ${breakpoints.md}) {
              ${getBackgroundRectangleAnimation("50%", "0.5s")};
            }

            @media (min-width: ${breakpoints.xl}) {
              ${getBackgroundRectangleAnimation("50%", "1.5s")};
            }
          }

          #left-square {
            @media (min-width: ${breakpoints.xl}) {
              animation: leftSquare 1s;
              animation-fill-mode: both;
              animation-delay: 0.5s;
            }
          }

          #top-text {
            @media (min-width: ${breakpoints.sm}) {
              ${getAppearFromLeftAnimation("0.5s")};
            }

            @media (min-width: ${breakpoints.xl}) {
              ${getAppearFromLeftAnimation("1.5s")};
            }
          }
        `
      : ""}
`;

const BackgroundRectangle = styled.div`
  background-color: ${({ theme }) => theme.background.color.primary};

  @media (min-width: ${breakpoints.sm}) {
    position: absolute;
    width: 100%;
    height: 100vh;
    min-height: ${TOP_CONTENT_MIN_HEIGHT};
  }
`;

const InnerContentContainer = styled.div`
  display: flex;
  align-items: center;
  pointer-events: auto;

  > div {
    display: flex;
  }

  @media (min-width: ${breakpoints.sm}) {
    height: 45vh;
    min-height: ${TOP_CONTENT_MIN_HEIGHT};
  }
`;

const LeftSquare = styled.div`
  width: 0;
  max-height: 0;
  opacity: 0;
  border: 40px solid ${({ theme }) => theme.color.tertiary};
  margin-right: 40px;

  @media (max-width: ${breakpoints.xl}) {
    display: none;
  }
`;

const TextContainer = styled.div`
  @media (min-width: ${breakpoints.sm}) {
    opacity: 0;
  }
`;

const StyledHeading = styled(Heading)`
  line-height: 1em;
`;

const InputContainer = styled.div`
  margin-top: 20px;
  width: 55%;
  max-width: 320px;

  @media (max-width: ${breakpoints.lg}) {
    width: 100%;
  }

  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
    max-width: none;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  input {
    border-color: ${(props) => props.theme.color.lightest};
    background-color: ${(props) => props.theme.color.lightest};
  }
`;

const ResultsContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 10px;

  svg {
    margin-right: 5px;
  }
`;

const StyledCheckIcon = styled(CheckIcon)`
  fill: ${(props) => props.theme.background.color.tertiary};
  height: 15px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  fill: ${(props) => props.theme.color.danger};
  height: 15px;
`;

export default Cities;
