import TaxNumberValidator from "@acin.jstools/tax-number-validator";

const taxNumberValidator = new TaxNumberValidator();

const taxNumberValidatorErrorCodes = {
  UNSUPPORTED_COUNTRY: "e01",
};

export const isTaxNumberValid = (taxNumber, countryCode) => {
  try {
    const isValid = taxNumberValidator.validate(taxNumber, countryCode, {
      exceptions: true,
    });

    return isValid;
  } catch (error) {
    return error.code === taxNumberValidatorErrorCodes.UNSUPPORTED_COUNTRY;
  }
};

export const isPhoneValid = (vat) => {
  const phoneRegex = /^\d{9}$/;

  return phoneRegex.test(vat);
};
