import React, { useCallback } from "react";
import { Text, Link } from "iparque-components";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { formatMoney } from "../../../../utils/money";
import PaymentMethodIcon from "../../components/paymentMethods/PaymentIcon";
import { getAmountColor } from "../controller";
import BalanceChargeDetails from "../BalanceChargeDetails";
import { movementCardDefaultProps, movementCardPropTypes } from "../propTypes";
import { useAppContext } from "../../../../components/AppProvider";
import { breakpoints, breakpointsMapping } from "../../../../utils/breakpoints";
import { convertDateTimeToReadableDate } from "../../../../utils/dateTime";
import ListCard from "../../components/cardList/Card";

const MovementCard = ({
  description,
  amount,
  dateTime,
  completed,
  balanceChargeId,
  paymentMethodId,
  licensePlate,
  invoice,
  vehiclesColors,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { breakpoint } = useAppContext();
  const hasInvoice = invoice.number && invoice.url;
  const hasBoxExtraInfo = hasInvoice || !completed || licensePlate;

  const smallVariant = [
    breakpointsMapping.lg,
    breakpointsMapping.md,
    breakpointsMapping.sm,
    breakpointsMapping.xs,
    breakpointsMapping.noBreakpoint,
  ].includes(breakpoint);

  const renderExtraInfoCallback = useCallback(
    ({ isOpen }) => (
      <>
        <AmountContainer />
        <ExtraInfoContainer>
          {licensePlate && (
            <div>
              <Label>{t("9570") /* Viatura */}</Label>
              <Value textColor={theme.carColor[vehiclesColors?.[licensePlate]]}>
                {licensePlate}
              </Value>
            </div>
          )}
          {balanceChargeId && !completed && isOpen ? (
            <BalanceChargeDetails small={smallVariant} id={balanceChargeId} />
          ) : null}
          {hasInvoice && (
            <InvoiceContainer>
              <div>
                <Label>{t("8402") /* Nº da fatura */}</Label>
                <Value>{invoice.number}</Value>
              </div>
              <Text variant="body9">
                <Link
                  underline="always"
                  target="_blank"
                  href={invoice.url}
                  rel="noopener"
                >
                  {t("7388") /* Ver factura  */}
                </Link>
              </Text>
            </InvoiceContainer>
          )}
        </ExtraInfoContainer>
      </>
    ),
    [balanceChargeId, completed, hasInvoice]
  );

  return (
    <StyledListCard
      renderExtraInfoCallback={hasBoxExtraInfo ? renderExtraInfoCallback : null}
      preserveArrowWidth
    >
      <AmountContainer>
        <Text variant="body5" color={getAmountColor(completed, amount)}>
          {amount > 0 ? `+${formatMoney(amount)}` : formatMoney(amount)}
        </Text>
      </AmountContainer>
      <div>
        <Text variant="body5" className="description">
          {description}
        </Text>
        <Text variant="body10">{convertDateTimeToReadableDate(dateTime)}</Text>
      </div>
      <IconContainer>
        <PaymentMethodIcon small={smallVariant} paymentMethodId={paymentMethodId} />
      </IconContainer>
    </StyledListCard>
  );
};

export default MovementCard;

MovementCard.propTypes = movementCardPropTypes;

MovementCard.defaultProps = movementCardDefaultProps;

const ExtraInfoContainer = styled.div`
  width: 100%;
`;

const AmountContainer = styled.div`
  min-width: 65px;

  @media (max-width: ${breakpoints.lg}) {
    margin-right: 30px;
  }

  @media (max-width: ${breakpoints.md}) {
    margin-right: 0;
  }
`;

const StyledListCard = styled(ListCard)`
  .description {
    line-height: 1.2;
    margin-bottom: 5px;
  }

  .list-card-extra-info-container {
    margin-top: 15px;
  }
`;

const IconContainer = styled.div`
  min-width: 45px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: 5px;

  @media (max-width: ${breakpoints.lg}) {
    margin-right: 25px;
  }

  @media (max-width: ${breakpoints.sm}) {
    margin-right: 5px;
  }
`;

const InvoiceContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const Label = styled(Text).attrs({ variant: "body10" })`
  margin-top: 5px;
`;

const Value = styled(Label)`
  font-weight: bold;
  ${({ textColor }) => (textColor ? `color: ${textColor};` : "")}
`;
