import Button from "components/buttons/Button";
import FlexBox from "components/FlexBox";
import { Heading } from "iparque-components";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../components/AppProvider";
import Link from "../../components/Link";
import DriversDataSource from "../../lib/clients/iParque/dataSources/driversDataSource";
import { DRIVER_ALREADY_ACTIVATED, hasErrorCode } from "../../utils/error";
import { getButtonSize } from "../../utils/general";
import { routesMapping } from "../../utils/routes";
import LeftSideContainer from "./components/PageSplittedTemplate/LeftSideContainer";
import PageSplittedTemplate from "./components/PageSplittedTemplate/Template";
import { pageContexts } from "./configs";

const driversDataSource = new DriversDataSource();

const activateAccount = (values, { setError, setIsLoading }) => {
  setIsLoading(true);

  driversDataSource
    .activateAccount(values)
    .then(() => setError(false))
    .catch((error) => {
      if (hasErrorCode(error, DRIVER_ALREADY_ACTIVATED)) {
        setError(DRIVER_ALREADY_ACTIVATED);
        return;
      }

      setError(true);
    })
    .finally(() => setIsLoading(false));
};

const URL_ERROR = "urlError";

const RegistryActivation = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { breakpoint, setIsLoading } = useContext(AppContext);
  const [userName, setUserName] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get("hash");
    const name = urlParams.get("name");

    if (!hash || !name) {
      setError(URL_ERROR);
      return;
    }

    setUserName(name);
    activateAccount({ activationHash: hash }, { setError, setIsLoading });
  }, []);

  const onPressLogin = () => {
    history.push(routesMapping.login);
  };

  const generateDisplayedInfo = () => {
    if (error === false) {
      return (
        <FlexBox flexDirection="column" className="fill">
          <Heading className="mt-30" color="secondary" variant="h7">
            {userName}, {t("7898") /* a tua conta foi ativada com sucesso! */}
          </Heading>
          <Heading color="secondary" variant="h8">
            {
              t(
                "7899"
              ) /* Efetua o teu login e começa já a usufruir de todas as funcionalidades iParque driver! */
            }
          </Heading>
          <Heading className="mt-20" color="secondary" variant="h7">
            {t("7838") /* Deixa-te de trocos! */}
          </Heading>
          <div className="mt-60">
            <Link to={routesMapping.login}>
              <Button fullWidth size={getButtonSize(breakpoint)} onClick={onPressLogin}>
                {t("7819") /* Login */}
              </Button>
            </Link>
          </div>
        </FlexBox>
      );
    }

    if (error === DRIVER_ALREADY_ACTIVATED) {
      return (
        <FlexBox flexDirection="column" className="fill">
          <Heading className="mt-30" color="secondary" variant="h7">
            {userName}, {t("7900") /* não foi possível ativar a tua conta! */}
          </Heading>
          <Heading color="secondary" variant="h8">
            {t("7901") /* A conta já se encontra ativada */}
          </Heading>
          <div className="mt-60">
            <Link to={routesMapping.login}>
              <Button fullWidth size={getButtonSize(breakpoint)} onClick={onPressLogin}>
                {t("7819") /* Login */}
              </Button>
            </Link>
          </div>
        </FlexBox>
      );
    }

    if (error === URL_ERROR) {
      return (
        <>
          <Heading className="mt-30" color="secondary" variant="h7">
            {t("7902") /* Não foi possível ativar a tua conta! */}
          </Heading>
          <Heading color="secondary" variant="h8">
            {t("7903") /* O URL introduzido encontra-se inválido */}
          </Heading>
        </>
      );
    }

    return (
      <>
        <Heading className="mt-30" color="secondary" variant="h7">
          {userName}, {t("7900") /* não foi possível ativar a tua conta! */}
        </Heading>
        <Heading color="secondary" variant="h8">
          {t("7904") /* Ocorreu um erro ao ativar a conta. */}
        </Heading>
      </>
    );
  };

  return (
    <PageSplittedTemplate
      pageContext={pageContexts.registryActivation}
      title={t("5570") /* Ativação de conta */}
      backUrl={routesMapping.registry}
    >
      <LeftSideContainer>{error !== null && generateDisplayedInfo()}</LeftSideContainer>
    </PageSplittedTemplate>
  );
};

export default RegistryActivation;
