import React, { useCallback, useEffect, useRef } from "react";
import PersonalDataForm from "../../components/PersonalData/Form";
import { useStepContext } from "../../components/Step";
import { usePermitHoldersContext } from "../../Provider";
import { actionTypes } from "../../store/actions";

const PersonalDataStep = () => {
  const formRef = useRef();
  const { dispatch } = usePermitHoldersContext();
  const { onAdvance } = useStepContext();

  useEffect(() => {
    return () => {
      // Save the current form values on back step
      dispatch({
        type: actionTypes.SET_PERSONAL_DATA,
        payload: { ...formRef.current.values, blockInfoAutoUpdate: true },
      });
    };
  }, []);

  const localOnAdvance = useCallback(
    (values) => {
      dispatch({
        type: actionTypes.SET_PERSONAL_DATA,
        payload: { ...values, blockInfoAutoUpdate: true },
      });

      onAdvance();
    },
    [onAdvance]
  );

  return <PersonalDataForm ref={formRef} onAdvance={localOnAdvance} />;
};

export default PersonalDataStep;
