import React, { useCallback, useMemo, useState } from "react";
import { useAppContext } from "components/AppProvider";
import { useTranslation } from "react-i18next";
import { paymentMethodsMapping } from "utils/payment";
import { breakpointsMapping } from "utils/breakpoints";
import FlexBox from "components/FlexBox";
import { useBackofficeContext } from "domain/private/components/BackOfficeProvider";
import LoadingPaymentBox from "domain/private/components/paymentMethods/LoadingPaymentBox";
import ErrorPaymentBox from "domain/private/components/paymentMethods/ErrorPaymentBox";
import { useAuth } from "components/AuthProvider";
import { convertFromCents } from "utils/money";
import { useBalanceChargeContext } from "./store/Store";
import ATMPaymentBox from "./components/ATMPaymentBox";
import BankTransferencePaymentBox from "./components/BankTransferencePaymentBox";
import CreditCardPaymentBox from "./components/CreditCardPaymentBox";
import MbWayPaymentBox from "./components/MbWayPaymentBox";
import AmountDefinitionPopup from "./components/AmountDefinitionPopup";
import PaymentMethodsList from "./PaymentMethodsList";

const PaymentMethods = () => {
  const [balanceDefinitionPopupData, setBalanceDefinitionPopupData] = useState({
    display: false,
  });
  const { balanceChargeMethods } = useBalanceChargeContext();
  const { displayConfirmation } = useBackofficeContext();
  const { defaultEntity } = useAuth();
  const { breakpoint } = useAppContext();
  const { t } = useTranslation();

  const getChosenAmount = useCallback((paymentMethod) => {
    const promise = new Promise((resolve) => {
      const onClose = () => {
        setBalanceDefinitionPopupData({
          display: false,
        });

        resolve(false);
      };

      setBalanceDefinitionPopupData({
        display: true,
        onClose,
        onConfirm: resolve,
        minAmount: convertFromCents(paymentMethod.minAmount),
      });
    });

    return promise;
  }, []);

  const isEntityConfirmed = useCallback(() => {
    const promise = new Promise((resolve) => {
      if (!defaultEntity?.name) {
        resolve(false);
      }

      displayConfirmation({
        title: t("9351") /* Confirmar carregamento de saldo */,
        message: (
          <>
            {
              t(
                "9668"
              ) /* Desejas realmente formalizar o pedido de carregamento de saldo na entidade */
            }
            &nbsp;
            <b>{defaultEntity.name}</b>?
          </>
        ),
        configs: {
          confirmationButtonLabel: t("5748") /* Carregar */,
        },
        onConfirm: () => resolve(true),
        onCancel: () => resolve(false),
      });
    });

    return promise;
  }, [defaultEntity]);

  const initialFlow = useCallback(
    (paymentMethod) => {
      const promise = new Promise((resolve) => {
        (async () => {
          const confirmationResult = await isEntityConfirmed();

          if (!confirmationResult) {
            resolve(false);
            return;
          }

          const amount = await getChosenAmount(paymentMethod);

          if (!amount) {
            resolve(false);
            return;
          }

          resolve(amount);
        })();
      });

      return promise;
    },
    [isEntityConfirmed, getChosenAmount]
  );

  const renderPaymentMethod = useCallback(
    (paymentMethod) => {
      if (!paymentMethod.isActive) {
        return null;
      }

      switch (paymentMethod.id) {
        case paymentMethodsMapping.atm.id:
          return (
            <ATMPaymentBox
              key="atm-payment"
              initialFlowCallback={initialFlow}
              paymentMethod={paymentMethod}
            />
          );

        case paymentMethodsMapping.bankTransference.id:
          return (
            <BankTransferencePaymentBox
              key="bank-transference-payment"
              initialFlowCallback={initialFlow}
              paymentMethod={paymentMethod}
            />
          );

        case paymentMethodsMapping.creditCard.id:
          return (
            <CreditCardPaymentBox
              key="credit-card-payment"
              initialFlowCallback={initialFlow}
              paymentMethod={paymentMethod}
            />
          );

        case paymentMethodsMapping.mbway.id:
          return (
            <MbWayPaymentBox
              key="mb-way-payment"
              initialFlowCallback={initialFlow}
              paymentMethod={paymentMethod}
            />
          );

        default:
          return null;
      }
    },
    [balanceChargeMethods]
  );

  const paymentMethods = useMemo(() => {
    const methods = [];

    balanceChargeMethods.methods.forEach((paymentMethod) => {
      const component = renderPaymentMethod(paymentMethod);

      if (component) {
        methods.push(component);
      }
    });

    return methods;
  }, [balanceChargeMethods]);

  if (balanceChargeMethods.areLoading) {
    return <LoadingPaymentBox />;
  }

  if (balanceChargeMethods.hasError || paymentMethods.length === 0) {
    return <ErrorPaymentBox />;
  }

  return (
    <>
      {!breakpoint || breakpoint === breakpointsMapping.xl ? (
        <PaymentMethodsList paymentMethods={paymentMethods} />
      ) : (
        <FlexBox flexDirection="column" gap={10}>
          {balanceChargeMethods.methods.map((paymentMethod) =>
            renderPaymentMethod(paymentMethod)
          )}
        </FlexBox>
      )}
      {balanceDefinitionPopupData.display && (
        <AmountDefinitionPopup
          onClose={balanceDefinitionPopupData.onClose}
          onConfirm={balanceDefinitionPopupData.onConfirm}
          minAmount={balanceDefinitionPopupData.minAmount}
        />
      )}
    </>
  );
};

export default PaymentMethods;
