import { useState } from "react";
import useIntersectionObserver from "./useIntersectionObserver";

const useOnScreen = (ref, options = {}) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useIntersectionObserver(ref, setIntersecting, options);

  return isIntersecting;
};

export default useOnScreen;
