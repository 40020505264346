/* eslint-disable import/prefer-default-export */
import i18n from "i18next";
import IdentificationDocumentsDataSource from "lib/clients/iParque/dataSources/identificationDocumentsDataSource";
import { errorMessage } from "../utils/userMessages";

const identificationDocumentsDataSource = new IdentificationDocumentsDataSource();

export const getAllPrefixes = async (
  entityId,
  documentTypeId,
  params,
  useCache = true
) => {
  try {
    const response = await identificationDocumentsDataSource.getAllPrefixes(
      entityId,
      documentTypeId,
      params,
      {},
      useCache
    );

    const prefixes = response?.items || [];

    return prefixes;
  } catch (error) {
    errorMessage(
      error,
      i18n.t("10729") /* Ocorreu um erro ao obter os prefixos da carta de condução. */
    );

    return null;
  }
};

export const getAllDrivingLicenceCategories = async (
  entityId,
  params,
  useCache = true
) => {
  try {
    const response = await identificationDocumentsDataSource.getAllDrivingLicenceCategories(
      entityId,
      params,
      {},
      useCache
    );

    const drivingLicenceCategories = response?.items || [];

    return drivingLicenceCategories;
  } catch (error) {
    errorMessage(
      error,
      i18n.t("10337") /* Ocorreu um erro ao obter as categorias da carta de condução. */
    );

    return null;
  }
};
