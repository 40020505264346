import { useAppContext } from "components/AppProvider";
import FlexBox from "components/FlexBox";
import Text from "components/Text";
import Button from "components/buttons/Button";
import CancelButton from "components/buttons/CancelButton";
import Popup from "domain/private/components/Popup";
import { useBackofficeThemeContext } from "domain/private/components/ThemeProvider";
import useChangeDefaultEntity from "hooks/useChangeDefaultEntity";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import { errorMessage } from "utils/userMessages";
import { ReactComponent as Arrow } from "../../../../assets/icons/bottom.svg";
import SuggestionCard from "./Card";

const SuggestedEntityPopup = ({ onClose, selectedEntity, suggestedEntity }) => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();
  const changeDefaultEntity = useChangeDefaultEntity();
  const { setIsLoading } = useAppContext();

  const handleOnSuccess = () => {
    setIsLoading(false);
    onClose();
  };

  const handleOnError = () => {
    setIsLoading(false);
    errorMessage(null, t("10972") /* Ocorreu um erro ao alterar a entidade */);
  };

  const handleChange = () => {
    setIsLoading(true);

    changeDefaultEntity(suggestedEntity.entityId, {
      onSuccess: handleOnSuccess,
      onError: handleOnError,
    });
  };

  return (
    <Popup
      onClose={onClose}
      title={t("10267") /* Estás na entidade correta? */}
      width={700}
    >
      <Text variant="body4" color="primary">
        {t("10268") /* Confirma se pretendes alterar para uma entidade mais próxima. */}
      </Text>
      <div className="mt-50">
        <SuggestionCard {...selectedEntity} />
        <StyledArrow fill={backofficeTheme.icon.selectedColor} />
        <SuggestionCard selected {...suggestedEntity} />
      </div>
      <ButtonsContainer justifyContent="flexEnd">
        <StyledCancelButton className="mr-10" onClick={onClose}>
          {t("48") /* Cancelar */}
        </StyledCancelButton>
        <StyledButton onClick={handleChange}>{t("1719") /* Alterar */}</StyledButton>
      </ButtonsContainer>
    </Popup>
  );
};

export default SuggestedEntityPopup;

SuggestedEntityPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedEntity: PropTypes.shape({
    entityId: PropTypes.number,
    name: PropTypes.string,
    entityLogo: PropTypes.string,
    distance: PropTypes.number,
    balance: PropTypes.number,
  }).isRequired,
  suggestedEntity: PropTypes.shape({
    entityId: PropTypes.number,
    name: PropTypes.string,
    entityLogo: PropTypes.string,
    distance: PropTypes.number,
    balance: PropTypes.number,
  }).isRequired,
};

SuggestedEntityPopup.defaultProps = {};

const ButtonsContainer = styled(FlexBox)`
  margin-top: 50px;

  @media (max-width: ${breakpoints.md}) {
    flex: 1;
  }
`;

const StyledCancelButton = styled(CancelButton)`
  @media (max-width: ${breakpoints.md}) {
    flex: 1;
  }
`;

const StyledButton = styled(Button)`
  @media (max-width: ${breakpoints.md}) {
    flex: 1;
  }
`;

const StyledArrow = styled(Arrow)`
  margin: 20px auto;
  display: block;
  color: red;
`;
