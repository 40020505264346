/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "./AuthProvider";
import { routesMapping } from "../utils/routes";
import Page403 from "../domain/errors/403/Page";

function PrivateRoute({ component: Component, allowed, ...other }) {
  const { isAuthenticated } = useAuth();

  const renderComponent = useCallback(
    (routerProps) => {
      if (!isAuthenticated) {
        return (
          <Redirect
            to={{
              pathname: routesMapping.login,
              state: { from: routerProps.location.pathname },
            }}
          />
        );
      }

      if (!allowed) {
        return <Page403 />;
      }

      return <Component {...routerProps} />;
    },
    [isAuthenticated, allowed]
  );

  return <Route {...other} render={renderComponent} />;
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  allowed: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  allowed: false,
};

export default PrivateRoute;
