import React from "react";
import PropTypes from "prop-types";
import ReactQRCode from "qrcode.react";

const QRCode = ({ width, height, value, backgroundColor, figureColor }) => {
  return (
    <ReactQRCode
      bgColor={backgroundColor}
      fgColor={figureColor}
      renderAs="svg"
      width={width}
      height={height}
      value={value}
    />
  );
};

QRCode.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  backgroundColor: PropTypes.string.isRequired,
  figureColor: PropTypes.string.isRequired,
};

QRCode.defaultProps = {
  width: null,
  height: null,
};

export default QRCode;
