import i18n from "i18next";
import DriversIdentificationsDataSource from "lib/clients/iParque/dataSources/driversIdentificationsDataSource";
import {
  hasErrorCode,
  DRIVER_ALREADY_IDENTIFIED,
  EXCEEDED_LIMIT_DATE_FOR_DRIVER_IDENTIFICATION,
  NOTICE_NOT_FOUND,
  DEFAULT_API_ERROR_CODE,
  INVALID_TOKEN,
  TOKEN_NOT_FOUND,
} from "utils/error";
import { errorMessage } from "utils/userMessages";

const driversIdentificationsDataSource = new DriversIdentificationsDataSource();

export const getIdentificationData = async (token, params, { t = i18n.t } = {}) => {
  try {
    const response = await driversIdentificationsDataSource.getIdentificationData(
      token,
      params,
      {},
      false
    );

    return response || [];
  } catch (error) {
    if (hasErrorCode(error, INVALID_TOKEN)) {
      return {
        error: t("10644") /* Token inválido. */,
      };
    }

    if (hasErrorCode(error, DRIVER_ALREADY_IDENTIFIED)) {
      return {
        error: t("10345") /* O condutor já foi identificado anteriormente. */,
      };
    }

    if (hasErrorCode(error, EXCEEDED_LIMIT_DATE_FOR_DRIVER_IDENTIFICATION)) {
      return {
        error: t(
          "10347"
        ) /* A data limite para a identificação do condutor foi excedida. */,
      };
    }

    return null;
  }
};

export const getIdentificationEvidence = async (token, params, { t = i18n.t } = {}) => {
  try {
    const response = await driversIdentificationsDataSource.getIdentificationEvidence(
      token,
      params,
      {},
      false
    );

    return response || [];
  } catch (error) {
    errorMessage(error, t("10348") /* Ocorreu um erro ao obter o comprovativo. */);

    return null;
  }
};

export const createIdentification = async (
  token,
  data,
  { autoCloseError = true, t = i18n.t } = {}
) => {
  try {
    await driversIdentificationsDataSource.createIdentification(token, data);

    return true;
  } catch (error) {
    errorMessage(
      error,
      t("10349") /* Ocorreu um erro ao identificar o condutor. */,
      null,
      {
        autoClose: autoCloseError,
      }
    );

    return null;
  }
};

export const getIdentificationToken = async (
  entityId,
  params,
  { autoCloseError = true, t = i18n.t } = {}
) => {
  try {
    const response = await driversIdentificationsDataSource.getIdentificationToken(
      entityId,
      params,
      {},
      false
    );

    return response || [];
  } catch (error) {
    if (
      hasErrorCode(error, NOTICE_NOT_FOUND) ||
      hasErrorCode(error, TOKEN_NOT_FOUND) ||
      hasErrorCode(error, DEFAULT_API_ERROR_CODE)
    ) {
      errorMessage(
        error,
        t("10558") /* O aviso introduzido não existe para a entidade selecionada */,
        null,
        {
          autoClose: autoCloseError,
        }
      );
    }

    return null;
  }
};
