import React, { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";
import { convertMinutesToSeconds, convertSecondsToReadableTime } from "utils/dateTime";
import { durationDefaultOptions } from "../../parking/controller";
import { infoMessage } from "../../../../utils/userMessages";

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const OptionContainer = styled.div`
  border-radius: 10px;
  padding: 2px 10px;
  border: 1px solid ${({ theme }) => theme.typography.heading.color.secondary};
  cursor: pointer;
  height: fit-content;

  :hover {
    background: ${({ theme }) => theme.typography.heading.color.secondary};

    .clock-options-text {
      color: ${({ theme }) => theme.box.color};
    }
  }
`;

const StyledText = styled.span`
  font-size: 34px;
  font-family: ${({ theme }) => theme.typography.font.tertiary};
  font-weight: ${({ theme }) => theme.typography.weight.regular};
  color: ${({ theme }) => theme.typography.heading.color.secondary};
`;

const ClockOptions = ({ onDurationChange, max }) => {
  const [durationOptions, setDurationOptions] = useState([]);
  const shouldDisplayMaxDurationInfo = useRef(true);
  const { t } = useTranslation();

  useEffect(() => {
    setDurationOptions(
      durationDefaultOptions.map((durationDefaultOption) => {
        return {
          duration: durationDefaultOption,
          durationFormatted: convertSecondsToReadableTime(
            convertMinutesToSeconds(durationDefaultOption),
            {
              avoidSeconds: true,
            }
          ),
        };
      })
    );
  }, []);

  const onOptionClick = useCallback(
    (optionDuration) => {
      onDurationChange((prevDuration) => {
        const newDuration = prevDuration + optionDuration;

        if (max === null) {
          return newDuration;
        }

        const isDurationHigherThenMax = max < newDuration;

        if (isDurationHigherThenMax && shouldDisplayMaxDurationInfo.current) {
          shouldDisplayMaxDurationInfo.current = false;
          infoMessage(
            t("8268", {
              max: convertSecondsToReadableTime(convertMinutesToSeconds(max), {
                avoidSeconds: true,
              }),
            }) /* A duração máxima do estacionamento é de {{max}} minutos. */
          );
        }

        return isDurationHigherThenMax ? max : newDuration;
      });
    },
    [max, onDurationChange]
  );

  return (
    <OptionsContainer>
      {durationOptions.map((option) => (
        <OptionContainer
          onClick={() => onOptionClick(option.duration)}
          key={option.duration}
        >
          <StyledText className="clock-options-text" variant="body10">
            {`+${option.durationFormatted}`}
          </StyledText>
        </OptionContainer>
      ))}
    </OptionsContainer>
  );
};

ClockOptions.propTypes = {
  onDurationChange: PropTypes.func.isRequired,
  max: PropTypes.number,
};

ClockOptions.defaultProps = {
  max: null,
};

export default ClockOptions;
