export const STEPS = {
  cities: "cities",
  permitTypes: "permitTypes",
  permitDetails: "permitDetails",
  personalData: "personalData",
  documents: "documents",
  vehicles: "vehicles",
  confirmation: "confirmation",
};

export const CREATION_CONTEXT = { public: "public", private: "private" };

export const STEPS_BEFORE_PERMIT_TYPE_CHOOSE = [STEPS.cities, STEPS.permitTypes];
