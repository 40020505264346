import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Lottie from "react-lottie";
import animation from "./animation.json";

const Container = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
`;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Loader = (props) => {
  const { isLoading, width, height } = props;

  const onClick = (event) => event.stopPropagation();

  return (
    isLoading && (
      <Container onClick={onClick}>
        <Lottie
          options={defaultOptions}
          height={height}
          width={width}
          isStopped={false}
          isPaused={false}
        />
      </Container>
    )
  );
};

Loader.defaultProps = {
  isLoading: false,
  width: 150,
  height: 150,
};

Loader.propTypes = {
  isLoading: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Loader;
