export const calculatedParkingInitialState = {
  isLoading: false,
  amount: 0,
  duration: null,
  error: false,
  endingDate: null,
};

export const parkingRenewalSteps = {
  chooseDuration: "chooseDuration",
  specialPaymentMethod: "specialPaymentMethod",
};
