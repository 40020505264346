export const settingsMapping = {
  notificationLongParkings: "notificationLongParkings",
  notificationBalanceAvailable: "notificationBalanceAvailable",
  notificationInfractions: "notificationInfractions",
  notificationNoBalance: "notificationNoBalance",
  notificationMaxTimeExceeded: "notificationMaxTimeExceeded",
  receiveNewslettersNewFeatures: "receiveNewslettersNewFeatures",
  receiveNewslettersMarketCampaigns: "receiveNewslettersMarketCampaigns",
  language: "language",
};

export const serverSettingsMapping = {
  [settingsMapping.notificationLongParkings]: "notification_long_parkings",
  [settingsMapping.notificationBalanceAvailable]: "notification_balance_available",
  [settingsMapping.notificationInfractions]: "notification_infractions",
  [settingsMapping.notificationNoBalance]: "notification_no_balance",
  [settingsMapping.notificationMaxTimeExceeded]: "notification_max_time_exceeded",
  [settingsMapping.receiveNewslettersNewFeatures]: "receive_newsletters_new_features",
  [settingsMapping.receiveNewslettersMarketCampaigns]:
    "receive_newsletters_market_campaigns",
  [settingsMapping.language]: "language",
};

export const notificationsSettings = [
  settingsMapping.notificationLongParkings,
  settingsMapping.notificationBalanceAvailable,
  settingsMapping.notificationInfractions,
  settingsMapping.notificationNoBalance,
  settingsMapping.notificationMaxTimeExceeded,
];

export const newslettersSettings = [
  settingsMapping.receiveNewslettersNewFeatures,
  settingsMapping.receiveNewslettersMarketCampaigns,
];
