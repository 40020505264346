import { processVehicles } from "domain/private/permitHolders/controllers/vehicles";
import DriversDataSource from "lib/clients/iParque/dataSources/driversDataSource";
import { errorMessage } from "utils/userMessages";

const driversDataSource = new DriversDataSource();

export const getVehicles = async (authHash, { t }) => {
  try {
    const response = await driversDataSource.getVehicles(authHash, {
      fillCollections: "model",
      noLimit: true,
      sold: false,
    });

    return processVehicles(response.items);
  } catch (error) {
    errorMessage(error, t("7956") /* Ocorreu um erro ao obter a lista de veículos */);

    return null;
  }
};
