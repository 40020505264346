import React from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Clipboard = ({ children, text, title }) => {
  return (
    <CopyToClipboard text={text} title={title}>
      {children}
    </CopyToClipboard>
  );
};

Clipboard.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
};

Clipboard.defaultProps = {
  title: "",
};

export default Clipboard;
