import { useEffect, useRef, useState } from "react";

export const useHeightAnimation = (isOpen = false, duration) => {
  const contentRef = useRef();
  const parentRef = useRef();

  const [isOpened, setIsOpened] = useState(isOpen);
  const [isFullyOpened, setIsFullyOpened] = useState(isOpen);

  const open = () => {
    setIsOpened(true);

    parentRef.current.style.maxHeight = "0";
    parentRef.current.style.maxHeight = `${contentRef.current.clientHeight}px`;

    setTimeout(() => setIsFullyOpened(true), duration);
    setTimeout(() => {
      parentRef.current.style.maxHeight = "none";
    }, duration);
  };

  const close = () => {
    setIsOpened(false);
    setIsFullyOpened(false);

    parentRef.current.style.maxHeight = `${contentRef.current.clientHeight}px`;

    setTimeout(() => {
      parentRef.current.style.maxHeight = "0";
    });
  };

  useEffect(() => {
    if (contentRef.current.clientHeight && isOpen) {
      open();
    }
  }, []);

  const handleClick = () => (isOpened ? close() : open());

  return {
    isOpened,
    open,
    isFullyOpened,
    handleClick,
    contentRef,
    parentRef,
  };
};
