import PropTypes from "prop-types";
import { SelectField } from "iparque-components";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useLanguages from "hooks/useLanguages";
import { settingsTabs } from "../../controller";

const LanguageTab = ({ className }) => {
  const { t } = useTranslation();

  const onChangeCallback = useCallback(() => {
    const parser = new URL(window.location);

    parser.searchParams.set("activeTabId", settingsTabs.language);

    window.location = parser.href;
  }, []);

  const { languages, onChangeLanguage, language } = useLanguages({
    useLoading: true,
    onChangeCallback,
  });

  const localOnChangeLanguage = useCallback(
    (event) => onChangeLanguage(event.target.value),
    [onChangeLanguage]
  );

  return (
    <Container className={className}>
      <SelectField
        options={languages}
        name="modelId"
        label={t("79") /* Idioma */}
        value={language}
        onChange={localOnChangeLanguage}
      />
    </Container>
  );
};

export default LanguageTab;

LanguageTab.defaultProps = {
  className: null,
};

LanguageTab.propTypes = {
  className: PropTypes.string,
};

const Container = styled.div`
  width: 300px;
`;
