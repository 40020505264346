import React from "react";
import useList from "./hooks/useList";

const ListHookWrapper = () => {
  useList();

  return <></>;
};

export default ListHookWrapper;
