export const formatAtm = (atm) => {
  if (!atm) {
    return "-";
  }

  return atm.replace(/\d{3}(?=.)/g, "$& ");
};

export const formatIban = (iban) => {
  if (!iban) {
    return "-";
  }

  return iban.replace(/^(.{4})(.{4})(.{4})(.{11})(.{2})$/, "$1 $2 $3 $4 $5");
};
