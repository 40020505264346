import FlexBox from "components/FlexBox";
import Skeleton from "components/Skeleton";
import React from "react";

const PermitTypesStepSkeleton = () => {
  return (
    <FlexBox>
      <Skeleton className="fill" height={80} />
    </FlexBox>
  );
};

export default PermitTypesStepSkeleton;
