import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Alert from "./Alert";

const RejectionWarning = ({ motive, observations }) => {
  const { t } = useTranslation();

  return (
    <Alert
      variant="danger"
      title={`${t("10567") /* O dístico foi rejeitado por: */} ${motive}`}
      subtitle={observations}
    />
  );
};

export default RejectionWarning;

RejectionWarning.propTypes = {
  motive: PropTypes.string.isRequired,
  observations: PropTypes.string,
};

RejectionWarning.defaultProps = {
  observations: undefined,
};
