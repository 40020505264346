import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import DefaultPermitDetailsStep from "components/permitHolders/Creation/defaultSteps/PermitDetails";
import { usePermitHoldersContext } from "components/permitHolders/Creation/Provider";
import { actionTypes } from "components/permitHolders/Creation/store/actions";
import React from "react";
import { useTranslation } from "react-i18next";
import { getById as getDriverInfo } from "requests/driver";
import { partyTypes } from "utils/clients";
import PermitDetailsWrapper from "./Wrapper";

const PermitDetailsStep = () => {
  const { driverHash } = useAuth();
  const { setIsLoading } = useAppContext();
  const { dispatch, selectedCity, personalData } = usePermitHoldersContext();
  const { t } = useTranslation();

  const onBeforeAdvance = async () => {
    if (personalData?.blockInfoAutoUpdate) {
      return;
    }

    setIsLoading(true);

    const driverInfo = await getDriverInfo(driverHash, {}, { t });

    setIsLoading(false);

    if (driverInfo) {
      dispatch({
        type: actionTypes.SET_PERSONAL_DATA,
        payload: {
          partyTypeId: partyTypes.person,
          name: driverInfo.name,
          countryId: driverInfo.countryId,
          taxpayerIdentificationNumber: driverInfo.vat,
          commercialName: "",
          economicActivityCode: "",
          address: driverInfo.address,
          complementaryAddress: "",
          zipCode: driverInfo.zipCode,
          locality: driverInfo.location,
          districtId: selectedCity.districtId,
          email: driverInfo.email,
          phone: "",
          mobilePhone: driverInfo.phone,
        },
      });
    }
  };

  return (
    <DefaultPermitDetailsStep
      onBeforeAdvance={onBeforeAdvance}
      WrapperComponent={<PermitDetailsWrapper />}
    />
  );
};

export default PermitDetailsStep;
