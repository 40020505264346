import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import AdvanceButton from "components/permitHolders/Creation/components/AdvanceButton";
import PersonalDataForm from "components/permitHolders/Creation/components/PersonalData/Form";
import { useStepContext } from "components/permitHolders/Creation/components/Step";
import DocumentCard from "components/permitHolders/Creation/defaultSteps/Confirmation/DocumentCard";
import usePermitHolderCreation from "components/permitHolders/Creation/hooks/usePermitHolderCreation";
import { usePermitHoldersContext } from "components/permitHolders/Creation/Provider";
import useCountries from "hooks/useCountries";
import { Text } from "iparque-components";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import { permitHoldersOccupationType } from "utils/permitHolders";
import VehicleCard from "./VehicleCard";

const ConfirmationStep = () => {
  const { t } = useTranslation();
  const { setIsLoading } = useAppContext();
  const { defaultEntityId, userId: driverId, driverHash } = useAuth();
  const { onSubmissionSuccess } = useStepContext();
  const { documents, vehicles, selectedPermitType } = usePermitHoldersContext();
  const { countryOptions } = useCountries({ entityId: defaultEntityId });
  const createPermitHolder = usePermitHolderCreation();

  const onConfirm = async () => {
    setIsLoading(true);

    const result = await createPermitHolder({ driverId, driverHash });

    setIsLoading(false);

    if (!result) {
      return;
    }

    onSubmissionSuccess();
  };

  const getCountryName = useCallback(
    (countryId) => {
      const country = countryOptions.find(
        (countryOption) => countryOption.value === countryId
      );

      return country?.label || "-";
    },
    [countryOptions]
  );

  return (
    <>
      <Container>
        <PersonalDataForm readOnly />
        <SectionTitle className="mt-20">{t("4297") /* Documentos */}</SectionTitle>
        {Object.values(documents).map((doc) => (
          <DocumentCard key={doc.designation} {...doc} />
        ))}
        {selectedPermitType.permitType.occupationTypeId ===
          permitHoldersOccupationType.vehicle && (
          <>
            <SectionTitle className="mt-20">{t("4424") /* Veículos */}</SectionTitle>
            <div>
              {vehicles.map((vehicle) => (
                <VehicleCard
                  key={vehicle.licencePlate}
                  regimeName={vehicle.regime.designation}
                  colorId={vehicle.colorId}
                  licencePlate={vehicle.licencePlate}
                  country={getCountryName(vehicle.countryId)}
                  brandName={vehicle.brandName}
                  modelName={vehicle.modelName}
                />
              ))}
            </div>
          </>
        )}
      </Container>
      <AdvanceButton type="button" lastStep onClick={onConfirm} />
    </>
  );
};

export default ConfirmationStep;

const Container = styled.div`
  max-width: 730px;

  @media (max-width: ${breakpoints.lg}) {
    max-width: 100%;
  }
`;

const SectionTitle = styled(Text).attrs({
  variant: "body2",
  color: "quintenary",
})`
  margin-bottom: 5px;
`;
