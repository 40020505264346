import React from "react";
import PropTypes from "prop-types";
import { Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import SelectedBenefit from "../../components/parkings/SelectedBenefit";
import ParkingMap from "../Map";
import { isEmpty } from "../../../../utils/general";

const CommonExtraInformation = ({ id, benefit, location, isOpen }) => {
  const { t } = useTranslation();
  const hasBenefit = !isEmpty(benefit);
  const hasLocation = location.lat !== null && location.lng !== null;

  return (
    <>
      {hasBenefit && (
        <div className="mt-20">
          <>
            <Text variant="body4" color="primary">
              {t("7076") /* Benefício */}
            </Text>
            <SelectedBenefit description={benefit.name} typeId={benefit.typeId} />
          </>
        </div>
      )}
      {hasLocation && (
        <div className="mt-20">
          <>
            <Text variant="body4" color="primary">
              {t("2539") /* Localização */}
            </Text>
            {isOpen && <ParkingMap id={id} location={location} />}
          </>
        </div>
      )}
    </>
  );
};

CommonExtraInformation.propTypes = {
  benefit: PropTypes.shape({ name: PropTypes.string, typeId: PropTypes.number }),
  location: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }).isRequired,
  id: PropTypes.number.isRequired,
  isOpen: PropTypes.bool,
};

CommonExtraInformation.defaultProps = {
  benefit: {},
  isOpen: false,
};

export default CommonExtraInformation;
