import React from "react";
import PropTypes from "prop-types";
import { Text } from "iparque-components";
import styled, { css } from "styled-components";
import { useBackofficeThemeContext } from "../ThemeProvider";

const BenefitContainer = styled.div`
  border-radius: 50px;
  padding: 5px 20px;
  max-width: fit-content;
  background-color: ${({ color }) => color};
`;

const StyledTypography = css`
  color: ${({ textColor }) => textColor};
`;

const StyledText = styled(Text)`
  ${StyledTypography}
`;

const SelectedBenefit = ({ description, color, typeId, className }) => {
  const { backofficeTheme } = useBackofficeThemeContext();
  let processedColor = color;

  if (!color && typeId) {
    processedColor = backofficeTheme.benefitColor[typeId] || "";
  }

  return (
    <BenefitContainer className={className} title={description} color={processedColor}>
      <StyledText textColor={backofficeTheme.background.color.background} variant="body9">
        {description}
      </StyledText>
    </BenefitContainer>
  );
};

SelectedBenefit.propTypes = {
  description: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  typeId: PropTypes.number,
};

SelectedBenefit.defaultProps = {
  color: "",
  typeId: null,
  className: null,
};

export default SelectedBenefit;
