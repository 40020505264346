import { PasswordStrengthIndicator } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { colorsVariantsMapping, textVariantsMapping } from "../utils/theme";

const PasswordRequirements = React.forwardRef((props, ref) => {
  const { password, className, textColor, textVariant } = props;
  const { t } = useTranslation();

  return (
    <PasswordStrengthIndicator
      ref={ref}
      className={className}
      textClassName="password-requirements-text"
      textColor={textColor}
      textVariant={textVariant}
      password={password}
      translations={{
        requirements: t("9671") /* A tua palavra-passe deve: */,
        minChars: t("8141") /* Ter pelo menos 10 carateres */,
        subRequirements: t("8142") /* Conter 3 destes elementos: */,
        uppercaseChars: t("8150") /* Letras maiúsculas; */,
        lowercaseChars: t("8151") /* Letras minúsculas; */,
        numbers: t("8152") /* Números; */,
        specialChars: t("8153") /* Carateres especiais */,
        strongLevel: t("8143") /* Atingir o nível forte */,
        levelIndicator: t("8144") /* Indicador de nível */,
        level: t("8146") /* Nível */,
        weak: t("8148") /* Fraco */,
        moderate: t("8149") /* Moderado */,
        strong: t("8147") /* Forte */,
      }}
    />
  );
});

PasswordRequirements.propTypes = {
  password: PropTypes.string,
  className: PropTypes.string,
  textColor: PropTypes.oneOf([
    colorsVariantsMapping.primary,
    colorsVariantsMapping.secondary,
  ]),
  textVariant: PropTypes.oneOf([textVariantsMapping.body9, textVariantsMapping.body10]),
};

PasswordRequirements.defaultProps = {
  password: "",
  className: "",
  textColor: colorsVariantsMapping.secondary,
  textVariant: textVariantsMapping.body9,
};

export default PasswordRequirements;
