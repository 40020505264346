import Button from "components/buttons/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { paymentMethodsMapping } from "utils/payment";
import { feeTypesMapping } from "../../../../utils/fees";
import { routesMapping } from "../../../../utils/routes";
import AmountDetails from "../../components/parkings/AmountDetails";
import ParkingEndDate from "../../components/parkings/EndDate";
import FeeNameBox from "../../components/parkings/FeeNameBox";
import SelectedBenefit from "../../components/parkings/SelectedBenefit";
import ParkingTimer from "../../components/parkings/Timer";
import TimerAdditionalInfo from "../../components/parkings/TimerAdditionalInfo";
import useTimerInfo from "../../hooks/useTimerInfo";
import { detailsDefaultProps, detailsPropTypes } from "../propTypes";

const center = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TimerContainer = styled.div`
  margin-top: 5vh;
  align-items: center;
  flex-direction: column;
  ${center}
`;

const GeneralContainer = styled.div`
  margin-top: 2vh;
  ${center}
`;

const AmountContainer = styled.div`
  margin-top: 5vh;
  ${center}
`;

const ButtonContainer = styled.div`
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  max-width: 200px;
  align-self: center;
`;

const ParkingDetails = ({
  id,
  benefitName,
  benefitColor,
  currentAmount,
  onRenewCallback,
  startingDate,
  endingDate,
  queryString,
  parkingTypeId,
  scheduleExtraInfo,
  fee,
  parkingPaymentTypeId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMinutesFee = fee.id === feeTypesMapping.minutes;

  const stopParking = () => {
    history.push(`${routesMapping.backofficeParking}/${id}/stop?${queryString}`);
  };

  const timerInfo = useTimerInfo(id, { endCallback: stopParking });

  const isMBWayPayment = parkingPaymentTypeId === paymentMethodsMapping.mbway.id;

  return (
    <>
      <TimerContainer>
        <ParkingTimer
          parkingId={id}
          parkingTypeId={parkingTypeId}
          startingDate={startingDate}
          endingDate={endingDate}
          scheduleExtraInfo={scheduleExtraInfo}
          size="lg"
        />
      </TimerContainer>
      <GeneralContainer>
        <ParkingEndDate
          parkingTypeId={parkingTypeId}
          className="mt-15"
          endingDate={endingDate}
        />
        <TimerAdditionalInfo
          state={timerInfo?.state}
          nextStart={timerInfo?.nextStart}
          variant="body11"
        />
      </GeneralContainer>
      {benefitName ? (
        <GeneralContainer>
          <SelectedBenefit description={benefitName} color={benefitColor} />
        </GeneralContainer>
      ) : null}
      <AmountContainer>
        <AmountDetails amount={currentAmount} />
      </AmountContainer>
      <GeneralContainer>
        {!isMinutesFee && <FeeNameBox className="mt-15" name={fee.name} />}
      </GeneralContainer>
      <ButtonContainer>
        <Button size="md" onClick={onRenewCallback} className="mb-10" color="info">
          {t("46") /* Renovar */}
        </Button>
        {isMinutesFee && !isMBWayPayment && (
          <Button onClick={stopParking} color="danger" size="md">
            {t("3216") /* Parar */}
          </Button>
        )}
      </ButtonContainer>
    </>
  );
};

ParkingDetails.propTypes = detailsPropTypes;

ParkingDetails.defaultProps = detailsDefaultProps;

export default ParkingDetails;
