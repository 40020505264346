import moment from "moment";
import {
  compareServerDateTimes,
  getCurrentDateTimeInServerFormat,
  getSecondsBetweenTwoDates,
} from "./dateTime";
import { buildQueryString } from "./general";

export const parkingTypesMapping = {
  duration: 1,
  startAndStop: 4,
};

export const parkingMethodsMapping = {
  driverWeb: 2,
};

const parkingStatesMapping = {
  active: 1,
  ended: 2,
  stopped: 3,
  cancelled: 4,
  pending: 5,
};

const finalizedParkingStates = [
  parkingStatesMapping.ended,
  parkingStatesMapping.stopped,
  parkingStatesMapping.cancelled,
];

export const getTextColorByState = (state, { defaultColor = "primary" } = {}) => {
  if ([parkingStatesMapping.active, parkingStatesMapping.ended].includes(state)) {
    return "success";
  }

  if (state === parkingStatesMapping.cancelled) {
    return "danger";
  }

  return defaultColor;
};

const isFinalized = (parkingStateId) => {
  return finalizedParkingStates.includes(parkingStateId);
};

export const getEndingDate = (parkingTypeId, parkingStateId, endingDate) => {
  if (!endingDate) {
    return null;
  }

  if (parkingTypeId !== parkingTypesMapping.startAndStop || isFinalized(parkingStateId)) {
    return endingDate;
  }

  return null;
};

export const isScheduled = (startingDate) => {
  return getSecondsBetweenTwoDates(startingDate, moment()) < 0;
};

export const isEnded = (endingDate) => {
  if (!endingDate) {
    return false;
  }

  return compareServerDateTimes(endingDate, getCurrentDateTimeInServerFormat()) >= 0;
};

export const isRunning = (startingDate) => {
  return compareServerDateTimes(startingDate, getCurrentDateTimeInServerFormat()) > 0;
};

export const buildParkingQueryString = (parking) => {
  return buildQueryString(
    Object.fromEntries(
      Object.entries(parking).filter(([key]) =>
        [
          "amount",
          "streetName",
          "streetId",
          "explorationName",
          "licensePlate",
          "vehicleBrand",
          "benefitName",
          "benefitColor",
          "vehicleColor",
        ].includes(key)
      )
    )
  );
};
