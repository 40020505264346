import { getTokens } from "../../../../utils/auth";
import AbstractDataSource from "./abstractDataSource";

export default class PaymentsDataSource extends AbstractDataSource {
  async creditCardCharge(visaReference, params = {}) {
    return this.iParqueClient.POST(`v1/payments/creditcard/${visaReference}`, params, {});
  }

  async creditCardCapture(token, params = {}) {
    return this.iParqueClient.POST(`v1/payments/creditcard/${token}/capture`, params, {});
  }

  async getParkingPaymentMethods(entityId, params = {}, useCache) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/payments/parkingMethods`,
      params,
      { Authorization: getTokens().token },
      useCache
    );
  }

  async getBalancePaymentMethods(entityId, params = {}, useCache) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/payments/balanceMethods`,
      params,
      { Authorization: getTokens().token },
      useCache
    );
  }

  async getNoticePaymentMethods(entityId, params = {}, useCache, cancelToken = null) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/payments/noticesMethods`,
      params,
      { Authorization: getTokens().token },
      useCache,
      cancelToken
    );
  }

  async checkExternalState(token, params) {
    return this.iParqueClient.GET(`v1/payments/creditcard/${token}/state`, params, {
      Authorization: getTokens().token,
    });
  }

  async getExternalPaymentStatus(entityId, paymentId, params) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/external/payments/${paymentId}/state`,
      params,
      { Authorization: getTokens().token }
    );
  }

  async getPaymentMethods(entityId, transactionTypeId, params = {}, useCache) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/payments/methods`,
      { transactionTypeId, ...params },
      { Authorization: getTokens().token },
      useCache
    );
  }
}
