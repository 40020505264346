import { paymentBoxSizes } from "domain/private/components/paymentMethods/helper";
import useWindowSize from "hooks/useWindowSize";
import { useMemo } from "react";

export const MIN_PIXELS_TO_DISPLAY_PAYMENTS_INLINE = 1600;
const INLINE_HEIGHT = 700;
const COLUMN_HEIGHT = 1500;

const useSubscriptionPaymentMethodsSize = () => {
  const windowSize = useWindowSize();

  const approximateHeight = useMemo(() => {
    if (windowSize.width < MIN_PIXELS_TO_DISPLAY_PAYMENTS_INLINE) {
      return COLUMN_HEIGHT;
    }

    return INLINE_HEIGHT;
  }, [windowSize.width]);

  return { size: paymentBoxSizes.medium, approximateHeight };
};

export default useSubscriptionPaymentMethodsSize;
