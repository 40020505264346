import React from "react";
import PropTypes from "prop-types";
import { Text } from "iparque-components";
import styled, { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppContext } from "components/AppProvider";
import { usePermitHoldersContext } from "components/permitHolders/Creation/Provider";
import ContentBorderBox from "domain/private/components/ContentBorderBox";
import ContentHeader from "domain/private/components/ContentHeader";
import { smallDevicesBreakpoints } from "utils/breakpoints";
import { routesMapping } from "utils/routes";
import SmallDevicesContentHeader from "domain/private/components/smallDevices/ContentHeader";
import useTitle from "../../hooks/useTitle";

const Template = ({ children }) => {
  const theme = useTheme();
  const history = useHistory();
  const title = useTitle();
  const { t } = useTranslation();
  const { breakpoint } = useAppContext();
  const { canGoBack, goBack } = usePermitHoldersContext();

  const returnToPermitHoldersList = () => {
    history.push(routesMapping.backofficePermitHolders);
  };

  const backCallback = canGoBack ? goBack : returnToPermitHoldersList;

  if (smallDevicesBreakpoints.includes(breakpoint)) {
    return (
      <>
        <SmallDevicesContentHeader
          topTitle={t("10416") /* Precisas de estacionar? */}
          bottomTitle={t("10574") /* Não te preocupes! Podes criar aqui o teu dístico */}
          backCallback={backCallback}
        />
        <Text variant="body7" className="uppercase mt-30 mb-20">
          {title}
        </Text>
        {children}
      </>
    );
  }

  return (
    <>
      <ContentHeader
        topTitle={t("10416") /* Precisas de estacionar? */}
        bottomTitle={t("10574") /* Não te preocupes! Podes criar aqui o teu dístico */}
      />
      <ContentBorderBox
        backCallback={backCallback}
        title={title}
        className="mt-40"
        cornerColor={theme.color.primary}
      >
        <ContentContainer>{children}</ContentContainer>
      </ContentBorderBox>
    </>
  );
};

export default Template;

Template.propTypes = {
  children: PropTypes.node.isRequired,
};

const ContentContainer = styled.div`
  margin-top: 20px;
`;
