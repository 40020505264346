import Button from "components/buttons/Button";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";

const AdvanceButton = ({ type, onClick, disabled, className, children, lastStep }) => {
  const { t } = useTranslation();

  const label = (() => {
    if (children) {
      return children;
    }

    return lastStep ? t("35") /* Confirmar */ : t("10223"); /* Avançar */
  })();

  return (
    <ButtonContainer className={`mt-50 ${className}`}>
      <Button
        type={type}
        onClick={onClick}
        color="quintenary"
        disabled={disabled}
        fullWidth
      >
        {label}
      </Button>
    </ButtonContainer>
  );
};

export default AdvanceButton;

AdvanceButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["button", "reset", "submit"]).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  lastStep: PropTypes.bool,
  children: PropTypes.string,
};

AdvanceButton.defaultProps = {
  onClick: undefined,
  className: "",
  disabled: false,
  lastStep: false,
  children: undefined,
};

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
  width: 200px;

  @media (max-width: ${breakpoints.sm}) {
    justify-content: center;
    width: 100%;
  }
`;
