function set(name, value, minutes, args = { sameSite: "Secure", secure: false }) {
  let expiresConfig = "";
  let sameSiteConfig = "";
  let secureConfig = "";

  const { sameSite, secure } = args;

  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expiresConfig = `; expires=${date.toUTCString()}`;
  }

  if (sameSite) {
    sameSiteConfig = `; SameSite=${sameSite}`;
  }

  if (secure) {
    secureConfig = `; Secure`;
  }

  document.cookie = `${name}=${
    value || ""
  }${expiresConfig}${sameSiteConfig}${secureConfig}; path=/`;
}

function get(name) {
  const nameEqual = `${name}=`;
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i += 1) {
    let cookie = cookies[i];

    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }

    if (cookie.indexOf(nameEqual) === 0) {
      return cookie.substring(nameEqual.length, cookie.length);
    }
  }

  return null;
}

function remove(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

const CookieHandler = {
  get,
  set,
  remove,
};

export default CookieHandler;

export const getCookie = get;
export const setCookie = set;
export const deleteCookie = remove;
