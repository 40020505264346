import styled from "styled-components";

const ParkingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: ${({ smallVersion }) => (smallVersion ? "250px" : "430px")};
`;

export default ParkingContainer;
