import React from "react";
import { tabsIdsMap } from "../../../helper";
import HistoryList from "../../../components/history/List";
import TabTitle from "../../../components/Title";
import Card from "./Card";

const HistoryTab = () => {
  return (
    <>
      <TabTitle tabId={tabsIdsMap.history} />
      <HistoryList Card={Card} />
    </>
  );
};

export default HistoryTab;
