import React from "react";
import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";
import { Text, Link } from "iparque-components";
import { ReactComponent as DetailsIcon } from "assets/icons/search.svg";

const DocumentCard = ({ designation, file }) => {
  const theme = useTheme();
  const downloadUrl = URL.createObjectURL(file);

  return (
    <Container>
      <div>
        <Text variant="body2" color="quintenary">
          {designation}
        </Text>
        <Text className="mt-5" variant="body6" color="quintenary">
          <Link underline="always" target="_blank" href={downloadUrl}>
            {file.name}
          </Link>
        </Text>
      </div>
      <Link target="_blank" href={downloadUrl}>
        <DetailsIcon
          width={25}
          title={file.name}
          fill={theme.card.dense.primary.iconColor}
        />
      </Link>
    </Container>
  );
};

export default DocumentCard;

DocumentCard.propTypes = {
  designation: PropTypes.string.isRequired,
  file: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.card.dense.primary.backgroundColor};
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;
