import axios from "axios";
import i18n from "i18next";
import CachedClient from "../cachedClient";
import {
  DEFAULT_API_ERROR_CODE,
  REQUEST_CANCELED,
  UNAUTHORIZED,
} from "../../../utils/error";
import { getTokens, getUser, setSession } from "../../../utils/auth";
import { removeCache } from "../../../utils/cache";
import { languageWithoutRegion } from "../../../utils/general";
import { convertSecondsToMilliseconds } from "../../../utils/dateTime";

let isRefreshingToken = false;
let isRefreshTokenExpired = false;
const checkRefreshTokenSeconds = 0.1;
const maxTimeToCheckRefreshTokenSeconds = 20;

const processOriginalRequest = (originalRequest) => {
  return {
    ...originalRequest,
    data: JSON.parse(originalRequest.data),
    headers: { ...originalRequest.headers, Authorization: getTokens().token },
  };
};

const processHeaders = (headers) => {
  const processedHeaders = headers;

  if (!processedHeaders.Authorization) {
    delete processedHeaders.Authorization;
  }

  return processedHeaders;
};

const buildUnauthorizedError = () => {
  const error = new Error("Unauthorized");
  error.code = UNAUTHORIZED;
  error.errors = [];

  return error;
};

const buildCanceledError = () => {
  const error = new Error("Canceled");
  error.code = REQUEST_CANCELED;
  error.errors = [];

  return error;
};

const buildUnexpectedError = () => {
  const error = new Error("An unexpected error occurred.");
  error.code = DEFAULT_API_ERROR_CODE;
  error.errors = [];

  return error;
};

const waitToRefreshToken = async () => {
  return new Promise((resolve, reject) => {
    let waitingSeconds = 0;

    const interval = setInterval(() => {
      waitingSeconds += checkRefreshTokenSeconds;

      if (isRefreshTokenExpired) {
        reject(UNAUTHORIZED);
      }

      if (waitingSeconds > maxTimeToCheckRefreshTokenSeconds) {
        reject(UNAUTHORIZED);
      }

      if (!isRefreshingToken) {
        clearInterval(interval);
        resolve();
      }
    }, convertSecondsToMilliseconds(checkRefreshTokenSeconds));
  });
};

export default class IParqueClient extends CachedClient {
  constructor() {
    super("");
    this.isRefreshBlocked = false;
    this.language = languageWithoutRegion(
      i18n.language || window.localStorage.i18nextLng
    );
  }

  setUnauthorizedRedirect(callback) {
    this.unauthorizedRedirect = callback;
  }

  async refreshToken() {
    const user = getUser();

    if (!user) {
      throw new Error();
    }

    const refreshTokenResponse = await super.POST(
      `v1/drivers/${user.authHash}/auth/refreshToken`,
      {
        refreshToken: getTokens().refreshToken,
        appId: process.env.REACT_APP_APP_ID,
      }
    );

    if (!refreshTokenResponse.data?.success) {
      throw buildUnauthorizedError();
    }

    return refreshTokenResponse;
  }

  async solveRequest(request) {
    try {
      const requestResponse = await axios(processOriginalRequest(request));

      return this.processResponse(requestResponse);
    } catch {
      throw buildUnexpectedError();
    }
  }

  async processResponse(response) {
    if (axios.isCancel(response)) {
      throw buildCanceledError();
    }

    const responseData = response.data;
    const originalRequest = response.config;
    const cacheKey = originalRequest.url.replace(originalRequest.baseURL, "");

    if (responseData?.success === true) {
      this.isRefreshBlocked = false;
      return responseData.data;
    }

    removeCache(cacheKey);

    if (responseData?.success === false) {
      if (responseData.error.code === UNAUTHORIZED) {
        if (isRefreshingToken) {
          try {
            await waitToRefreshToken();

            return this.solveRequest(originalRequest);
          } catch (error) {
            if (error === UNAUTHORIZED) {
              throw buildUnauthorizedError();
            }

            throw buildUnexpectedError();
          }
        }

        if (this.isRefreshBlocked) {
          throw buildUnexpectedError();
        }

        try {
          isRefreshingToken = true;
          this.isRefreshBlocked = true; // Prevent an infinite loop of refresh token attempts
          const refreshTokenResponse = await this.refreshToken();

          isRefreshingToken = false;
          setSession(refreshTokenResponse.data.data);

          return this.solveRequest(originalRequest);
        } catch (responseError) {
          isRefreshTokenExpired = true;

          const event = new Event("unauthorized");
          document.dispatchEvent(event);

          throw responseError;
        }
      }

      removeCache(cacheKey);
      const error = new Error(responseData.error.msg);
      error.code = responseData.error.code;
      error.errors = responseData.error?.errors || [];
      throw error;
    }

    throw buildUnexpectedError();
  }

  async GET(path, params = {}, headers = {}, useCache = false, cancelToken = null) {
    return this.processResponse(
      await super.GET(
        path,
        { ...params, lang: this.language, appId: process.env.REACT_APP_APP_ID },
        processHeaders(headers),
        useCache,
        cancelToken
      )
    );
  }

  async POST(path, body = {}, headers = {}) {
    let params;

    if (body instanceof FormData) {
      params = body;

      params.append("lang", this.language);
      params.append("appId", process.env.REACT_APP_APP_ID);
    } else {
      params = { ...body, lang: this.language, appId: process.env.REACT_APP_APP_ID };
    }

    return this.processResponse(await super.POST(path, params, processHeaders(headers)));
  }

  async PUT(path, body = {}, headers = {}) {
    return this.processResponse(
      await super.PUT(
        path,
        { ...body, lang: this.language, appId: process.env.REACT_APP_APP_ID },
        processHeaders(headers)
      )
    );
  }

  async DELETE(path, params = {}, headers = {}) {
    return this.processResponse(
      await super.DELETE(
        path,
        { ...params, lang: this.language, appId: process.env.REACT_APP_APP_ID },
        processHeaders(headers)
      )
    );
  }
}
