import { useAppContext } from "components/AppProvider";
import Button from "components/buttons/Button";
import FlexBox from "components/FlexBox";
import VehicleBox from "domain/private/components/VehicleBox";
import { Heading, Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled, { css, useTheme } from "styled-components";
import { breakpoints, breakpointsMapping } from "utils/breakpoints";
import { colorsMapping } from "utils/colors";

export const SPACE_BETWEEN_CARDS = 12;

const VehiclePicker = ({
  vehicle,
  allVehicleOptions,
  availableVehicleOptions,
  editVehicle,
  makeEditable,
  removeVehicle,
  canRemove,
  position,
  allowOperations,
  smallVersion,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { breakpoint } = useAppContext();

  const localSmallVersion =
    [breakpointsMapping.xs, breakpointsMapping.sm].includes(breakpoint) || smallVersion;

  const canEdit = !vehicle.editable;

  const selectedVehicle = useMemo(
    () =>
      allVehicleOptions.find((option) => option.licencePlate === vehicle.licencePlate),
    [allVehicleOptions, vehicle]
  );

  const options = useMemo(
    () =>
      selectedVehicle
        ? [selectedVehicle, ...availableVehicleOptions]
        : availableVehicleOptions,
    [selectedVehicle, availableVehicleOptions]
  );

  const onClickEdit = () => makeEditable(position);

  const onClickRemove = () => removeVehicle(position);

  const onClickVehicleOption = useCallback(
    (id) => {
      const selectedOption = options.find((option) => option.id === id);

      if (!selectedOption) {
        return;
      }

      editVehicle(position, {
        countryId: selectedOption.countryId,
        licencePlate: selectedOption.licencePlate,
        brandId: selectedOption.brandId,
        brandName: selectedOption.brandName,
        modelId: selectedOption.modelId,
        modelName: selectedOption.modelName,
        colorId: selectedOption.colorId,
      });
    },
    [editVehicle, position, options]
  );

  return (
    <Container flagged={vehicle.flagged} smallVersion={localSmallVersion}>
      <ResumeContainer smallVersion={localSmallVersion}>
        <div>
          <Heading variant="h4" color="tertiary">
            {vehicle.regime?.designation || ""}
          </Heading>
          {canEdit && (
            <LicencePlate
              textColor={theme.carColor[selectedVehicle?.colorId || colorsMapping.gray]}
            >
              {vehicle.licencePlate}
            </LicencePlate>
          )}
        </div>
        {allowOperations && (
          <ActionButtonsContainer smallVersion={localSmallVersion}>
            {canEdit && (
              <Button
                color="secondary"
                onClick={onClickEdit}
                className="action-button"
                size={localSmallVersion ? "xs" : "md"}
              >
                {t("3375") /* Editar */}
              </Button>
            )}
            {canRemove && (
              <Button
                onClick={onClickRemove}
                color="danger"
                className="action-button"
                size={smallVersion ? "xs" : "md"}
              >
                {t("717") /* Eliminar */}
              </Button>
            )}
          </ActionButtonsContainer>
        )}
      </ResumeContainer>
      {vehicle.editable && (
        <>
          {options.length > 0 ? (
            <>
              <Text variant="body7" className="mt-10 mb-20 uppercase">
                {t("10477") /* Seleciona o veículo */}
              </Text>
              <OptionsContainer>
                {options.map((vehicleOption) => (
                  <VehicleBox
                    key={vehicleOption.id}
                    id={vehicleOption.id}
                    licensePlate={vehicleOption.licencePlate}
                    selected={vehicle.licencePlate === vehicleOption.licencePlate}
                    color={theme.carColor[vehicleOption.colorId]}
                    brand={vehicleOption.brandName}
                    model={vehicleOption.modelName}
                    disabled={!vehicleOption.enabled}
                    onClick={onClickVehicleOption}
                    warningText={
                      vehicleOption.brandName
                        ? undefined
                        : t(
                            "10478"
                          ) /* Para selecionares este veículo terás que adicionar a marca e o modelo */
                    }
                  />
                ))}
              </OptionsContainer>
            </>
          ) : (
            <Text variant="body10" color="danger" className="mt-10 mb-20">
              {
                t(
                  "10722"
                ) /* Não tens veículo(s) associado(s). Clica no menu dos veículos para adicionares. */
              }
            </Text>
          )}
        </>
      )}
    </Container>
  );
};

export default VehiclePicker;

const vehicleOptionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    colorId: PropTypes.number.isRequired,
    licencePlate: PropTypes.string.isRequired,
    brandName: PropTypes.string,
    modelName: PropTypes.string,
    enabled: PropTypes.bool.isRequired,
  })
);

VehiclePicker.defaultProps = {
  allowOperations: true,
  smallVersion: false,
};

VehiclePicker.propTypes = {
  vehicle: PropTypes.shape({
    licencePlate: PropTypes.string,
    colorId: PropTypes.number,
    editable: PropTypes.bool.isRequired,
    flagged: PropTypes.bool.isRequired,
    regime: PropTypes.shape({
      designation: PropTypes.string.isRequired,
    }),
  }).isRequired,
  allVehicleOptions: vehicleOptionsPropTypes.isRequired,
  availableVehicleOptions: vehicleOptionsPropTypes.isRequired,
  editVehicle: PropTypes.func.isRequired,
  makeEditable: PropTypes.func.isRequired,
  removeVehicle: PropTypes.func.isRequired,
  canRemove: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
  allowOperations: PropTypes.bool,
  smallVersion: PropTypes.bool,
};

const Container = styled.div`
  ${({ smallVersion }) =>
    smallVersion
      ? css`
          padding: 25px 20px;
        `
      : css`
          padding: 25px 40px;
        `}

  border: 2px solid
    ${({ theme, flagged }) => (flagged ? theme.color.danger : theme.color.light)};

  :not(:first-child) {
    margin-top: ${SPACE_BETWEEN_CARDS}px;
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 30px 20px;
  }
`;

const ResumeContainer = styled.div`
  display: flex;
  min-height: 90px;
  justify-content: space-between;

  ${({ smallVersion }) =>
    smallVersion
      ? css`
          flex-direction: column;
        `
      : css`
          align-items: center;
        `}
`;

const ActionButtonsContainer = styled(FlexBox)`
  gap: 10px;

  ${({ smallVersion }) =>
    smallVersion
      ? css`
          flex-direction: row;
          margin-left: auto;
          margin-top: 10px;

          .action-button {
            width: 120px;
          }
        `
      : css`
          flex-direction: column;

          .action-button {
            width: 160px;
          }
        `}
`;

const LicencePlate = styled(Text)`
  color: ${({ textColor }) => textColor};
`;

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 10px;
`;
