import React, { useMemo } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import styled from "styled-components";
import PropTypes from "prop-types";
import Skeleton from "../../../components/Skeleton";
import { useBackofficeThemeContext } from "./ThemeProvider";

const Container = styled.div`
  margin-bottom: ${({ itemGapWidth }) => `${itemGapWidth}px`};
`;

const ListSkeleton = ({ height, width, itemGapWidth, itemsNumber }) => {
  const { backofficeTheme } = useBackofficeThemeContext();

  const list = useMemo(() => {
    const lines = [];

    for (let index = 0; index < itemsNumber; index += 1) {
      lines.push(
        <Container key={index} itemGapWidth={itemGapWidth}>
          <Skeleton height={height} width={width} />
        </Container>
      );
    }

    return lines;
  }, []);

  return (
    <SkeletonTheme
      color={backofficeTheme.loadingSkeleton.listColor}
      highlightColor={backofficeTheme.loadingSkeleton.listHighlightColor}
    >
      {list}
    </SkeletonTheme>
  );
};

ListSkeleton.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  itemsNumber: PropTypes.number.isRequired,
  itemGapWidth: PropTypes.number,
};

ListSkeleton.defaultProps = {
  height: null,
  width: null,
  itemGapWidth: 5,
};

export default ListSkeleton;
