/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { CheckboxField as IParqueCheckboxField } from "iparque-components";
import { useField } from "formik";

const CheckboxField = (props) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  const hasError = meta.error && meta.touched;

  return (
    <IParqueCheckboxField
      error={hasError}
      {...field}
      {...props}
      helperText={hasError ? meta.error : ""}
    />
  );
};

export default CheckboxField;
