import { useState, useEffect } from "react";
import { getAllEntities, getEntityById, getEntityCities } from "requests/entities";

const useCities = ({ entityId } = {}) => {
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchEntityData() {
      let entitiesData;

      if (entityId) {
        const entityData = await getEntityById(entityId);

        entitiesData = { items: entityData ? [entityData] : [] };
      } else {
        entitiesData = await getAllEntities();
      }

      if (entitiesData?.items) {
        let citiesData = [];

        await entitiesData?.items.forEach(async (entity) => {
          const citiesResponse = await getEntityCities(entity.id, {
            noLimit: true,
            fillCollections: "attachment",
          });

          if (citiesResponse?.items) {
            const cityItems = citiesResponse.items.map((city) => ({
              ...city,
              entity,
            }));

            citiesData = [...citiesData, ...cityItems];

            setCities(citiesData);
          }
        });

        setIsLoading(false);
      }
    }

    fetchEntityData();
  }, [entityId]);

  return { cities, isLoading };
};

export default useCities;
