import DraggableFileInput from "components/DraggableFileInput";
import { useValidationSchema } from "components/ValidationSchemaProvider";
import { useField, useFormikContext } from "formik";
import {
  FormFieldContainer,
  FormFieldLabel,
  FormFieldTextHelper,
} from "iparque-components";
import PropTypes from "prop-types";
import React from "react";

const DraggableFileFormField = ({ helperText, id, name, label, ...other }) => {
  const processedId = id || name;
  const [, meta, { setTouched }] = useField(name);
  const formik = useFormikContext();
  const hasError = meta.error && meta.touched;
  const { isFieldRequired } = useValidationSchema();

  const onChange = (file) => formik.setFieldValue(name, file);
  const onBlur = () => setTouched(true);

  return (
    <FormFieldContainer>
      <FormFieldLabel
        color="primary"
        hasAnimation={false}
        htmlFor={processedId}
        error={hasError}
        required={isFieldRequired}
      >
        {label}
      </FormFieldLabel>
      <DraggableFileInput
        error={hasError}
        onChange={onChange}
        name={name}
        id={processedId}
        className="mt-10"
        onBlur={onBlur}
        {...other}
      />
      <FormFieldTextHelper error={hasError}>
        {hasError ? meta.error : helperText}
      </FormFieldTextHelper>
    </FormFieldContainer>
  );
};

DraggableFileFormField.propTypes = {
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DraggableFileFormField.defaultProps = {
  id: undefined,
  helperText: "",
};

export default DraggableFileFormField;
