import PropTypes from "prop-types";
import { Text } from "iparque-components";
import React from "react";
import styled from "styled-components";
import FlexBox from "./FlexBox";

const InlineDetails = ({ details, color, className }) => {
  return (
    <FlexBox className={className} flexDirection="column">
      {details.map((detail) => (
        <Line key={detail.label}>
          <Label variant="body5" color={color}>
            {detail.label}:
          </Label>
          <Value variant="body5" color={color}>
            {detail.value}
          </Value>
        </Line>
      ))}
    </FlexBox>
  );
};

export default React.memo(InlineDetails);

InlineDetails.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.node.isRequired,
    })
  ).isRequired,
  color: PropTypes.oneOf(["primary", "quintenary"]),
  className: PropTypes.string,
};

InlineDetails.defaultProps = {
  color: "primary",
  className: undefined,
};

const Label = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.weight.regular};
`;

const Value = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.weight.bold};
`;

const Line = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:first-of-type) {
    padding-top: 10px;
  }
`;
