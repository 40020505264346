import InfiniteList from "components/InfiniteList";
import React from "react";
import { LIST_MAX_HEIGHT } from "../../configs";
import useDocuments from "../../hooks/useDocuments";
import Card from "./Card";
import EmptyListInfo from "./EmptyListInfo";
import ErrorComponent from "./ErrorComponent";

const NUMBER_OF_LOADING_CARDS = 1;
const CARD_HEIGHT = 65;

const DocumentsList = () => {
  const { items, isLoading, onFinishedScroll, hasError } = useDocuments();

  return (
    <InfiniteList
      hasError={hasError}
      onFinishedScroll={onFinishedScroll}
      isLoading={isLoading}
      renderItem={(item) => <Card {...item} />}
      items={items}
      loadingItemsNumber={NUMBER_OF_LOADING_CARDS}
      EmptyListComponent={<EmptyListInfo />}
      ErrorComponent={<ErrorComponent />}
      cardHeight={CARD_HEIGHT}
      maxHeight={LIST_MAX_HEIGHT}
    />
  );
};

export default DocumentsList;
