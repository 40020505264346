import PropTypes from "prop-types";
import { parkingStatesMapping as animationStates } from "../components/ParkedCar";

export const parkingFinishedPropTypes = {
  id: PropTypes.number,
  vehicleColor: PropTypes.string,
  streetName: PropTypes.string,
  explorationName: PropTypes.string,
  vehicleLicensePlate: PropTypes.string,
  vehicleBrand: PropTypes.string,
  amount: PropTypes.number,
  benefitColor: PropTypes.string,
  benefitName: PropTypes.string,
  animationState: PropTypes.string,
  endingDate: PropTypes.string,
  isLoading: PropTypes.bool,
  scheduleExtraInfo: PropTypes.shape({
    isRunning: PropTypes.bool,
    remainingDuration: PropTypes.number,
    currentDuration: PropTypes.number,
    nextPause: PropTypes.string,
    nextStart: PropTypes.string,
  }),
};

export const parkingFinishedDefaultPropTypes = {
  id: null,
  amount: 0,
  vehicleColor: "",
  streetName: "",
  explorationName: "",
  vehicleLicensePlate: "",
  vehicleBrand: "",
  benefitName: "",
  benefitColor: null,
  animationState: animationStates.parked,
  endingDate: "",
  isLoading: false,
  scheduleExtraInfo: null,
};
