import { usePermitHoldersListContext } from "../Provider";

const useUnapprovedList = () => {
  const {
    unapprovedList: { items, isRequested, isLoading, hasError },
  } = usePermitHoldersListContext();

  return {
    isEmptyListDisplayed: isRequested ? items.length === 0 : undefined,
    isUnapprovedListLoading: isLoading,
    hasUnapprovedListError: hasError,
  };
};

export default useUnapprovedList;
