import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ParkedCar, { parkingStatesMapping } from "../ParkedCar";

const Container = styled.div`
  @media (min-width: 1300px) {
    margin-left: 140px;
    margin-top: 50px;
  }
`;

const ParkedCarContainer = styled.div`
  position: absolute;
  top: 35px;
  left: 30px;
  z-index: 1000;

  @media (max-width: 1300px) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  padding: 20px;
  background: ${({ theme }) => theme.box.color};
  position: relative;

  @media (min-width: 1300px) {
    padding: 25px 25px 25px 140px;
  }
`;

const ParkedCarBox = ({ state, vehicleColor, children, className, displayImage }) => {
  return (
    <Container className={className}>
      <ParkedCarContainer>
        {displayImage && <ParkedCar width={250} color={vehicleColor} state={state} />}
      </ParkedCarContainer>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};

ParkedCarBox.propTypes = {
  state: PropTypes.oneOf([
    parkingStatesMapping.beforeParking,
    parkingStatesMapping.parked,
    parkingStatesMapping.afterParking,
  ]),
  vehicleColor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  displayImage: PropTypes.bool,
};

ParkedCarBox.defaultProps = {
  state: null,
  vehicleColor: "",
  children: "",
  className: "",
  displayImage: true,
};

export default ParkedCarBox;
