import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Input, Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import useDebounce from "../../../../../../hooks/useDebounce";

const Container = styled.div`
  position: relative;
  width: 120px;
  display: flex;
  align-items: center;

  .notification-time-input {
    border-radius: 10px;
    width: 120px;
    box-sizing: border-box;
    padding: 3px 5px;
    font-size: 14px;
    border-width: 2px;

    ::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
    appearance: textfield;
  }

  .notification-time-text {
    position: absolute;
    right: 8px;
    color: ${({ theme }) => theme.formField.color.primary.border};
  }
`;

const inputDelay = 500;

const NotificationTimeInput = ({ className, onChange, value }) => {
  const { t } = useTranslation();
  const [inputMinutes, setInputMinutes] = useState(value);
  const debouncedInputMinutes = useDebounce(inputMinutes, inputDelay);

  useEffect(() => {
    if (!Number.isNaN(debouncedInputMinutes) && debouncedInputMinutes > 0) {
      onChange(parseInt(debouncedInputMinutes, 10));
    }
  }, [debouncedInputMinutes, onChange]);

  useEffect(() => {
    setInputMinutes(value);
  }, [value]);

  const onInputChange = useCallback((event) => {
    setInputMinutes(event.target.value);
  }, []);

  return (
    <Container className={className}>
      <Input
        className="notification-time-input"
        name="notificationTime"
        onChange={onInputChange}
        type="number"
        value={inputMinutes}
      />
      <Text className="notification-time-text" variant="body10">
        {t("1553") /* min */}
      </Text>
    </Container>
  );
};

NotificationTimeInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

NotificationTimeInput.defaultProps = {
  className: PropTypes.null,
};

export default NotificationTimeInput;
