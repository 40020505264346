// eslint-disable-next-line import/prefer-default-export
export const formatDistance = (distance) => {
  if (!distance && distance !== 0) {
    return `- m`;
  }

  if (distance > 1000) {
    return `${Math.round(distance / 1000)} km`;
  }

  return `${Math.round(distance)} m`;
};
