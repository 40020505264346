import { driverPublic, driverPublicColors as colors } from "iparque-components";
import mapTheme from "./maps/public";

const publicTheme = {
  ...driverPublic,
  color: colors,
  map: mapTheme,
  background: {
    color: {
      primary: colors.primary,
      secondary: colors.lightest,
      tertiary: colors.tertiary,
      quaternary: colors.secondary,
      highlightBox: colors.light,
      background: colors.lightest,
    },
  },
  icon: {
    color: colors.darkest,
  },
  scroll: {
    trackColor: colors.light,
    thumbColor: colors.tertiary,
  },
  loadingSkeleton: {
    color: colors.light,
    highlightColor: colors.lightest,
  },
  card: {
    outlined: {
      primary: {
        borderColor: colors.darkest,
        hoverBorderColor: colors.tertiary,
        selectedBorderColor: colors.tertiary,
      },
    },
    dense: {
      primary: {
        backgroundColor: "#EFEFEF",
        iconColor: colors.darkest,
        positiveIconColor: "#198D6E",
      },
    },
  },
  rentals: {
    confirmationCard: {
      colors: {
        background: "#EFEFEF",
      },
    },
  },
  driversIdentifications: {
    message: {
      colors: {
        success: "#E8FFF0",
        error: "#F5E2E1",
      },
    },
  },
  citiesCard: {
    borderColor: colors.light,
    hoverBorderColor: colors.medium,
    selectedBorderColor: colors.medium,
  },
};

export default publicTheme;
