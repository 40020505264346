import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Text } from "iparque-components";
import styled from "styled-components";

const StyledTabs = styled.div`
  display: flex;
`;

const StyledTab = styled.div`
  flex: 1;
  min-width: 150px;
  text-transform: uppercase;
  padding: 0 10px 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;

  ${({ $isActive, theme }) =>
    $isActive && `border-bottom: 2px solid ${theme.typography.text.color.primary};`}
`;

const TabPanel = styled.div`
  margin-top: 20px;
`;

const Tabs = ({ tabs, activeTab, className }) => {
  const [localActiveTab, setLocalActiveTab] = useState(null);

  useEffect(() => {
    setLocalActiveTab(activeTab || tabs[0].id);
  }, [activeTab]);

  const handleSetActiveTab = useCallback((newActiveTab) => {
    setLocalActiveTab(newActiveTab);
  }, []);

  const activeTabPanel = useMemo(() => {
    for (let index = 0; index < tabs.length; index += 1) {
      const tab = tabs[index];

      if (tab.id === localActiveTab) {
        return <TabPanel key={tab.id}>{tab.component}</TabPanel>;
      }
    }

    return null;
  }, [tabs, localActiveTab]);

  return (
    <div className={className}>
      <StyledTabs>
        {tabs.map((tab) => (
          <StyledTab
            $isActive={tab.id === localActiveTab}
            key={tab.id}
            onClick={() => handleSetActiveTab(tab.id)}
          >
            <Text className="tab-label" align="center" variant="body9">
              {tab.label}
            </Text>
          </StyledTab>
        ))}
      </StyledTabs>
      {activeTabPanel}
    </div>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  activeTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      component: PropTypes.node.isRequired,
    })
  ).isRequired,
};

Tabs.defaultProps = {
  className: null,
  activeTab: null,
};

export default Tabs;
