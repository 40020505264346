import React, { useEffect } from "react";
import Axios from "axios";
import { useLocation } from "react-router-dom";
import { useAuth } from "components/AuthProvider";
import { useAppContext } from "components/AppProvider";
import { breakpointsMapping, smallDevicesBreakpoints } from "utils/breakpoints";
import NoticesPayment from "./Payment";
import MediumDevicesNoticesPayment from "./MediumDevicesPayment";
import SmallDevicesNoticesPayment from "./SmallDevicesPayment";
import { actionsMapping } from "./store/actions";
import { usePaymentContext } from "./store/Store";
import { getPaymentMethods } from "./controller";
import useNotices from "../hooks/useNotices";

const NoticesPaymentBasedOnDevice = () => {
  const { defaultEntityId } = useAuth();
  const { dispatch } = usePaymentContext();
  const { breakpoint } = useAppContext();
  const { backToList } = useNotices();
  const { state: noticesToPay } = useLocation();

  useEffect(() => {
    (async () => {
      const cancelToken = Axios.CancelToken.source();

      if (!noticesToPay) {
        backToList();
        return null;
      }

      dispatch({ type: actionsMapping.addNoticesToPay, payload: noticesToPay ?? [] });

      const paymentMethods = await getPaymentMethods(
        defaultEntityId,
        {},
        { cancelToken }
      );

      dispatch({ type: actionsMapping.addPaymentMethods, payload: paymentMethods ?? [] });

      return cancelToken.cancel();
    })();
  }, []);

  const chooseViewBasedOnDevice = () => {
    // Prevent access to notice payment details without data
    if (!noticesToPay) {
      return null;
    }

    if (smallDevicesBreakpoints.includes(breakpoint)) {
      return <SmallDevicesNoticesPayment />;
    }

    if (breakpointsMapping.noBreakpoint === breakpoint) {
      return <MediumDevicesNoticesPayment />;
    }

    return <NoticesPayment />;
  };

  return <>{chooseViewBasedOnDevice()}</>;
};

export default NoticesPaymentBasedOnDevice;
