import Button from "components/buttons/Button";
import FlexBox from "components/FlexBox";
import SmallDevicesContentHeader from "domain/private/components/smallDevices/ContentHeader";
import { Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Requirements from "../components/Requirements";
import ApprovedList from "../components/smallMediumDevices/ApprovedList";
import UnapprovedList from "../components/smallMediumDevices/UnapprovedList";
import useApprovedList from "../hooks/useApprovedList";
import useOnClickCreate from "../hooks/useOnClickCreate";
import useRequirementsUtils from "../hooks/useRequirementsUtils";
import useUnapprovedList from "../hooks/useUnapprovedList";

const PermitHoldersListSmallDevicesPage = () => {
  const { t } = useTranslation();
  const { shouldDisplayRequirements } = useRequirementsUtils();
  const { shouldDisplayApprovedList } = useApprovedList();
  const {
    isEmptyListDisplayed,
    isUnapprovedListLoading,
    hasUnapprovedListError,
  } = useUnapprovedList();
  const { onClickNewPermitHolder } = useOnClickCreate();

  return (
    <>
      <SmallDevicesContentHeader
        topTitle={t("10416") /* Precisas de estacionar? */}
        bottomTitle={t("10574") /* Não te preocupes! Podes criar aqui o teu dístico */}
      />
      {shouldDisplayRequirements ? (
        <Requirements />
      ) : (
        <>
          {(isEmptyListDisplayed === false ||
            isUnapprovedListLoading ||
            hasUnapprovedListError) && (
            <>
              <FlexBox justifyContent="center" className="mt-30">
                <Button
                  className={
                    isUnapprovedListLoading || hasUnapprovedListError ? "hidden" : ""
                  }
                  onClick={onClickNewPermitHolder}
                >
                  {t("10577") /* Novo dístico */}
                </Button>
              </FlexBox>
              <StyledText variant="body9" className="uppercase mt-30 mb-10">
                {t("10418") /* Processos por aprovar */}
              </StyledText>
            </>
          )}
          <UnapprovedList />
          {shouldDisplayApprovedList && (
            <>
              <Text variant="body9" className="uppercase mt-30 mb-10">
                {t("10578") /* Dísticos aprovados */}
              </Text>
              <ApprovedList />
            </>
          )}
        </>
      )}
    </>
  );
};

export default PermitHoldersListSmallDevicesPage;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.permitHolders.unapprovedBoxCorner};
`;
