import Button from "components/buttons/Button";
import { Heading, Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { convertDateTimeToReadableDate, formatDate } from "../../../utils/dateTime";
import { formatMoney } from "../../../utils/money";
import { getTextColorByState } from "../../../utils/notices";
import ListCard from "../components/cardList/Card";
import CardCell from "../components/cardList/Cell";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import useNotices from "./hooks/useNotices";
import { noticePropTypes } from "./propTypes";

const StyledText = styled(Text)`
  color: ${({ textColor }) => textColor};
`;

const ExtraInformationContainer = styled.div`
  display: flex;
  width: 100%;
`;

const NoticeCard = (props) => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();
  const { pay } = useNotices();
  const {
    id,
    number,
    licensePlate,
    vehicleColorId,
    dateTime,
    paymentDateTime,
    amount,
    stateName,
    stateId,
    zoneName,
    streetName,
    situation,
    infraction,
    payable,
    paymentScenario,
  } = props;

  const extraInfo = (
    <ExtraInformationContainer>
      <CardCell size="lg" />
      <CardCell size="sm" />
      <CardCell size="sm" />
      <CardCell flex={3.5}>
        <Text variant="body4">{t("456") /* Norma */}</Text>
        <Text variant="body3">{infraction}</Text>
      </CardCell>
      <CardCell size="md">
        <Text variant="body4">{t("7255") /* Zona */}</Text>
        <Text variant="body3">{zoneName}</Text>
      </CardCell>
      <CardCell size="sm" />
    </ExtraInformationContainer>
  );

  return (
    <ListCard extraInfo={extraInfo}>
      <CardCell size="lg">
        <Text color={payable ? "danger" : "primary"} variant="body2">
          {situation}
        </Text>
        <Text variant="body3">{streetName}</Text>
      </CardCell>
      <CardCell size="sm">
        <StyledText textColor={backofficeTheme.carColor[vehicleColorId]} variant="body2">
          {licensePlate}
        </StyledText>
      </CardCell>
      <CardCell size="sm">
        <Text variant="body4">{t("4713") /* Número */}</Text>
        <Text variant="body3">{number}</Text>
      </CardCell>
      <CardCell size="md">
        <Text variant="body4">{t("8284") /* Registado */}</Text>
        <Text variant="body3">{convertDateTimeToReadableDate(dateTime)}</Text>
      </CardCell>
      <CardCell size="lg">
        <Text variant="body4">{t("1482") /* Data limite de pagamento */}</Text>
        <Text variant="body3">{formatDate(paymentDateTime)}</Text>
      </CardCell>
      <CardCell size="md">
        <Heading variant="h5" color="tertiary">
          {formatMoney(amount, { centsFormat: false })}
        </Heading>
      </CardCell>
      {!paymentScenario && (
        <CardCell size="sm">
          {payable ? (
            <Button onClick={() => pay(id, [props])} size="sm">
              {t("4537") /* Pagar */}
            </Button>
          ) : (
            <Text color={getTextColorByState(stateId)} variant="body2">
              {stateName}
            </Text>
          )}
        </CardCell>
      )}
    </ListCard>
  );
};

NoticeCard.propTypes = noticePropTypes;

export default NoticeCard;
