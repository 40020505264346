import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const SimpleColorPicker = ({ colorIds, onChange, selectedColorId, className }) => {
  return (
    <Container className={className}>
      {colorIds.map((colorId) => (
        <Color
          key={colorId}
          isSelected={selectedColorId === colorId}
          colorId={colorId}
          onClick={() => onChange(colorId)}
          sele
        />
      ))}
    </Container>
  );
};

export default SimpleColorPicker;

SimpleColorPicker.defaultProps = {
  selectedColorId: null,
  className: null,
};

SimpleColorPicker.propTypes = {
  colorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedColorId: PropTypes.number,
  className: PropTypes.string,
};

const selectedColorStyle = css`
  width: 50px;
  height: 40px;
  border-width: 1px;
`;

const Container = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
`;

const Color = styled.div`
  width: 40px;
  height: 30px;
  border-width: 1px 0;
  border-style: solid;
  box-sizing: border-box;
  cursor: pointer;

  ${({ theme, colorId }) => {
    const contrastColor = theme.colorPicker?.contrastColor?.[colorId];
    const carColor = theme.carColor[colorId];

    return css`
      border-color: ${contrastColor?.border || carColor};
      background: ${contrastColor?.background || carColor};
    `;
  }}

  ${({ isSelected }) => isSelected && selectedColorStyle}

  &:hover {
    ${selectedColorStyle}
  }
`;
