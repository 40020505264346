import React from "react";
import Button from "./Button";
import useOperations from "../../hooks/useOperations";

const Operations = () => {
  const operations = useOperations();

  return (
    <>
      {operations.map((operation) => (
        <Button key={operation.id} color={operation.color} onClick={operation.action}>
          {operation.label}
        </Button>
      ))}
    </>
  );
};

export default Operations;
