import i18n from "i18n";
import ClientsDataSource from "lib/clients/iParque/dataSources/clients";
import { errorMessage } from "utils/userMessages";

const clientsDataSource = new ClientsDataSource();

export const getPartyTypes = async (entityId, params = {}, useCache = false) => {
  try {
    const response = await clientsDataSource.getPartyTypes(entityId, params, useCache);

    return {
      items: response.items,
      totalItems: response.totalItems,
    };
  } catch (error) {
    errorMessage(
      error,
      i18n.t("10235") /* Ocorreu um erro ao obter os tipos de pessoa */
    );

    return { hasError: true, items: [] };
  }
};

export const createClient = async (entityId, data, { autoCloseError = true } = {}) => {
  try {
    const response = await clientsDataSource.create(entityId, data);

    return { clientId: response.id };
  } catch (error) {
    errorMessage(
      error,
      i18n.t("10287") /* Ocorreu um erro ao guardar os dados pessoais */,
      null,
      { autoClose: autoCloseError }
    );

    return null;
  }
};

export const updateClient = async (
  entityId,
  clientId,
  data,
  { autoCloseError = true, t = i18n.t, error: errorLabel } = {}
) => {
  try {
    const result = await clientsDataSource.update(entityId, clientId, data);

    return result;
  } catch (error) {
    errorMessage(
      error,
      errorLabel || t("10452") /* Ocorreu um erro ao atualizar os dados pessoais */,
      null,
      { autoClose: autoCloseError }
    );

    return false;
  }
};

export const getGenders = async (entityId, params = {}, useCache = false) => {
  try {
    const response = await clientsDataSource.getGenders(entityId, params, useCache);

    return {
      items: response.items,
      totalItems: response.totalItems,
    };
  } catch (error) {
    errorMessage(
      error,
      i18n.t("10341") /* Ocorreu um erro ao obter os géneros das pessoas */
    );

    return { hasError: true, items: [] };
  }
};

export const createIdentificationDocument = async (
  entityId,
  clientId,
  data,
  { autoCloseError = true } = {},
  { t = i18n.t } = {}
) => {
  try {
    const response = await clientsDataSource.createIdentificationDocument(
      entityId,
      clientId,
      data
    );

    return { clientId: response.id };
  } catch (error) {
    errorMessage(
      error,
      t("10731") /* Ocorreu um erro ao guardar os documentos de identificação */,
      null,
      { autoClose: autoCloseError }
    );

    return null;
  }
};
