import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { paymentMethodsMapping, paymentTypesMapping } from "utils/payment";
import PaymentMethodBox from "./PaymentBox";
import PaymentMethodIcon from "./PaymentIcon";
import MbWayPaymentDetails from "./MbWayPaymentDetails";
import { paymentBoxSizes } from "./helper";

const MbWayPaymentBox = ({
  title,
  shortMessage,
  longMessage,
  onClick,
  onGeneratePayment,
  amount,
  displayAmount,
  itemTypeId,
  onSuccess,
  size,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);

  const onConfirmPayment = useCallback(
    async (_, params) => {
      const paymentId = await onGeneratePayment(params);

      return paymentId;
    },
    [onGeneratePayment]
  );

  const onPaymentStarted = useCallback(() => setIsPaymentInProgress(true), []);

  const onPaymentEnded = useCallback(() => setIsPaymentInProgress(false), []);

  const onCloseDetails = useCallback(() => setIsOpen(false), []);

  return (
    <PaymentMethodBox
      paymentMethodId={paymentMethodsMapping.mbway.id}
      icon={
        <PaymentMethodIcon size={size} paymentMethodId={paymentMethodsMapping.mbway.id} />
      }
      shortMessage={shortMessage}
      title={title}
      onClick={onClick}
      displayExtraInfo={isOpen}
      setDisplayExtraInfo={setIsOpen}
      isLoading={isPaymentInProgress}
      amount={amount}
      displayAmount={displayAmount}
      extraInfo={
        <MbWayPaymentDetails
          isOpen
          id={paymentMethodsMapping.mbway.id}
          amount={amount}
          itemTypeId={itemTypeId}
          isPaymentInProgress={isPaymentInProgress}
          longMessage={longMessage}
          onConfirmPayment={onConfirmPayment}
          onSuccess={onSuccess}
          onPaymentStarted={onPaymentStarted}
          onPaymentEnded={onPaymentEnded}
          onCloseDetails={onCloseDetails}
        />
      }
      size={size}
    />
  );
};

MbWayPaymentBox.propTypes = {
  title: PropTypes.string.isRequired,
  shortMessage: PropTypes.string.isRequired,
  longMessage: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onGeneratePayment: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  amount: PropTypes.number,
  displayAmount: PropTypes.bool,
  itemTypeId: PropTypes.oneOf(Object.values(paymentTypesMapping)).isRequired,
  size: PropTypes.oneOf(Object.values(paymentBoxSizes)),
};

MbWayPaymentBox.defaultProps = {
  amount: null,
  displayAmount: false,
  onSuccess: () => {},
  onClick: null,
  size: paymentBoxSizes.large,
};

export default MbWayPaymentBox;
