import InfiniteList from "components/InfiniteList";
import PropTypes from "prop-types";
import React from "react";
import { LIST_MAX_HEIGHT } from "../../configs";
import useHistory from "../../hooks/useHistory";
import EmptyListInfo from "./EmptyListInfo";
import ErrorComponent from "./ErrorComponent";

const NUMBER_OF_LOADING_CARDS = 10;
const CARD_HEIGHT = 95;

const HistoryList = ({ Card }) => {
  const { items, isLoading, onFinishedScroll, hasError } = useHistory();

  return (
    <InfiniteList
      hasError={hasError}
      onFinishedScroll={onFinishedScroll}
      isLoading={isLoading}
      renderItem={(item) => <Card {...item} />}
      items={items}
      loadingItemsNumber={NUMBER_OF_LOADING_CARDS}
      EmptyListComponent={<EmptyListInfo />}
      ErrorComponent={<ErrorComponent />}
      cardHeight={CARD_HEIGHT}
      maxHeight={LIST_MAX_HEIGHT}
    />
  );
};

export default HistoryList;

HistoryList.propTypes = {
  Card: PropTypes.func.isRequired,
};
