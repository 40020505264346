import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import { usePendingOperationsContext } from "components/pendingOperations/Provider";
import ContractsPopup from "domain/private/components/ContractsPopUp";
import { saveEntityContractAcceptance } from "domain/private/controllers/cities";
import DriversDataSource from "lib/clients/iParque/dataSources/driversDataSource";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertSecondsToMilliseconds } from "utils/dateTime";

const contractDelay = convertSecondsToMilliseconds(0.5);
const driverDataSource = new DriversDataSource();

const EntityPendingContracts = () => {
  const { t } = useTranslation();
  const { setIsLoading } = useAppContext();
  const { removeDefaultEntity, driverHash } = useAuth();
  const [isAwaitingToNextContract, setIsAwaitingToNextContract] = useState(false);
  const {
    pendingContracts,
    acceptContract,
    removeContracts,
  } = usePendingOperationsContext();

  const { entityId, contracts: unAcceptedContracts } = pendingContracts;

  const currentContract = unAcceptedContracts?.[0];

  const onCancel = useCallback(() => {
    try {
      driverDataSource.deleteDefaultEntity(driverHash);
      // eslint-disable-next-line no-empty
    } catch {}

    removeContracts();
    removeDefaultEntity();
  }, [removeContracts]);

  const handleAcceptContract = useCallback(
    async (contractId) => {
      setIsLoading(true);

      const result = await saveEntityContractAcceptance(driverHash, entityId, {
        contractId,
      });

      setIsLoading(false);

      if (!result) {
        onCancel();
        return;
      }

      acceptContract(contractId);
      setIsAwaitingToNextContract(true);

      setTimeout(() => setIsAwaitingToNextContract(false), contractDelay);
    },
    [unAcceptedContracts]
  );

  return (
    <>
      {currentContract && !isAwaitingToNextContract && (
        <ContractsPopup
          id={currentContract.id}
          title={currentContract.title}
          description={currentContract.description}
          onClose={onCancel}
          handleAccept={handleAcceptContract}
          acceptButtonText={t("6387") /* Aceitar */}
          rejectButtonText={t("1196") /* Rejeitar */}
        />
      )}
    </>
  );
};

export default EntityPendingContracts;
