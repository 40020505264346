import { getTokens } from "utils/auth";
import AbstractDataSource from "./abstractDataSource";

export default class NotificationsDataSource extends AbstractDataSource {
  async getAll(entityId, authHash, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/${authHash}/notifications`,
      params,
      { Authorization: getTokens().token }
    );
  }

  async markAsRead(entityId, authHash, notificationId, params = {}) {
    const route = params.markAllAsRead
      ? `v1/entities/${entityId}/drivers/${authHash}/notifications/visualization`
      : `v1/entities/${entityId}/drivers/${authHash}/notifications/${notificationId}/visualization`;

    return this.iParqueClient.POST(route, params, { Authorization: getTokens().token });
  }
}
