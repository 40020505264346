import React from "react";
import Store from "./store/Store";
import BalanceChargeBasedOnDevice from "./BalanceChargeBasedOnDevice";

const BalanceChargePage = () => {
  return (
    <Store>
      <BalanceChargeBasedOnDevice />
    </Store>
  );
};

export default BalanceChargePage;
