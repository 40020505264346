import React from "react";
import TabTitle from "../../../components/Title";
import OperationsButtons from "../../../components/vehicles/SaveButton";
import StatusComponent from "../../../components/vehicles/StatusComponent";
import VehiclesPicker from "../../../components/vehicles/VehiclesPicker";
import { tabsIdsMap } from "../../../helper";
import useVehiclesTab from "../../../hooks/useVehiclesTab";
import { usePermitHoldersDetailsContext } from "../../../Provider";

const VehiclesTab = () => {
  useVehiclesTab();
  const {
    vehiclesTab: { addedVehicles, isLoading },
  } = usePermitHoldersDetailsContext();

  return (
    <>
      <TabTitle tabId={tabsIdsMap.vehicles} />
      <StatusComponent />
      {(addedVehicles.length > 0 || isLoading) && (
        <>
          <VehiclesPicker />
          <OperationsButtons />
        </>
      )}
    </>
  );
};

export default VehiclesTab;
