import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { breakpoints } from "utils/breakpoints";
import { getCurrentDateTimeInServerFormat } from "utils/dateTime";
import { useStepContext } from "../../components/Step";
import { usePermitHoldersContext } from "../../Provider";
import { actionTypes } from "../../store/actions";
import PermitTypeCard, { CARDS_VERTICAL_MARGIN_BETWEEN } from "./Card";

const PermitTypeStep = () => {
  const { dispatch, selectedPermitType } = usePermitHoldersContext();
  const { permitTypes } = useStepContext();

  const onChooseCity = useCallback(
    (permitTypeId) =>
      dispatch([
        {
          type: actionTypes.SET_SELECTED_PERMIT_TYPE,
          payload: {
            permitType: permitTypes.find((permitType) => permitType.id === permitTypeId),
            updatedDateTime: getCurrentDateTimeInServerFormat(),
          },
        },
        ...(selectedPermitType?.permitType.id !== permitTypeId
          ? [
              {
                type: actionTypes.REMOVE_DOCUMENTS,
              },
            ]
          : []),
      ]),
    [permitTypes, selectedPermitType]
  );

  const Components = useMemo(() => {
    const indexToSplit = Math.ceil(permitTypes.length / 2);
    const processedPermitTypes = [...permitTypes];

    return {
      leftColumn: processedPermitTypes
        .splice(0, indexToSplit)
        .map((permitType) => (
          <PermitTypeCard onClick={onChooseCity} key={permitType.id} {...permitType} />
        )),
      rightColumn: processedPermitTypes.map((permitType) => (
        <PermitTypeCard onClick={onChooseCity} key={permitType.id} {...permitType} />
      )),
    };
  }, [permitTypes, onChooseCity]);

  return (
    <Container>
      <ColumnContainer>{Components.leftColumn}</ColumnContainer>
      {Components.rightColumn.length > 0 ? (
        <ColumnContainer>{Components.rightColumn}</ColumnContainer>
      ) : null}
    </Container>
  );
};

export default PermitTypeStep;

const smallVariantContainerStyle = css`
  flex-direction: column;

  > div:not(:first-of-type) {
    margin-left: 0;
    margin-top: ${CARDS_VERTICAL_MARGIN_BETWEEN}px;
  }
`;

const Container = styled.div`
  display: flex;
  ${smallVariantContainerStyle};

  @media (min-width: ${breakpoints.xl}), (max-width: ${breakpoints.lg}) {
    flex-direction: row;

    > div:not(:first-of-type) {
      margin-left: 30px;
      margin-top: 0;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    ${smallVariantContainerStyle};
  }
`;

const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
