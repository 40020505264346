import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Text } from "iparque-components";
import { useAuth } from "../../../components/AuthProvider";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/power.svg";
import { breakpoints } from "../../../utils/breakpoints";
import { useBackofficeThemeContext } from "./ThemeProvider";
import { themesMapping } from "../../../themes/theme";

const LogoutContainer = styled.div`
  bottom: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.lg}) {
    justify-content: center;
  }
`;

const StyledLogoutIcon = styled(LogoutIcon)`
  width: 25px;
  fill: ${({ theme, color }) => theme.typography.text.color[color]};
  padding-right: 10px;
`;

const Logout = ({ lightBackground }) => {
  const { backofficeThemeName } = useBackofficeThemeContext();
  const { removeSession } = useAuth();
  const { t } = useTranslation();

  const color =
    backofficeThemeName === themesMapping.normal && lightBackground
      ? `primary`
      : `secondary`;

  return (
    <LogoutContainer onClick={removeSession}>
      <StyledLogoutIcon color={color} />
      <Text color={color} variant="body8">
        {t("2565") /* Terminar Sessão */}
      </Text>
    </LogoutContainer>
  );
};

Logout.propTypes = {
  lightBackground: PropTypes.bool,
};

Logout.defaultProps = {
  lightBackground: false,
};

export default Logout;
