import { personalDataDefaultValues } from "../helper";
import documentsTabActions from "./documentsTabActions";
import historyTabActions from "./historyTabActions";
import locationTabActions from "./locationTabActions";
import subscriptionsTabActions from "./subscriptionsTabActions";
import vehiclesTabActions from "./vehiclesTabActions";

export const actionTypes = {
  SET_INITIAL_REQUEST_STATUS: "SET_INITIAL_REQUEST_STATUS",
  SET_IS_EDITABLE: "SET_IS_EDITABLE",
  SET_PERSONAL_DATA: "SET_PERSONAL_DATA",
  SET_STATIC_INFO: "SET_STATIC_INFO",
  SET_STATE: "SET_STATE",
  SET_WARNINGS: "SET_WARNINGS",
  DOCUMENTS_TAB: {
    SET_LIST: "SET_DOCUMENTS_TAB_LIST",
  },
  VEHICLES_TAB: {
    SET_IS_LOADING: "VEHICLES_TAB_SET_IS_LOADING",
    SET_HAS_ERROR: "VEHICLES_TAB_SET_HAS_ERROR",
    SET_VEHICLE_REGIMES: "VEHICLES_TAB_SET_VEHICLE_REGIMES",
    SET_ADDED_VEHICLES: "VEHICLES_TAB_SET_ADDED_VEHICLES",
    ADD_ADDED_VEHICLE: "VEHICLES_TAB_ADD_ADDED_VEHICLE",
    FLAG_ADDED_VEHICLE: "VEHICLES_TAB_FLAG_ADDED_VEHICLE",
    EDIT_ADDED_VEHICLE: "VEHICLES_TAB_EDIT_ADDED_VEHICLE",
    MAKE_ADDED_VEHICLE_EDITABLE: "VEHICLES_TAB_MAKE_ADDED_VEHICLE_EDITABLE",
    REMOVE_ADDED_VEHICLE: "VEHICLES_TAB_REMOVE_ADDED_VEHICLE",
    SET_VEHICLES_OPTIONS: "VEHICLES_TAB_SET_VEHICLES_OPTIONS",
    SET_INITIAL_ADDED_VEHICLES: "VEHICLES_TAB_SET_INITIAL_ADDED_VEHICLES",
    SET_ACTIVE_SUBSCRIPTIONS: "VEHICLES_TAB_SET_ACTIVE_SUBSCRIPTIONS",
    RESET_CHANGES: "VEHICLES_TAB_RESET_CHANGES",
  },
  LOCATIONS_TAB: {
    IS_LOADING: "LOCATIONS_TAB_IS_LOADING",
    HAS_ERROR: "LOCATIONS_TAB_HAS_ERROR",
    LOCATIONS: "LOCATIONS_TAB_LOCATIONS",
    ZONES: "LOCATIONS_TAB_ZONES",
  },
  SUBSCRIPTIONS_TAB: {
    SET_LIST: "SET_SUBSCRIPTIONS_TAB_LIST",
    SET_PAYMENT_METHODS: "SUBSCRIPTIONS_TAB_SET_PAYMENT_METHODS",
  },
  HISTORY_TAB: {
    SET_LIST: "SET_HISTORY_TAB_LIST",
  },
};

const setInitialRequestStatus = (state, initialRequestStatus) => ({
  ...state,
  initialRequestStatus,
});

const setIsEditable = (state, isEditable) => ({ ...state, isEditable });

const setPersonalData = (state, personalData) => ({
  ...state,
  personalData: { ...personalDataDefaultValues, ...personalData },
});

const setStaticInfo = (state, staticInfo) => ({
  ...state,
  ...staticInfo,
});

const setState = (state, processState) => ({
  ...state,
  state: processState,
});

const setWarnings = (state, warnings) => ({
  ...state,
  warnings,
});

const actions = {
  [actionTypes.SET_INITIAL_REQUEST_STATUS]: setInitialRequestStatus,
  [actionTypes.SET_IS_EDITABLE]: setIsEditable,
  [actionTypes.SET_PERSONAL_DATA]: setPersonalData,
  [actionTypes.SET_STATIC_INFO]: setStaticInfo,
  [actionTypes.SET_STATE]: setState,
  [actionTypes.SET_WARNINGS]: setWarnings,
  [actionTypes.VEHICLES_TAB.SET_IS_LOADING]: vehiclesTabActions.setIsLoading,
  [actionTypes.VEHICLES_TAB.SET_HAS_ERROR]: vehiclesTabActions.setHasError,
  [actionTypes.VEHICLES_TAB.SET_VEHICLE_REGIMES]: vehiclesTabActions.setVehicleRegimes,
  [actionTypes.VEHICLES_TAB.SET_ACTIVE_SUBSCRIPTIONS]:
    vehiclesTabActions.setActiveSubscriptions,
  [actionTypes.VEHICLES_TAB.SET_ADDED_VEHICLES]: vehiclesTabActions.setAddedVehicles,
  [actionTypes.VEHICLES_TAB.SET_INITIAL_ADDED_VEHICLES]:
    vehiclesTabActions.setInitialAddedVehicles,
  [actionTypes.VEHICLES_TAB.ADD_ADDED_VEHICLE]: vehiclesTabActions.addAddedVehicle,
  [actionTypes.VEHICLES_TAB.FLAG_ADDED_VEHICLE]: vehiclesTabActions.flagAddedVehicle,
  [actionTypes.VEHICLES_TAB.EDIT_ADDED_VEHICLE]: vehiclesTabActions.editAddedVehicle,
  [actionTypes.VEHICLES_TAB.MAKE_ADDED_VEHICLE_EDITABLE]:
    vehiclesTabActions.makeAddedVehicleEditable,
  [actionTypes.VEHICLES_TAB.REMOVE_ADDED_VEHICLE]: vehiclesTabActions.removeAddedVehicle,
  [actionTypes.VEHICLES_TAB.SET_VEHICLES_OPTIONS]: vehiclesTabActions.setVehiclesOptions,
  [actionTypes.VEHICLES_TAB.RESET_CHANGES]: vehiclesTabActions.resetChanges,
  [actionTypes.LOCATIONS_TAB.IS_LOADING]: locationTabActions.setIsLoading,
  [actionTypes.LOCATIONS_TAB.HAS_ERROR]: locationTabActions.setHasError,
  [actionTypes.LOCATIONS_TAB.LOCATIONS]: locationTabActions.setLocations,
  [actionTypes.LOCATIONS_TAB.ZONES]: locationTabActions.setZones,
  [actionTypes.SUBSCRIPTIONS_TAB.SET_LIST]: subscriptionsTabActions.setList,
  [actionTypes.SUBSCRIPTIONS_TAB.SET_PAYMENT_METHODS]:
    subscriptionsTabActions.setPaymentMethods,
  [actionTypes.HISTORY_TAB.SET_LIST]: historyTabActions.setList,
  [actionTypes.DOCUMENTS_TAB.SET_LIST]: documentsTabActions.setList,
};

export default actions;
