import { getBalancePaymentMethod } from "utils/payment";

export const actionsMapping = {
  addPaymentMethods: "ADD_PAYMENT_METHODS",
  addNoticesToPay: "ADD_NOTICES_TO_PAY",
};

const actions = {
  [actionsMapping.addPaymentMethods]: (state, paymentMethods) => {
    return {
      ...state,
      paymentMethods: [getBalancePaymentMethod(), ...paymentMethods],
    };
  },
  [actionsMapping.addNoticesToPay]: (state, noticesToPay) => {
    return {
      ...state,
      noticesToPay,
    };
  },
};

export default actions;
