import GenericTabIdentification from "components/tabs/verticals/components/TabIdentification";
import PropTypes from "prop-types";
import React from "react";
import { tabsIdsMap } from "../helper";
import useTabsUtils from "../hooks/useTabsUtils";

const TabIdentification = ({ className, tabId }) => {
  const { getTabIcon, getTabLabel } = useTabsUtils();

  return (
    <GenericTabIdentification
      className={className}
      Icon={getTabIcon(tabId)}
      label={getTabLabel(tabId)}
    />
  );
};

export default TabIdentification;

TabIdentification.propTypes = {
  className: PropTypes.string,
  tabId: PropTypes.oneOf(Object.values(tabsIdsMap)).isRequired,
};

TabIdentification.defaultProps = {
  className: "",
};
