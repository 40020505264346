import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { parkingTypesMapping } from "utils/parking";

const useParkingTypeNames = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      [parkingTypesMapping.duration]: t("10723") /* Por duração */,
      [parkingTypesMapping.startAndStop]: "Start & Stop",
    }),
    [t]
  );
};

export default useParkingTypeNames;
