import Button from "components/buttons/Button";
import { Form, Formik } from "formik";
import { errorToast, Heading } from "iparque-components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { AppContext } from "../../components/AppProvider";
import TextField from "../../components/formFields/Text";
import PasswordRequirements from "../../components/PasswordRequirements";
import DriversDataSource from "../../lib/clients/iParque/dataSources/driversDataSource";
import { getButtonSize } from "../../utils/general";
import { routesMapping } from "../../utils/routes";
import { successMessage } from "../../utils/userMessages";
import LeftSideContainer from "./components/PageSplittedTemplate/LeftSideContainer";
import PageSplittedTemplate from "./components/PageSplittedTemplate/Template";
import { pageContexts } from "./configs";

const driversDataSource = new DriversDataSource();

const resetPassword = (values, args) => {
  const { setIsLoading, history, t } = args;
  driversDataSource
    .resetPassword(values)
    .then(() => {
      successMessage(t("8162") /* Password alterada com sucesso */);
      history.push(routesMapping.login);
    })
    .catch(() =>
      errorToast(
        t("7847") /* Erro! */,
        t("7877") /* Ocorreu um erro ao definir a nova senha. */
      )
    )
    .finally(() => setIsLoading(false));
};

let hash = null;

const PasswordDefinition = () => {
  const { t } = useTranslation();
  const { breakpoint, setIsLoading } = useContext(AppContext);
  const history = useHistory();
  const passwordRef = useRef();
  const [email, setEmail] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setEmail(urlParams.get("email"));
    hash = urlParams.get("hash");

    if (!hash || !urlParams.get("email")) {
      errorToast(t("7847") /* Erro! */, t("7878") /* O URL inserido não está correto. */);
    }
  }, []);

  return (
    <PageSplittedTemplate
      pageContext={pageContexts.passwordDefinition}
      title={t("7887") /* Definir Senha */}
      backUrl={routesMapping.login}
    >
      <LeftSideContainer className="mt-20">
        <Heading color="secondary" variant="h8">
          {t("7880") /* Por favor, indica a tua nova senha de acesso. */}
        </Heading>
        <Heading color="secondary" variant="h7">
          {email || ""}
        </Heading>
        <Formik
          initialValues={{
            password: "",
            passwordConfirmation: "",
          }}
          validationSchema={Yup.object({
            password: Yup.string()
              .required(t("10271") /* O campo é obrigatório */)
              .password(
                t(
                  "8154"
                ) /* O campo deverá preencher os requisitos apresentados abaixo */,
                passwordRef
              ),
            passwordConfirmation: Yup.string()
              .required(t("10271") /* O campo é obrigatório */)
              .oneOf(
                [Yup.ref("password"), null],
                t(
                  "7883"
                ) /* A confirmação da senha não corresponde com a senha introduzida */
              ),
          })}
          onSubmit={(values) => {
            setIsLoading(true);
            resetPassword({ ...values, email, hash }, { setIsLoading, history, t });
          }}
        >
          {({ handleChange, values }) => (
            <Form className="mt-20">
              <TextField
                type="password"
                id="password-field"
                name="password"
                label={t("7909") /* Nova Senha */}
                onChange={handleChange}
              />
              <TextField
                type="password"
                id="password-confirmation-field"
                name="passwordConfirmation"
                label={t("7884") /* Confirmar Senha */}
              />
              <PasswordRequirements
                ref={passwordRef}
                className="mt-20"
                password={values.password}
              />
              <Button
                type="submit"
                className="mt-50"
                size={getButtonSize(breakpoint)}
                fullWidth
              >
                {t("88") /* Guardar */}
              </Button>
            </Form>
          )}
        </Formik>
      </LeftSideContainer>
    </PageSplittedTemplate>
  );
};

export default PasswordDefinition;
