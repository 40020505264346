import { Heading } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import RequirementCard from "./Card";

const RequirementsList = ({ title, requirements }) => {
  return (
    <Container>
      <StyledHeading align="center" color="secondary" variant="h6">
        {title}
      </StyledHeading>
      <CardsContainer className="mt-40">
        {requirements.map((requirement, index) => (
          <RequirementCard key={requirement.label} index={index + 1} {...requirement} />
        ))}
      </CardsContainer>
    </Container>
  );
};

export default RequirementsList;

RequirementsList.propTypes = {
  title: PropTypes.string.isRequired,
  requirements: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      button: PropTypes.shape({
        label: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired,
      }),
    })
  ).isRequired,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 5vh 0;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  justify-content: center;
`;

const StyledHeading = styled(Heading)`
  font-size: 25px;

  @media (max-width: ${breakpoints.lg}) {
    font-size: 17px;
  }
`;

const CardsContainer = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  gap: 12px;
  margin-top: 40px;
  align-items: center;
`;
