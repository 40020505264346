import { useAuth } from "components/AuthProvider";
import useInfiniteList from "hooks/useInfiniteList";
import { useCallback, useEffect } from "react";
import {
  approvedPermitHolderStates,
  unapprovedPermitHolderStates,
} from "utils/permitHolders";
import { getPermitHolders } from "../controller";
import { usePermitHoldersListContext } from "../Provider";
import { actionTypes } from "../store/actions";

const useList = () => {
  const { dispatch } = usePermitHoldersListContext();
  const { driverHash, userId: driverId, defaultEntityId: entityId } = useAuth();

  const getUnapprovedPermitHolders = useCallback(
    async (params) => {
      const response = await getPermitHolders(entityId, driverHash, {
        ...params,
        stateIds: unapprovedPermitHolderStates,
        driverId,
      });

      return response;
    },
    [driverHash, entityId]
  );

  const getApprovedPermitHolders = useCallback(
    async (params) => {
      const response = await getPermitHolders(entityId, driverHash, {
        ...params,
        stateIds: approvedPermitHolderStates,
        driverId,
      });

      return response;
    },
    [driverHash, entityId]
  );

  const approved = useInfiniteList(getApprovedPermitHolders);

  const unapproved = useInfiniteList(getUnapprovedPermitHolders);

  useEffect(() => dispatch({ type: actionTypes.SET_APPROVED_LIST, payload: approved }), [
    approved.items,
  ]);

  useEffect(
    () => dispatch({ type: actionTypes.SET_UNAPPROVED_LIST, payload: unapproved }),
    [unapproved.items]
  );
};

export default useList;
