export const allowedFileFormats = ["jpg", "jpeg", "png", "pdf"];

export const fieldNames = {
  citizenCard: {
    countryId: "citizenCardCountryId",
    number: "citizenCardNumber",
    issuingEntity: "citizenCardIssuingEntity",
    issuingDate: "citizenCardIssuingDate",
  },
  drivingLicence: {
    countryId: "drivingLicenceCountryId",
    prefix: "drivingLicencePrefix",
    number: "drivingLicenceNumber",
    issuingEntity: "drivingLicenceIssuingEntity",
    issuingDate: "drivingLicenceIssuingDate",
    restrictions: "drivingLicenceRestrictions",
    categories: "categories",
  },
};
