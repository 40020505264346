import { useAuth } from "components/AuthProvider";
import useInfiniteList from "hooks/useInfiniteList";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getDocuments as getDocumentsRequest } from "../controller";
import { usePermitHoldersDetailsContext } from "../Provider";
import { actionTypes } from "../store/actions";

const useDocuments = () => {
  const { defaultEntityId: entityId, driverHash } = useAuth();
  const {
    id: permitHolderId,
    dispatch,
    documentsTab: { list: documentsTabList },
  } = usePermitHoldersDetailsContext();
  const { t } = useTranslation();

  const getDocuments = useCallback(
    async (params) => {
      const response = await getDocumentsRequest(
        entityId,
        driverHash,
        permitHolderId,
        params,
        { t }
      );

      return response;
    },
    [driverHash, entityId, permitHolderId, t]
  );

  const documentsList = useInfiniteList(getDocuments);

  useEffect(
    () =>
      dispatch({
        type: actionTypes.DOCUMENTS_TAB.SET_LIST,
        payload: documentsList,
      }),
    [documentsList.items]
  );

  return documentsTabList;
};

export default useDocuments;
