import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { routesMapping } from "../../../../utils/routes";

const useNotices = () => {
  const history = useHistory();

  const pay = useCallback((noticeId, notices) => {
    history.push(
      `${routesMapping.backofficeNotices}/${noticeId}`,
      notices.filter((notice) => notice.payable)
    );
  }, []);

  const backToList = useCallback((data) => {
    history.push(routesMapping.backofficeNotices, data);
  }, []);

  return { pay, backToList };
};

export default useNotices;
