import { useAppContext } from "components/AppProvider";
import Button from "components/buttons/Button";
import { Text } from "iparque-components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { breakpointsMapping } from "utils/breakpoints";
import { routesMapping } from "../../../utils/routes";
import ContentBorderBox, {
  CONTENT_CONTAINER_HORIZONTAL_SPACING,
} from "../components/ContentBorderBox";
import ContentHeader from "../components/ContentHeader";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import ActiveParkingBox from "./ActiveParkingBox";
import EmptyActiveParkings from "./components/EmptyActiveParkings";
import FinishedParkingBox from "./FinishedParkingBox";
import ActiveParkingBoxMediumDevices from "./mediumDevices/ActiveParkingBox";
import FinishedParkingBoxMediumDevices from "./mediumDevices/FinishedParkingBox";
import parkingsProps from "./propTypes";

const FinishedParkingsContainer = styled.div`
  margin-top: 10px;
  margin-right: ${CONTENT_CONTAINER_HORIZONTAL_SPACING}px;
`;

const Parkings = ({ activesParkings, finishedParkings, updateCallback }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { breakpoint } = useAppContext();
  const { backofficeTheme } = useBackofficeThemeContext();

  const { ActiveParkingBoxComponent, FinishedParkingBoxComponent } = useMemo(() => {
    const isMediumDevice = breakpoint === breakpointsMapping.noBreakpoint;

    return {
      ActiveParkingBoxComponent: isMediumDevice
        ? ActiveParkingBoxMediumDevices
        : ActiveParkingBox,
      FinishedParkingBoxComponent: isMediumDevice
        ? FinishedParkingBoxMediumDevices
        : FinishedParkingBox,
    };
  }, [breakpoint]);

  return (
    <>
      <ContentHeader
        topTitle={t("7997") /* Consulta os teus estacionamentos */}
        bottomTitle={t("7998") /* Não deixes que nada te escape! */}
      />
      <ContentBorderBox
        className="mt-50"
        cornerColor={backofficeTheme.color.primary}
        title={t("2") /* Estacionamentos ativos */}
        topRightCornerContent={
          activesParkings.length > 0 && (
            <Button
              size="sm"
              onClick={() => {
                history.push(`${routesMapping.backofficeParking}`);
              }}
            >
              {t("4045") /* Novo Estacionamento */}
            </Button>
          )
        }
      >
        {activesParkings.length > 0 ? (
          activesParkings.map((parking) => (
            <ActiveParkingBoxComponent
              key={parking.id}
              updateCallback={updateCallback}
              {...parking}
            />
          ))
        ) : (
          <EmptyActiveParkings />
        )}
      </ContentBorderBox>
      {finishedParkings.length > 0 && (
        <>
          <Text className="uppercase mt-30" variant="body9">
            {t("8001") /* Histórico de estacionamentos */}
          </Text>
          <FinishedParkingsContainer>
            {finishedParkings.map((parking) => (
              <FinishedParkingBoxComponent key={parking.id} {...parking} />
            ))}
          </FinishedParkingsContainer>
        </>
      )}
    </>
  );
};

Parkings.propTypes = parkingsProps;

export default Parkings;
