import InfiniteList from "components/InfiniteList";
import React from "react";
import useInfiniteList from "../../../../hooks/useInfiniteList";
import EmptyResultsText from "../components/EmptyResultsText";
import { movementsListDefaultProps, movementsListProps } from "../propTypes";
import MovementCard from "../smallDevices/Card";

const MovementList = ({ className, getDataCallback, vehiclesColors }) => {
  const { items, onFinishedScroll, isLoading, hasError } = useInfiniteList(
    getDataCallback
  );

  return (
    <div className={className}>
      <InfiniteList
        hasError={hasError}
        onFinishedScroll={onFinishedScroll}
        isLoading={isLoading}
        renderItem={(item) => <MovementCard {...item} vehiclesColors={vehiclesColors} />}
        items={items}
        loadingItemsNumber={1}
        cardHeight={90}
        maxHeight="700px"
        EmptyListComponent={<EmptyResultsText />}
      />
    </div>
  );
};

MovementList.propTypes = movementsListProps;

MovementList.defaultProps = movementsListDefaultProps;

export default MovementList;
