import CheckboxField from "components/formFields/Checkbox";
import PropTypes from "prop-types";
import useLocalizedUrls from "hooks/useLocalizedUrls";
import { Link, Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { FieldContainer } from "./FormGrid";

const RegistryConditions = ({ textColor }) => {
  const { privacyPolicyUrl, termsAndConditionsUrl } = useLocalizedUrls();
  const { t } = useTranslation();

  return (
    <>
      <FieldContainer fullWidth>
        <CheckboxField
          id="terms-conditions-field"
          name="termsConditions"
          label={
            <Text variant="body7" color={textColor}>
              {t("7841") /* Declaro que li e aceito os */}
              &nbsp;
              <Link
                target="_blank"
                href={termsAndConditionsUrl}
                underline="always"
                rel="noopener"
              >
                {t("7897") /* Termos de Utilização */}
              </Link>
            </Text>
          }
        />
      </FieldContainer>
      <FieldContainer fullWidth>
        <CheckboxField
          id="privacy-policy-field"
          name="privacyPolicy"
          label={
            <Text variant="body7" color={textColor}>
              {t("11867") /* Declaro que li e aceito a */}
              &nbsp;
              <Link
                target="_blank"
                href={privacyPolicyUrl}
                underline="always"
                rel="noopener"
              >
                {t("6005") /* Política de Privacidade */}
              </Link>
            </Text>
          }
        />
      </FieldContainer>
      <FieldContainer fullWidth>
        <CheckboxField
          id="newsletter-new-features-field"
          name="newslettersNewFeatures"
          label={
            <Text variant="body7" color={textColor}>
              {
                t(
                  "6374"
                ) /* Desejo receber Newsletters com as novas funcionalidades do iParque */
              }
            </Text>
          }
        />
      </FieldContainer>
      <FieldContainer fullWidth>
        <CheckboxField
          id="newsletter-market-campaigns-field"
          name="newslettersMarketCampaigns"
          label={
            <Text variant="body7" color={textColor}>
              {
                t(
                  "7697"
                ) /* Pretendo receber newsletters de campanhas promocionais relativas
                        a soluções comercializadas pelo Grupo ACIN */
              }
            </Text>
          }
        />
      </FieldContainer>
    </>
  );
};

export default RegistryConditions;

RegistryConditions.defaultProps = {
  textColor: "secondary",
};

RegistryConditions.propTypes = {
  textColor: PropTypes.string,
};
