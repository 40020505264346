import PropTypes from "prop-types";
import LabelValueCardCell, {
  LABEL_VALUE_CARD_VALUE_VARIANT,
} from "domain/private/components/cardList/LabelValueCell";
import useLanguage from "hooks/useLanguage";
import React from "react";
import { useTranslation } from "react-i18next";
import { convertDateTimeToReadableDate } from "utils/dateTime";
import FlexBox from "components/FlexBox";
import { Text } from "iparque-components/dist/components";
import ListCard from "../../../components/ListCard";

const Card = ({ dateTime, operation, userName, ...other }) => {
  const { t } = useTranslation();
  const language = useLanguage();

  return (
    <ListCard {...other}>
      <FlexBox flexDirection="column" className="fill">
        <FlexBox>
          <Text variant={LABEL_VALUE_CARD_VALUE_VARIANT}>{operation}</Text>
        </FlexBox>
        <FlexBox className="mt-10">
          <LabelValueCardCell label={t("10557") /* Realizado por */} value={userName} />
          <LabelValueCardCell
            label={t("2055") /* Data */}
            value={convertDateTimeToReadableDate(dateTime, null, language)}
          />
        </FlexBox>
      </FlexBox>
    </ListCard>
  );
};

Card.propTypes = {
  dateTime: PropTypes.string.isRequired,
  operation: PropTypes.string.isRequired,
  userName: PropTypes.string,
};

Card.defaultProps = {
  userName: "-",
};

export default Card;
