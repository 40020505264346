import InfiniteList from "components/InfiniteList";
import useInfiniteList from "hooks/useInfiniteList";
import useOnPageScrollEnd from "hooks/useOnPageScrollEnd";
import React from "react";
import EmptyResultsText from "../components/EmptyResultsText";
import { movementsListDefaultProps, movementsListProps } from "../propTypes";
import MovementCard from "./Card";

const MovementList = ({ className, getDataCallback, vehiclesColors }) => {
  const {
    items,
    onFinishedScroll,
    isLoading,
    hasError,
  } = useInfiniteList(getDataCallback, { skipScrollCheck: true });

  useOnPageScrollEnd(onFinishedScroll, { offset: 70 });

  return (
    <div className={className}>
      <InfiniteList
        hasError={hasError}
        onFinishedScroll={onFinishedScroll}
        isLoading={isLoading}
        renderItem={(item) => <MovementCard {...item} vehiclesColors={vehiclesColors} />}
        items={items}
        loadingItemsNumber={1}
        cardHeight={90}
        EmptyListComponent={<EmptyResultsText />}
      />
    </div>
  );
};

MovementList.propTypes = movementsListProps;

MovementList.defaultProps = movementsListDefaultProps;

export default MovementList;
