const setIsLoading = (state, payload) => ({
  ...state,
  vehiclesTab: { ...state.vehiclesTab, isLoading: payload },
});

const setHasError = (state, payload) => ({
  ...state,
  vehiclesTab: { ...state.vehiclesTab, hasError: payload },
});

const setVehicleRegimes = (state, payload) => ({
  ...state,
  vehiclesTab: { ...state.vehiclesTab, vehiclesRegimes: payload },
});

const setActiveSubscriptions = (state, payload) => ({
  ...state,
  vehiclesTab: { ...state.vehiclesTab, activeSubscriptions: payload },
});

const setAddedVehicles = (state, payload) => ({
  ...state,
  vehiclesTab: { ...state.vehiclesTab, addedVehicles: payload },
});

const setInitialAddedVehicles = (state, payload) => ({
  ...state,
  vehiclesTab: { ...state.vehiclesTab, initialAddedVehicles: payload },
});

const setVehiclesOptions = (state, payload) => ({
  ...state,
  vehiclesTab: { ...state.vehiclesTab, vehiclesOptions: payload },
});

const addAddedVehicle = (state, vehicle) => {
  const addedVehicles = [...state.vehiclesTab.addedVehicles, vehicle];

  return {
    ...state,
    vehiclesTab: { ...state.vehiclesTab, addedVehicles },
  };
};

const flagAddedVehicle = (state, { position }) => {
  const addedVehicles = [...state.vehiclesTab.addedVehicles];

  addedVehicles[position].flagged = true;

  return {
    ...state,
    vehiclesTab: { ...state.vehiclesTab, addedVehicles },
  };
};

const editAddedVehicle = (state, { position, vehicle }) => {
  const addedVehicles = [...state.vehiclesTab.addedVehicles];

  addedVehicles[position] = {
    ...vehicle,
    permitHolderVehicleId: addedVehicles[position].permitHolderVehicleId,
    regime: addedVehicles[position].regime,
    editable: false,
    flagged: false,
    touched: addedVehicles[position].licencePlate !== vehicle.licencePlate,
  };

  return {
    ...state,
    vehiclesTab: { ...state.vehiclesTab, addedVehicles },
  };
};

const makeAddedVehicleEditable = (state, { position }) => {
  const addedVehicles = [...state.vehiclesTab.addedVehicles];

  addedVehicles[position].editable = true;

  return {
    ...state,
    vehiclesTab: { ...state.vehiclesTab, addedVehicles },
  };
};

const removeAddedVehicle = (state, { position }) => {
  const addedVehicles = [...state.vehiclesTab.addedVehicles];

  addedVehicles.splice(position, 1);

  return {
    ...state,
    vehiclesTab: { ...state.vehiclesTab, addedVehicles },
  };
};

const resetChanges = (state, currentVehicles) => {
  const addedVehicles = [...state.vehiclesTab.addedVehicles];
  const initialAddedVehicles = [];

  currentVehicles.forEach((currentVehicle) => {
    initialAddedVehicles.push({
      id: currentVehicle.id,
      licencePlate: currentVehicle.licencePlate,
    });

    const addedVehicle = addedVehicles.find(
      (addedVehicle_) => addedVehicle_.licencePlate === currentVehicle.licencePlate
    );

    if (addedVehicle) {
      addedVehicle.permitHolderVehicleId = currentVehicle.id;
      addedVehicle.touched = false;
    }
  });

  return {
    ...state,
    vehiclesTab: { ...state.vehiclesTab, addedVehicles, initialAddedVehicles },
  };
};

export default {
  addAddedVehicle,
  editAddedVehicle,
  removeAddedVehicle,
  flagAddedVehicle,
  makeAddedVehicleEditable,
  setHasError,
  setIsLoading,
  setAddedVehicles,
  setInitialAddedVehicles,
  setVehicleRegimes,
  setActiveSubscriptions,
  setVehiclesOptions,
  resetChanges,
};
