import { Link, Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Error403 from "../../components/errors/403";
import Template from "../../components/Template";
import { usePublicEntityContext } from "../../Provider";
import ConfirmationStatus from "./Status";

const PermitHoldersCreationConfirmation = () => {
  const { t } = useTranslation();
  const { state = {} } = useLocation();
  const { isEntityDataLoading } = usePublicEntityContext();

  const { evidenceUrl, name } = state;

  if (!name) {
    return <Error403 />;
  }

  return (
    <Template
      isLoading={isEntityDataLoading}
      title={t("10531") /* Dístico criado com sucesso */}
    >
      <Body color="quintenary" variant="body5">
        {
          t("10532", {
            name,
          }) /* {{name}}, o teu dístico foi criado com sucesso. */
        }
      </Body>
      {evidenceUrl && (
        <Body color="quintenary" variant="body5">
          <span>
            {t("10303") /* Podes descarregar o comprovativo */}{" "}
            <Link underline="always" target="_blank" href={evidenceUrl}>
              {t("6515") /* aqui */}
            </Link>
          </span>
        </Body>
      )}
      <ConfirmationStatus />
    </Template>
  );
};

export default PermitHoldersCreationConfirmation;

const Body = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.weight.bold};
`;
