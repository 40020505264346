import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import useClickOutside from "hooks/useClickOutside";
import useOnKeyPress from "hooks/useOnKeyPress";
import { keyboardKeyCodes } from "utils/keys";
import NotificationsBell from "./Bell";
import NotificationsList from "./List";
import { useNotificationsContext } from "../Provider";

const NotificationsArea = ({ className }) => {
  const { isOpen, close } = useNotificationsContext();
  const ref = useRef(null);

  const closeNotifications = useCallback(() => {
    if (isOpen) {
      close();
    }
  }, [isOpen, close]);

  useClickOutside(ref, closeNotifications);
  useOnKeyPress(keyboardKeyCodes.ESC, closeNotifications);

  return (
    <div ref={ref} className={className}>
      <Container>
        <NotificationsBell />
        <NotificationsList />
      </Container>
    </div>
  );
};

export default NotificationsArea;

NotificationsArea.propTypes = {
  className: PropTypes.string,
};

NotificationsArea.defaultProps = {
  className: undefined,
};

const Container = styled.div`
  position: relative;
`;
