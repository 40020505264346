import FlexBox from "components/FlexBox";
import React from "react";
import styled from "styled-components";
import AddButton from "../../../components/documents/AddButton";
import DocumentsList from "../../../components/documents/List";
import TabIdentification from "../../../components/TabIdentification";
import TabTitle from "../../../components/Title";
import { tabsIdsMap } from "../../../helper";
import { usePermitHoldersDetailsContext } from "../../../Provider";
import TabDetailsOperations from "../../operations/TabDetails";

const DocumentsTab = () => {
  const { isEditable } = usePermitHoldersDetailsContext();

  return (
    <FlexBox className="fill" flexDirection="column" justifyContent="spaceBetween">
      <div>
        <TabDetailsOperations className="mb-10" />
        <TabIdentification tabId={tabsIdsMap.documents} />
        <TabTitle className="mt-20" tabId={tabsIdsMap.documents} />
        <DocumentsList />
      </div>
      {isEditable && <StyledAddButton />}
    </FlexBox>
  );
};

export default DocumentsTab;

const StyledAddButton = styled(AddButton)`
  width: 280px;
  margin: 20px auto 0;
`;
