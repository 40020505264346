import { notificationStates } from "utils/notifications";
import { NOTIFICATIONS_REQUEST_LIMIT } from "../configs";

export const actionTypes = {
  OPEN: "OPEN",
  CLOSE: "CLOSE",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  MARK_AS_READ: "MARK_AS_READ",
  MARK_AS_UNREAD: "MARK_AS_UNREAD",
  SET_UNREAD_NUMBER: "SET_UNREAD_NUMBER",
};

const open = (state) => ({
  ...state,
  isOpen: true,
});

const close = (state) => ({
  ...state,
  isOpen: false,
  notifications: state.notifications.slice(0, NOTIFICATIONS_REQUEST_LIMIT),
});

const setNotifications = (state, { notifications, totalNotifications }) => ({
  ...state,
  notifications,
  totalNotifications,
});

const markAsRead = (state, notificationId) => {
  if (notificationId === undefined) {
    const newNotifications = state.notifications.map((notification) => ({
      ...notification,
      stateId: notificationStates.read,
    }));

    return {
      ...state,
      notifications: newNotifications,
      unreadNumber: 0,
    };
  }

  const targetNotification = state.notifications.find(
    (notification) =>
      notification.id === notificationId &&
      notification.stateId === notificationStates.unread
  );

  if (!targetNotification) {
    return state;
  }

  const newNotifications = state.notifications.map((notification) => ({
    ...notification,
    stateId:
      notification.id === notificationId ? notificationStates.read : notification.stateId,
  }));

  return {
    ...state,
    notifications: newNotifications,
    unreadNumber: state.unreadNumber - 1,
  };
};

const markAsUnread = (state, { notificationId, previousUnreadNumber }) => {
  if (notificationId === undefined) {
    const newNotifications = state.notifications.map((notification) => ({
      ...notification,
      stateId: notificationStates.unread,
    }));

    return {
      ...state,
      notifications: newNotifications,
      unreadNumber: previousUnreadNumber,
    };
  }

  const targetNotification = state.notifications.find(
    (notification) =>
      notification.id === notificationId &&
      notification.stateId === notificationStates.read
  );

  if (!targetNotification) {
    return state;
  }

  const newNotifications = state.notifications.map((notification) => ({
    ...notification,
    stateId:
      notification.id === notificationId
        ? notificationStates.unread
        : notification.stateId,
  }));

  return {
    ...state,
    notifications: newNotifications,
    unreadNumber: state.unreadNumber + 1,
  };
};

const setUnreadNumber = (state, unreadNumber) => ({ ...state, unreadNumber });

const actions = {
  [actionTypes.OPEN]: open,
  [actionTypes.CLOSE]: close,
  [actionTypes.SET_NOTIFICATIONS]: setNotifications,
  [actionTypes.MARK_AS_READ]: markAsRead,
  [actionTypes.MARK_AS_UNREAD]: markAsUnread,
  [actionTypes.SET_UNREAD_NUMBER]: setUnreadNumber,
};

export default actions;
