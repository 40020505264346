import EmptyList from "domain/private/components/EmptyList";
import React from "react";
import { useTranslation } from "react-i18next";
import useVehicles from "../hooks/useVehicles";

const EmptyVehicles = () => {
  const { t } = useTranslation();
  const { addVehicle } = useVehicles();

  return (
    <EmptyList
      title={t("9035") /* Ainda não tens veículos registados? */}
      subtitle={t("9036") /* Clica no botão para criar! */}
      button={{ action: addVehicle, label: t("1874") /* Novo Veículo */ }}
    />
  );
};

export default EmptyVehicles;
