import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import VerticalTabs from "../../../../components/tabs/verticals/smallDevices/Tabs";
import ContentHeader from "../../components/ContentHeader";
import Theme from "../Theme";
import { settingsPropTypes } from "../propTypes";
import LanguageTab from "../tabs/language/Tab";
import useVerticalTabs from "../hooks/useVerticalTabs";

const Container = styled.div`
  width: 625px;
`;

const Settings = ({ tabs }) => {
  const { t } = useTranslation();
  const {
    displayVerticalTabs,
    displayLanguageComponent,
    handleBackToTabs,
    handleTabClick,
  } = useVerticalTabs();

  return (
    <>
      <ContentHeader
        topTitle={t("8516") /* Este lugar é teu! */}
        bottomTitle={t("8517") /* Configura-o à tua maneira. */}
        backCallback={!displayVerticalTabs ? handleBackToTabs : null}
      />
      <Container>
        <Theme className="mt-50" />
        <VerticalTabs
          className="mt-60"
          tabs={tabs}
          displayTabs={displayVerticalTabs}
          onTabClick={handleTabClick}
        />
        {displayLanguageComponent && <StyledLanguageTab />}
      </Container>
    </>
  );
};

Settings.propTypes = settingsPropTypes;

export default Settings;

const StyledLanguageTab = styled(LanguageTab)`
  margin: 50px 0 0 65px;
  width: calc(100% - 80px);
`;
