import { getTokens } from "utils/auth";
import { entityConfigurationsMapping } from "../../../../utils/entities";
import AbstractDataSource from "./abstractDataSource";

export default class EntitiesDataSource extends AbstractDataSource {
  async getAll(params = {}) {
    return this.iParqueClient.GET("v1/entities", params, {});
  }

  async getById(id, params = {}, useCache = false) {
    return this.iParqueClient.GET(`v1/entities/${id}`, params, {}, useCache);
  }

  async getCitiesById(id, params = {}, useCache = false) {
    return this.iParqueClient.GET(`v1/entities/${id}/explorations`, params, {}, useCache);
  }

  async getThemeById(id, params = {}, useCache = false) {
    return this.iParqueClient.GET(`v1/entities/${id}/themes`, params, {}, useCache);
  }

  async getConfig(entityId, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/configs`,
      {
        ...params,
        "config[0]":
          entityConfigurationsMapping.onlyParkWithActiveGeographicLocationActive,
        "config[1]": entityConfigurationsMapping.canParkWithoutBalance,
        "config[2]": entityConfigurationsMapping.iban,
        "config[3]": entityConfigurationsMapping.canCreatePublicPermits,
      },
      {
        Authorization: getTokens().token,
      }
    );
  }

  async getPublicConfigs(entityId, params = {}) {
    return this.iParqueClient.GET(`v1/entities/${entityId}/configs/public`, params);
  }
}
