import React, { useState, useCallback } from "react";
import { useAuth } from "components/AuthProvider";
import { paymentMethodsMapping, paymentTypesMapping } from "utils/payment";
import { paymentBoxPropTypes } from "./propTypes";
import GeneralMbWayPaymentBox from "../../../components/paymentMethods/MbWayPaymentBox";
import useAccountMovements from "../../hooks/useAccountMovements";
import { generateBalancePayment } from "../controller";

const MbWayPaymentBox = ({ initialFlowCallback, paymentMethod }) => {
  const { backToList } = useAccountMovements();
  const { defaultEntityId, authHash } = useAuth();
  const [amount, setAmount] = useState();

  const onClick = () =>
    new Promise((resolve) => {
      (async () => {
        const selectedAmount = await initialFlowCallback(paymentMethod);

        if (!selectedAmount) {
          resolve(false);
          return;
        }

        resolve(true);
        setAmount(selectedAmount);
      })();
    });

  const onGeneratePayment = useCallback(
    async (params) => {
      const paymentData = await generateBalancePayment(defaultEntityId, authHash, {
        ...params,
        amount,
        paymentMethodId: paymentMethodsMapping.mbway.id,
      });

      return paymentData?.id;
    },
    [defaultEntityId, authHash, amount]
  );

  return (
    <GeneralMbWayPaymentBox
      title={paymentMethod.title}
      shortMessage={paymentMethod.shortMessage}
      longMessage={paymentMethod.longMessage}
      onClick={onClick}
      onGeneratePayment={onGeneratePayment}
      onSuccess={backToList}
      amount={amount}
      itemTypeId={paymentTypesMapping.balanceCharge}
      displayAmount
    />
  );
};

MbWayPaymentBox.propTypes = paymentBoxPropTypes;

export default MbWayPaymentBox;
