import useIsPublicPage from "hooks/useIsPublicPage";
import { ToastContainer } from "iparque-components";
import DriversDataSource from "lib/clients/iParque/dataSources/driversDataSource";
import EntityPendingContracts from "components/pendingOperations/EntityPendingContracts";
import { usePendingOperationsContext } from "components/pendingOperations/Provider";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { entityConfigurationsMapping, getConfigValue } from "utils/entities";
import { AppContext } from "../../components/AppProvider";
import { useAuth } from "../../components/AuthProvider";
import PrivateRoute from "../../components/PrivateRoute";
import { checkDefaultEntity } from "../../utils/auth";
import { smallDevicesBreakpoints } from "../../utils/breakpoints";
import { isPublicRoute, routesMapping } from "../../utils/routes";
import BalanceChargePage from "./accountMovements/charge/Page";
import AccountMovementsPage from "./accountMovements/Page";
import CitiesPage from "./cities/Page";
import BackOfficeProvider from "./components/BackOfficeProvider";
import ThemeProvider from "./components/ThemeProvider";
import Menu, { MENU_WIDTH } from "./menu/Menu";
import SmallDevicesMenu from "./menu/SmallDevicesMenu";
import NoticesPage from "./notices/Page";
import NoticesPaymentPage from "./notices/payment/Page";
import NotificationsArea from "./notifications/NotificationsArea";
import NotificationPage from "./notifications/Page";
import NotificationsProvider from "./notifications/Provider";
import ParkingPage from "./parking/Page";
import ParkingFinishedPage from "./parkingFinished/Page";
import ParkingRenewPage from "./parkingRenew/Page";
import ParkingsPage from "./parkings/Page";
import PermitHolderCreation from "./permitHolders/Creation";
import PermitHolderDetails from "./permitHolders/Details";
import PermitHoldersList from "./permitHolders/List";
import ProfilePage from "./profile/Page";
import SettingsPage from "./settings/Page";
import VehicleDetailsPage from "./vehicles/details/DetailsPage";
import VehiclesPage from "./vehicles/Page";

const driversDataSource = new DriversDataSource();

const PrivatePage = () => {
  const {
    user,
    isAuthenticated,
    isGeneralDataProtectionRegulationAccepted,
    defaultEntity,
    driverHash,
  } = useAuth();
  const history = useHistory();
  const isPublicPage = useIsPublicPage();
  const [displayPrivatePage, setDisplayPrivatePage] = useState(false);
  const { breakpoint } = useContext(AppContext);
  const [hasDefaultEntityDefined, setHasDefaultEntityDefined] = useState(null);
  const {
    hasPendingOperations,
    isResolvingPendingOperation,
    resolveOldestPendingOperation,
    decorateWithPendingOperation,
  } = usePendingOperationsContext();
  const isSmallDevice = smallDevicesBreakpoints.includes(breakpoint);

  const canCreatePublicPermits = getConfigValue(
    defaultEntity?.configurations || [],
    entityConfigurationsMapping.canCreatePublicPermits
  );

  useEffect(() => {
    if (!displayPrivatePage || !driverHash) {
      return;
    }

    driversDataSource.setNotificationToken(driverHash);
  }, [driverHash, displayPrivatePage]);

  useEffect(() => {
    if (isPublicRoute(history.location.pathname)) {
      setDisplayPrivatePage(false);

      return;
    }

    if (!isAuthenticated) {
      history.replace(decorateWithPendingOperation(routesMapping.login));

      window.location.reload();

      return;
    }

    if (!checkDefaultEntity(user)) {
      setHasDefaultEntityDefined(false);
      setDisplayPrivatePage(true);

      if (history.location.pathname !== routesMapping.backofficeProfile) {
        history.push(routesMapping.backofficeCities);
      }

      return;
    }

    setHasDefaultEntityDefined(true);
    setDisplayPrivatePage(true);
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (!isPublicPage && isAuthenticated) {
      setDisplayPrivatePage(true);
    }

    if (checkDefaultEntity(user)) {
      setHasDefaultEntityDefined(true);
    }
  }, [isPublicPage, isAuthenticated, user]);

  useEffect(() => {
    if (
      isAuthenticated &&
      isGeneralDataProtectionRegulationAccepted &&
      hasPendingOperations &&
      !isResolvingPendingOperation
    ) {
      resolveOldestPendingOperation();
    }
  }, [
    isAuthenticated,
    isGeneralDataProtectionRegulationAccepted,
    hasPendingOperations,
    isResolvingPendingOperation,
    resolveOldestPendingOperation,
  ]);

  const components = useMemo(() => {
    const allowed = hasDefaultEntityDefined && isGeneralDataProtectionRegulationAccepted;
    const allowPublicPermits = allowed && canCreatePublicPermits;

    return (
      <>
        <PrivateRoute
          path={routesMapping.backofficeParking}
          component={ParkingPage}
          allowed={allowed}
          exact
        />
        <PrivateRoute
          path={`${routesMapping.backofficeParking}/:parkingId/stop`}
          component={ParkingFinishedPage}
          allowed={allowed}
        />
        <PrivateRoute
          path={`${routesMapping.backofficeParking}/:parkingId/renew`}
          component={ParkingRenewPage}
          allowed={allowed}
        />
        <PrivateRoute
          path={routesMapping.backofficeParkings}
          component={ParkingsPage}
          allowed={allowed}
        />
        <PrivateRoute
          path={routesMapping.backofficeAccountMovements}
          component={AccountMovementsPage}
          allowed={allowed}
          exact
        />
        <PrivateRoute
          path={routesMapping.backofficePermitHolders}
          component={PermitHoldersList}
          allowed={allowPublicPermits}
          exact
        />
        <PrivateRoute
          path={routesMapping.backofficePermitHoldersCreation}
          component={PermitHolderCreation}
          allowed={allowPublicPermits}
          exact
        />
        <PrivateRoute
          path={`${routesMapping.backofficePermitHolders}/:permitHolderId(\\d+)`}
          component={PermitHolderDetails}
          allowed={allowPublicPermits}
        />
        <PrivateRoute
          path={routesMapping.backofficeBalanceCharge}
          component={BalanceChargePage}
          allowed={allowed}
        />
        <PrivateRoute
          path={routesMapping.backofficeVehicles}
          component={VehiclesPage}
          allowed={allowed}
          exact
        />
        <PrivateRoute
          path={`${routesMapping.backofficeVehicles}/:vehicleId`}
          component={VehicleDetailsPage}
          allowed={allowed}
        />
        <PrivateRoute
          path={routesMapping.backofficeNotices}
          component={NoticesPage}
          allowed={allowed}
          exact
        />
        <PrivateRoute
          path={`${routesMapping.backofficeNotices}/:noticeId`}
          component={NoticesPaymentPage}
          allowed={allowed}
        />
        <PrivateRoute
          path={routesMapping.backofficeCities}
          component={CitiesPage}
          allowed={!hasDefaultEntityDefined || isGeneralDataProtectionRegulationAccepted}
        />
        <PrivateRoute
          path={routesMapping.backofficeDefinitions}
          component={SettingsPage}
          allowed={hasDefaultEntityDefined}
        />
        <PrivateRoute
          path={routesMapping.backofficeProfile}
          component={ProfilePage}
          allowed
        />
        <PrivateRoute
          path={routesMapping.backofficeNotification}
          component={NotificationPage}
          allowed={allowed}
        />
      </>
    );
  }, [
    hasDefaultEntityDefined,
    isGeneralDataProtectionRegulationAccepted,
    canCreatePublicPermits,
  ]);

  if (!displayPrivatePage || !isAuthenticated) {
    return null;
  }

  return (
    <ThemeProvider>
      <BackOfficeProvider>
        <NotificationsProvider>
          <EntityPendingContracts />
          <Container displayVertical={isSmallDevice}>
            {hasDefaultEntityDefined ? (
              <>
                {isSmallDevice ? (
                  <MenuContainer>
                    <SmallDevicesMenu />
                  </MenuContainer>
                ) : (
                  <>
                    <LargeDevicesNotificationsArea />
                    <Menu />
                  </>
                )}
              </>
            ) : null}
            <DynamicContentContainer
              displayMenu={hasDefaultEntityDefined}
              isSmallDevice={isSmallDevice}
            >
              {components}
            </DynamicContentContainer>
          </Container>
        </NotificationsProvider>
      </BackOfficeProvider>
      {!isPublicPage && <ToastContainer />}
    </ThemeProvider>
  );
};

export default PrivatePage;

const LargeDevicesNotificationsArea = styled(NotificationsArea)`
  position: absolute;
  top: 25px;
  right: 35px;
`;

const Container = styled.div`
  display: flex;

  ${(props) => props.displayVertical && `flex-direction: column;`};
`;

const MenuContainer = styled.div`
  z-index: 1000;
  position: fixed;
`;

const DynamicContentContainer = styled.div`
  display: flex;

  ${({ displayMenu, isSmallDevice }) =>
    isSmallDevice
      ? css`
          overflow-y: auto;
          padding: 15px;
          flex-direction: column;
          box-sizing: border-box;
          margin-top: ${displayMenu ? "70px" : 0};
          min-height: calc(100vh - ${displayMenu ? "70px" : 0});
        `
      : css`
          margin-left: ${displayMenu ? `${MENU_WIDTH}px` : 0};
          min-height: 100vh;
          max-width: 1600px;
          width: fill-available;
          box-sizing: border-box;
          padding: 5vh 3vw;
          display: flex;
          flex-direction: column;
        `};
`;
