import { breakpointsMapping } from "./breakpoints";
import { FALLBACK_LANGUAGE } from "./languages";

export const socialNetworksMapping = {
  facebook: "facebook",
};

const SMALL = "sm";
const MEDIUM = "md";
const LARGE = "lg";

const LANGUAGE_CHARS_LENGTH = 2;

export const getButtonSize = (breakpoint) => {
  if (breakpoint === breakpointsMapping.sm || breakpoint === breakpointsMapping.xs) {
    return SMALL;
  }

  if (
    breakpoint === breakpointsMapping.md ||
    breakpoint === breakpointsMapping.noBreakpoint
  ) {
    return MEDIUM;
  }

  return LARGE;
};

export const buildQueryString = (params) => {
  if (params === undefined) {
    return "";
  }

  return Object.keys(params)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join("&");
};

export const hasObjectProperty = (object, property) => {
  return Object.prototype.hasOwnProperty.call(object, property);
};

export const languageWithoutRegion = (language) => {
  if (!language) {
    return FALLBACK_LANGUAGE;
  }

  if (language.length === LANGUAGE_CHARS_LENGTH) {
    return language;
  }

  return language.slice(0, LANGUAGE_CHARS_LENGTH);
};

export const isEmpty = (object) => {
  return Object.keys(object).length === 0;
};

export const meetsRequirements = (requirements) => {
  const values = Object.values(requirements);

  for (let index = 0; index < values.length; index += 1) {
    const value = values[index];

    if (!value) {
      return false;
    }
  }

  return true;
};

export const areObjectsEquals = (firstObject, secondObject) => {
  return (
    Object.entries(firstObject).toString() === Object.entries(secondObject).toString()
  );
};

export const getObjectKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

const downloadFile = (content, fileName, contentType) => {
  const anchor = document.createElement("a");
  const file = new Blob([content], { type: contentType });

  anchor.href = URL.createObjectURL(file);
  anchor.download = fileName;
  anchor.click();
};

export const downloadJson = (object, fileName) => {
  return downloadFile(JSON.stringify(object, null, 2), fileName, "text/json");
};

const numberFormat = (number, { decimalPlaces = 2 } = {}) => {
  return Number(
    `${Math.round(parseFloat(`${number}e${decimalPlaces}`))}e-${decimalPlaces}`
  ).toFixed(decimalPlaces);
};

export function roundDecimalPlaces(number, { decimalPlaces = 2 } = {}) {
  if (number === "" || Number.isNaN(number)) {
    return 0;
  }

  return numberFormat(number, { decimalPlaces });
}
