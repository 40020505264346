import React from "react";
import { useTranslation } from "react-i18next";
import StatusWidget from "../StatusWidget";

const EmptyListInfo = () => {
  const { t } = useTranslation();

  return (
    <StatusWidget variant="info">
      {t("10506") /* Ainda não foram adicionados veículos ao dístico. */}
    </StatusWidget>
  );
};

export default EmptyListInfo;
