import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";

const SmallDevicesFillSpace = styled.div`
  flex: 1;

  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

export default SmallDevicesFillSpace;
