import { useAppContext } from "components/AppProvider";
import { errorToast } from "iparque-components/dist/components";
import DriversDataSource from "lib/clients/iParque/dataSources/driversDataSource";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  RECEIVE_NEWSLETTERS_MARKET_CAMPAIGNS,
  RECEIVE_NEWSLETTERS_NEW_FEATURES,
} from "utils/appConfigs";
import { userContractsMapping } from "utils/contracts";
import { EMAIL_ALREADY_EXISTS, hasErrorCode } from "utils/error";
import { routesMapping } from "utils/routes";
import { warningMessage } from "utils/userMessages";
import useGoogleRecaptcha from "./useGoogleRecaptcha";

const driversDataSource = new DriversDataSource();

const useRegistry = () => {
  const { setIsLoading } = useAppContext();
  const { t } = useTranslation();
  const history = useHistory();

  const { executeGoogleRecaptcha, isGoogleRecaptchaLoaded } = useGoogleRecaptcha();

  const onSubmit = useCallback(
    async (values) => {
      const valuesCopy = { ...values };
      const subscriptionIds = [];

      setIsLoading(true);

      const token = await executeGoogleRecaptcha();

      if (!token) {
        setIsLoading(false);
        warningMessage(t("7858") /* Por favor, tenta novamente. */);
        return;
      }

      if (valuesCopy.termsConditions && valuesCopy.privacyPolicy) {
        valuesCopy.contractId = userContractsMapping.generalDataProtectionRegulation;
      }

      delete valuesCopy.termsConditions;

      try {
        await driversDataSource.registry({
          recaptchaToken: token,
          ...valuesCopy,
        });
      } catch (error) {
        setIsLoading(false);

        if (hasErrorCode(error, EMAIL_ALREADY_EXISTS)) {
          history.push(routesMapping.registryConclusion);
          return;
        }

        errorToast(
          t("7847") /* Erro! */,
          t("9672") /* Ocorreu um erro ao realizar o registo. */
        );

        return;
      }

      if (values.newslettersNewFeatures) {
        subscriptionIds.push(RECEIVE_NEWSLETTERS_NEW_FEATURES);
      }

      if (values.newslettersMarketCampaigns) {
        subscriptionIds.push(RECEIVE_NEWSLETTERS_MARKET_CAMPAIGNS);
      }

      if (subscriptionIds.length > 0) {
        try {
          await driversDataSource.subscribeNewsletters({
            email: valuesCopy.email,
            subscriptionIds,
          });
        } catch {
          warningMessage(
            t(
              "8872"
            ) /* Ocorreu um erro ao guardar as tuas preferências relativas às newsletters */
          );
        }
      }

      setIsLoading(false);

      history.push(routesMapping.registryConclusion);
    },
    [history, t, executeGoogleRecaptcha]
  );

  return { isGoogleRecaptchaLoaded, onSubmit };
};

export default useRegistry;
