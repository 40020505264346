import Axios from "axios";
import { AppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import useLocation from "hooks/useLocation";
import DriversDataSource from "lib/clients/iParque/dataSources/driversDataSource";
import { useContext, useEffect, useState } from "react";
import { getDefaultEntity } from "utils/auth";
import { formatCitiesWithDistances } from "utils/cities";
import { getCitiesFromEntities } from "utils/entities";
import { isEmpty } from "utils/general";
import { isPointInArea } from "utils/geolocation";

const driversDataSource = new DriversDataSource();

const useClosestEntitySuggestion = () => {
  const { user, userId } = useAuth();
  const { setIsLoading } = useContext(AppContext);
  const { position } = useLocation(false, {
    enableHighAccuracy: true,
  });
  const [entities, setEntities] = useState();
  const [currentEntity, setCurrentEntity] = useState();
  const [suggestedEntity, setSuggestedEntity] = useState();
  const entity = getDefaultEntity(user);

  useEffect(() => {
    const cancelToken = Axios.CancelToken.source();

    if (!position) {
      return;
    }

    setIsLoading(true);

    (async () => {
      const data = await driversDataSource.getEntities(
        userId,
        { cities: true, mobile: true },
        false,
        cancelToken
      );

      if (data) {
        setEntities(data.items);
      }
    })();

    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!position || isEmpty(position) || !entities) {
      return;
    }

    const citiesSortedByDistance = formatCitiesWithDistances(
      getCitiesFromEntities(entities),
      position
    );

    const currentCity = citiesSortedByDistance.find(
      (city) => city.entityId === entity.id
    );

    const suggestedCity =
      citiesSortedByDistance.find((city) => {
        return isPointInArea(position, city?.explorationArea || []);
      }) || citiesSortedByDistance[0];

    if (!currentCity || !suggestedCity || suggestedCity.entityId === entity.id) {
      setSuggestedEntity(null);
      setCurrentEntity(null);
    } else {
      setSuggestedEntity(suggestedCity);
      setCurrentEntity(currentCity);
    }
  }, [entities, position]);

  return { selectedEntity: currentEntity, suggestedEntity };
};

export default useClosestEntitySuggestion;
