import PropTypes from "prop-types";
import { colorsMapping } from "utils/colors";

export const vehicleProps = {
  licensePlate: PropTypes.string,
  id: PropTypes.number,
  brandId: PropTypes.number,
  modelId: PropTypes.number,
  purchasedDate: PropTypes.string,
  sellingDate: PropTypes.string,
  colorId: PropTypes.number,
};

export const vehicleDefaultProps = {
  brandId: undefined,
  modelId: undefined,
  vehicleId: undefined,
  colorId: colorsMapping.gray,
  licensePlate: "",
  purchasedDate: "",
  sellingDate: "",
  vehicle: undefined,
};
