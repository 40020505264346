import { useEffect, useState, useCallback } from "react";
import { GOOGLE_RECAPTCHA_URL } from "../utils/configs";

const SCRIPT_ID = "google-recaptcha-script";

const useGoogleRecaptcha = () => {
  const [googleRecaptchaInstance, setGoogleRecaptchaInstance] = useState(null);
  const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;

  const executeAfterLoad = useCallback(() => {
    setGoogleRecaptchaInstance(window.grecaptcha);
  }, []);

  useEffect(() => {
    let googleRecaptchaScript = null;
    const isScriptLoaded = document.getElementById(SCRIPT_ID);

    if (isScriptLoaded) {
      setGoogleRecaptchaInstance(window.grecaptcha);
    } else {
      googleRecaptchaScript = document.createElement("script");
      googleRecaptchaScript.src = `${GOOGLE_RECAPTCHA_URL}?render=${recaptchaKey}`;
      googleRecaptchaScript.defer = true;
      googleRecaptchaScript.id = SCRIPT_ID;
      window.document.body.appendChild(googleRecaptchaScript);
      googleRecaptchaScript.addEventListener("load", executeAfterLoad);
    }

    return () => {
      if (googleRecaptchaScript) {
        googleRecaptchaScript.removeEventListener("load", executeAfterLoad);
      }
    };
  }, []);

  const executeAsync = useCallback(
    async (action = "submit") => {
      if (!googleRecaptchaInstance) {
        return null;
      }

      try {
        return await googleRecaptchaInstance.execute(recaptchaKey, { action });
      } catch (error) {
        return null;
      }
    },
    [googleRecaptchaInstance]
  );

  return {
    executeGoogleRecaptcha: executeAsync,
    isGoogleRecaptchaLoaded: !!googleRecaptchaInstance,
  };
};

export default useGoogleRecaptcha;
