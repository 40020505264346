import React from "react";
import PropTypes from "prop-types";
import { Heading, Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { ReactComponent as NoInternetIcon } from "../../assets/icons/no-internet.svg";
import FlexBox from "../../components/FlexBox";
import { breakpoints } from "../../utils/breakpoints";

const Overlay = ({ visible }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <WarningContainer
      visible={visible}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <NoInternetIcon fill={theme.color.danger} className="mb-40" />
      <StyledHeading variant="h4" align="center" className="mt-20">
        Beep Beep!
      </StyledHeading>
      <StyledHeading variant="h4" align="center">
        {t("9993") /* Para utilizar o iParque Driver tens de estar online. */}
      </StyledHeading>
      <Text variant="body4" align="center" className="mt-40">
        {t("9610") /* Verifica a tua ligação à internet. */}
      </Text>
    </WarningContainer>
  );
};

Overlay.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default Overlay;

const WarningContainer = styled(FlexBox)`
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 10%;
  background-color: ${({ theme }) => theme.color.darkest}D8;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  transition: opacity 0.5s;
  z-index: 99;
`;

const StyledHeading = styled(Heading)`
  line-height: 1em;
  max-width: 550px;

  @media (max-width: ${breakpoints.md}) {
    font-size: 20pt;
  }
`;
