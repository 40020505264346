import React from "react";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../components/smallDevices/ContentHeader";
import { requirementsPagePropsTypes } from "./propTypes";
import ParkingRequirements from "./Requirements";

const ParkingRequirementsPage = ({ requirementsMet }) => {
  const { t } = useTranslation();

  return (
    <>
      <ContentHeader
        topTitle={t("7830") /* Vamos estacionar? */}
        bottomTitle={t("7831") /* É fácil! */}
      />
      <ParkingRequirements className="mt-40" requirementsMet={requirementsMet} />
    </>
  );
};

ParkingRequirementsPage.propTypes = requirementsPagePropsTypes;

export default ParkingRequirementsPage;
