import { useAuth } from "components/AuthProvider";
import PermitHolderCreationFlow from "components/permitHolders/Creation";
import { CREATION_CONTEXT, STEPS } from "components/permitHolders/Creation/helper";
import { errorToast } from "iparque-components";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import { routesMapping } from "utils/routes";
import { successMessage } from "utils/userMessages";
import Template from "./components/Template";
import ConfirmationStep from "./steps/Confirmation";
import DocumentsStep from "./steps/Documents";
import PermitDetailsStep from "./steps/PermitDetails";
import VehiclesStep from "./steps/Vehicles";

const StepsComponents = {
  [STEPS.documents]: DocumentsStep,
  [STEPS.permitDetails]: PermitDetailsStep,
  [STEPS.vehicles]: VehiclesStep,
  [STEPS.confirmation]: ConfirmationStep,
};

const PermitHolderCreation = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { defaultEntity } = useAuth();

  const onSubmissionSuccess = useCallback(() => {
    history.push(routesMapping.backofficePermitHolders);
    successMessage(t("10485") /* O teu dístico foi criado com sucesso. */);
  }, [history]);

  const onError = useCallback(() => {
    history.push(routesMapping.backofficePermitHolders);
    errorToast(
      t("3613") /* Erro */,
      t("10180") /* Ocorreu um erro ao obter a informação da entidade */
    );
  }, [t, history]);

  const entity = useMemo(
    () =>
      defaultEntity
        ? {
            id: defaultEntity.id,
            logo: defaultEntity.logo,
            countryId: defaultEntity.countryId,
            name: defaultEntity.name,
          }
        : undefined,
    [defaultEntity]
  );

  return (
    <Container>
      <PermitHolderCreationFlow
        creationContext={CREATION_CONTEXT.private}
        entity={entity}
        isEntityDataLoading={false}
        onSubmissionSuccess={onSubmissionSuccess}
        onInitialRequestError={onError}
        TemplateComponent={Template}
        StepsComponents={StepsComponents}
      />
    </Container>
  );
};

export default PermitHolderCreation;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  #personal-data-form-container {
    max-width: 700px;

    @media (max-width: ${breakpoints.lg}) {
      min-width: 100%;
    }
  }
`;
