import { useState, useEffect, useCallback, useRef } from "react";
import { isScrollInBottom } from "../utils/dom";
import { lazyLoadPaginationLimit, firstPage, getPageSkip } from "../utils/pagination";
import { areObjectsEquals } from "../utils/general";

const useInfiniteList = (
  callback,
  { limit = lazyLoadPaginationLimit, skipScrollCheck = false, ...otherParams } = {}
) => {
  const listContainerElement = useRef();
  const fetching = useRef(false);
  const [list, setList] = useState({
    items: [],
    search: {},
    currentPage: firstPage,
    totalPages: 0,
    isLoading: true,
    isRequested: false,
    errors: [],
    hasError: false,
  });

  const processedCallback = useCallback(
    async (skip, { cancelToken, reset }) => {
      const response = await callback(
        {
          limit,
          skip,
          ...otherParams,
          ...list.search,
        },
        cancelToken
      );

      fetching.current = false;

      if (response === null) {
        return;
      }

      setList((prevList) => {
        return {
          ...prevList,
          totalPages: response?.hasError ? 0 : response.totalPages,
          items: response?.hasError
            ? []
            : [...(reset ? [] : prevList.items), ...response.items],
          errors: response?.errors || [],
          isLoading: false,
          isRequested: true,
          hasError: response?.hasError || false,
        };
      });
    },
    [list.search]
  );

  const onFinishedScroll = useCallback(
    (event) => {
      if (fetching.current) {
        return;
      }

      listContainerElement.current = event.target;

      const newPage = list.currentPage + 1;

      if (
        (skipScrollCheck || isScrollInBottom(event.target)) &&
        newPage <= list.totalPages &&
        !list.isLoading
      ) {
        fetching.current = true;

        setList((prevList) => {
          return {
            ...prevList,
            currentPage: newPage,
          };
        });
      }
    },
    [list.currentPage, list.totalPages, list.isLoading]
  );

  const changeSearch = useCallback(
    (newSearch) => {
      if (areObjectsEquals(list.search, newSearch)) {
        return;
      }

      if (listContainerElement.current) {
        listContainerElement.current.scrollTop = 0;
      }

      setList((prevList) => {
        return {
          ...prevList,
          search: newSearch,
          items: [],
          isLoading: true,
          currentPage: firstPage,
        };
      });
    },
    [list.search]
  );

  useEffect(() => {
    processedCallback(getPageSkip(list.currentPage, limit), {
      reset: false,
    });
  }, [list.currentPage, list.search]);

  const update = useCallback(
    () =>
      processedCallback(getPageSkip(firstPage, limit), {
        cancelToken: undefined,
        reset: true,
      }),
    [processedCallback]
  );

  return {
    ...list,
    setSearch: changeSearch,
    onFinishedScroll,
    update,
  };
};

export default useInfiniteList;
