import { useAppContext } from "components/AppProvider";
import Button from "components/buttons/Button";
import FlexBox from "components/FlexBox";
import CardCell from "domain/private/components/cardList/Cell";
import LabelValueCardCell, {
  LABEL_VALUE_CARD_VALUE_VARIANT,
} from "domain/private/components/cardList/LabelValueCell";
import useLanguage from "hooks/useLanguage";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpointsMapping } from "utils/breakpoints";
import { formatDate } from "utils/dateTime";
import { formatMoney } from "utils/money";
import { permitHoldersSubscriptionsStates } from "utils/permitHolders";
import useOccupationType from "../../../hooks/useOccupationType";
import useSubscriptionPaymentMethodsSize from "../../../hooks/useSubscriptionPaymentMethodsSize";
import useSubscriptionsCard from "../../../hooks/useSubscriptionsCard";
import ListCard from "../../ListCard";
import PaymentMethods from "../PaymentMethods";
import SubscriptionStateText from "../StateText";

const Card = ({
  id,
  cardNumber,
  licencePlates,
  startingDate,
  endDate,
  amount,
  state,
  ...other
}) => {
  const language = useLanguage();
  const { t } = useTranslation();
  const { breakpoint } = useAppContext();
  const {
    cardRef,
    onPay,
    closeDetails,
    isLoading,
    shouldDisplayPayButton,
  } = useSubscriptionsCard();
  const { approximateHeight } = useSubscriptionPaymentMethodsSize();
  const { isVehicleOccupation } = useOccupationType();

  const smallVersion = [breakpointsMapping.xs, breakpointsMapping.sm].includes(
    breakpoint
  );

  return (
    <ListCard
      extraInfo={
        <PaymentMethods subscriptionId={id} closeDetails={closeDetails} amount={amount} />
      }
      isLoading={isLoading}
      {...other}
      ref={cardRef}
      displayArrow={false}
      extraInfoApproximateHeight={approximateHeight}
    >
      <FlexBox flexDirection="column" className="fill">
        <FlexBox>
          <FlexBox flexDirection="column" size="sm">
            <CardCell>
              {isVehicleOccupation && (
                <LicencePlatesText variant="body2">{licencePlates}</LicencePlatesText>
              )}
              <Text variant={LABEL_VALUE_CARD_VALUE_VARIANT}>{cardNumber}</Text>
            </CardCell>
            <LabelValueCardCell
              className="mt-10"
              label={t("10212") /* Data de início */}
              value={formatDate(startingDate, language)}
            />
          </FlexBox>
          <FlexBox flexDirection="column" size="sm">
            <FlexBox flexDirection="column" alignItems="flexEnd">
              <SubscriptionStateText {...state} right />
              <Text variant={LABEL_VALUE_CARD_VALUE_VARIANT}>
                {formatMoney(amount, { country: language })}
              </Text>
            </FlexBox>
            <LabelValueCardCell
              align={smallVersion ? "right" : "left"}
              className="mt-10"
              label={t("8459") /* Data de fim */}
              value={formatDate(endDate, language)}
            />
          </FlexBox>
        </FlexBox>
        {state.id === permitHoldersSubscriptionsStates.waitingForPayment && (
          <FlexBox className="mt-20" justifyContent="flexEnd">
            {shouldDisplayPayButton && (
              <Button size="md" onClick={onPay}>
                {t("4537") /* Pagar */}
              </Button>
            )}
          </FlexBox>
        )}
      </FlexBox>
    </ListCard>
  );
};

export default Card;

Card.propTypes = {
  id: PropTypes.number.isRequired,
  cardNumber: PropTypes.string.isRequired,
  licencePlates: PropTypes.string.isRequired,
  startingDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  state: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

const LicencePlatesText = styled(Text).attrs({ variant: "body7" })`
  font-weight: ${({ theme }) => theme.typography.weight.bold};
`;
