import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Text } from "iparque-components";
import SmallDevicesContentHeader from "../../components/smallDevices/ContentHeader";
import { routesMapping } from "../../../../utils/routes";
import VehicleForm from "./Form";
import { vehicleProps } from "./propTypes";

const VehicleDetailsSmallDevices = ({ vehicle }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <SmallDevicesContentHeader
        topTitle={t("7946") /* Queres estacionar? */}
        backCallback={() => history.push(routesMapping.backofficeVehicles)}
        bottomTitle={
          vehicle
            ? t("7965") /* Edita o teu veículo */
            : t("7957") /* Regista o teu veículo! */
        }
      />
      <Text className="mt-30 uppercase" color="quaternary" variant="body9">
        {
          vehicle
            ? `${t("4214") /* Veículo */} ${vehicle.licensePlate || ""}`
            : t("1874") /* Novo Veículo */
        }
      </Text>
      <VehicleForm vehicle={vehicle} />
    </>
  );
};

VehicleDetailsSmallDevices.defaultProps = {
  vehicle: undefined,
};

VehicleDetailsSmallDevices.propTypes = {
  vehicle: PropTypes.shape(vehicleProps),
};

export default VehicleDetailsSmallDevices;
