import Button from "components/buttons/Button";
import { Heading } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { routesMapping } from "../../../utils/routes";
import VehicleValidationRequirement from "./components/VehicleValidationRequirement";
import useEmptyUnpaidNotices from "./hooks/useEmptyUnpaidNotices";
import { emptyUnpaidNoticesProps } from "./propTypes";

const Container = styled.div`
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RequirementContainer = styled.div`
  max-width: 700px;
`;

const EmptyUnpaidNotices = ({ validatedLicencePlates, unvalidatedLicencePlates }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { title, primarySubtitle, secondarySubtitle } = useEmptyUnpaidNotices(
    validatedLicencePlates,
    unvalidatedLicencePlates
  );

  const startParking = () => {
    history.push(`${routesMapping.backofficeParking}`);
  };

  return (
    <Container>
      <Heading color="tertiary" variant="h3">
        {title}
      </Heading>
      <Heading color="tertiary" variant="h7">
        {primarySubtitle}
      </Heading>
      {secondarySubtitle && (
        <Heading color="tertiary" variant="h7" className="mt-5">
          {secondarySubtitle}
        </Heading>
      )}
      {unvalidatedLicencePlates.length > 0 ? (
        <RequirementContainer className="mt-40">
          <VehicleValidationRequirement />
        </RequirementContainer>
      ) : (
        <Button size="sm" onClick={startParking} className="mt-40">
          {t("4045") /* Novo Estacionamento */}
        </Button>
      )}
    </Container>
  );
};

export default EmptyUnpaidNotices;

EmptyUnpaidNotices.propTypes = emptyUnpaidNoticesProps;
