import i18n from "i18next";
import { errorToast } from "iparque-components";
import EntitiesDataSource from "lib/clients/iParque/dataSources/entitiesDataSource";
import { errorMessage } from "../utils/userMessages";

const entitiesDataSource = new EntitiesDataSource();

export const getEntityById = async (id) => {
  try {
    const response = await entitiesDataSource.getById(id, {}, true);

    return {
      id: response.id,
      logo: response.logo,
      name: response.name,
      countryId: response.countryId,
    };
  } catch (error) {
    errorMessage(
      error,
      i18n.t("10180") /* Ocorreu um erro ao obter a informação da entidade */
    );

    return null;
  }
};

export const getAllEntities = async (params = {}, { t = i18n.t } = {}) => {
  try {
    const response = await entitiesDataSource.getAll(params, true);

    return response;
  } catch (error) {
    errorMessage(error, t("10547") /* Ocorreu um erro ao obter as entidades */);

    return null;
  }
};

export const getEntityCities = async (id, params = {}) => {
  try {
    const response = await entitiesDataSource.getCitiesById(id, params, true);

    return {
      items: response.items.map((city) => ({
        id: city.alias,
        name: city.name,
        districtId: city.districtId > 0 ? city.districtId : undefined,
        logoAttachment: city.attachment
          ? {
              url: city.attachment.path,
              name: city.attachment.name,
            }
          : undefined,
      })),
    };
  } catch (error) {
    errorMessage(
      error,
      i18n.t("10181") /* Ocorreu um erro ao obter as cidades da entidade */
    );

    return { hasError: true };
  }
};

export const getEntityThemeByType = async (id, typeId, params = {}) => {
  try {
    const response = await entitiesDataSource.getThemeById(
      id,
      { ...params, themeTypeId: typeId },
      true
    );

    if (!response.items.length) {
      errorToast(
        i18n.t("3613") /* Erro */,
        i18n.t("10207") /* Ocorreu um erro ao obter o tema da entidade */
      );

      return null;
    }

    const entityTheme = {};

    response.items[0].fields.forEach((field) => {
      entityTheme[field.typeId] = field.value;
    });

    return entityTheme;
  } catch (error) {
    errorMessage(
      error,
      i18n.t("10207") /* Ocorreu um erro ao obter o tema da entidade */
    );

    return null;
  }
};

export const getPublicEntityConfigs = async (id) => {
  try {
    const response = await entitiesDataSource.getPublicConfigs(id);

    return response.items;
  } catch (error) {
    errorMessage(
      error,
      i18n.t("9572") /* Ocorreu um erro ao obter as configurações da entidade */
    );

    return null;
  }
};
