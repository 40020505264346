import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";

const PopupButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  gap: 15px;

  button {
    width: 130px;

    @media (max-width: ${breakpoints.sm}) {
      flex: 1;
    }
  }
`;

export default PopupButtonsContainer;
