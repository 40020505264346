import React, { useEffect } from "react";
import { Heading } from "iparque-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SelectedBenefit from "../../components/parkings/SelectedBenefit";
import AmountDetails from "../../components/parkings/AmountDetails";
import BottomButton from "../../components/parkings/smallDevices/BottomButton";
import { formatMoney } from "../../../../utils/money";
import { longFeeDurationDefaultProps, longFeeDurationPropTypes } from "../propTypes";
import FeeNameBox from "../../components/parkings/FeeNameBox";
import { parkingTypesMapping } from "../../../../utils/parking";
import ParkingEndDate from "../../components/parkings/EndDate";
import Skeleton from "../../../../components/Skeleton";

const GeneralContainer = styled.div`
  margin-top: 2vh;
  justify-content: center;
  display: flex;
`;

const AmountContainer = styled.div`
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
`;

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.color.secondary};
  margin-top: 5px;
  line-height: 25px;
`;

const ParkingChooseDuration = ({
  parking,
  calculateParkingCallback,
  onConfirmRenewalCallback,
  calculatedParking,
  fee,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (parking.id) {
      calculateParkingCallback();
    }
  }, [parking.id]);

  return (
    <>
      {parking.benefitName ? (
        <GeneralContainer>
          <SelectedBenefit
            description={parking.benefitName}
            color={parking.benefitColor}
          />
        </GeneralContainer>
      ) : null}
      <AmountContainer>
        <AmountDetails amount={parking.currentAmount || 0} />
        {calculatedParking.isLoading ? (
          <Skeleton height={25} width={124} />
        ) : (
          <StyledHeading variant="h2">
            {`+${formatMoney(calculatedParking?.amount, { centsFormat: false })}`}
          </StyledHeading>
        )}
      </AmountContainer>
      <GeneralContainer className={fee.name ? "visible" : "hidden"}>
        <FeeNameBox className="mt-15" name={fee.name} />
      </GeneralContainer>
      <GeneralContainer>
        <ParkingEndDate
          isLoading={calculatedParking?.isLoading}
          className="mt-10"
          parkingTypeId={parkingTypesMapping.duration}
          endingDate={calculatedParking.endingDate}
        />
      </GeneralContainer>
      <BottomButton
        onClick={onConfirmRenewalCallback}
        disabled={calculatedParking.duration === null}
        color="primary"
        size="md"
      >
        {t("35") /* Confirmar */}
      </BottomButton>
    </>
  );
};

ParkingChooseDuration.propTypes = longFeeDurationPropTypes;

ParkingChooseDuration.defaultProps = longFeeDurationDefaultProps;

export default ParkingChooseDuration;
