import GeneralButton from "components/buttons/Button";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

export const BUTTON_MIN_WIDTH = 160;
export const SPACE_BETWEEN_BUTTONS = 10;

const Button = ({ children, ...other }) => {
  return (
    <StyledButton size="xs" className="operation-button uppercase" {...other}>
      {children}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled(GeneralButton)`
  min-width: ${BUTTON_MIN_WIDTH}px;

  :not(:last-of-type) {
    margin-right: ${SPACE_BETWEEN_BUTTONS}px;
  }
`;

Button.propTypes = {
  children: PropTypes.node.isRequired,
};
