/* eslint-disable react/no-array-index-key */
import digitalClockAnimation from "assets/animations/moduleDigitalClock.json";
import mbWayAnimation from "assets/animations/moduleMbWay.json";
import permitHoldersAnimation from "assets/animations/modulePermitHolders.json";
import clockAnimation from "assets/animations/moduleStopWatch.json";
import ConditionalWrapper from "components/ConditionalWrapper";
import useMediaQuery from "hooks/useMediaQuery";
import useOnScreen from "hooks/useOnScreen";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import ContentContainer from "../components/ContentContainer";
import FullPageContainer from "../components/FullPageContainer";
import FeatureItem from "./FeatureItem";

const intersectionOptions = { unobserveOnIntersection: true };

const Features = () => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const isOnScreen = useOnScreen(containerRef, intersectionOptions);
  const shouldWrap = useMediaQuery({ maxWidth: breakpoints.xl });

  const featuresSets = [
    [
      {
        animationData: clockAnimation,
        title: t("7820") /* Inicia e termina o teu estacionamento */,
        highlightedTitle: t("7821") /* Quando quiseres! */,
        text: t(
          "7822"
        ) /* Com a modalidade Start and Stop, tu é que controlas o tempo de estacionamento! Paga apenas o período em que o teu veículo esteve estacionado. */,
        ariaLabel: t("10651") /* Iniciar e terminar o tempo do estacionamento quando */,
      },
      {
        animationData: digitalClockAnimation,
        title: t("7823") /* Seleciona apenas o tempo que queres */,
        highlightedTitle: t("7824") /* E não pagues extras! */,
        text: t(
          "7825"
        ) /* Sabes exatamente quanto tempo precisas de estacionamento? Ótimo! Paga só esse período. E se te atrasares? Nós notificamos para que possas prolongar o tempo. */,
        ariaLabel: t("10652") /* Selecionar apenas o tempo que quer estacionar */,
      },
    ],
    [
      {
        animationData: mbWayAnimation,
        title: t("7826") /* Não precisas de saldo para estacionar! */,
        highlightedTitle: t("7827") /* Paga com o MB WAY! */,
        text: t(
          "7828"
        ) /* És daqueles que gosta de fazer pagamentos de forma rápida e prática? Então, paga o estacionamento com MB WAY. Sem saldo ou cartões! */,
        ariaLabel: t("10653") /* Pagamento do estacionamento através de MBWAY */,
      },
      {
        animationData: permitHoldersAnimation,
        title: t("10581") /* Gere facilmente os teus */,
        highlightedTitle: t("10583") /* dísticos */,
        text: t(
          "10582"
        ) /* Disponibilizamos um sistema único para que possas gerir de forma simples e rápida os teus dísticos, em conjunto com as concessionárias. */,
        ariaLabel: t("10654") /* Gerir dísticos de estacionamentos de forma simples */,
      },
    ],
  ];

  return (
    <FullPageContainer>
      <ContentContainer>
        <ModulesContainer ref={containerRef}>
          {featuresSets.map((featuresSet, setIndex) => (
            <ConditionalWrapper
              key={`feature-set-${setIndex}`}
              condition={shouldWrap}
              wrapper={<InnerModulesContainer />}
            >
              {featuresSet.map((feature, featureIndex) => (
                <FeatureItem
                  key={feature.title}
                  animationData={feature.animationData}
                  title={feature.title}
                  ariaLabel={feature.ariaLabel}
                  highlightedTitle={feature.highlightedTitle}
                  text={feature.text}
                  index={parseInt(`${setIndex}${featureIndex}`, 2)}
                  isSectionOnScreen={isOnScreen}
                />
              ))}
            </ConditionalWrapper>
          ))}
        </ModulesContainer>
      </ContentContainer>
    </FullPageContainer>
  );
};

const ModulesContainer = styled.div`
  margin: 60px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 40px;

  @media (max-width: ${breakpoints.xl}) {
    grid-template-columns: 1fr;
    grid-gap: 100px;
  }

  @media (max-width: ${breakpoints.sm}) {
    grid-gap: 70px;
  }
`;

const InnerModulesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 100px;

  @media (max-width: ${breakpoints.md}) {
    grid-template-columns: 1fr;
    grid-gap: 100px 0;
  }

  @media (max-width: ${breakpoints.sm}) {
    grid-gap: 70px;
  }
`;

export default Features;
