/* eslint-disable import/prefer-default-export */
import i18n from "i18next";
import { getMissingFields } from "../domain/private/profile/utils";
import DriversDataSource from "../lib/clients/iParque/dataSources/driversDataSource";
import {
  UNABLE_TO_LOGIN_DRIVER,
  hasErrorCode,
  OLD_PASSWORD_NOT_MATCH,
  PASSWORD_ARE_EQUAL,
  EMAIL_DISPATCHER_NOT_READY,
} from "../utils/error";
import { errorMessage, successMessage, warningMessage } from "../utils/userMessages";

const driversDataSource = new DriversDataSource();

export const loginSocial = async (socialNetwork, params) => {
  try {
    const response = await driversDataSource.loginSocial(socialNetwork, params);

    return { hasAccount: true, response };
  } catch (error) {
    if (hasErrorCode(error, UNABLE_TO_LOGIN_DRIVER)) {
      return { hasAccount: false };
    }

    errorMessage(error, i18n.t("7859") /* Ocorreu um erro ao realizar o login */);

    return null;
  }
};

export const createSocial = async (socialNetwork, params) => {
  try {
    const response = await driversDataSource.createSocial(socialNetwork, params);

    return response;
  } catch (error) {
    errorMessage(error, i18n.t("7859") /* Ocorreu um erro ao realizar o login */);

    return null;
  }
};

export const getById = async (
  authHash,
  params = {},
  { useCache = false, t = i18n.t } = {}
) => {
  try {
    const response = await driversDataSource.getById(
      authHash,
      {
        ...params,
        information: true,
      },
      {},
      useCache
    );

    return {
      ...response,
      phone: response?.phone > 0 ? response.phone : undefined,
      vat: response.vat || undefined,
      location: response.location || undefined,
      zipCode: response.zipCode || undefined,
      address: response.address || undefined,
    };
  } catch (error) {
    errorMessage(error, t("10633") /* Ocorreu um erro ao obter os teus dados */);

    return null;
  }
};

export const update = async (authHash, params) => {
  try {
    await driversDataSource.update(authHash, params);

    successMessage(i18n.t("8195") /* Dados atualizados com sucesso */);

    return true;
  } catch (error) {
    const missingFields = getMissingFields(error);

    if (missingFields.length) {
      return missingFields;
    }

    errorMessage(error, i18n.t("9700") /* Ocorreu um erro ao guardar os teus dados */);

    return false;
  }
};

export const removeAccount = async (authHash, params) => {
  try {
    await driversDataSource.delete(authHash, params);

    return true;
  } catch (error) {
    errorMessage(error, i18n.t("9347") /* Ocorreu um erro ao apagar a conta */);

    return false;
  }
};

export const changePassword = async (authHash, params) => {
  try {
    await driversDataSource.changePassword(authHash, params);

    successMessage(i18n.t("8198") /* Senha alterada com sucesso */);

    return true;
  } catch (error) {
    if (hasErrorCode(error, OLD_PASSWORD_NOT_MATCH)) {
      warningMessage(i18n.t("8197") /* A senha antiga está incorreta */);
    } else if (hasErrorCode(error, PASSWORD_ARE_EQUAL)) {
      warningMessage(
        i18n.t("8238") /* A nova password deverá ser diferente da password antiga */,
        null,
        {
          autoClose: false,
        }
      );
    } else {
      errorMessage(error, i18n.t("8196") /* Ocorreu um erro ao atualizar a password */);
    }

    return false;
  }
};

export const acceptContract = async (authHash, params, token = undefined) => {
  try {
    await driversDataSource.saveContract(
      authHash,
      params,
      token ? { Authorization: token } : undefined
    );

    return true;
  } catch (error) {
    errorMessage(error, i18n.t("8543") /* Ocorreu um erro ao guardar a definição */);

    return false;
  }
};

export const declineContract = async (authHash, params) => {
  try {
    await driversDataSource.declineContract(authHash, params);

    return true;
  } catch (error) {
    errorMessage(error, i18n.t("8543") /* Ocorreu um erro ao guardar a definição */);

    return false;
  }
};

export const resendActivationEmail = async (params) => {
  try {
    await driversDataSource.resendActivationEmail(params);

    return true;
  } catch (error) {
    let message = "";

    if (hasErrorCode(error, EMAIL_DISPATCHER_NOT_READY)) {
      message = i18n.t(
        "10945"
      ) /* Já foi enviado um email de ativação. Tenta novamente mais tarde. */;
    } else {
      message = i18n.t(
        "10946"
      ) /* Ocorreu um erro ao enviar o email para ativar a conta */;
    }

    errorMessage(error, message);

    return false;
  }
};
