import PropTypes from "prop-types";
import React, { createContext, useContext, useReducer, useCallback, useRef } from "react";
import { actionTypes } from "./store/actions";
import reducer from "./store/reducer";

const initialState = {
  currentStep: { index: undefined, state: {} },
  dispatch: () => {},
};

const StepsContext = createContext(initialState);

const StepsProvider = ({ children }) => {
  const stepsStack = useRef([]);
  const [state, dispatch] = useReducer(reducer, initialState);

  const goToStep = useCallback((step, stepState) => {
    stepsStack.current.push({ index: step, state: stepState });

    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: { index: step, state: stepState },
    });
  }, []);

  const goBack = useCallback(() => {
    stepsStack.current.pop();

    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: stepsStack.current[stepsStack.current.length - 1],
    });
  }, []);

  return (
    <StepsContext.Provider
      value={{
        dispatch,
        goToStep,
        goBack,
        canGoBack: stepsStack.current.length > 1,
        ...state,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
};

export default StepsProvider;

StepsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useStepsContext = () => {
  return useContext(StepsContext);
};
