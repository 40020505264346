import CheckboxField from "components/formFields/Checkbox";
import InputField from "components/formFields/Input";
import { Form, Formik } from "formik";
import useLocalizedUrls from "hooks/useLocalizedUrls";
import {
  Button,
  Heading,
  Link,
  Text,
  errorToast,
  successToast,
} from "iparque-components";
import React, { Suspense, useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Yup from "yup.js";
import { AppContext } from "../../../../components/AppProvider";
import HiddenLabel from "../../../../components/HiddenLabel";
import useOnScreen from "../../../../hooks/useOnScreen";
import DriversDataSource from "../../../../lib/clients/iParque/dataSources/driversDataSource";
import {
  RECEIVE_NEWSLETTERS_MARKET_CAMPAIGNS,
  RECEIVE_NEWSLETTERS_NEW_FEATURES,
} from "../../../../utils/appConfigs";
import { breakpoints } from "../../../../utils/breakpoints";
import ContentContainer from "../../components/ContentContainer";

const DriverStreet = React.lazy(() => import("./DriverStreet"));

const driversDataSource = new DriversDataSource();

const subscribeNewsletters = (form, args) => {
  const { t, setIsLoading } = args;

  setIsLoading(true);

  let subscriptionIds = [RECEIVE_NEWSLETTERS_NEW_FEATURES];

  if (form.newsletterAcceptance) {
    subscriptionIds = [...subscriptionIds, RECEIVE_NEWSLETTERS_MARKET_CAMPAIGNS];
  }

  driversDataSource
    .subscribeNewsletters({
      email: form.email,
      subscriptionIds,
    })
    .then(() =>
      successToast(
        t("586") /* Sucesso */,
        t("9707") /* A subscrição da newsletter foi efetuada com sucesso */
      )
    )
    .catch(() => errorToast(t("7847") /* Erro! */, t("3402") /* Ocorreu um erro */))
    .finally(() => setIsLoading(false));

  return 0;
};

const intersectionOptions = { unobserveOnIntersection: true };

const Subscription = () => {
  const { t } = useTranslation();
  const { setIsLoading } = useContext(AppContext);
  const { privacyPolicyUrl, termsAndConditionsUrl } = useLocalizedUrls();
  const containerRef = useRef(null);
  const isOnScreen = useOnScreen(containerRef, intersectionOptions);

  const onSubmit = (values) => {
    subscribeNewsletters(values, { t, setIsLoading });
  };

  const validationSchema = useMemo(
    () =>
      Yup.object({
        email: Yup.string().email().required(" "),
        termsAndConditions: Yup.bool().oneOf([true], " "),
        privacyPolicy: Yup.bool().oneOf([true], " "),
      }),
    [t]
  );

  return (
    <StyledContentContainer>
      <Container ref={containerRef} isOnScreen={isOnScreen}>
        <ImageContainer>
          <Suspense fallback={<div />}>
            <DriverStreet isAnimationContainerOnScreen={isOnScreen} />
          </Suspense>
        </ImageContainer>
        <FormContainer>
          <div>
            <StyledHeading variant="h5" color="secondary">
              {t("7839") /* Não andes às voltas! */}
            </StyledHeading>
            <StyledHeading variant="h6" color="tertiary">
              {t("7840") /* Subscreve a nossa newsletter e sê o primeiro a estacionar. */}
            </StyledHeading>
          </div>
          <Formik
            initialValues={{
              email: "",
              termsAndConditions: false,
              privacyPolicy: false,
              newsletterAcceptance: false,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <InputRow>
                <HiddenLabel htmlFor="subscription-email-id">Email</HiddenLabel>
                <StyledInput
                  id="subscription-email-id"
                  type="email"
                  name="email"
                  placeholder={t("4440") /* Email */}
                />
                <Button type="submit" color="secondary">
                  {t("3961") /* Subscrever */}
                </Button>
              </InputRow>
              <CheckboxField
                id="terms-conditions-field"
                name="termsAndConditions"
                label={
                  <Text variant="body8" color="secondary">
                    {t("7841") /* Declaro que li e aceito os */}
                    &nbsp;
                    <Link
                      target="_blank"
                      href={termsAndConditionsUrl}
                      underline="always"
                      rel="noopener"
                    >
                      {t("7897") /* Termos de Utilização */}
                    </Link>
                  </Text>
                }
              />
              <CheckboxField
                id="privacy-policy-field"
                name="privacyPolicy"
                label={
                  <Text variant="body8" color="secondary">
                    {t("11867") /* Declaro que li e aceito a */}
                    &nbsp;
                    <Link
                      target="_blank"
                      href={privacyPolicyUrl}
                      underline="always"
                      rel="noopener"
                    >
                      {t("6005") /* Política de Privacidade */}
                    </Link>
                  </Text>
                }
              />
              <CheckboxField
                id="newsletter-acceptance-id"
                className="newsletter-acceptance-checkbox"
                name="newsletterAcceptance"
                label={
                  <Text variant="body8" color="secondary">
                    {
                      t(
                        "10963"
                      ) /* Pretendo receber newsletters sobre as novas funcionalidades e campanhas publicitárias do Grupo ACIN. */
                    }
                  </Text>
                }
              />
            </Form>
          </Formik>
        </FormContainer>
      </Container>
    </StyledContentContainer>
  );
};

const StyledContentContainer = styled(ContentContainer)`
  position: relative;

  @media (max-width: ${breakpoints.lg}) {
    width: 90%;
  }

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`;

const Container = styled.div`
  position: absolute;
  display: flex;
  z-index: 2;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.background.color.highlightBox};
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);

  @media (min-width: ${breakpoints.md}) {
    transform: translateY(${({ isOnScreen }) => (isOnScreen ? 0 : "20%")});
    opacity: ${({ isOnScreen }) => (isOnScreen ? 1 : 0)};
    transition: transform 1s 0.5s, opacity 1s 0.5s;
  }

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 50px;

  @media (max-width: ${breakpoints.md}) {
    position: absolute;
    box-sizing: border-box;
    left: 0;
    padding: 0;
    width: 100%;
    transform: translateY(-55%);
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;

  @media (max-width: ${breakpoints.md}) {
    width: 100%;
    margin-top: 100px;
  }
`;

const StyledHeading = styled(Heading)`
  line-height: 1.2em;
`;

const InputRow = styled.div`
  display: flex;
  margin-top: 10px;

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`;

const StyledInput = styled(InputField)`
  width: 100%;
  max-width: 450px;
  margin-right: 15px;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 10px;
    max-width: unset;
  }
`;

export default Subscription;
