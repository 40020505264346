import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { durationSteps, parkingBottomSteps, startAndStopSteps } from "./controller";
import ParkingMap from "./Map";
import {
  selectedBenefitDefaultProps,
  selectedBenefitPropTypes,
  selectedVehicleDefaultProps,
  selectedVehiclePropTypes,
  stepsDefaultProps,
  stepsPropTypes,
} from "./propTypes";
import StepWrapper from "../components/StepWrapper";

const ParkingSteps = ({
  basePath,
  isMapInteractable,
  candidateStreetId,
  selectedVehicle,
  step,
  selectedStreet,
  selectedBenefit,
  checkParking,
  startParkingCallback,
  createParkingCallback,
  calculateParkingCallback,
  activeParking,
  calculatedParking,
  onChooseDurationCallback,
  setAllowBackStepCallback,
  selectedFee,
}) => {
  const [StartAndStop, setStartAndStop] = useState(null);
  const [Duration, setDuration] = useState(null);

  useEffect(() => {
    const importStartAndStop = () => {
      const StartAndStopImport = React.lazy(() => import(`${basePath}/StartAndStop.jsx`));

      setStartAndStop(StartAndStopImport);
    };

    const importDuration = () => {
      const DurationImport = React.lazy(() => import(`${basePath}/Duration.jsx`));

      setDuration(DurationImport);
    };

    importStartAndStop();
    importDuration();
  }, []);

  return (
    <>
      <ParkingMap
        selectedStreetId={candidateStreetId}
        isInteractable={isMapInteractable}
        isVisible={parkingBottomSteps.includes(step)}
      />
      {StartAndStop && (
        <StepWrapper isVisible={startAndStopSteps.includes(step)}>
          <StartAndStop
            step={step}
            selectedVehicle={selectedVehicle}
            selectedStreet={selectedStreet}
            selectedBenefit={selectedBenefit}
            startParkingCallback={startParkingCallback}
            activeParking={activeParking}
          />
        </StepWrapper>
      )}
      {Duration && (
        <StepWrapper isVisible={durationSteps.includes(step)}>
          <Duration
            step={step}
            selectedVehicle={selectedVehicle}
            selectedStreet={selectedStreet}
            selectedBenefit={selectedBenefit}
            calculateParkingCallback={calculateParkingCallback}
            selectedFee={selectedFee}
            calculatedParking={calculatedParking}
            checkParking={checkParking}
            onChooseDurationCallback={onChooseDurationCallback}
            createParkingCallback={createParkingCallback}
            activeParking={activeParking}
            setAllowBackStepCallback={setAllowBackStepCallback}
          />
        </StepWrapper>
      )}
    </>
  );
};

ParkingSteps.propTypes = {
  basePath: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  candidateStreetId: PropTypes.number,
  selectedVehicle: PropTypes.shape(selectedVehiclePropTypes),
  selectedBenefit: PropTypes.shape(selectedBenefitPropTypes),
  ...stepsPropTypes,
};

ParkingSteps.defaultProps = {
  streets: [],
  candidateStreetId: null,
  selectedVehicle: selectedVehicleDefaultProps,
  selectedBenefit: selectedBenefitDefaultProps,
  ...stepsDefaultProps,
};

export default React.memo(ParkingSteps);
