import { useState, useEffect, useCallback } from "react";
import useGeolocationPermission from "./useGeolocationPermission";
import { permissionResults } from "./useNavigatorPermissions";

const defaultSettings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
};

export const locationReadingErrorsMapping = {
  permissionDenied: 1,
  positionUnavailable: 2,
  timeout: 3,
  notSupportedGeolocation: 1000,
};

const useLocation = (watchPosition = false, settings = defaultSettings) => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);
  const { permissionResult } = useGeolocationPermission();

  const onChange = useCallback(
    ({ coords, timestamp }) =>
      setPosition({
        latitude: coords.latitude,
        longitude: coords.longitude,
        accuracy: coords.accuracy,
        timestamp,
      }),
    []
  );

  const onError = useCallback((geolocationError) => setError(geolocationError.code), []);

  const checkPermission = useCallback(() => {
    let watchId = null;

    if (!navigator || !navigator.geolocation) {
      setError(locationReadingErrorsMapping.permissionDenied);
      return undefined;
    }

    if (watchPosition) {
      watchId = navigator.geolocation.watchPosition(onChange, onError, settings);
    } else {
      navigator.geolocation.getCurrentPosition(onChange, onError, settings);
    }

    return () => (watchId ? navigator.geolocation.clearWatch(watchId) : undefined);
  }, []);

  useEffect(checkPermission, []);

  useEffect(
    () =>
      permissionResult === permissionResults.granted ? checkPermission() : undefined,
    [permissionResult]
  );

  return { position, locationReadingError: error };
};

export default useLocation;
