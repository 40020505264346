import AbstractDataSource from "./abstractDataSource";

export default class AttachmentsDataSource extends AbstractDataSource {
  async create(entityId, file, data) {
    const formData = new FormData();

    formData.append("file", file);

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return this.iParqueClient.POST(`v1/entities/${entityId}/attachments`, formData, {
      "Content-type": "multipart/form-data",
    });
  }
}
