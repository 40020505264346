/* eslint-disable react/jsx-props-no-spreading */
import Axios from "axios";
import useDebounce from "hooks/useDebounce";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { routesMapping } from "utils/routes";
import { AppContext } from "../../../components/AppProvider";
import { useAuth } from "../../../components/AuthProvider";
import useChangeDefaultEntity from "../../../hooks/useChangeDefaultEntity";
import DriversDataSource from "../../../lib/clients/iParque/dataSources/driversDataSource";
import { smallDevicesBreakpoints } from "../../../utils/breakpoints";
import { convertSecondsToMilliseconds } from "../../../utils/dateTime";
import { getCitiesFromEntities } from "../../../utils/entities";
import { errorMessage } from "../../../utils/userMessages";
import ContractsPopup from "../components/ContractsPopUp";
import { saveEntityContractAcceptance } from "../controllers/cities";
import { settingsTabs } from "../settings/controller";
import Cities from "./Cities";
import CitiesSmallDevices from "./CitiesSmallDevices";
import CitiesProvider from "./Provider";

const driversDataSource = new DriversDataSource();
const delayBetweenContracts = convertSecondsToMilliseconds(0.5);

const Page = () => {
  const history = useHistory();
  const { breakpoint, setIsLoading } = useContext(AppContext);
  const { isGeneralDataProtectionRegulationAccepted, driverHash } = useAuth();
  const { t } = useTranslation();
  const changeEntityRequest = useChangeDefaultEntity();
  const [cities, setCities] = useState([]);
  const [candidateEntityId, setCandidateEntityId] = useState(null);
  const [contract, setContract] = useState(null);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const handleCloseContractsModal = useCallback(() => {
    setContract(null);
    setCandidateEntityId(null);
  }, []);

  const onEntityChangedSuccess = () => {
    history.push(
      isGeneralDataProtectionRegulationAccepted
        ? routesMapping.backofficeParking
        : `${routesMapping.backofficeDefinitions}?activeTabId=${settingsTabs.account}`
    );
  };

  const checkToUnacceptedContracts = async () => {
    const citiesOfEntity = cities.filter((city) => city.entityId === candidateEntityId);

    if (citiesOfEntity[0]?.contracts?.length) {
      setContract(citiesOfEntity[0].contracts[0]);
      return;
    }

    setIsLoading(true);
    await changeEntityRequest(candidateEntityId, { onSuccess: onEntityChangedSuccess });
    setIsLoading(false);
  };

  const removeAcceptedContracts = (contractId) => {
    const newCitiesList = [];

    cities.forEach((city) => {
      if (city.entityId !== candidateEntityId || city.contracts.length === 0) {
        newCitiesList.push(city);
        return;
      }

      if (city.entityId === candidateEntityId) {
        const processedCity = {
          ...city,
          contracts: city.contracts.filter(
            (cityContract) => cityContract.id !== contractId
          ),
        };

        newCitiesList.push(processedCity);
      }
    });

    setCities(newCitiesList); // Will force to check if still exists contracts to accept
  };

  const handleAccept = useCallback(
    async (contractId) => {
      setIsLoading(true);
      const result = await saveEntityContractAcceptance(driverHash, candidateEntityId, {
        contractId,
      });
      setIsLoading(false);

      if (!result) {
        return;
      }

      removeAcceptedContracts(contractId);
    },
    [removeAcceptedContracts]
  );

  useEffect(() => {
    let hadContractBefore = false;

    if (contract) {
      hadContractBefore = true;
      setContract(null);
    }

    if (candidateEntityId) {
      setTimeout(
        checkToUnacceptedContracts,
        hadContractBefore ? delayBetweenContracts : 0
      ); // Add delay between contracts
    }
  }, [cities, candidateEntityId]);

  useEffect(() => {
    const cancelToken = Axios.CancelToken.source();

    setIsLoading(true);
    driversDataSource
      .getEntities(
        driverHash,
        { cities: true, mobile: true, noLimit: true },
        false,
        cancelToken
      )
      .then((response) => {
        setCities(getCitiesFromEntities(response.items));
      })
      .catch((error) => {
        errorMessage(error, t("7950") /* Ocorreu um erro ao obter as cidades */);
      })
      .finally(() => setIsLoading(false));

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const filteredCities = useMemo(
    () =>
      debouncedSearch
        ? cities.filter((city) =>
            city.name.toUpperCase().includes(debouncedSearch.toUpperCase())
          )
        : cities,
    [cities, debouncedSearch]
  );

  const citiesProps = {
    cities: filteredCities,
    search,
    setSearch,
  };

  return (
    <CitiesProvider setDefaultEntity={setCandidateEntityId}>
      {contract && (
        <ContractsPopup
          key={contract.id}
          {...contract}
          onClose={handleCloseContractsModal}
          handleAccept={handleAccept}
          acceptButtonText={t("8907") /* Iniciar estacionamento */}
          rejectButtonText={t("9") /* Voltar */}
        />
      )}
      {smallDevicesBreakpoints.includes(breakpoint) ? (
        <CitiesSmallDevices {...citiesProps} />
      ) : (
        <Cities {...citiesProps} />
      )}
    </CitiesProvider>
  );
};

export default Page;
