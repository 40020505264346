import { useBackofficeThemeContext } from "domain/private/components/ThemeProvider";
import useGoogleMaps from "hooks/useGoogleMaps";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import styled from "styled-components";
import { usePermitHoldersDetailsContext } from "../../Provider";
import MapController from "./controller";

export const MAP_CONTAINER = "locations-map";

const LocationsMap = ({ mapController, onStreetClick }) => {
  const { backofficeTheme } = useBackofficeThemeContext();
  const {
    locationsTab: { locations },
  } = usePermitHoldersDetailsContext();
  const { map, google } = useGoogleMaps(MAP_CONTAINER, {
    styles: backofficeTheme.map,
    streetViewControl: false,
    maxZoom: 17,
  });

  useEffect(() => {
    if (google && map) {
      // eslint-disable-next-line no-param-reassign
      mapController.current = new MapController(google, map, {
        theme: backofficeTheme,
        onStreetClick,
      });
    }
  }, [map, google]);

  useEffect(() => {
    if (!map) {
      return;
    }

    map.setOptions({ styles: backofficeTheme.map });
    mapController.current?.setTheme(backofficeTheme);
  }, [backofficeTheme]);

  useEffect(() => {
    mapController.current?.setStreets(locations);
  }, [locations]);

  return <MapContainer id={MAP_CONTAINER} />;
};

export default LocationsMap;

LocationsMap.propTypes = {
  onStreetClick: PropTypes.func.isRequired,
  mapController: PropTypes.shape({ current: PropTypes.instanceOf(MapController) })
    .isRequired,
};

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 450px;
`;
