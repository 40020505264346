/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import styled from "styled-components";
import { Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import FlexBox from "components/FlexBox";
import useInfiniteList from "../../../../hooks/useInfiniteList";
import ListSkeleton from "../../components/ListSkeleton";
import ContentHeader from "../../components/smallDevices/ContentHeader";
import { noticesPropTypes } from "../propTypes";
import NoticesCard from "./Card";
import EmptyUnpaidNotices from "./EmptyUnpaidNotices";
import AllNotices from "./AllNotices";
import ErrorCard from "../../components/ErrorCard";
import Requirements from "../components/Requirements";
import { NOTICE_PLACEHOLDER_HEIGHT } from "../controller";
import PayAllButton from "../components/PayAllButton";
import ValidateVehiclesLink from "../components/ValidateVehiclesLink";

const UnpaidNoticesContainer = styled.div`
  max-height: 500px;
  overflow: auto;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

const Notices = ({
  getNoticesCallback,
  validatedLicencePlates,
  unvalidatedLicencePlates,
}) => {
  const { t } = useTranslation();
  const {
    items,
    onFinishedScroll,
    hasError,
    errors,
    isRequested,
    isLoading,
  } = useInfiniteList(getNoticesCallback, {
    unpaid: true,
  });

  const hasMultiplePayable = items.map((item) => item.payable).length > 1;

  const unpaidNoticesContent = (() => {
    if (isLoading) {
      return <ListSkeleton itemsNumber={1} height={NOTICE_PLACEHOLDER_HEIGHT} />;
    }

    if (hasError && !errors.length) {
      return <ErrorCard />;
    }

    if (errors.length) {
      return <Requirements errors={errors} className="fill" />;
    }

    if (items.length) {
      return (
        <UnpaidNoticesContainer onScroll={onFinishedScroll}>
          {items.map((item) => (
            <NoticesCard key={`${item.number}-unpaid`} {...item} />
          ))}
        </UnpaidNoticesContainer>
      );
    }

    if (isRequested && items.length === 0) {
      return (
        <EmptyUnpaidNotices
          validatedLicencePlates={validatedLicencePlates}
          unvalidatedLicencePlates={unvalidatedLicencePlates}
        />
      );
    }

    return null;
  })();

  return (
    <>
      <ContentHeader
        topTitle={t("8415") /* Ups! Tens infrações? */}
        bottomTitle={t("8416") /* Não te preocupes. Paga aqui! */}
      />
      {!errors.length && (
        <FlexBox flexDirection="column" className="mt-20 mb-10" gap={10}>
          <Text className="uppercase" variant="body9">
            {t("8417") /* Infrações por pagar */}
          </Text>
          <ValidateVehiclesLink
            unvalidatedLicencePlates={unvalidatedLicencePlates}
            hasUnpaidNotices={items.length > 0}
          />
        </FlexBox>
      )}
      {unpaidNoticesContent}
      {isRequested && hasMultiplePayable ? (
        <ButtonWrapper>
          <PayAllButton notices={items} size="sm" />
        </ButtonWrapper>
      ) : null}
      <AllNotices getNoticesCallback={getNoticesCallback} />
    </>
  );
};

Notices.propTypes = noticesPropTypes;

export default Notices;
