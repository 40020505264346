import React, { createContext, useReducer, useContext } from "react";
import PropTypes from "prop-types";
import Reducer from "./reducer";

const initialState = {
  balanceChargeMethods: {
    methods: [],
    areLoading: true,
    hasError: false,
  },
};

const BalanceChargeContext = createContext(initialState);

export const useBalanceChargeContext = () => useContext(BalanceChargeContext);

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <BalanceChargeContext.Provider value={{ dispatch, ...state }}>
      {children}
    </BalanceChargeContext.Provider>
  );
};

Store.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Store;
