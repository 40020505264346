import { getMessagingToken } from "lib/firebase/firebaseHelper";
import AbstractDataSource from "./abstractDataSource";
import { getTokens } from "../../../../utils/auth";

export default class DriversDataSource extends AbstractDataSource {
  async login(params = {}) {
    return this.iParqueClient.POST("v1/drivers/login", params, {});
  }

  async logout(authHash) {
    return this.iParqueClient.POST(`v1/drivers/${authHash}/logout`);
  }

  async setNotificationToken(authHash) {
    const token = await getMessagingToken();

    if (!token) {
      return null;
    }

    return this.iParqueClient.POST(
      `v1/drivers/${authHash}/notificationToken`,
      { token },
      { Authorization: getTokens().token }
    );
  }

  async disableNotificationToken(authHash, headers) {
    const token = await getMessagingToken();

    if (!token) {
      return null;
    }

    return this.iParqueClient.DELETE(
      `v1/drivers/${authHash}/notificationToken/${token}`,
      {},
      headers
    );
  }

  async getById(authHash, params, headers = {}, useCache = false) {
    return this.iParqueClient.GET(
      `v1/drivers/${authHash}`,
      params,
      {
        ...headers,
        Authorization: getTokens().token,
      },
      useCache
    );
  }

  async loginSocial(socialNetwork, params = {}) {
    return this.iParqueClient.POST(
      `v1/drivers/loginSocial?socialNetwork=${socialNetwork}`,
      params,
      {}
    );
  }

  async createSocial(socialNetwork, params = {}) {
    return this.iParqueClient.POST(
      `v1/drivers/createSocial?socialNetwork=${socialNetwork}`,
      params,
      {}
    );
  }

  async registry(params = {}) {
    return this.iParqueClient.POST("v1/drivers", params, {});
  }

  async update(authHash, params = {}) {
    return this.iParqueClient.PUT(`v1/drivers/${authHash}`, params, {});
  }

  async delete(authHash, params = {}) {
    return this.iParqueClient.DELETE(`v1/drivers/${authHash}`, params, {});
  }

  async passwordRecovery(params = {}) {
    return this.iParqueClient.POST("v1/drivers/recoverPassword", params, {});
  }

  async resetPassword(params = {}) {
    return this.iParqueClient.POST("v1/drivers/resetPassword", params, {});
  }

  async changePassword(authHash, params) {
    return this.iParqueClient.POST(`v1/drivers/${authHash}/changePassword`, params, {});
  }

  async activateAccount(params = {}) {
    return this.iParqueClient.POST("v1/drivers/activate", params, {});
  }

  async getEntities(authHash, params = {}, useCache = false, cancelToken = null) {
    return this.iParqueClient.GET(
      `v1/drivers/${authHash}/entities`,
      params,
      {
        Authorization: getTokens().token,
      },
      useCache,
      cancelToken
    );
  }

  async getEntity(authHash, entityId, params = {}) {
    return this.iParqueClient.GET(`v1/drivers/${authHash}/entities/${entityId}`, params, {
      Authorization: getTokens().token,
    });
  }

  async subscribeNewsletters(params = {}) {
    return this.iParqueClient.POST(`v1/drivers/newsletters`, params, {});
  }

  async changeDefaultEntity(authHash, params = {}) {
    return this.iParqueClient.POST(`v1/drivers/${authHash}/defaultEntity`, params, {
      Authorization: getTokens().token,
    });
  }

  async deleteDefaultEntity(authHash, params = {}) {
    return this.iParqueClient.DELETE(`v1/drivers/${authHash}/defaultEntity`, params, {
      Authorization: getTokens().token,
    });
  }

  async getVehicles(authHash, params = {}, headers = {}, useCache = false, cancelToken) {
    return this.iParqueClient.GET(
      `v1/drivers/${authHash}/vehicles`,
      params,
      {
        ...headers,
        Authorization: getTokens().token,
      },
      useCache,
      cancelToken
    );
  }

  async createVehicle(authHash, params = {}) {
    return this.iParqueClient.POST(`v1/drivers/${authHash}/vehicles`, params, {
      Authorization: getTokens().token,
    });
  }

  async editVehicle(authHash, vehicleId, params = {}) {
    return this.iParqueClient.PUT(
      `v1/drivers/${authHash}/vehicles/${vehicleId}`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }

  async deleteVehicle(authHash, vehicleId, params = {}) {
    return this.iParqueClient.DELETE(
      `v1/drivers/${authHash}/vehicles/${vehicleId}`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }

  async getVehicleById(authHash, vehicleId, params = {}) {
    return this.iParqueClient.GET(
      `v1/drivers/${authHash}/vehicles/${vehicleId}`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }

  async getParkings(entityId, authHash, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/${authHash}/parkings`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }

  async getParkingById(
    entityId,
    authHash,
    parkingId,
    params = {},
    headers = {},
    useCache = false,
    cancelToken = null
  ) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/${authHash}/parkings/${parkingId}`,
      params,
      {
        ...headers,
        Authorization: getTokens().token,
      },
      useCache,
      cancelToken
    );
  }

  async getBalance(entityId, authHash, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/${authHash}/balance`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }

  async getBalanceHistory(
    entityId,
    authHash,
    params = {},
    useCache = false,
    cancelToken = null
  ) {
    return this.iParqueClient.GET(
      `v3/entities/${entityId}/drivers/${authHash}/balanceHistory`,
      params,
      {
        Authorization: getTokens().token,
      },
      useCache,
      cancelToken
    );
  }

  async gePaymentsHistory(
    entityId,
    authHash,
    params = {},
    useCache = false,
    cancelToken = null
  ) {
    return this.iParqueClient.GET(
      `v3/entities/${entityId}/drivers/${authHash}/paymentHistory`,
      params,
      {
        Authorization: getTokens().token,
      },
      useCache,
      cancelToken
    );
  }

  async getBalancePaymentDetails(entityId, authHash, balancePaymentId, params = {}) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/${authHash}/payments/balance/${balancePaymentId}`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }

  async getNotices(
    entityId,
    authHash,
    params = {},
    useCache = false,
    cancelToken = null
  ) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivers/${authHash}/notices`,
      params,
      {
        Authorization: getTokens().token,
      },
      useCache,
      cancelToken
    );
  }

  async getSettings(authHash, params = {}, useCache = false, cancelToken = null) {
    return this.iParqueClient.GET(
      `v1/drivers/${authHash}/settings`,
      params,
      {
        Authorization: getTokens().token,
      },
      useCache,
      cancelToken
    );
  }

  async saveSettings(authHash, params = {}) {
    return this.iParqueClient.POST(`v1/drivers/${authHash}/settings`, params, {
      Authorization: getTokens().token,
    });
  }

  async getAllData(authHash, params = {}) {
    return this.iParqueClient.GET(`v1/drivers/${authHash}/export`, params, {
      Authorization: getTokens().token,
    });
  }

  async saveContract(
    authHash,
    params = {},
    headers = { Authorization: getTokens()?.token }
  ) {
    return this.iParqueClient.POST(`v1/drivers/${authHash}/contracts`, params, headers);
  }

  async declineContract(authHash, params = {}) {
    return this.iParqueClient.DELETE(`v1/drivers/${authHash}/contracts`, params, {
      Authorization: getTokens().token,
    });
  }

  async saveEntityContractAcceptance(authHash, entityId, params) {
    return this.iParqueClient.POST(
      `v1/drivers/${authHash}/entities/${entityId}/contracts`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }

  async payNoticeWithBalance(entityId, authHash, params) {
    return this.iParqueClient.POST(
      `v1/entities/${entityId}/drivers/${authHash}/notices/paymentWithBalance`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }

  async generateBalancePayment(entityId, authHash, params) {
    return this.iParqueClient.POST(
      `v1/entities/${entityId}/drivers/${authHash}/payments/generateBalance`,
      params,
      {
        Authorization: getTokens().token,
      }
    );
  }

  async resendActivationEmail(params) {
    return this.iParqueClient.POST("v1/drivers/resendActivationEmail", { ...params });
  }
}
