import { AppContext } from "components/AppProvider";
import { Heading, Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { breakpoints, breakpointsMapping } from "utils/breakpoints";
import { formatMoney } from "utils/money";

const StyledHeading = styled(Heading)`
  line-height: 40px;
  align-self: flex-end;

  @media (max-width: ${breakpoints.lg}) {
    align-self: center;
  }
`;

const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmountDetails = ({ amount, isLoading, isPaymentPending }) => {
  const { t } = useTranslation();
  const { breakpoint } = useContext(AppContext);
  const isMediumDevice = [breakpointsMapping.md, breakpointsMapping.lg].includes(
    breakpoint
  );

  return (
    <AmountContainer>
      <Text align={isMediumDevice ? "center" : "left"} variant="body4">
        {
          isPaymentPending
            ? t("615") /* Montante a pagar */
            : t("8000") /* Montante pago */
        }
      </Text>
      {isLoading ? (
        <Skeleton height={35} width={134} />
      ) : (
        <StyledHeading color="tertiary" variant="h1">
          {formatMoney(amount, { centsFormat: false })}
        </StyledHeading>
      )}
    </AmountContainer>
  );
};

AmountDetails.propTypes = {
  amount: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isPaymentPending: PropTypes.bool,
};

AmountDetails.defaultProps = {
  isLoading: false,
  isPaymentPending: true,
};

export default AmountDetails;
