import React, { useMemo } from "react";
import TabComponent from "../components/TabComponent";
import { tabsIdsMap } from "../helper";
import { usePermitHoldersDetailsContext } from "../Provider";
import useOccupationType from "./useOccupationType";
import useTabsUtils from "./useTabsUtils";

const useTabs = ({
  GeneralTab,
  DocumentsTab,
  VehiclesTab,
  LocationsTab,
  SubscriptionsTab,
  HistoryTab,
}) => {
  const { getTabLabel, getTabIcon } = useTabsUtils();
  const { initialRequestStatus } = usePermitHoldersDetailsContext();
  const { isVehicleOccupation } = useOccupationType();

  const tabs = useMemo(
    () => [
      {
        id: tabsIdsMap.general,
        label: getTabLabel(tabsIdsMap.general),
        component: <TabComponent Component={GeneralTab} />,
        icon: getTabIcon(tabsIdsMap.general),
      },
      {
        id: tabsIdsMap.documents,
        label: getTabLabel(tabsIdsMap.documents),
        component: <TabComponent Component={DocumentsTab} />,
        icon: getTabIcon(tabsIdsMap.documents),
      },
      ...(isVehicleOccupation || initialRequestStatus.isLoading
        ? [
            {
              id: tabsIdsMap.vehicles,
              label: getTabLabel(tabsIdsMap.vehicles),
              component: <TabComponent Component={VehiclesTab} />,
              icon: getTabIcon(tabsIdsMap.vehicles),
            },
            {
              id: tabsIdsMap.locations,
              label: getTabLabel(tabsIdsMap.locations),
              component: <TabComponent Component={LocationsTab} />,
              icon: getTabIcon(tabsIdsMap.locations),
            },
          ]
        : []),
      {
        id: tabsIdsMap.subscriptions,
        label: getTabLabel(tabsIdsMap.subscriptions),
        component: <TabComponent Component={SubscriptionsTab} />,
        icon: getTabIcon(tabsIdsMap.subscriptions),
      },
      {
        id: tabsIdsMap.history,
        label: getTabLabel(tabsIdsMap.history),
        component: <TabComponent Component={HistoryTab} />,
        icon: getTabIcon(tabsIdsMap.history),
      },
    ],
    [
      GeneralTab,
      DocumentsTab,
      LocationsTab,
      SubscriptionsTab,
      initialRequestStatus.isLoading,
      isVehicleOccupation,
    ]
  );

  return tabs;
};

export default useTabs;
