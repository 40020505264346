import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import InfiniteList from "components/InfiniteList";
import { Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import Item from "./Item";
import { itemProps } from "./propTypes";

const List = ({ isLoading, selected, onClick, items }) => {
  const { t } = useTranslation();

  return (
    <Container className="mt-20">
      <InfiniteList
        isLoading={isLoading}
        renderItem={(city) => <Item selected={selected} {...city} onClick={onClick} />}
        cardHeight={70}
        items={items}
        EmptyListComponent={
          <Text variant="body7" color="quintenary">
            {t("5054") /* Sem resultados */}
          </Text>
        }
      />
    </Container>
  );
};

List.propTypes = {
  isLoading: PropTypes.bool,
  selected: PropTypes.shape({
    entityId: PropTypes.number,
    cityId: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape(itemProps)),
};

List.defaultProps = {
  isLoading: false,
  selected: undefined,
  items: undefined,
};

export default List;

const Container = styled.div`
  max-height: 240px;
  overflow-y: auto;
`;
