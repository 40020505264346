import { createGlobalStyle, css } from "styled-components";
import { getBodyScrollStyle, getScrollStyle } from "utils/scroll";

const ScrollBarStyles = createGlobalStyle`
  ${({ theme }) => css`
    body {
      ${getBodyScrollStyle(theme)}
    }

    ${getScrollStyle(theme)}
  `}`;

export default ScrollBarStyles;
