export const sizes = {
  small: "small",
  medium: "medium",
  large: "large",
};

export const paymentBoxSizes = { medium: "medium", large: "large" };

export const scenarios = {
  accountMovements: "accountMovements",
  paymentMethod: "paymentMethod",
};

const iconsSizes = {
  atm: {
    small: {
      width: "20px",
      height: "25px",
    },
    medium: {
      width: "35px",
      height: "33px",
    },
    large: {
      width: "50px",
      height: "50px",
    },
  },
  mbway: {
    small: {
      width: "40px",
      height: "30px",
    },
    medium: {
      width: "55px",
      height: "35px",
    },
  },
  creditCard: {
    small: {
      width: "25px",
      height: "25px",
    },
    medium: {
      width: "35px",
      height: "35px",
    },
    large: {
      width: "50px",
      height: "50px",
    },
  },
  balance: {
    small: {
      width: "30px",
      height: "30px",
    },
    medium: {
      width: "35px",
      height: "35px",
    },
    large: {
      width: "50px",
      height: "50px",
    },
  },
  bankTransference: {
    small: {
      width: "25px",
      height: "25px",
    },
    medium: {
      width: "35px",
      height: "35px",
    },
    large: {
      width: "50px",
      height: "50px",
    },
  },
};

export const getIconSize = ({ iconType, size, sizeType }) => {
  return (
    iconsSizes[iconType][size]?.[sizeType] || iconsSizes[iconType][sizes.medium][sizeType]
  );
};
