import React from "react";
import { useStepsContext } from "domain/public/components/StepsProvider";
import DriversIdentificationsInformations from "domain/public/components/DriversIdentificationsInformations";
import { useDriverIdentificationContext } from "../../Provider";
import { actionTypes } from "../../store/actions";
import { STEPS } from "../helper";

const Informations = () => {
  const { areTheTermsAndConditionsAccepted, dispatch } = useDriverIdentificationContext();
  const { goToStep } = useStepsContext();

  const onAdvance = () => {
    dispatch({
      type: actionTypes.SET_TERMS_AND_CONDITIONS_ACCEPTED,
      payload: true,
    });

    goToStep(STEPS.personalData);
  };

  return (
    <DriversIdentificationsInformations
      onAdvance={onAdvance}
      areTheTermsAndConditionsAccepted={areTheTermsAndConditionsAccepted}
    />
  );
};

export default Informations;
