import { useCallback } from "react";
import { usePermitHoldersDetailsContext } from "../Provider";
import { actionTypes } from "../store/actions";

const useLargeScreenTabs = () => {
  const { dispatch } = usePermitHoldersDetailsContext();

  const onTabChange = useCallback(
    () => dispatch({ type: actionTypes.SET_IS_EDITABLE, payload: false }),
    []
  );

  return { onTabChange };
};

export default useLargeScreenTabs;
