import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { errorToast } from "iparque-components";
import { useHistory } from "react-router-dom";
import {
  setSession,
  getTokens,
  getUser,
  removeSession as removeStoredSession,
  setUserDefaultEntity,
  getDefaultEntityId,
  updateBalance as updateStoredDefaultEntityBalance,
  updateUser as updateStoredUser,
  isGeneralDataProtectionRegulationAccepted as isGDPRAccepted,
  changeDataProtectionRegulationAgreement,
  removeDefaultEntity as removeUserDefaultEntity,
} from "utils/auth";
import { routesMapping } from "../utils/routes";
import DriversDataSource from "../lib/clients/iParque/dataSources/driversDataSource";
import { isEmpty } from "../utils/general";
import EntitiesDataSource from "../lib/clients/iParque/dataSources/entitiesDataSource";
import { warningMessage } from "../utils/userMessages";

const AuthContext = createContext({ driverHash: undefined });

export const useAuth = () => {
  return useContext(AuthContext);
};

const redirectToLogin = ({ history }) => {
  history.push(routesMapping.login);
};

const driverDataSource = new DriversDataSource();
const entitiesDataSource = new EntitiesDataSource();

const AuthProvider = (props) => {
  const { children } = props;
  const [authTokens, setAuthTokens] = useState(getTokens());
  const [user, setUser] = useState(getUser());
  const [
    isGeneralDataProtectionRegulationAccepted,
    setIsGeneralDataProtectionRegulationAccepted,
  ] = useState(isGDPRAccepted());
  const history = useHistory();
  const { t } = useTranslation();

  const setDefaultEntity = useCallback((defaultEntity) => {
    setUserDefaultEntity(defaultEntity);
    setUser(getUser());
  }, []);

  const removeDefaultEntity = useCallback(() => {
    removeUserDefaultEntity();
    setUser(getUser());
  }, []);

  const setSessionAuthSession = useCallback(() => {
    const newUser = getUser();
    setAuthTokens(getTokens());

    // When Driver does not have default entity selected
    if (!newUser.driver.defaultEntityId) {
      setUser(getUser());
      return;
    }

    Promise.all([
      driverDataSource.getEntity(newUser.authHash, newUser.driver.defaultEntityId),
      entitiesDataSource.getConfig(newUser.driver.defaultEntityId),
    ])
      .then((response) => {
        const [defaultEntityResponse, entityConfigResponse] = response;
        defaultEntityResponse.configurations = entityConfigResponse.items;

        /* If the user has a default entity, but not had all contracts signed,
           this code will remove the default entity and force him to choose one again
           and sign contracts */
        if (defaultEntityResponse.contracts.length) {
          removeDefaultEntity();
          return;
        }

        setUserDefaultEntity(defaultEntityResponse);
        setUser(getUser());
      })
      .catch(() => {
        errorToast(
          t("3613") /* Erro */,
          t("7932") /* Ocorreu um erro ao obter os dados da entidade predefinida */
        );
      });
  }, [user, removeDefaultEntity]);

  const updateBalance = useCallback(() => {
    const newUser = getUser();

    driverDataSource
      .getBalance(getDefaultEntityId(), newUser.authHash)
      .then((response) => {
        updateStoredDefaultEntityBalance(response.balance);
        setUser(getUser());
      })
      .catch(() => {
        warningMessage(t("8121") /* Ocorreu um erro a atualizar o saldo */);
      });
  }, []);

  const updateUser = (newUserInfo) => {
    updateStoredUser(newUserInfo);
    setUser(getUser());
  };

  const removeSession = useCallback(async () => {
    await driverDataSource.logout(user.authHash);
    await driverDataSource.disableNotificationToken(user.authHash, {
      Authorization: authTokens.token,
    });
    removeStoredSession();
    setAuthTokens({ token: null, refreshToken: null });
    setUser({});
  }, [user, authTokens]);

  const handleAcceptGeneralDataProtectionRegulation = useCallback((isAccepted) => {
    changeDataProtectionRegulationAgreement(isAccepted);
    setIsGeneralDataProtectionRegulationAccepted(isAccepted);
  }, []);

  useEffect(() => {
    document.addEventListener("changeUserData", setSessionAuthSession);
    document.addEventListener("unauthorized", removeSession);
    setIsGeneralDataProtectionRegulationAccepted(isGDPRAccepted());

    return () => {
      document.removeEventListener("changeUserData", setSessionAuthSession);
      document.removeEventListener("unauthorized", removeSession);
    };
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        setSession,
        isAuthenticated: Boolean(authTokens.refreshToken) && !isEmpty(user),
        redirectToLogin: () => redirectToLogin({ history }),
        userId: user?.driver?.id || null,
        authHash: user?.authHash || null, // deprecated
        driverHash: user?.authHash || null,
        defaultEntityId: user?.driver?.defaultEntityId || null,
        defaultEntity: user?.driver?.defaultEntity || null,
        user,
        removeSession,
        setDefaultEntity,
        removeDefaultEntity,
        updateBalance,
        updateUser,
        isGeneralDataProtectionRegulationAccepted,
        handleAcceptGeneralDataProtectionRegulation,
        loginWithExternalApp: user.loginWithExternalApp ?? false,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
