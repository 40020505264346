import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAuth } from "../components/AuthProvider";
import { getMissingFields } from "../domain/private/profile/utils";
import DriversDataSource from "../lib/clients/iParque/dataSources/driversDataSource";
import EntitiesDataSource from "../lib/clients/iParque/dataSources/entitiesDataSource";
import { routesMapping } from "../utils/routes";
import { errorMessage } from "../utils/userMessages";

const driversDataSource = new DriversDataSource();
const entitiesDataSource = new EntitiesDataSource();

const useChangeDefaultEntity = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { driverHash, setDefaultEntity } = useAuth();

  const changeDefaultEntity = useCallback(
    async (entityId, { onSuccess, onError } = {}) => {
      try {
        const [defaultEntityResponse, entityConfigResponse] = await Promise.all([
          driversDataSource.changeDefaultEntity(driverHash, { entityId }),
          entitiesDataSource.getConfig(entityId),
        ]);

        defaultEntityResponse.configurations = entityConfigResponse.items;

        setDefaultEntity(defaultEntityResponse);
        onSuccess?.();
      } catch (error) {
        const missingFields = getMissingFields(error);

        if (missingFields.length) {
          history.push({
            pathname: `${routesMapping.backofficeProfile}`,
            state: { missingFields, entityId },
          });
          return;
        }

        errorMessage(
          error,
          t("7951") /* Ocorreu um erro ao guardar a cidade escolhida */
        );
        onError?.();
      }
    },
    [driverHash, setDefaultEntity]
  );

  return changeDefaultEntity;
};

export default useChangeDefaultEntity;
