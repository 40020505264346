const setIsLoading = (state, payload) => ({
  ...state,
  locationsTab: { ...state.locationsTab, isLoading: payload },
});

const setHasError = (state, payload) => ({
  ...state,
  locationsTab: { ...state.locationsTab, hasError: payload },
});

const setLocations = (state, payload) => ({
  ...state,
  locationsTab: { ...state.locationsTab, locations: payload },
});

const setZones = (state, payload) => ({
  ...state,
  locationsTab: { ...state.locationsTab, zones: payload },
});

export default {
  setIsLoading,
  setHasError,
  setLocations,
  setZones,
};
