import ScrollBarStyles from "components/ScrollBarStyles";
import PropTypes from "prop-types";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import {
  createGlobalStyle,
  css,
  ThemeProvider as StyledComponentsThemeProvider,
} from "styled-components";
import {
  getBackofficeTheme,
  getBackofficeThemeConfiguration,
  getStoredBackofficeTheme,
  hasSystemThemeOnBackoffice,
  setStoredBackofficeTheme,
  themesMapping,
} from "../../../themes/theme";
import "../fonts.css";

const ThemeContext = createContext();

export const useBackofficeThemeContext = () => {
  return useContext(ThemeContext);
};

const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    body {
      background-color: ${theme.background.color.background};
    }
  `}`;

const ThemeProvider = ({ children }) => {
  const [backofficeThemeName, setBackofficeThemeName] = useState(getBackofficeTheme());
  const [settingsBackofficeThemeName, setSettingsBackofficeThemeName] = useState(
    getStoredBackofficeTheme()
  );

  const setBackofficeTheme = useCallback((newTheme) => {
    setStoredBackofficeTheme(newTheme);
    setSettingsBackofficeThemeName(newTheme);
    setBackofficeThemeName(newTheme);
  }, []);

  const themeConfiguration = useMemo(getBackofficeThemeConfiguration, [
    settingsBackofficeThemeName,
    backofficeThemeName,
  ]);

  const setBackofficeThemeBySystem = useCallback((event) => {
    if (!hasSystemThemeOnBackoffice()) {
      return;
    }

    setBackofficeThemeName(event.matches ? themesMapping.dark : themesMapping.normal);
  }, []);

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", setBackofficeThemeBySystem);

    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", setBackofficeThemeBySystem);
    };
  }, []);

  return (
    <StyledComponentsThemeProvider theme={themeConfiguration}>
      <ThemeContext.Provider
        value={{
          settingsBackofficeThemeName,
          backofficeThemeName,
          backofficeTheme: themeConfiguration,
          setBackofficeTheme,
        }}
      >
        <ScrollBarStyles />
        <GlobalStyles />
        <SkeletonTheme
          color={themeConfiguration.loadingSkeleton.color}
          highlightColor={themeConfiguration.loadingSkeleton.highlightColor}
        >
          {children}
        </SkeletonTheme>
      </ThemeContext.Provider>
    </StyledComponentsThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
