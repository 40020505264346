import AddVehicleLink from "components/permitHolders/components/AddVehicleLink";
import PropTypes from "prop-types";
import VehiclePicker, {
  SPACE_BETWEEN_CARDS,
} from "components/permitHolders/components/VehiclePicker";
import { getAvailableVehicleOptions } from "components/permitHolders/helpers/vehicles";
import ListSkeleton from "domain/private/components/ListSkeleton";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useVehicleTabOperations from "../../hooks/useVehiclesTabOperations";
import { usePermitHoldersDetailsContext } from "../../Provider";

const VehiclesPicker = ({ smallVersion }) => {
  const {
    editVehicle,
    makeEditable,
    removeVehicle,
    addVehicle,
  } = useVehicleTabOperations();
  const { t } = useTranslation();
  const {
    isEditable,
    vehiclesTab: { addedVehicles, vehiclesOptions, isLoading },
  } = usePermitHoldersDetailsContext();

  const availableVehicleOptions = useMemo(
    () => getAvailableVehicleOptions(addedVehicles, vehiclesOptions),
    [addedVehicles, vehiclesOptions]
  );

  if (isLoading) {
    return (
      <ListSkeleton height={120} itemsNumber={1} itemGapWidth={SPACE_BETWEEN_CARDS} />
    );
  }

  return (
    <div>
      {addedVehicles.map((vehicle, index) => (
        <VehiclePicker
          key={vehicle.licencePlate}
          position={index}
          editVehicle={editVehicle}
          makeEditable={makeEditable}
          removeVehicle={removeVehicle}
          canRemove={index > 0 && index === addedVehicles.length - 1}
          vehicle={vehicle}
          allVehicleOptions={vehiclesOptions}
          availableVehicleOptions={availableVehicleOptions}
          allowOperations={isEditable}
          isLoading={isLoading}
          smallVersion={smallVersion}
        />
      ))}
      {isEditable && (
        <AddVehicleLink onClick={addVehicle} className="mt-30">
          {t("10479") /* Adicionar outro veículo */}
        </AddVehicleLink>
      )}
    </div>
  );
};

export default VehiclesPicker;

VehiclesPicker.propTypes = {
  smallVersion: PropTypes.bool,
};

VehiclesPicker.defaultProps = {
  smallVersion: false,
};
