import useClickOutside from "hooks/useClickOutside";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import PopoverContainer from "../Popover/Container";
import IconButton from "./index";

const positions = { left: "left", right: "right" };

const IconButtonPopover = ({ PopoverComponent, buttonClassName, position, ...other }) => {
  const [isPopoverDisplayed, setIsPopoverDisplayed] = useState(false);
  const buttonRef = useRef();

  useClickOutside(buttonRef, setIsPopoverDisplayed);

  return (
    <Container>
      <IconButton
        ref={buttonRef}
        className={buttonClassName}
        onClick={setIsPopoverDisplayed}
        {...other}
      />
      {isPopoverDisplayed && (
        <StyledPopoverContainer position={position}>
          {PopoverComponent}
        </StyledPopoverContainer>
      )}
    </Container>
  );
};

export default IconButtonPopover;

IconButtonPopover.propTypes = {
  PopoverComponent: PropTypes.node.isRequired,
  buttonClassName: PropTypes.string,
  position: PropTypes.oneOf(Object.values(positions)),
};

IconButtonPopover.defaultProps = {
  buttonClassName: "",
  position: positions.left,
};

const getPositionStyle = (position) =>
  ({
    [positions.left]: css`
      right: 0;
    `,
    [positions.right]: css`
      left: 0;
    `,
  }[position]);

const Container = styled.div`
  position: relative;
`;

const StyledPopoverContainer = styled(PopoverContainer)`
  position: absolute;
  margin-top: 10px;

  ${({ position }) => getPositionStyle(position)}
`;
