import { feeTypesMapping } from "../../../../utils/fees";

export const getReadableTime = (
  hours,
  minutes,
  t,
  { alwaysShowHours = false, padMinutes = false } = {}
) => {
  let readableTime = "";

  if (hours || alwaysShowHours) {
    readableTime += `${hours}h`.padStart(3, "0");
  }

  if (!minutes && !padMinutes) {
    return readableTime;
  }

  readableTime += `${minutes}`.padStart(2, "0");

  if (!hours) {
    readableTime += t("1553"); /* min */
  }

  return readableTime;
};

export const getFilteredPopupFees = (fees) =>
  fees.filter((fee) => fee.id !== feeTypesMapping.minutes || fee.timeTables.length);
