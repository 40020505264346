import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { Text, Button } from "iparque-components";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import Link from "components/Link";
import { routesMapping } from "utils/routes";
import PasswordRequirementsPopup from "./PasswordRequirementsPopup";

const StyledInfoIcon = styled(InfoIcon)`
  width: 100px;
  fill: ${({ theme }) => theme.color.dark};
`;

const PasswordRequirementsWarningPopup = ({
  onClose,
  visible,
  onContinue,
  requirementsDeadline,
}) => {
  const { t } = useTranslation();

  return (
    <PasswordRequirementsPopup
      button={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Button size="xs" onClick={onContinue} color="secondary">
          {t("3960") /* Continuar */}
        </Button>
      }
      icon={<StyledInfoIcon className="mr-15" />}
      onClose={onClose}
      visible={visible}
    >
      <div>
        <Text elementType="p" color="secondary" variant="body6">
          {t("9667") /* Caro(a) condutor(a) */}
        </Text>
        <Text className="mt-10" elementType="p" color="secondary" variant="body6">
          {
            t(
              "9666"
            ) /* Devido a uma revisão da nossa política de segurança, informamos que a tua senha deverá ser alterada de forma a cumprir com os novos requisitos definidos. */
          }
        </Text>
        <Text color="secondary" variant="body6">
          <b>
            <Link
              underline="always"
              to={routesMapping.backofficeProfile}
              onClick={onContinue}
            >
              {t("9665") /* Clica aqui para alterares a tua senha agora */}
            </Link>
          </b>
        </Text>
        <Text className="mt-10" color="secondary" variant="body6">
          <Trans
            i18nKey="8013"
            tOptions={{ interpolation: { escapeValue: false } }}
            values={{ date: requirementsDeadline }}
            components={{ b: <b /> }}
          />
          {/*  Esta alteração deve ser efetuada até <b>{{date}}</b>. */}
        </Text>
      </div>
    </PasswordRequirementsPopup>
  );
};

PasswordRequirementsWarningPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onContinue: PropTypes.func.isRequired,
  requirementsDeadline: PropTypes.string.isRequired,
};

export default PasswordRequirementsWarningPopup;
