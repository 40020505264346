import FlexBox from "components/FlexBox";
import Text from "components/Text";
import LabelValueCardCell, {
  LABEL_VALUE_CARD_VALUE_VARIANT,
} from "domain/private/components/cardList/LabelValueCell";
import useLanguage from "hooks/useLanguage";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { convertDateTimeToReadableDate } from "utils/dateTime";
import ListCard from "../../ListCard";

const HistoryCard = ({ dateTime, operation, userName, ...other }) => {
  const { t } = useTranslation();
  const language = useLanguage();

  return (
    <ListCard {...other}>
      <FlexBox flexDirection="column" className="fill">
        <FlexBox flexDirection="column">
          <Text numberOfLines={2} variant={LABEL_VALUE_CARD_VALUE_VARIANT}>
            {operation}
          </Text>
          <Text variant="body4">
            {convertDateTimeToReadableDate(dateTime, null, language)}
          </Text>
        </FlexBox>
        <FlexBox className="mt-10">
          <LabelValueCardCell label={t("10557") /* Realizado por */} value={userName} />
        </FlexBox>
      </FlexBox>
    </ListCard>
  );
};

HistoryCard.propTypes = {
  dateTime: PropTypes.string.isRequired,
  operation: PropTypes.string.isRequired,
  userName: PropTypes.string,
};

HistoryCard.defaultProps = {
  userName: "-",
};

export default HistoryCard;
