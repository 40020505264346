import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import useLanguage from "hooks/useLanguage";
import PropTypes from "prop-types";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getPermitHolderDetails } from "requests/permitHolders";
import { personalDataDefaultValues } from "./helper";
import { actionTypes } from "./store/actions";
import reducer from "./store/reducer";

const initialState = {
  initialRequestStatus: { isLoading: true, hasError: false },
  isEditable: false,
  reference: "",
  token: "",
  city: "",
  createdDateTime: undefined,
  state: { id: undefined, designation: "" },
  permit: {
    id: undefined,
    occupationTypeId: undefined,
    type: { id: undefined, designation: "" },
  },
  personalData: personalDataDefaultValues,
  warnings: {},
  vehiclesTab: {
    isLoading: true,
    hasError: false,
    vehiclesOptions: [],
    addedVehicles: [],
    initialAddedVehicles: [],
    vehiclesRegimes: [],
    activeSubscriptions: [],
  },
  locationsTab: { isLoading: false, hasError: false, locations: [], zones: [] },
  subscriptionsTab: {
    list: {
      items: [],
      isLoading: true,
      isRequested: false,
      hasError: false,
      onFinishedScroll: undefined,
      update: () => {},
    },
    arePaymentMethodsLoading: false,
    paymentMethods: [],
    subscriptionsWithPaymentDetailsOpen: [],
  },
  historyTab: {
    list: {
      items: [],
      isLoading: true,
      isRequested: false,
      hasError: false,
      onFinishedScroll: undefined,
      update: () => {},
    },
  },
  documentsTab: {
    list: {
      items: [],
      isLoading: true,
      isRequested: false,
      hasError: false,
      onFinishedScroll: undefined,
      update: () => {},
    },
  },
};

const PermitHoldersDetailsContext = createContext({
  ...initialState,
  tabsRef: undefined,
});

const PermitHoldersDetailsProvider = ({ children }) => {
  const tabsRef = useRef();
  const { setIsLoading } = useAppContext();
  const language = useLanguage();
  const { t } = useTranslation();
  const { defaultEntityId: entityId, driverHash } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { state: locationState } = useLocation();
  const { permitHolderId } = useParams();
  const history = useHistory();

  const values = useMemo(() => ({ ...state, tabsRef, dispatch }), [state]);

  useEffect(() => {
    (async () => {
      let personalData;
      let clientId;
      let reference;
      let token;
      let createdDateTime;
      let city;
      let permit;
      let processState;
      let warnings;

      if (locationState) {
        ({
          personalData,
          clientId,
          reference,
          token,
          createdDateTime,
          city,
          permit,
          state: processState,
          warnings,
        } = locationState);

        history.replace({ state: undefined }); // Remove from route state to force get data from server when page is reloaded
      } else {
        setIsLoading(true);

        const details = await getPermitHolderDetails(
          entityId,
          driverHash,
          permitHolderId,
          {},
          { language, t }
        );

        setIsLoading(false);

        if (!details) {
          dispatch({
            type: actionTypes.SET_INITIAL_REQUEST_STATUS,
            payload: { isLoading: false, hasError: true },
          });
          return;
        }

        ({
          personalData,
          clientId,
          reference,
          token,
          createdDateTime,
          city,
          permit,
          state: processState,
          warnings,
        } = details);
      }

      dispatch([
        { type: actionTypes.SET_PERSONAL_DATA, payload: personalData },
        {
          type: actionTypes.SET_STATIC_INFO,
          payload: {
            id: permitHolderId,
            token,
            reference,
            createdDateTime,
            city,
            permit,
            clientId,
          },
        },
        {
          type: actionTypes.SET_STATE,
          payload: processState,
        },
        {
          type: actionTypes.SET_WARNINGS,
          payload: warnings,
        },
        {
          type: actionTypes.SET_INITIAL_REQUEST_STATUS,
          payload: { isLoading: false, hasError: false },
        },
      ]);
    })();
  }, [permitHolderId]);

  return (
    <PermitHoldersDetailsContext.Provider value={values}>
      {children}
    </PermitHoldersDetailsContext.Provider>
  );
};

export default PermitHoldersDetailsProvider;

export const usePermitHoldersDetailsContext = () => {
  return useContext(PermitHoldersDetailsContext);
};

PermitHoldersDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
