import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Text } from "iparque-components";
import { useTranslation } from "react-i18next";
import { useBackofficeThemeContext } from "./ThemeProvider";

const CORNER_BORDER_WIDTH = 13;
const CORNER_SIDE_WIDTH = 40;
const ANIMATION_TIME = 0.2;
const CONTENT_CONTAINER_PADDING = 15;

export const CONTENT_CONTAINER_HORIZONTAL_SPACING =
  CORNER_BORDER_WIDTH + CONTENT_CONTAINER_PADDING;

const ContentBorderBox = React.forwardRef(
  (
    {
      children,
      cornerColor,
      textColor,
      className,
      title,
      backCallback,
      topRightCornerContent,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const hasBackCallback = typeof backCallback === "function";
    const { backofficeTheme } = useBackofficeThemeContext();
    const processedBackCallback = hasBackCallback ? backCallback : () => {};

    let processedColor = cornerColor;

    if (!processedColor) {
      processedColor = backofficeTheme.color.primary;
    }

    return (
      <Box ref={ref} className={className} hasBackCallback={hasBackCallback}>
        <Title
          hasBackCallback={hasBackCallback}
          textColor={textColor}
          color="primary"
          variant="body7"
        >
          {title}
        </Title>
        <TopLeftCorner
          hasBackCallback={hasBackCallback}
          cornerColor={processedColor}
          title={t("9") /* Voltar */}
          onClick={processedBackCallback}
        />
        <TopRightContainer>{topRightCornerContent}</TopRightContainer>
        <BottomRightCorner cornerColor={processedColor} />
        <ContentContainer>{children}</ContentContainer>
      </Box>
    );
  }
);

ContentBorderBox.defaultProps = {
  className: "",
  title: "",
  backCallback: null,
  cornerColor: null,
  textColor: null,
  topRightCornerContent: null,
};

ContentBorderBox.propTypes = {
  children: PropTypes.node.isRequired,
  cornerColor: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.node,
  backCallback: PropTypes.func,
  topRightCornerContent: PropTypes.node,
};

export default ContentBorderBox;

const Box = styled.div`
  position: relative;
  padding: ${CORNER_BORDER_WIDTH}px;
  padding-left: ${CORNER_BORDER_WIDTH}px;
`;

const TopLeftCorner = styled.div`
  position: absolute;
  left: 0;

  ${({ hasBackCallback, cornerColor }) =>
    hasBackCallback
      ? css`
          width: 18px;
          height: 18px;
          border-left: 6px solid ${cornerColor};
          border-top: 6px solid ${cornerColor};
          transform: rotate(-45deg);
          transition: all ${ANIMATION_TIME}s ease;
          top: 30px;
          cursor: pointer;
          left: 6px;
        `
      : css`
          transform: rotate(0);
          transition: all ${ANIMATION_TIME}s ease;
          top: 0;
          border-left: ${CORNER_BORDER_WIDTH}px solid ${cornerColor};
          border-top: ${CORNER_BORDER_WIDTH}px solid ${cornerColor};
          width: ${CORNER_SIDE_WIDTH}px;
          height: ${CORNER_SIDE_WIDTH}px;
        `}
`;

const TopRightContainer = styled.div`
  position: absolute;
  right: ${CONTENT_CONTAINER_HORIZONTAL_SPACING}px;
  bottom: calc(100% - ${CORNER_BORDER_WIDTH}px);
`;

const BottomRightCorner = styled.div`
  width: ${CORNER_SIDE_WIDTH}px;
  height: ${CORNER_SIDE_WIDTH}px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: ${CORNER_BORDER_WIDTH}px solid ${({ cornerColor }) => cornerColor};
  border-right: ${CORNER_BORDER_WIDTH}px solid ${({ cornerColor }) => cornerColor};
  pointer-events: none;
`;

const Title = styled(Text)`
  text-transform: uppercase;
  position: absolute;
  top: 0;
  line-height: ${CORNER_BORDER_WIDTH}px;
  left: ${({ hasBackCallback }) =>
    hasBackCallback ? CONTENT_CONTAINER_HORIZONTAL_SPACING : CORNER_SIDE_WIDTH + 20}px;
  transition: all ${ANIMATION_TIME}s ease;
  color: ${({ textColor }) => textColor};
`;

const ContentContainer = styled.div`
  padding: ${CONTENT_CONTAINER_PADDING}px;
  min-height: 40px;
`;
