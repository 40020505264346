import AbstractDataSource from "./abstractDataSource";

export default class IdentificationDocumentsDataSource extends AbstractDataSource {
  async getAllPrefixes(
    entityId,
    documentTypeId,
    params = {},
    headers = {},
    useCache = false
  ) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/identificationDocuments/${documentTypeId}/prefixes`,
      params,
      headers,
      useCache
    );
  }

  async getAllDrivingLicenceCategories(
    entityId,
    params = {},
    headers = {},
    useCache = false
  ) {
    return this.iParqueClient.GET(
      `v1/entities/${entityId}/drivingLicences/categories`,
      params,
      headers,
      useCache
    );
  }
}
