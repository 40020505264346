import FlexBox from "components/FlexBox";
import useLanguage from "hooks/useLanguage";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import lightTheme from "themes/normal";
import { formatDistance } from "utils/distance";
import { formatMoney } from "utils/money";
import { useCitiesProvider } from "./Provider";

const CityItem = ({ city, selected, className }) => {
  const { t } = useTranslation();
  const { setDefaultEntity } = useCitiesProvider();
  const language = useLanguage();

  const hasDistances =
    !Number.isNaN(city?.distance) &&
    city?.distance !== undefined &&
    city?.distance !== null;

  return (
    <CityContainer
      className={className}
      alignCenter={!city?.distance}
      selected={selected}
      onClick={() => setDefaultEntity(city.entityId)}
    >
      <Logo src={city.entityLogo} alt={city.name} />
      <FlexBox flexDirection="column" justifyContent="spaceBetween" className="fill">
        <div>
          {city?.name ? (
            <>
              <Text variant="body6" color="primary">
                {city.name}
              </Text>
              <Text variant="body11" color="primary">
                {city.entityName}
              </Text>
            </>
          ) : (
            <Text variant="body6" color="primary">
              {city.entityName}
            </Text>
          )}
        </div>
        {hasDistances && (
          <Text variant="body11" color="primary">
            {`${t("9485") /* Distância */}: <${formatDistance(city.distance)}`}
          </Text>
        )}
      </FlexBox>
      <MoneyWrapper>
        <RightText variant="body10" color="primary">
          {t("8395") /* Saldo atual */}
        </RightText>
        <RightText variant="body2" color="primary">
          {formatMoney(city.balance || 0, { centsFormat: false, country: language })}
        </RightText>
      </MoneyWrapper>
    </CityContainer>
  );
};

CityItem.defaultProps = {
  className: null,
  selected: false,
};

CityItem.propTypes = {
  city: PropTypes.shape({
    name: PropTypes.string,
    entityName: PropTypes.string.isRequired,
    entityId: PropTypes.number.isRequired,
    entityLogo: PropTypes.string.isRequired,
    distance: PropTypes.number,
    balance: PropTypes.number,
  }).isRequired,
  selected: PropTypes.bool,
  className: PropTypes.string,
};

export default CityItem;

const CityContainer = styled.div`
  display: flex;
  align-items: ${({ alignCenter }) => (alignCenter ? "center" : "stretch")};
  padding: 10px 15px;
  cursor: pointer;
  border: ;
  background-color: ${({ theme }) => theme.box.entitiesBackgroundColor};
  border: ${({ selected, theme }) =>
    selected
      ? `solid 1px ${theme.box.selectedEntityBoderderColor}`
      : `solid 1px ${theme.box.entitiesBorderColor}`};
`;

const Logo = styled.img`
  margin-right: 20px;
  padding: 10px;
  box-sizing: border-box;
  width: 80px;
  height: 80px;
  object-fit: contain;
  object-position: left;
  background-color: ${lightTheme.box.entitiesBackgroundColor};
`;

const RightText = styled(Text)`
  text-align: right;
`;

const MoneyWrapper = styled.div`
  margin-top: auto;
`;
