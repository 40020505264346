import { userContractsMapping } from "./contracts";
import { deleteCookie, getCookie, setCookie } from "./cookie";
import { compareServerDateTimes, getCurrentDateTimeInServerFormat } from "./dateTime";
import env, { envs } from "./environment";
import { isEmpty } from "./general";

const tokenCookieName = env.select({
  [envs.development]: "token",
  [envs.production]: "a28f21ae-80fe-4427-b21c-a5739c15b625",
});

const refreshTokenCookieName = env.select({
  [envs.development]: "refreshToken",
  [envs.production]: "80d67077-f30a-4040-90bb-9519fa8ffdbe",
});

const localStorageKey = env.select({
  [envs.development]: "driverSession",
  [envs.production]: "63f0f093-a301-4a61-a4b3-8a0b5060b77a",
});

export const getUser = () => {
  return JSON.parse(localStorage.getItem(localStorageKey)) || {};
};

export const removeDefaultEntity = () => {
  const user = getUser();

  return localStorage.setItem(
    localStorageKey,
    JSON.stringify({
      ...user,
      driver: {
        ...user.driver,
        defaultEntityId: null,
        defaultEntity: undefined,
      },
    })
  );
};

export const setUserDefaultEntity = (defaultEntity) => {
  const user = getUser();

  if (isEmpty(user)) {
    return null;
  }

  return localStorage.setItem(
    localStorageKey,
    JSON.stringify({
      ...user,
      driver: {
        ...user.driver,
        defaultEntityId: defaultEntity.id,
        defaultEntity,
      },
    })
  );
};

export const updateBalance = (balance) => {
  const user = getUser();

  if (user?.driver?.defaultEntity === undefined) {
    return null;
  }

  return localStorage.setItem(
    localStorageKey,
    JSON.stringify({
      ...user,
      driver: {
        ...user.driver,
        defaultEntity: {
          ...user.driver.defaultEntity,
          balance,
        },
      },
    })
  );
};

export const updateUser = (newUserInfo) => {
  const user = getUser();

  if (user?.driver?.defaultEntity === undefined) {
    return null;
  }

  return localStorage.setItem(
    localStorageKey,
    JSON.stringify({
      ...user,
      driver: {
        ...user.driver,
        ...newUserInfo,
      },
    })
  );
};

export const getDefaultEntityId = () => {
  const user = getUser();

  if (isEmpty(user)) {
    return null;
  }

  return user?.driver?.defaultEntityId || null;
};

export const getDefaultEntity = () => {
  const user = getUser();

  if (isEmpty(user)) {
    return null;
  }

  return user?.driver?.defaultEntity || null;
};

export const getEntityConfiguration = (configurationKey) => {
  const configurations = getDefaultEntity()?.configurations || [];

  for (let index = 0; index < configurations.length; index += 1) {
    const configuration = configurations[index];

    if (configuration.key === configurationKey) {
      return configuration.value;
    }
  }

  return null;
};

const setUser = (user) => {
  localStorage.setItem(localStorageKey, JSON.stringify(user));
};

export const getTokens = () => {
  const token = getCookie(tokenCookieName) || null;
  const refreshToken = getCookie(refreshTokenCookieName) || null;

  return { token, refreshToken };
};

export const removeSession = () => {
  deleteCookie(tokenCookieName);
  deleteCookie(refreshTokenCookieName);
  localStorage.removeItem(localStorageKey);
};

export const checkDefaultEntity = (user) => {
  return user?.driver?.defaultEntityId ?? false;
};

const getContracts = () => {
  const user = getUser();

  return user?.contracts ?? [];
};

export const getContractById = (id, contracts = getContracts()) => {
  for (let index = 0; index < contracts.length; index += 1) {
    const contract = contracts[index];

    if (contract.id === id) {
      return contract;
    }
  }

  return null;
};

export const isGeneralDataProtectionRegulationAccepted = (
  generalDataProtectionRegulationContract = getContractById(
    userContractsMapping.generalDataProtectionRegulation
  )
) => {
  if (!generalDataProtectionRegulationContract) {
    return false;
  }

  const currentDateTime = getCurrentDateTimeInServerFormat();

  const isAccepted = !!(
    generalDataProtectionRegulationContract.acceptanceDate &&
    compareServerDateTimes(
      generalDataProtectionRegulationContract.acceptanceDate,
      currentDateTime
    ) >= 0
  );

  const isNotExpired = !!(
    !generalDataProtectionRegulationContract.expirationDate ||
    compareServerDateTimes(
      currentDateTime,
      generalDataProtectionRegulationContract.expirationDate
    ) >= 0
  );

  return isAccepted && isNotExpired;
};

export const changeDataProtectionRegulationAgreement = (isAccepted) => {
  const user = getUser();
  const contracts = getContracts();

  if (isEmpty(user)) {
    return null;
  }

  const updatedContracts = contracts.map((contract) => {
    if (contract.id !== userContractsMapping.generalDataProtectionRegulation) {
      return contract;
    }

    if (isAccepted) {
      return { ...contract, acceptanceDate: getCurrentDateTimeInServerFormat() };
    }

    return { ...contract, acceptanceDate: null };
  });

  return localStorage.setItem(
    localStorageKey,
    JSON.stringify({
      ...user,
      contracts: updatedContracts,
    })
  );
};

export const setSession = (response, callback = null) => {
  if (response.token) {
    setCookie(
      tokenCookieName,
      response.token,
      parseInt(process.env.REACT_APP_TOKEN_EXPIRATION_MINUTES, 10),
      {
        sameSite: "Strict",
        secure: process.env.REACT_APP_SECURE_COOKIES === "true",
      }
    );

    delete response.token;
  }

  if (response.refreshToken) {
    setCookie(
      refreshTokenCookieName,
      response.refreshToken,
      parseInt(process.env.REACT_APP_REFRESH_TOKEN_EXPIRATION_MINUTES, 10),
      {
        sameSite: "Strict",
        secure: process.env.REACT_APP_SECURE_COOKIES === "true",
      }
    );

    delete response.refreshToken;
  }

  setUser(response);

  if (callback) {
    callback();
  }

  const event = new Event("changeUserData");
  document.dispatchEvent(event);
};
