import { CONTENT_CONTAINER_HORIZONTAL_SPACING } from "domain/private/components/ContentBorderBox";
import { Search } from "iparque-components";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import useInfiniteList from "../../../../hooks/useInfiniteList";
import useSearchField from "../../../../hooks/useSearchField";
import ListSkeleton from "../../components/ListSkeleton";
import { NOTICE_PLACEHOLDER_HEIGHT } from "../controller";
import { allNoticesDefaultProps, allNoticesPropTypes } from "../propTypes";
import NoticeCard from "./Card";

const AllNotices = ({ getNoticesCallback, className }) => {
  const { t } = useTranslation();
  const { search, debounceSearch, onSearchChange } = useSearchField();
  const {
    items,
    isLoading,
    hasError,
    isRequested,
    onFinishedScroll,
    setSearch,
  } = useInfiniteList(getNoticesCallback);

  useEffect(() => {
    if (search === undefined) {
      return;
    }

    setSearch({ licensePlate: debounceSearch });
  }, [debounceSearch]);

  if (hasError || (isRequested && !isLoading && !items.length && search === undefined)) {
    return null;
  }

  const shouldRenderSearch = items.length > 0 || search !== undefined;

  return (
    <Container className={`${className} mt-30`}>
      {shouldRenderSearch && (
        <Search
          className="mb-15 mt-10"
          value={search}
          name="search-notice"
          placeholder={t("8455") /* Pesquisa por matrícula */}
          onChange={onSearchChange}
          disabled
        />
      )}
      <ListContainer className="mt-15" onScroll={onFinishedScroll}>
        {isLoading ? (
          <ListSkeleton itemsNumber={3} height={NOTICE_PLACEHOLDER_HEIGHT} />
        ) : (
          <>
            {items.map((item) => (
              <NoticeCard key={`${item.number}-all`} paid={false} {...item} />
            ))}
          </>
        )}
      </ListContainer>
    </Container>
  );
};

export default AllNotices;

AllNotices.propTypes = allNoticesPropTypes;

AllNotices.defaultProps = allNoticesDefaultProps;

const Container = styled.div`
  margin: 0 ${CONTENT_CONTAINER_HORIZONTAL_SPACING}px;

  @media (max-width: ${breakpoints.lg}) {
    margin: 0;
  }
`;

const ListContainer = styled.div`
  max-height: 800px;
  overflow: auto;
`;
