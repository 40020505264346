import React from "react";
import HistoryList from "../../../components/history/List";
import TabIdentification from "../../../components/TabIdentification";
import TabTitle from "../../../components/Title";
import { tabsIdsMap } from "../../../helper";
import TabDetailsOperations from "../../operations/TabDetails";
import Card from "../../../components/history/smallDevices/Card";

const HistoryTab = () => {
  return (
    <>
      <TabDetailsOperations className="mb-10" />
      <TabIdentification tabId={tabsIdsMap.history} />
      <TabTitle className="mt-20" tabId={tabsIdsMap.history} />
      <HistoryList Card={Card} />
    </>
  );
};

export default HistoryTab;
