export const entityIds = {
  parquesTejo: 45,
};

export const entityConfigurationsMapping = {
  onlyParkWithActiveGeographicLocationActive:
    "ONLY_PARK_WITH_ACTIVE_GEOGRAPHIC_LOCATION_ACTIVE",
  canParkWithoutBalance: "CAN_PARK_WITHOUT_BALANCE",
  iban: "IBAN",
  canCreatePublicPermits: "CAN_CREATE_PUBLIC_PERMITS",
};

export const entityThemes = {
  light: 1,
  dark: 2,
};

export const entityThemeFields = {
  primaryColor: 1,
  secondaryColor: 2,
};

export const getCitiesFromEntities = (entities) => {
  return (
    Array.prototype.concat.apply(
      [],
      entities.map((entity) => {
        return entity.cities.map((city) => {
          return {
            ...city,
            entityId: entity.id,
            entityName: entity.name,
            entityLogo: entity.logo,
            contracts: entity.contracts,
            balance: entity.balance.amount,
          };
        });
      })
    ) || []
  );
};

export const getConfigValue = (configs, targetConfig) => {
  for (let index = 0; index < configs.length; index += 1) {
    const config = configs[index];

    if (
      config.type === "boolean" &&
      ["0", "1"].includes(config.value) &&
      config.key === targetConfig
    ) {
      return parseInt(config.value, 10) === 1;
    }

    if (config.key === targetConfig) {
      return !!config.value;
    }
  }

  return false;
};
