import ClickableDiv from "components/ClickableDiv";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const AddVehicleLink = ({ onClick, children, className }) => {
  return (
    <StyledClickableDiv onClick={onClick} className={className}>
      <Text variant="body2">{children}</Text>
    </StyledClickableDiv>
  );
};

export default AddVehicleLink;

AddVehicleLink.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

AddVehicleLink.defaultProps = {
  className: undefined,
};

const StyledClickableDiv = styled(ClickableDiv)`
  align-self: flex-start;

  > span {
    text-decoration: underline;
  }
`;
