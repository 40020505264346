import { useState, useEffect, useCallback } from "react";

export const permissionResults = {
  granted: "granted",
  prompt: "prompt",
  denied: "denied",
};

const useNavigatorPermissions = (name, configuration = {}) => {
  const [error, setError] = useState(false);
  const [result, setResult] = useState("");

  const onPermissionRequested = useCallback(
    (event) => setResult(event.currentTarget),
    []
  );

  useEffect(() => {
    if (window?.navigator?.permissions) {
      window.navigator.permissions.query({ name, ...configuration }).then((status) => {
        // eslint-disable-next-line no-param-reassign
        status.onchange = onPermissionRequested;

        setResult(status.state);
      });
    } else {
      setError(true);
    }
  }, [name, configuration]);

  return { permissionResult: result, hasErrorCheckingPermission: error };
};

export default useNavigatorPermissions;
