import Button from "components/buttons/Button";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import styled from "styled-components";
import { operationsMap } from "../../helper";
import useOperations from "../../hooks/useOperations";

const TabOperations = ({ className }) => {
  const operations = useOperations();

  const localOperations = useMemo(
    () => operations?.filter((operation) => operation.id !== operationsMap.edit) || [],
    [operations]
  );

  return (
    <Container className={className}>
      {localOperations.map((operation) => (
        <Button
          key={operation.id}
          color={operation.color}
          onClick={operation.action}
          className="operation-button"
        >
          {operation.label}
        </Button>
      ))}
    </Container>
  );
};

export default TabOperations;

TabOperations.propTypes = {
  className: PropTypes.string.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .operation-button {
    width: 280px;

    :not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`;
