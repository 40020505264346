import { getPin } from "./pin";

class MapController {
  constructor(google, map, icon = null, { theme }) {
    this.google = google;
    this.map = map;
    this.icon = icon;
    this.markers = [];
    this.theme = theme;
    this.bounds = new this.google.maps.LatLngBounds();

    if (icon === null) {
      this.icon = getPin(google, { color: this.theme.color.primary });
    } else {
      this.icon = icon;
    }
  }

  renderMarker(location, args = { draggable: false }) {
    if (location.lat === null || location.lng === null) {
      return;
    }

    const marker = new this.google.maps.Marker({
      position: { lat: location.lat, lng: location.lng },
      map: this.map,
      draggable: args.draggable,
      title: location?.name,
      icon: this.icon,
    });

    this.bounds.extend(location);
    this.markers.push(marker);
  }

  renderMarkers(locations) {
    for (let i = 0; i < locations.length; i += 1) {
      const contentPosition = locations[i];

      this.renderMarker(contentPosition);
    }
  }

  fitCenter() {
    if (this.bounds) {
      this.map.fitBounds(this.bounds);
    }
  }
}

export default MapController;
