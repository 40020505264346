import Button from "components/buttons/Button";
import { Heading } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAuth } from "../../../components/AuthProvider";
import { themesMapping } from "../../../themes/theme";
import { formatMoney } from "../../../utils/money";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import useAccountMovements from "./hooks/useAccountMovements";

const Container = styled.div`
  padding: 25px 15px;
  background: ${({ color }) => color};
  display: flex;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
`;

const Title = styled(Heading)`
  line-height: 30px;
`;

const Body = styled(Heading)`
  font-weight: ${({ theme }) => theme.typography.weight.regular};
`;

const ButtonContainer = styled.div`
  margin-left: auto;
`;

const CurrentBalance = () => {
  const { user } = useAuth();
  const { charge } = useAccountMovements();
  const { t } = useTranslation();
  const { backofficeThemeName, backofficeTheme } = useBackofficeThemeContext();
  const userAmount = user.driver?.defaultEntity?.balance?.amount;
  const hasBalance = userAmount > 0;

  const getBoxColor = () => {
    const opacity = 10;

    if (backofficeThemeName !== themesMapping.normal) {
      return backofficeTheme.box.color;
    }

    if (hasBalance) {
      return `${backofficeTheme.color.success}${opacity}`;
    }

    return `${backofficeTheme.color.danger}${opacity}`;
  };

  return (
    <>
      {!hasBalance && (
        <InfoContainer>
          <Title color="tertiary" variant="h3">
            {t("9650") /* Estás com a luz da reserva acesa! */}
          </Title>
          <Body color="tertiary" variant="h7" className="mt-10">
            {t("10844") /* Vamos abastecer o saldo? */}
          </Body>
        </InfoContainer>
      )}
      <Container color={getBoxColor()}>
        <Heading color={hasBalance ? "tertiary" : "danger"}>
          {formatMoney(userAmount, { centsFormat: false })}
        </Heading>
        <ButtonContainer>
          <Button onClick={charge}>{t("8457") /* Carregar */}</Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default CurrentBalance;
