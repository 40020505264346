/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo, useState } from "react";
import Notices from "./Notices";
import SmallDevicesNotices from "./smallDevices/Notices";
import MediumDevicesNotices from "./mediumDevices/Notices";
import { useAppContext } from "../../../components/AppProvider";
import { breakpointsMapping, smallDevicesBreakpoints } from "../../../utils/breakpoints";
import { useAuth } from "../../../components/AuthProvider";
import { getNotices, getVehicles, processNotices } from "./controller";

const NoticesPage = () => {
  const { breakpoint } = useAppContext();
  const { defaultEntityId, updateBalance, driverHash } = useAuth();
  const [vehicles, setVehicles] = useState([]);

  const getNoticesCallback = useCallback(async (params, cancelToken) => {
    updateBalance();

    const [noticesResponse, vehiclesResponse] = await Promise.all([
      getNotices(defaultEntityId, driverHash, params, cancelToken),
      getVehicles(driverHash, cancelToken),
    ]);

    setVehicles(vehiclesResponse.items);

    return {
      ...noticesResponse,
      items: processNotices(noticesResponse.items || [], vehiclesResponse.items),
    };
  }, []);

  const { validatedLicencePlates, unvalidatedLicencePlates } = useMemo(() => {
    const validated = [];
    const unvalidated = [];

    vehicles.forEach((vehicle) => {
      if (vehicle.sellingDate === null && vehicle.vin === null) {
        unvalidated.push(vehicle.licensePlate);
      } else if (vehicle.sellingDate === null && vehicle.vin !== null) {
        validated.push(vehicle.licensePlate);
      }
    });

    return { validatedLicencePlates: validated, unvalidatedLicencePlates: unvalidated };
  }, [vehicles]);

  const chooseViewBasedOnDevice = () => {
    const props = {
      getNoticesCallback,
      validatedLicencePlates,
      unvalidatedLicencePlates,
    };

    if (smallDevicesBreakpoints.includes(breakpoint)) {
      return <SmallDevicesNotices {...props} />;
    }

    if (breakpointsMapping.noBreakpoint === breakpoint) {
      return <MediumDevicesNotices {...props} />;
    }

    return <Notices {...props} />;
  };

  return <>{chooseViewBasedOnDevice()}</>;
};

export default NoticesPage;
