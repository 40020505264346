import useCountries from "hooks/useCountries";
import useDistricts from "hooks/useDistricts";
import { useEffect, useState } from "react";
import { getPartyTypes } from "requests/clients";

const useFormOptions = (entityId) => {
  const { countryOptions } = useCountries({
    entityId,
  });
  const { districtsOptions } = useDistricts(entityId);
  const [partyTypesOptions, setPartyTypesOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const partyTypesResponse = await getPartyTypes(entityId, { noLimit: true }, true);

      setPartyTypesOptions(
        partyTypesResponse.items.map((partyType) => ({
          label: partyType.designation,
          value: partyType.id,
        }))
      );
    })();
  }, []);

  return {
    countries: countryOptions,
    partyTypes: partyTypesOptions,
    districts: districtsOptions,
  };
};

export default useFormOptions;
