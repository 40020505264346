export const pageContexts = {
  login: "login",
  registry: "registry",
  registryConclusion: "registryConclusion",
  registryActivation: "registryActivation",
  passwordRecovery: "passwordRecovery",
  passwordDefinition: "passwordDefinition",
};

// eslint-disable-next-line no-useless-escape
export const iparqueCookiesAcceptanceName = "iPCookies";
export const cookieDurationInMinutes = 525600; // 365 days
