import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text, Switch } from "iparque-components";
import Skeleton from "../../../../components/Skeleton";
import { useSettingsProvider } from "../Provider";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const SwitchContainer = styled.div`
  margin-left: auto;
`;

const SwitchSkeleton = styled(Skeleton)`
  span {
    border-radius: 20px;
  }
`;

const SwitchField = ({
  title,
  description,
  definitionKey,
  checked,
  className,
  onChange,
  avoidLoading,
}) => {
  const { isSettingsLoading } = useSettingsProvider();

  return (
    <Container className={className}>
      <div className="mr-10">
        <Text variant="body9">{title}</Text>
        {React.isValidElement(description) ? (
          React.cloneElement(description, {
            className: "mt-5",
          })
        ) : (
          <Text className="mt-5" variant="body10">
            {description}
          </Text>
        )}
      </div>
      <SwitchContainer>
        {isSettingsLoading && !avoidLoading ? (
          <SwitchSkeleton width={46} height={25} />
        ) : (
          <Switch id={definitionKey} checked={checked} onChange={onChange} />
        )}
      </SwitchContainer>
    </Container>
  );
};

SwitchField.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  definitionKey: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  avoidLoading: PropTypes.bool,
};

SwitchField.defaultProps = {
  className: null,
  avoidLoading: false,
};

export default SwitchField;
