import {
  cookieDurationInMinutes,
  iparqueCookiesAcceptanceName,
} from "domain/public/configs";
import { useCallback } from "react";
import CookieHandler from "utils/cookie";

const useCookies = () => {
  const handleAcceptCookies = useCallback(() => {
    CookieHandler.set(iparqueCookiesAcceptanceName, true, cookieDurationInMinutes);
  }, []);

  return {
    isCookiesAccepted: CookieHandler.get(iparqueCookiesAcceptanceName),
    handleAcceptCookies,
  };
};

export default useCookies;
