import PermitHoldersDataSource from "lib/clients/iParque/dataSources/permitHolders";

const permitHoldersDataSource = new PermitHoldersDataSource();

const processItems = (items) =>
  items.map((item) => ({
    id: item.id,
    reference: item.reference,
    typeName: item.permit?.permitType?.designation,
    occupationTypeId: item.permit?.occupationTypeId,
    creationDateTime: item.createdDate,
    city: item.city,
    licencePlates: item.licensePlates,
    stateName: item.state?.name,
    stateId: item.state?.id,
  }));

export const getPermitHolders = async (entityId, driverHash, params) => {
  try {
    const response = await permitHoldersDataSource.getAll(entityId, driverHash, {
      ...params,
      fillCollections: "permit,state",
    });

    return {
      items: processItems(response.items),
      totalPages: response.totalPages,
    };
  } catch {
    return { hasError: true };
  }
};
