import { useBackofficeThemeContext } from "domain/private/components/ThemeProvider";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useCornerColor = (items) => {
  const { state } = useLocation();
  const { backofficeTheme } = useBackofficeThemeContext();

  const cornerColor = useMemo(() => {
    if (state?.cornerColor) {
      return state.cornerColor;
    }

    return items.length > 0
      ? backofficeTheme.color.danger
      : backofficeTheme.color.primary;
  }, [state?.cornerColor, items, backofficeTheme]);

  return cornerColor;
};

export default useCornerColor;
