export const isScrollInBottom = (element, { offset = 0 } = {}) => {
  if (!element) {
    return false;
  }

  return element.scrollHeight - element.scrollTop - element.clientHeight <= 1 + offset;
};

export const hasHorizontalScroll = (element) => {
  if (!element) {
    return false;
  }

  return element.scrollWidth > element.offsetWidth;
};
