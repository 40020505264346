import { useAppContext } from "components/AppProvider";
import PropTypes from "prop-types";
import FlexBox from "components/FlexBox";
import PublicEntityAdvertisement from "domain/public/entities/components/Advertisement";
import React from "react";
import styled from "styled-components";
import {
  breakpoints,
  breakpointsMapping,
  smallDevicesBreakpoints,
} from "utils/breakpoints";
import { getPadding } from "./helper";
import LargeDevicesTemplate from "./LargeDevices";
import SmallDevicesTemplate from "./SmallDevices";

const breakpointsToDisplayTopBackArrow = [breakpointsMapping.xs, breakpointsMapping.sm];

const Template = (props) => {
  const { breakpoint } = useAppContext();

  return (
    <FlexBox>
      <LeftContainer>
        {breakpointsToDisplayTopBackArrow.includes(breakpoint) ? (
          <SmallDevicesTemplate {...props} />
        ) : (
          <LargeDevicesTemplate {...props} />
        )}
      </LeftContainer>
      {!smallDevicesBreakpoints.includes(breakpoint) && (
        <RightContainer>
          <PublicEntityAdvertisement />
        </RightContainer>
      )}
    </FlexBox>
  );
};

export default Template;

Template.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node,
  isLoading: PropTypes.bool,
  onBack: PropTypes.func,
};

Template.defaultProps = {
  isLoading: false,
  subTitle: undefined,
  onBack: undefined,
};

const LeftContainer = styled.div`
  flex: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${getPadding()};
  height: 100vh;
  overflow-y: auto;

  @media (max-width: ${breakpoints.md}) {
    padding: ${getPadding(breakpoints.md)};
  }

  @media (max-width: ${breakpoints.sm}) {
    padding: ${getPadding(breakpoints.sm)};
  }
`;

const RightContainer = styled.div`
  flex: 1;
`;
