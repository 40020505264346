const statesMapping = {
  pending: "em tramitacao",
  sent: "enviado co",
  exported: "exportado",
  informative: "informativo",
  justified: "justificado",
  paid: "pago",
  paidCo: "pago co",
  unpaid: "por pagar",
  claimed: "reclamado",
  processed: "tramitado",
};

const paidStates = [statesMapping.paidCo, statesMapping.paid];

export const getTextColorByState = (state) => {
  if (paidStates.includes(state)) {
    return "success";
  }

  if (state === statesMapping.unpaid) {
    return "danger";
  }

  return "warning";
};
