import React from "react";
import PermitHoldersListPage from "./Page";
import PermitHoldersListProvider from "./Provider";

const PermitHoldersList = () => {
  return (
    <PermitHoldersListProvider>
      <PermitHoldersListPage />
    </PermitHoldersListProvider>
  );
};

export default PermitHoldersList;
