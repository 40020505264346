import { OPEN_CARD_ANIMATION_SECONDS } from "domain/private/components/cardList/config";
import { useCallback, useEffect, useRef, useState } from "react";
import { convertSecondsToMilliseconds } from "utils/dateTime";
import useSubscriptionsOperations from "./useSubscriptionsOperations";

const useSubscriptionsCard = () => {
  const cardRef = useRef();
  const [shouldDisplayPayButton, setShouldDisplayPayButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isCardOpened, setIsCardOpened] = useState(false);
  const { initializePayment } = useSubscriptionsOperations();

  const onPay = useCallback(async () => {
    setIsLoading(true);

    const result = await initializePayment();

    setIsLoading(false);

    if (result) {
      setIsCardOpened(true);
    }
  }, [initializePayment]);

  const closeDetails = useCallback(() => {
    setIsCardOpened(false);
  }, []);

  useEffect(() => {
    setTimeout(
      () => setShouldDisplayPayButton(!isCardOpened),
      isCardOpened ? 0 : convertSecondsToMilliseconds(OPEN_CARD_ANIMATION_SECONDS)
    );
  }, [isCardOpened]);

  useEffect(() => {
    cardRef.current?.setIsOpen(isCardOpened);
  }, [isCardOpened]);

  return { cardRef, isLoading, onPay, closeDetails, shouldDisplayPayButton };
};

export default useSubscriptionsCard;
