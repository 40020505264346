import InfiniteList from "components/InfiniteList";
import React from "react";
import styled from "styled-components";
import { usePermitHoldersListContext } from "../../Provider";
import Card from "./Card";

const ApprovedList = () => {
  const {
    approvedList: { items, isLoading, onFinishedScroll, hasError },
  } = usePermitHoldersListContext();

  return (
    <Container>
      <InfiniteList
        hasError={hasError}
        onFinishedScroll={onFinishedScroll}
        isLoading={isLoading}
        renderItem={(item) => <Card approved {...item} />}
        items={items}
      />
    </Container>
  );
};

export default ApprovedList;

const Container = styled.div`
  height: 35vh;
`;
