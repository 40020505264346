import React from "react";
import PermitHoldersPage from "./Page";
import PermitHoldersDetailsProvider from "./Provider";

const PermitHolderDetails = () => {
  return (
    <PermitHoldersDetailsProvider>
      <PermitHoldersPage />
    </PermitHoldersDetailsProvider>
  );
};

export default PermitHolderDetails;
