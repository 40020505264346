/* eslint-disable import/prefer-default-export */
import i18n from "i18next";

export const generateTitle = ({ isRenewed, isMinutesFee }) => {
  if (isRenewed) {
    return i18n.t("8133"); /* Estacionamento a decorrer */
  }

  if (isMinutesFee) {
    return i18n.t(
      "8182"
    ); /* Escolhe por quanto tempo pretendes renovar o estacionamento */
  }

  return i18n.t("8236") /* Renova o teu estacionamento */;
};
