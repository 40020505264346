import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Heading from "iparque-components/dist/components/Heading";
import Text from "iparque-components/dist/components/Text";
import { breakpoints } from "../../../utils/breakpoints";
import FlexBox from "../../../components/FlexBox";

const Container = styled(FlexBox)`
  height: 100%;
  flex: 1;

  @media (max-width: ${breakpoints.lg}) {
    flex-direction: column;
  }
`;

const InfoContainer = styled.div`
  max-width: 300px;
  flex-direction: column;
  margin-left: 30px;

  @media (min-width: ${breakpoints.xl}) {
    max-width: 350px;
  }

  @media (max-width: ${breakpoints.lg}) {
    max-width: 350px;
    margin-top: 80px;
  }

  @media (max-width: ${breakpoints.md}) {
    max-width: 300px;
    margin-top: 40px;
  }
`;

const StyledTitle = styled(Heading)`
  font-size: 48px;
`;

const StyledText = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.weight.light};
`;

const PageError = ({ animation, title, subtitle, text }) => {
  return (
    <Container alignItems="center">
      <FlexBox justifyContent="center" size="sm">
        {animation}
      </FlexBox>
      <FlexBox size="sm">
        <InfoContainer>
          <StyledTitle color="tertiary" variant="h1">
            {title}
          </StyledTitle>
          <Heading color="tertiary" variant="h5">
            {subtitle}
          </Heading>
          <StyledText variant="body5" className="mt-10">
            {text}
          </StyledText>
        </InfoContainer>
      </FlexBox>
    </Container>
  );
};

PageError.propTypes = {
  animation: PropTypes.shape().isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default PageError;
