import { useAuth } from "components/AuthProvider";
import DriversDataSource from "lib/clients/iParque/dataSources/driversDataSource";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { errorMessage } from "utils/userMessages";
import { usePermitHoldersListContext } from "../Provider";
import { actionTypes } from "../store/actions";

const driversDataSource = new DriversDataSource();

const useRequirements = () => {
  const { driverHash } = useAuth();
  const { t } = useTranslation();
  const { dispatch } = usePermitHoldersListContext();

  useEffect(() => {
    (async () => {
      try {
        const response = await driversDataSource.getVehicles(driverHash, { limit: 1 });

        dispatch({
          type: actionTypes.SET_REQUIREMENTS,
          payload: { hasVehicles: response.items.length > 0 },
        });
      } catch (error) {
        errorMessage(error, t("10419") /* Ocorreu um erros ao verificar os requisitos */);
      }
    })();
  }, []);
};

export default useRequirements;
