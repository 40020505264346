import React from "react";
import PropTypes from "prop-types";
import { Text } from "iparque-components";
import styled, { css } from "styled-components";
import { convertMinutesToReadableTime } from "../../../../../../utils/dateTime";
import { useBackofficeThemeContext } from "../../../../components/ThemeProvider";
import { themesMapping } from "../../../../../../themes/theme";

const highlightStyle = css`
  background-color: ${({ theme }) => theme.boxOptionsField.selectedBackgroundColor};

  .box-option-field-text {
    color: ${({ theme }) => theme.background.color.background};
  }
`;

const Container = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.boxOptionsField.backgroundColor};
  border-radius: 10px;
  padding: 7px 5px;
  width: 70px;
  outline: none;

  .box-option-field-text {
    color: ${({ theme, isNormalTheme }) =>
      isNormalTheme && theme.boxOptionsField.selectedBackgroundColor};
  }

  ${({ isSelected }) => isSelected && highlightStyle};

  :hover {
    ${highlightStyle}
  }
`;

const NotificationTimeOption = ({ minutes, onClick, className, isSelected }) => {
  const { backofficeThemeName } = useBackofficeThemeContext();
  const isNormalTheme = backofficeThemeName === themesMapping.normal;

  const localOnClick = () => {
    onClick(minutes);
  };

  return (
    <Container
      className={className}
      isSelected={isSelected}
      isNormalTheme={isNormalTheme}
      tabIndex="0"
      onKeyPress={localOnClick}
      onClick={localOnClick}
      role="button"
    >
      <Text
        align="center"
        className="box-option-field-text"
        variant="body10"
        color="secondary"
      >
        {convertMinutesToReadableTime(minutes)}
      </Text>
    </Container>
  );
};

NotificationTimeOption.propTypes = {
  minutes: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
};

NotificationTimeOption.defaultProps = {
  className: null,
};

export default NotificationTimeOption;
