import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { Text } from "iparque-components";
import { ReactComponent as CarIcon } from "assets/icons/car.svg";
import FlexBox from "components/FlexBox";
import LabelValueCardCell from "domain/private/components/cardList/LabelValueCell";
import { breakpoints } from "utils/breakpoints";

const VehicleCard = ({
  regimeName,
  colorId,
  country,
  licencePlate,
  brandName,
  modelName,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const carColor = theme.carColor[colorId];

  return (
    <Container>
      <FlexBox>
        <Text className="fill">{regimeName}</Text>
        <CarIcon
          fill={carColor}
          width={42}
          height={42}
          className="show-on-small-devices"
        />
      </FlexBox>
      <FlexBox alignItems="center">
        <CarIcon
          fill={carColor}
          width={70}
          height={70}
          className="mt-10 mr-40 hide-on-small-devices"
        />
        <InfoContainer>
          <LabelValueCardCell label={t("2721") /* País */} value={country} />
          <LabelValueCardCell label={t("7254") /* Matrícula */} value={licencePlate} />
          <LabelValueCardCell label={t("8306") /* Marca */} value={brandName} />
          <LabelValueCardCell label={t("4548") /* Modelo */} value={modelName} />
        </InfoContainer>
      </FlexBox>
    </Container>
  );
};

export default VehicleCard;

VehicleCard.propTypes = {
  regimeName: PropTypes.string.isRequired,
  colorId: PropTypes.number.isRequired,
  country: PropTypes.string.isRequired,
  licencePlate: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
};

const Container = styled.div`
  border: 2px solid ${({ theme }) => theme.card.outlined.primary.borderColor};
  padding: 20px;

  :not(:first-child) {
    margin-top: 10px;
  }

  .show-on-small-devices {
    display: none;
  }

  @media (max-width: ${breakpoints.md}) {
    .show-on-small-devices {
      display: initial;
    }

    .hide-on-small-devices {
      display: none;
    }
  }
`;

const InfoContainer = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: ${breakpoints.md}) {
    gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
`;
