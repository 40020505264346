import { useAuth } from "components/AuthProvider";
import Button from "components/buttons/Button";
import FlexBox from "components/FlexBox";
import { Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ContentHeader from "../components/ContentHeader";
import QRCode from "../components/QRCode";
import PasswordForm from "./PasswordForm";
import { profileDefaultProps, profilePropTypes } from "./propTypes";
import UserForm from "./UserForm";

const Container = styled.div`
  max-width: 1000px;
`;

const UserInfoContainer = styled.div`
  margin-top: 10px;
  display: flex;
`;

const PasswordContainer = styled.div`
  margin-top: 30px;
  border-top: 1px solid ${({ theme }) => theme.border.lightBackground.color};
`;

const FormContainer = styled.div`
  margin-left: 80px;
  flex: 1;
`;

const EditButtonContainer = styled.div`
  display: flex;
  visibility: ${({ $visibility }) => ($visibility ? "visible" : "hidden")};
  justify-content: flex-end;
`;

const Profile = ({
  user,
  countries,
  countryCodesMapping,
  saveUserCallback,
  saveUserPasswordCallback,
  onUserFormEditClickCallback,
  onPasswordEditClickCallback,
  editUserForm,
  editPasswordForm,
  title,
  extraRequiredFields,
}) => {
  const { t } = useTranslation();
  const { loginWithExternalApp } = useAuth();

  return (
    <Container>
      <ContentHeader topTitle={title.top} bottomTitle={title.bottom} />
      <div className="mt-50">
        <FlexBox justifyContent="spaceBetween">
          <Text color="primary" variant="body7" className="uppercase">
            {t("8187") /* O meu identificador */}
          </Text>
          <EditButtonContainer $visibility={!editUserForm}>
            <Button color="info" onClick={() => onUserFormEditClickCallback(true)}>
              {t("3375") /* Editar */}
            </Button>
          </EditButtonContainer>
        </FlexBox>
        <UserInfoContainer>
          <div>
            <QRCode width={160} height={160} value={user.uid} />
          </div>
          <FormContainer>
            <UserForm
              user={user}
              countries={countries}
              countryCodesMapping={countryCodesMapping}
              edit={editUserForm}
              saveUserCallback={saveUserCallback}
              extraRequiredFields={extraRequiredFields}
              onCancel={() => onUserFormEditClickCallback(false)}
            />
          </FormContainer>
        </UserInfoContainer>
        {!loginWithExternalApp && (
          <PasswordContainer>
            {editPasswordForm ? (
              <PasswordForm
                saveUserPasswordCallback={saveUserPasswordCallback}
                onCancel={() => onPasswordEditClickCallback(false)}
              />
            ) : (
              <Button
                size="sm"
                color="info"
                className="mt-30"
                onClick={() => onPasswordEditClickCallback(true)}
              >
                {t("8192") /* Alterar senha */}
              </Button>
            )}
          </PasswordContainer>
        )}
      </div>
    </Container>
  );
};

Profile.propTypes = profilePropTypes;

Profile.defaultProps = profileDefaultProps;

export default Profile;
