import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import Button from "components/buttons/Button";
import TextField from "components/formFields/Text";
import FormGridContainer, { FieldContainer } from "components/FormGrid";
import PermitHoldersFormBody from "components/permitHolders/components/Form/Body";
import { ValidationSchemaProvider } from "components/ValidationSchemaProvider";
import { Form as FormikForm, Formik } from "formik";
import usePermitHoldersFormValidation from "hooks/usePermitHoldersFormValidation";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { updateClient } from "requests/clients";
import { updatePermitHolder } from "requests/permitHolders";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import { userTypes } from "utils/users";
import { usePermitHoldersDetailsContext } from "../../Provider";
import { actionTypes } from "../../store/actions";

const PersonalInfoForm = ({ className }) => {
  const { t } = useTranslation();
  const {
    token: permitHolderToken,
    permit,
    isEditable,
    dispatch,
    personalData,
    clientId,
    reference,
    createdDateTime,
    city,
  } = usePermitHoldersDetailsContext();
  const { setIsLoading } = useAppContext();
  const { defaultEntityId: entityId, driverHash, userId: driverId } = useAuth();
  const { validationSchema } = usePermitHoldersFormValidation();

  const initialValues = {
    reference,
    createdDateTime,
    city,
    ...personalData,
  };

  const onSubmit = async (values) => {
    const valuesCopy = { ...values };

    delete valuesCopy.city;
    delete valuesCopy.createdDateTime;
    delete valuesCopy.reference;

    setIsLoading(true);

    const clientUpdateResult = await updateClient(entityId, clientId, values, {
      error: t("10562") /* Ocorreu um erro ao guardar os dados pessoais do teu dístico */,
    });

    if (!clientUpdateResult) {
      setIsLoading(false);
      return;
    }

    await updatePermitHolder(
      entityId,
      driverHash,
      permitHolderToken,
      {
        clientId: clientUpdateResult.id,
        permitId: permit.id,
        userTypeId: userTypes.client,
        userId: driverId,
      },
      { t }
    );

    setIsLoading(false);

    dispatch([
      { type: actionTypes.SET_PERSONAL_DATA, payload: valuesCopy },
      { type: actionTypes.SET_IS_EDITABLE, payload: false },
    ]);
  };

  return (
    <ValidationSchemaProvider schema={validationSchema}>
      <Formik
        initialValues={initialValues || {}}
        validationSchema={isEditable ? validationSchema : undefined}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values }) => (
          <Form className={className}>
            <FormGridContainer>
              <FieldContainer>
                <TextField name="reference" label={t("2021") /* Referência */} readOnly />
              </FieldContainer>
              <FieldContainer>
                <TextField
                  name="createdDateTime"
                  label={t("8827") /* Data de criação */}
                  readOnly
                />
              </FieldContainer>
              <FieldContainer>
                <TextField name="city" label={t("2053") /* Cidade */} readOnly />
              </FieldContainer>
              <PermitHoldersFormBody
                entityId={entityId}
                values={values}
                readOnly={!isEditable}
              />
            </FormGridContainer>
            {isEditable && (
              <ButtonContainer>
                <Button type="click" className="mt-40 submit-button uppercase">
                  {t("88") /* Guardar */}
                </Button>
              </ButtonContainer>
            )}
          </Form>
        )}
      </Formik>
    </ValidationSchemaProvider>
  );
};

export default PersonalInfoForm;

PersonalInfoForm.propTypes = {
  className: PropTypes.string,
};

PersonalInfoForm.defaultProps = {
  className: undefined,
};

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;

  @media (max-width: ${breakpoints.sm}) {
    justify-content: center;
    margin: auto;

    .submit-button {
      width: 250px;
    }
  }
`;

const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
`;
