import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const DocumentsList = ({ documents }) => {
  return (
    <List>
      {documents.map((doc) => (
        <li className="mt-5" key={doc.designation}>
          {doc.designation}
        </li>
      ))}
    </List>
  );
};

export default DocumentsList;

DocumentsList.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({ designation: PropTypes.string.isRequired })
  ).isRequired,
};

const List = styled.ul`
  margin: 5px 0 0;
  list-style-type: none;
  padding-left: 15px;

  li {
    ::before {
      content: "-";
      margin-right: 5px;
    }
  }
`;
