import Button from "components/buttons/Button";
import FlexBox from "components/FlexBox";
import { Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Loader from "../../../components/loader/Loader";
import { routesMapping } from "../../../utils/routes";
import ContentBorderBox from "../components/ContentBorderBox";
import ContentHeader from "../components/ContentHeader";
import ParkingContainer from "../components/ParkingContainer";
import AmountDetails from "../components/parkings/AmountDetails";
import ParkedCarBox from "../components/parkings/ParkedCarBox";
import ParkingDetails from "../components/parkings/ParkingDetails";
import SelectedBenefit from "../components/parkings/SelectedBenefit";
import ParkingTimer from "../components/parkings/Timer";
import { useBackofficeThemeContext } from "../components/ThemeProvider";
import { parkingFinishedDefaultPropTypes, parkingFinishedPropTypes } from "./propTypes";

const PageContainer = styled.div`
  max-width: 68vw;
  display: flex;
  flex-direction: column;
`;

const ParkingDetailsWrapper = styled.div`
  flex: 3;
`;

const SelectedBenefitWrapper = styled.div`
  flex: 2;
  margin: 0 20px;
`;

const TimerContainer = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const ParkingFinished = ({
  id,
  vehicleColor,
  streetName,
  explorationName,
  vehicleLicensePlate,
  vehicleBrand,
  benefitName,
  benefitColor,
  amount,
  animationState,
  endingDate,
  isLoading,
  scheduleExtraInfo,
}) => {
  const { backofficeTheme } = useBackofficeThemeContext();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <PageContainer>
      <ContentHeader
        topTitle={t("7830") /* Vamos estacionar? */}
        bottomTitle={t("7831") /* É fácil! */}
      />
      <ContentBorderBox
        title={t("8124") /* Estacionamento finalizado */}
        cornerColor={backofficeTheme.color.primary}
        className="mt-40"
      >
        <ParkedCarBox state={animationState} vehicleColor={vehicleColor}>
          <ParkingContainer>
            {isLoading && (
              <LoaderWrapper>
                <Loader width={80} height={80} isLoading={isLoading} />
              </LoaderWrapper>
            )}
            <FlexBox>
              <ParkingDetailsWrapper>
                <ParkingDetails
                  vehicleColor={vehicleColor}
                  vehicleBrand={vehicleBrand}
                  vehicleLicensePlate={vehicleLicensePlate}
                  streetName={streetName}
                  explorationName={explorationName}
                  endingDate={endingDate}
                />
              </ParkingDetailsWrapper>
              {benefitName ? (
                <SelectedBenefitWrapper>
                  <SelectedBenefit description={benefitName} color={benefitColor} />
                </SelectedBenefitWrapper>
              ) : null}
              {!Number.isNaN(amount) ? (
                <AmountDetails amount={amount} isPaymentPending={false} />
              ) : null}
            </FlexBox>
            <TimerContainer>
              {id && (
                <ParkingTimer
                  parkingId={id}
                  endingDate={endingDate}
                  scheduleExtraInfo={scheduleExtraInfo}
                  size="lg"
                />
              )}
            </TimerContainer>
            <BottomContainer>
              {id && (
                <Text variant="body1" color="tertiary" className="uppercase">
                  {t("8124") /* Estacionamento finalizado */}
                </Text>
              )}
              <Button
                size="sm"
                onClick={() => {
                  history.push(`${routesMapping.backofficeParking}`);
                }}
                className="mt-20"
              >
                {t("4045") /* Novo Estacionamento */}
              </Button>
            </BottomContainer>
          </ParkingContainer>
        </ParkedCarBox>
      </ContentBorderBox>
    </PageContainer>
  );
};

ParkingFinished.propTypes = parkingFinishedPropTypes;

ParkingFinished.defaultProps = parkingFinishedDefaultPropTypes;

export default ParkingFinished;
