import useLanguage from "hooks/useLanguage";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { entityIds } from "utils/entities";
import { usePublicEntityContext } from "../../Provider";

const PublicEntityAdvertisement = () => {
  const { entity } = usePublicEntityContext();
  const [imageConfig, setImageConfig] = useState();
  const language = useLanguage();

  useEffect(() => {
    (async () => {
      const entityImageConfig = {
        [entityIds.parquesTejo]: {
          promise: () => import(`../assets/${language}/parques-tejo.jpg`),
          alt: "Parques tejo",
        },
      }[entity?.id] || {
        promise: () => import(`../assets/${language}/iparque-mobile.webp`),
        alt: "iParque Mobile",
      };

      const imageObject = await entityImageConfig.promise();

      setImageConfig({ url: imageObject.default, alt: entityImageConfig.alt });
    })();
  }, [language, entity?.id]);

  // Avoid load advertisement without entity theme info
  if (!entity) {
    return null;
  }

  return (
    <Container>
      <Image src={imageConfig?.url} alt={imageConfig?.alt} />
    </Container>
  );
};

export default PublicEntityAdvertisement;

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.tertiary};
  justify-content: center;
  width: 50vw;
`;

const Image = styled.img`
  align-self: center;
  max-height: 100vh;
  max-width: 50vw;
`;
