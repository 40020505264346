/* eslint-disable react/no-array-index-key */
import { useAppContext } from "components/AppProvider";
import { FieldContainer } from "components/FormGrid";
import RecaptchaInfo from "domain/public/components/RecaptchaInfo";
import useGoogleRecaptcha from "hooks/useGoogleRecaptcha";
import { Text, TextField } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { permitHoldersOccupationType } from "utils/permitHolders";
import { warningMessage } from "utils/userMessages";
import AdvanceButton from "../../components/AdvanceButton";
import PersonalDataForm from "../../components/PersonalData/Form";
import { useStepContext } from "../../components/Step";
import VehicleForm from "../../components/Vehicle/Form";
import usePermitHolderCreation from "../../hooks/usePermitHolderCreation";
import { usePermitHoldersContext } from "../../Provider";
import DocumentCard from "./DocumentCard";

const ConfirmationStep = () => {
  const { t } = useTranslation();
  const { setIsLoading } = useAppContext();
  const {
    documents,
    vehicles,
    selectedPermitType,
    personalData,
    selectedCity,
  } = usePermitHoldersContext();
  const { onSubmissionSuccess } = useStepContext();
  const { executeGoogleRecaptcha, isGoogleRecaptchaLoaded } = useGoogleRecaptcha();
  const createPermitHolder = usePermitHolderCreation();

  const onConfirm = async () => {
    setIsLoading(true);

    const token = await executeGoogleRecaptcha();

    if (!token) {
      setIsLoading(false);
      warningMessage(t("7858") /* Por favor, tenta novamente. */);
      return;
    }

    const result = await createPermitHolder({
      recaptchaToken: token,
    });

    if (!result) {
      setIsLoading(false);
      return;
    }

    const { permitHolderId, allowRegistry } = result;

    await onSubmissionSuccess({ permitHolderId, allowRegistry, ...personalData });
    setIsLoading(false);
  };

  return (
    <Container>
      <div>
        <FieldContainer>
          <TextField
            name="city"
            label={t("2053") /* Cidade */}
            value={selectedCity.name}
            readOnly
          />
        </FieldContainer>
        <SectionTitle variant="body10" color="quintenary" className="mt-10">
          {t("10728") /* Dados pessoais */}
        </SectionTitle>
        <PersonalDataForm readOnly className="mt-10" />
        <SectionTitle variant="body10" color="quintenary" className="mt-20">
          {t("4297") /* Documentos */}
        </SectionTitle>
        {Object.values(documents).map((doc) => (
          <DocumentCard key={doc.designation} {...doc} />
        ))}
        {selectedPermitType.permitType.occupationTypeId ===
          permitHoldersOccupationType.vehicle && (
          <>
            <SectionTitle variant="body10" color="quintenary" className="mt-20">
              {t("4424") /* Veículos */}
            </SectionTitle>
            {vehicles.map((vehicle, index) => (
              <VehicleForm
                key={index}
                position={index}
                className="vehicles-form"
                canRemove={index > 0 && index === vehicles.length - 1}
                vehicle={vehicle}
                readOnly
              />
            ))}
          </>
        )}
      </div>
      <div className="mt-50">
        <RecaptchaInfo />
        <AdvanceButton
          type="button"
          lastStep
          onClick={onConfirm}
          disabled={!isGoogleRecaptchaLoaded}
        />
      </div>
    </Container>
  );
};

export default ConfirmationStep;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;

  .form-field {
    min-height: 60px;
  }
`;

const SectionTitle = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  margin-bottom: 5px;
`;
