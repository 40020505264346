import React from "react";
import { useField } from "formik";
import { Input } from "iparque-components";
import PropTypes from "prop-types";

const InputField = ({ helperText, normalize, ...props }) => {
  const [field, meta] = useField(props);
  const hasError = meta.error && meta.touched;

  return (
    <Input
      error={hasError}
      value={normalize ? normalize(field.value) : field.value}
      {...field}
      {...props}
    />
  );
};

export default InputField;

InputField.propTypes = {
  helperText: PropTypes.node,
  normalize: PropTypes.func,
};

InputField.defaultProps = {
  helperText: undefined,
  normalize: undefined,
};
