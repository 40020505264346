import { useAuth } from "components/AuthProvider";
import useChangeDefaultEntity from "hooks/useChangeDefaultEntity";
import DriversDataSource from "lib/clients/iParque/dataSources/driversDataSource";
import PropTypes from "prop-types";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { getFromStorage, saveOnStorage } from "utils/localStorage";

const CONTRACTS_SESSION_KEY = "985517d2-2611-4208-9cbf-01bc095c47bc";

export const pendingOperationKeys = {
  associatePermitHolder: "associatePermitHolder",
};

const initialPendingContracts = { entityId: undefined, contracts: [] };

const PendingOperationsContext = createContext({
  addPendingOperation: () => {},
  hasPendingOperations: false,
  resolveOldestPendingOperation: () => {},
  isResolvingPendingOperation: false,
  ongoingPendingOperation: undefined,
  completePendingOperation: () => {},
  decorateWithPendingOperation: (loginLocation) => loginLocation,
  pendingContracts: initialPendingContracts,
  acceptContract: () => {},
  removeContracts: () => {},
});

const driversDataSource = new DriversDataSource();

const PendingOperationsProvider = ({ children }) => {
  const [pendingOperationsStack, setPendingOperationsStack] = useState([]);
  const [ongoingPendingOperation, setOngoingPendingOperation] = useState(false);
  const [pendingContracts, setPendingContracts] = useState(
    getFromStorage(CONTRACTS_SESSION_KEY) || initialPendingContracts
  );
  const changeDefaultEntity = useChangeDefaultEntity();
  const { defaultEntityId, driverHash } = useAuth();
  const history = useHistory();

  const addPendingOperation = useCallback(
    (operation, location, entityIdentifier, data) =>
      setPendingOperationsStack((currentPendingOperationsStack) => [
        ...currentPendingOperationsStack,
        { key: operation, location, entityIdentifier, data },
      ]),
    []
  );

  const hasPendingOperations = useMemo(() => pendingOperationsStack.length > 0, [
    pendingOperationsStack,
  ]);

  const resolveOldestPendingOperation = useCallback(async () => {
    const targetPendingOperation = pendingOperationsStack[0];

    setOngoingPendingOperation(targetPendingOperation.key);

    const entity = await driversDataSource.getEntity(
      driverHash,
      targetPendingOperation.entityIdentifier
    );

    if (!entity) {
      setOngoingPendingOperation();
      return;
    }

    const entityId = entity.id;

    if (!defaultEntityId || defaultEntityId !== entityId) {
      await changeDefaultEntity(entityId);
    }

    if (entity.contracts.length) {
      const localPendingContracts = { entityId, contracts: entity.contracts };

      setPendingContracts(localPendingContracts);
      saveOnStorage(CONTRACTS_SESSION_KEY, localPendingContracts);
    }

    history.push(targetPendingOperation.location, targetPendingOperation.data);
  }, [
    pendingOperationsStack,
    history,
    ongoingPendingOperation,
    defaultEntityId,
    changeDefaultEntity,
  ]);

  const completePendingOperation = useCallback(() => {
    setPendingOperationsStack(
      pendingOperationsStack.filter(
        (pendingOperation) => pendingOperation.key !== ongoingPendingOperation
      )
    );

    setOngoingPendingOperation();
  }, [pendingOperationsStack, ongoingPendingOperation]);

  const decorateWithPendingOperation = useCallback(
    (loginLocation) => {
      if (!ongoingPendingOperation) {
        return loginLocation;
      }

      const ongoingPendingOperationInfo = pendingOperationsStack.find(
        (pendingOperation) => pendingOperation.key === ongoingPendingOperation
      );

      if (!ongoingPendingOperationInfo) {
        return loginLocation;
      }

      if (
        ongoingPendingOperationInfo.key === pendingOperationKeys.associatePermitHolder
      ) {
        return `${loginLocation}?token=${ongoingPendingOperationInfo.data.token}&entityToken=${ongoingPendingOperationInfo.entityIdentifier}`;
      }

      return loginLocation;
    },
    [ongoingPendingOperation]
  );

  const acceptContract = useCallback(
    (contractId) => {
      const localPendingContracts = {
        ...pendingContracts,
        contracts: pendingContracts.contracts.filter(
          (contract) => contract.id !== contractId
        ),
      };

      setPendingContracts(localPendingContracts);
      saveOnStorage(CONTRACTS_SESSION_KEY, localPendingContracts); // Avoid lose contracts on page reload
    },
    [pendingContracts]
  );

  const removeContracts = useCallback(() => {
    setPendingContracts(initialPendingContracts);
    saveOnStorage(CONTRACTS_SESSION_KEY, initialPendingContracts);
  }, []);

  return (
    <PendingOperationsContext.Provider
      value={{
        addPendingOperation,
        hasPendingOperations,
        resolveOldestPendingOperation,
        isResolvingPendingOperation: !!ongoingPendingOperation,
        ongoingPendingOperation,
        completePendingOperation,
        decorateWithPendingOperation,
        pendingContracts,
        acceptContract,
        removeContracts,
      }}
    >
      {children}
    </PendingOperationsContext.Provider>
  );
};

PendingOperationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PendingOperationsProvider;

export const usePendingOperationsContext = () => useContext(PendingOperationsContext);
