/* eslint-disable import/prefer-default-export */
import i18n from "i18next";
import EntityCountriesDataSource from "lib/clients/iParque/dataSources/entityCountriesDataSource";
import CountriesDataSource from "../lib/clients/iParque/dataSources/countriesDataSource";
import { errorMessage } from "../utils/userMessages";

const countriesDataSource = new CountriesDataSource();
const entityCountriesDataSource = new EntityCountriesDataSource();

export const getAll = async (entityId, params, useCache = true) => {
  try {
    let response;

    if (entityId) {
      response = await entityCountriesDataSource.getAll(entityId, params, {}, useCache);
    } else {
      response = await countriesDataSource.getAll(params, {}, useCache);
    }
    const countries = response?.items || [];

    return countries.map((country) => {
      return {
        name: country.name,
        id: country.id,
        code: country.code,
        isoCode: country.isoCode,
      };
    });
  } catch (error) {
    errorMessage(error, i18n.t("7890") /* Ocorreu um erro ao obter os países. */);

    return null;
  }
};
