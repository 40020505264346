import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { STEPS } from "components/permitHolders/Creation/helper";
import { usePermitHoldersContext } from "components/permitHolders/Creation/Provider";

const useTitle = () => {
  const { currentStep } = usePermitHoldersContext();
  const { t } = useTranslation();

  const title = useMemo(
    () =>
      ({
        [STEPS.cities]: t(
          "10529"
        ) /* Escolhe a cidade onde pretendes adquirir o dístico */,
        [STEPS.permitTypes]: t("10530") /* Escolhe o tipo de dístico */,
        [STEPS.permitDetails]: t("10205") /* Verifica as condições */,
        [STEPS.personalData]: t("10225") /* Preenche os teus dados */,
        [STEPS.documents]: t("10236") /* Adiciona os teus documentos */,
        [STEPS.vehicles]: t(
          "10575"
        ) /* Seleciona o(s) veículo(s) que queres associar ao dístico */,
        [STEPS.confirmation]: t("10464") /* Verifica os teus dados */,
      }[currentStep?.index] || ""),
    [currentStep]
  );

  return title;
};

export default useTitle;
