const PING_URL = "https://www.google.com/";
const POLLING_INTERVAL = 3000;

let interval;
let onNetworkConnectionCallback = () => {};
let onNetworkErrorCallback = () => {};

const checkConnection = async () =>
  fetch(PING_URL, { mode: "no-cors" })
    .then(() => true)
    .catch(() => false);

const startPolling = () => {
  if (interval) {
    return;
  }

  interval = setInterval(async () => {
    const hasConnection = await checkConnection();

    if (hasConnection) {
      clearInterval(interval);
      onNetworkConnectionCallback();
    } else {
      onNetworkErrorCallback();
    }
  }, POLLING_INTERVAL);
};

export const setNetworkConnectionCallbacks = (onSuccess, onError) => {
  onNetworkConnectionCallback = onSuccess;
  onNetworkErrorCallback = onError;
};

export const onNetworkError = async () => {
  const hasConnection = await checkConnection();

  if (!hasConnection) {
    onNetworkErrorCallback();
    startPolling();
  }
};
