import { Button } from "iparque-components/dist/components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StartPaymentButton = ({ isPaid, resetState, displayPaymentForm }) => {
  const { t } = useTranslation();

  return (
    <>
      {isPaid ? (
        <StyledButton size="xs" color="secondary" onClick={resetState}>
          {t("9") /* Voltar */}
        </StyledButton>
      ) : (
        <StyledButton size="xs" color="secondary" onClick={displayPaymentForm}>
          {t("11818") /* Clica aqui */}
        </StyledButton>
      )}
    </>
  );
};

export default StartPaymentButton;

StartPaymentButton.propTypes = {
  isPaid: PropTypes.bool,
  resetState: PropTypes.func.isRequired,
  displayPaymentForm: PropTypes.func.isRequired,
};

StartPaymentButton.defaultProps = {
  isPaid: false,
};

const StyledButton = styled(Button)`
  min-width: 100px;
`;
