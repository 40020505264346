import Skeleton from "components/Skeleton";
import VerticalTabs from "components/tabs/verticals/Tabs";
import ContentBorderBox from "domain/private/components/ContentBorderBox";
import ContentHeader from "domain/private/components/ContentHeader";
import ErrorCard from "domain/private/components/ErrorCard";
import { Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import Operations from "../components/Operations";
import OperationsSkeleton from "../components/Operations/Skeleton";
import Warnings from "../components/Warnings";
import useBack from "../hooks/useBack";
import useTabs from "./hooks/useTabs";
import { usePermitHoldersDetailsContext } from "../Provider";
import Title from "./Title";
import TitleSkeleton from "./Title/Skeleton";

const PermitHolderDetailsLargePage = () => {
  const { permit, initialRequestStatus, tabsRef } = usePermitHoldersDetailsContext();
  const { t } = useTranslation();
  const onBack = useBack();
  const { tabs, onTabChange } = useTabs();

  return (
    <>
      <ContentHeader
        topTitle={t("10572") /* Consulta aqui os detalhes do teu dístico */}
        bottomTitle={t("7998") /* Não deixes que nada te escape! */}
      />
      {initialRequestStatus.hasError ? (
        <ErrorCard className="mt-40" />
      ) : (
        <ContentBorderBox
          backCallback={onBack}
          className="mt-40"
          title={initialRequestStatus.isLoading ? <TitleSkeleton /> : <Title />}
          topRightCornerContent={
            initialRequestStatus.isLoading ? <OperationsSkeleton /> : <Operations />
          }
        >
          <Warnings />
          {initialRequestStatus.isLoading ? (
            <Skeleton height={15} width={80} className="mt-30" />
          ) : (
            <Text variant="body7" className="mt-30">
              {permit.type.designation}
            </Text>
          )}
          <VerticalTabs
            ref={tabsRef}
            tabs={tabs}
            className="mt-10"
            onTabChange={onTabChange}
          />
        </ContentBorderBox>
      )}
    </>
  );
};

export default PermitHolderDetailsLargePage;
