import InfiniteList from "components/InfiniteList";
import React from "react";
import { LIST_MAX_HEIGHT } from "../../../configs";
import useSubscriptions from "../../../hooks/useSubscriptions";
import EmptyListInfo from "../EmptyListInfo";
import ErrorComponent from "../ErrorComponent";
import Card from "./Card";

const NUMBER_OF_LOADING_CARDS = 2;
const CARD_HEIGHT = 120;

const SubscriptionsList = () => {
  const { items, isLoading, onFinishedScroll, hasError } = useSubscriptions();

  return (
    <InfiniteList
      hasError={hasError}
      onFinishedScroll={onFinishedScroll}
      isLoading={isLoading}
      renderItem={(item) => <Card {...item} />}
      items={items}
      loadingItemsNumber={NUMBER_OF_LOADING_CARDS}
      cardHeight={CARD_HEIGHT}
      EmptyListComponent={<EmptyListInfo />}
      ErrorComponent={<ErrorComponent />}
      maxHeight={LIST_MAX_HEIGHT}
    />
  );
};

export default SubscriptionsList;
