import { ReactComponent as ErrorIcon } from "assets/icons/error.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import styled, { useTheme } from "styled-components";
import { breakpoints } from "utils/breakpoints";

const variants = {
  info: "info",
  error: "error",
};

const iconsMap = {
  [variants.info]: InfoIcon,
  [variants.error]: ErrorIcon,
};

const StatusWidget = ({ children, variant }) => {
  const theme = useTheme();
  const iconColor = theme.typography.text.color.primary;

  const Icon = iconsMap[variant];

  return (
    <Container>
      <Icon className="icon" fill={iconColor} />
      <Text className="status-text" variant="body6">
        {children}
      </Text>
    </Container>
  );
};

export default StatusWidget;

StatusWidget.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(Object.values(variants)),
};

StatusWidget.defaultProps = {
  variant: variants.info,
};

const Container = styled.div`
  display: flex;
  align-items: center;

  .icon {
    flex-shrink: 0;
    width: 33px;
    height: 33px;
    margin-right: 20px;
  }

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    margin-top: 20px;

    .icon {
      margin-bottom: 20px;
      width: 50px;
      height: 50px;
    }

    .status-text {
      text-align: center;
    }
  }
`;
