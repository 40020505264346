import PropTypes from "prop-types";

const licencePlatesProps = {
  validatedLicencePlates: PropTypes.arrayOf(PropTypes.string).isRequired,
  unvalidatedLicencePlates: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export const noticesPropTypes = {
  getNoticesCallback: PropTypes.func.isRequired,
  ...licencePlatesProps,
};

export const allNoticesPropTypes = {
  getNoticesCallback: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export const allNoticesDefaultProps = {
  className: "",
};

export const noticePropTypes = {
  id: PropTypes.number.isRequired,
  number: PropTypes.string.isRequired,
  licensePlate: PropTypes.string.isRequired,
  vehicleColorId: PropTypes.number.isRequired,
  dateTime: PropTypes.string.isRequired,
  paymentDateTime: PropTypes.string,
  amount: PropTypes.number.isRequired,
  stateName: PropTypes.string.isRequired,
  stateId: PropTypes.string.isRequired,
  zoneName: PropTypes.string.isRequired,
  streetName: PropTypes.string.isRequired,
  situation: PropTypes.string.isRequired,
  infraction: PropTypes.string.isRequired,
  payable: PropTypes.bool.isRequired,
  paymentScenario: PropTypes.bool,
};

export const emptyUnpaidNoticesProps = licencePlatesProps;
