import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as CopyIcon } from "../assets/icons/copy.svg";
import { useBackofficeThemeContext } from "../domain/private/components/ThemeProvider";
import ClipBoard from "./Clipboard";

const ClipboardIcon = ({ text, width, height, className }) => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();

  const onClick = (event) => event.stopPropagation();

  return (
    <ClipBoard text={text} title={t("6886") /* Copiar */}>
      <StyledCopyIcon
        onClick={onClick}
        width={width}
        height={height}
        cursor="pointer"
        fill={backofficeTheme.icon.color}
        className={className}
      />
    </ClipBoard>
  );
};

export default ClipboardIcon;

ClipboardIcon.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ClipboardIcon.defaultProps = { className: null, width: "18px", height: "18px" };

const StyledCopyIcon = styled(CopyIcon)`
  min-width: ${({ width }) => width};
`;
