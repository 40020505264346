import { ReactComponent as FrontArrowIcon } from "assets/icons/front-arrow-large.svg";
import FlexBox from "components/FlexBox";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { usePermitHoldersContext } from "../../Provider";

export const CARDS_VERTICAL_MARGIN_BETWEEN = 15;

const PermitTypeCard = ({ id, designation, onClick }) => {
  const { selectedPermitType, isPrivateContext } = usePermitHoldersContext();
  const selected = selectedPermitType?.permitType.id === id;

  return (
    <Container selected={selected} onClick={() => onClick(id)}>
      <FlexBox alignItems={isPrivateContext ? "center" : "flexStart"}>
        <Text className="permit-type-designation-text" color="quintenary" variant="body1">
          {designation}
        </Text>
      </FlexBox>
      {!isPrivateContext && (
        <FlexBox alignItems="flexEnd">
          <StyledFrontIcon className="advance-icon" />
        </FlexBox>
      )}
    </Container>
  );
};

export default PermitTypeCard;

PermitTypeCard.propTypes = {
  id: PropTypes.number.isRequired,
  designation: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 250px;
  flex-wrap: wrap;
  border: 2px solid
    ${({ theme, selected }) =>
      theme.card.outlined.primary[selected ? "selectedBorderColor" : "borderColor"]};
  cursor: pointer;
  padding: 15px;
  height: 50px;
  height: 80px;

  &:not(:first-of-type) {
    margin-top: ${CARDS_VERTICAL_MARGIN_BETWEEN}px;
  }

  ${({ selected }) =>
    selected &&
    css`
      border-color: ${({ theme }) => theme.card.outlined.primary.selectedBorderColor};

      .permit-type-designation-text {
        color: ${({ theme }) => theme.card.outlined.primary.selectedBorderColor};
      }

      .advance-icon {
        fill: ${({ theme }) => theme.card.outlined.primary.selectedBorderColor};
      }
    `}

  :hover {
    border-color: ${({ theme }) => theme.card.outlined.primary.hoverBorderColor};

    .permit-type-designation-text {
      color: ${({ theme }) => theme.card.outlined.primary.hoverBorderColor};
    }

    .advance-icon {
      fill: ${({ theme }) => theme.card.outlined.primary.hoverBorderColor};
    }
  }
`;

const StyledFrontIcon = styled(FrontArrowIcon)`
  fill: ${({ theme }) => theme.card.outlined.primary.borderColor};
`;
