const notificationPermissionStatus = {
  granted: "granted",
  denied: "denied",
  default: "default",
};

export const notificationTypes = {
  noBalance: "no_balance",
  balanceAvailable: "balance_available",
  longParking: "long_parking",
  maxTimeExceeded: "max_time_exceeded",
  parkingAlmostFinished: "parking_almost_ending",
  parkingFinished: "parking_finished",
  infractionEmitted: "infraction_emitted",
  externalBalanceCharge: "external_balance_charge",
  externalParkingPayment: "external_parking_payment",
  externalNoticePayment: "external_notice_payment",
  permitHolderChangeState: "permit_holder_change_of_state",
  permitHolderPaymentSubscription: "permit_holder_payment_subscription",
  externalSubscriptionPayment: "external_subscription_payment",
};

export const notificationTypeIds = {
  [notificationTypes.permitHolderChangeState]: 1,
  [notificationTypes.permitHolderPaymentSubscription]: 2,
  [notificationTypes.balanceAvailable]: 3,
  [notificationTypes.longParking]: 4,
  [notificationTypes.maxTimeExceeded]: 5,
  [notificationTypes.noBalance]: 6,
  [notificationTypes.parkingAlmostFinished]: 7,
  [notificationTypes.parkingFinished]: 8,
  [notificationTypes.infractionEmitted]: 9,
};

const silentNotificationTypes = [
  notificationTypes.externalBalanceCharge,
  notificationTypes.externalParkingPayment,
  notificationTypes.externalNoticePayment,
  notificationTypes.externalSubscriptionPayment,
];

export const hasNotificationPermission = () =>
  Notification.permission === notificationPermissionStatus.granted;

export const isNotificationTypeSupported = (notificationType) => {
  if (!notificationType) {
    return false;
  }

  return Object.values(notificationTypes).includes(notificationType);
};

export const shouldDisplayNotification = (notificationType) => {
  if (!isNotificationTypeSupported(notificationType)) {
    return false;
  }

  return !silentNotificationTypes.includes(notificationType);
};
