import { useAuth } from "components/AuthProvider";
import Button from "components/buttons/Button";
import { Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import QRCode from "../../components/QRCode";
import ContentHeader from "../../components/smallDevices/ContentHeader";
import PasswordForm from "../PasswordForm";
import { profileDefaultProps, profilePropTypes } from "../propTypes";
import UserForm from "../UserForm";

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  .profile-edit-icon {
    margin-left: auto;
  }
`;

const Container = styled.div`
  margin-top: 50px;
  display: flex;
  width: fill-available;
`;

const FormContainer = styled.div`
  width: fill-available;
  margin-left: 80px;
`;

const Profile = ({
  user,
  countries,
  countryCodesMapping,
  saveUserCallback,
  saveUserPasswordCallback,
  onUserFormEditClickCallback,
  onPasswordEditClickCallback,
  editUserForm,
  editPasswordForm,
  extraRequiredFields,
  title,
}) => {
  const { t } = useTranslation();
  const { loginWithExternalApp } = useAuth();

  return (
    <>
      <HeaderContainer>
        <ContentHeader
          topTitle={title.top}
          bottomTitle={title.bottom}
          backCallback={
            editPasswordForm ? () => onPasswordEditClickCallback(false) : undefined
          }
        />
        {!editUserForm && (
          <EditIcon
            className="profile-edit-icon"
            cursor="pointer"
            onClick={() => onUserFormEditClickCallback(true)}
          />
        )}
      </HeaderContainer>
      {editPasswordForm ? (
        <PasswordForm saveUserPasswordCallback={saveUserPasswordCallback} />
      ) : (
        <Container>
          <div>
            <Text variant="body7" className="uppercase">
              {t("8187") /* O meu identificador */}
            </Text>
            <QRCode className="mt-40" width={160} height={160} value={user.uid} />
          </div>
          <FormContainer>
            <UserForm
              user={user}
              countries={countries}
              countryCodesMapping={countryCodesMapping}
              edit={editUserForm}
              saveUserCallback={saveUserCallback}
              extraRequiredFields={extraRequiredFields}
              onCancel={() => onUserFormEditClickCallback(false)}
            />
            {!loginWithExternalApp && (
              <Button
                size="sm"
                color="info"
                className="mt-60"
                onClick={() => onPasswordEditClickCallback(true)}
              >
                {t("8192") /* Alterar senha */}
              </Button>
            )}
          </FormContainer>
        </Container>
      )}
    </>
  );
};

Profile.propTypes = profilePropTypes;

Profile.defaultProps = profileDefaultProps;

export default Profile;
