import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Text, Button } from "iparque-components";
import { useBackofficeContext } from "domain/private/components/BackOfficeProvider";
import { useAppContext } from "components/AppProvider";
import { useAuth } from "components/AuthProvider";
import { removeAccount } from "requests/driver";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-left: auto;
  padding: 0;
  min-width: fit-content;
`;

const RemoveAccount = ({ title, className }) => {
  const { t } = useTranslation();
  const { removeSession, driverHash } = useAuth();
  const { setIsLoading } = useAppContext();
  const { displayConfirmation } = useBackofficeContext();

  const handleClick = useCallback(async () => {
    displayConfirmation({
      title: t("9345") /* Apagar conta */,
      message: t(
        "9346"
      ) /* Ao confirmares irás apagar a tua conta de forma permanente e irreversível. Tens a certeza que pretendes apagar a tua conta? */,
      onConfirm: async () => {
        setIsLoading(true);

        const result = await removeAccount(driverHash);

        setIsLoading(false);

        if (!result) {
          return;
        }

        removeSession();
      },
      configs: {
        confirmationButtonColor: "danger",
      },
    });
  }, []);

  return (
    <Container className={className}>
      <div className="mr-10">
        <Text variant="body9">{title}</Text>
      </div>
      <StyledButton color="danger" variant="link" onClick={handleClick}>
        {t("1568") /* Apagar */}
      </StyledButton>
    </Container>
  );
};

RemoveAccount.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
};

RemoveAccount.defaultProps = {
  className: null,
};

export default RemoveAccount;
