/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Text, Search } from "iparque-components";
import useSearchField from "../../../hooks/useSearchField";
import useInfiniteList from "../../../hooks/useInfiniteList";
import NoticeCard from "./Card";
import { allNoticesDefaultProps, allNoticesPropTypes } from "./propTypes";
import ListSkeleton from "../components/ListSkeleton";
import { NOTICE_PLACEHOLDER_HEIGHT } from "./controller";
import { CONTENT_CONTAINER_HORIZONTAL_SPACING } from "../components/ContentBorderBox";

const Container = styled.div`
  margin: 0 ${CONTENT_CONTAINER_HORIZONTAL_SPACING}px;
`;

const ListContainer = styled.div`
  max-height: 800px;
  overflow: auto;
`;

const AllNotices = ({ getNoticesCallback }) => {
  const { t } = useTranslation();
  const { search, debounceSearch, onSearchChange } = useSearchField();
  const {
    items,
    isLoading,
    isRequested,
    onFinishedScroll,
    setSearch,
    hasError,
  } = useInfiniteList(getNoticesCallback);

  useEffect(() => {
    if (search === undefined) {
      return;
    }

    setSearch({ licensePlate: debounceSearch });
  }, [debounceSearch]);

  if (hasError || (isRequested && !isLoading && !items.length && search === undefined)) {
    return null;
  }

  const shouldRenderSearch = items.length > 0 || search !== undefined;

  return (
    <Container>
      <Text variant="body9" className="uppercase mt-30">
        {t("2950") /* Todas */}
      </Text>
      {shouldRenderSearch && (
        <Search
          className="mb-15 mt-10"
          value={search}
          name="search-notice"
          placeholder={t("8455") /* Pesquisa por matrícula */}
          onChange={onSearchChange}
          disabled
        />
      )}
      <ListContainer onScroll={onFinishedScroll}>
        {isLoading ? (
          <ListSkeleton itemsNumber={3} height={NOTICE_PLACEHOLDER_HEIGHT} />
        ) : (
          <>
            {items.map((item) => (
              <NoticeCard key={`${item.number}-all`} paid={false} {...item} />
            ))}
          </>
        )}
      </ListContainer>
    </Container>
  );
};

AllNotices.propTypes = allNoticesPropTypes;

AllNotices.defaultProps = allNoticesDefaultProps;

export default AllNotices;
