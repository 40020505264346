import NotificationsDataSource from "lib/clients/iParque/dataSources/notificationsDataSource";
import { sortOrder } from "utils/server";

const notificationsDataSource = new NotificationsDataSource();

const processNotifications = (notifications) =>
  notifications.map((notification) => ({
    id: notification.id,
    title: notification.title,
    message: notification.message,
    stateId: notification.driverNotificationStateId,
    typeId: notification.driverNotificationTypeId,
    resourceId: notification.resourceId,
    createdAt: notification.createdAt,
  }));

export const getAll = async (entityId, authHash, params) => {
  try {
    const response = await notificationsDataSource.getAll(entityId, authHash, {
      ...params,
      sortBy: "id",
      sortOrder: sortOrder.desc,
    });

    return {
      items: processNotifications(response.items),
      totalItems: response.totalItems,
      totalPages: response.totalPages,
    };
  } catch {
    return { hasError: true };
  }
};

export const markAsRead = async (entityId, authHash, notificationId, params) => {
  try {
    await notificationsDataSource.markAsRead(entityId, authHash, notificationId, params);

    return true;
  } catch {
    return false;
  }
};
