import CardCell from "domain/private/components/cardList/Cell";
import useLanguage from "hooks/useLanguage";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { convertDateTimeToReadableDate } from "utils/dateTime";
import { permitHoldersOccupationType } from "utils/permitHolders";
import ListCard from "../../components/Card";

const Card = ({
  id,
  reference,
  typeName,
  creationDateTime,
  licencePlates,
  city,
  stateName,
  stateId,
  approved,
  occupationTypeId,
  ...other
}) => {
  const { t } = useTranslation();
  const language = useLanguage();

  return (
    <ListCard id={id} approved={approved} {...other}>
      <CardCell size="sm">
        <Text variant="body4">{t("2021") /* Referência */}</Text>
        <Text variant="body3">{reference}</Text>
      </CardCell>
      <CardCell size="sm">
        <Text variant="body4">{t("3659") /* Tipo */}</Text>
        <Text variant="body3">{typeName}</Text>
      </CardCell>
      <CardCell size="sm">
        <Text variant="body4">{t("8827") /* Data de criação */}</Text>
        <Text variant="body3">
          {convertDateTimeToReadableDate(creationDateTime, null, language)}
        </Text>
      </CardCell>
      <CardCell size="sm">
        {occupationTypeId === permitHoldersOccupationType.vehicle && (
          <>
            <Text variant="body4">{t("179") /* Matrículas */}</Text>
            <Text variant="body3">
              {licencePlates.length > 0 ? licencePlates.join("; ") : "-"}
            </Text>
          </>
        )}
      </CardCell>
      <CardCell size="sm">
        <Text variant="body4">{t("2053") /* Cidade */}</Text>
        <Text variant="body3">{city}</Text>
      </CardCell>
      {!approved && stateId && (
        <CardCell size="sm">
          <StateText stateId={stateId} variant="body3">
            {stateName}
          </StateText>
        </CardCell>
      )}
    </ListCard>
  );
};

export default Card;

Card.propTypes = {
  id: PropTypes.number.isRequired,
  reference: PropTypes.string.isRequired,
  typeName: PropTypes.string,
  creationDateTime: PropTypes.string.isRequired,
  licencePlates: PropTypes.arrayOf(PropTypes.string).isRequired,
  occupationTypeId: PropTypes.oneOf(Object.values(permitHoldersOccupationType))
    .isRequired,
  city: PropTypes.string.isRequired,
  stateName: PropTypes.string,
  stateId: PropTypes.number,
  approved: PropTypes.bool,
};

Card.defaultProps = {
  typeName: "-",
  approved: false,
  stateId: undefined,
  stateName: undefined,
};

const StateText = styled(Text)`
  color: ${({ theme, stateId }) => theme.permitHolders.states[stateId]};
`;
