import { partyTypes } from "utils/clients";

export const tabsIdsMap = {
  general: "general",
  documents: "documents",
  vehicles: "vehicles",
  locations: "locations",
  subscriptions: "subscriptions",
  history: "history",
};

export const personalDataDefaultValues = {
  partyTypeId: partyTypes.person,
  name: "",
  countryId: undefined,
  taxpayerIdentificationNumber: "",
  commercialName: "",
  economicActivityCode: undefined,
  address: "",
  block: "",
  floor: "",
  doorNumber: "",
  zipCode: "",
  locality: "",
  districtId: undefined,
  email: "",
  phone: "",
  mobilePhone: "",
};

export const warningsMap = {
  rejection: "rejection",
  missingDocuments: "requiredDocuments",
  missingVehicles: "noVehiclesAssociated",
  analysisAlert: "analysisAlert",
  expiredDocuments: "expiredDocuments",
};

export const operationsMap = {
  inactivate: "inactivate",
  edit: "edit",
  sendToAnalysis: "sendToAnalysis",
};
