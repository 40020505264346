export const permitHoldersOccupationType = {
  vehicle: 1,
  parkingLot: 2,
};

export const permitHolderStates = {
  underElaboration: 1,
  toApproval: 2,
  approved: 4,
  inactivated: 5,
  rejected: 7,
  provisoryApproved: 8,
  approvedWithChanges: 9,
  underAnalysis: 10,
};

export const permitHoldersSubscriptionsStates = {
  active: 1,
  inactive: 2,
  waitingForPayment: 3,
  waitingForBeginning: 4,
  waitingForRenewal: 5,
  expired: 6,
  notPaid: 7,
};

export const approvedPermitHolderStates = [
  permitHolderStates.approved,
  permitHolderStates.provisoryApproved,
  permitHolderStates.approvedWithChanges,
];

export const unapprovedPermitHolderStates = [
  permitHolderStates.underElaboration,
  permitHolderStates.toApproval,
  permitHolderStates.rejected,
  permitHolderStates.underAnalysis,
  permitHolderStates.inactivated,
];
