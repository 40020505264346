import { errorToast } from "iparque-components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { createAttachment } from "requests/attachments";
import { createClient, createIdentificationDocument } from "requests/clients";
import { createIdentification } from "requests/driversIdentifications";
import { attachmentTypes } from "utils/attachments";
import { clientValidationScenarios, partyCreationTypes } from "utils/clients";
import { PORTUGAL } from "utils/countries";
import { formatToServerDate } from "utils/dateTime";
import { identificationTypes } from "utils/driversIdentifications";
import { identificationDocumentType } from "utils/identificationDocuments";
import { usePublicEntityContext } from "../../Provider";
import { useDriverIdentificationContext } from "../Provider";

const useDriverIdentificationCreation = () => {
  const { t } = useTranslation();
  const {
    identificationData,
    personalData,
    documents,
  } = useDriverIdentificationContext();
  const { entity } = usePublicEntityContext();
  const { identificationToken } = useParams();

  const saveIdentificationDocuments = !(
    identificationData?.typeId === identificationTypes.rentalVehicleDriver &&
    entity?.countryId !== PORTUGAL
  );

  const create = useCallback(
    async ({ recaptchaToken } = {}) => {
      const clientResponse = await createClient(
        entity.id,
        {
          ...personalData,
          scenario:
            identificationData.typeId === identificationTypes.rentalVehicleDriver &&
            entity.countryId !== PORTUGAL
              ? clientValidationScenarios.driversIdentifications
              : clientValidationScenarios.offenceDefendant,
          creationContextTypeId: partyCreationTypes.owners,
          recaptchaToken,
        },
        { autoCloseError: false }
      );

      if (!clientResponse) {
        return null;
      }

      const { clientId } = clientResponse;

      let attachmentId = null;

      if (documents.contract) {
        const attachmentResponse = await createAttachment(
          entity.id,
          documents.contract,
          {
            attachmentTypeId: attachmentTypes.rentalContract,
            fileName: documents.contract.name,
          },
          { autoCloseError: false }
        );

        if (!attachmentResponse) {
          return null;
        }

        attachmentId = attachmentResponse.attachmentId;
      }

      const identificationDocuments = saveIdentificationDocuments
        ? [
            createIdentificationDocument(
              entity.id,
              clientId,
              {
                identificationDocumentTypeId: identificationDocumentType.drivingLicence,
                drivingLicencePrefixId: documents.drivingLicence.prefix,
                countryId: documents.drivingLicence.countryId,
                documentNumber: documents.drivingLicence.number,
                issuingEntity: documents.drivingLicence.issuingEntity,
                issuingDate: documents.drivingLicence.issuingDate
                  ? formatToServerDate(documents.drivingLicence.issuingDate)
                  : null,
                restrictions: documents.drivingLicence.restrictions,
                drivingLicenceCategories: documents.drivingLicence.categories,
              },
              { autoCloseError: false },
              { t }
            ),
            createIdentificationDocument(
              entity.id,
              clientId,
              {
                identificationDocumentTypeId: identificationDocumentType.citizenCard,
                countryId: documents.citizenCard.countryId,
                documentNumber: documents.citizenCard.number,
                issuingEntity: documents.citizenCard.issuingEntity,
                issuingDate: documents.citizenCard.issuingDate
                  ? formatToServerDate(documents.citizenCard.issuingDate)
                  : null,
              },
              { autoCloseError: false },
              { t }
            ),
          ]
        : [];

      const results = await Promise.all([
        ...identificationDocuments,
        createIdentification(
          identificationToken,
          {
            ownerId: clientId,
            attachmentId,
          },
          { t }
        ),
      ]);

      const areAllRequestsMadeWithSuccess = results.every((result) => result);

      if (!areAllRequestsMadeWithSuccess) {
        errorToast(
          t("3613") /* Erro */,
          t(
            "10732"
          ) /* Foram obtidos alguns erros ao guardar os dados da identificação do condutor, por favor tenta novamente */,
          { autoClose: false }
        );

        return null;
      }

      return { clientId };
    },
    [entity, personalData, documents]
  );

  return create;
};

export default useDriverIdentificationCreation;
