import FlexBox from "components/FlexBox";
import DropdownList from "domain/private/components/DropdownList";
import React from "react";
import styled from "styled-components";
import LocationsMap from "../../../components/locations/Map";
import LocationsTabStatus from "../../../components/locations/Status";
import TabTitle from "../../../components/Title";
import { tabsIdsMap } from "../../../helper";
import useLocationsTab from "../../../hooks/useLocationsTab";

const LocationsTab = () => {
  const {
    mapController,
    selectedStreet,
    setSelectedStreet,
    zones,
    isLoading,
  } = useLocationsTab();

  return (
    <>
      <TabTitle tabId={tabsIdsMap.locations} />
      <LocationsTabStatus />
      {(zones.length > 0 || isLoading) && (
        <FlexBox>
          <MapContainer>
            <LocationsMap
              mapController={mapController}
              onStreetClick={setSelectedStreet}
            />
          </MapContainer>
          <FlexBox className="ml-30 fill">
            <DropdownList items={zones} selectedItem={selectedStreet} />
          </FlexBox>
        </FlexBox>
      )}
    </>
  );
};

export default LocationsTab;

const MapContainer = styled.div`
  width: 60%;
`;
