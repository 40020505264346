import React from "react";
import { Text } from "iparque-components";
import { useTranslation } from "react-i18next";

const EmptyResultsText = () => {
  const { t } = useTranslation();

  return (
    <Text variant="body10" className="mt-20">
      {t("8412") /* Sem movimentos */}
    </Text>
  );
};

export default EmptyResultsText;
