import { useAuth } from "components/AuthProvider";
import { notificationToast } from "iparque-components";
import firebaseMessageObservable from "lib/firebase/firebaseMessageObservable";
import PropTypes from "prop-types";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useHistory } from "react-router-dom";
import { notificationStates } from "utils/notifications";
import { shouldDisplayNotification } from "utils/pushNotifications";
import { routesMapping } from "utils/routes";
import { getAll, markAsRead as markAsReadRequest } from "./controller";
import { actionTypes } from "./store/actions";
import reducer from "./store/reducer";

const initialReducerState = {
  notifications: [],
  totalNotifications: 0,
  unreadNumber: 0,
  isOpen: false,
};

const NotificationsContext = createContext({
  ...initialReducerState,
  open: () => undefined,
  close: () => undefined,
  toggleOpen: () => undefined,
  markAsRead: () => undefined,
  updateUnread: () => undefined,
  dispatch: () => undefined,
});

const NotificationsProvider = ({ children }) => {
  const history = useHistory();
  const { defaultEntityId, driverHash } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialReducerState);

  const displayNotification = useCallback(({ notification, data }) => {
    if (!shouldDisplayNotification(data?.type)) {
      return;
    }

    const urlParams = new URLSearchParams(data);
    const url = `${routesMapping.backofficeNotification}?${urlParams}`;

    notificationToast(notification.title, notification.body, {
      onClick: () => history.push(url),
    });
  });

  const getUnreadNotificationsNumber = useCallback(async () => {
    if (!defaultEntityId) {
      return;
    }

    const response = await getAll(defaultEntityId, driverHash, {
      limit: 1,
      stateId: notificationStates.unread,
    });

    dispatch({ type: actionTypes.SET_UNREAD_NUMBER, payload: response.totalItems || 0 });
  }, [defaultEntityId, driverHash]);

  const open = useCallback(() => {
    getUnreadNotificationsNumber();
    dispatch({ type: actionTypes.OPEN });
  }, [getUnreadNotificationsNumber]);

  const close = useCallback(() => {
    getUnreadNotificationsNumber();
    dispatch({ type: actionTypes.CLOSE });
  }, [getUnreadNotificationsNumber]);

  const toggleOpen = useCallback(() => (state.isOpen ? close() : open()), [
    state.isOpen,
    close,
    open,
  ]);

  useEffect(() => {
    const onMessageHandler = (message) => {
      close();
      displayNotification(message);
    };

    firebaseMessageObservable.subscribeOnMessage(onMessageHandler);

    return () => firebaseMessageObservable.unsubscribeOnMessage(onMessageHandler);
  }, [state.isOpen, close]);

  useEffect(() => {
    getUnreadNotificationsNumber();
  }, [getUnreadNotificationsNumber]);

  useEffect(() => {
    const onVisibilityChangeHandler = () => {
      if (document.visibilityState === "visible") {
        return;
      }

      if (state.isOpen) {
        dispatch({ type: actionTypes.TOGGLE_OPEN });
      }

      getUnreadNotificationsNumber();
    };

    document.addEventListener("visibilitychange", onVisibilityChangeHandler);

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChangeHandler);
    };
  }, [state.isOpen, getUnreadNotificationsNumber]);

  const markAsRead = useCallback(
    async (notificationId, params) => {
      const previousUnreadNumber = state.unreadNumber;

      dispatch({
        type: actionTypes.MARK_AS_READ,
        payload: notificationId,
      });

      const success = await markAsReadRequest(
        defaultEntityId,
        driverHash,
        notificationId,
        params
      );

      if (success) {
        return;
      }

      dispatch({
        type: actionTypes.MARK_AS_UNREAD,
        payload: { notificationId, previousUnreadNumber },
      });
    },
    [state.unreadNumber, defaultEntityId, driverHash]
  );

  return (
    <NotificationsContext.Provider
      value={{
        ...state,
        open,
        close,
        toggleOpen,
        markAsRead,
        updateUnread: getUnreadNotificationsNumber,
        dispatch,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;

export const useNotificationsContext = () => useContext(NotificationsContext);

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
