import { driverDark, driverDarkColors as colors } from "iparque-components";
import { parkingTypesMapping } from "utils/parking";
import {
  permitHoldersSubscriptionsStates,
  permitHolderStates,
} from "utils/permitHolders";
import { benefitTypesMapping } from "../utils/benefits";
import { colorsMapping } from "../utils/colors";
import { streetColorsMapping } from "../utils/streets";
import parkingRequirementsAnimation from "./animations/parkingRequirements/dark";
import mapTheme from "./maps/dark";

const backgroundColor = colors.darker;

const dark = {
  ...driverDark,
  color: colors,
  map: mapTheme,
  background: {
    color: {
      menu: colors.darkest,
      smallDevicesMenu: colors.darker,
      background: backgroundColor,
      primary: colors.primary,
      secondary: colors.lightest,
    },
  },
  scroll: {
    trackColor: colors.dark,
    thumbColor: colors.primary,
  },
  box: {
    color: colors.dark,
    highlightedColor: colors.mediumDark,

    // Exceptions
    entitiesBackgroundColor: colors.dark,
    entitiesBorderColor: colors.dark,
    selectedEntityBoderderColor: colors.lightest,
  },
  streetBox: {
    highlightColor: colors.primary,
    backgroundColor: colors.dark,
    buttonColor: {
      selected: "primary",
      unselected: "quaternary",
    },
  },
  icon: {
    color: colors.lightest,
    selectedColor: colors.primary,
  },
  parking: {
    horizontalList: {
      arrow: {
        enabled: colors.primary,
        disabled: colors.medium,
      },
    },
    licencePlateBox: {
      text: "#E2E2E2",
      border: "#9F9F9F",
    },
    parkingTypeBox: {
      [parkingTypesMapping.startAndStop]: {
        color: colors.primary,
        contentColor: colors.dark,
      },
      [parkingTypesMapping.duration]: {
        color: colors.secondary,
        contentColor: colors.dark,
      },
    },
    benefitBox: {
      contentColor: colors.darker,
    },
  },
  carColor: {
    [colorsMapping.black]: "#626262",
    [colorsMapping.red]: "#FF5F55",
    [colorsMapping.yellow]: "#FFDF5A",
    [colorsMapping.green]: "#2EBD61",
    [colorsMapping.white]: "#E2E2E2",
    [colorsMapping.blue]: "#37A9FD",
    [colorsMapping.gray]: "#ACACAC",
    [colorsMapping.orange]: "#FFA253",
  },
  streetColor: {
    [streetColorsMapping.red]: "#DB2127",
    [streetColorsMapping.yellow]: "#FBD75B",
    [streetColorsMapping.green]: "#7BD148",
    [streetColorsMapping.blue]: colors.secondary,
    [streetColorsMapping.gray]: "#BDC3C7",
    [streetColorsMapping.orange]: "#FFB878",
  },
  benefitColor: {
    [benefitTypesMapping.offerMinutesFirstPaidPeriod]: "#DA93FF",
    [benefitTypesMapping.offerMinutesAfterFirstPaidPeriod]: "#8DF6FF",
    [benefitTypesMapping.offerMinutesMultipleParkings]: "#EBD58C",
    [benefitTypesMapping.percentageDiscount]: "#FF91F7",
  },
  input: {
    borderColor: colors.primary,
    textColor: colors.primary,
    focusedBorderColor: colors.primary,
  },
  loadingSkeleton: {
    color: colors.lightest,
    highlightColor: colors.light,
    listColor: colors.dark,
    listHighlightColor: colors.medium,
    smoothColor: colors.dark,
    smoothHighlightColor: colors.medium,
  },
  paymentBox: {
    borderColor: {
      normal: colors.medium,
      hover: colors.lightest,
    },
  },
  parkingPaymentBox: {
    borderColor: colors.lightest,
    iconColor: colors.lightest,
  },
  popup: {
    overlayColor: "#00000080",
    backgroundColor: colors.mediumDark,
    titleColor: colors.lightest,
  },
  qrCode: {
    backgroundColor,
    figureColor: colors.lightest,
  },
  border: {
    lightBackground: {
      color: colors.lightest,
    },
  },
  animation: {
    stopParking: parkingRequirementsAnimation,
  },
  paymentIcon: {
    accountMovements: {
      iconColor: colors.lightest,
    },
    paymentMethod: {
      iconColor: colors.lightest,
    },
  },
  tab: {
    vertical: {
      selectedColor: colors.mediumDark,
      hoverColor: colors.dark,
      arrowColor: colors.lightest,
    },
  },
  dropdownList: {
    border: colors.dark,
    arrowColor: colors.lightest,
  },
  boxOptionsField: {
    backgroundColor: colors.mediumDark,
    selectedBackgroundColor: colors.lightest,
  },
  definitions: {
    exportIconColor: colors.lightest,
  },
  balanceCharge: {
    input: colors.lightest,
    options: colors.lightest,
  },
  draggableFile: {
    primary: {
      borderColor: colors.lightest,
    },
  },
  feeInfo: {
    typePicker: {
      background: {
        selected: colors.lightest,
        unselected: colors.mediumDark,
      },
      border: {
        selected: colors.lightest,
        unselected: colors.lightest,
      },
      text: {
        selected: colors.mediumDark,
        unselected: colors.lightest,
      },
    },
    priceTable: {
      background: `#FFFFFF26`,
      text: colors.lightest,
    },
  },
  requirements: {
    border: colors.dark,
    background: colors.dark,
    label: colors.lightest,
  },
  card: {
    outlined: {
      primary: {
        borderColor: colors.lightest,
        hoverBorderColor: colors.primary,
        selectedBorderColor: colors.primary,
      },
    },
    dense: {
      primary: {
        backgroundColor: colors.dark,
        iconColor: colors.lightest,
        positiveIconColor: "#198D6E",
      },
    },
  },
  permitHolders: {
    unapprovedBoxCorner: colors.lightest,
    approvedCardBackground: colors.dark,
    states: {
      [permitHolderStates.underElaboration]: "#F17B06",
      [permitHolderStates.toApproval]: colors.darkest,
      [permitHolderStates.approved]: colors.success,
      [permitHolderStates.rejected]: colors.danger,
      [permitHolderStates.provisoryApproved]: colors.success,
      [permitHolderStates.approvedWithChanges]: colors.success,
      [permitHolderStates.underAnalysis]: colors.secondary,
      [permitHolderStates.inactivated]: colors.light,
    },
    subscriptionStates: {
      [permitHoldersSubscriptionsStates.active]: colors.success,
      [permitHoldersSubscriptionsStates.inactive]: colors.danger,
      [permitHoldersSubscriptionsStates.waitingForPayment]: "#FFDF5A",
      [permitHoldersSubscriptionsStates.waitingForBeginning]: "#4E87FF",
      [permitHoldersSubscriptionsStates.waitingForRenewal]: "#BD8CED",
      [permitHoldersSubscriptionsStates.expired]: "#626262",
      [permitHoldersSubscriptionsStates.notPaid]: "#E2E2E2",
    },
  },
  alerts: {
    danger: { icon: colors.danger, background: colors.dark },
    warning: { icon: "#ffa253", background: colors.dark },
  },
  notifications: {
    backgroundColor: colors.mediumDark,
    mediumDevicesBackgroundColor: colors.darker,
    noNotificationsTextColor: "#CBCBCB",
    headerTextColor: colors.lightest,
    showAllTextColor: colors.secondary,
    card: {
      dividerColor: colors.dark,
      hoverColor: colors.medium,
      dateColor: "#808080",
      hoverDateColor: colors.lightest,
      read: { dot: colors.lightest, text: "#AAAAAA" },
      unread: { dot: colors.secondary, text: colors.lightest },
    },
  },
  parkings: {
    timer: {
      label: colors.lightest,
      value: colors.primary,
    },
  },
  citiesCard: {
    borderColor: colors.mediumDark,
    hoverBorderColor: colors.primary,
    selectedBorderColor: colors.primary,
  },
  entitySuggestion: {
    card: {
      backgroundColor: "#313131",
      selectedBackgroundColor: colors.dark,
      selectedBorderColor: "#313131",
      disabledFontColor: colors.light,
    },
  },
};

export default dark;
