import RequirementsList from "domain/private/components/requirements/List";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { routesMapping } from "utils/routes";
import { usePermitHoldersListContext } from "../Provider";

const Requirements = () => {
  const { t } = useTranslation();
  const { requirements } = usePermitHoldersListContext();
  const history = useHistory();

  const addVehicle = () => {
    history.push(`${routesMapping.backofficeVehicles}/add`);
  };

  const requirementsConfig = useMemo(() => {
    const config = [];

    if (!requirements.hasVehicles) {
      config.push({
        label: t("10420") /* Adicionar veículos */,
        button: { label: t("3387") /* Adicionar */, action: addVehicle },
      });
    }

    return config;
  }, [requirements]);

  return (
    <RequirementsList
      requirements={requirementsConfig}
      title={t("10576") /* Para criares dísticos deverás fazer o seguinte: */}
    />
  );
};

export default Requirements;
