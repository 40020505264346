import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { notificationStates } from "utils/notifications";
import { notificationTypes } from "utils/pushNotifications";
import { routesMapping } from "utils/routes";
import { useNotificationsContext } from "./Provider";

const useNotificationRedirect = () => {
  const { replace } = useHistory();
  const { markAsRead, updateUnread } = useNotificationsContext();

  const notificationTypeActionMapping = useMemo(() => {
    const goToAccountMovements = () => replace(routesMapping.backofficeAccountMovements);

    const goToParking = () => replace(routesMapping.backofficeParkings);

    const goToNotices = () => replace(routesMapping.backofficeNotices);

    const goToPermitHolders = (permitHolderId = "") =>
      replace(`${routesMapping.backofficePermitHolders}/${permitHolderId}`);

    return {
      [notificationTypes.balanceAvailable]: goToAccountMovements,
      [notificationTypes.permitHolderChangeState]: goToPermitHolders,
      [notificationTypes.permitHolderPaymentSubscription]: goToPermitHolders,
      [notificationTypes.infractionEmitted]: goToNotices,
      [notificationTypes.longParking]: goToParking,
      [notificationTypes.maxTimeExceeded]: goToParking,
      [notificationTypes.noBalance]: goToParking,
      [notificationTypes.parkingAlmostFinished]: goToParking,
      [notificationTypes.parkingFinished]: goToParking,
    };
  }, [replace]);

  const handleRedirect = useCallback(
    async (id, { type, stateId, resourceId }) => {
      notificationTypeActionMapping[type](resourceId);

      if (stateId === notificationStates.unread) {
        await markAsRead(id);

        updateUnread();
      }
    },
    [markAsRead, updateUnread, notificationTypeActionMapping]
  );

  return { handleRedirect };
};

export default useNotificationRedirect;
