import React, { forwardRef, useImperativeHandle } from "react";
import { ReactComponent as FrontIcon } from "assets/icons/front.svg";
import FlexBox from "components/FlexBox";
import { Text } from "iparque-components";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useHeightAnimation } from "../../../hooks/useHeightAnimation";

const DURATION = 350;

const Card = forwardRef(({ children, header, isOpen, className, readOnly }, ref) => {
  const {
    isOpened,
    open,
    handleClick,
    contentRef,
    isFullyOpened,
    parentRef,
  } = useHeightAnimation(readOnly || isOpen, DURATION);

  useImperativeHandle(ref, () => ({ openCard: open, isOpen: isOpened }));

  return (
    <Container className={className} readOnly={readOnly}>
      <CardHeader onClick={!readOnly ? handleClick : () => {}} readOnly={readOnly}>
        <FlexBox className="fill" justifyContent="spaceBetween" alignItems="center">
          <StyledText color="quintenary">
            {header} {readOnly ? "" : "*"}
          </StyledText>
          {!readOnly && (
            <IconWrapper isOpen={isOpened}>
              <Arrow />
            </IconWrapper>
          )}
        </FlexBox>
      </CardHeader>
      <ContentWrapper isFullyOpened={isFullyOpened} ref={parentRef}>
        <ChildrenWrapper ref={contentRef}>{children}</ChildrenWrapper>
      </ContentWrapper>
    </Container>
  );
});

Card.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
};

Card.defaultProps = {
  isOpen: false,
  className: "",
  readOnly: false,
};

export default Card;

const Container = styled.div`
  width: 100%;
  padding: 20px;
  padding-bottom: ${({ readOnly }) => (readOnly ? "0px" : "20px")};
  box-sizing: border-box;
  border: solid ${({ theme }) => theme.card.outlined.primary.borderColor}
    ${({ readOnly }) => (readOnly ? "0px" : "2px")};
  background-color: ${({ readOnly, theme }) =>
    readOnly ? theme.card.dense.primary.backgroundColor : "transparent"};
`;

const CardHeader = styled.div`
  cursor: ${({ readOnly }) => (readOnly ? "auto" : "pointer")};
`;

const StyledText = styled(Text)`
  flex: 1;
  text-align: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  transition: ${`transform ${DURATION}ms ease-in-out`};
  transform: ${({ isOpen }) => (isOpen ? "rotate(-90deg)" : "rotate(90deg)")};
`;

const Arrow = styled(FrontIcon)`
  width: 10px;
`;

const ContentWrapper = styled.div`
  overflow: ${({ isFullyOpened }) => (isFullyOpened ? "visible" : "hidden")};
  transition: max-height ${`${DURATION}ms ease-in-out`};
  margin-right: 30px;
`;

const ChildrenWrapper = styled.div`
  padding-top: 20px;
`;
