import React from "react";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../components/smallDevices/ContentHeader";
import Vehicles from "./Vehicles";
import SoldVehicles from "../SoldVehicles";

const VehiclesSmallDevices = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContentHeader
        topTitle={t("7946") /* Queres estacionar? */}
        bottomTitle={t("7957") /* Regista o teu veículo! */}
      />
      <Vehicles />
      <SoldVehicles />
    </>
  );
};

export default VehiclesSmallDevices;
