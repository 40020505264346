import InfiniteList from "components/InfiniteList";
import React from "react";
import EmptyUnapprovedList from "../../components/EmptyUnapproved";
import { usePermitHoldersListContext } from "../../Provider";
import Card from "./Card";

const NUMBER_OF_LOADING_ELEMENTS = 1;

const UnapprovedList = () => {
  const {
    unapprovedList: { items, isLoading, onFinishedScroll, hasError },
  } = usePermitHoldersListContext();

  return (
    <InfiniteList
      hasError={hasError}
      onFinishedScroll={onFinishedScroll}
      loadingItemsNumber={NUMBER_OF_LOADING_ELEMENTS}
      isLoading={isLoading}
      renderItem={(item) => <Card {...item} />}
      items={items}
      EmptyListComponent={<EmptyUnapprovedList />}
      maxHeight="40vh"
    />
  );
};

export default UnapprovedList;
