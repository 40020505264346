import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

const SettingsContext = createContext();

export const useSettingsProvider = () => {
  return useContext(SettingsContext);
};

const SettingsProvider = ({ saveSettings, isSettingsLoading, children }) => {
  return (
    <SettingsContext.Provider
      value={{
        saveSettings,
        isSettingsLoading,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  saveSettings: PropTypes.func.isRequired,
  isSettingsLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default SettingsProvider;
