import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContentHeader from "../../components/ContentHeader";
import VehicleForm from "./Form";
import ContentBorderBox from "../../components/ContentBorderBox";
import { useBackofficeThemeContext } from "../../components/ThemeProvider";
import { vehicleProps } from "./propTypes";
import { routesMapping } from "../../../../utils/routes";

const VehicleDetails = ({ vehicle }) => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();
  const history = useHistory();

  return (
    <div>
      <ContentHeader
        topTitle={t("7946") /* Queres estacionar? */}
        bottomTitle={
          vehicle
            ? t("7965") /* Edita o teu veículo */
            : t("7957") /* Regista o teu veículo! */
        }
      />
      <ContentBorderBox
        cornerColor={backofficeTheme.color.primary}
        className="mt-40"
        backCallback={() => {
          history.push(routesMapping.backofficeVehicles);
        }}
        title={
          vehicle
            ? `${t("4214") /* Veículo */} ${vehicle.licensePlate || ""}`
            : t("1874") /* Novo Veículo */
        }
      >
        <ContentContainer>
          <VehicleForm vehicle={vehicle} />
        </ContentContainer>
      </ContentBorderBox>
    </div>
  );
};

export default VehicleDetails;

VehicleDetails.defaultProps = {
  vehicle: undefined,
};

VehicleDetails.propTypes = {
  vehicle: PropTypes.shape(vehicleProps),
};

const ContentContainer = styled.div`
  display: flex;
  padding: 15px 0 0 20px;
`;
