import FlexBox from "components/FlexBox";
import SelectField from "components/formFields/Select";
import TextField from "components/formFields/Text";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DatePickerField from "components/formFields/DatePicker";
import { useFormikContext } from "formik";
import { PORTUGAL } from "utils/countries";
import { usePublicEntityContext } from "domain/public/entities/Provider";
import { identificationTypes } from "utils/driversIdentifications";
import FieldWrapper from "../../components/FieldWrapper";
import FormFileInput from "../../components/FormFileInput/FormFileInput";
import Card from "./components/Card";
import useForm from "./useForm";
import { useDriverIdentificationContext } from "../../Provider";
import { fieldNames } from "./configs";
import DrivingLicenceCategoriesInput from "./components/DrivingLicenceCategoriesInput";

const DocumentsFormBody = ({ readOnly }) => {
  const { t } = useTranslation();
  const { countries, drivingLicencePrefixes, drivingLicenceCategories } = useForm();
  const { isValid, isValidating, isSubmitting, errors } = useFormikContext();
  const { identificationData } = useDriverIdentificationContext();
  const { entity } = usePublicEntityContext();
  const citizenCardRef = useRef();
  const drivingLicenceCardRef = useRef();

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      const errorFields = Object.keys(errors);

      if (errorFields) {
        for (let i = 0; i < Object.keys(errors).length; i += 1) {
          if (
            Object.values(fieldNames.citizenCard).includes(errorFields[i]) &&
            !citizenCardRef.current.isOpen
          ) {
            citizenCardRef.current.openCard();
          }

          if (
            Object.values(fieldNames.drivingLicence).includes(errorFields[i]) &&
            !drivingLicenceCardRef.current.isOpen
          ) {
            drivingLicenceCardRef.current.openCard();
          }
        }
      }
    }
  }, [isSubmitting, isValid, isValidating]);

  const isVoluntaryIdentification =
    identificationData?.typeId === identificationTypes.voluntary;

  const isRentalVehicleDriverIdentification =
    identificationData?.typeId === identificationTypes.rentalVehicleDriver;

  const isPortugalRentalVehicleDriverIdentification =
    isRentalVehicleDriverIdentification && entity?.countryId === PORTUGAL;

  const showPersonalIdentificationDocuments =
    isVoluntaryIdentification || isPortugalRentalVehicleDriverIdentification;

  return (
    <>
      <div>
        {showPersonalIdentificationDocuments && (
          <>
            <Card
              header={`${t("10685") /* Cartão de cidadão */}`}
              ref={citizenCardRef}
              isOpen
              readOnly={readOnly}
            >
              <StyledFlexBox>
                <FieldWrapper>
                  <SelectField
                    className="form-field"
                    name={fieldNames.citizenCard.countryId}
                    options={countries}
                    label={t("2721") /* País */}
                    readOnly={readOnly}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    className="form-field"
                    name={fieldNames.citizenCard.number}
                    label={`${t("4713") /* Número */}`}
                    readOnly={readOnly}
                  />
                </FieldWrapper>
              </StyledFlexBox>
              <StyledFlexBox>
                <FieldWrapper>
                  <TextField
                    className="form-field"
                    name={fieldNames.citizenCard.issuingEntity}
                    label={`${t("1180") /* Entidade emissora */}`}
                    readOnly={readOnly}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  {entity && (
                    <DatePickerField
                      name={fieldNames.citizenCard.issuingDate}
                      label={t("1181") /* Data de emissão */}
                      readOnly={readOnly}
                    />
                  )}
                </FieldWrapper>
              </StyledFlexBox>
            </Card>
            <Card
              header={`${t("10727") /* Carta de condução */}`}
              ref={drivingLicenceCardRef}
              isOpen
              readOnly={readOnly}
              className="mt-30"
            >
              <StyledFlexBox>
                <FieldWrapper>
                  <SelectField
                    className="form-field"
                    name={fieldNames.drivingLicence.countryId}
                    options={countries}
                    label={t("2721") /* País */}
                    readOnly={readOnly}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <SelectField
                    className="form-field"
                    name={fieldNames.drivingLicence.prefix}
                    options={drivingLicencePrefixes}
                    label={t("8911") /* Prefixo */}
                    readOnly={readOnly}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    className="form-field"
                    name={fieldNames.drivingLicence.number}
                    label={`${t("4713") /* Número */}`}
                    readOnly={readOnly}
                  />
                </FieldWrapper>
              </StyledFlexBox>
              <StyledFlexBox>
                <FieldWrapper>
                  <TextField
                    className="form-field"
                    name={fieldNames.drivingLicence.issuingEntity}
                    label={`${t("1180") /* Entidade emissora */}`}
                    readOnly={readOnly}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  {entity && (
                    <DatePickerField
                      name={fieldNames.drivingLicence.issuingDate}
                      label={t("1181") /* Data de emissão */}
                      readOnly={readOnly}
                    />
                  )}
                </FieldWrapper>
              </StyledFlexBox>
              <DrivingLicenceCategoriesInput
                name={fieldNames.drivingLicence.categories}
                drivingLicenceCategories={drivingLicenceCategories}
                readOnly={readOnly}
              />
              <FieldWrapper>
                <TextField
                  className="form-field mt-20"
                  name={fieldNames.drivingLicence.restrictions}
                  label={`${t("8893") /* Restrições */}`}
                  readOnly={readOnly}
                />
              </FieldWrapper>
            </Card>
          </>
        )}
        {isRentalVehicleDriverIdentification && !readOnly && (
          <FormFileInput
            className={showPersonalIdentificationDocuments ? "mt-30" : ""}
            name="contract"
            designation={t("6405") /* Contrato */}
            readOnly={readOnly}
            isRequired
          />
        )}
      </div>
    </>
  );
};

DocumentsFormBody.propTypes = {
  readOnly: PropTypes.bool,
};

DocumentsFormBody.defaultProps = {
  readOnly: false,
};

export default DocumentsFormBody;

const StyledFlexBox = styled(FlexBox)`
  display: flex;
  column-gap: 2em;
  flex-flow: row wrap;
`;
