import { colorsMapping } from "./colors";
import { feeTypesMapping } from "./fees";
import { paymentMethodsMapping } from "./payment";

export const vehicleStates = {
  available: "available",
  availableWithNonStoppableParking: "availableWithNonStoppableParking",
  unavailable: "unavailable",
};

const vehicleStatesThatAllowParking = [
  vehicleStates.available,
  vehicleStates.availableWithNonStoppableParking,
];

export const changeIndexToLicensePlate = (vehicles) => {
  const vehiclesProcessed = {};

  vehicles.forEach((vehicle) => {
    vehiclesProcessed[vehicle.licensePlate] = vehicle;
  });

  return vehiclesProcessed;
};

export const getVehiclesColorsMap = (vehicles) => {
  const vehiclesProcessed = {};

  vehicles.forEach((vehicle) => {
    vehiclesProcessed[vehicle.licensePlate] = vehicle?.colorId || colorsMapping.gray;
  });

  return vehiclesProcessed;
};

export const getAvailableVehicles = (vehicles) => {
  return vehicles.filter((vehicle) =>
    vehicleStatesThatAllowParking.includes(vehicle.state)
  );
};

export const getVehicleState = (parkings) => {
  if (!parkings || parkings?.length === 0) {
    return vehicleStates.available;
  }

  for (let index = 0; index < parkings.length; index += 1) {
    const parking = parkings[index];

    if (parking.feeTypeId === feeTypesMapping.minutes) {
      if (parking.parkingPaymentTypeId === paymentMethodsMapping.mbway.id) {
        return vehicleStates.availableWithNonStoppableParking;
      }

      return vehicleStates.unavailable;
    }
  }

  return vehicleStates.availableWithNonStoppableParking;
};
