import React from "react";
import FlexBox from "components/FlexBox";
import styled from "styled-components";
import { Text } from "iparque-components";
import { ReactComponent as SvgSuccessIcon } from "assets/icons/success.svg";
import { ReactComponent as SvgErrorIcon } from "assets/icons/error.svg";
import PropTypes from "prop-types";

const Message = ({ type, text }) => {
  const icon = {
    success: <SuccessIcon />,
    error: <ErrorIcon />,
  };

  return (
    <Container justify="flexStart" alignItems="center" type={type}>
      {icon[type]}
      <Text variant="body6" color="quintenary">
        {text}
      </Text>
    </Container>
  );
};

Message.propTypes = {
  type: PropTypes.oneOf(["success", "error"]).isRequired,
  text: PropTypes.string.isRequired,
};

export default Message;

const Container = styled(FlexBox)`
  background-color: ${({ type, theme }) =>
    type === "error"
      ? theme.driversIdentifications.message.colors.error
      : theme.driversIdentifications.message.colors.success};
  padding: 1em;
  margin: 0.5em 0;
`;

const SuccessIcon = styled(SvgSuccessIcon)`
  width: 25px;
  margin-right: 1em;
`;

const ErrorIcon = styled(SvgErrorIcon)`
  width: 25px;
  margin-right: 1em;
`;
