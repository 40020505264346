import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ClockOptions from "./ClockOptions";
import ConfigurableClock from "./ConfigurableClock";

export const CLOCK_WITH_OPTIONS_GAP = 40;

const Container = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  height: 200px;
  gap: ${CLOCK_WITH_OPTIONS_GAP}px;

  > div {
    flex: 1;
  }
`;

const ConfigurableClockWithOptions = ({ duration, onDurationChange, max, min }) => {
  return (
    <Container>
      <ConfigurableClock
        max={max}
        min={min}
        duration={duration}
        onDurationChange={onDurationChange}
      />
      <ClockOptions max={max} onDurationChange={onDurationChange} />
    </Container>
  );
};

ConfigurableClockWithOptions.propTypes = {
  duration: PropTypes.number,
  onDurationChange: PropTypes.func.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
};

ConfigurableClockWithOptions.defaultProps = {
  max: null,
  min: null,
  duration: null,
};

export default ConfigurableClockWithOptions;
