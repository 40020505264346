import React from "react";
import StepsProvider from "../components/StepsProvider";
import DriverIdentificationSteps from "./Steps";

const DriversIdentifications = () => {
  return (
    <StepsProvider>
      <DriverIdentificationSteps />
    </StepsProvider>
  );
};

export default DriversIdentifications;
