import { useRef, useState, useMemo } from "react";
import { MAP_CONTAINER } from "../components/locations/Map";
import { usePermitHoldersDetailsContext } from "../Provider";
import useLocations from "./useLocations";

const useLocationsTab = () => {
  const mapController = useRef();
  const [selectedStreet, setSelectedStreet] = useState();
  useLocations();
  const {
    locationsTab: { zones, isLoading },
  } = usePermitHoldersDetailsContext();

  const zonesWithClickEvent = useMemo(
    () =>
      zones.map((zone) => ({
        ...zone,
        items: zone.items.map((street) => ({
          ...street,
          onClick: (streetId) => {
            mapController.current?.selectStreet(streetId);
            setSelectedStreet(streetId);

            document.querySelector(`#${MAP_CONTAINER}`).scrollIntoView({
              behavior: "smooth",
            });
          },
        })),
      })),
    [zones]
  );

  return {
    zones: zonesWithClickEvent,
    selectedStreet,
    mapController,
    setSelectedStreet,
    isLoading,
  };
};

export default useLocationsTab;
