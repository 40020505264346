import InfiniteList from "components/InfiniteList";
import React from "react";
import EmptyUnapprovedList from "../EmptyUnapproved";
import useApprovedList from "../../hooks/useApprovedList";
import { usePermitHoldersListContext } from "../../Provider";
import Card from "./Card";

const NUMBER_OF_LOADING_ELEMENTS = 1;
const CARD_HEIGHT = 105;

const UnapprovedList = () => {
  const {
    unapprovedList: { items, isLoading, onFinishedScroll, hasError },
  } = usePermitHoldersListContext();
  const { shouldDisplayApprovedList } = useApprovedList();

  return (
    <InfiniteList
      hasError={hasError}
      onFinishedScroll={onFinishedScroll}
      loadingItemsNumber={NUMBER_OF_LOADING_ELEMENTS}
      cardHeight={CARD_HEIGHT}
      isLoading={isLoading}
      renderItem={(item) => <Card {...item} />}
      items={items}
      EmptyListComponent={
        <EmptyUnapprovedList justify={shouldDisplayApprovedList ? "center" : undefined} />
      }
      maxHeight="40vh"
    />
  );
};

export default UnapprovedList;
