import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const cornerBorder = 1;
const cornerSideWidth = 40;

const Box = styled.div`
  position: relative;
  padding: 10px;
  width: fit-content;
`;

const TopLeftCorner = styled.span`
  display: block;
  width: ${cornerSideWidth}px;
  height: ${cornerSideWidth}px;
  position: absolute;
  top: 0;
  left: 0;
  border-left: ${cornerBorder}px solid ${({ cornerColor }) => cornerColor};
  border-top: ${cornerBorder}px solid ${({ cornerColor }) => cornerColor};
`;

const TopRightCorner = styled.span`
  display: block;
  width: ${cornerSideWidth}px;
  height: ${cornerSideWidth}px;
  position: absolute;
  top: 0;
  right: 0;
  border-right: ${cornerBorder}px solid ${({ cornerColor }) => cornerColor};
  border-top: ${cornerBorder}px solid ${({ cornerColor }) => cornerColor};
`;

const BottomLeftCorner = styled.span`
  display: block;
  width: ${cornerSideWidth}px;
  height: ${cornerSideWidth}px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-left: ${cornerBorder}px solid ${({ cornerColor }) => cornerColor};
  border-bottom: ${cornerBorder}px solid ${({ cornerColor }) => cornerColor};
`;

const BottomRightCorner = styled.span`
  display: block;
  width: ${cornerSideWidth}px;
  height: ${cornerSideWidth}px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: ${cornerBorder}px solid ${({ cornerColor }) => cornerColor};
  border-right: ${cornerBorder}px solid ${({ cornerColor }) => cornerColor};
`;

const ContentContainer = styled.div`
  padding: 10px;
  display: flex;
`;

const CornerBorderBox = React.forwardRef(({ children, cornerColor, className }, ref) => {
  return (
    <Box ref={ref} className={className}>
      <TopLeftCorner cornerColor={cornerColor} />
      <TopRightCorner cornerColor={cornerColor} />
      <BottomLeftCorner cornerColor={cornerColor} />
      <BottomRightCorner cornerColor={cornerColor} />
      <ContentContainer>{children}</ContentContainer>
    </Box>
  );
});

CornerBorderBox.defaultProps = {
  className: "",
};

CornerBorderBox.propTypes = {
  children: PropTypes.node.isRequired,
  cornerColor: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CornerBorderBox;
