import React from "react";
import PropTypes from "prop-types";
import { paymentMethodsMapping } from "utils/payment";
import PaymentMethodBox from "./PaymentBox";
import PaymentMethodIcon from "./PaymentIcon";

const CreditCardPaymentBox = ({ shortMessage, title, onClick, isLoading }) => {
  return (
    <PaymentMethodBox
      icon={
        <PaymentMethodIcon
          size="large"
          paymentMethodId={paymentMethodsMapping.creditCard.id}
        />
      }
      shortMessage={shortMessage}
      title={title}
      onClick={onClick}
      isLoading={isLoading}
    />
  );
};

CreditCardPaymentBox.defaultProps = {
  shortMessage: undefined,
  isLoading: undefined,
};

CreditCardPaymentBox.propTypes = {
  title: PropTypes.string.isRequired,
  shortMessage: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default CreditCardPaymentBox;
