import { breakpoints } from "utils/breakpoints";

const paddings = {
  default: {
    top: "7vh",
    left: "2.5vw",
    bottom: "30px",
    right: "5vw",
  },
  [breakpoints.md]: {
    top: "70px",
    left: "70px",
    bottom: "20px",
    right: "70px",
  },
  [breakpoints.sm]: {
    top: "20px",
    left: "15px",
    bottom: "15px",
    right: "15px",
  },
};

export const getPadding = (type = "default") =>
  `${paddings[type].top} ${paddings[type].left} ${paddings[type].bottom} ${paddings[type].right}`;
