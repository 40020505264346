/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import { FACEBOOK_SDK_URL } from "../utils/configs";

const useFacebook = () => {
  const [facebookInstance, setFacebookInstance] = useState(null);

  const executeAfterLoad = () => {
    window.fbAsyncInit = () => {
      FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v9.0",
      });

      setFacebookInstance(FB);
    };
  };

  useEffect(() => {
    let facebookScript = null;

    if (typeof FB !== "undefined") {
      setFacebookInstance(FB);
    } else {
      facebookScript = document.createElement("script");
      facebookScript.src = FACEBOOK_SDK_URL;
      facebookScript.defer = true;
      window.document.body.appendChild(facebookScript);
      facebookScript.addEventListener("load", executeAfterLoad);
    }

    return () => {
      if (facebookScript) {
        facebookScript.removeEventListener("load", executeAfterLoad);
      }
    };
  }, []);

  return { facebookInstance };
};

export default useFacebook;
