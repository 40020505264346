import { Heading } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { routesMapping } from "../../utils/routes";
import LeftSideContainer from "./components/PageSplittedTemplate/LeftSideContainer";
import PageSplittedTemplate from "./components/PageSplittedTemplate/Template";
import { pageContexts } from "./configs";

const RegistryConclusion = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <PageSplittedTemplate
      pageContext={pageContexts.registryConclusion}
      title={t("7905") /* Bem vindo ao */}
      backUrl={routesMapping.registry}
      onBackClick={history.goBack}
    >
      <LeftSideContainer>
        <Heading className="mt-30" color="secondary" variant="h7">
          {t("7906") /* Obrigado por te registares como driver! */}
        </Heading>
        <Heading color="secondary" variant="h8">
          {
            t(
              "7907"
            ) /* Para começares a estacionar sem trocos e complicações, confirma o teu registo através do link de ativação que foi enviado para o teu email.
            Se não recebeste nenhuma mensagem, por favor, verifica a caixa de spam. */
          }
        </Heading>
        <Heading className="mt-20" color="secondary" variant="h7">
          {t("10962") /* É tão fácil estacionar com o iParque Driver! */}
        </Heading>
      </LeftSideContainer>
    </PageSplittedTemplate>
  );
};

export default RegistryConclusion;
