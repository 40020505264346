/* eslint-disable no-empty */
import axios from "axios";
import { useEffect, useState } from "react";
import { getFromStorage, saveOnStorage } from "utils/localStorage";
import useLanguage from "./useLanguage";

const localStorageKey = "9934189f-c99e-4de3-a795-8344e953fb87";

const useWarnings = () => {
  const [warnings, setWarnings] = useState([]);
  const language = useLanguage();

  useEffect(() => {
    (async () => {
      try {
        const warningsResponse = await axios(`/locales/${language}/warnings.json`);

        const newWarnings = [];

        warningsResponse.data.forEach((warning, index) => {
          const warningLocalStorageKey = `${localStorageKey}-${warning.id || index}`;

          if (getFromStorage(warningLocalStorageKey)) {
            return;
          }

          const warningCopy = { ...warning, id: index };

          if (warning.button) {
            warningCopy.button = {
              label: warning.button,
              onClick: () => saveOnStorage(warningLocalStorageKey, true),
            };
          }

          newWarnings.push(warningCopy);
        });

        setWarnings(newWarnings);
      } catch {
        setWarnings([]);
      }
    })();
  }, [language]);

  return warnings;
};

export default useWarnings;
