import PermitHoldersFormBody from "components/permitHolders/components/Form/Body";
import FormGridContainer from "components/FormGrid";
import { ValidationSchemaProvider } from "components/ValidationSchemaProvider";
import { Formik } from "formik";
import usePermitHoldersFormValidation from "hooks/usePermitHoldersFormValidation";
import PropTypes from "prop-types";
import React from "react";
import { usePermitHoldersContext } from "../../Provider";
import AdvanceButton from "../AdvanceButton";
import Form from "../Form";
import useForm from "./useForm";

const PersonalDataForm = React.forwardRef(({ onAdvance, readOnly }, ref) => {
  const { entity } = usePermitHoldersContext();
  const { getInitialData } = useForm();
  const { validationSchema } = usePermitHoldersFormValidation();

  return (
    <ValidationSchemaProvider schema={validationSchema}>
      <Formik
        innerRef={ref}
        initialValues={getInitialData()}
        validationSchema={readOnly ? undefined : validationSchema}
        onSubmit={onAdvance}
      >
        {({ values }) => (
          <Form>
            <FormGridContainer id="personal-data-form-container" readOnly={readOnly}>
              <PermitHoldersFormBody
                entityId={entity?.id}
                values={values}
                readOnly={readOnly}
              />
            </FormGridContainer>
            {!readOnly && <AdvanceButton type="submit" />}
          </Form>
        )}
      </Formik>
    </ValidationSchemaProvider>
  );
});

export default PersonalDataForm;

PersonalDataForm.propTypes = {
  onAdvance: PropTypes.func,
  readOnly: PropTypes.bool,
};

PersonalDataForm.defaultProps = {
  onAdvance: () => {},
  readOnly: false,
};
