import React from "react";
import AppInfo from "./appInfo/AppInfo";
import Header from "./Header";
import FullPageContainer from "../components/FullPageContainer";
import ContentContainer from "../components/ContentContainer";

const Head = () => {
  return (
    <FullPageContainer>
      <Header />
      <ContentContainer>
        <AppInfo />
      </ContentContainer>
    </FullPageContainer>
  );
};

export default Head;
