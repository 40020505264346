import ConditionalWrapper from "components/ConditionalWrapper";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as BackIcon } from "../../../../assets/icons/backArrow.svg";
import { AppContext } from "../../../../components/AppProvider";
import Link from "../../../../components/Link";
import { breakpoints, breakpointsMapping } from "../../../../utils/breakpoints";
import { routesMapping } from "../../../../utils/routes";

const BackContainer = styled.div`
  margin-top: 30px;
  margin-left: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }

  @media (max-width: ${breakpoints.sm}) {
    margin-left: 20px;
  }

  .login-back-icon {
    margin-right: 10px;
    height: 12px;
  }
`;

const StyledBackIcon = styled(BackIcon)`
  fill: ${(props) => props.theme.color.dark};
`;

const LargeDevices = [
  breakpointsMapping.lg,
  breakpointsMapping.noBreakpoint,
  breakpointsMapping.xl,
];

const Back = ({ to, onClick }) => {
  const { breakpoint } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <ConditionalWrapper condition={!onClick} wrapper={<Link to={to} />}>
      <BackContainer onClick={onClick}>
        <StyledBackIcon className="login-back-icon" />
        {LargeDevices.includes(breakpoint) && (
          <Text color="secondary" variant="body8">
            {t("9") /* Voltar */}
          </Text>
        )}
      </BackContainer>
    </ConditionalWrapper>
  );
};

Back.defaultProps = {
  to: routesMapping.landing,
  onClick: undefined,
};

Back.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
};

export default Back;
