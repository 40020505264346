import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { breakpoints } from "utils/breakpoints";

const FullPageContainer = React.forwardRef(({ className = "", children }, ref) => {
  return (
    <Container ref={ref} className={className}>
      {children}
    </Container>
  );
});

FullPageContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

FullPageContainer.defaultProps = {
  className: "",
  children: null,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${(props) => props.theme.background.color.primary};
  position: relative;

  @media (min-width: ${breakpoints.sm}) {
    min-height: 100vh;
  }
`;

export default FullPageContainer;
