import Button from "components/buttons/Button";
import FlexBox from "components/FlexBox";
import { Heading } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { formatMoney } from "../../../utils/money";
import { parkingTypesMapping } from "../../../utils/parking";
import AmountDetails from "../components/parkings/AmountDetails";
import ConfigurableClockWithOptions, {
  CLOCK_WITH_OPTIONS_GAP,
} from "../components/parkings/ConfigurableClockWithOptions";
import ParkingEndDate from "../components/parkings/EndDate";
import ParkingDetails from "../components/parkings/ParkingDetails";
import SelectedBenefit from "../components/parkings/SelectedBenefit";
import useChooseDuration from "../hooks/useChooseDuration";
import { chooseDurationDefaultProps, chooseDurationPropTypes } from "./propTypes";

const ParkingDetailsWrapper = styled.div`
  flex: 3;
`;

const SelectedBenefitWrapper = styled.div`
  flex: 2;
  margin: 0 20px;
`;

const TimerContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.color.secondary};
`;

const ParkingChooseDuration = ({
  parking,
  calculateParkingCallback,
  onConfirmRenewalCallback,
  calculatedParking,
  fee,
}) => {
  const { chosenDuration, setChosenDuration } = useChooseDuration(
    calculatedParking,
    calculateParkingCallback
  );
  const { t } = useTranslation();

  return (
    <>
      <FlexBox>
        <ParkingDetailsWrapper>
          <ParkingDetails
            vehicleColor={parking.vehicleColor}
            vehicleBrand={parking.vehicleBrand}
            vehicleLicensePlate={parking.vehicleLicensePlate}
            streetName={parking.streetName}
            explorationName={parking.explorationName}
          />
        </ParkingDetailsWrapper>
        {parking.benefitName && (
          <SelectedBenefitWrapper>
            <SelectedBenefit
              description={parking.benefitName}
              color={parking.benefitColor}
            />
          </SelectedBenefitWrapper>
        )}
        <div>
          <AmountDetails amount={parking.currentAmount || 0} />
          {calculatedParking.isLoading ? (
            <Skeleton height={25} width={124} />
          ) : (
            <StyledHeading variant="h2" align="right">
              {`+${formatMoney(calculatedParking?.amount, { centsFormat: false })}`}
            </StyledHeading>
          )}
        </div>
      </FlexBox>
      <TimerContainer>
        <ConfigurableClockWithOptions
          duration={chosenDuration}
          max={fee.maxDuration}
          onDurationChange={setChosenDuration}
        />
        <FlexBox gap={CLOCK_WITH_OPTIONS_GAP}>
          <FlexBox className="fill" justifyContent="center">
            <ParkingEndDate
              isLoading={calculatedParking?.isLoading}
              className="mt-30 ml-5"
              parkingTypeId={parkingTypesMapping.duration}
              endingDate={calculatedParking.endingDate}
            />
          </FlexBox>
          <div className="fill" />
        </FlexBox>
      </TimerContainer>
      <ButtonContainer>
        <Button
          disabled={calculatedParking.duration === null}
          size="md"
          color="primary"
          onClick={() => onConfirmRenewalCallback(chosenDuration)}
        >
          {t("35") /* Confirmar */}
        </Button>
      </ButtonContainer>
    </>
  );
};

ParkingChooseDuration.propTypes = chooseDurationPropTypes;

ParkingChooseDuration.defaultProps = chooseDurationDefaultProps;

export default ParkingChooseDuration;
