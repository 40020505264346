import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "components/AuthProvider";
import { getExternalPaymentStatus } from "requests/externalPayments";
import { convertMinutesToSeconds, convertSecondsToMilliseconds } from "utils/dateTime";
import { externalPaymentStates, externalPaymentWebhookTypes } from "utils/payment";
import { notificationTypes } from "utils/pushNotifications";
import { useTranslation } from "react-i18next";
import firebaseMessageObservable from "lib/firebase/firebaseMessageObservable";

const pollingTimeout = convertSecondsToMilliseconds(5);
const mbWayDefaultTimeout = convertSecondsToMilliseconds(convertMinutesToSeconds(4));

const defaultPaymentStatus = {
  isConcluded: false,
  error: undefined,
};

const externalPaymentNotificationTypes = [
  notificationTypes.externalBalanceCharge,
  notificationTypes.externalParkingPayment,
  notificationTypes.externalNoticePayment,
  notificationTypes.externalSubscriptionPayment,
];

const externalPaymentErrorStates = [
  externalPaymentStates.cancelled,
  externalPaymentStates.pending,
];

const externalPaymentConcludedStates = [
  externalPaymentStates.confirmed,
  externalPaymentStates.pending,
  externalPaymentStates.cancelled,
];

const useExternalPaymentStatus = () => {
  const { t } = useTranslation();
  const { defaultEntityId } = useAuth();
  const [paymentStatus, setPaymentStatus] = useState(defaultPaymentStatus);
  const [paymentRequestParams, setPaymentRequestParams] = useState();

  const defaultPaymentError = useMemo(
    () => t("10156") /* Ocorreu um erro ao realizar o pagamento */,
    [t]
  );

  const paymentErrors = useMemo(
    () => ({
      [externalPaymentWebhookTypes.paymentCancelled]: t(
        "10111"
      ) /* Pagamento cancelado */,
      [externalPaymentWebhookTypes.paymentExpired]: t("10110") /* Pagamento expirado */,
      [externalPaymentWebhookTypes.paymentMethodError]: t(
        "10111"
      ) /* Pagamento cancelado */,
    }),
    [t]
  );

  const stopWatchingPaymentStatus = useCallback(() => {
    setPaymentRequestParams(undefined);
  }, []);

  const checkPaymentStatus = useCallback(
    async ({ isTimeoutCheck = false } = {}) => {
      const paymentState = await getExternalPaymentStatus(
        defaultEntityId,
        paymentRequestParams.paymentId,
        { itemTypeId: paymentRequestParams.itemTypeId }
      );

      const isConcluded = isTimeoutCheck
        ? true
        : externalPaymentConcludedStates.includes(paymentState);

      const error = (() => {
        if (isTimeoutCheck && paymentState !== externalPaymentStates.confirmed) {
          return t("10110"); /* Pagamento expirado */
        }

        if (externalPaymentErrorStates.includes(paymentState)) {
          return defaultPaymentError;
        }

        return undefined;
      })();

      setPaymentStatus({ isConcluded, error });

      if (paymentState !== externalPaymentStates.pendingConfirmation) {
        stopWatchingPaymentStatus();
      }
    },
    [defaultEntityId, paymentRequestParams, t, stopWatchingPaymentStatus]
  );

  const onMessageHandler = useCallback(
    ({ data }) => {
      if (!data || !externalPaymentNotificationTypes.includes(data.type)) {
        return;
      }

      const webhookTypeId = parseInt(data.webhookTypeId, 10);
      const hasError = webhookTypeId !== externalPaymentWebhookTypes.paymentConfirmed;
      const errorMessage = paymentErrors[webhookTypeId] || defaultPaymentError;

      setPaymentStatus({
        isConcluded: true,
        error: hasError ? errorMessage : undefined,
      });
    },
    [paymentErrors, defaultPaymentError]
  );

  const onVisibilityChangeHandler = useCallback(() => {
    if (document.visibilityState === "visible") {
      checkPaymentStatus();
    }
  }, [checkPaymentStatus]);

  useEffect(() => {
    if (!paymentRequestParams) {
      return undefined;
    }

    // Check payment status after MB Way's default timeout
    const mbWayTimeout = setTimeout(() => {
      checkPaymentStatus({ isTimeoutCheck: true });
    }, mbWayDefaultTimeout);

    // Check payment status when page gains focus
    document.addEventListener("visibilitychange", onVisibilityChangeHandler);

    let pollingInterval;

    // Listen for notification if possible. Otherwise use polling
    (async () => {
      const subscribed = await firebaseMessageObservable.subscribeOnMessage(
        onMessageHandler
      );

      if (subscribed) {
        return;
      }

      pollingInterval = setInterval(() => {
        checkPaymentStatus();
      }, pollingTimeout);
    })();

    return () => {
      setPaymentStatus(defaultPaymentStatus);
      clearTimeout(mbWayTimeout);
      clearInterval(pollingInterval);
      firebaseMessageObservable.unsubscribeOnMessage(onMessageHandler);
      document.removeEventListener("visibilitychange", onVisibilityChangeHandler);
    };
  }, [
    paymentRequestParams,
    checkPaymentStatus,
    onVisibilityChangeHandler,
    onMessageHandler,
  ]);

  return {
    paymentStatus,
    watchPaymentStatus: setPaymentRequestParams,
    stopWatchingPaymentStatus,
  };
};

export default useExternalPaymentStatus;
