import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { tabsIdsMap } from "../../helper";
import { usePermitHoldersDetailsContext } from "../../Provider";
import Alert from "./Alert";
import DocumentsList from "./components/DocumentsList";

const MissingDocumentsWarning = ({ documents }) => {
  const { t } = useTranslation();
  const { tabsRef } = usePermitHoldersDetailsContext();

  const displayDocumentsTab = () => tabsRef.current?.changeTab(tabsIdsMap.documents);

  return (
    <Alert
      variant="warning"
      onClick={displayDocumentsTab}
      onClickTitle={t("10637") /* Ir para tab documentos */}
      title={
        t(
          "10565"
        ) /* Para dares seguimento ao dístico terás de adicionar os seguintes documentos: */
      }
      subtitle={<DocumentsList documents={documents} />}
    />
  );
};

export default MissingDocumentsWarning;

MissingDocumentsWarning.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({ designation: PropTypes.string.isRequired })
  ).isRequired,
};
