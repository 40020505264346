import React from "react";
import { useTranslation } from "react-i18next";
import SmallDevicesContentHeader from "domain/private/components/smallDevices/ContentHeader";
import { useBackofficeThemeContext } from "domain/private/components/ThemeProvider";
import { Text } from "iparque-components";
import useNotices from "../hooks/useNotices";
import NoticeCard from "../smallDevices/Card";
import PaymentMethods from "./PaymentMethods";
import { usePaymentContext } from "./store/Store";
import { getNoticesResume } from "./controller";
import NoticesContainer from "./components/NoticesContainer";

const SmallDevicesNoticesPayment = () => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();
  const { backToList } = useNotices();
  const cornerColor = backofficeTheme.color.danger;
  const { noticesToPay } = usePaymentContext();
  const { ids, amount } = getNoticesResume(noticesToPay);

  return (
    <>
      <SmallDevicesContentHeader
        topTitle={t("8415") /* Ups! Tens infrações? */}
        bottomTitle={t("8416") /* Não te preocupes. Paga aqui! */}
        backCallback={() => backToList({ cornerColor })}
        backArrowColor={cornerColor}
      />
      <Text className="uppercase mt-20 mb-10" variant="body9">
        {t("9295") /* Seleciona o método de pagamento */}
      </Text>
      <NoticesContainer amount={amount}>
        {noticesToPay.map((notice) => (
          <NoticeCard key={notice.id} {...notice} paymentScenario />
        ))}
      </NoticesContainer>
      <div className="mt-30">
        <PaymentMethods noticeIds={ids} amountToPay={amount} />
      </div>
    </>
  );
};

export default SmallDevicesNoticesPayment;
