import Button from "components/buttons/Button";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { ALL_NOTICES } from "../controller";
import useNotices from "../hooks/useNotices";
import { noticePropTypes } from "../propTypes";

const PayAllButton = ({ size, notices }) => {
  const { t } = useTranslation();
  const { pay } = useNotices();

  return (
    <Button onClick={() => pay(ALL_NOTICES, notices)} size={size}>
      {t("8422") /* Pagar Todas */}
    </Button>
  );
};

PayAllButton.defaultProps = {
  size: undefined,
};

PayAllButton.propTypes = {
  size: PropTypes.string,
  notices: PropTypes.arrayOf(PropTypes.shape(noticePropTypes)).isRequired,
};

export default PayAllButton;
