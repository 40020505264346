import { useAuth } from "components/AuthProvider";
import PermitHoldersDataSource from "lib/clients/iParque/dataSources/permitHolders";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getPaymentMethods as getPaymentMethodsRequest } from "requests/payments";
import { convertFromCents } from "utils/money";
import {
  getBalancePaymentMethod,
  paymentConditions,
  processPaymentMethods,
  transactionTypes,
} from "utils/payment";
import { errorMessage } from "utils/userMessages";
import { userTypes } from "utils/users";
import { usePermitHoldersDetailsContext } from "../Provider";
import { actionTypes } from "../store/actions";

const permitHoldersDataSource = new PermitHoldersDataSource();

const useSubscriptionsOperations = () => {
  const { dispatch, id: permitHolderId } = usePermitHoldersDetailsContext();
  const {
    subscriptionsTab: {
      paymentMethods,
      list: { update },
    },
  } = usePermitHoldersDetailsContext();
  const {
    defaultEntityId: entityId,
    userId: driverId,
    updateBalance,
    driverHash,
  } = useAuth();
  const { t } = useTranslation();

  const initializePayment = useCallback(async () => {
    if (paymentMethods.length > 0) {
      return true;
    }

    const paymentMethodsResponse = await getPaymentMethodsRequest(
      entityId,
      transactionTypes.subscriptions,
      {},
      { t }
    );

    if (paymentMethodsResponse.hasError) {
      return false;
    }

    dispatch({
      type: actionTypes.SUBSCRIPTIONS_TAB.SET_PAYMENT_METHODS,
      payload: processPaymentMethods([
        getBalancePaymentMethod(t),
        ...paymentMethodsResponse.items,
      ]),
    });

    return true;
  }, [t, entityId, paymentMethods]);

  const payWithBalance = useCallback(
    async (subscriptionId) => {
      try {
        await permitHoldersDataSource.paySubscriptionWithBalance(
          entityId,
          driverHash,
          subscriptionId
        );

        await Promise.all([updateBalance(), update()]);

        return true;
      } catch (error) {
        errorMessage(error, t("10540") /* Ocorreu um erro ao pagar a subscrição */);

        return false;
      }
    },
    [entityId, driverHash, t, update, updateBalance]
  );

  const generateAtmPayment = useCallback(
    async (subscriptionId, params) => {
      try {
        const response = await permitHoldersDataSource.generateSubscriptionPayment(
          entityId,
          driverHash,
          subscriptionId,
          {
            ...params,
            userTypeId: userTypes.client,
            userId: driverId,
            paymentConditionTypeId: paymentConditions.promptPayment,
          }
        );

        const details = await permitHoldersDataSource.getSubscriptionById(
          entityId,
          driverHash,
          permitHolderId,
          subscriptionId,
          { fillCollections: "reference" }
        );

        return {
          id: response.id,
          bankEntityNumber: details.reference.entityNumber,
          reference: details.reference.reference,
          amount: convertFromCents(details.reference.amount),
          deadline: details.reference.deadline,
        };
      } catch (error) {
        errorMessage(
          error,
          t("10541") /* Ocorreu um erro ao gerar os dados de pagamento da subscrição */
        );

        return null;
      }
    },
    [entityId, driverId, driverHash]
  );

  const generateMbWayPayment = useCallback(
    async (subscriptionId, params) => {
      try {
        const response = await permitHoldersDataSource.generateSubscriptionPayment(
          entityId,
          driverHash,
          subscriptionId,
          {
            ...params,
            paymentConditionTypeId: paymentConditions.promptPayment,
            userTypeId: userTypes.client,
            userId: driverId,
          }
        );

        return response.id;
      } catch (error) {
        errorMessage(
          error,
          t("10542") /* Ocorreu um erro ao iniciar o pagamento da subscrição */
        );

        return null;
      }
    },
    [entityId, t, driverHash]
  );

  return {
    initializePayment,
    payWithBalance,
    generateAtmPayment,
    generateMbWayPayment,
  };
};

export default useSubscriptionsOperations;
