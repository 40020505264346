import Loader from "components/loader/Loader";
import PropTypes from "prop-types";
import React, { useImperativeHandle } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as FrontIcon } from "../../../../assets/icons/front.svg";
import useToggleOpen from "../../../../hooks/useToggleOpen";
import { breakpoints } from "../../../../utils/breakpoints";
import { CARDS_GAP_WITH, OPEN_CARD_ANIMATION_SECONDS } from "./config";

const ARROW_CONTAINER_WIDTH_PX = 40;

const ListCard = React.forwardRef(
  (
    {
      children,
      extraInfo,
      className,
      preserveArrowWidth,
      renderExtraInfoCallback,
      highlighted,
      onCardClick,
      isLoading,
      loaderSize,
      displayArrow,
      extraInfoApproximateHeight,
    },
    ref
  ) => {
    const hasExtraInfo =
      extraInfo !== null || typeof renderExtraInfoCallback === "function";
    const { isOpen, toggleOpen, setIsOpen } = useToggleOpen();
    const isToPreserveArrowWidth = !hasExtraInfo && preserveArrowWidth && displayArrow;

    const extraInfoContent = (() => {
      if (typeof renderExtraInfoCallback === "function") {
        return renderExtraInfoCallback({ isOpen });
      }

      if (extraInfo) {
        return React.cloneElement(extraInfo, { isOpen });
      }

      return null;
    })();

    useImperativeHandle(ref, () => ({ setIsOpen }), [setIsOpen]);

    return (
      <Container
        $isToPreserveArrowWidth={isToPreserveArrowWidth}
        className={className}
        $hasExtraInfo={hasExtraInfo}
        highlighted={highlighted}
        onClick={onCardClick}
        ref={ref}
      >
        {isLoading && (
          <Loader width={loaderSize} height={loaderSize} isLoading={isLoading} />
        )}
        <MainInfoContainer>
          {children}
          {hasExtraInfo && displayArrow && (
            <ArrowContainer>
              <StyledArrowIcon onClick={toggleOpen} $isOpen={isOpen} />
            </ArrowContainer>
          )}
        </MainInfoContainer>
        {hasExtraInfo && (
          <ExtraInfoContainer
            $isOpen={isOpen}
            displayArrow={displayArrow}
            extraInfoApproximateHeight={extraInfoApproximateHeight}
          >
            <ExtraInfoWrapper className="list-card-extra-info-container">
              {extraInfoContent}
            </ExtraInfoWrapper>
          </ExtraInfoContainer>
        )}
      </Container>
    );
  }
);

ListCard.propTypes = {
  children: PropTypes.node.isRequired,
  extraInfo: PropTypes.node,
  renderExtraInfoCallback: PropTypes.func,
  className: PropTypes.string,
  preserveArrowWidth: PropTypes.bool,
  highlighted: PropTypes.bool,
  onCardClick: PropTypes.func,
  isLoading: PropTypes.bool,
  loaderSize: PropTypes.number,
  displayArrow: PropTypes.bool,
  extraInfoApproximateHeight: PropTypes.number,
};

ListCard.defaultProps = {
  extraInfo: null,
  className: null,
  preserveArrowWidth: false,
  renderExtraInfoCallback: null,
  highlighted: false,
  onCardClick: undefined,
  isLoading: false,
  loaderSize: 50,
  displayArrow: true,
  extraInfoApproximateHeight: 1000,
};

export default ListCard;

const Container = styled.div`
  position: relative;
  display: flex;
  padding: 10px 15px;
  background: ${({ theme, highlighted }) =>
    highlighted ? theme.box.highlightedColor : theme.box.color};

  :not(:first-child) {
    margin-top: ${CARDS_GAP_WITH}px;
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};

  ${({ $hasExtraInfo }) =>
    $hasExtraInfo &&
    css`
      flex-direction: column;
    `};

  ${({ $isToPreserveArrowWidth }) =>
    $isToPreserveArrowWidth &&
    css`
      padding-right: ${ARROW_CONTAINER_WIDTH_PX + 25}px;
    `};

  @media (max-width: ${breakpoints.lg}) {
    padding: 10px;
    ${({ $isToPreserveArrowWidth }) =>
      $isToPreserveArrowWidth &&
      css`
        padding-right: ${ARROW_CONTAINER_WIDTH_PX + 20}px;
      `}
  }
`;

const ExtraInfoContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: ${({ displayArrow }) =>
    displayArrow ? `calc(100% - ${ARROW_CONTAINER_WIDTH_PX + 10}px)` : "100%"};
  transition: max-height ${OPEN_CARD_ANIMATION_SECONDS}s ease-in-out;
  max-height: ${({ $isOpen, extraInfoApproximateHeight }) =>
    $isOpen ? `${extraInfoApproximateHeight}px` : 0};
  margin-right: ${({ displayArrow }) =>
    displayArrow ? `${ARROW_CONTAINER_WIDTH_PX}px` : "0"};
`;

const ExtraInfoWrapper = styled.div`
  margin-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
`;

const StyledArrowIcon = styled(FrontIcon)`
  cursor: pointer;
  fill: ${({ theme }) => theme.icon.color};
  transform: ${({ $isOpen }) => ($isOpen ? `rotate(-90deg)` : `rotate(90deg)`)};
  transition: transform ${OPEN_CARD_ANIMATION_SECONDS}s ease;
  height: 20px;

  @media (max-width: ${breakpoints.sm}) {
    height: 15px;
  }
`;

const ArrowContainer = styled.div`
  min-width: ${ARROW_CONTAINER_WIDTH_PX}px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
`;

const MainInfoContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
