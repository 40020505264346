import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Text } from "iparque-components";
import { useHistory } from "react-router-dom";
import ContentHeader from "../components/smallDevices/ContentHeader";
import ParkingDetails from "../components/parkings/smallDevices/ParkingDetails";
import { parkingFinishedDefaultPropTypes, parkingFinishedPropTypes } from "./propTypes";
import SelectedBenefit from "../components/parkings/SelectedBenefit";
import AmountDetails from "../components/parkings/AmountDetails";
import ParkingTimer from "../components/parkings/Timer";
import { formatDateTime } from "../../../utils/dateTime";
import { breakpoints } from "../../../utils/breakpoints";
import BottomButton from "../components/parkings/smallDevices/BottomButton";
import { routesMapping } from "../../../utils/routes";
import { AppContext } from "../../../components/AppProvider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 75vh;
`;

const ParkingDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimerContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BenefitContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BottomDetailsContainer = styled.div`
  margin-top: 2vh;
  display: flex;
  align-items: baseline;
  align-self: center;

  @media (max-width: ${breakpoints.sm}) {
    align-self: auto;
  }
`;

const EndDateContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 30px;
  width: 140px;
`;

const AmountContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  flex-direction: column;
`;

const ParkingFinished = ({
  id,
  vehicleColor,
  streetName,
  explorationName,
  vehicleLicensePlate,
  vehicleBrand,
  benefitName,
  benefitColor,
  amount,
  animationState,
  endingDate,
  isLoading,
  scheduleExtraInfo,
}) => {
  const { t } = useTranslation();
  const { setIsLoading } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    setIsLoading(isLoading);

    return () => {
      setIsLoading(false);
    };
  }, [isLoading]);

  return (
    <>
      <ContentHeader
        topTitle={t("7830") /* Vamos estacionar? */}
        bottomTitle={t("7831") /* É fácil! */}
      />
      <Text className="uppercase mt-20" color="tertiary" variant="body10">
        {t("8124") /* Estacionamento finalizado */}
      </Text>
      <Container>
        <ParkingDetailsWrapper>
          <ParkingDetails
            vehicleColor={vehicleColor}
            vehicleBrand={vehicleBrand}
            vehicleLicensePlate={vehicleLicensePlate}
            streetName={streetName}
            explorationName={explorationName}
            endingDate={endingDate}
            animationState={animationState}
            displayImage
          />
        </ParkingDetailsWrapper>
        <TimerContainer>
          {id && (
            <>
              <ParkingTimer
                parkingId={id}
                endingDate={endingDate}
                scheduleExtraInfo={scheduleExtraInfo}
                size="lg"
              />
              <Text variant="body1" color="tertiary" className="uppercase mt-20">
                {t("8124") /* Estacionamento finalizado */}
              </Text>
            </>
          )}
        </TimerContainer>
        {benefitName ? (
          <BenefitContainer>
            <SelectedBenefit description={benefitName} color={benefitColor} />
          </BenefitContainer>
        ) : null}
        <BottomDetailsContainer>
          <EndDateContainer>
            <Text className="mt-15" variant="body10">
              {t("8459") /* Data de fim */}
            </Text>
            <Text variant="body7">{endingDate ? formatDateTime(endingDate) : "-"}</Text>
          </EndDateContainer>
          <AmountContainer>
            <AmountDetails amount={amount} isPaymentPending={false} />
          </AmountContainer>
        </BottomDetailsContainer>
        <BottomButton
          size="sm"
          onClick={() => {
            history.push(`${routesMapping.backofficeParking}`);
          }}
        >
          {t("4045") /* Novo Estacionamento */}
        </BottomButton>
      </Container>
    </>
  );
};

ParkingFinished.propTypes = parkingFinishedPropTypes;

ParkingFinished.defaultProps = parkingFinishedDefaultPropTypes;

export default ParkingFinished;
