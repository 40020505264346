import FlexBox from "components/FlexBox";
import Text from "components/Text";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import lightTheme from "themes/normal";
import { formatDistance } from "utils/distance";
import { formatMoney } from "utils/money";

const SuggestionCard = ({ className, selected, name, entityLogo, distance, balance }) => {
  const { t } = useTranslation();
  const hasDistance =
    !Number.isNaN(distance) && distance !== undefined && distance !== null;

  return (
    <Container className={className} alignItems="center" selected={selected}>
      <Logo src={entityLogo} alt={name} />
      <FullWidthContainer>
        <StyledText variant="body6" selected={selected}>
          {name}
        </StyledText>
        {hasDistance && (
          <StyledText variant="body11" color="primary" selected={selected}>
            {`${t("9485") /* Distância */}: <${formatDistance(distance)}`}
          </StyledText>
        )}
      </FullWidthContainer>
      <FullWidthContainer>
        <RightAlignedText variant="body10" selected={selected}>
          {t("8395") /* Saldo atual */}
        </RightAlignedText>
        <RightAlignedText variant="body3" color="primary" selected={selected}>
          {formatMoney(balance, { centsFormat: false })}
        </RightAlignedText>
      </FullWidthContainer>
    </Container>
  );
};

SuggestionCard.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.bool,
  name: PropTypes.string.isRequired,
  entityLogo: PropTypes.string.isRequired,
  distance: PropTypes.number,
  balance: PropTypes.number.isRequired,
};

SuggestionCard.defaultProps = {
  className: "",
  selected: false,
  distance: undefined,
};

export default SuggestionCard;

const Container = styled(FlexBox)`
  max-width: 530px;
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.entitySuggestion.card.selectedBackgroundColor
      : theme.entitySuggestion.card.backgroundColor};
  ${({ theme, selected }) =>
    selected
      ? `border: 1px solid ${theme.entitySuggestion.card.selectedBorderColor};`
      : ""}
  margin: 0 auto;
  padding: 10px;
`;

const FullWidthContainer = styled.div`
  flex: 1;
`;

const StyledText = styled(Text)`
  ${({ theme, selected }) =>
    !selected ? `color: ${theme.entitySuggestion.card.disabledFontColor};` : ""}
`;

const RightAlignedText = styled(Text)`
  text-align: right;
  ${({ theme, selected }) =>
    !selected ? `color: ${theme.entitySuggestion.card.disabledFontColor};` : ""}
`;

const Logo = styled.img`
  margin-right: 20px;
  padding: 5px;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  object-fit: contain;
  object-position: left;
  background-color: ${lightTheme.box.entitiesBackgroundColor};
`;
