import { usePermitHoldersListContext } from "../Provider";

const useApprovedList = () => {
  const {
    approvedList: { items, isRequested, hasError },
  } = usePermitHoldersListContext();

  return {
    shouldDisplayApprovedList: (isRequested && items.length > 0) || hasError,
  };
};

export default useApprovedList;
