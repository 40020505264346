import Button from "components/buttons/Button";
import { usePublicPagePopupsContext } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { getAppearFromLeft, getAppearFromRight } from "themes/animations/animations";
import { ReactComponent as DriverLogo } from "../../../assets/images/driverLogo.svg";
import { breakpoints } from "../../../utils/breakpoints";
import { routesMapping } from "../../../utils/routes";
import MaxWidthContainer from "../components/MaxWidthContainer";
import LanguageField from "./LanguageFiled";

const Header = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onPressLogin = () => history.push(routesMapping.login);
  const { paddingTop, animationDuration } = usePublicPagePopupsContext();

  return (
    <Container popupsAnimationDuration={animationDuration} popupsHeight={paddingTop}>
      <MaxWidthContainer>
        <LogoContainer>
          <DriverLogo
            id="logo"
            role="img"
            aria-labelledby={
              t("10648") /* Logo da app para pagamento de estacionamento */
            }
            title={t("10648") /* Logo da app para pagamento de estacionamento */}
          />
        </LogoContainer>
      </MaxWidthContainer>
      <ButtonWrapper
        popupsAnimationDuration={animationDuration}
        popupsHeight={paddingTop}
      >
        <StyledButton id="button" onClick={onPressLogin}>
          {t("61") /* Entrar */}
        </StyledButton>
      </ButtonWrapper>
      <LanguageContainer>
        <LanguageWrapper
          popupsAnimationDuration={animationDuration}
          popupsHeight={paddingTop}
        >
          <LanguageField className="language-select" />
        </LanguageWrapper>
      </LanguageContainer>
    </Container>
  );
};

const Container = styled.div`
  transition: padding ${({ popupsAnimationDuration }) => popupsAnimationDuration}s;
  padding-top: ${({ popupsHeight }) => popupsHeight ?? 0}px;

  #logo {
    animation: ${getAppearFromLeft("-20%")} 0.7s;
    animation-fill-mode: both;
    animation-delay: 0.5s;
  }

  #button {
    animation: ${getAppearFromRight("20%")} 0.7s;
    animation-fill-mode: both;
    animation-delay: 0.5s;
  }
`;

const ButtonWrapper = styled(MaxWidthContainer)`
  position: fixed;
  z-index: 3;
  left: 0;
  right: 0;
  transition: top ${({ popupsAnimationDuration }) => popupsAnimationDuration}s;
  top: ${({ popupsHeight }) => popupsHeight ?? 0}px;
`;

const loginButtonPosition = css`
  position: absolute;
  top: 60px;

  @media (max-width: ${breakpoints.md}) {
    top: 30px;
  }
`;

const StyledButton = styled(Button)`
  ${loginButtonPosition};
  right: 110px;

  @media (max-width: ${breakpoints.sm}) {
    padding: 5px;
    width: 140px;
  }
`;

const LanguageContainer = styled(MaxWidthContainer)`
  ${loginButtonPosition};
  position: absolute;
  right: 0;
  left: 0;
`;

const LanguageWrapper = styled.div`
  transition: top ${({ popupsAnimationDuration }) => popupsAnimationDuration}s;
  top: ${({ popupsHeight }) => popupsHeight ?? 0}px;
  position: absolute;
  right: 20px;

  .language-select {
    margin-top: 7px;
  }

  @media (max-width: ${breakpoints.sm}) {
    right: 5%;
  }
`;

const LogoContainer = styled.div`
  padding: 60px 0 20px 100px;

  @media (max-width: ${breakpoints.lg}) {
    padding-left: 10%;
  }

  @media (max-width: ${breakpoints.md}) {
    padding-top: 30px;
  }

  @media (max-width: ${breakpoints.sm}) {
    padding-left: 5%;
  }
`;

export default Header;
