/* eslint-disable func-names */
import EconomicActivityCodeValidator from "lib/validators/economicActivityCode";
import ZipCodeFactory from "lib/validators/zipCode/factory";
import isLicencePlateValid from "licence-plate-validator";
import { partyTypesWithCompanyFields } from "utils/clients";
import { isFileExtensionAllowed } from "utils/file";
import * as Yup from "yup";
import { PORTUGAL } from "./utils/countries";
import {
  isValidDateFormat,
  maxDateValidation,
  minDateValidation,
} from "./utils/dateTime";
import { isPhoneValid, isTaxNumberValid } from "./utils/validators";

Yup.addMethod(Yup.string, "validDateFormat", function (message) {
  return this.test("validDateFormat", message, function (value) {
    const { path, createError } = this;

    return isValidDateFormat(value) || createError({ path, message });
  });
});

Yup.addMethod(Yup.string, "maxDate", function (message, maxDate) {
  return this.test("maxDate", message, function (value) {
    let maxDateProcessed = maxDate;

    if (typeof maxDate === "object") {
      maxDateProcessed = this.resolve(maxDate);
    }

    const { path, createError } = this;

    return maxDateValidation(value, maxDateProcessed) || createError({ path, message });
  });
});

Yup.addMethod(Yup.string, "minDate", function (message, minDate) {
  return this.test("maxDate", message, function (value) {
    let minDateProcessed = minDate;

    if (typeof minDate === "object") {
      minDateProcessed = this.resolve(minDate);
    }

    const { path, createError } = this;

    return minDateValidation(value, minDateProcessed) || createError({ path, message });
  });
});

Yup.addMethod(Yup.string, "password", function (message, passwordRef) {
  return this.test("password", message, function () {
    const { path, createError } = this;

    return passwordRef.current?.isValid() || createError({ path, message });
  });
});

Yup.addMethod(Yup.mixed, "extraRequired", function (message, extraFields) {
  return this.test("extraRequired", message, function (value) {
    const { path, createError } = this;

    return extraFields.includes(path) && !value ? createError({ path, message }) : true;
  });
});

Yup.addMethod(Yup.mixed, "vat", function (message, countryCodesMapping) {
  return this.test("vat", message, function (value) {
    if (!value) {
      return true;
    }

    const { path, createError } = this;
    const { countryId } = this.parent;

    const countryCode = countryCodesMapping[parseInt(countryId, 10)];

    return isTaxNumberValid(value, countryCode) || createError({ path, message });
  });
});

Yup.addMethod(Yup.mixed, "phone", function (message, country) {
  return this.test("phone", message, function (value) {
    let countryProcessed = country;

    if (typeof country === "object") {
      countryProcessed = this.resolve(country);
    }

    const { path, createError } = this;

    if (parseInt(countryProcessed, 10) !== PORTUGAL || !value) {
      return true;
    }

    return isPhoneValid(value) || createError({ path, message });
  });
});

Yup.addMethod(Yup.string, "licencePlate", function (message, countryCodesMapping) {
  return this.test("licencePlate", message, function (value) {
    const { path, createError } = this;
    const { countryId } = this.parent;

    const countryCode = countryCodesMapping[countryId];

    if (!value || !countryCode) {
      return createError({ path, message });
    }

    return (
      isLicencePlateValid(countryCode, value.toUpperCase()) ||
      createError({ path, message })
    );
  });
});

Yup.addMethod(Yup.string, "zipCode", function (message, countryCodesMapping) {
  return this.test("zipCode", message, function (value) {
    const { path, createError } = this;
    const { countryId } = this.parent;

    const countryCode = countryCodesMapping[countryId];

    if (!value || !countryCode) {
      return true;
    }

    return (
      ZipCodeFactory.build(countryCode).isValid(value) || createError({ path, message })
    );
  });
});

Yup.addMethod(Yup.number, "economicActivityCode", function (message) {
  return this.test("economicActivityCode", message, function (value) {
    const { path, createError } = this;

    return EconomicActivityCodeValidator.isValid(value) || createError({ path, message });
  });
});

Yup.addMethod(Yup.mixed, "fileExtension", function (t, extensions) {
  return this.test("fileExtension", "", function (value) {
    if (!value || extensions.length === 0) {
      return true;
    }

    const { path, createError } = this;
    let error = "";

    const isValid = isFileExtensionAllowed(value.name, {
      allowedFormats: extensions,
    });

    if (!isValid) {
      const extensionsCopy = [...extensions];
      const lastExtension = extensionsCopy.pop();

      error = error.replace("extensions", extensionsCopy);
      error = error.replace("extension", lastExtension);

      if (extensions.length === 1) {
        error = t("10591", {
          extension: lastExtension,
        }); /* Apenas são permitidos ficheiros com o formato {{extension}} */
      } else {
        error = t("10592", {
          extensions: extensionsCopy,
          extension: lastExtension,
        }); /* Apenas são permitidos ficheiros com os formatos {{extensions}} e {{extension}} */
      }
    }

    return isValid || createError({ path, message: error });
  });
});

Yup.addMethod(Yup.mixed, "requiredIfCompany", function (message) {
  return this.test("requiredIfCompany", message, function (value) {
    const { partyTypeId } = this.parent;

    if (!partyTypesWithCompanyFields.includes(partyTypeId)) {
      return true;
    }

    return value !== undefined && value !== "";
  });
});

export default Yup;
