import { useEffect } from "react";

const useOnKeyPress = (keyCode, callback) => {
  useEffect(() => {
    const onKeyDown = (event) => {
      if (event.code === keyCode) {
        callback();
      }
    };

    document.addEventListener("keydown", onKeyDown);

    return () => document.removeEventListener("keydown", onKeyDown);
  }, [callback]);
};

export default useOnKeyPress;
