import React from "react";
import { useTranslation } from "react-i18next";
import ContentBorderBox from "domain/private/components/ContentBorderBox";
import ContentHeader from "domain/private/components/ContentHeader";
import { useBackofficeThemeContext } from "domain/private/components/ThemeProvider";
import useNotices from "../hooks/useNotices";
import NoticeCard from "../smallDevices/Card";
import PaymentMethods from "./PaymentMethods";
import { usePaymentContext } from "./store/Store";
import { getNoticesResume } from "./controller";
import NoticesContainer from "./components/NoticesContainer";

const MediumDevicesNoticesPayment = () => {
  const { t } = useTranslation();
  const { backofficeTheme } = useBackofficeThemeContext();
  const { backToList } = useNotices();
  const cornerColor = backofficeTheme.color.danger;
  const { noticesToPay } = usePaymentContext();
  const { ids, amount } = getNoticesResume(noticesToPay);

  return (
    <>
      <ContentHeader
        topTitle={t("8415") /* Ups! Tens infrações? */}
        bottomTitle={t("8416") /* Não te preocupes. Paga aqui! */}
      />
      <ContentBorderBox
        className="mt-40"
        cornerColor={cornerColor}
        backCallback={() => backToList({ cornerColor })}
        title={t("9295") /* Seleciona o método de pagamento */}
      >
        <NoticesContainer amount={amount}>
          {noticesToPay.map((notice) => (
            <NoticeCard key={notice.id} {...notice} paymentScenario />
          ))}
        </NoticesContainer>
        <div className="mt-30">
          <PaymentMethods noticeIds={ids} amountToPay={amount} />
        </div>
      </ContentBorderBox>
    </>
  );
};

export default MediumDevicesNoticesPayment;
