import { countriesMapping } from "utils/countries";
import DefaultZipCodeValidator from "./default";
import PortugueseZipCodeValidator from "./portuguese";

export default class ZipCodeFactory {
  static build(countryCode) {
    switch (countryCode.toLowerCase()) {
      case countriesMapping.pt:
        return new PortugueseZipCodeValidator();
      default:
        return new DefaultZipCodeValidator();
    }
  }
}
