import Button from "components/buttons/Button";
import FlexBox from "components/FlexBox";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { paymentMethodsMapping } from "utils/payment";
import { paymentBoxSizes } from "./helper";
import PaymentMethodBox from "./PaymentBox";
import PaymentMethodIcon from "./PaymentIcon";

const BalancePaymentBox = ({ amount, handlePay, extraInfo, size }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const cardRef = useRef();

  const localHandlePay = async (event) => {
    event.stopPropagation();

    setIsLoading(true);

    await handlePay();

    setIsLoading(false);
    cardRef.current?.toggleDetails();
  };

  const localExtraInfo = (
    <ExtraInfoContainer>
      {extraInfo && extraInfo}
      <Text className="mt-20" color="primary" variant="body7">
        {t("9310") /* Tens a certeza que pretendes continuar? */}
      </Text>
      <FlexBox justifyContent="center" className="mt-20">
        <Button onClick={localHandlePay}>{t("4537") /* Pagar */}</Button>
      </FlexBox>
    </ExtraInfoContainer>
  );

  return (
    <PaymentMethodBox
      paymentMethodId={paymentMethodsMapping.balance.id}
      amount={amount}
      icon={
        <PaymentMethodIcon
          scenario="paymentMethod"
          size={size}
          paymentMethodId={paymentMethodsMapping.balance.id}
        />
      }
      extraInfo={localExtraInfo}
      title={t("8171") /* Saldo Condutor */}
      size={size}
      isLoading={isLoading}
      ref={cardRef}
    />
  );
};

export default BalancePaymentBox;

BalancePaymentBox.defaultProps = {
  extraInfo: undefined,
  size: paymentBoxSizes.large,
};

BalancePaymentBox.propTypes = {
  amount: PropTypes.number.isRequired,
  handlePay: PropTypes.func.isRequired,
  extraInfo: PropTypes.node,
  size: PropTypes.oneOf(Object.values(paymentBoxSizes)),
};

const ExtraInfoContainer = styled.div`
  width: 100%;
`;
