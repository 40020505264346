import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { useAppContext } from "components/AppProvider";
import { Button, Popup, Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { resendActivationEmail as resendActivationEmailRequest } from "requests/driver";
import styled from "styled-components";
import { allowedBreakpointsToDisplayPopupIcon } from "./helper";

const InactiveAccountPopup = ({ email, onClose, visible }) => {
  const { t } = useTranslation();
  const { breakpoint } = useAppContext();

  const sendActivationEmail = () => {
    resendActivationEmailRequest({ email });

    onClose();
  };

  return (
    <Popup onClose={onClose} visible={visible}>
      <Container>
        <ContentContainer>
          {allowedBreakpointsToDisplayPopupIcon.includes(breakpoint) && (
            <StyledInfoIcon />
          )}
          <div>
            <Text elementType="p" color="secondary" variant="body6">
              {t("10943") /* Ativação */}
            </Text>
            <Text variant="body7" color="secondary" className="mt-10">
              {
                t(
                  "10944"
                ) /* A tua conta encontra-se inativa. Para ativá-la deverás aceder ao teu email e clicar no link de ativação. */
              }
            </Text>
          </div>
        </ContentContainer>
        <ButtonContainer>
          <Text variant="body10">
            <Button
              size="xs"
              variant="link"
              onClick={sendActivationEmail}
              color="secondary"
            >
              {t("5573") /* Enviar novamente */}
            </Button>
          </Text>
          <Button size="xs" onClick={onClose} color="secondary">
            OK
          </Button>
        </ButtonContainer>
      </Container>
    </Popup>
  );
};

InactiveAccountPopup.propTypes = {
  email: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

InactiveAccountPopup.defaultProps = {
  email: "",
};

export default InactiveAccountPopup;

const Container = styled.div`
  margin-top: 10px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  margin: 20px 0 10px;
`;

const ContentContainer = styled.div`
  display: flex;
`;

const StyledInfoIcon = styled(InfoIcon)`
  width: 100px;
  fill: ${({ theme }) => theme.color.dark};
`;
