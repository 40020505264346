import { Heading } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as DriverLogo } from "../../../../assets/images/driverLogo.svg";
import { ReactComponent as IparqueDriver } from "../../../../assets/images/iparqueDriver.svg";
import { breakpoints } from "../../../../utils/breakpoints";
import LeftSideContainer from "./LeftSideContainer";

const Container = styled(LeftSideContainer)`
  margin-bottom: 0; /* Override LeftSideContainer margin-bottom */
  flex: 0;
  padding: 0;
`;

const LogoWrapper = styled.div`
  margin-bottom: 50px;

  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 20px;
  }
`;

const LeftSideHeader = ({ title }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <LogoWrapper>
        <DriverLogo
          role="img"
          aria-labelledby={t("10648") /* Logo da app para pagamento de estacionamento */}
          title={t("10648") /* Logo da app para pagamento de estacionamento */}
        />
      </LogoWrapper>
      <div>
        <Heading color="secondary" variant="h9">
          {title}
        </Heading>
        <IparqueDriver
          className="mt-8"
          role="img"
          aria-labelledby={t("10648") /* Logo da app para pagamento de estacionamento */}
          title={t("10648") /* Logo da app para pagamento de estacionamento */}
        />
      </div>
    </Container>
  );
};

LeftSideHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default LeftSideHeader;
