import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import FileInput from "./FileInput";

const FormFileInput = ({ name, designation, isRequired, allowedFormats, className }) => {
  const [field, meta, helpers] = useField(name);
  const hasError = !!(meta.error && meta.touched);
  const helperText = hasError ? meta.error : "";

  return (
    <FileInput
      name={name}
      designation={designation}
      isRequired={isRequired}
      hasError={hasError}
      helperText={helperText}
      initialValue={field.value}
      setInputValue={helpers.setValue}
      allowedFormats={allowedFormats}
      className={className}
    />
  );
};

FormFileInput.propTypes = {
  name: PropTypes.string.isRequired,
  designation: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  allowedFormats: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

FormFileInput.defaultProps = {
  isRequired: false,
  allowedFormats: [],
  className: "",
};

export default FormFileInput;
