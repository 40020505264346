import RequirementsList from "domain/private/components/requirements/List";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  DRIVER_VEHICLES_NOT_VALIDATED,
  INVALID_DRIVER_VAT_NUMBER,
  REQUIRED_DRIVER_VAT_NUMBER,
  REQUIRED_DRIVER_VEHICLES,
} from "utils/error";
import { routesMapping } from "utils/routes";
import { openEmailForVehicleValidation } from "../controller";

const Requirements = ({ errors, className }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const requirements = (() => {
    const initialRequirements = [];

    for (let index = 0; index < errors.length; index += 1) {
      const error = errors[index];

      if (error === INVALID_DRIVER_VAT_NUMBER || error === REQUIRED_DRIVER_VAT_NUMBER) {
        initialRequirements.push({
          label: t("9673") /* Colocar o NIF no teu perfil */,
          button: {
            label: t("8428") /* Ir para o perfil */,
            action: () => history.push(`${routesMapping.backofficeProfile}`),
          },
        });
      }

      if (error === DRIVER_VEHICLES_NOT_VALIDATED) {
        initialRequirements.push({
          label: `${t("10641") /* Validar o teu veículo. */} ${
            t(
              "10639"
            ) /* Contacta os nossos serviços de apoio de forma a validarmos a(s) tuas(s) matrícula(s). */
          }`,
          button: {
            label: t("2783") /* Validar */,
            action: openEmailForVehicleValidation,
          },
        });
      }

      if (error === REQUIRED_DRIVER_VEHICLES) {
        initialRequirements.push({
          label: t("9693") /* Adicionar o teu veículo */,
          button: {
            label: t("3387") /* Adicionar */,
            action: () => history.push(`${routesMapping.backofficeVehicles}/add`),
          },
        });
      }
    }

    return initialRequirements;
  })();

  return (
    <Container className={className}>
      <RequirementsList
        title={t("10636") /* Para veres as tuas infrações deverás fazer o seguinte: */}
        requirements={requirements}
      />
    </Container>
  );
};

export default Requirements;

Requirements.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

Requirements.defaultProps = {
  className: undefined,
};

const Container = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
