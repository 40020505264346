const buildReducer = (actionsMapping) => {
  const reducer = (state, actions) => {
    if (Array.isArray(actions)) {
      let newState = state;

      actions.forEach((action) => {
        if (!actionsMapping[action.type]) {
          return;
        }

        newState = actionsMapping[action.type](newState, action.payload);
      });

      return newState;
    }

    return actionsMapping[actions.type]
      ? actionsMapping[actions.type](state, actions.payload)
      : state;
  };

  return reducer;
};

export default buildReducer;
