import React from "react";
import Skeleton from "../../../components/Skeleton";
import useAnimationSize from "../hooks/useAnimationSize";

const sizeFactor = 0.8;

const AnimationPlaceholder = () => {
  const { height, width } = useAnimationSize();

  return <Skeleton height={height * sizeFactor} width={width * sizeFactor} circle />;
};

export default AnimationPlaceholder;
