import Button from "components/buttons/Button";
import FormGridContainer, {
  FieldContainer,
  TemplateFieldContainer,
} from "components/FormGrid";
import RegistryConditions from "components/RegistryConditions";
import { ValidationSchemaProvider } from "components/ValidationSchemaProvider";
import { Form, Formik } from "formik";
import useRegistry from "hooks/useRegistry";
import React, { useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { AppContext } from "../../components/AppProvider";
import SelectField from "../../components/formFields/Select";
import TextField from "../../components/formFields/Text";
import PasswordRequirements from "../../components/PasswordRequirements";
import useCountries from "../../hooks/useCountries";
import { PORTUGAL } from "../../utils/countries";
import { getButtonSize } from "../../utils/general";
import { routesMapping } from "../../utils/routes";
import LeftSideContainer from "./components/PageSplittedTemplate/LeftSideContainer";
import PageSplittedTemplate from "./components/PageSplittedTemplate/Template";
import RecaptchaInfo from "./components/RecaptchaInfo";
import { pageContexts } from "./configs";

const Registry = () => {
  const passwordRef = useRef();
  const { t } = useTranslation();
  const { breakpoint } = useContext(AppContext);
  const { countryOptions, countryCodesMapping } = useCountries();
  const { isGoogleRecaptchaLoaded, onSubmit } = useRegistry();

  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required(t("10271") /* O campo é obrigatório */),
        email: Yup.string()
          .required(t("10271") /* O campo é obrigatório */)
          .email(t("7894") /* O campo deverá ser válido */),
        emailConfirmation: Yup.string()
          .required(t("10271") /* O campo é obrigatório */)
          .email(t("7894") /* O campo deverá ser válido */)
          .oneOf(
            [Yup.ref("email"), null],
            t("7895") /* A Confirmação da Email não corresponde com o Email introduzido */
          ),
        password: Yup.string()
          .required(t("10271") /* O campo é obrigatório */)
          .password(
            t("8154") /* O campo deverá preencher os requisitos apresentados abaixo */,
            passwordRef
          ),
        passwordConfirmation: Yup.string()
          .required(t("10271") /* O campo é obrigatório */)
          .oneOf(
            [Yup.ref("password"), null],
            t("7883") /* A confirmação da senha não corresponde com a senha introduzida */
          ),
        countryId: Yup.string().required(t("10271") /* O campo é obrigatório */),
        vat: Yup.string().vat(
          t("9977") /* O campo é inválido para o país selecionado */,
          countryCodesMapping
        ),
        termsConditions: Yup.bool().oneOf(
          [true],
          t("11868") /* Terás de aceitar os Termos de Utilização */
        ),
        privacyPolicy: Yup.bool().oneOf(
          [true],
          t("11869") /* Terás de aceitar a Política de Privacidade */
        ),
      }),
    [t]
  );

  return (
    <PageSplittedTemplate
      pageContext={pageContexts.registry}
      title={t("7892") /* Criar conta */}
      backUrl={routesMapping.login}
    >
      <LeftSideContainer>
        <ValidationSchemaProvider schema={validationSchema}>
          <Formik
            initialValues={{
              name: "",
              email: "",
              emailConfirmation: "",
              password: "",
              passwordConfirmation: "",
              countryId: PORTUGAL,
              vat: "",
              termsConditions: false,
              privacyPolicy: false,
              newslettersNewFeatures: false,
              newslettersMarketCampaigns: false,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleChange, values }) => (
              <Form>
                <FormGridContainer>
                  <FieldContainer fullWidth>
                    <TextField
                      id="name-field"
                      name="name"
                      label={t("2038") /* Nome completo */}
                    />
                  </FieldContainer>
                  <TemplateFieldContainer>
                    <TextField
                      type="email"
                      id="email-field"
                      name="email"
                      label={t("178") /* Email */}
                    />
                  </TemplateFieldContainer>
                  <TemplateFieldContainer>
                    <TextField
                      type="email"
                      id="email-confirmation-field"
                      name="emailConfirmation"
                      label={t("3972") /* Confirmar Email */}
                    />
                  </TemplateFieldContainer>
                  <TemplateFieldContainer>
                    <SelectField
                      id="country-field"
                      name="countryId"
                      options={countryOptions}
                      label={t("2721") /* País */}
                    />
                  </TemplateFieldContainer>
                  <TemplateFieldContainer>
                    <TextField
                      type="text"
                      id="vat-field"
                      name="vat"
                      label={t("4198") /* NIF */}
                      helperText={
                        !values.vat &&
                        t("9988") /* O NIF é necessário para a emissão de faturas */
                      }
                    />
                  </TemplateFieldContainer>
                  <TemplateFieldContainer>
                    <TextField
                      type="password"
                      id="password-field"
                      name="password"
                      onChange={handleChange}
                      label={t("3550") /* Senha */}
                    />
                  </TemplateFieldContainer>
                  <TemplateFieldContainer>
                    <TextField
                      type="password"
                      id="password-confirmation-field"
                      name="passwordConfirmation"
                      label={t("7884") /* Confirmar Senha */}
                    />
                  </TemplateFieldContainer>
                  <FieldContainer className="mt-20" fullWidth>
                    <PasswordRequirements ref={passwordRef} password={values.password} />
                  </FieldContainer>
                  <div className="mt-20" />
                  <RegistryConditions />
                  <FieldContainer className="mt-30" fullWidth>
                    <Button
                      size={getButtonSize(breakpoint)}
                      disabled={!isGoogleRecaptchaLoaded}
                      type="submit"
                      color="quaternary"
                      fullWidth
                    >
                      {t("2556") /* Registar */}
                    </Button>
                  </FieldContainer>
                </FormGridContainer>
                <RecaptchaInfo />
              </Form>
            )}
          </Formik>
        </ValidationSchemaProvider>
      </LeftSideContainer>
    </PageSplittedTemplate>
  );
};

export default Registry;
