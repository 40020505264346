import React, { useCallback, useState } from "react";
import { Heading } from "iparque-components";
import PropTypes from "prop-types";
import styled from "styled-components";
import { paymentTypesMapping } from "utils/payment";
import { convertSecondsToMilliseconds } from "utils/dateTime";
import { breakpoints } from "utils/breakpoints";
import Loader from "components/loader/Loader";
import { themesMapping } from "themes/theme";
import { ReactComponent as MbwayIcon } from "assets/icons/mbway.svg";
import { paymentBoxGeneralStyle } from "./PaymentMethodBox";
import { useBackofficeThemeContext } from "../../components/ThemeProvider";
import MbWayPaymentDetails from "../../components/paymentMethods/MbWayPaymentDetails";

const SLIDE_ANIMATION_TIME = 0.3;

const Container = styled.div`
  ${paymentBoxGeneralStyle}

  border: 2px solid
    ${({ theme, $isNormalTheme }) =>
    $isNormalTheme ? theme.color.danger : theme.parkingPaymentBox.borderColor};
  position: relative;
  flex-direction: column;
  cursor: ${({ isOpen }) => (isOpen ? "unset" : "pointer")};

  ${({ allowHover, theme, $isNormalTheme }) =>
    allowHover &&
    `
      :hover {
        background: ${
          $isNormalTheme ? theme.color.danger : theme.parkingPaymentBox.borderColor
        };

        .payment-box-icon {
          fill: ${theme.box.color};
        }

        .mbway-icon-text {
          fill: ${theme.box.color};
        }

        .mbway-icon-box {
          fill: ${theme.box.color};
        }

        .payment-box-text {
          color: ${theme.box.color};
        }
      }
    `}

  @media (max-width: ${breakpoints.lg}) {
    ${({ centered }) =>
      centered
        ? `
            align-self: center;
            margin: 20px 0;
          `
        : ""}
  }
`;

const GeneralInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledMbwayIcon = styled(MbwayIcon)`
  ${({ $isNormalTheme }) =>
    $isNormalTheme &&
    `
      .mbway-icon-text {
        fill: black;
      }

      .mbway-icon-box {
        fill: #d60510;
      }
    `}
`;

const StyledHeading = styled(Heading)`
  line-height: 25px;
  width: 1px; /* Workaround to break line with only one word */
  ${({ $isNormalTheme, theme }) => $isNormalTheme && `color: ${theme.color.danger}`}
`;

const MbWayPaymentBox = ({
  description,
  longMessage,
  className,
  disableOthersCallback,
  code,
  disabled,
  amount,
  onSuccess,
  onConfirmPayment,
  setAllowBackStepCallback,
  id,
  defaultIsOpen,
  onCancel,
  centered,
  itemTypeId,
}) => {
  const { backofficeTheme, backofficeThemeName } = useBackofficeThemeContext();
  const [isDetailsOpen, setIsDetailsOpen] = useState(defaultIsOpen);
  const [allowHover, setAllowHover] = useState(!isDetailsOpen);
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);

  const isNormalTheme = backofficeThemeName === themesMapping.normal;

  const openDetails = useCallback(() => {
    if (!isDetailsOpen && !isPaymentInProgress) {
      setIsDetailsOpen(true);
      setAllowHover(false);
    }
  }, [isDetailsOpen]);

  const onCloseDetails = useCallback(() => {
    if (onCancel) {
      onCancel();
      return;
    }

    setIsDetailsOpen(false);

    setTimeout(() => {
      setAllowHover(true);
    }, convertSecondsToMilliseconds(SLIDE_ANIMATION_TIME));
  }, []);

  const onPaymentStarted = useCallback(() => {
    setIsPaymentInProgress(true);
    setAllowHover(false);
    disableOthersCallback(code);
    setAllowBackStepCallback(false);
  }, [disableOthersCallback, setAllowBackStepCallback]);

  const onPaymentEnded = useCallback(() => {
    setIsPaymentInProgress(false);
    setAllowBackStepCallback(true);
    disableOthersCallback(null);
  }, [disableOthersCallback, setAllowBackStepCallback]);

  return (
    <Container
      onClick={openDetails}
      $isNormalTheme={isNormalTheme}
      className={className}
      isOpen={isDetailsOpen}
      allowHover={allowHover}
      disabled={disabled}
      centered={centered}
    >
      <Loader width={80} height={80} isLoading={isPaymentInProgress} />
      <GeneralInfoContainer>
        <StyledMbwayIcon
          className="payment-box-icon"
          $isNormalTheme={isNormalTheme}
          fill={backofficeTheme.parkingPaymentBox.iconColor}
        />
        <div className="ml-15">
          <StyledHeading
            $isNormalTheme={isNormalTheme}
            variant="h4"
            className="payment-box-text"
            color="secondary"
          >
            {description}
          </StyledHeading>
        </div>
      </GeneralInfoContainer>
      <MbWayPaymentDetails
        id={id}
        amount={amount}
        itemTypeId={itemTypeId}
        isOpen={isDetailsOpen}
        isPaymentInProgress={isPaymentInProgress}
        slideAnimationTime={SLIDE_ANIMATION_TIME}
        longMessage={longMessage}
        onConfirmPayment={onConfirmPayment}
        onSuccess={onSuccess}
        onPaymentStarted={onPaymentStarted}
        onPaymentEnded={onPaymentEnded}
        onCloseDetails={onCloseDetails}
      />
    </Container>
  );
};

MbWayPaymentBox.propTypes = {
  description: PropTypes.string.isRequired,
  disableOthersCallback: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  onConfirmPayment: PropTypes.func.isRequired,
  setAllowBackStepCallback: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  longMessage: PropTypes.string,
  className: PropTypes.string,
  code: PropTypes.string.isRequired,
  amount: PropTypes.number,
  id: PropTypes.number.isRequired,
  defaultIsOpen: PropTypes.bool,
  centered: PropTypes.bool,
  itemTypeId: PropTypes.oneOf(Object.values(paymentTypesMapping)).isRequired,
};

MbWayPaymentBox.defaultProps = {
  className: "",
  longMessage: "",
  amount: null,
  defaultIsOpen: false,
  onCancel: null,
  centered: false,
  disableOthersCallback: () => {},
};

export default MbWayPaymentBox;
