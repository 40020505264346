import React from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import Skeleton from "../../../../components/Skeleton";
import { useBackofficeThemeContext } from "../ThemeProvider";
import BoxContainer from "./BoxContainer";

const LoadingPaymentBox = () => {
  const { backofficeTheme } = useBackofficeThemeContext();

  return (
    <SkeletonTheme
      color={backofficeTheme.loadingSkeleton.smoothColor}
      highlightColor={backofficeTheme.loadingSkeleton.smoothHighlightColor}
    >
      <BoxContainer disabled size="large">
        <Skeleton height={20} width={250} />
      </BoxContainer>
    </SkeletonTheme>
  );
};

export default LoadingPaymentBox;
