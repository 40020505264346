import React, { useState } from "react";
import GeneralBankTransferencePaymentBox from "domain/private/components/paymentMethods/BankTransferencePaymentBox";
import { useAuth } from "components/AuthProvider";
import { getEntityConfiguration } from "utils/auth";
import { entityConfigurationsMapping } from "utils/entities";
import { generateBalancePayment } from "../controller";
import { paymentBoxPropTypes } from "./propTypes";

const BankTransferencePaymentBox = ({ initialFlowCallback, paymentMethod }) => {
  const { defaultEntityId, authHash } = useAuth();
  const [details, setDetails] = useState();

  const onClick = () => {
    const promise = new Promise((resolve) => {
      (async () => {
        const amount = await initialFlowCallback(paymentMethod);

        if (!amount) {
          resolve(false);
          return;
        }

        resolve(true);

        setDetails({
          isLoading: true,
        });

        const newDetails = await generateBalancePayment(defaultEntityId, authHash, {
          amount,
          paymentMethodId: paymentMethod.id,
        });

        if (!newDetails) {
          setDetails({
            isLoading: false,
          });
          return;
        }

        setDetails({
          amount: newDetails.amount,
          iban: getEntityConfiguration(entityConfigurationsMapping.iban),
          deadline: newDetails.deadline,
          isLoading: false,
        });
      })();
    });

    return promise;
  };

  return (
    <GeneralBankTransferencePaymentBox
      longMessage={paymentMethod.longMessage}
      shortMessage={paymentMethod.shortMessage}
      onClick={onClick}
      title={paymentMethod.title}
      {...details}
    />
  );
};

BankTransferencePaymentBox.propTypes = paymentBoxPropTypes;

export default BankTransferencePaymentBox;
