import { Button, Heading, Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";

const GenericError = ({ title, subtitle, Animation, goBack }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <AnimationContainer>{Animation}</AnimationContainer>
      <InfoContainer flexDirection="column">
        <Heading variant="h3" elementType="h6" color="quintenary">
          STOP!
        </Heading>
        <Heading variant="h9" color="quintenary" className="mt-10">
          {title}
        </Heading>
        <Text variant="body5" color="quintenary" className="mt-10">
          {subtitle}
        </Text>
        {goBack && (
          <div>
            <Button color="quintenary" onClick={goBack} className="mt-60 button">
              {t("9") /* Voltar */}
            </Button>
          </div>
        )}
      </InfoContainer>
    </Container>
  );
};

GenericError.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  Animation: PropTypes.node.isRequired,
  goBack: PropTypes.func,
};

GenericError.defaultProps = {
  goBack: undefined,
};

export default GenericError;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.md}) {
    margin: 0;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const AnimationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  flex: 1;
`;

const InfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.md}) {
    justify-content: center;
  }
`;
