import Button from "components/buttons/Button";
import FlexBox from "components/FlexBox";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import useVehicleTabOperations from "../../hooks/useVehiclesTabOperations";
import { usePermitHoldersDetailsContext } from "../../Provider";

const SaveButton = ({ justifyContent }) => {
  const { isEditable } = usePermitHoldersDetailsContext();
  const { saveVehicles } = useVehicleTabOperations();
  const { t } = useTranslation();

  if (!isEditable) {
    return null;
  }

  return (
    <FlexBox className="mt-40" justifyContent={justifyContent}>
      <StyledButton type="button" onClick={saveVehicles}>
        {t("88") /* Guardar */}
      </StyledButton>
    </FlexBox>
  );
};

export default SaveButton;

SaveButton.propTypes = {
  justifyContent: PropTypes.oneOf(["flexEnd", "center"]),
};

SaveButton.defaultProps = {
  justifyContent: "flexEnd",
};

const StyledButton = styled(Button)`
  @media (max-width: ${breakpoints.lg}) {
    width: 250px;
  }
`;
