import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const HiddenLabel = styled.label`
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  overflow: hidden;
`;

const AppThemeProvider = (props) => {
  const { htmlFor, children } = props;

  return <HiddenLabel htmlFor={htmlFor}>{children}</HiddenLabel>;
};

AppThemeProvider.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AppThemeProvider;
