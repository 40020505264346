import {
  hasErrorCode,
  INVALID_TAX_PAYER_IDENTIFICATION_NUMBER,
  REQUIRED_PHONE,
} from "../../../utils/error";

export const FORM_BUTTON_WIDTH = "160px";

export const fieldsMapping = {
  phone: "phoneNumber",
  vat: "vat",
};

const extraRequiredFields = [
  {
    index: fieldsMapping.phone,
    errorCode: REQUIRED_PHONE,
  },
  {
    index: fieldsMapping.vat,
    errorCode: INVALID_TAX_PAYER_IDENTIFICATION_NUMBER,
  },
];

export const getMissingFields = (response) => {
  const missingFields = [];

  extraRequiredFields.forEach((extraRequiredField) => {
    if (hasErrorCode(response, extraRequiredField.errorCode)) {
      missingFields.push(extraRequiredField.index);
    }
  });

  return missingFields;
};
