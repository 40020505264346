import React from "react";
import Skeleton from "react-loading-skeleton";
import { Heading } from "iparque-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ConfigurableClockWithOptions from "../../components/parkings/smallDevices/ConfigurableClockWithOptions";
import SelectedBenefit from "../../components/parkings/SelectedBenefit";
import AmountDetails from "../../components/parkings/AmountDetails";
import BottomButton from "../../components/parkings/smallDevices/BottomButton";
import { formatMoney } from "../../../../utils/money";
import { chooseDurationPropTypes, parkingRenewDefaultPropTypes } from "../propTypes";
import useChooseDuration from "../../hooks/useChooseDuration";
import ParkingEndDate from "../../components/parkings/EndDate";
import { parkingTypesMapping } from "../../../../utils/parking";

const TimerContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BenefitContainer = styled.div`
  margin-top: 2vh;
  justify-content: center;
  display: flex;
`;

const AmountContainer = styled.div`
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
`;

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.color.secondary};
  margin-top: 5px;
  line-height: 25px;
  align-self: center;
`;

const ParkingChooseDuration = ({
  parking,
  calculateParkingCallback,
  onConfirmRenewalCallback,
  calculatedParking,
  fee,
}) => {
  const { chosenDuration, setChosenDuration } = useChooseDuration(
    calculatedParking,
    calculateParkingCallback
  );
  const { t } = useTranslation();

  return (
    <>
      <TimerContainer>
        <ConfigurableClockWithOptions
          duration={chosenDuration}
          max={fee.maxDuration}
          onDurationChange={setChosenDuration}
        />
        <ParkingEndDate
          isLoading={calculatedParking?.isLoading}
          className="mt-30 ml-5"
          parkingTypeId={parkingTypesMapping.duration}
          endingDate={calculatedParking.endingDate}
        />
      </TimerContainer>
      {parking.benefitName ? (
        <BenefitContainer>
          <SelectedBenefit
            description={parking.benefitName}
            color={parking.benefitColor}
          />
        </BenefitContainer>
      ) : null}
      <AmountContainer>
        <AmountDetails amount={parking.currentAmount || 0} />
        {calculatedParking.isLoading ? (
          <Skeleton height={25} width={124} />
        ) : (
          <StyledHeading variant="h2">
            {`+${formatMoney(calculatedParking?.amount, { centsFormat: false })}`}
          </StyledHeading>
        )}
      </AmountContainer>
      <BottomButton
        onClick={() => onConfirmRenewalCallback(chosenDuration)}
        disabled={calculatedParking.duration === null}
        color="primary"
        size="md"
      >
        {t("35") /* Confirmar */}
      </BottomButton>
    </>
  );
};

ParkingChooseDuration.propTypes = chooseDurationPropTypes;

ParkingChooseDuration.defaultProps = parkingRenewDefaultPropTypes;
export default ParkingChooseDuration;
