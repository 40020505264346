/* eslint-disable class-methods-use-this */
import StatusDataSource from "lib/clients/iParque/dataSources/statusDataSource";
import moment from "moment";
import { removeMilliseconds, serverDateTimeFormat } from "utils/dateTime";

export default class NTPProvider {
  async getDateTime() {
    try {
      const response = await new StatusDataSource().getStatus();

      return moment.utc(
        removeMilliseconds(response.utcDateTime.date),
        serverDateTimeFormat
      );
    } catch {
      return undefined;
    }
  }
}
