import { Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AdvanceButton from "../../components/AdvanceButton";
import Card from "./Card";
import useDocumentsStep from "./useDocumentsStep";

const DocumentsStep = () => {
  const { t } = useTranslation();
  const {
    documents,
    isStepTouched,
    onAdvance,
    onSubmitDocument,
    onRemoveDocument,
    isDocumentValid,
  } = useDocumentsStep();

  return (
    <Container>
      <div>
        {documents.map((doc) => (
          <Card
            key={doc.id}
            onSubmitDocument={onSubmitDocument}
            onRemoveDocument={onRemoveDocument}
            isStepTouched={isStepTouched}
            isValid={isDocumentValid(doc.id)}
            {...doc}
          />
        ))}
        <Text className="mt-30" variant="body7" color="quintenary">
          * {t("10254") /* Documento(s) obrigatório(s) */}
        </Text>
      </div>
      <AdvanceButton type="button" onClick={onAdvance} />
    </Container>
  );
};

export default DocumentsStep;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
