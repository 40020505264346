import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import Icon from "./icon";
import useGoogleMaps from "../../../../hooks/useGoogleMaps";

const MapContainer = styled.div`
  width: 100%;
  height: 100vh;
`;

const MAP_CONTAINER_ID = "cities-map-container";
const MAX_ZOOM = 16;
const DEFAULT_ZOOM = 3;

let markers = [];

const clearMap = () => {
  markers.map((marker) => marker.setMap(null));
  markers = [];
};

const Map = (props) => {
  const { cities, theme } = props;
  let icon = null;
  let bounds = [];

  const { map, google } = useGoogleMaps(MAP_CONTAINER_ID, {
    styles: theme.map,
    streetViewControl: false,
    zoomControl: true,
    maxZoom: MAX_ZOOM,
  });

  if (google) {
    icon = Icon(google);
  }

  const renderOne = (city) => {
    const position = {
      lat: city.latitude ? parseFloat(city.latitude) : 0,
      lng: city.longitude ? parseFloat(city.longitude) : 0,
    };

    // eslint-disable-next-line no-new
    const marker = new google.maps.Marker({
      position,
      map,
      title: city.name,
      icon: icon.getIcon(),
    });

    bounds.extend(position);
    markers.push(marker);
  };

  const renderAll = () => {
    if (!google || !map) {
      return;
    }

    clearMap();

    bounds = new google.maps.LatLngBounds();

    cities.forEach((city) => renderOne(city));

    map.fitBounds(bounds);

    if (!markers.length) {
      map.setZoom(DEFAULT_ZOOM);
    }
  };

  useEffect(() => {
    renderAll();
  }, [cities, google, map]);

  return <MapContainer id={MAP_CONTAINER_ID} />;
};

Map.propTypes = {
  theme: PropTypes.shape({ map: PropTypes.arrayOf(PropTypes.shape()).isRequired })
    .isRequired,
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
};

export default withTheme(Map);
