export default class EconomicActivityCodeValidator {
  static isValid(value) {
    if (value === undefined || value === "") {
      return true;
    }

    return (
      !Number.isNaN(value) &&
      value > 0 &&
      value % 1 === 0 &&
      value.toString().length === 5
    );
  }
}
