import React, { useRef } from "react";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import styled from "styled-components";
import { breakpoints } from "../../../utils/breakpoints";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import usePopupAccessibility from "../../../hooks/usePopupAccessibility";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.popup.overlayColor};
`;

const PopupContainer = styled.div`
  position: fixed;
  padding: 20px;
  background-color: ${(props) => props.theme.popup.backgroundColor};
  z-index: 999;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ width }) => width}px;
  ${({ width }) => (width ? `max-width: ${width}px` : "")};
  box-sizing: border-box;

  @media (max-width: ${breakpoints.md}) {
    width: calc(100vw - 50px);
  }

  @media (max-width: ${breakpoints.sm}) {
    margin: 0;
    width: 100vw;
    top: auto;
    bottom: 0;
    transform: translate(-50%);
  }
`;

const Content = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  padding: 5px 0 10px;
  position: relative;
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.popup.titleColor};
`;

const StyledCloseIcon = styled(CloseIcon)`
  margin-left: auto;
  width: 18px;
  cursor: pointer;
  fill: ${({ theme }) => theme.popup.titleColor};
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.popup.titleColor};
`;

const Popup = ({ children, title, onClose, width }) => {
  const popupRef = useRef(null);

  usePopupAccessibility({ popupRef, onClose });

  return (
    <Container>
      <PopupContainer ref={popupRef} width={width}>
        <Header>
          <StyledText color="primary" className="uppercase" variant="body7">
            {title}
          </StyledText>
          {typeof onClose === "function" && <StyledCloseIcon onClick={onClose} />}
        </Header>
        <Content>{children}</Content>
      </PopupContainer>
    </Container>
  );
};

Popup.defaultProps = {
  onClose: null,
  width: 600,
};

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
  onClose: PropTypes.func,
};

export default Popup;
