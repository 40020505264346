import FlexBox from "components/FlexBox";
import Skeleton from "components/Skeleton";
import VerticalTabs from "components/tabs/verticals/smallDevices/Tabs";
import SmallDevicesContentHeader from "domain/private/components/smallDevices/ContentHeader";
import useToggleVerticalTabs from "hooks/useToggleVerticalTabs";
import { Text } from "iparque-components";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Warnings from "../components/Warnings";
import useBack from "../hooks/useBack";
import useTabs from "../hooks/useTabs";
import { usePermitHoldersDetailsContext } from "../Provider";
import { actionTypes } from "../store/actions";
import TabOperations from "./operations/Tab";
import DocumentsTab from "./tabs/Documents";
import GeneralTab from "./tabs/General";
import HistoryTab from "./tabs/History";
import LocationsTab from "./tabs/Locations";
import SubscriptionsTab from "./tabs/Subscriptions";
import VehiclesTab from "./tabs/Vehicles";
import Title from "./Title";
import TitleSkeleton from "./Title/Skeleton";

const PermitHolderDetailsSmallDevicesPage = () => {
  const { t } = useTranslation();
  const onBack = useBack();
  const {
    initialRequestStatus,
    permit,
    dispatch,
    tabsRef,
  } = usePermitHoldersDetailsContext();
  const {
    displayVerticalTabs,
    handleBackToTabs,
    handleDisplayTabDetails,
  } = useToggleVerticalTabs();
  const tabs = useTabs({
    GeneralTab,
    DocumentsTab,
    VehiclesTab,
    LocationsTab,
    SubscriptionsTab,
    HistoryTab,
  });

  const handleTabBack = useCallback(() => {
    dispatch({ type: actionTypes.SET_IS_EDITABLE, payload: false });
    handleBackToTabs();
  }, []);

  return (
    <FlexBox flexDirection="column" className="fill" justifyContent="spaceBetween">
      <FlexBox flexDirection="column" className="fill">
        <SmallDevicesContentHeader
          topTitle={t("10572") /* Consulta aqui os detalhes do teu dístico */}
          bottomTitle={t("7998") /* Não deixes que nada te escape! */}
          backCallback={displayVerticalTabs ? onBack : handleTabBack}
        />
        {initialRequestStatus.isLoading ? (
          <TitleSkeleton className="mt-30" />
        ) : (
          <Title className="mt-30" />
        )}
        <Warnings className="mt-10 mb-10" />
        {initialRequestStatus.isLoading ? (
          <Skeleton height={12} width={100} className="mt-20" />
        ) : (
          <PermitText variant="body9" className="mt-10 uppercase">
            {permit.type.designation}
          </PermitText>
        )}
        <VerticalTabs
          ref={tabsRef}
          className="mt-20"
          tabs={tabs}
          displayTabs={displayVerticalTabs}
          onTabClick={handleDisplayTabDetails}
        />
      </FlexBox>
      {displayVerticalTabs && <TabOperations className="mt-30" />}
    </FlexBox>
  );
};

export default PermitHolderDetailsSmallDevicesPage;

const PermitText = styled(Text)`
  font-size: 13px;
`;
