import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Text } from "iparque-components";
import { convertDateTimeToReadableDate } from "../../../../utils/dateTime";
import { parkingTypesMapping } from "../../../../utils/parking";
import Skeleton from "../../../../components/Skeleton";

const ParkingEndDate = ({ endingDate, parkingTypeId, className, isLoading }) => {
  const { t } = useTranslation();

  return (
    <>
      {parkingTypeId === parkingTypesMapping.duration && (
        <div className={className}>
          {isLoading ? (
            <Skeleton height={20} width={250} />
          ) : (
            <>
              {endingDate && (
                <Text variant="body4" color="primary">
                  {t("8378") /* Válido até */}&nbsp;
                  <b>{convertDateTimeToReadableDate(endingDate)}</b>
                </Text>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

ParkingEndDate.propTypes = {
  parkingTypeId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  endingDate: PropTypes.string,
  className: PropTypes.string,
};

ParkingEndDate.defaultProps = {
  className: null,
  endingDate: null,
  isLoading: false,
};

export default ParkingEndDate;
