import Button from "components/buttons/Button";
import FlexBox from "components/FlexBox";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoints } from "utils/breakpoints";
import useVehicleTabOperations from "../../hooks/useVehiclesTabOperations";
import { usePermitHoldersDetailsContext } from "../../Provider";
import EmptyListInfo from "./EmptyListInfo";
import ErrorComponent from "./ErrorComponent";

const StatusComponent = () => {
  const {
    isEditable,
    vehiclesTab: { addedVehicles, hasError, isLoading },
  } = usePermitHoldersDetailsContext();
  const { addVehicle } = useVehicleTabOperations();
  const { t } = useTranslation();

  if (hasError) {
    return <ErrorComponent />;
  }

  if (!isLoading && addedVehicles.length === 0) {
    return (
      <FlexBox className="fill" flexDirection="column" justifyContent="spaceBetween">
        <EmptyListInfo />
        {isEditable && (
          <ButtonContainer>
            <Button className="mt-40" onClick={addVehicle}>
              {t("10505") /* Adicionar veículo */}
            </Button>
          </ButtonContainer>
        )}
      </FlexBox>
    );
  }

  return null;
};

export default StatusComponent;

const ButtonContainer = styled.div`
  @media (max-width: ${breakpoints.lg}) {
    margin: 0 auto;
  }
`;
