import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useAppContext } from "components/AppProvider";
import { getEntityCities } from "requests/entities";
import {
  getPermitTypeDocuments,
  getPermitTypes,
  getPermitTypeVehicleRegimes,
} from "requests/permitHolders";
import { getCurrentDateTimeInServerFormat } from "utils/dateTime";
import { permitHoldersOccupationType } from "utils/permitHolders";
import Step from "./components/Step";
import CityStep from "./defaultSteps/City";
import ConfirmationStep from "./defaultSteps/Confirmation";
import DocumentsStep from "./defaultSteps/Documents";
import PermitDetailsStep from "./defaultSteps/PermitDetails";
import PermitTypeStep from "./defaultSteps/PermitType";
import PersonalDataStep from "./defaultSteps/PersonalData";
import VehiclesStep from "./defaultSteps/Vehicles";
import { STEPS } from "./helper";
import { usePermitHoldersContext } from "./Provider";
import { actionTypes } from "./store/actions";
import PermitTypesStepSkeleton from "./defaultSteps/PermitType/StepSkeleton";

const DefaultStepsComponents = {
  [STEPS.cities]: CityStep,
  [STEPS.permitTypes]: PermitTypeStep,
  [STEPS.permitDetails]: PermitDetailsStep,
  [STEPS.personalData]: PersonalDataStep,
  [STEPS.documents]: DocumentsStep,
  [STEPS.vehicles]: VehiclesStep,
  [STEPS.confirmation]: ConfirmationStep,
};

const PermitHoldersSteps = ({
  onSubmissionSuccess,
  onInitialRequestError,
  TemplateComponent,
  StepsComponents,
}) => {
  const {
    dispatch,
    goToStep,
    entity,
    isEntityDataLoading,
    selectedCity,
    selectedPermitType,
  } = usePermitHoldersContext();
  const { setIsLoading } = useAppContext();
  const [isInitialRequestLoading, setIsInitialRequestLoading] = useState(false);

  const onAdvanceVehiclesStep = useCallback(
    () => goToStep(STEPS.confirmation, { onSubmissionSuccess }),
    [goToStep, onSubmissionSuccess]
  );

  const onAdvanceDocumentsStep = useCallback(async () => {
    if (
      selectedPermitType.permitType.occupationTypeId ===
      permitHoldersOccupationType.parkingLot
    ) {
      goToStep(STEPS.confirmation, { onSubmissionSuccess });
    } else {
      setIsLoading(true);

      const response = await getPermitTypeVehicleRegimes(
        entity.id,
        selectedPermitType.permitType.id,
        { noLimit: true }
      );

      setIsLoading(false);

      if (response.hasError) {
        return;
      }

      goToStep(STEPS.vehicles, {
        vehicleRegimes: response.items,
        onAdvance: onAdvanceVehiclesStep,
      });
    }
  }, [selectedPermitType, goToStep, entity, onAdvanceVehiclesStep, onSubmissionSuccess]);

  const onAdvancePersonalData = useCallback(async () => {
    setIsLoading(true);

    const response = await getPermitTypeDocuments(
      entity.id,
      selectedPermitType.permitType.id,
      { noLimit: true }
    );

    setIsLoading(false);

    if (response.hasError) {
      return;
    }

    goToStep(STEPS.documents, {
      documents: response.items,
      onAdvance: onAdvanceDocumentsStep,
    });
  }, [selectedPermitType, onAdvanceDocumentsStep, goToStep, entity]);

  const onAdvancePermitDetails = useCallback(() => {
    goToStep(STEPS.personalData, { onAdvance: onAdvancePersonalData });
  }, [goToStep, onAdvancePersonalData]);

  useEffect(() => {
    if (!entity || selectedCity?.id) {
      return;
    }

    (async () => {
      const citiesResponse = await getEntityCities(entity.id, {
        noLimit: true,
        fillCollections: "attachment",
      });

      if (citiesResponse.hasError) {
        onInitialRequestError();
        setIsInitialRequestLoading(false);
        return;
      }

      if (citiesResponse.items.length === 1) {
        dispatch({
          type: actionTypes.SET_SELECTED_CITY,
          payload: {
            id: citiesResponse.items[0].id,
            name: citiesResponse.items[0].name,
            districtId: citiesResponse.items[0].districtId,
            updatedDateTime: getCurrentDateTimeInServerFormat(),
          },
        });
      } else {
        goToStep(STEPS.cities, { cities: citiesResponse.items });
      }

      setIsInitialRequestLoading(false);
    })();
  }, [entity, selectedCity?.id, onInitialRequestError]);

  useEffect(() => {
    if (!selectedCity?.id) {
      return;
    }

    (async () => {
      setIsLoading(true);

      const permitTypesResponse = await getPermitTypes(entity.id, selectedCity.id, {
        noLimit: true,
        fillCollections: "attachment,paymentPeriodicity,vehicleRegimeType",
        allowPublicRegistration: 1,
      });

      setIsLoading(false);

      if (permitTypesResponse.hasError) {
        return;
      }

      if (permitTypesResponse.items.length === 1) {
        dispatch({
          type: actionTypes.SET_SELECTED_PERMIT_TYPE,
          payload: {
            permitType: permitTypesResponse.items[0],
            updatedDateTime: getCurrentDateTimeInServerFormat(),
          },
        });
        return;
      }

      goToStep(STEPS.permitTypes, {
        permitTypes: permitTypesResponse.items,
      });
    })();
  }, [selectedCity]);

  useEffect(() => {
    if (!selectedPermitType?.permitType) {
      return;
    }

    goToStep(STEPS.permitDetails, {
      ...selectedPermitType.permitType,
      onAdvance: onAdvancePermitDetails,
    });
  }, [selectedPermitType]);

  const Steps = useMemo(
    () =>
      Object.values(STEPS).map((stepIndex) => {
        const StepComponent =
          StepsComponents[stepIndex] || DefaultStepsComponents[stepIndex];

        return (
          <Step key={stepIndex} step={stepIndex}>
            <StepComponent />
          </Step>
        );
      }),
    [StepsComponents]
  );

  if (isInitialRequestLoading || isEntityDataLoading) {
    return (
      <TemplateComponent isLoading>
        <PermitTypesStepSkeleton />
      </TemplateComponent>
    );
  }

  return <TemplateComponent>{Steps}</TemplateComponent>;
};

export default PermitHoldersSteps;

export const permitHolderStepsPropTypes = {
  onSubmissionSuccess: PropTypes.func.isRequired,
  onInitialRequestError: PropTypes.func.isRequired,
  TemplateComponent: PropTypes.func.isRequired,
  StepsComponents: PropTypes.shape({
    [STEPS.cities]: PropTypes.func,
    [STEPS.permitTypes]: PropTypes.func,
    [STEPS.permitDetails]: PropTypes.func,
    [STEPS.personalData]: PropTypes.func,
    [STEPS.documents]: PropTypes.func,
    [STEPS.vehicles]: PropTypes.func,
    [STEPS.confirmation]: PropTypes.func,
  }),
};

PermitHoldersSteps.propTypes = permitHolderStepsPropTypes;

PermitHoldersSteps.defaultProps = {
  StepsComponents: {},
};
