import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ClickableDiv = ({ onClick, children, ...props }) => {
  return (
    <StyledDiv
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
      {...props}
    >
      {children}
    </StyledDiv>
  );
};

export default ClickableDiv;

ClickableDiv.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const StyledDiv = styled.div`
  cursor: pointer;
`;
