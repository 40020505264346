import InfiniteList from "components/InfiniteList";
import React from "react";
import { usePermitHoldersListContext } from "../../Provider";
import Card from "./Card";

const ApprovedList = () => {
  const {
    approvedList: { items, isLoading, onFinishedScroll, hasError },
  } = usePermitHoldersListContext();

  return (
    <InfiniteList
      onFinishedScroll={onFinishedScroll}
      hasError={hasError}
      isLoading={isLoading}
      renderItem={(item) => <Card approved {...item} />}
      items={items}
    />
  );
};

export default ApprovedList;
