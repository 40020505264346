import PropTypes from "prop-types";
import React, { createContext, useContext, useReducer } from "react";
import { getBalancePaymentMethod } from "utils/payment";
import Reducer from "./reducer";

const initialState = {
  paymentMethods: [getBalancePaymentMethod()],
  noticesToPay: [],
};

const PaymentContext = createContext(initialState);

export const usePaymentContext = () => useContext(PaymentContext);

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <PaymentContext.Provider value={{ dispatch, ...state }}>
      {children}
    </PaymentContext.Provider>
  );
};

Store.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Store;
