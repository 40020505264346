import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Error403 from "./components/errors/403";
import PermitHoldersCreation from "./permitHolders/Creation";
import PermitHoldersCreationConfirmation from "./permitHolders/CreationConfirmation";
import DriversIdentifications from "./driversIdentifications";
import PublicEntityProvider from "./Provider";
import { publicEntitiesRoutes } from "./routes";
import PublicEntityThemeProvider from "./ThemeProvider";
import DriversIdentificationsEvidence from "./driversIdentifications/Steps/Evidence";

const PublicEntities = () => {
  const { path } = useRouteMatch();

  return (
    <PublicEntityProvider>
      <PublicEntityThemeProvider>
        <Switch>
          <Route path={`${path}/${publicEntitiesRoutes.permitHoldersCreation}`} exact>
            <PermitHoldersCreation />
          </Route>
          <Route
            path={`${path}/${publicEntitiesRoutes.permitHoldersCreationConfirmation}`}
            exact
          >
            <PermitHoldersCreationConfirmation />
          </Route>
          <Route
            path={`${path}/${publicEntitiesRoutes.driversIdentifications}/:identificationToken?`}
            exact
          >
            <DriversIdentifications />
          </Route>
          <Route
            path={`${path}/${publicEntitiesRoutes.driversIdentifications}/:identificationToken/confirmation`}
            exact
          >
            <DriversIdentificationsEvidence />
          </Route>
          <Route path="*">
            <Error403 />
          </Route>
        </Switch>
      </PublicEntityThemeProvider>
    </PublicEntityProvider>
  );
};

export default PublicEntities;
