import FlexBox from "components/FlexBox";
import SelectField from "components/formFields/Select";
import TextField from "components/formFields/Text";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FieldWrapper from "../../../../components/FieldWrapper";
import { formProps } from "./propTypes";
import useForm from "./useForm";

const Body = ({ values, readOnly }) => {
  const { t } = useTranslation();
  const { countries, genders } = useForm();

  return (
    <div>
      <FieldWrapper>
        <TextField
          className="form-field"
          name="name"
          label={t("4518") /* Nome */}
          readOnly={readOnly}
        />
      </FieldWrapper>
      <StyledFlexBox>
        <FieldWrapper>
          <SelectField
            className="form-field"
            name="genderId"
            options={genders}
            label={t("159") /* Sexo */}
            readOnly={readOnly}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SelectField
            className="form-field"
            name="countryId"
            options={countries}
            label={t("2721") /* País */}
            readOnly={readOnly}
          />
        </FieldWrapper>
      </StyledFlexBox>
      <StyledFlexBox>
        <FieldWrapper>
          <TextField
            className="form-field"
            name="taxpayerIdentificationNumber"
            label={`${t("4198") /* NIF */} ${readOnly ? "" : "*"}`}
            disabled={!readOnly && values.companyTaxpayerIdentificationNumber !== ""}
            readOnly={readOnly}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            className="form-field"
            name="companyTaxpayerIdentificationNumber"
            label={`${t("10238") /* NIPC */} ${readOnly ? "" : "*"}`}
            disabled={!readOnly && values.taxpayerIdentificationNumber !== ""}
            readOnly={readOnly}
          />
        </FieldWrapper>
      </StyledFlexBox>
      <FieldWrapper>
        <TextField
          className="form-field"
          name="address"
          label={t("4520") /* Morada */}
          readOnly={readOnly}
        />
      </FieldWrapper>
      <StyledFlexBox>
        <FieldWrapper>
          <TextField
            className="form-field"
            name="zipCode"
            label={t("4830") /* Código postal */}
            readOnly={readOnly}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            className="form-field"
            name="locality"
            label={t("4338") /* Localidade */}
            readOnly={readOnly}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            className="form-field"
            name="complementaryAddress"
            label={t("156") /* Província */}
            readOnly={readOnly}
          />
        </FieldWrapper>
      </StyledFlexBox>
    </div>
  );
};

Body.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  values: PropTypes.shape(formProps).isRequired,
};

export default Body;

const StyledFlexBox = styled(FlexBox)`
  column-gap: 30px;
  flex-flow: row wrap;
`;
