export const platforms = {
  android: "android",
  ios: "ios",
  windows: "windows",
  default: "default",
};

const getPlatform = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes("android")) {
    return platforms.android;
  }

  if (
    userAgent.includes("iphone") ||
    userAgent.includes("ipad") ||
    userAgent.includes("ipod")
  ) {
    return platforms.ios;
  }

  if (userAgent.includes("windows")) {
    return platforms.windows;
  }

  return platforms.default;
};

export const Platform = { select: (options) => options[getPlatform()] };
