import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Template from "domain/public/entities/components/Template";
import { useStepsContext } from "domain/public/components/StepsProvider";
import { STEPS } from "./helper";
import NoticeData from "./NoticeData";
import Informations from "./Informations";

const DriverIdentificationSteps = () => {
  const { t } = useTranslation();
  const { currentStep, canGoBack, goBack, goToStep } = useStepsContext();

  const stepsTitles = {
    [STEPS.noticeData]: t("10504") /* Dados do aviso */,
    [STEPS.informations]: t("6037") /* Informações */,
  };

  useEffect(() => {
    goToStep(STEPS.informations);
  }, []);

  return (
    <Template
      title={t("10239") /* Identificar o condutor do veículo */}
      subTitle={stepsTitles[currentStep?.index]}
      onBack={canGoBack ? goBack : undefined}
    >
      <Step isVisible={currentStep?.index === STEPS.informations}>
        <Informations />
      </Step>
      <Step isVisible={currentStep?.index === STEPS.noticeData}>
        <NoticeData />
      </Step>
    </Template>
  );
};

export default DriverIdentificationSteps;

const Step = styled.div`
  display: ${({ isVisible }) => (isVisible ? `flex` : `none`)};
  flex-direction: column;
  flex: 1;
  margin-top: 10px;
`;
