/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { TextField as IParqueTextField } from "iparque-components";
import { useField } from "formik";
import { useValidationSchema } from "components/ValidationSchemaProvider";

const TextField = ({ helperText, normalize, ...props }) => {
  const [field, meta] = useField(props);
  const hasError = meta.error && meta.touched;
  const { isFieldRequired } = useValidationSchema();

  return (
    <IParqueTextField
      {...field}
      {...props}
      error={hasError}
      required={isFieldRequired?.(field.name)}
      helperText={hasError ? meta.error : helperText}
      value={normalize ? normalize(field.value) : field.value}
    />
  );
};

export default TextField;

TextField.propTypes = {
  helperText: PropTypes.node,
  normalize: PropTypes.func,
};

TextField.defaultProps = {
  helperText: undefined,
  normalize: undefined,
};
