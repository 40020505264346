import { toast } from "iparque-components";
import PropTypes from "prop-types";
import React, { createContext, useCallback, useContext, useReducer, useRef } from "react";
import { CREATION_CONTEXT } from "./helper";
import { actionTypes } from "./store/actions";
import reducer from "./store/reducer";

const initialState = {
  selectedCity: undefined,
  selectedPermitType: undefined,
  termsAndConditionAcceptance: false,
  personalData: undefined,
  documents: {},
  vehicles: [],
};

const PermitHoldersContext = createContext({
  ...initialState,
  isPrivateContext: undefined,
  creationContext: undefined,
  dispatch: () => {},
  goToStep: () => {},
  goBack: () => {},
  getStepState: () => {},
  isInStack: () => {},
  canGoBack: false,
});

const PermitHoldersProvider = ({
  entity,
  isEntityDataLoading,
  creationContext,
  children,
}) => {
  const stepsStack = useRef([]);
  const [state, dispatch] = useReducer(reducer, initialState);

  const goToStep = useCallback((step, stepState) => {
    toast.dismiss();
    stepsStack.current.push({ index: step, state: stepState });

    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: { index: step, state: stepState },
    });
  }, []);

  const goBack = useCallback(() => {
    stepsStack.current.pop();

    dispatch({
      type: actionTypes.SET_CURRENT_STEP,
      payload: stepsStack.current[stepsStack.current.length - 1],
    });
  }, []);

  const getStepState = useCallback((stepIndex) => {
    return stepsStack.current.find((step) => step.index === stepIndex)?.state;
  }, []);

  const isInStack = useCallback(
    (stepIndex) => !!stepsStack.current.find((step) => step.index === stepIndex),
    []
  );

  return (
    <PermitHoldersContext.Provider
      value={{
        dispatch,
        goToStep,
        goBack,
        getStepState,
        isInStack,
        canGoBack: stepsStack.current.length > 1,
        entity,
        isEntityDataLoading,
        creationContext,
        isPrivateContext: creationContext === CREATION_CONTEXT.private,
        ...state,
      }}
    >
      {children}
    </PermitHoldersContext.Provider>
  );
};

export default PermitHoldersProvider;

export const usePermitHoldersContext = () => {
  return useContext(PermitHoldersContext);
};

export const entityPropTypes = {
  isEntityDataLoading: PropTypes.bool.isRequired,
  creationContext: PropTypes.oneOf(Object.values(CREATION_CONTEXT)).isRequired,
  entity: PropTypes.shape({
    id: PropTypes.number.isRequired,
    logo: PropTypes.string,
    countryId: PropTypes.number.isRequired,
  }),
};

PermitHoldersProvider.propTypes = {
  ...entityPropTypes,
  children: PropTypes.node.isRequired,
};
