import PropTypes from "prop-types";
import { selectedFeeInitialState } from "./controller";

export const streetPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    explorationName: PropTypes.string.isRequired,
    distance: PropTypes.number,
  }).isRequired
);

export const timeUnitsPropTypes = PropTypes.shape({
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
});

export const timePeriodPropTypes = PropTypes.shape({
  start: timeUnitsPropTypes,
  end: timeUnitsPropTypes,
});

export const feePropTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  amount: PropTypes.number,
  paymentDeadline: PropTypes.number,
  beginTime: timeUnitsPropTypes,
  endTime: timeUnitsPropTypes,
  timeTables: PropTypes.arrayOf(
    PropTypes.shape({
      intervals: PropTypes.arrayOf(
        PropTypes.shape({
          feeCalculationTypeId: PropTypes.number,
          amount: PropTypes.number,
          from: PropTypes.number,
          to: PropTypes.number,
        })
      ),
      periods: PropTypes.arrayOf(timePeriodPropTypes),
    })
  ),
  minDuration: PropTypes.number,
  maxDuration: PropTypes.number,
};

export const vehiclePropTypes = {
  licensePlate: PropTypes.string.isRequired,
  colorId: PropTypes.number.isRequired,
  brand: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
};

export const parkingTypePropTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export const benefitPropTypes = {
  id: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
};

export const selectedVehiclePropTypes = {
  id: PropTypes.number,
  licensePlate: PropTypes.string,
  brand: PropTypes.string,
  color: PropTypes.string,
};

export const selectedVehicleDefaultProps = {
  id: null,
  licensePlate: "",
  brand: "",
  color: "",
};

export const selectedStreetPropTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  explorationName: PropTypes.string,
};

const selectedFeePropTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  maxDuration: PropTypes.number,
};

const selectedFeeDefaultProps = selectedFeeInitialState;

export const selectedStreetDefaultProps = {
  id: null,
  name: "",
  explorationName: "",
};

export const selectedBenefitPropTypes = {
  id: PropTypes.number,
  description: PropTypes.string,
  type: PropTypes.number,
  color: PropTypes.string,
};

export const selectedBenefitDefaultProps = {
  id: null,
  description: "",
  type: null,
  color: "",
};

const activeParkingPropTypes = {
  id: PropTypes.number,
  amount: PropTypes.number,
  startingDate: PropTypes.string,
  scheduleExtraInfo: PropTypes.shape({
    isRunning: PropTypes.bool,
    remainingDuration: PropTypes.number,
    nextPause: PropTypes.string,
    nextStart: PropTypes.string,
  }),
};

const activeParkingDefaultProps = {
  id: null,
  amount: 0,
  startingDate: "",
  scheduleExtraInfo: null,
};

export const calculatedParkingPropTypes = {
  amount: PropTypes.number,
  duration: PropTypes.number,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  endingDate: PropTypes.string,
};

export const calculatedParkingDefaultProps = {
  amount: null,
  duration: null,
  isLoading: false,
  error: false,
  endingDate: null,
};

const feesPropTypes = PropTypes.arrayOf(PropTypes.shape(feePropTypes).isRequired);

const vehiclesPropTypes = PropTypes.arrayOf(PropTypes.shape(vehiclePropTypes).isRequired);

const parkingTypesPropTypes = PropTypes.arrayOf(
  PropTypes.shape(parkingTypePropTypes).isRequired
);

const benefitsPropTypes = PropTypes.arrayOf(PropTypes.shape(benefitPropTypes).isRequired);

export const bottomStepsPropTypes = {
  fees: feesPropTypes,
  popupFees: feesPropTypes,
  vehicles: vehiclesPropTypes,
  benefits: benefitsPropTypes,
  onlyParkWithActiveGeographicLocationActive: PropTypes.bool,
  setSelectedStreetCallBack: PropTypes.func.isRequired,
  setCandidateStreetCallback: PropTypes.func.isRequired,
  setSelectedFeeCallBack: PropTypes.func.isRequired,
  setSelectedVehicleCallback: PropTypes.func.isRequired,
  setParkingTypeCallback: PropTypes.func.isRequired,
  setBenefitCallback: PropTypes.func.isRequired,
  selectedFeeId: PropTypes.number,
  selectedParkingTypeId: PropTypes.number,
};

export const bottomStepsDefaultProps = {
  fees: [],
  vehicles: [],
  benefits: [],
  selectedFeeId: null,
  selectedParkingTypeId: null,
  onlyParkWithActiveGeographicLocationActive: false,
};

export const stepsPropTypes = {
  isMapInteractable: PropTypes.bool.isRequired,
  selectedStreet: PropTypes.shape(selectedStreetPropTypes),
  checkParking: PropTypes.func.isRequired,
  startParkingCallback: PropTypes.func.isRequired,
  onChooseDurationCallback: PropTypes.func.isRequired,
  createParkingCallback: PropTypes.func.isRequired,
  calculateParkingCallback: PropTypes.func.isRequired,
  setAllowBackStepCallback: PropTypes.func.isRequired,
  activeParking: PropTypes.shape(activeParkingPropTypes),
  calculatedParking: PropTypes.shape(calculatedParkingPropTypes),
  selectedFee: PropTypes.shape(selectedFeePropTypes),
  maxDuration: PropTypes.number,
  feeName: PropTypes.string,
};

export const stepsDefaultProps = {
  selectedStreet: selectedStreetDefaultProps,
  activeParking: activeParkingDefaultProps,
  calculatedParking: calculatedParkingDefaultProps,
  selectedFee: selectedFeeDefaultProps,
  maxDuration: null,
  feeName: "",
};

export const candidateStreetPropTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  hasFees: PropTypes.bool,
  feePeriods: PropTypes.string,
  hasStreetHoliday: PropTypes.bool,
  isLoadingInfo: PropTypes.bool,
};

export const candidateStreetsDefaultProps = {
  id: null,
  hasFees: null,
  feePeriods: "",
  hasStreetHoliday: true,
  isLoadingInfo: false,
};

export const parkingPropTypes = {
  onlyParkWithActiveGeographicLocationActive: PropTypes.bool,
  streets: streetPropTypes,
  onBack: PropTypes.func,
  step: PropTypes.string.isRequired,
  candidateStreet: PropTypes.shape(candidateStreetPropTypes),
  selectedVehicle: PropTypes.shape(selectedVehiclePropTypes).isRequired,
  selectedBenefit: PropTypes.shape(selectedBenefitPropTypes).isRequired,
  bottomStepsProps: PropTypes.shape(bottomStepsPropTypes),
  stepsProps: PropTypes.shape(stepsPropTypes),
};

export const parkingDefaultProps = {
  onlyParkWithActiveGeographicLocationActive: false,
  selectedStreetId: null,
  selectedStreet: { id: null, hasFees: null },
  onBack: null,
  streets: [],
  candidateStreet: candidateStreetsDefaultProps,
  selectedVehicle: selectedVehicleDefaultProps,
  selectedBenefit: selectedStreetDefaultProps,
  bottomStepsProps: bottomStepsDefaultProps,
  stepsProps: stepsDefaultProps,
};

export const startAndStopPropTypes = {
  selectedVehicle: PropTypes.shape(selectedVehiclePropTypes),
  selectedStreet: PropTypes.shape(selectedStreetPropTypes),
  selectedBenefit: PropTypes.shape(selectedBenefitPropTypes),
  activeParking: PropTypes.shape(activeParkingPropTypes),
  startParkingCallback: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
};

export const startAndStopDefaultProps = {
  selectedVehicle: selectedVehicleDefaultProps,
  selectedStreet: selectedStreetDefaultProps,
  selectedBenefit: selectedBenefitDefaultProps,
  activeParking: activeParkingDefaultProps,
};

export const streetListProps = {
  onlyParkWithActiveGeographicLocationActive: PropTypes.bool,
  streets: streetPropTypes,
  popupFees: PropTypes.arrayOf(PropTypes.shape(feePropTypes)),
  selectedStreet: PropTypes.shape(candidateStreetPropTypes),
  setSelectedStreetCallBack: PropTypes.func.isRequired,
  setCandidateStreetCallback: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export const streetListDefaultProps = {
  streets: [],
  popupFees: [],
  selectedStreet: candidateStreetsDefaultProps,
};

export const feesListProps = {
  fees: feesPropTypes,
  setSelectedFeeCallBack: PropTypes.func.isRequired,
  selectedFeeId: PropTypes.number,
};

export const feesListDefaultProps = {
  fees: [],
  selectedFeeId: null,
};

export const vehiclesListProps = {
  vehicles: vehiclesPropTypes,
  setSelectedVehicleCallback: PropTypes.func.isRequired,
  selectedVehicle: PropTypes.shape(selectedVehiclePropTypes),
};

export const vehiclesListDefaultProps = {
  vehicles: [],
  selectedVehicle: selectedVehicleDefaultProps,
};

export const parkingTypesListProps = {
  parkingTypes: parkingTypesPropTypes,
  setParkingTypeCallback: PropTypes.func.isRequired,
  selectedParkingTypeId: PropTypes.number,
};

export const parkingTypesListDefaultProps = {
  parkingTypes: [],
  selectedParkingTypeId: null,
};

export const benefitsListProps = {
  benefits: benefitsPropTypes,
  setBenefitCallback: PropTypes.func.isRequired,
  selectedBenefitId: PropTypes.number,
};

export const benefitsListDefaultProps = {
  benefits: [],
  selectedBenefitId: null,
};

export const durationPropTypes = {
  selectedVehicle: PropTypes.shape(selectedVehiclePropTypes),
  selectedStreet: PropTypes.shape(selectedStreetPropTypes),
  selectedBenefit: PropTypes.shape(selectedBenefitPropTypes),
  activeParking: PropTypes.shape(activeParkingPropTypes),
  calculatedParking: PropTypes.shape(calculatedParkingPropTypes),
  selectedFee: PropTypes.shape(selectedFeePropTypes),
  step: PropTypes.string.isRequired,
  checkParking: PropTypes.func.isRequired,
  calculateParkingCallback: PropTypes.func.isRequired,
  createParkingCallback: PropTypes.func.isRequired,
  onChooseDurationCallback: PropTypes.func.isRequired,
  setAllowBackStepCallback: PropTypes.func.isRequired,
};

export const durationDefaultProps = {
  selectedVehicle: selectedVehicleDefaultProps,
  selectedStreet: selectedStreetDefaultProps,
  selectedBenefit: selectedBenefitDefaultProps,
  activeParking: activeParkingDefaultProps,
  calculatedParking: calculatedParkingDefaultProps,
  selectedFee: selectedFeeDefaultProps,
};

export const detailsPropTypes = {
  selectedVehicle: PropTypes.shape(selectedVehiclePropTypes),
  selectedStreet: PropTypes.shape(selectedStreetPropTypes),
  selectedBenefit: PropTypes.shape(selectedBenefitPropTypes),
  activeParking: PropTypes.shape(activeParkingPropTypes),
  selectedFee: PropTypes.shape(selectedFeePropTypes),
  parkingTypeId: PropTypes.number.isRequired,
};

export const detailsDefaultProps = {
  selectedVehicle: selectedVehicleDefaultProps,
  selectedStreet: selectedStreetDefaultProps,
  selectedBenefit: selectedBenefitDefaultProps,
  activeParking: activeParkingDefaultProps,
  selectedFee: selectedFeeDefaultProps,
};

export const longFeeStartPropTypes = {
  calculateParkingCallback: PropTypes.func.isRequired,
  onChooseDurationCallback: PropTypes.func.isRequired,
  selectedBenefit: PropTypes.shape(selectedBenefitPropTypes),
  selectedVehicle: PropTypes.shape(selectedVehiclePropTypes),
  calculatedParking: PropTypes.shape(calculatedParkingPropTypes),
  selectedStreet: PropTypes.shape(selectedStreetPropTypes),
  feeName: PropTypes.string,
};

export const longFeeStartDefaultProps = {
  selectedVehicle: selectedVehicleDefaultProps,
  selectedStreet: selectedStreetDefaultProps,
  selectedBenefit: selectedBenefitDefaultProps,
  calculatedParking: calculatedParkingDefaultProps,
  feeName: "",
};
