import { straightLineDistance } from "utils/geolocation";

const sortByDistance = (cities) => {
  const citiesWithDistance = [];
  const citiesWithoutDistance = [];

  cities.forEach((city) => {
    if (city.distance !== undefined) {
      citiesWithDistance.push(city);
    } else {
      citiesWithoutDistance.push(city);
    }
  });

  return [
    ...citiesWithDistance.sort((cityA, cityB) => cityA.distance - cityB.distance),
    ...citiesWithoutDistance,
  ];
};

export const formatCitiesWithDistances = (cities, currentPosition) => {
  if (!currentPosition || !cities) {
    return cities;
  }

  const processedCities = cities?.map((city) => {
    const { latitude, longitude, explorationArea } = city;
    let positions = explorationArea;
    let distances;

    if (!latitude || !explorationArea) {
      return city;
    }

    if (latitude) {
      positions = [{ latitude, longitude }, ...positions];
      distances = positions.map((position) =>
        straightLineDistance(position, currentPosition)
      );
    }

    return {
      ...city,
      distance: distances ? Math.min(...distances) : undefined,
    };
  });

  return sortByDistance(processedCities);
};
