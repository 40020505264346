import PropTypes from "prop-types";
import React, { createContext, useContext, useMemo, useReducer } from "react";
import reducer from "./store/reducer";

const initialState = {
  requirements: {
    hasVehicles: undefined,
  },
  approvedList: {
    items: [],
    isLoading: true,
    isRequested: false,
    hasError: false,
    onFinishedScroll: undefined,
  },
  unapprovedList: {
    items: [],
    isLoading: true,
    isRequested: false,
    hasError: false,
    onFinishedScroll: undefined,
  },
};

const PermitHoldersListContext = createContext({ ...initialState });

const PermitHoldersListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const values = useMemo(() => ({ ...state, dispatch }), [state, dispatch]);

  return (
    <PermitHoldersListContext.Provider value={values}>
      {children}
    </PermitHoldersListContext.Provider>
  );
};

export default PermitHoldersListProvider;

export const usePermitHoldersListContext = () => {
  return useContext(PermitHoldersListContext);
};

PermitHoldersListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
