import Button from "components/buttons/Button";
import { Divider, Text } from "iparque-components";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ReactComponent as AppAdvertisement } from "../../../assets/images/app-advertisement.svg";
import { ReactComponent as Logo } from "../../../assets/images/driverLogo.svg";
import { DRIVER_APP_ANDROID_URL, DRIVER_APP_APPLE_URL } from "../../../utils/configs";
import { languageWithoutRegion } from "../../../utils/general";
import Logout from "../components/Logout";
import MenuLinks from "./Links";
import Profile from "./Profile";

const MENU_CONTAINER_HORIZONTAL_PADDING = "25";
const APP_CONTAINER_HORIZONTAL_PADDING = "15";

export const MENU_WIDTH = 220;

const Container = styled.div`
  background-color: ${({ theme }) => theme.background.color.menu};
  height: 100vh;
  position: fixed;
  display: flex;
  width: ${MENU_WIDTH}px;
`;

const Wrapper = styled.div`
  padding: 30px ${MENU_CONTAINER_HORIZONTAL_PADDING}px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const StyledLogo = styled(Logo)`
  height: 60px;
  width: 60px;
  min-height: 60px;
  min-width: 60px;
  fill: ${({ theme }) => theme.background.color.primary};
  margin-bottom: 10px;
`;

const AppContainer = styled.div`
  cursor: pointer;
  height: 200px;
  margin-bottom: 50px;
  padding: 15px ${APP_CONTAINER_HORIZONTAL_PADDING}px;
  border-radius: 15px;
  background-color: ${({ theme }) => `${theme.background.color.secondary}30`};
  transition: background-color 0.25s;

  .app-text {
    line-height: 20px;
  }

  .app-button {
    line-height: 20px;
  }

  :hover {
    background-color: ${({ theme }) => `${theme.background.color.secondary}60`};
  }

  @media (max-height: 1000px) {
    display: none;
  }
`;

const AppButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 5px 0 10px;
`;

const StoreButtonsContainer = styled.div`
  margin-top: 10px;
  width: ${(props) => `${props.storeButtonsContainerWidth}px`};

  svg {
    width: 100%;
  }
`;

const DividerText = styled(Text)`
  opacity: 0.5;
`;

function Menu() {
  const { t, i18n } = useTranslation();

  const [displayAppStoreButtons, setDisplayAppStoreButtons] = useState(false);
  const [GoogleStore, setGoogleStore] = useState(null);
  const [AppleStore, setAppleStore] = useState(null);
  const [storeButtonsContainerWidth, setStoreButtonsContainerWidth] = useState(null);

  const language = languageWithoutRegion(i18n.language);

  const wrapperRef = useRef(null);

  useEffect(() => {
    setStoreButtonsContainerWidth(
      wrapperRef.current.offsetWidth -
        MENU_CONTAINER_HORIZONTAL_PADDING * 2 -
        APP_CONTAINER_HORIZONTAL_PADDING * 2
    ); // Remove div paddings
  }, [wrapperRef?.current?.offsetWidth]);

  useEffect(() => {
    let isMounted = true;

    const importGoogleStoreComponent = async () => {
      const googleStoreImport = await import(
        `../../../components/images/${language}/GoogleStore`
      );

      if (isMounted) {
        setGoogleStore(googleStoreImport.default);
      }
    };

    const importAppleStoreComponent = async () => {
      const appleStoreImport = await import(
        `../../../components/images/${language}/AppleStore`
      );

      if (isMounted) {
        setAppleStore(appleStoreImport.default);
      }
    };

    importGoogleStoreComponent();
    importAppleStoreComponent();

    return () => {
      isMounted = false;
    };
  }, [language]);

  return (
    <Container>
      <Wrapper ref={wrapperRef}>
        <StyledLogo />
        <Divider />
        <div>
          <DividerText color="secondary" variant="body5">
            {t("4849") /* Perfil */}
          </DividerText>
          <Profile />
        </div>
        <Divider />
        <DividerText color="secondary" variant="body5">
          {t("7936") /* Gestão */}
        </DividerText>
        <MenuLinks />
        <AppContainer onClick={() => setDisplayAppStoreButtons(!displayAppStoreButtons)}>
          <Text className="app-text" color="secondary" variant="body1">
            {t("7938") /* Instale */}
          </Text>
          <Text className="app-text" color="secondary" variant="body1">
            {t("7939") /* a app */}
          </Text>
          <Text className="app-text" color="tertiary" variant="body1">
            {t("7940") /* iParque driver */}
          </Text>
          {!displayAppStoreButtons ? (
            <>
              <AppButtonWrapper className="full-width">
                <Button className="app-button" size="xs">
                  {t("7941") /* Aqui */}
                </Button>
              </AppButtonWrapper>
              <AppAdvertisement width="80px" height="100px" />
            </>
          ) : (
            <StoreButtonsContainer
              storeButtonsContainerWidth={storeButtonsContainerWidth}
            >
              <a
                target="blank"
                aria-label="Google Play"
                href={DRIVER_APP_ANDROID_URL}
                rel="noopener"
              >
                {GoogleStore}
              </a>
              <a
                target="blank"
                aria-label="App Store"
                href={DRIVER_APP_APPLE_URL}
                rel="noopener"
              >
                {AppleStore}
              </a>
            </StoreButtonsContainer>
          )}
        </AppContainer>
        <Logout />
      </Wrapper>
    </Container>
  );
}

export default Menu;
