import React from "react";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import InputFileButton from "components/InputFileButton";
import { Link, Text } from "iparque-components";
import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";
import { breakpoints } from "utils/breakpoints";

const FileInput = ({
  designation,
  initialValue,
  isRequired,
  hasError,
  helperText,
  setInputValue,
  allowedFormats,
  className,
}) => {
  const theme = useTheme();

  return (
    <div className={className}>
      <Container hasError={hasError}>
        {initialValue ? (
          <FilledInputContainer>
            <div>
              <Text variant="body1" color="quintenary">
                {designation}
                {isRequired ? " *" : ""}
              </Text>
              <Text variant="body6" color="quintenary">
                <Link
                  underline="always"
                  target="_blank"
                  href={URL.createObjectURL(initialValue)}
                >
                  {initialValue.name}
                </Link>
              </Text>
            </div>
            <CloseIcon
              width={18}
              cursor="pointer"
              fill={theme.color.danger}
              onClick={() => setInputValue(null)}
            />
          </FilledInputContainer>
        ) : (
          <EmptyInputContainer>
            <Text variant="body1" color="quintenary">
              {designation}
              {isRequired ? " *" : ""}
            </Text>
            <InputFileButton
              className="input-file-button"
              name="file"
              onChange={(fileData) => setInputValue(fileData)}
              allowedFormats={allowedFormats}
            />
          </EmptyInputContainer>
        )}
      </Container>
      <HelperText>{hasError ? helperText : ""}</HelperText>
    </div>
  );
};

FileInput.propTypes = {
  designation: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  hasError: PropTypes.bool,
  helperText: PropTypes.string,
  setInputValue: PropTypes.func,
  allowedFormats: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  initialValue: PropTypes.shape({ name: PropTypes.string }),
};

FileInput.defaultProps = {
  hasError: false,
  helperText: "",
  setInputValue: () => {},
  allowedFormats: [],
  className: "",
  initialValue: null,
};

export default FileInput;

const Container = styled.div`
  border: 2px solid
    ${({ theme, hasError }) =>
      hasError ? theme.color.danger : theme.card.outlined.primary.borderColor};
  padding: 15px 20px;

  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;

const FilledInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmptyInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    align-items: flex-start;

    .input-file-button {
      margin-top: 20px;
      margin-left: auto;
    }
  }
`;

const HelperText = styled.span`
  color: ${({ theme }) => theme.color.danger};
  font-family: ${({ theme }) => theme.typography.font.primary};
  font-size: 0.66em;
`;
