import GenericListCard from "domain/private/components/cardList/Card";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import useDetails from "../hooks/useDetails";

const ListCard = ({ children, approved, id, ...other }) => {
  const { displayDetails } = useDetails();

  const onCardClick = () => displayDetails(id);

  return (
    <StyledListCard
      approved={approved}
      highlighted={!approved}
      onCardClick={onCardClick}
      {...other}
    >
      {children}
    </StyledListCard>
  );
};

export default ListCard;

ListCard.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.node.isRequired,
  approved: PropTypes.bool,
};

ListCard.defaultProps = {
  approved: false,
};

const StyledListCard = styled(GenericListCard)`
  ${({ approved, theme }) =>
    approved
      ? css`
          background-color: ${theme.permitHolders.approvedCardBackground};
        `
      : ""}
`;
