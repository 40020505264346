const animation = {
  v: "5.5.7",
  meta: { g: "LottieFiles AE 0.1.20", a: "", k: "", d: "", tc: "" },
  fr: 60,
  ip: 0,
  op: 180,
  w: 280,
  h: 280,
  nm: "stop_light",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Path_81354-2",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [238.35, 50.4, 0], ix: 2 },
            a: { a: 0, k: [3.6, 3.65, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.3, 0],
                        [0, 0.3],
                        [0, 0],
                        [0, 0],
                        [0, 0.3],
                        [0.3, 0],
                        [0, 0],
                        [0, 0],
                        [0.3, 0],
                        [0, -0.3],
                        [0, 0],
                        [0, 0],
                        [0, -0.3],
                        [-0.2, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0.3],
                        [0.3, 0],
                        [0, 0],
                        [0, 0],
                        [0.3, 0],
                        [0, -0.3],
                        [0, 0],
                        [0, 0],
                        [0, -0.3],
                        [-0.3, 0],
                        [0, 0],
                        [0, 0],
                        [-0.3, 0],
                        [0, 0.4],
                        [0, 0],
                      ],
                      v: [
                        [0.5, 4.2],
                        [3.1, 4.2],
                        [3.1, 6.8],
                        [3.6, 7.3],
                        [4.1, 6.8],
                        [4.1, 4.2],
                        [6.7, 4.2],
                        [7.2, 3.7],
                        [6.7, 3.2],
                        [4.2, 3.2],
                        [4.2, 0.5],
                        [3.7, 0],
                        [3.2, 0.5],
                        [3.2, 3.1],
                        [0.5, 3.1],
                        [0, 3.6],
                        [0.5, 4.2],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.023529411765, 0.317647058824, 0.945098039216, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81354-2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 3600,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Ellipse_315",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [238.608, 51.006, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.192156862745, 0.835294117647, 0.419607843137, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse_315",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Path_81353",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [226.707, 60.456, 0], ix: 2 },
            a: { a: 0, k: [8.7, 11.65, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.5, 1.1],
                        [-0.8, 0.7],
                        [-1, 0.4],
                        [-1.1, 0],
                        [-1.1, -0.5],
                        [-0.7, -1],
                        [0, 0],
                        [0.1, -0.1],
                        [0.2, 0],
                        [0.1, 0.1],
                        [0.1, 0.1],
                        [0.2, 0.1],
                        [0.2, 0.1],
                        [0.3, 0.1],
                        [0.4, 0],
                        [0.8, -0.9],
                        [0.2, -1.4],
                        [0, 0],
                        [0, 0],
                        [0.2, -0.2],
                        [0.3, 0],
                        [0, 0],
                        [0, -0.1],
                        [0, 0],
                        [0, -0.2],
                        [0, 0],
                        [0, 0],
                        [0.2, -0.2],
                        [0.3, 0],
                        [0, 0],
                        [-1, -1.1],
                        [-1.2, 0.1],
                        [-0.6, 0.2],
                        [-0.3, 0.3],
                        [-0.2, 0.2],
                        [-0.2, 0],
                        [-0.1, -0.1],
                        [-0.1, -0.1],
                        [0, 0],
                        [1.1, -0.6],
                        [1.3, 0],
                        [1, 0.4],
                        [0.8, 0.8],
                        [0.4, 1],
                        [0.2, 1.3],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.2],
                        [0, 0],
                        [0, 0.1],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0.2, -1.2],
                        [0.5, -1],
                        [0.7, -0.7],
                        [1, -0.4],
                        [1.3, 0],
                        [1, 0.6],
                        [0, 0],
                        [-0.1, 0.1],
                        [-0.1, 0.1],
                        [-0.1, 0],
                        [-0.1, -0.1],
                        [-0.1, -0.1],
                        [-0.2, -0.2],
                        [-0.3, -0.1],
                        [-0.4, -0.1],
                        [-1.2, 0],
                        [-1, 1.1],
                        [0, 0],
                        [0, 0],
                        [0, 0.3],
                        [-0.2, 0.2],
                        [0, 0],
                        [0, 0.2],
                        [0, 0],
                        [0, 0.1],
                        [0, 0],
                        [0, 0],
                        [0, 0.3],
                        [-0.2, 0.2],
                        [0, 0],
                        [0.2, 1.5],
                        [0.8, 0.9],
                        [0.6, 0],
                        [0.4, -0.2],
                        [0.3, -0.3],
                        [0.2, -0.2],
                        [0.1, 0],
                        [0.1, 0.1],
                        [0, 0],
                        [-0.7, 1.1],
                        [-1.2, 0.6],
                        [-1.1, 0],
                        [-1, -0.4],
                        [-0.8, -0.8],
                        [-0.5, -1.1],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.1],
                        [0, 0],
                        [0, -0.2],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0.201, 8.4],
                        [2.301, 8.4],
                        [3.401, 4.9],
                        [5.301, 2.3],
                        [7.901, 0.6],
                        [11.001, 0],
                        [14.701, 0.8],
                        [17.301, 3.1],
                        [15.901, 4.6],
                        [15.6, 4.9],
                        [15.1, 5],
                        [14.7, 4.9],
                        [14.3, 4.6],
                        [13.8, 4.2],
                        [13.2, 3.8],
                        [12.3, 3.5],
                        [11.1, 3.4],
                        [8, 4.7],
                        [6.2, 8.5],
                        [14.1, 8.5],
                        [14.1, 9.7],
                        [13.8, 10.4],
                        [13, 10.7],
                        [5.9, 10.7],
                        [5.9, 11.2],
                        [5.9, 12.1],
                        [5.9, 12.5],
                        [12.6, 12.5],
                        [12.6, 13.7],
                        [12.3, 14.4],
                        [11.5, 14.7],
                        [6.1, 14.7],
                        [7.8, 18.7],
                        [10.9, 20],
                        [12.7, 19.7],
                        [13.8, 19],
                        [14.5, 18.3],
                        [15.1, 18],
                        [15.4, 18.1],
                        [15.7, 18.3],
                        [17.4, 19.8],
                        [14.6, 22.4],
                        [10.7, 23.3],
                        [7.4, 22.7],
                        [4.8, 20.9],
                        [3, 18.2],
                        [2, 14.6],
                        [0, 14.6],
                        [0, 12.4],
                        [1.8, 12.4],
                        [1.8, 12],
                        [1.8, 11.1],
                        [1.8, 10.6],
                        [0, 10.6],
                        [0, 8.4],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.023529411765, 0.317647058824, 0.945098039216, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81353",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Rectangle_8059",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [226.908, 60.406, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [17.4, 23.2], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle_8059",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Path_81351-2",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [240.835, 40.876, 0], ix: 2 },
            a: { a: 0, k: [8.3, 5.6, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-3.8, -5.8],
                      ],
                      o: [
                        [6.8, 1.4],
                        [0, 0],
                      ],
                      v: [
                        [0, 0],
                        [16.6, 11.2],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.949019607843, 0.949019607843, 0.949019607843, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81351-2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "Path_81350-2",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [249.785, 69.826, 0], ix: 2 },
            a: { a: 0, k: [3.85, 9.35, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [4.9, -5],
                      ],
                      o: [
                        [0, 7],
                        [0, 0],
                      ],
                      v: [
                        [7.7, 0],
                        [0, 18.7],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.949019607843, 0.949019607843, 0.949019607843, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81350-2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "Path_81349-2",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [241.035, 82.826, 0], ix: 2 },
            a: { a: 0, k: [1.9, 1.15, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.3, -0.6],
                      ],
                      o: [
                        [-1.2, 0.9],
                        [0, 0],
                      ],
                      v: [
                        [3.8, 0],
                        [0, 2.3],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.949019607843, 0.949019607843, 0.949019607843, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81349-2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "Path_81348-2",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [218.485, 76.053, 0], ix: 2 },
            a: { a: 0, k: [17.15, 10.777, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [4.6, 13.8],
                        [0.2, 1.2],
                      ],
                      o: [
                        [-13.8, 4.6],
                        [-0.4, -1.2],
                        [0, 0],
                      ],
                      v: [
                        [34.3, 20.2],
                        [0.9, 3.5],
                        [0, 0],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.949019607843, 0.949019607843, 0.949019607843, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81348-2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "Path_81347-2",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [214.035, 47.976, 0], ix: 2 },
            a: { a: 0, k: [13.2, 13.2, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-14.6, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, -14.6],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 26.4],
                        [26.4, 0],
                        [26.4, 0],
                        [26.4, 0],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.949019607843, 0.949019607843, 0.949019607843, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81347-2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: "Ellipse_312",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [228.108, 61.506, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [63, 63], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse_312",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Path_81354",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [73.2, 22.5, 0], ix: 2 },
            a: { a: 0, k: [3.7, 3.75, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.3, 0],
                        [0, 0.3],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.3],
                        [0.3, 0],
                        [0, 0],
                        [0, 0],
                        [0.3, 0],
                        [0, -0.3],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.3],
                        [-0.2, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0.3],
                        [0.3, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.3, 0],
                        [0, -0.3],
                        [0, 0],
                        [0, 0],
                        [0, -0.3],
                        [-0.4, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.3, 0],
                        [0, 0.3],
                        [0, 0],
                      ],
                      v: [
                        [0.5, 4.3],
                        [3.2, 4.3],
                        [3.2, 7],
                        [3.7, 7.5],
                        [4.2, 7],
                        [4.2, 7],
                        [4.2, 4.3],
                        [6.9, 4.3],
                        [7.4, 3.8],
                        [6.9, 3.3],
                        [4.3, 3.3],
                        [4.3, 0.5],
                        [3.8, 0],
                        [3.2, 0.5],
                        [3.2, 0.5],
                        [3.2, 3.2],
                        [0.5, 3.2],
                        [0, 3.7],
                        [0.5, 4.3],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.023529411765, 0.317647058824, 0.945098039216, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81354",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 3600,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Ellipse_313",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [73.65, 23, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [16, 16], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.192156862745, 0.835294117647, 0.419607843137, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse_313",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Path_81351",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [77.654, 12.771, 0], ix: 2 },
            a: { a: 0, k: [9.7, 6.55, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-4.5, -6.8],
                      ],
                      o: [
                        [7.9, 1.6],
                        [0, 0],
                      ],
                      v: [
                        [0, 0],
                        [19.4, 13.1],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.949019607843, 0.949019607843, 0.949019607843, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81351",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Path_81350",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [88.154, 46.521, 0], ix: 2 },
            a: { a: 0, k: [4.5, 10.9, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [5.8, -5.7],
                      ],
                      o: [
                        [0, 8.2],
                        [0, 0],
                      ],
                      v: [
                        [9, 0],
                        [0, 21.8],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.949019607843, 0.949019607843, 0.949019607843, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81350",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Path_81349",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [77.954, 61.771, 0], ix: 2 },
            a: { a: 0, k: [2.2, 1.35, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.5, -0.8],
                      ],
                      o: [
                        [-1.4, 1],
                        [0, 0],
                      ],
                      v: [
                        [4.4, 0],
                        [0, 2.7],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.949019607843, 0.949019607843, 0.949019607843, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81349",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "Path_81348",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [51.454, 53.921, 0], ix: 2 },
            a: { a: 0, k: [20.1, 12.6, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [5.4, 16.2],
                        [0.3, 1.4],
                      ],
                      o: [
                        [-16.2, 5.4],
                        [-0.5, -1.3],
                        [0, 0],
                      ],
                      v: [
                        [40.2, 23.6],
                        [1.1, 4.1],
                        [0, 0],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.949019607843, 0.949019607843, 0.949019607843, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81348",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "Path_81347",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [46.304, 21.071, 0], ix: 2 },
            a: { a: 0, k: [15.45, 15.45, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-17.1, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, -17],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0, 30.9],
                        [30.9, 0],
                        [30.9, 0],
                        [30.9, 0],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.949019607843, 0.949019607843, 0.949019607843, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81347",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "Path_281",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [61.964, 33.854, 0], ix: 2 },
            a: { a: 0, k: [15.3, 11.1, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.3, 0.6],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.6],
                        [0.6, 0],
                        [0.1, 0],
                        [0, 0],
                        [0, 0],
                        [1.6, 0.1],
                        [0, 0],
                        [0.3, -1.6],
                        [0, 0],
                        [0, 0],
                        [0, -0.6],
                        [-0.6, 0],
                        [-0.1, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.6],
                        [0, 0],
                        [-0.7, 0],
                        [0, 0],
                        [0, 0.7],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.7, 0],
                        [0, -0.7],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.2, -1.5],
                        [0, 0],
                        [-1.5, 0.1],
                        [0, 0],
                        [0, 0],
                        [-0.7, 0],
                        [0, 0.7],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.3, 0.5],
                        [0, 0],
                        [0, 0.7],
                        [0, 0],
                        [0.7, 0],
                        [0, 0],
                        [-0.1, -0.6],
                      ],
                      v: [
                        [30, 12.3],
                        [28.8, 10.5],
                        [28.6, 9.6],
                        [28.7, 9.6],
                        [29.9, 8.5],
                        [28.8, 7.3],
                        [28.7, 7.3],
                        [28, 7.3],
                        [26.9, 2.8],
                        [23.8, 0],
                        [6.7, 0],
                        [3.6, 2.8],
                        [2.5, 7.3],
                        [1.8, 7.3],
                        [0.6, 8.4],
                        [1.7, 9.6],
                        [1.8, 9.6],
                        [1.9, 9.6],
                        [1.7, 10.5],
                        [0.5, 12.3],
                        [0, 14.1],
                        [0, 21],
                        [1.2, 22.2],
                        [29.4, 22.2],
                        [30.6, 21],
                        [30.6, 14.1],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.6, -0.1],
                        [0, 0],
                        [-0.2, -0.2],
                        [0, -0.3],
                        [0, 0],
                        [0.6, -0.1],
                        [0, 0],
                        [0.1, 0],
                        [0, 0],
                        [0, 0.7],
                      ],
                      o: [
                        [0, 0],
                        [0.1, -0.6],
                        [0, 0],
                        [0.3, 0],
                        [0.3, 0.2],
                        [0, 0],
                        [0.1, 0.6],
                        [0, 0],
                        [-0.1, 0],
                        [0, 0],
                        [-0.7, 0],
                        [0, 0],
                      ],
                      v: [
                        [4.7, 9.1],
                        [6.2, 3.2],
                        [7.5, 2.3],
                        [23.2, 2.3],
                        [24, 2.5],
                        [24.5, 3.2],
                        [26, 9.1],
                        [25.1, 10.5],
                        [25.1, 10.5],
                        [24.8, 10.5],
                        [5.8, 10.5],
                        [4.6, 9.3],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0.4],
                        [-0.4, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0.8],
                        [0.8, 0],
                        [0, 0],
                        [-0.1, 0.4],
                        [-0.3, 0],
                        [0, 0],
                        [0, -1.5],
                        [1.5, 0],
                        [0.1, 0],
                      ],
                      o: [
                        [0, 0],
                        [-0.4, 0],
                        [0, -0.4],
                        [0, 0],
                        [0, 0],
                        [0.8, 0],
                        [0, -0.8],
                        [0, 0],
                        [-0.4, 0],
                        [0, -0.3],
                        [0, 0],
                        [1.5, -0.1],
                        [0.1, 1.5],
                        [-0.1, 0.1],
                        [0, 0],
                      ],
                      v: [
                        [5.3, 17.9],
                        [3.4, 17.9],
                        [2.7, 17.2],
                        [3.4, 16.5],
                        [3.4, 16.5],
                        [5.2, 16.5],
                        [6.6, 15],
                        [5.2, 13.6],
                        [3.8, 13.6],
                        [3.2, 12.9],
                        [3.8, 12.3],
                        [5.3, 12.3],
                        [8.2, 14.9],
                        [5.6, 17.8],
                        [5.3, 17.9],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 3",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 3,
                  ty: "sh",
                  ix: 4,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [10.3, 19.8],
                        [10.8, 18.6],
                        [20.1, 18.6],
                        [20.6, 19.8],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 4",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 4,
                  ty: "sh",
                  ix: 5,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 1.5],
                        [-1.5, 0],
                        [-0.1, 0],
                        [0, 0],
                        [0, -0.3],
                        [0.3, 0],
                        [0.1, 0],
                        [0, 0],
                        [0, -0.8],
                        [-0.8, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.4],
                        [0.4, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.5, 0.1],
                        [-0.1, -1.5],
                        [0.1, 0],
                        [0, 0],
                        [0.4, 0],
                        [0, 0.4],
                        [0, 0],
                        [0, 0],
                        [-0.8, 0],
                        [0, 0.8],
                        [0, 0],
                        [0, 0],
                        [0.4, 0],
                        [0, 0.4],
                        [0, 0],
                      ],
                      v: [
                        [27.1, 17.9],
                        [25.3, 17.9],
                        [22.4, 15.3],
                        [25, 12.4],
                        [25.3, 12.4],
                        [26.8, 12.4],
                        [27.5, 13],
                        [26.9, 13.7],
                        [26.8, 13.7],
                        [25.3, 13.7],
                        [23.8, 15.1],
                        [25.2, 16.6],
                        [25.3, 16.6],
                        [27.1, 16.6],
                        [27.8, 17.3],
                        [27.1, 17.9],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 5",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.023529411765, 0.317647058824, 0.945098039216, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_281",
              np: 7,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "Path_280",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [71.085, 47.941, 0], ix: 2 },
            a: { a: 0, k: [2.75, 1.7, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.6, 0],
                        [0, 0],
                        [0, 0.6],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0.6],
                        [0, 0],
                        [-0.6, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [4.5, 0],
                        [5.5, 0],
                        [5.5, 2.3],
                        [4.4, 3.4],
                        [1.1, 3.4],
                        [0, 2.3],
                        [0, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.023529411765, 0.317647058824, 0.945098039216, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_280",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: "Path_279",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [52.837, 47.941, 0], ix: 2 },
            a: { a: 0, k: [2.75, 1.7, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.6, 0],
                        [0, 0],
                        [0, 0.6],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0.6],
                        [0, 0],
                        [-0.6, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [4.5, 0],
                        [5.5, 0],
                        [5.5, 2.3],
                        [4.4, 3.4],
                        [1.1, 3.4],
                        [0, 2.3],
                        [0, 2.3],
                        [0, 0],
                        [4.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.023529411765, 0.317647058824, 0.945098039216, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_279",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: "Ellipse_311",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [62.65, 37, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [74, 74], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse_311",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_2",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Path_81345",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [133.258, 51.565, 0], ix: 2 },
            a: { a: 0, k: [5.363, 3.707, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-7.8, 0.4],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [7.8, -0.4],
                        [0, 0],
                      ],
                      v: [
                        [0.002, 0],
                        [7.502, 7.4],
                        [6.502, 0.7],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.18431372549, 0.180392156863, 0.254901960784, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81345",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Path_81344",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [137.896, 37.014, 0], ix: 2 },
            a: { a: 0, k: [7.3, 4.356, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.3],
                        [3.9, 0],
                        [0, 2.3],
                        [-3.9, 0],
                      ],
                      o: [
                        [0, 2.3],
                        [-3.9, 0],
                        [0, -2.3],
                        [3.9, 0],
                      ],
                      v: [
                        [14.6, 4.2],
                        [7.5, 8.4],
                        [0, 7.3],
                        [7.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.18431372549, 0.180392156863, 0.254901960784, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81344",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Path_81343",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [158.872, 76.614, 0], ix: 2 },
            a: { a: 0, k: [6.297, 23.856, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.5, -1.7],
                        [-0.5, -3.2],
                        [0.2, -0.5],
                        [1.4, -0.5],
                        [1.1, -0.2],
                        [0, 0],
                        [-1.6, 6.9],
                      ],
                      o: [
                        [0, 0],
                        [1.7, 0],
                        [0.9, 3.1],
                        [1.4, 8.6],
                        [-0.2, 0.5],
                        [-1.4, 0.5],
                        [-1.1, 0.2],
                        [0, 0],
                        [1.6, -6.9],
                      ],
                      v: [
                        [1.521, 0],
                        [2.221, 0],
                        [5.921, 2.8],
                        [8.021, 12.3],
                        [10.821, 42.8],
                        [12.221, 46.1],
                        [7.121, 47.7],
                        [3.621, 45.5],
                        [0.321, 23.9],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.411764705882, 0.023529411765, 0.945098039216, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81343",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Path_81342",
          sr: 1,
          ks: {
            o: { a: 0, k: 10, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [155.103, 68.339, 0], ix: 2 },
            a: { a: 0, k: [3.481, 14.519, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.3, -0.7],
                        [0.8, -0.9],
                        [-0.4, 2.1],
                        [0.1, 3.3],
                        [-0.4, 0],
                        [0, 0],
                        [-0.5, -1.6],
                        [-0.1, -0.4],
                      ],
                      o: [
                        [-2.7, 0.8],
                        [-0.3, -2.1],
                        [1.4, -6.1],
                        [0, -0.4],
                        [0, 0],
                        [1.7, 0],
                        [0.1, 0.3],
                        [0.6, 7.7],
                      ],
                      v: [
                        [5.474, 27.238],
                        [0.174, 29.038],
                        [0.374, 22.837],
                        [2.774, 0.137],
                        [3.274, 0.337],
                        [3.274, 0.538],
                        [5.974, 1.538],
                        [6.274, 2.637],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81342",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Path_81341",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [135.746, 79.608, 0], ix: 2 },
            a: { a: 0, k: [9.946, 17.15, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-4.9, -6.9],
                        [-2.9, -2.8],
                        [-0.9, 0.1],
                        [-1.6, 0],
                        [1.7, 0.7],
                        [-0.5, 1.1],
                        [1.7, 2.8],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [2.2, 3.3],
                        [0.6, 0.7],
                        [0, 0],
                        [1.6, 0],
                        [-1.7, -0.7],
                        [0.5, -1.1],
                        [-1.7, -2.8],
                      ],
                      v: [
                        [2.896, 0],
                        [0.796, 0.9],
                        [2.896, 21.8],
                        [10.596, 31.1],
                        [12.896, 32],
                        [13.596, 34.3],
                        [19.596, 31.3],
                        [16.796, 28.3],
                        [9.796, 15.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.411764705882, 0.023529411765, 0.945098039216, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81341",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "Path_81340",
          sr: 1,
          ks: {
            o: { a: 0, k: 10, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [133.446, 73.677, 0], ix: 2 },
            a: { a: 0, k: [6.35, 10.219, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.4, -2.2],
                        [1.7, 0.1],
                        [1, 3.8],
                        [0, 0],
                        [0, 0],
                        [-1.6, -2.8],
                      ],
                      o: [
                        [-1.7, 0.3],
                        [-4.4, -0.2],
                        [0.2, -1.2],
                        [0, 0],
                        [0, 0],
                        [0.6, 1.2],
                      ],
                      v: [
                        [12.7, 20.1],
                        [7.5, 20.4],
                        [0, 1.9],
                        [0.3, 0],
                        [1.6, 1.7],
                        [9.4, 14.6],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81340",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "Path_81339",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [142.629, 67.602, 0], ix: 2 },
            a: { a: 0, k: [15.943, 16.747, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.3, -0.7],
                        [0.8, -0.9],
                        [0.1, -0.1],
                        [4, 0.9],
                        [1.4, -0.2],
                        [1.7, 0.1],
                        [1, 3.8],
                        [0.1, 0.1],
                        [-0.7, 0.7],
                        [-1.9, 0.3],
                        [0, 0],
                        [0.4, 1.4],
                        [-0.9, 0.2],
                        [-1.4, -0.5],
                        [-0.1, -1.6],
                        [0, -0.4],
                      ],
                      o: [
                        [-2.7, 0.8],
                        [-0.1, 0.1],
                        [-0.7, 1.2],
                        [-1.3, -0.3],
                        [-1.7, 0.3],
                        [-4.4, -0.2],
                        [-0.1, -0.2],
                        [-0.9, -2.8],
                        [0.7, -0.7],
                        [1.9, -0.3],
                        [0, 0],
                        [-0.4, -1.4],
                        [1.5, 0.1],
                        [1.5, 0.4],
                        [0, 0.4],
                        [0.7, 7.6],
                      ],
                      v: [
                        [30.41, 30.203],
                        [25.11, 32.003],
                        [24.81, 32.303],
                        [17.11, 33.003],
                        [13.01, 32.703],
                        [7.81, 33.003],
                        [0.31, 14.503],
                        [0.11, 14.003],
                        [4.81, 7.703],
                        [9.51, 3.903],
                        [14.31, 7.703],
                        [18.41, 0.803],
                        [23.71, 0.103],
                        [28.21, 1.103],
                        [31.01, 4.503],
                        [31.11, 5.703],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.411764705882, 0.023529411765, 0.945098039216, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81339",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "Path_81338",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [142.58, 95.505, 0], ix: 2 },
            a: { a: 0, k: [3.184, 4.747, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-3.6, 1.9],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.6, -1.9],
                        [0, 0],
                      ],
                      v: [
                        [0, 3.2],
                        [5.4, 9.2],
                        [1.4, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0.721568627451, 0.721568627451, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81338",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "Path_81337",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [162.585, 100.005, 0], ix: 2 },
            a: { a: 0, k: [3.189, 4.747, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-3.6, 1.9],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [3.6, -1.9],
                        [0, 0],
                      ],
                      v: [
                        [0, 3.2],
                        [5.4, 9.2],
                        [1.5, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0.721568627451, 0.721568627451, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81337",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: "Path_81336",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [198.474, 138.518, 0], ix: 2 },
            a: { a: 0, k: [7.918, 10.445, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.5, 1.4],
                        [2.5, -0.9],
                        [0.1, 0],
                        [0, -0.1],
                        [0.3, 0.4],
                        [-1.2, -4.5],
                        [-1.8, -1.6],
                        [-0.4, 1.6],
                        [1.1, 1.7],
                        [0.6, 2.2],
                      ],
                      o: [
                        [-0.9, -2.5],
                        [0, 0],
                        [-0.4, 0.1],
                        [-0.2, 0.7],
                        [-0.3, -0.4],
                        [1.2, 4.5],
                        [1.8, 1.6],
                        [0.3, -2],
                        [-1.3, -1.9],
                        [-0.2, -1.5],
                      ],
                      v: [
                        [10.54, 3.185],
                        [4.44, 0.285],
                        [4.34, 0.285],
                        [3.74, 0.685],
                        [1.14, 1.285],
                        [0.44, 4.185],
                        [6.94, 19.785],
                        [15.74, 19.585],
                        [14.54, 13.785],
                        [11.74, 7.485],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.078431372549, 0.074509803922, 0.090196078431, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81336",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: "Path_81335",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [125.156, 137.259, 0], ix: 2 },
            a: { a: 0, k: [11.457, 9.801, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.2, -2.4],
                        [0, 0],
                        [-7.2, -1.9],
                        [-6, 4.2],
                        [-0.2, 0.7],
                        [1.6, 0],
                        [1.8, 0],
                        [1.3, -0.3],
                      ],
                      o: [
                        [0, 0],
                        [-1.2, 2.4],
                        [0, 0],
                        [0, 0],
                        [6, -4.2],
                        [0.2, -0.7],
                        [-1.6, 0],
                        [-1.3, 0],
                        [0, 0],
                      ],
                      v: [
                        [12.196, 1.6],
                        [9.396, 2.8],
                        [4.996, 10.5],
                        [2.696, 19.6],
                        [12.196, 15.6],
                        [22.896, 8.6],
                        [19.896, 0],
                        [16.896, 3.7],
                        [12.896, 4.2],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.078431372549, 0.074509803922, 0.090196078431, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81335",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: "Path_81334",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [155.923, 105.958, 0], ix: 2 },
            a: { a: 0, k: [43.073, 26.4, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-3.7, -9.4],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 1.4],
                        [1.9, 1.2],
                        [-6.7, 0.4],
                        [-3.7, -2.1],
                        [0, 0],
                        [-4, -2.6],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 2.1],
                        [7.7, 3.2],
                        [3.5, 2.6],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [3.7, 9.4],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -1.4],
                        [0, 0],
                        [6.7, -0.4],
                        [3.7, 2.1],
                        [0, 0],
                        [4, 2.6],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -2.1],
                        [-4, -1.6],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [18.446, 5.3],
                        [13.146, 10],
                        [0.546, 25.4],
                        [11.246, 48.4],
                        [13.146, 52.8],
                        [17.846, 52.8],
                        [20.846, 51.4],
                        [20.146, 49.5],
                        [18.546, 45.5],
                        [13.846, 26.9],
                        [29.846, 17.4],
                        [42.146, 17.4],
                        [60.546, 23.4],
                        [72.446, 40.4],
                        [79.646, 51.6],
                        [86.146, 50.4],
                        [82.846, 43.7],
                        [79.546, 36.7],
                        [61.246, 11.1],
                        [49.846, 4.8],
                        [32.246, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.125490196078, 0.105882352941, 0.435294117647, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81334",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: "Ellipse_310",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [138.696, 42.258, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [15.2, 15.2], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0.721568627451, 0.721568627451, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse_310",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: "Path_81333",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [141.296, 52.768, 0], ix: 2 },
            a: { a: 0, k: [6.6, 9.59, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1, -1.2],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.9, 5.8],
                      ],
                      o: [
                        [0, 0],
                        [-1, 1.2],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.9, -5.8],
                      ],
                      v: [
                        [0, 3.579],
                        [1.6, 15.479],
                        [9.5, 19.179],
                        [13.2, 8.979],
                        [11.1, 8.779],
                        [9, 2.279],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0.721568627451, 0.721568627451, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81333",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: "Path_81332",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [138.646, 43.108, 0], ix: 2 },
            a: { a: 0, k: [10.85, 12.15, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -8.6],
                        [6, 0],
                        [0, 8.5],
                        [-6, 0],
                      ],
                      o: [
                        [0, 8.5],
                        [-6, 0],
                        [0, -8.5],
                        [6, 0],
                      ],
                      v: [
                        [21.7, 15.5],
                        [12, 24.3],
                        [0, 15.5],
                        [10.9, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.18431372549, 0.180392156863, 0.254901960784, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81332",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: "Path_81303",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [183.851, 157.549, 0], ix: 2 },
            a: { a: 0, k: [10.451, 15.551, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -5.4],
                        [5.8, -0.1],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [6.4, -0.1],
                        [0.1, 5.8],
                        [0, 0],
                      ],
                      v: [
                        [6, 19.002],
                        [6.1, 31.002],
                        [0.3, 31.102],
                        [0, 0.102],
                        [11.1, 0.002],
                        [20.9, 9.202],
                        [11.3, 19.002],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 2.8],
                        [2.8, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [2.8, 0],
                        [0, -2.5],
                        [0, 0],
                      ],
                      v: [
                        [5.8, 5.202],
                        [5.9, 13.902],
                        [10.6, 13.902],
                        [14.9, 9.302],
                        [10.5, 5.202],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81303",
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 3600,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: "Path_81302",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [156.4, 157.85, 0], ix: 2 },
            a: { a: 0, k: [12.902, 16.051, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [8, -0.1],
                        [0.1, 10.9],
                        [-8, 0.1],
                        [-0.1, -11],
                      ],
                      o: [
                        [0.1, 11],
                        [-8, 0.1],
                        [-0.1, -10.9],
                        [8, -0.1],
                        [0, 0],
                      ],
                      v: [
                        [25.802, 15.901],
                        [13.102, 32.101],
                        [0.002, 16.201],
                        [12.702, 0.001],
                        [25.802, 15.901],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-4.6, 0],
                        [0.1, 6.2],
                        [0, 0],
                        [4.6, 0],
                        [0, -6.2],
                      ],
                      o: [
                        [0, 0],
                        [0.1, 6.2],
                        [4.6, 0],
                        [0, 0],
                        [-0.1, -6.2],
                        [-4.6, 0],
                        [0, 0],
                      ],
                      v: [
                        [5.902, 14.901],
                        [5.902, 17.201],
                        [12.902, 27.101],
                        [19.702, 17.101],
                        [19.702, 14.801],
                        [12.702, 4.901],
                        [5.902, 14.901],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81302",
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 3600,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 18,
          ty: 4,
          nm: "Path_81301",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [131.05, 158.1, 0], ix: 2 },
            a: { a: 0, k: [10.65, 15.6, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [21.3, 5.1],
                        [13.6, 5.2],
                        [13.9, 31.1],
                        [8.1, 31.2],
                        [7.8, 5.3],
                        [0.1, 5.4],
                        [0, 0.2],
                        [21.2, 0],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81301",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 3600,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 19,
          ty: 4,
          nm: "Path_81300",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [107.451, 158.201, 0], ix: 2 },
            a: { a: 0, k: [10.851, 16.101, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.2, 0.2],
                        [0.2, 0],
                        [0, -1.9],
                        [-0.1, -11.4],
                        [6.5, -0.1],
                        [0, 6.3],
                        [0, 0.2],
                        [0, 0],
                        [0, -0.2],
                        [-2.6, -0.2],
                        [-0.2, 0],
                        [0, 1.8],
                        [0.1, 11.9],
                        [-5.5, 0.1],
                        [-0.1, -6.4],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.2, -2.2],
                        [-0.2, 0],
                        [-2.8, 0],
                        [0.1, 6.3],
                        [0.1, 7.1],
                        [-5.7, 0.1],
                        [0, -0.4],
                        [0, 0],
                        [0, 0.1],
                        [-0.2, 2.5],
                        [0.2, 0],
                        [3.5, 0],
                        [-0.1, -7.4],
                        [-0.1, -5.9],
                        [5.4, 0],
                        [0, 0],
                      ],
                      v: [
                        [20.9, 9.2],
                        [20.9, 9.7],
                        [15, 9.8],
                        [15, 9.4],
                        [11.4, 5.1],
                        [10.8, 5.1],
                        [6.5, 8.4],
                        [21.7, 22.2],
                        [10.8, 32.2],
                        [0, 22.7],
                        [0, 21.7],
                        [6, 21.6],
                        [6, 22.3],
                        [10.3, 27.2],
                        [11, 27.2],
                        [15.7, 23.5],
                        [0.5, 8.8],
                        [10.8, 0],
                        [20.9, 9.2],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81300",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 3600,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 20,
          ty: 4,
          nm: "Path_81299",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [144.223, 158.358, 0], ix: 2 },
            a: { a: 0, k: [63.9, 63.9, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [127.3, 36.9],
                        [89.6, 0],
                        [36.9, 0.5],
                        [0, 38.2],
                        [0.5, 90.9],
                        [38.1, 127.8],
                        [90.9, 127.3],
                        [127.8, 89.7],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.827450980392, 0.305882352941, 0.305882352941, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81299",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 21,
          ty: 4,
          nm: "Path_81298",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [144.223, 158.358, 0], ix: 2 },
            a: { a: 0, k: [68.4, 68.4, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [95.9, 0],
                        [39.5, 0.6],
                        [0, 40.9],
                        [0.6, 97.3],
                        [40.9, 136.8],
                        [97.3, 136.2],
                        [136.8, 95.9],
                        [136.2, 39.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.850980392157, 0.850980392157, 0.850980392157, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Path_81298",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "saldo",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [235.5, 82.75, 0], ix: 2 },
        a: { a: 0, k: [229.5, 62.25, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 91,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 106,
              s: [110, 110, 100],
            },
            { t: 115, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: "a",
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [261.594, 28.25],
                [195.422, 28.25],
                [195.5, 94.612],
                [261.672, 94.612],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: "Mask 1",
        },
      ],
      w: 268,
      h: 239,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: "veiculo",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [69.25, 61, 0], ix: 2 },
        a: { a: 0, k: [63.25, 40.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 71,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 86,
              s: [110, 110, 100],
            },
            { t: 95, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: "a",
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [102.579, -0.25],
                [21.75, -0.25],
                [21.75, 76.66],
                [102.579, 76.66],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: "Mask 1",
        },
      ],
      w: 268,
      h: 239,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: "Stop",
      refId: "comp_2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [150.5, 245.5, 0], ix: 2 },
        a: { a: 0, k: [144.5, 225, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 49,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 64,
              s: [110, 110, 100],
            },
            { t: 73, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: "a",
          pt: {
            a: 0,
            k: {
              i: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              o: [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
              v: [
                [227.033, 30],
                [61, 30],
                [61, 229.307],
                [227.033, 229.307],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: "Mask 1",
        },
      ],
      w: 268,
      h: 239,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "planta",
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [10.35, 14.353, 0], ix: 2 },
        a: { a: 0, k: [4.35, 14.55, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.3, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.3, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.3, 27.9],
                    [8.2, 22.4],
                    [4.3, 28.5],
                    [4.3, 29.1],
                    [3.5, 29.1],
                    [3.9, 21],
                    [3.9, 21],
                    [3.9, 21],
                    [3.9, 20.2],
                    [0, 14.1],
                    [3.9, 19.6],
                    [3.9, 19.8],
                    [4.2, 13.7],
                    [0.8, 7.4],
                    [4.2, 12.6],
                    [4.5, 0],
                    [4.5, 0],
                    [4.4, 9.9],
                    [7.7, 6],
                    [4.3, 10.8],
                    [4.2, 16.2],
                    [7.3, 11],
                    [4.2, 17],
                    [4.2, 20],
                    [8.7, 12.7],
                    [4.1, 21],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.247058823529, 0.239215686275, 0.337254901961, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Path_81306",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "planta",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [255.008, 251.721, 0], ix: 2 },
        a: { a: 0, k: [10.7, 28.501, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 15,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 28,
              s: [110, 110, 100],
            },
            { t: 32, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -8.1],
                    [5.9, 0],
                    [0.1, 0],
                    [0.3, 0],
                    [0, 7.5],
                    [-0.7, 0.7],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 8],
                    [-0.1, 0],
                    [-0.3, 0],
                    [-5.3, -0.4],
                    [0, -7.7],
                    [0, 0],
                    [0, 0],
                    [0.1, -0.2],
                  ],
                  v: [
                    [21.4, 18.103],
                    [10.7, 29.003],
                    [10.3, 29.003],
                    [9.5, 29.003],
                    [0, 18.203],
                    [10.6, 0.003],
                    [10.6, 0.003],
                    [10.6, 0.003],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.192156862745, 0.835294117647, 0.419607843137, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Path_81305",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Ellipse_299",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [150.513, 250.558, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 1,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 17,
              s: [106, 106, 100],
            },
            { t: 21, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [245.6, 17.8], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.635294117647, 0.635294117647, 0.635294117647, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse_299",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "Path_81304",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [128.606, 247.359, 0], ix: 2 },
        a: { a: 0, k: [122.606, 212.899, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 24,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 43,
              s: [110, 110, 100],
            },
            { t: 52, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [62.5, -16.2],
                    [12.5, -12.9],
                    [0, 0],
                    [19.6, -15.3],
                    [-64.9, 0],
                    [0, 60.8],
                  ],
                  o: [
                    [-14.2, 3.7],
                    [0.4, 0.4],
                    [-14.7, 17.6],
                    [-49.8, 39],
                    [64.9, 0],
                    [0, -60.8],
                  ],
                  v: [
                    [130.713, 2.498],
                    [91.413, 28.998],
                    [63.513, 61.198],
                    [13.213, 112.598],
                    [130.713, 222.798],
                    [248.213, 112.698],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.949019607843, 0.949019607843, 0.949019607843, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Path_81304",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

export default animation;
