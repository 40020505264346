import ClickableDiv from "components/ClickableDiv";
import FlexBox from "components/FlexBox";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { convertDateTimeToReadableDate } from "utils/dateTime";
import { notificationStates } from "utils/notifications";
import { notificationTypeIds } from "utils/pushNotifications";
import { useNotificationsContext } from "../Provider";
import useNotificationRedirect from "../useNotificationRedirect";

const Notification = ({ id, title, message, stateId, typeId, resourceId, createdAt }) => {
  const { close } = useNotificationsContext();
  const { handleRedirect } = useNotificationRedirect();

  const isRead = stateId === notificationStates.read;

  const onClickNotification = () => {
    const notificationType = Object.keys(notificationTypeIds).find(
      (key) => notificationTypeIds[key] === typeId
    );

    close();
    handleRedirect(id, { type: notificationType, resourceId, stateId });
  };

  return (
    <Container onClick={onClickNotification}>
      <FlexBox className="notification-inner-container">
        <Dot isRead={isRead} />
        <TextContainer isRead={isRead}>
          <Title title={title}>{title}</Title>
          <Message title={message.replaceAll("\\n", "\n")}>
            {message.split("\\n").map((line) => (
              <span key={line}>
                {line}
                <br />
              </span>
            ))}
          </Message>
          <Date id="date">{convertDateTimeToReadableDate(createdAt)}</Date>
        </TextContainer>
      </FlexBox>
    </Container>
  );
};

export default React.memo(Notification);

Notification.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  stateId: PropTypes.number.isRequired,
  typeId: PropTypes.number.isRequired,
  resourceId: PropTypes.number,
  createdAt: PropTypes.string.isRequired,
};

Notification.defaultProps = {
  resourceId: undefined,
};

const Container = styled(ClickableDiv)`
  padding: 5px 15px 0px;

  :not(:last-child) > .notification-inner-container {
    padding-bottom: 6px;
    border-bottom: 1px solid ${({ theme }) => theme.notifications.card.dividerColor};
  }

  :hover {
    background-color: ${({ theme }) => theme.notifications.card.hoverColor};

    #date {
      color: ${({ theme }) => theme.notifications.card.hoverDateColor};
    }
  }
`;

const Dot = styled.div`
  min-width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 5px;
  background-color: ${({ isRead, theme }) =>
    isRead ? theme.notifications.card.read.dot : theme.notifications.card.unread.dot};
`;

const TextContainer = styled.div`
  overflow: hidden;
  line-height: 1.2;

  > span {
    color: ${({ isRead, theme }) =>
      isRead ? theme.notifications.card.read.text : theme.notifications.card.unread.text};
  }
`;

const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

const Title = styled(StyledText)`
  line-height: 1.5;
  text-transform: uppercase;
`;

const Message = styled(StyledText)`
  @supports (-webkit-line-clamp: 2) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const Date = styled.div`
  font-size: 9px;
  margin-top: 4px;
  color: ${({ theme }) => theme.notifications.card.dateColor};
`;
