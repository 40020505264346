import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { languageWithoutRegion } from "utils/general";

const useLanguage = () => {
  const { i18n } = useTranslation();

  const language = useMemo(() => languageWithoutRegion(i18n.language), [i18n]);

  return language;
};

export default useLanguage;
