import PropTypes from "prop-types";

export const citiesPropTypes = {
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      entityId: PropTypes.number.isRequired,
      entityLogo: PropTypes.string.isRequired,
    })
  ).isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
};
