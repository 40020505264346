import { ReactComponent as FrontIcon } from "assets/icons/front.svg";
import { ReactComponent as Network } from "assets/icons/network.svg";
import FlexBox from "components/FlexBox";
import Text from "components/Text";
import useClickOutside from "hooks/useClickOutside";
import PropTypes from "prop-types";
import React, { useCallback, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useLanguageContext } from "../LanguageProvider";

const animationTime = "0.3s";
const itemHeight = 30;

const LanguageField = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, []);

  useClickOutside(ref, handleClickOutside);

  const onSelectClick = () => {
    setIsOpen(!isOpen);
  };

  const { languages, onChangeLanguage, language } = useLanguageContext();

  const onSelect = (lang) => {
    onChangeLanguage(lang);
    setIsOpen(false);
  };

  const selectedOption = languages.find((option) => option.value === language);
  const dropdownHeight = itemHeight * languages.length;

  if (!selectedOption) {
    return null;
  }

  return (
    <Container ref={ref} className={className}>
      <InputContainer onClick={onSelectClick} isOpen={isOpen}>
        <FlexBox alignItems="center">
          <Network className="mr-5" />
          <Text className="fit-text-vertically" variant="body6">
            {selectedOption.value.toUpperCase()}
          </Text>
        </FlexBox>
        <StyledArrowIcon $isOpen={isOpen} />
      </InputContainer>
      <DropdownContainer isOpen={isOpen} height={dropdownHeight}>
        {languages.map((option) => (
          <DropdownOption key={option.value} onClick={() => onSelect(option.value)}>
            <OptionLabel isSelected={option.value === language} variant="body6">
              {option.label}
            </OptionLabel>
          </DropdownOption>
        ))}
      </DropdownContainer>
    </Container>
  );
};

export default LanguageField;

LanguageField.propTypes = {
  className: PropTypes.string.isRequired,
};

const Container = styled.div`
  color: ${({ theme }) => theme.color.lightest};
  position: relative;
  width: 70px;
`;

const InputContainer = styled.div`
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-bottom: 5px;

  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      border-bottom: 1px solid ${theme.color.lightest};
    `}
`;

const DropdownContainer = styled.div`
  margin-top: 5px;
  font-size: 14px;
  overflow: hidden;
  position: absolute;
  transition: max-height ${animationTime} ease-in-out;
  max-height: ${({ isOpen, height }) => (isOpen ? `${height}px` : 0)};
`;

const DropdownOption = styled.div`
  cursor: pointer;
  padding: 4px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  :hover {
    border-bottom-color: ${({ theme }) => theme.color.lightest};
  }
`;

const StyledArrowIcon = styled(FrontIcon)`
  cursor: pointer;
  fill: ${({ theme }) => theme.color.lightest};
  transform: ${({ $isOpen }) => ($isOpen ? `rotate(-90deg)` : `rotate(90deg)`)};
  transition: transform ${animationTime} ease;
  height: 20px;
`;

const OptionLabel = styled(Text)`
  font-weight: ${({ isSelected, theme }) =>
    theme.typography.weight[isSelected ? "semiBold" : "light"]};
`;
