import React, { useState, useEffect, useMemo } from "react";
import ActionPopup from "domain/private/components/ActionPopup";
import styled, { css } from "styled-components";
import { useAppContext } from "components/AppProvider";
import { Text } from "iparque-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import FlexBox from "components/FlexBox";
import { formatMoney } from "utils/money";
import { breakpoints, breakpointsMapping } from "utils/breakpoints";
import { roundDecimalPlaces } from "utils/general";
import { infoMessage } from "utils/userMessages";

const defaultAmountOptions = [5, 7.5, 10, 15, 20];

const StyledInput = styled.span`
  display: flex;
  padding: 5px;
  font-size: 80px;
  font-family: ${({ theme }) => theme.balanceCharge.input};
  font-weight: ${({ theme }) => theme.typography.weight.light};
  background-color: transparent;
  border: ${({ theme }) => `1px solid ${theme.balanceCharge.input}`};
  border-radius: 10px;
  color: ${({ theme }) => theme.balanceCharge.input};
  width: fit-content;
  text-align: center;

  @media (max-width: ${breakpoints.md}) {
    font-size: 50px;
  }

  @media (max-width: ${breakpoints.sm}) {
    font-size: 40px;
  }

  &:after {
    content: "€";
    margin-right: 15px;
  }

  input {
    outline: none;
    border: none;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    background: inherit;
    color: inherit;
    width: ${({ inputSize }) => inputSize}px;
    text-align: center;

    ::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    ::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
  }
`;

const optionHighlightStyle = css`
  background: ${({ theme }) => theme.balanceCharge.options};

  .amount-option-text {
    color: ${({ theme }) => theme.box.color};
  }
`;

const OptionsContainer = styled(FlexBox)`
  flex-wrap: wrap;
`;

const OptionContainer = styled.div`
  border-radius: 10px;
  margin: 5px;
  padding: 2px 10px;
  border: 1px solid ${({ theme }) => theme.balanceCharge.options};
  cursor: pointer;
  height: fit-content;

  :hover {
    ${optionHighlightStyle}
    opacity: 0.7;
  }

  .amount-option-text {
    font-size: 34px;
    font-family: ${({ theme }) => theme.typography.font.tertiary};
    font-weight: ${({ theme }) => theme.typography.weight.regular};
    color: ${({ theme }) => theme.balanceCharge.options};

    @media (max-width: ${breakpoints.md}) {
      font-size: 25px;
    }
  }

  ${({ selected }) => selected && optionHighlightStyle}
`;

const AmountDefinitionPopup = ({ onClose, onConfirm, minAmount }) => {
  const { t } = useTranslation();
  const { breakpoint } = useAppContext();
  const [amount, setAmount] = useState(minAmount);
  const [options, setOptions] = useState([]);

  const isAmountValid = (newAmount) => {
    return newAmount >= minAmount;
  };

  const displayAmountInfo = () => {
    infoMessage(
      `${t("9354") /* O valor mínimo é */} ${formatMoney(minAmount, {
        centsFormat: false,
      })}`
    );
  };

  const getAmountProcessed = (newAmount) => {
    const isValid = isAmountValid(newAmount);

    if (!isValid) {
      displayAmountInfo();
    }

    return isValid ? newAmount : minAmount;
  };

  const localOnConfirm = () => {
    if (!isAmountValid(amount)) {
      return;
    }

    onConfirm(parseFloat(amount, 10));
    onClose();
  };

  const onChange = (event) => {
    setAmount(event.target.value);
  };

  const setAmountWithDecimalPlaces = (newAmount = amount) => {
    setAmount(roundDecimalPlaces(getAmountProcessed(newAmount)));
  };

  useEffect(() => {
    const newOptions = defaultAmountOptions.filter((option) => option >= minAmount);

    setOptions(newOptions);
  }, []);

  const inputSize = useMemo(() => {
    const numberOfCharacters = amount.length;
    let minWidth = 240;
    let placeWidth = 46;

    if (breakpoint === breakpointsMapping.md) {
      minWidth = 200;
      placeWidth = 30;
    }

    if (breakpoint === breakpointsMapping.sm) {
      minWidth = 150;
      placeWidth = 25;
    }

    if (!numberOfCharacters || Number.isNaN(numberOfCharacters)) {
      return minWidth;
    }

    const computedWidth = numberOfCharacters * placeWidth;

    return computedWidth >= minWidth ? computedWidth : minWidth;
  }, [amount]);

  return (
    <ActionPopup
      width={750}
      onClose={onClose}
      title={t("4080") /* Carregamento de Saldo */}
      onConfirm={localOnConfirm}
    >
      <Text variant="body8">
        {t("9669") /* Insere ou escolhe o valor que desejas carregar. */}
      </Text>
      <FlexBox className="mt-40" alignItems="center" flexDirection="column">
        <StyledInput inputSize={inputSize}>
          <input
            min={minAmount}
            type="number"
            step={0.01}
            maxLength="5"
            required="required"
            value={amount}
            onChange={onChange}
            onBlur={() => setAmountWithDecimalPlaces(amount)}
          />
        </StyledInput>
        <OptionsContainer justifyContent="center" className="mt-20 mb-20">
          {options.map((option) => (
            <OptionContainer
              onClick={() => setAmountWithDecimalPlaces(option)}
              selected={parseFloat(option) === parseFloat(amount)}
              key={option}
            >
              <Text className="amount-option-text" variant="body10">
                {formatMoney(option, { centsFormat: false })}
              </Text>
            </OptionContainer>
          ))}
        </OptionsContainer>
      </FlexBox>
    </ActionPopup>
  );
};

AmountDefinitionPopup.defaultProps = {
  minAmount: null,
};

AmountDefinitionPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  minAmount: PropTypes.number,
};

export default AmountDefinitionPopup;
