import useLocalizedUrls from "hooks/useLocalizedUrls";
import { Divider, Link, Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import TabContainer from "../../components/TabContainer";
import { accountDefaultProps, accountPropTypes } from "../../propTypes";
import ExportUserData from "./ExportUserData";
import GeneralDataProtectionRegulationField from "./GeneralDataProtectionRegulationField";
import RemoveAccount from "./RemoveAccount";

const AccountTab = ({ isSmallDevicesVersion }) => {
  const { t } = useTranslation();
  const { privacyPolicyUrl, termsAndConditionsUrl } = useLocalizedUrls();

  return (
    <TabContainer>
      {isSmallDevicesVersion && (
        <Text className="mb-20" variant="body8">
          {t("8527") /* Conta */}
        </Text>
      )}
      <ExportUserData
        className="mb-10"
        title={t("8597") /* Exportar os meus dados */}
        description={
          t("8598") /* Descarregar todos os meus dados pessoais guardados pelo iParque */
        }
      />
      <Divider color="secondary" />
      <GeneralDataProtectionRegulationField
        className="mb-10 mt-10"
        title={t("227") /* Termos e Condições */}
        description={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Text variant="body10">
            {t("8600") /* Li e aceito as */}
            &nbsp;
            <Link
              target="_blank"
              href={termsAndConditionsUrl}
              underline="always"
              rel="noopener"
            >
              {t("8601") /* Condições Gerais */}
            </Link>
            &nbsp;
            {t("10521") /* de adesão bem como a */}
            &nbsp;
            <Link
              target="_blank"
              href={privacyPolicyUrl}
              underline="always"
              rel="noopener"
            >
              {t("6005") /* Política de Privacidade */}
            </Link>
          </Text>
        }
      />
      <Divider color="secondary" />
      <RemoveAccount className="mb-10 mt-10" title={t("9345") /* Apagar conta */} />
    </TabContainer>
  );
};

AccountTab.propTypes = accountPropTypes;

AccountTab.defaultProps = accountDefaultProps;

export default AccountTab;
