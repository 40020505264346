import React from "react";
import styled from "styled-components";
import { ReactComponent as FrontIcon } from "../../../../assets/icons/front.svg";
import { tabDefaultProps, tabPropTypes } from "../propTypes";
import TabIdentification from "./TabIdentification";

const StyledTab = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;

  ${({ isActive, theme }) =>
    isActive && `background: ${theme.tab.vertical.selectedColor};`};

  :hover {
    background: ${({ theme }) => theme.tab.vertical.hoverColor};
    border-bottom: 1px solid ${({ theme }) => theme.tab.vertical.hoverColor};
  }

  :not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.tab.vertical.selectedColor};
  }
`;

const Arrow = styled(FrontIcon)`
  margin-left: auto;
  height: 20px;
  fill: ${({ theme }) => theme.tab.vertical.arrowColor};
`;

const Tab = ({ isActive, onClick, label, icon }) => {
  return (
    <StyledTab isActive={isActive} onClick={onClick}>
      <TabIdentification Icon={icon} label={label} />
      <Arrow />
    </StyledTab>
  );
};

Tab.propTypes = tabPropTypes;

Tab.defaultProps = tabDefaultProps;

export default Tab;
