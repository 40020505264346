import { driverPublicColors as colors } from "iparque-components";

export default function Icon(google) {
  const getIcon = (color = colors.tertiary) => {
    return {
      url: `data:image/svg+xml;base64, ${btoa(
        `<svg
          xmlns="http://www.w3.org/2000/svg"
          width="24.518"
          height="30"
          viewBox="0 0 24.518 30"
        >
          <path
            fill="${color}"
            d="M518,300v30h24.518V300Zm18.426,23.909H524.091V306.091h12.335Z"
            transform="translate(-518 -300)"
          />
        </svg>`
      )}`,
      scaledSize: new google.maps.Size(20, 20),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(10, 10),
    };
  };

  return { getIcon };
}
