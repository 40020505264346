import Button from "components/buttons/Button";
import { Heading, Text } from "iparque-components";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { convertDateTimeToReadableDate, formatDate } from "../../../../utils/dateTime";
import { formatMoney } from "../../../../utils/money";
import { getTextColorByState } from "../../../../utils/notices";
import ListCard from "../../components/cardList/Card";
import CardCell from "../../components/cardList/Cell";
import { useBackofficeThemeContext } from "../../components/ThemeProvider";
import useNotices from "../hooks/useNotices";
import { noticePropTypes } from "../propTypes";

const StyledText = styled(Text)`
  color: ${({ textColor }) => textColor};
`;

const ExtraInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Line = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
`;

const NoticeCard = (props) => {
  const { backofficeTheme } = useBackofficeThemeContext();
  const { t } = useTranslation();
  const { pay } = useNotices();
  const {
    id,
    number,
    licensePlate,
    vehicleColorId,
    dateTime,
    paymentDateTime,
    amount,
    stateName,
    stateId,
    zoneName,
    streetName,
    situation,
    infraction,
    payable,
    paymentScenario,
  } = props;

  const extraInfo = (
    <ExtraInformationContainer>
      {payable ? (
        <Line>
          <StyledText
            textColor={backofficeTheme.carColor[vehicleColorId]}
            variant="body3"
          >
            {licensePlate}
          </StyledText>
        </Line>
      ) : null}
      <Line>
        <CardCell>
          <Text variant="body12">{t("8284") /* Registado */}</Text>
          <Text variant="body11">{convertDateTimeToReadableDate(dateTime)}</Text>
        </CardCell>
        <CardCell>
          <Text variant="body12">{t("1482") /* Data limite de pagamento */}</Text>
          <Text variant="body11">{formatDate(paymentDateTime)}</Text>
        </CardCell>
      </Line>
      <Line>
        <CardCell>
          <Text variant="body12">{t("4713") /* Número */}</Text>
          <Text variant="body11">{number}</Text>
        </CardCell>
        <CardCell>
          <Text variant="body12">{t("456") /* Norma */}</Text>
          <Text variant="body11">{infraction}</Text>
        </CardCell>
      </Line>
      <Line>
        <CardCell>
          <Text variant="body12">{t("7255") /* Zona */}</Text>
          <Text variant="body11">{zoneName}</Text>
        </CardCell>
        <CardCell>
          <Text variant="body12">{t("2054") /* Rua */}</Text>
          <Text variant="body11">{streetName}</Text>
        </CardCell>
      </Line>
    </ExtraInformationContainer>
  );

  return (
    <ListCard extraInfo={extraInfo}>
      <CardCell size="xl">
        <Text color={payable ? "danger" : "primary"} variant="body3">
          {situation}
        </Text>
        <>
          {payable ? (
            <Heading variant="h5" color="tertiary">
              {formatMoney(amount, { centsFormat: false })}
            </Heading>
          ) : (
            <StyledText
              textColor={backofficeTheme.carColor[vehicleColorId]}
              variant="body3"
            >
              {licensePlate}
            </StyledText>
          )}
        </>
      </CardCell>
      {!paymentScenario && (
        <CardCell size="md">
          {payable ? (
            <Button onClick={() => pay(id, [props])} size="xs">
              {t("4537") /* Pagar */}
            </Button>
          ) : (
            <>
              <Text color={getTextColorByState(stateId)} variant="body5">
                {stateName}
              </Text>
              <Heading variant="h5" color="tertiary">
                {formatMoney(amount, { centsFormat: false })}
              </Heading>
            </>
          )}
        </CardCell>
      )}
    </ListCard>
  );
};

NoticeCard.propTypes = noticePropTypes;

export default NoticeCard;
